import React, { useContext } from "react";
import DashboardSectionTitle from "../DashboardSectionTitle/DashboardSectionTitle";
import NotificationCard from "./NotificationCard";
import SimpleMessageCard from "../../../ui/SimpleMessageCard/SimpleMessageCard";

//Context
import AppContext from "../../../AppContext";

//Service
import recordService from "../../../services/Record";

function DashboardNotificationContent(props) {
  const { notifications, setNotifications, getAllNotifications } = useContext(AppContext);
  console.log("Notifications = ", notifications);

  // Get all notification list from DB
  // function getAllNotifications() {
  //   console.log("inside getAllNotifications() in app.js");
  //   recordService
  //     .getAllNotifications()
  //     .then((response) => {
  //       if (response.data.status === "success") {
  //         console.log("Response from APP notifications : ", response.data);
  //         setNotifications(
  //           response.data.data.result.notifications.filter(
  //             (notification) => !notification.is_read
  //           )
  //         );
  //       }
  //       if (response.data.status === "failed") {
  //         console.log("ERROR:", response.data.error);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log("There is a problem in creating new record...", err);
  //     });
  // }

  // Accept Group request/invitation
  function markNotificationAsRead(data) {
    console.log("Data in markNotificationAsRead =", data);
    recordService
      .markNotificationAsRead(data)
      .then((response) => {
        console.log(response.data);
        if (response.data.status === "success") {
          console.log("-------------------------");
          console.log("Response from markNotificationAsRead =", response);
          console.log("-------------------------");
          //Fetch all notifications
          getAllNotifications();
        }
        if (response.data.status === "failed") {
          console.log("ERROR:", response.data.error);
          // handleAlert(response.data.error, "error");
        }
      })
      .catch((err) => {
        console.log("There is a problem in accept request...", err);
        // handleAlert("Request acceptance failed!", "error");
      });
  }

  return (
    <>
      <DashboardSectionTitle
        iconImg="offer-icon17.png"
        title="Notifications"
        pageTitle={true}
        className="mb2"
      />

      {notifications.length > 0 ? (
        <>
          {notifications.map((notification, index) => (
            <NotificationCard
              key={notification.notification_id}
              notification={notification}
              getContentType={props.getContentType}
              markNotificationAsRead={markNotificationAsRead}
            />
          ))}
        </>
      ) : (
        <SimpleMessageCard
          type="warning"
          msg="No notifications"
          allowIcon={false}
        />
      )}
    </>
  );
}

export default DashboardNotificationContent;
