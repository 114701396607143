import React from 'react'
import { Modal, Button } from "react-bootstrap";
import TermsInfo from "../../components/Terms/TermsInfo";
import PrivacyInfo from "../../components/Terms/PrivacyInfo";
import CookieInfo from "../../components/Terms/CookieInfo";

function InformationModal(props) {

  return (
    <>
      <Modal
        centered
        size="lg"
        className='theme-modal terms-info-modal'
        show={props.show.isVisible}
        onHide={props.handleClose}
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>{props.data.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="inner_page">
            {(() => {
              switch(props.data.type) {
                case 'terms': 
                  return <TermsInfo />
                case 'policy':
                  return <PrivacyInfo />
                case 'cookie':
                  return <CookieInfo />    
                default: return <p>No Data found.</p>
              }
            })()}
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button type="button" onClick={props.handleClose} className="btn_theme btn_light btn_rounded">Cancel</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default InformationModal