import React from "react";
import "./HomeInnerPageBanner.css";

function HomeInnerPageBanner(props) {
  return (
    <>
      <section
        className={`inner_banner ${props.className}`}
        style={{
          background: `url(${process.env.PUBLIC_URL}/img/about-banner.jpg) no-repeat`,
        }}
      >
        {props.children}
      </section>
    </>
  );
}

export default HomeInnerPageBanner;
