import React, { useState } from "react";
import { Collapse } from "react-bootstrap";
import { BsFillInfoCircleFill } from "react-icons/bs";
import "./DashboardSectionTitle.css";

function DashboardSectionTitle(props) {
  const [infoCollapse, setInfoCollapse] = useState(false);
  const classList = `${props.pageTitle ? "page_title" : "section_title"} ${
    props.className
  }`;
  return (
    <>
      <div className={classList}>
        {props.iconImg ? (
          <img
            src={`${process.env.PUBLIC_URL}/img/icons/${props.iconImg}`}
            className="me-3"
            alt={props.title}
            height="36px"
          />
        ) : (
          ""
        )}
        {props.title}
        {props.withInfo && (
          <BsFillInfoCircleFill
            style={{
              color: "#507ec0",
              fontSize: "20px",
              cursor: "pointer",
              position: "relative",
              top: "-5px",
            }}
            className="ms-2"
            aria-controls="info_collapse"
            aria-expanded={infoCollapse}
            onClick={() => setInfoCollapse(!infoCollapse)}
          />
        )}
      </div>
      {props.withInfo && (
        <Collapse in={infoCollapse}>
          <div
            id="info_collapse"
            className={`shadow1 border-1 mb2 text-left bg_secondary_light`}
            style={{ borderRadius: "4px" }}
          >
            <div className="text_white font-15 p2">{props.infoData}</div>
          </div>
        </Collapse>
      )}
    </>
  );
}

export default DashboardSectionTitle;
