import React, { useState, useEffect } from "react";
import { Col, Row, Accordion, Card, Form } from "react-bootstrap";
import CustomCard from "../../../ui/CustomCard/CustomCard";
import CustomAccordionGroup from "../../../ui/CustomAccordionGroup/CustomAccordionGroup";
import CustomAccordionItem from "../../../ui/CustomAccordionItem/CustomAccordionItem";
import DashboardSectionTitle from "../DashboardSectionTitle/DashboardSectionTitle";
import CreateForum from "./CreateForum";
import CreateThread from "./CreateThread";
import ForumCard from "./ForumCard";

//Service
import recordService from "../../../services/Record";
import authService from "../../../services/Auth";

function DashboardForum(props) {
  const authUser = JSON.parse(authService.getAuthUser());
  const isAdmin = authUser.user_id == 1 ? true : false;
  console.log("Executed DashboardForum", props);

  // let actions = ["create_thread", "view_threads", "edit_thread"];

  // useEffect(() => {
  //   async function getData() {
  //     if (props.forums.length == 0) {
  //       setLoading(true);
  //     }

  //     let updatedRecords;
  //     updatedRecords = await props.forums.map((record) => {
  //       return { ...record, actions: actions };
  //     });
  //     console.log("Forum data =", props.forums);
  //     props.setForums(updatedRecords);
  //     setTimeout(() => {
  //       setLoading(false);
  //     }, 1000);
  //   }

  //   getData();
  // }, []);

  // const forumThreads = [
  //   {
  //     id: "ft1",
  //     title: "Dream Interpretation",
  //     postedDate: "03/25/2021 11:27 PM",
  //     desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorum laborum amet perspiciatis iusto deleniti placeat autem distinctio quidem eveniet quis doloribus blanditiis cum nobis inventore eaque necessitatibus, magnam reiciendis recusandae vel fugit provident quae quisquam aperiam exercitationem. Tempore, ipsa voluptatem!",
  //     threadCount: 1,
  //   },
  //   {
  //     id: "ft2",
  //     title: "Technical issues",
  //     postedDate: "03/25/2021 11:27 PM",
  //     desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorum laborum amet perspiciatis iusto deleniti placeat autem distinctio quidem eveniet quis doloribus blanditiis cum nobis inventore eaque necessitatibus, magnam reiciendis recusandae vel fugit provident quae quisquam aperiam exercitationem. Tempore, ipsa voluptatem!",
  //     threadCount: 3,
  //   },
  //   {
  //     id: "ft3",
  //     title: "Test Group",
  //     postedDate: "03/25/2021 11:27 PM",
  //     desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorum laborum amet perspiciatis iusto deleniti placeat autem distinctio quidem eveniet quis doloribus blanditiis cum nobis inventore eaque necessitatibus, magnam reiciendis recusandae vel fugit provident quae quisquam aperiam exercitationem. Tempore, ipsa voluptatem!",
  //     threadCount: 2,
  //   },
  // ];

  return (
    <>
      {isAdmin && (
        <CustomAccordionGroup className="custom_accordion mt2">
          <CustomAccordionItem className="mb2 shadow1" eventKey="0">
            <Accordion.Header>
              <img
                src={`${process.env.PUBLIC_URL}/img/qa.png`}
                alt="Question-answer icon"
              />{" "}
              Create Forum
            </Accordion.Header>
            <Accordion.Body>
              <CreateForum isAddMode={true} getAllForums={props.getAllForums} />
            </Accordion.Body>
          </CustomAccordionItem>
        </CustomAccordionGroup>
      )}
      {/* <CustomCard className={`main_card shadow1 border-0 mt2 mb2`}>
        <Card.Body>
          <div className="form_style mt2">
            <Form>
              <Row className="g-0">
                <Col xs={12} sm={6}>
                  <Form.Group className="form-group rounded_corners search_field">
                    <Form.Control
                      type="text"
                      placeholder="Search"
                      style={{
                        backgroundImage: `url(${process.env.PUBLIC_URL}/img/search-icon.png)`,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center right 20px",
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} sm={6}>
                  <Form.Group className="form-group rounded_corners px-1">
                    <Form.Select
                      aria-label="Default select example"
                      className="form-control"
                    >
                      <option>Latest Discussion</option>
                      <option>Discussion 1</option>
                      <option>Discussion 1</option>
                      <option>Discussion 1</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </div>
        </Card.Body>
      </CustomCard> */}
      {/* <hr /> */}
      <div className="latest_discussion_wrap">
        <DashboardSectionTitle
          iconImg=""
          title="All Forums"
          pageTitle={false}
          className="mb2 pt1"
        />
        {props.forums?.map((forum) => {
          return (
            <ForumCard
              key={forum.forum_id}
              forum={forum}
              getAllForums={props.getAllForums}
            />
          );
        })}
      </div>
      {/* </Card.Body>
      </CustomCard> */}
    </>
  );
}

export default DashboardForum;
