import React from "react";
import { Collapse } from "react-bootstrap";

function CustomCollapseContainer(props) {
  let customClass = props.className ? props.className : "";

  function handleCollapseOnExiting() {
    if (props.cardTop) {
      window.scroll({
        top: props.cardTop,
        behavior: "smooth",
      });
    }
  }

  function handleCollapseOnEntering(e) {
    console.log("Collapse Container = ", e);
    console.log("Collapsing container = ", props.ariaControls);

    if (props.ariaControls === "view_associations") {
      props.handleAssociationList(props.record_id);
    }
    if (props.ariaControls === "view_comments") {
      props.handleCommentList(props.record_id);
    }
    if (props.ariaControls === "view_threads") {
      props.handleThreadList(props.forum_id);
    }
    if (props.ariaControls === "view_thread_comments") {
      props.handleThreadCommentList(props.forum_thread_id);
    }
    if (props.ariaControls === "group_details") {
      props.handleGroupDetails(props.group_id);
    }
    if (props.ariaControls === "add_group_user") {
      props.handleUserList();
    }
    if (props.ariaControls === "group_messages") {
      props.handleGroupCommentList(props.group_id);
    }
    if (props.ariaControls === "view_session_comments") {
      props.handleConsultationCommentList(props.consult_id);
    }
    if (props.ariaControls === "view_session_transactions") {
      props.getAllConsultationTrasactionList(props.session);
    }
    if (
      props.ariaControls === "view_details" ||
      props.ariaControls === "edit" ||
      props.ariaControls === "decode"
    ) {
      props.handleRecordDetails(props.record_id);
    }
    if (props.ariaControls === "edit_forum") {
      props.handleIsUpdating(props.forum);
      console.log("Inside edit_forum button collapse");
    }
    if (props.ariaControls === "edit_thread") {
      props.handleIsUpdating(props.thread);
      console.log("Inside edit_thread button collapse");
    }
    if (props.ariaControls === "match") {
      props.handleMatchedExperienceList(props.record_id);
      console.log("Inside Match button collapse");
    }
    if (props.ariaControls === "view_match") {
      props.getMatchedElements(props.record_id);
      console.log(
        "Inside View Match button collapse -- record_id =",
        props.record_id
      );
      console.log(
        "Inside View Match button collapse -- matchedRecords =",
        props.matchedRecords
      );
    }
  }
  return (
    <Collapse
      in={props.openCollapse}
      onEntering={handleCollapseOnEntering}
      onExiting={handleCollapseOnExiting}
    >
      <div id={props.ariaControls} className={customClass}>
        {props.children}
      </div>
    </Collapse>
  );
}

export default CustomCollapseContainer;
