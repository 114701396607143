import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Form,
  Button,
  Accordion,
  Row,
  Col,
  Spinner,
  OverlayTrigger,
  Tooltip,
  Card,
} from "react-bootstrap";
import DashboardCheckboxCard from "../DashboardCheckboxCard/DashboardCheckboxCard";
import DashboardSectionTitle from "../DashboardSectionTitle/DashboardSectionTitle";
import CustomAccordionGroup from "../../../ui/CustomAccordionGroup/CustomAccordionGroup";
import CustomAccordionItem from "../../../ui/CustomAccordionItem/CustomAccordionItem";
import CustomCard from "../../../ui/CustomCard/CustomCard";
import FieldError from "../../../ui/FieldError/FieldError";
import { Formik } from "formik";
import * as yup from "yup";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import { handleAlert, formatDateYMD } from "../../../util/util";
import { causesList, resolutionsList } from "../../../util/emoji";

//Services
import recordService from "../../../services/Record";
//Context
import AppContext from "../../../AppContext";

function RecordThoughts(props) {
  const today = formatDateYMD(new Date());
  console.log("Today =", today, "\tType =", typeof today);
  const [loading, setLoading] = useState(false);
  const [recordFormValues, setRecordFormValues] = useState({ tags: [] });
  const { getData, getUserProfileData, getAllAchievements } =
    useContext(AppContext);

  const isAddMode = props.isAddMode ? props.isAddMode : true;
  // console.log("isAddmode === ", props.isAddMode);

  const [initialValues, setInitialValues] = useState({
    title: "",
    description: "",
    tags: [],
    causes: [],
    resolutions: [],
  });

  //For Decoded Description field
  const [isDecoded, setIsDecoded] = useState(false);
  //Array of objects: Array of Dictionary words
  const [decodedDescription, setDecodedDescription] = useState([]);
  //Generated decoded string html (Tooltip html structure) elements + normal text words array
  const [decodedStr, setDecodedStr] = useState(null);

  //For other causes
  const otherCauseRef = useRef("");
  //For other resolutions
  const otherResolutionRef = useRef("");

  //useEffect for setting initialValues when component loads when change in props.recordDetails
  useEffect(() => {
    let initialValue = {};
    if (props.isAddMode == false && props.recordDetails) {
      console.log(
        "Inside useEffect If in outofbody = ",
        isAddMode,
        "\nprops.recordDetails =",
        props.recordDetails,
        "\n props.isAddMode = ",
        props.isAddMode
      );

      initialValue = {
        title: props.recordDetails.title ? props.recordDetails.title : "",
        tags: props.recordDetails.tags ? props.recordDetails.tags : [],
        emotions: props.recordDetails.emotions
          ? props.recordDetails.emotions
          : [],
        characters: props.recordDetails.characters
          ? props.recordDetails.characters
          : [],
        interactions: props.recordDetails.interactions
          ? props.recordDetails.interactions
          : [],
        locations: props.recordDetails.locations
          ? props.recordDetails.locations
          : [],
        causes: props.recordDetails.causes ? props.recordDetails.causes : [],
        resolutions: props.recordDetails.resolutions
          ? props.recordDetails.resolutions
          : [],
        description: props.recordDetails.description
          ? props.recordDetails.description
          : "",
        is_private: props.recordDetails.is_private
          ? props.recordDetails.is_private
          : false,
        is_consult_needed: props.recordDetails.is_consult_needed
          ? props.recordDetails.is_consult_needed
          : false,
      };
      setInitialValues(initialValue);

      otherCauseRef.current.value = props.recordDetails.causes
        .filter((item) => !causesList.map((e) => e.value).includes(item))
        .toString();
      otherResolutionRef.current.value = props.recordDetails.resolutions
        .filter((item) => !resolutionsList.map((e) => e.value).includes(item))
        .toString();
      // console.log("Initial Values in useEffect if loop = ", initialValues);
    }
  }, [props.recordDetails]);

  //Checking descriptionRef should not be empty...
  function checkDescription(value) {
    const descVal = value;
    if (descVal !== "") {
      console.log("Description val = ", descVal);
      return true;
    } else {
      // setDescriptionError("Description cannot be empty");
      return false;
    }
  }

  //Get decoded string
  function getDecodedString(dictionary, descVal) {
    let tempStr = [];
    let tempDesc = descVal;
    const descriptionWords = tempDesc.split(" ");
    console.log("tempDesc =", tempDesc, "\nDictionary=", dictionary);
    console.log("Desc Words =", descriptionWords, "\ntempDesc =", tempDesc);
    const dictionaryWords = dictionary.map((item) => {
      return item.word.toLowerCase();
    });
    console.log("This is word array =", dictionaryWords, " created.");
    tempStr = descriptionWords.map((word) => {
      let matchedWord = dictionary.find((dic) =>
        dic.tags
          .map((tag) => tag.toLowerCase())
          .concat([dic.word.toLowerCase()])
          .includes(word.toLowerCase().replace(/[^a-zA-Z0-9]/g, ""))
      );
      if (matchedWord) {
        return (
          <OverlayTrigger
            key={matchedWord.id}
            trigger={["focus", "click"]}
            placement="top"
            rootClose={true}
            overlay={
              <Tooltip
                id={`tooltip-${matchedWord.id}`}
                className="custom_tooltip"
              >
                <div className="tooltip_title text_primary">
                  {matchedWord.word}
                </div>
                <div className="mb-2">
                  <span className="text_primary">Short: </span>
                  {matchedWord.short_description}
                </div>
                <div>
                  <span className="text_primary">Long: </span>
                  {matchedWord.description}
                </div>
              </Tooltip>
            }
          >
            <span className="tooltip_badge">{word}</span>
          </OverlayTrigger>
        );
      } else {
        return word;
      }
    });

    console.log("decoded: ", tempStr);
    setDecodedStr(tempStr);
  }

  //Decode function
  function handleDecode(value) {
    console.log("Called function handleDecode()...", value);
    //Checking value (i.e. description fields value) should not be empty...
    if (checkDescription(value)) {
      console.log("Description is decoded");
      const descVal = { description: value };
      console.log("descVal =", descVal);
      //Calling Decode function
      recordService
        .getDecodedDictionary(descVal)
        .then((response) => {
          if (response.data.status === "success") {
            console.log("Response from Record Form : ", response.data);
            const dictionary = response.data.data.result.dictionary;
            setIsDecoded(true);
            getDecodedString(dictionary, value);
            setDecodedDescription(dictionary);
          }
          if (response.data.status === "failed") {
            console.log("ERROR:", response.data);
            setIsDecoded(false);
            setDecodedDescription([]);
          }
        })
        .catch((err) => {
          console.log("There is a problem in decoding description...", err);
        });
    } else {
      console.log("Description is empty");
      handleAlert(
        "Error! Description cannot be empty! Please enter some text for analysis",
        "error"
      );
    }
  }

  //For getting record list and updating it into context variable
  // function getData() {
  //   recordService
  //     .getAllRecords()
  //     .then((response) => {
  //       if (response.data.status === "success") {
  //         // console.log("Response from APP : ", response.data);
  //         const records = response.data.data.result.records;
  //         setExperiences(records);
  //       }
  //       if (response.data.status === "failed") {
  //         console.log("ERROR:", response.data.error);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log("There is a problem in creating new record...", err);
  //     });
  // }

  //Get Profile Stats
  // function getUserProfileData() {
  //   recordService
  //     .getUserProfileData()
  //     .then((response) => {
  //       if (response.data.status === "success") {
  //         // console.log("Response from APP : ", response.data);
  //         const stats = response.data.data.result.details;
  //         setProfileStats(stats);
  //       }
  //       if (response.data.status === "failed") {
  //         console.log("ERROR:", response.data.error);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log("There is a problem in fetching Profile Stats...", err);
  //     });
  // }

  const recordThoughtsSchema = yup.object({
    title: yup.string().max(100, "Title is too long!").required("Required"),
    description: yup.string().required("Required"),
  });

  //filters the othe field value with checkbox values and return a combined array...
  function filterOtherValues(savedOptions, optionList, otherValue) {
    let newSavedOptions = [];
    if (savedOptions.length > 0) {
      if (otherValue !== "") {
        newSavedOptions = savedOptions
          .filter((item) => optionList.map((opt) => opt.value).includes(item))
          .concat(otherValue);
      } else {
        newSavedOptions = savedOptions.filter((item) =>
          optionList.map((opt) => opt.value).includes(item)
        );
      }
    } else {
      if (otherValue !== "") newSavedOptions.push(otherValue);
    }
    return newSavedOptions;
  }

  const onSubmit = async (values, { resetForm }) => {
    console.log("Form Values :", values);
    setLoading(true);

    let final_value = Object.assign({}, initialValues);
    // final_value["date_of_occurrence"] = values["date_of_occurrence"];
    // final_value["record_type"] = values["record_type"];
    final_value["title"] = values["title"];
    final_value["description"] = values["description"];
    final_value["tags"] = values["tags"];
    if (props.isAddMode) {
      final_value["record_date"] = today;
    }

    // if (values["record_id"]) {
    //   final_value["record_id"] = values["record_id"];
    // }

    //Check whether there is a props.recordDetails ? If present then add record_id to final_value
    if (props.recordDetails?.record_id) {
      //Check isAddMode is false which is for update
      if (!props.isAddMode) {
        final_value["record_id"] = props.recordDetails.record_id;
      }
    }

    console.log("Final Values", final_value);
    values = final_value;

    const otherCauseValue = otherCauseRef?.current.value;
    const newCauses = filterOtherValues(
      values.causes,
      causesList,
      otherCauseValue
    );
    console.log("In thoughts newCauses =", newCauses);

    const otherResolutionValue = otherResolutionRef?.current.value;
    const newResolutions = filterOtherValues(
      values.resolutions,
      resolutionsList,
      otherResolutionValue
    );
    console.log("In thoughts newResolutions =", newResolutions);

    //Create newValues object which will be used as a data to the create/update record service
    const newValues = {
      ...values,
      causes: newCauses,
      resolutions: newResolutions,
      type: "thought",
      is_complete: true,
      is_private: false,
      is_consult_needed: false,
      description_decoded: decodedStr,
      dictionary_words: decodedDescription,
    };
    console.log("New Values =", newValues);

    // Update existing thought record...
    if (!props.isAddMode && newValues.hasOwnProperty("record_id")) {
      // console.log(
      //   "In the Update mode - isAddMode = ",
      //   props.isAddMode,
      //   "\n newValues = ",
      //   newValues
      // );
      console.log("You have a record...");

      //Update an existing record having id = record_id
      recordService
        .updateRecord(newValues)
        .then((response) => {
          if (response.data.status === "success") {
            console.log(response.data);

            handleAlert("Success! Record: Thought updated", "success");
            setLoading(false);
            setIsDecoded(false);
            setDecodedDescription([]);
            setDecodedStr([]);
            props.handleCollapse("edit");
            resetForm();
            // props.handleCollapse("record_outofbody");
            //fetch new context data
            getData();
            //Get all achievements
            getAllAchievements();
            //Get Profile Stats
            getUserProfileData();
          }
          if (response.data.status === "failed") {
            console.log("ERROR:", response.data.error);
            handleAlert("Error! Record: Thought updation failed", "error");
            setLoading(false);
            // resetForm();
          }
        })
        .catch((err) => {
          console.log("There is a problem in creating new record...", err);
        });
    }

    // Create new thought record...
    if (props.isAddMode && !newValues.hasOwnProperty("record_id")) {
      // console.log(
      //   "In the Create mode - isAddMode = ",
      //   props.isAddMode,
      //   "\n newValues = ",
      //   newValues
      // );
      console.log("You dont have a record...");

      //Create new record
      recordService
        .createRecord(newValues)
        .then((response) => {
          if (response.data.status === "success") {
            console.log(response.data);

            handleAlert("Success! Record: Thought created", "success");
            setLoading(false);
            setInitialValues({
              title: "",
              description: "",
              tags: [],
              causes: [],
              resolutions: [],
            });
            setIsDecoded(false);
            setDecodedDescription([]);
            setDecodedStr([]);
            resetForm();
            props.handleCollapse("record_thoughts");
            //fetch new context data
            getData();
            //Get all achievements
            getAllAchievements();
            //Get Profile Stats
            getUserProfileData();
          }
          if (response.data.status === "failed") {
            console.log("ERROR:", response.data.error);
            handleAlert("Error! Record: Thought creation failed", "error");
            setLoading(false);
            // resetForm();
          }
        })
        .catch((err) => {
          console.log("There is a problem in creating new record...", err);
        });
    }
  };

  return (
    <>
      <div>
        <Formik
          initialValues={initialValues}
          validationSchema={recordThoughtsSchema}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {(formik) => {
            return (
              <Form onSubmit={formik.handleSubmit} className="form_style">
                {/* <div className="font-14 mb2">
                  * No recorded experiences are shared until you choose to share
                  them on the share page.
                </div> */}
                <Form.Group className="form-group rounded_corners">
                  <DashboardSectionTitle
                    iconImg=""
                    title="Title"
                    pageTitle={false}
                    className="mb1 small_title"
                  />

                  <Form.Control
                    type="text"
                    placeholder=""
                    name="title"
                    {...formik.getFieldProps("title")}
                  />
                  <div className="text-end text_gray8">
                    <small>* Maximum 100 characters long</small>
                  </div>
                  <FieldError
                    valid={
                      formik.touched.title && formik.errors.title ? true : false
                    }
                    text={formik.errors.title}
                  />
                </Form.Group>

                <CustomCard className="main_card mb3 bg_primary_light2">
                  <Card.Body>
                    <Row className="align-items-center mb1">
                      <Col xs={12} sm={12} md={8} lg={8}>
                        <div className="font-14 fw_sb">Full Description</div>
                      </Col>
                      <Col xs={12} sm={12} md={4} lg={4}>
                        {/* <div className="d-flex justify-content-end">
                          <Button
                            type="button"
                            key="decode_btn"
                            className="btn btn_theme btn_small btn_secondary btn_rounded"
                            onClick={() => {
                              handleDecode(formik.values.description);
                            }}
                          >
                            Analysis
                          </Button>
                        </div> */}
                      </Col>
                    </Row>
                    <Form.Group className="form-group rounded_corners">
                      <Form.Control
                        as="textarea"
                        placeholder=""
                        name="description"
                        {...formik.getFieldProps("description")}
                      />
                      <FieldError
                        valid={
                          formik.touched.description &&
                          formik.errors.description
                            ? true
                            : false
                        }
                        text={formik.errors.description}
                      />
                    </Form.Group>

                    {isDecoded && formik.values.description != "" && (
                      <>
                        <hr />
                        <Row>
                          <Col xs={12}>
                            <div className="pb1">
                              {decodedStr?.length > 0 && (
                                <>
                                  <div className="font-14 fw_sb pb1">
                                    Analysed description:
                                  </div>
                                  {decodedStr?.map((item, index) => {
                                    return (
                                      <React.Fragment key={index}>
                                        {item}{" "}
                                      </React.Fragment>
                                    );
                                  })}
                                </>
                              )}
                            </div>
                            {decodedStr?.length < 0 && (
                              <>
                                <p className="font-14 fw_sb">
                                  No more analysed desciption to display
                                </p>
                              </>
                            )}
                          </Col>
                        </Row>
                      </>
                    )}
                  </Card.Body>
                </CustomCard>

                <Form.Group className="form-group rounded_corners">
                  <DashboardSectionTitle
                    iconImg=""
                    title="Tags"
                    pageTitle={false}
                    className="mb1 small_title"
                  />
                  <TagsInput
                    name="rt-tags"
                    id="rt-tags-input"
                    inputProps={{
                      className: "react-tagsinput-input",
                      placeholder: "Add a tag",
                      name: "rt-tags",
                      id: "rt-tags-input",
                    }}
                    value={formik.values.tags}
                    onChange={(tags) => {
                      console.log(tags);
                      formik.setFieldValue("tags", tags);
                    }}
                  />
                </Form.Group>

                <CustomAccordionGroup className="custom_accordion dashboard_accordion pt2">
                  <CustomAccordionItem className="mb2 shadow1" eventKey="0">
                    <Accordion.Header>
                      Causes
                      <span className="accordion_toggle_icon"></span>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="text-left font-14 fw_sb mb2">
                        What do you think caused this thought? (select as many
                        as apply)
                      </div>
                      {console.log(initialValues)}
                      <Row className="row row-cols-2 row-cols-md-3 g-2">
                        {causesList.map((item) => {
                          return (
                            <Col key={item.id}>
                              <DashboardCheckboxCard
                                key={item.id}
                                labelInitial="rt"
                                item={item}
                                name="causes"
                                handleChange={formik.handleChange}
                                isDisabled={false}
                                isChecked={
                                  initialValues?.causes.indexOf(item.value) > -1
                                }
                                initialValues={initialValues?.causes}
                              />
                            </Col>
                          );
                        })}
                      </Row>
                      <hr />
                      <Row className="row-cols-1">
                        <Col>
                          <div className="font-14 fw_sb mb-2">
                            Specify other (Optional)
                          </div>
                          <Form.Group className="form-group rounded_corners mb-2">
                            <Form.Control
                              type="text"
                              name="other_cause"
                              // onChange={(e) => {
                              //   console.log(e.target.value);
                              // }}
                              ref={otherCauseRef}
                              onBlur={(e) => {
                                console.log(e.target.value);
                              }}
                            />
                          </Form.Group>
                          <div className="font-13 text_gray7 fw_sb ms-3">
                            * Comma seperated list of keywords
                          </div>
                          <div className="font-13 text_gray7 fw_sb mb-2 ms-3">
                            Example: cause 1, cause 2, cause 3
                          </div>
                        </Col>
                      </Row>
                    </Accordion.Body>
                  </CustomAccordionItem>

                  <CustomAccordionItem className="mb2 shadow1" eventKey="1">
                    <Accordion.Header>
                      Resolutions
                      <span className="accordion_toggle_icon"></span>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="text-left font-14 fw_sb mb2">
                        Has the thought inspired any resolutions? (select as
                        many as apply)
                      </div>
                      <Row className="row row-cols-2 row-cols-md-3 g-2">
                        {resolutionsList.map((item) => {
                          return (
                            <Col key={item.id}>
                              <DashboardCheckboxCard
                                labelInitial="rt"
                                item={item}
                                name="resolutions"
                                handleChange={formik.handleChange}
                                isDisabled={false}
                                isChecked={
                                  initialValues?.resolutions.indexOf(
                                    item.value
                                  ) > -1
                                }
                                initialValues={initialValues?.resolutions}
                              />
                            </Col>
                          );
                        })}
                      </Row>
                      <hr />
                      <Row className="row-cols-1">
                        <Col>
                          <div className="font-14 fw_sb mb-2">
                            Specify other (Optional)
                          </div>
                          <Form.Group className="form-group rounded_corners mb-2">
                            <Form.Control
                              type="text"
                              name="other_resolution"
                              // onChange={(e) => {
                              //   console.log(e.target.value);
                              // }}
                              ref={otherResolutionRef}
                              onBlur={(e) => {
                                console.log(e.target.value);
                              }}
                            />
                          </Form.Group>
                          <div className="font-13 text_gray7 fw_sb ms-3">
                            * Comma seperated list of keywords
                          </div>
                          <div className="font-13 text_gray7 fw_sb mb-2 ms-3">
                            Example: resolution 1, resolution 2
                          </div>
                        </Col>
                      </Row>
                    </Accordion.Body>
                  </CustomAccordionItem>
                </CustomAccordionGroup>

                <div className="mt1 mb1 btn_wrap text-center">
                  <Button
                    type="submit"
                    disabled={!formik.isValid}
                    className="btn btn_theme btn_primary btn_rounded"
                  >
                    {loading ? (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />{" "}
                        Loading
                      </>
                    ) : (
                      "Save & Finish"
                    )}
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </>
  );
}

export default RecordThoughts;
