const bgcolors = [
  "#ebacf9",
  "#f2cc85",
  "#ffe0b5",
  "#a58ff7",
  "#f28a92",
  "#fcbdd2",
  "#cd6fed",
  "#ffc6d7",
  "#fc79ba",
  "#ffe3c1",
  "#c1ffaf",
  "#f7d3a0",
  "#8be7ef",
  "#f9837f",
  "#94bdf7",
  "#7ff4ba",
  "#adff87",
  "#b2f780",
  "#89ffc4",
  "#9b8fe8",
  "#fff58e",
  "#e397f4",
  "#7171ed",
  "#bfbbf7",
  "#b2ffc1",
  "#b1e7f9",
  "#89ff87",
  "#f9debb",
  "#7ef7bc",
  "#a4defc",
  "#c2d9fc",
  "#b3f8fc",
  "#f280ac",
  "#98f9e8",
  "#f9cfb8",
  "#f9a495",
  "#6c9ce2",
  "#c37ee5",
  "#f29780",
  "#9af4a9",
  "#a0ffb1",
  "#6feddc",
  "#efd27a",
  "#b162d6",
  "#a6fcb2",
  "#d5c1ff",
  "#cdc7fc",
  "#f7afc0",
  "#9fd2ea",
  "#9effe8",
  "#d1ff9e",
  "#82f2c7",
  "#ed8bcb",
  "#b3f7f9",
  "#ffd7cc",
  "#e7ff7c",
  "#ffccce",
  "#ffdfcc",
  "#f2c98c",
  "#9cfce6",
  "#9bf7e1",
  "#ea8def",
  "#e482ed",
  "#dec1ff",
  "#a1fce1",
  "#7ab2e2",
  "#ed71dc",
  "#9ff791",
  "#8a7fe8",
  "#dff981",
  "#cbffaa",
  "#f29c82",
  "#deea6e",
  "#dff78a",
  "#bbeff9",
  "#f48dcd",
  "#93b1ed",
  "#91f7e1",
  "#fc92d2",
  "#ebffa3",
  "#ccffff",
  "#8a79e0",
  "#93f486",
  "#c2cffc",
  "#bc96e8",
  "#75efd7",
  "#f4d684",
  "#a8a4f4",
  "#d3fc99",
  "#d0c6ff",
  "#8cf0f2",
  "#d1f26f",
  "#ea7091",
  "#a2daf2",
  "#ed95ca",
  "#b3dffc",
  "#ffe4b7",
  "#f4a1be",
  "#d3ffaf",
  "#f7f986",
  "#f9d38b",
  "#f2d579",
  "#f4b7a1",
  "#b9a0ff",
  "#f794ba",
  "#afffed",
  "#f4bffc",
  "#f986cd",
  "#fafc8d",
  "#f9b3e2",
  "#edb082",
  "#c6a7ef",
  "#a4fc94",
  "#fcb5da",
  "#73f4e3",
  "#f9ee72",
  "#f7b888",
  "#f9e789",
  "#a1fcf1",
  "#ffddb5",
  "#fcd7a6",
  "#f49e9c",
  "#8cb6ea",
  "#f47e7c",
  "#7efce9",
  "#efa2c5",
  "#afffc1",
  "#f7f291",
  "#e684ed",
  "#ea7c69",
  "#c7f276",
  "#ed7da8",
  "#baedff",
  "#b3ebf9",
  "#f99de7",
  "#b48bf9",
  "#f28cae",
  "#baef7a",
  "#caffb2",
  "#a9fcb2",
  "#ffadd8",
  "#b574f7",
  "#efc388",
  "#fffcba",
  "#f9d3a7",
  "#95f4e3",
  "#b4ef7c",
  "#e4bffc",
  "#f9e793",
  "#fff9bc",
  "#b6a0f7",
  "#ebc0f9",
  "#a0f7a7",
  "#b5fafc",
  "#a4a8f2",
  "#e4b5fc",
  "#fcb5be",
  "#f9aee2",
  "#dfccff",
  "#d8b0f2",
  "#d2fca6",
  "#ffbdaf",
  "#ffe5a0",
  "#fc94db",
  "#8081ed",
  "#ef6b7b",
  "#fcdc83",
  "#baecfc",
  "#98b0f2",
  "#85f7ea",
  "#d7ef6b",
  "#ccffad",
  "#ffa79e",
  "#fcaeca",
  "#74f7f5",
  "#ff77dd",
  "#9ed8ed",
  "#c6caff",
  "#8aef81",
  "#fce07b",
  "#d5b9f7",
  "#ffccce",
  "#80fccd",
  "#6f75e8",
  "#e9fca9",
  "#8fa4ef",
  "#a377e5",
  "#ffe6c6",
  "#ffe177",
  "#a2f9df",
  "#b76add",
  "#f998a2",
  "#8defc0",
  "#b2ffc1",
  "#b9ff96",
  "#e8ef83",
  "#cff293",
  "#a4fcc5",
  "#988de8",
  "#ffd1b5",
];

export { bgcolors };
