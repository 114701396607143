import React, { useState, useEffect, useContext } from "react";
import {
  Card,
  Row,
  Col,
  Form,
  InputGroup,
  Button,
  Spinner,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import CustomCard from "../../../ui/CustomCard/CustomCard";
import DashboardSectionTitle from "../DashboardSectionTitle/DashboardSectionTitle";
import { Field, Formik } from "formik";
import * as yup from "yup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { COUNTRIES } from "../../../util/countries";
import { handleAlert, formatDateYMD, calculateAge, handleCustomSelectStyles } from "../../../util/util";

import ThemeContext from "../../../ThemeContext";

//Service
import recordService from "../../../services/Record";
import FieldError from "../../../ui/FieldError/FieldError";
import { FaLessThanEqual } from "react-icons/fa";

function UpdateGeneralSettings(props) {
  const { theme } = useContext(ThemeContext);
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState({});
  const isAddMode = props.isAddMode;
  const currDate = new Date();
  //To get start date as Thu Jan 01 1970 05:30:00
  const startDate = new Date(0);
  let currYear = currDate.getFullYear();
  let startYear = startDate.getFullYear();
  let licenseYears = [];
  for (let i = currYear; i >= startYear; i--) {
    licenseYears.push(i.toString());
  }
  const languages = [
    "English",
    "Spanish",
    "German",
    "French",
    "Chinese",
    "Japanese",
    "Portuguese",
    "Indian (Hindi etc.)",
    "Arabic",
    "Other",
  ];
  const education = [
    "Certification only (no degree)",
    "Associates",
    "Bachelors",
    "Masters",
    "Doctorate",
    "MD",
  ];
  const specialties = [
    "Psychology",
    "Jungian Psychotherapy",
    "Psychotherapy",
    "Sleep Therapy",
    "Modern Psychoanalysis",
    "Neuropsychology",
    "Neurophysiology",
    "Cognitive Behavior Therapy",
    "Psychiatry",
    "Nutrition Therapy",
    "Dreamwork and Dream Analysis",
    "Emotion Focused Therapy",
    "Conflict Resolution Therapy",
    "Eye Movement Desensitization and Reprocessing (EMDR)",
    "Mindfulness-Based Cognitive Therapy (MBCT)",
    "Motivational Therapy",
    "Complementary and Alternative Medicine (CAM)",
    "Spiritualist",
    "Hypnotist",
    "Counselor",
    "Clinical Social Workers",
    "Marriage and Family Therapist",
    "Rehabilitation Counselor",
    "Other",
  ];

  //React select styles
  const customStyles = handleCustomSelectStyles(theme);
  
  // const customStyles = {
  //   control: (provided, state) => ({
  //     ...provided,
  //     border: theme === "dark" ? "1px solid #666" : "1px solid #ddd",
  //     borderRadius: "30px",
  //     minHeight: "48px",
  //     backgroundColor: theme === "dark" ? "#333" : "#fff",
  //     color: "inherit",
  //     "&:focus": {
  //       borderWidth: "1px",
  //       borderColor: "#39b3ed",
  //       boxShadow: "none",
  //     },
  //     "&:hover": {
  //       borderColor: theme === "dark" ? "#666" : "#ddd",
  //     },
  //   }),
  //   singleValue: (provided) => ({
  //     ...provided,
  //     color: "inherit",
  //   }),
  //   placeholder: (provided) => ({
  //     ...provided,
  //     color: "#ffffff",
  //   }),
  //   menuList: (provided) => ({
  //     ...provided,
  //     backgroundColor: theme === "dark" ? "#444" : "#fff",
  //     padding: 0,
  //   }),
  //   option: (provided, { isFocused, isSelected }) => ({
  //     ...provided,
  //     background: isFocused ? "#999" : isSelected ? "#0991f6" : undefined,
  //   }),
  //   valueContainer: (provided, state) => ({
  //     ...provided,
  //     padding: "2px 12px",
  //     color: "inherit",
  //     zIndex: 1,
  //   }),
  // };

  const countryOptions = COUNTRIES.map((country) => {
    return { label: country.name, value: country.name };
  });

  const languageOptions = languages.map((lang) => {
    return { label: lang, value: lang };
  });

  const educationOptions = education.map((education) => {
    return { label: education, value: education };
  });

  const specialtyOptions = specialties.map((spl) => {
    return { label: spl, value: spl };
  });

  const licenseYearOptions = licenseYears.map((year) => {
    return { label: year, value: year };
  });

  const isLicense = [
    { key: "Yes", value: true },
    { key: "No", value: false },
  ];
  const genderOptions = [
    { key: "Male", value: "male" },
    { key: "Female", value: "female" },
    { key: "Would rather not disclose", value: "other" },
  ];

  // function calculateAge(birthday) {
  //   let ageDifMs = Date.now() - birthday;
  //   let ageDate = new Date(ageDifMs); // miliseconds from epoch
  //   return Math.abs(ageDate.getUTCFullYear() - 1970);
  // }

  const initialValues = {
    firstname: "",
    lastname: "",
    url_key: "",
    date_of_birth: null,
    address1: "",
    address2: "",
    country: "",
    state: "",
    city: "",
    zipcode: "",
    gender: "Male",
    phone_no: "",
    education: "",
    is_offer_services: null,
    is_licensed: null,
    license_number: "",
    license_year: "",
    language_spoken: "",
    specialty: [],
    average_cost_per_session: 0,
    expertise: "",
  };

  const generalSettingsSchema = yup.object({
    firstname: yup.string().required("Required"),
    lastname: yup.string().required("Required"),
    date_of_birth: yup
      .date()
      .required("Required")
      .nullable()
      .test(
        "date_of_birth",
        "Should be greater than 13 years",
        function (value) {
          return calculateAge(new Date(value)) > 13;
        }
      ),
    gender: yup.string().required("Required"),
    address1: yup.string(),
    country: yup
      .string()
      .transform((_, input) => {
        // console.log('input = ',input);
        return input != null && input.toString ? input.toString() : "";
      })
      .required("Required"),
    phone_no: yup.string(),
    // is_licensed: yup.boolean().required("Required"),
    license_number: yup.string().when(["is_offer_services", "is_licensed"], {
      is: (is_offer_services, is_licensed) =>
        is_offer_services && is_licensed === "true" ? true : false,
      then: yup.string().required("Required"),
      otherwise: yup.string().transform((_, input) => {
        // console.log('input = ',input);
        return input != null && input.toString ? input.toString() : "";
      }),
    }),
    license_year: yup.string().when(["is_offer_services", "is_licensed"], {
      is: (is_offer_services, is_licensed) =>
        is_offer_services && is_licensed === "true" ? true : false,
      then: yup
        .string()
        .transform((_, input) => {
          // console.log('input = ',input);
          return input != null && input.toString ? input.toString() : "";
        })
        .required("Required"),
      otherwise: yup.string().transform((_, input) => {
        // console.log('input = ',input);
        return input != null && input.toString ? input.toString() : "";
      }),
    }),
    education: yup.string().when("is_offer_services", {
      is: true,
      then: yup
        .string()
        .transform((_, input) => {
          // console.log('input = ',input);
          return input != null && input.toString ? input.toString() : "";
        })
        .required("Required"),
      otherwise: yup.string().transform((_, input) => {
        // console.log('input = ',input);
        return input != null && input.toString ? input.toString() : "";
      }),
    }),
    language_spoken: yup.string().when("is_offer_services", {
      is: true,
      then: yup
        .string()
        .transform((_, input) => {
          // console.log('input = ',input);
          return input != null && input.toString ? input.toString() : "";
        })
        .required("Required"),
      otherwise: yup.string().transform((_, input) => {
        // console.log('input = ',input);
        return input != null && input.toString ? input.toString() : "";
      }),
    }),
    specialty: yup.string().when("is_offer_services", {
      is: true,
      then: yup
        .string()
        .transform((_, input) => {
          // console.log('input = ',input);
          return input != null && input.toString ? input.toString() : "";
        })
        .required("Required"),
      otherwise: yup.string().transform((_, input) => {
        // console.log('input = ',input);
        return input != null && input.toString ? input.toString() : "";
      })  
    }),
    average_cost_per_session: yup
      .number("Invalid amount")
      .when("is_offer_services", {
        is: true,
        then: yup
          .number("Invalid amount")
          .integer("Invalid amount")
          .required("Required"),
        otherwise: yup.number("Invalid amount"),
      }),
    expertise: yup.string().when("is_offer_services", {
      is: true,
      then: yup.string().required("Required"),
      otherwise: yup.string(),
    }),
  });

  useEffect(() => {
    if (!isAddMode && props.currentUser) {
      const tempSpecialty = props.currentUser.specialty.map((spl) => {
        return { label: spl, value: spl };
      });

      let setting_values = {
        firstname: props.currentUser.firstname
          ? props.currentUser.firstname
          : "",
        lastname: props.currentUser.lastname ? props.currentUser.lastname : "",
        // url_key: "",
        date_of_birth: new Date(
          formatDateYMD(new Date(props.currentUser.date_of_birth))
        ),
        gender: props.currentUser.gender ? props.currentUser.gender : null,
        address1: props.currentUser.address.address1
          ? props.currentUser.address.address1
          : "",
        address2: props.currentUser.address.address2
          ? props.currentUser.address.address2
          : "",
        country: props.currentUser.country
          ? [
              {
                label: props.currentUser.country,
                value: props.currentUser.country,
              },
            ]
          : [],
        state: props.currentUser.address.state
          ? props.currentUser.address.state
          : "",
        city: props.currentUser.address.city
          ? props.currentUser.address.city
          : "",
        zipcode: props.currentUser.address.zipcode
          ? props.currentUser.address.zipcode
          : "",
        phone_no: props.currentUser.phone_no ? props.currentUser.phone_no : "",
        // education: props.currentUser.education
        //   ? props.currentUser.education
        //   : "",
        education: props.currentUser.education
          ? [
              {
                label: props.currentUser.education,
                value: props.currentUser.education,
              },
            ]
          : [],
        is_offer_services: props.currentUser.is_offer_services,
        is_licensed: props.currentUser.is_licensed
          ? props.currentUser.is_licensed.toString()
          : false.toString(),
        license_number: props.currentUser.license_number
          ? props.currentUser.license_number
          : "",
        license_year: props.currentUser.license_year
          ? [
              {
                label: props.currentUser.license_year,
                value: props.currentUser.license_year,
              },
            ]
          : [],
        language_spoken: props.currentUser.language_spoken
          ? [
              {
                label: props.currentUser.language_spoken,
                value: props.currentUser.language_spoken,
              },
            ]
          : [],
        specialty: props.currentUser.specialty.length > 0 ? tempSpecialty : [],
        average_cost_per_session: props.currentUser.average_cost_per_session
          ? props.currentUser.average_cost_per_session
          : 0,
        expertise: props.currentUser.expertise
          ? props.currentUser.expertise
          : "",
      };
      setFormValues(setting_values);
    }
  }, [props.currentUser]);

  const onSubmit = async (values, { resetForm }) => {
    setLoading(true);
    console.log("Form Values :", values);

    const new_is_licensed = values.is_licensed === "true" ? true : false;
    let new_license_year = "";
    let new_language_spoken = "";
    let new_education = "";
    const new_country = Array.isArray(values.country)
      ? values.country[0].value
      : values.country.value;
    // const new_license_year = Array.isArray(values.license_year)
    //   ? values.license_year[0].value
    //   : values.license_year.value;
    // new_language_spoken = Array.isArray(values.language_spoken)
    //   ? values.language_spoken[0].value
    //   : values.language_spoken.value;
    const new_specialty = values.specialty.map((spl) => spl.value);
    const new_date_of_birth = formatDateYMD(values.date_of_birth);

    if (new_is_licensed) {
      if (values.license_year.length > 0 || values.license_year.value) {
        console.log("Have values.license_year =", values.license_year);
        new_license_year = Array.isArray(values.license_year)
          ? values.license_year[0].value
          : values.license_year.value;
        console.log("new_license_year =", new_license_year);
      } else {
        console.log("Dont have values.license_year =", values.license_year);
      }
    }
    if (values.is_offer_services) {
      if (values.language_spoken.length > 0 || values.language_spoken.value) {
        console.log("Have values.language_spoken =", values.language_spoken);
        new_language_spoken = Array.isArray(values.language_spoken)
          ? values.language_spoken[0].value
          : values.language_spoken.value;
        console.log("new_language_spoken =", new_language_spoken);
      } else {
        console.log(
          "Dont have values.language_spoken =",
          values.language_spoken
        );
      }

      if (values.education.length > 0 || values.education.value) {
        console.log("Have values.education =", values.education);
        new_education = Array.isArray(values.education)
          ? values.education[0].value
          : values.education.value;
        console.log("new_education =", new_education);
      } else {
        console.log("Dont have values.education =", values.education);
      }
    }

    const newValues = {
      ...values,
      address: {
        address1: values.address1,
        address2: values.address2,
        city: values.city,
        state: values.state,
        zipcode: values.zipcode,
        country: new_country,
      },
      country: new_country,
      date_of_birth: new_date_of_birth,
      is_licensed: new_is_licensed,
      education: new_education,
      language_spoken: new_language_spoken,
      license_year: new_license_year,
      specialty: new_specialty,
    };
    console.log("New values in Update Settings =", newValues);

    if (!isAddMode) {
      console.log("Updating existing user details");
      recordService
        .updateUserDetails(newValues)
        .then((response) => {
          if (response.data.status === "success") {
            console.log(response.data);
            handleAlert(
              "You have successfully updated user details!",
              "success"
            );
            // setFormValues({});
            resetForm({});
            setLoading(false);
            props.handleClose();
            props.getUserDetails();
          } else if (response.data.status === "failed") {
            console.log("ERROR:", response.data.error);
            handleAlert("User details updation failed!", "error");
            // setFormValues({});
            resetForm({});
            setLoading(false);
            props.handleClose();
            // props.getUserDetails();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <>
      {/* <div className="text_danger font-15">* All fields are mandatory.</div> */}
      <CustomCard className={`main_card shadow1 border-0 mt2 mb2`}>
        <Card.Body>
          <Formik
            initialValues={isAddMode ? initialValues : formValues}
            validationSchema={generalSettingsSchema}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {(formik) => {
              console.log("Formik vals = ", formik);
              return (
                <Form className="form_style" onSubmit={formik.handleSubmit}>
                  <Row className="row-cols-1 row-cols-md-2">
                    <Col>
                      <DashboardSectionTitle
                        iconImg=""
                        title="* First name"
                        pageTitle={false}
                        className="mb1 small_title"
                      />
                      <Form.Group className="form-group rounded_corners">
                        <Form.Control
                          type="text"
                          placeholder="First name"
                          name="firstname"
                          {...formik.getFieldProps("firstname")}
                          autoComplete="off"
                        />
                        <FieldError
                          valid={
                            formik.touched.firstname && formik.errors.firstname
                              ? true
                              : false
                          }
                          text={formik.errors.firstname}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <DashboardSectionTitle
                        iconImg=""
                        title="* Last name"
                        pageTitle={false}
                        className="mb1 small_title"
                      />
                      <Form.Group className="form-group rounded_corners">
                        <Form.Control
                          type="text"
                          placeholder="Last name"
                          name="lastname"
                          {...formik.getFieldProps("lastname")}
                          autoComplete="off"
                        />
                        <FieldError
                          valid={
                            formik.touched.lastname && formik.errors.lastname
                              ? true
                              : false
                          }
                          text={formik.errors.lastname}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="row-cols-1 row-cols-md-2">
                    <Col>
                      <Form.Group className="form-group rounded_corners">
                        {/* <DashboardSectionTitle
                          iconImg=""
                          title="Date of birth"
                          pageTitle={false}
                          className="mb1 small_title"
                        /> */}
                        <div className="text_mediumgray mb1 position-relative fw_sb text_gray3">
                          * Date of birth
                          <OverlayTrigger
                            trigger={["hover", "focus"]}
                            placement="top"
                            rootClose={true}
                            overlay={
                              <Tooltip
                                id="tooltip-dob"
                                className="custom_tooltip"
                              >
                                Knowing user's precise birthdays helps us
                                provide all users with accurate analytics
                                regarding particular demographic groups.
                              </Tooltip>
                            }
                          >
                            <span className="text_primary_dark fw_n text-end position-absolute end-0">
                              <small>
                                <i>Why do we collect?</i>
                              </small>
                            </span>
                          </OverlayTrigger>
                        </div>

                        <Field name="date_of_birth">
                          {({ form, field }) => {
                            const { setFieldValue } = form;
                            const { value } = field;

                            return (
                              <DatePicker
                                id="date_of_birth"
                                className="form-control"
                                {...field}
                                selected={value}
                                onChange={(val) =>
                                  setFieldValue("date_of_birth", val)
                                }
                                dateFormat="yyyy-MM-dd"
                                placeholderText="Date of birth"
                                showMonthDropdown
                                showYearDropdown
                                // scrollableMonthYearDropdown
                                dropdownMode="select"
                                autoComplete="off"
                              />
                            );
                          }}
                        </Field>
                        <div className="text-end text_gray8">
                          <small>* Age greater than 13 years</small>
                        </div>
                        <FieldError
                          valid={
                            formik.touched.date_of_birth &&
                            formik.errors.date_of_birth
                              ? true
                              : false
                          }
                          text={formik.errors.date_of_birth}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="form-group rounded_corners px-2 inline_radio_fields">
                        {/* <div className="text_mediumgray mb1">Gender</div> */}
                        {/* <DashboardSectionTitle
                          iconImg=""
                          title="Gender"
                          pageTitle={false}
                          className="mb1 small_title"
                        /> */}
                        <div className="text_mediumgray mb1 position-relative fw_sb text_gray3">
                          * Gender
                          <OverlayTrigger
                            trigger={["hover", "focus"]}
                            placement="top"
                            rootClose={true}
                            overlay={
                              <Tooltip
                                id="tooltip-gender"
                                className="custom_tooltip"
                              >
                                Knowing user's precise gender helps us provide
                                all users with accurate analytics regarding
                                particular demographic groups.
                              </Tooltip>
                            }
                          >
                            <span className="text_primary_dark fw_n text-end position-absolute end-0">
                              <small>
                                <i>Why do we collect?</i>
                              </small>
                            </span>
                          </OverlayTrigger>
                        </div>

                        <div className="">
                          {genderOptions.map((option) => {
                            return (
                              <Form.Check
                                key={option.value}
                                inline
                                label={option.key}
                                name="gender"
                                type="radio"
                                id={`gender-${option.key}`}
                                value={option.value}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                checked={
                                  formik.values?.gender ==
                                  option.value.toString()
                                }
                              />
                            );
                          })}
                        </div>
                        <FieldError
                          valid={
                            formik.touched.gender && formik.errors.gender
                              ? true
                              : false
                          }
                          text={formik.errors.gender}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="row-cols-1 row-cols-md-2">
                    <Col>
                      <DashboardSectionTitle
                        iconImg=""
                        title="Address Line 1"
                        pageTitle={false}
                        className="mb1 small_title"
                      />
                      <Form.Group className="form-group rounded_corners">
                        <Form.Control
                          type="text"
                          placeholder="Address Line 1"
                          name="address1"
                          {...formik.getFieldProps("address1")}
                          autoComplete="off"
                        />
                        <FieldError
                          valid={
                            formik.touched.address1 && formik.errors.address1
                              ? true
                              : false
                          }
                          text={formik.errors.address1}
                        />
                        {/* {formik.touched.address1 && formik.errors.address1 ? (
                          <FieldError
                            valid={true}
                            text={formik.errors.address1}
                          />
                        ) : (
                          <FieldError valid={false} text="" />
                        )} */}
                      </Form.Group>
                    </Col>
                    <Col>
                      <DashboardSectionTitle
                        iconImg=""
                        title="Address Line 2"
                        pageTitle={false}
                        className="mb1 small_title"
                      />
                      <Form.Group className="form-group rounded_corners">
                        <Form.Control
                          type="text"
                          placeholder="Address Line 2"
                          name="address2"
                          {...formik.getFieldProps("address2")}
                          autoComplete="off"
                        />

                        {/* {formik.touched.address2 && formik.errors.address2 ? (
                          <p className="error-state-text">
                            {formik.errors.address2}
                          </p>
                        ) : null} */}
                      </Form.Group>
                    </Col>
                    <Col>
                      <DashboardSectionTitle
                        iconImg=""
                        title="* Country"
                        pageTitle={false}
                        className="mb1 small_title"
                      />
                      <Form.Group className="form-group rounded_corners">
                        <Select
                          styles={customStyles}
                          placeholder="Country"
                          value={formik.values.country}
                          onChange={(value) => {
                            formik.setFieldValue("country", value);
                          }}
                          onBlur={() => {
                            formik.setFieldTouched("country", true);
                          }}
                          isMulti={false}
                          options={countryOptions}
                          name="country"
                        />
                        {formik.touched.country && formik.errors.country ? (
                          <p className="error-state-text">
                            {formik.errors.country}
                          </p>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col>
                      <DashboardSectionTitle
                        iconImg=""
                        title="State"
                        pageTitle={false}
                        className="mb1 small_title"
                      />
                      <Form.Group className="form-group rounded_corners">
                        <Form.Control
                          type="text"
                          placeholder="State"
                          name="state"
                          {...formik.getFieldProps("state")}
                          autoComplete="off"
                        />

                        {/* {formik.touched.state && formik.errors.state ? (
                          <p className="error-state-text">
                            {formik.errors.state}
                          </p>
                        ) : null} */}
                      </Form.Group>
                    </Col>
                    <Col>
                      <DashboardSectionTitle
                        iconImg=""
                        title="City"
                        pageTitle={false}
                        className="mb1 small_title"
                      />
                      <Form.Group className="form-group rounded_corners">
                        <Form.Control
                          type="text"
                          placeholder="City"
                          name="city"
                          {...formik.getFieldProps("city")}
                          autoComplete="off"
                        />
                        {/* {formik.touched.city && formik.errors.city ? (
                          <p className="error-state-text">
                            {formik.errors.city}
                          </p>
                        ) : null} */}
                      </Form.Group>
                    </Col>
                    <Col>
                      <DashboardSectionTitle
                        iconImg=""
                        title="Postal code"
                        pageTitle={false}
                        className="mb1 small_title"
                      />
                      <Form.Group className="form-group rounded_corners">
                        <Form.Control
                          type="text"
                          placeholder="Postal code"
                          name="zipcode"
                          {...formik.getFieldProps("zipcode")}
                          autoComplete="off"
                        />

                        {/* {formik.touched.zipcode && formik.errors.zipcode ? (
                          <p className="error-state-text">
                            {formik.errors.zipcode}
                          </p>
                        ) : null} */}
                      </Form.Group>
                    </Col>
                    <Col>
                      <DashboardSectionTitle
                        iconImg=""
                        title="Mobile"
                        pageTitle={false}
                        className="mb1 small_title"
                      />
                      <Form.Group className="form-group rounded_corners">
                        <Form.Control
                          type="text"
                          placeholder="Mobile"
                          name="phone_no"
                          {...formik.getFieldProps("phone_no")}
                          autoComplete="off"
                        />
                        <FieldError
                          valid={
                            formik.touched.phone_no && formik.errors.phone_no
                              ? true
                              : false
                          }
                          text={formik.errors.phone_no}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="row-cols-1">
                    <Col>
                      <Form.Group className="form-group rounded_corners bg_primary_light2 p1">
                        <Form.Check
                          type="checkbox"
                          id="is_offer_services"
                          label="Would you like to offer services to uDreamed ?"
                          name="is_offer_services"
                          {...formik.getFieldProps("is_offer_services")}
                          checked={formik.values.is_offer_services}
                        />

                        {formik.touched.is_offer_services &&
                        formik.errors.is_offer_services ? (
                          <p className="error-state-text">
                            {formik.errors.is_offer_services}
                          </p>
                        ) : null}
                      </Form.Group>
                    </Col>
                  </Row>
                  {formik.values.is_offer_services ? (
                    <>
                      <Row className="row-cols-1">
                        <Col>
                          <Form.Group className="form-group rounded_corners px-2 inline_radio_fields">
                            <DashboardSectionTitle
                              iconImg=""
                              title="Do you have a License?"
                              pageTitle={false}
                              className="mb1 small_title"
                            />
                            <div className="">
                              {isLicense.map((option) => {
                                return (
                                  <Form.Check
                                    key={option.value}
                                    inline
                                    label={option.key}
                                    name="is_licensed"
                                    type="radio"
                                    id={`isLicense-${option.key}`}
                                    value={option.value}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    checked={
                                      formik.values?.is_licensed ==
                                      option.value.toString()
                                    }
                                  />
                                );
                              })}
                            </div>
                            {/* {formik.touched.is_licensed &&
                            formik.errors.is_licensed ? (
                              <p className="error-state-text">
                                {formik.errors.is_licensed}
                              </p>
                            ) : null} */}
                          </Form.Group>
                        </Col>
                      </Row>
                      {formik.values.is_licensed == "true" ? (
                        <>
                          <Row className="row-cols-1 row-cols-md-2">
                            <Col>
                              <DashboardSectionTitle
                                iconImg=""
                                title="License Number"
                                pageTitle={false}
                                className="mb1 small_title"
                              />
                              <Form.Group className="form-group rounded_corners">
                                <Form.Control
                                  type="text"
                                  placeholder="License Number"
                                  name="license_number"
                                  {...formik.getFieldProps("license_number")}
                                  autoComplete="off"
                                />
                                <FieldError
                                  valid={
                                    formik.touched.license_number &&
                                    formik.errors.license_number
                                      ? true
                                      : false
                                  }
                                  text={formik.errors.license_number}
                                />
                              </Form.Group>
                            </Col>
                            <Col>
                              <DashboardSectionTitle
                                iconImg=""
                                title="License Year"
                                pageTitle={false}
                                className="mb1 small_title"
                              />
                              <Form.Group className="form-group rounded_corners">
                                <Select
                                  styles={customStyles}
                                  placeholder="License Year"
                                  value={formik.values.license_year}
                                  onChange={(value) => {
                                    formik.setFieldValue("license_year", value);
                                  }}
                                  onBlur={() => {
                                    formik.setFieldTouched(
                                      "license_year",
                                      true
                                    );
                                  }}
                                  isMulti={false}
                                  options={licenseYearOptions}
                                  name="license_year"
                                />
                                <FieldError
                                  valid={
                                    formik.touched.license_year &&
                                    formik.errors.license_year
                                      ? true
                                      : false
                                  }
                                  text={formik.errors.license_year}
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                        </>
                      ) : null}

                      <Row className="row-cols-1 row-cols-md-2">
                        <Col>
                          <DashboardSectionTitle
                            iconImg=""
                            title="Education"
                            pageTitle={false}
                            className="mb1 small_title"
                          />
                          <Form.Group className="form-group rounded_corners">
                            {/* <Form.Control
                              type="text"
                              placeholder="Education (Highest credential)"
                              name="education"
                              {...formik.getFieldProps("education")}
                              autoComplete="off"
                            /> */}
                            <Select
                              styles={customStyles}
                              placeholder="Education"
                              value={formik.values.education}
                              onChange={(value) => {
                                formik.setFieldValue("education", value);
                              }}
                              onBlur={() => {
                                formik.setFieldTouched("education", true);
                              }}
                              isMulti={false}
                              options={educationOptions}
                              name="language_spoken"
                            />
                            <div className="text-end text_gray8">
                              <small>* Specify Highest credential only</small>
                            </div>
                            <FieldError
                              valid={
                                formik.touched.education &&
                                formik.errors.education
                                  ? true
                                  : false
                              }
                              text={formik.errors.education}
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <DashboardSectionTitle
                            iconImg=""
                            title="Language Spoken"
                            pageTitle={false}
                            className="mb1 small_title"
                          />
                          <Form.Group className="form-group rounded_corners">
                            <Select
                              styles={customStyles}
                              placeholder="Language Spoken"
                              value={formik.values.language_spoken}
                              onChange={(value) => {
                                formik.setFieldValue("language_spoken", value);
                              }}
                              onBlur={() => {
                                formik.setFieldTouched("language_spoken", true);
                              }}
                              isMulti={false}
                              options={languageOptions}
                              name="language_spoken"
                            />
                            <FieldError
                              valid={
                                formik.touched.language_spoken &&
                                formik.errors.language_spoken
                                  ? true
                                  : false
                              }
                              text={formik.errors.language_spoken}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className="row-cols-1 row-cols-md-2">
                        <Col>
                          <DashboardSectionTitle
                            iconImg=""
                            title="Specialty"
                            pageTitle={false}
                            className="mb1 small_title"
                          />
                          <Form.Group className="form-group rounded_corners">
                            <Select
                              styles={customStyles}
                              placeholder="Specialty"
                              value={formik.values.specialty}
                              onChange={(value) => {
                                formik.setFieldValue("specialty", value);
                              }}
                              onBlur={() => {
                                formik.setFieldTouched("specialty", true);
                              }}
                              isMulti={true}
                              options={specialtyOptions}
                              name="specialty"
                            />
                            <FieldError
                              valid={
                                formik.touched.specialty &&
                                formik.errors.specialty
                                  ? true
                                  : false
                              }
                              text={formik.errors.specialty}
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <DashboardSectionTitle
                            iconImg=""
                            title="Average Cost per Session"
                            pageTitle={false}
                            className="mb1 small_title"
                          />
                          <InputGroup className="form-group rounded_corners mb0">
                            <InputGroup.Text>$</InputGroup.Text>
                            <Form.Control
                              aria-label="average_cost_per_session"
                              type="text"
                              placeholder="0"
                              name="average_cost_per_session"
                              {...formik.getFieldProps(
                                "average_cost_per_session"
                              )}
                              onChange={(e) => {
                                e.preventDefault();
                                const { value } = e.target;
                                const regex =
                                  /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;
                                if (!value || regex.test(value.toString())) {
                                  formik.setFieldValue(
                                    "average_cost_per_session",
                                    value
                                  );
                                }
                              }}
                            />
                          </InputGroup>
                          <FieldError
                            valid={
                              formik.touched.average_cost_per_session &&
                              formik.errors.average_cost_per_session
                                ? true
                                : false
                            }
                            text={formik.errors.average_cost_per_session}
                          />
                        </Col>
                      </Row>
                      <Row className="row-cols-1">
                        <Col>
                          <DashboardSectionTitle
                            iconImg=""
                            title="Expertise and services"
                            pageTitle={false}
                            className="mb1 small_title"
                          />
                          <Form.Group className="form-group rounded_corners">
                            <Form.Control
                              as="textarea"
                              rows={3}
                              placeholder="Expertise and services"
                              name="expertise"
                              {...formik.getFieldProps("expertise")}
                              autoComplete="off"
                            />
                            <FieldError
                              valid={
                                formik.touched.expertise &&
                                formik.errors.expertise
                                  ? true
                                  : false
                              }
                              text={formik.errors.expertise}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </>
                  ) : null}
                  <div className="btn_wrap pt1">
                    <Button
                      key="updateSettingsBtn"
                      type="submit"
                      // disabled={!formik.isValid}
                      className="btn_theme btn_primary btn_rounded"
                    >
                      {loading ? (
                        <>
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />{" "}
                          Processing
                        </>
                      ) : (
                        "Update Settings"
                        // <>{isAddMode ? "Save Association" : "Update Settings"}</>
                      )}
                    </Button>
                    <Button
                      key="closeModalBtn"
                      type="button"
                      className="btn_theme btn_light btn_rounded"
                      onClick={props.handleClose}
                    >
                      Cancel
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Card.Body>
      </CustomCard>
    </>
  );
}

export default UpdateGeneralSettings;
