import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Card } from "react-bootstrap";
import CustomCard from "../../ui/CustomCard/CustomCard";
import { TfiYoutube } from "react-icons/tfi";
import "./HomeFooter.css";

function HomeFooter() {
  return (
    <footer className={`index_sect footer_sect`}>
      <Container>
        <Row className="row row-cols-1 row-cols-md-3 row-cols-lg-4">
          <Col>
            <CustomCard className={`footer_card bg_transparent border-0`}>
              <Card.Body>
                <img
                  src={`${process.env.PUBLIC_URL}/img/logo-icon.png`}
                  width="190"
                  className="img-fluid mx-auto"
                  alt="uDreamed Icon"
                />
              </Card.Body>
            </CustomCard>
          </Col>
          <Col>
            <CustomCard className={`footer_card bg_transparent border-0`}>
              <Card.Body>
                <h6>About Udreamed</h6>
                <ul>
                  <li>
                    <Link to="/signup">Sign Up</Link>
                  </li>

                  <li>
                    <Link to="/about">About</Link>
                  </li>
                  {/* <li>
                    <Link to="/blogs">Blogs</Link>
                  </li> */}
                  {/* <li>
                    <Link to="/create-ad">Create Ad</Link>
                  </li> */}
                  {/* <li>
                    <Link to="/press">Press</Link>
                  </li> */}
                  <li>
                    <Link to="/privacy-policy">Privacy</Link>
                  </li>
                  <li>
                    <Link to="/terms">Terms</Link>
                  </li>
                  <li>
                    <Link to="/cookies">Cookies</Link>
                  </li>
                </ul>
              </Card.Body>
            </CustomCard>
          </Col>
          <Col>
            <CustomCard className={`footer_card bg_transparent border-0`}>
              <Card.Body>
                <h6>Contact UDreamed</h6>
                <ul>
                  <li>
                    <a href="mailto:feedback@udreamed.com">Support</a>
                  </li>

                  <li>
                    <a href="mailto:help@udreamed.com">Help Center</a>
                  </li>
                  <li>
                    <a href="mailto:developers@udreamed.com">Developers</a>
                  </li>
                </ul>
                <div className={`d-flex pt2 pb2 follow_us_wrap`}>
                  Follow us:{" "}
                  <a
                    href="https://www.youtube.com/channel/UCiujxblFduQz8V4xHjMzyzQ"
                    className="youtube_link"
                    target="_blank"
                  >
                    <TfiYoutube />
                    {/* <i className="fa fa-youtube" aria-hidden="true"></i> */}
                  </a>
                  {/* <a href="#" className="insta_link" target="_blank">
                    <i className="fa fa-instagram" aria-hidden="true"></i>
                  </a> */}
                </div>
              </Card.Body>
            </CustomCard>
          </Col>
          <Col md={12}>
            <CustomCard
              className={`footer_card copyright_info bg_transparent border-0`}
            >
              <Card.Body>
                <p>
                  &copy; Copyright 2013-{new Date().getFullYear()}. <br />
                  All Rights Reserved.
                </p>
              </Card.Body>
            </CustomCard>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default HomeFooter;
