import React from "react";
import "./HomeSectionSubHeading.css";

function HomeSectionSubHeading({ htmlTag, type, titleText, className }) {
  let typeClass;
  switch (type) {
    case "l":
      typeClass = "subheading_large";
      break;
    case "m":
      typeClass = "subheading_medium";
      break;
    case "sm":
      typeClass = "subheading_small";
      break;

    default:
      typeClass = "";
  }
  const classList =
    "section_subheading " + (className ? `${className}` : "") + " " + typeClass;
  return (
    <>
      {(() => {
        switch (htmlTag) {
          case "h4":
            return <h4 className={classList}>{titleText}</h4>;
          case "h5":
            return <h5 className={classList}>{titleText}</h5>;
          case "h6":
            return <h6 className={classList}>{titleText}</h6>;
          default:
            return <p className={classList}>{titleText}</p>;
        }
      })()}
    </>
  );
}

export default HomeSectionSubHeading;
