import React from "react";
import DashboardAdvertisement from "../DashboardAdvertisement/DashboardAdvertisement";

function DashboardRightPane() {
  return (
    <div className="adv_container">
      <DashboardAdvertisement />
      <DashboardAdvertisement />
      <DashboardAdvertisement />
    </div>
  );
}

export default DashboardRightPane;
