import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import HomeSectionHeading from "../HomeSectionHeading/HomeSectionHeading";
import HomeSectionSubHeading from "../HomeSectionHeading/HomeSectionSubHeading";
import OwlSlider from "../OwlSlider/OwlSlider";
import styles from "./HomeTestimonial.module.css";

function HomeTestimonial(props) {
  const owlSliderConfigurations = {
    margin: 15,
    loop: true,
    dots: false,
    responsiveClass: true,
    responsive: {
      0: { items: 1 },
      767: { items: 2 },
      991: { items: 3 },
    },
  };
  return (
    <>
      <div className={`index_sect ${styles.testimonials_sect}`}>
        <Container>
          <HomeSectionHeading
            htmlTag="h2"
            type="m"
            titleText="Join your colleagues, classmates, and friends on uDreamed"
            className="text-center fw_el mb1"
          />
          <HomeSectionSubHeading
            htmlTag=""
            type="sm"
            titleText="what our users say about their experiences"
            className="text-center fw_sb"
          />

          <div className="pt3">
            <OwlSlider
              data={props.testiData}
              options={owlSliderConfigurations}
            />
          </div>
        </Container>
      </div>
    </>
  );
}

export default HomeTestimonial;
