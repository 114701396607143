import React from "react";
import { Row, Col } from "react-bootstrap";
import EmojiIconCard from "../../../ui/IconCard/EmojiIconCard";
import PlaceholderCard from "../../../ui/PlaceholderCard/PlaceholderCard";
import { handleAlert, formatDateYMD } from "../../../util/util";
import {
  emotionsList,
  charactersList,
  interactionsList,
  locationsList,
  causesList,
  resolutionsList,
} from "../../../util/emoji";

function DashboardExperienceDetails(props) {
  console.log("DashboardExperienceDetails Props - ", props);
  // Date formatting
  function formattedDate(d) {
    return [d.getMonth() + 1, d.getDate(), d.getFullYear()]
      .map((n) => (n < 10 ? `0${n}` : `${n}`))
      .join("/");
  }

  function getTypeOfRecord(type) {
    if (type) {
      switch (type) {
        case "dream":
          return "Dream";
        case "event":
          return "Event";
        case "thought":
          return "Thought";
        case "outofbody":
          return "Out of Body";
        default:
          return "";
      }
    }
  }

  const type = getTypeOfRecord(props.recordDetails.type);
  // const type = props.recordDetails.type ? props.recordDetails.type : "";
  const dream_type = props.recordDetails.dream_type
    ? props.recordDetails.dream_type
    : "";
  const date = props.recordDetails.record_created_date
    ? props.recordDetails.record_created_date
    : "";
  let newDate = formatDateYMD(new Date(date));
  const tags = props.recordDetails.tags ? props.recordDetails.tags : [];
  const emotions = props.recordDetails.emotions
    ? props.recordDetails.emotions
    : [];
  const characters = props.recordDetails.characters
    ? props.recordDetails.characters
    : [];
  const interactions = props.recordDetails.interactions
    ? props.recordDetails.interactions
    : [];
  const locations = props.recordDetails.locations
    ? props.recordDetails.locations
    : [];
  const causes = props.recordDetails.causes ? props.recordDetails.causes : [];
  const resolutions = props.recordDetails.resolutions
    ? props.recordDetails.resolutions
    : [];
  const description = props.recordDetails.description
    ? props.recordDetails.description
    : "";

  return (
    <>
      {!props.recordDetails.hasOwnProperty("created_date") && (
        <>
          <PlaceholderCard type="details" />
        </>
      )}

      {type && (
        <>
          {/* <DashboardSectionTitle
            iconImg=""
            title="Type of Record"
            pageTitle={false}
            className="small_title mb1"
          /> */}
          <div className="bg_lightgray p1 font-14 fw_b mb2 border-radius-10">
            Type of Record: <span className="ps-1 fw_n">{type}</span>
          </div>
          {/* <div className="bg_lightgray p1 mb2">{type}</div> */}
          {/* <div className="bg_lightgray p1 mb2">Normal dream</div> */}
        </>
      )}

      {dream_type && (
        <>
          {/* <DashboardSectionTitle
            iconImg=""
            title="Type of Dream"
            pageTitle={false}
            className="small_title mb1"
          /> */}
          <div className="bg_lightgray p1 font-14 fw_b mb2 border-radius-10">
            Type of Dream:{" "}
            <span className="ps-1 fw_n text-capitalize">{dream_type}</span>
          </div>
          {/* <div className="bg_lightgray p1 mb2 text-capitalize">
            {dream_type}
          </div> */}
          {/* <div className="bg_lightgray p1 mb2">Normal dream</div> */}
        </>
      )}

      {date && (
        <>
          {/* <DashboardSectionTitle
            iconImg=""
            title="Date of occurance"
            pageTitle={false}
            className="small_title mb1"
          /> */}
          <div className="bg_lightgray p1 font-14 fw_b mb2 border-radius-10">
            Date of occurance:{" "}
            <span className="ps-1 fw_n text-capitalize">{date}</span>
          </div>
          {/* <div className="bg_lightgray p1 mb2">{date}</div> */}
          {/* <div className="bg_lightgray p1 mb2">{newDate}</div> */}
          {/* <div className="bg_lightgray p1 mb2">09/21/2021</div> */}
        </>
      )}

      {tags.length > 0 && (
        <>
          {/* <DashboardSectionTitle
            iconImg=""
            title="Tags"
            pageTitle={false}
            className="small_title mb1"
          /> */}
          <div className="bg_lightgray p1 font-14 fw_b mb2 border-radius-10">
            Tags: {/* <span className="ps-1 fw_n">{date}</span> */}
            {tags.map((item, index) => {
              return (
                <>
                  <span key={index} className="custom_tag tag_purple">
                    {item}
                  </span>
                </>
              );
            })}
          </div>
          {/* <div className="bg_lightgray p1 mb2">
            {tags.map((item, index) => {
              return (
                <>
                  <span key={index} className="custom_tag tag_secondary">
                    {item}
                  </span>
                </>
              );
            })}
          </div> */}
        </>
      )}

      {emotions.length > 0 && (
        <>
          {/* <DashboardSectionTitle
            iconImg=""
            title="What emotions did you feel during the dream?"
            pageTitle={false}
            className="small_title mb1"
          /> */}
          <div className="font-14 fw_b mb1">
            {/* What emotions did you feel during the dream? */}
            Emotions
          </div>
          <div className="bg_lightgray p1 mb2 border-radius-10">
            <Row className="row-cols-2 row-cols-md-3 row-cols-lg-4">
              {/* {emotions.map((item, index) => {
                return (
                  <>
                    <Col key={index}>
                      <EmojiIconCard iconGroup="emotions" iconName={item} />
                    </Col>
                  </>
                );
              })} */}
              {emotions
                .filter((item) =>
                  emotionsList.map((opt) => opt.value).includes(item)
                )
                .map((item, index) => {
                  return (
                    <>
                      <Col key={index}>
                        <EmojiIconCard iconGroup="emotions" iconName={item} />
                      </Col>
                    </>
                  );
                })}
            </Row>
            {emotions.filter(
              (item) => !emotionsList.map((opt) => opt.value).includes(item)
            ).length > 0 && (
              <div className="text-center border-top pt-2">
                <div className="fw_b font-14 mb-2">Other values: </div>
                <div>
                  {emotions
                    .filter(
                      (item) =>
                        !emotionsList.map((opt) => opt.value).includes(item)
                    )
                    .toString()
                    .trim()
                    .split(",")
                    .map((item, index) => {
                      return (
                        <>
                          {item !== "" && (
                            <span
                              key={index}
                              className="custom_tag tag_danger_light"
                            >
                              {item.trim()}
                            </span>
                          )}
                        </>
                      );
                    })}
                </div>
              </div>
            )}
          </div>
        </>
      )}

      {characters.length > 0 && (
        <>
          {/* <DashboardSectionTitle
            iconImg=""
            title="Character(s) inside the dream"
            pageTitle={false}
            className="small_title mb1"
          /> */}
          <div className="font-14 fw_b mb1">
            {/* Character(s) inside the dream */}
            Characters
          </div>
          <div className="bg_lightgray p1 mb2 border-radius-10">
            <Row className="row-cols-2 row-cols-md-3 row-cols-lg-4">
              {/* {characters.map((item, index) => {
                return (
                  <>
                    <Col key={index}>
                      <EmojiIconCard iconGroup="characters" iconName={item} />
                    </Col>
                  </>
                );
              })} */}
              {characters
                .filter((item) =>
                  charactersList.map((opt) => opt.value).includes(item)
                )
                .map((item, index) => {
                  return (
                    <>
                      <Col key={index}>
                        <EmojiIconCard iconGroup="characters" iconName={item} />
                      </Col>
                    </>
                  );
                })}
            </Row>
            {characters.filter(
              (item) => !charactersList.map((opt) => opt.value).includes(item)
            ).length > 0 && (
              <div className="text-center border-top pt-2">
                <div className="fw_b font-14 mb-2">Other values: </div>
                <div>
                  {characters
                    .filter(
                      (item) =>
                        !charactersList.map((opt) => opt.value).includes(item)
                    )
                    .toString()
                    .trim()
                    .split(",")
                    .map((item, index) => {
                      return (
                        <>
                          {item !== "" && (
                            <span
                              key={index}
                              className="custom_tag tag_danger_light"
                            >
                              {item.trim()}
                            </span>
                          )}
                        </>
                      );
                    })}
                </div>
              </div>
            )}
          </div>
        </>
      )}

      {interactions.length > 0 && (
        <>
          {/* <DashboardSectionTitle
            iconImg=""
            title="Interactions between characters"
            pageTitle={false}
            className="small_title mb1"
          /> */}
          <div className="font-14 fw_b mb1">
            Interactions between characters
            {/* Interactions */}
          </div>
          <div className="bg_lightgray p1 mb2 border-radius-10">
            <Row className="row-cols-2 row-cols-md-3 row-cols-lg-4">
              {/* {interactions.map((item, index) => {
                return (
                  <>
                    <Col key={index}>
                      <EmojiIconCard iconGroup="interactions" iconName={item} />
                    </Col>
                  </>
                );
              })} */}
              {interactions
                .filter((item) =>
                  interactionsList.map((opt) => opt.value).includes(item)
                )
                .map((item, index) => {
                  return (
                    <>
                      <Col key={index}>
                        <EmojiIconCard
                          iconGroup="interactions"
                          iconName={item}
                        />
                      </Col>
                    </>
                  );
                })}
            </Row>
            {interactions.filter(
              (item) => !interactionsList.map((opt) => opt.value).includes(item)
            ).length > 0 && (
              <div className="text-center border-top pt-2">
                <div className="fw_b font-14 mb-2">Other values: </div>
                <div>
                  {interactions
                    .filter(
                      (item) =>
                        !interactionsList.map((opt) => opt.value).includes(item)
                    )
                    .toString()
                    .trim()
                    .split(",")
                    .map((item, index) => {
                      return (
                        <>
                          {item !== "" && (
                            <span
                              key={index}
                              className="custom_tag tag_danger_light"
                            >
                              {item.trim()}
                            </span>
                          )}
                        </>
                      );
                    })}
                </div>
              </div>
            )}
          </div>
        </>
      )}

      {locations.length > 0 && (
        <>
          {/* <DashboardSectionTitle
            iconImg=""
            title="Location(s) inside the dream (select as many as apply)"
            pageTitle={false}
            className="small_title mb1"
          /> */}
          <div className="font-14 fw_b mb1">
            {/* Location(s) inside the dream (select as many as apply) */}
            Locations
          </div>
          <div className="bg_lightgray p1 mb2 border-radius-10">
            <Row className="row-cols-2 row-cols-md-3 row-cols-lg-4">
              {/* {locations.map((item, index) => {
                return (
                  <>
                    <Col key={index}>
                      <EmojiIconCard iconGroup="locations" iconName={item} />
                    </Col>
                  </>
                );
              })} */}
              {locations
                .filter((item) =>
                  locationsList.map((opt) => opt.value).includes(item)
                )
                .map((item, index) => {
                  return (
                    <>
                      <Col key={index}>
                        <EmojiIconCard iconGroup="locations" iconName={item} />
                      </Col>
                    </>
                  );
                })}
            </Row>
            {locations.filter(
              (item) => !locationsList.map((opt) => opt.value).includes(item)
            ).length > 0 && (
              <div className="text-center border-top pt-2">
                <div className="fw_b font-14 mb-2">Other values: </div>
                <div>
                  {locations
                    .filter(
                      (item) =>
                        !locationsList.map((opt) => opt.value).includes(item)
                    )
                    .toString()
                    .trim()
                    .split(",")
                    .map((item, index) => {
                      return (
                        <>
                          {item !== "" && (
                            <span
                              key={index}
                              className="custom_tag tag_danger_light"
                            >
                              {item.trim()}
                            </span>
                          )}
                        </>
                      );
                    })}
                </div>
              </div>
            )}
          </div>
        </>
      )}

      {causes.length > 0 && (
        <>
          {/* <DashboardSectionTitle
            iconImg=""
            title="What do you think caused this dream?"
            pageTitle={false}
            className="small_title mb1"
          /> */}
          <div className="font-14 fw_b mb1">
            {/* What do you think caused this dream? */}
            Causes
          </div>
          <div className="bg_lightgray p1 mb2 border-radius-10">
            <Row className="row-cols-2 row-cols-md-3 row-cols-lg-4">
              {/* {causes.map((item, index) => {
                return (
                  <>
                    <Col key={index}>
                      <EmojiIconCard iconGroup="causes" iconName={item} />
                    </Col>
                  </>
                );
              })} */}
              {causes
                .filter((item) =>
                  causesList.map((opt) => opt.value).includes(item)
                )
                .map((item, index) => {
                  return (
                    <>
                      <Col key={index}>
                        <EmojiIconCard iconGroup="causes" iconName={item} />
                      </Col>
                    </>
                  );
                })}
            </Row>
            {causes.filter(
              (item) => !causesList.map((opt) => opt.value).includes(item)
            ).length > 0 && (
              <div className="text-center border-top pt-2">
                <div className="fw_b font-14 mb-2">Other values: </div>
                <div>
                  {causes
                    .filter(
                      (item) =>
                        !causesList.map((opt) => opt.value).includes(item)
                    )
                    .toString()
                    .trim()
                    .split(",")
                    .map((item, index) => {
                      return (
                        <>
                          {item !== "" && (
                            <span
                              key={index}
                              className="custom_tag tag_danger_light"
                            >
                              {item.trim()}
                            </span>
                          )}
                        </>
                      );
                    })}
                </div>
              </div>
            )}
          </div>
        </>
      )}

      {resolutions.length > 0 && (
        <>
          {/* <DashboardSectionTitle
            iconImg=""
            title="Has the dream inspired any resolutions? (select as many as apply)"
            pageTitle={false}
            className="small_title mb1"
          /> */}
          <div className="font-14 fw_b mb1">
            {/* Has the dream inspired any resolutions? (select as many as apply) */}
            Resolutions
          </div>
          <div className="bg_lightgray p1 mb2 border-radius-10">
            <Row className="row-cols-2 row-cols-md-3 row-cols-lg-4">
              {/* {resolutions.map((item, index) => {
                return (
                  <>
                    <Col key={index}>
                      <EmojiIconCard iconGroup="resolutions" iconName={item} />
                    </Col>
                  </>
                );
              })} */}
              {resolutions
                .filter((item) =>
                  resolutionsList.map((opt) => opt.value).includes(item)
                )
                .map((item, index) => {
                  return (
                    <>
                      <Col key={index}>
                        <EmojiIconCard
                          iconGroup="resolutions"
                          iconName={item}
                        />
                      </Col>
                    </>
                  );
                })}
            </Row>
            {resolutions.filter(
              (item) => !resolutionsList.map((opt) => opt.value).includes(item)
            ).length > 0 && (
              <div className="text-center border-top pt-2">
                <div className="fw_b font-14 mb-2">Other values: </div>
                <div>
                  {resolutions
                    .filter(
                      (item) =>
                        !resolutionsList.map((opt) => opt.value).includes(item)
                    )
                    .toString()
                    .trim()
                    .split(",")
                    .map((item, index) => {
                      return (
                        <>
                          {item !== "" && (
                            <span
                              key={index}
                              className="custom_tag tag_danger_light"
                            >
                              {item.trim()}
                            </span>
                          )}
                        </>
                      );
                    })}
                </div>
              </div>
            )}
          </div>
        </>
      )}

      {/* <DashboardSectionTitle
        iconImg=""
        title="Character(s) inside the dream?"
        pageTitle={false}
        className="small_title mb1"
      />
      <div className="bg_lightgray p1 mb2">
        <Row>
          {characterList.map((item) => {
            return (
              <Col key={item.id} xs={12} sm={6} md={4} lg={3}>
                <DashboardCheckboxCard item={item} isDisabled={false} />
              </Col>
            );
          })}
        </Row>
      </div> */}

      {description && (
        <>
          {/* <DashboardSectionTitle
            iconImg=""
            title="What do you think the dream meant?"
            pageTitle={false}
            className="small_title mb1"
          /> */}
          <div className="font-14 fw_b mb1">Full description</div>
          <div className="bg_lightgray p1 border-radius-10">{description}</div>
        </>
      )}
    </>
  );
}

export default DashboardExperienceDetails;
