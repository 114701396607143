import React, { useState, useEffect, useContext } from "react";
import { Nav, Tab } from "react-bootstrap";
import CustomCard from "../../../ui/CustomCard/CustomCard";

import DashboardSectionTitle from "../DashboardSectionTitle/DashboardSectionTitle";
import DashboardAnalyzeSearch from "./DashboardAnalyzeSearch";
import DashboardAnalyzePattern from "./DashboardAnalyzePattern";
import DashboardAnalyzeExplore from "./DashboardAnalyzeExplore";
import { formatDateYMD } from "../../../util/util";

//Context
import AppContext from "../../../AppContext";

//Service
import recordService from "../../../services/Record";

function DashboardAnalyzeContent() {
  const { setProfileStats } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [activeKey, setActiveKey] = useState("analyze_search");
  const [analyzedPatterns, setAnalyzedPatterns] = useState({});
  const [analyzedExplore, setAnalyzedExplore] = useState({});
  const [analyzedExperiences, setAnalyzedExperiences] = useState([]);
  const today = formatDateYMD(new Date());
  
  useEffect(() => {
    console.log("analyzedExperiences useeffect");
    
    getAnalyzeSearch({
      record_type: "all",
      target: "global",
      // end_date: "2022-05-31",
      end_date: today,
      start_date: "2021-02-28",
      keyword: "",
    });

    const newValues = {
      record_type: "dream",
      target: "global",
      element: "emotions",
      // end_date: "2022-05-31",
      end_date: today,
      start_date: "2021-02-28",
    };
    getAnalyzePattern(newValues);
  }, []);

  //Get Profile Stats
  function getUserProfileData() {
    recordService
      .getUserProfileData()
      .then((response) => {
        if (response.data.status === "success") {
          // console.log("Response from APP : ", response.data);
          const stats = response.data.data.result.details;
          setProfileStats(stats);
        }
        if (response.data.status === "failed") {
          console.log("ERROR:", response.data.error);
        }
      })
      .catch((err) => {
        console.log("There is a problem in fetching Profile Stats...", err);
      });
  }

  async function getAnalyzeSearch(newValues) {
    recordService
      .analyzeSearch(newValues)
      .then((response) => {
        if (response.data.status === "success") {
          // console.log("Analyze service res = ", response.data);
          // handleAlert(
          //   "You have successfully created an association!",
          //   "success"
          // );
          const records = response.data.data.result.records;
          // console.log("records = ", records);
          // let updatedRes = records.map((record) => {
          //   return { ...record, record_type: "dream" };
          // });
          // console.log("updatedRes = ", updatedRes);
          // setAnalyzedExperiences(updatedRes);
          setAnalyzedExperiences(records);
          setLoading(false);
          //Get Profile Stats
          getUserProfileData();
          // resetForm({});
        } else if (response.data.status === "failed") {
          console.log("ERROR:", response.data.error);
          // handleAlert("Association creation failed!", "error");
          setLoading(false);
          setAnalyzedExperiences([]);
          // resetForm({});
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function getAnalyzeExplore(newValues) {
    console.log("---getAnalyzeExplore newValues = ", newValues);
    recordService
      .analyzeExplore(newValues)
      .then((response) => {
        if (response.data.status === "success") {
          console.log("Analyze Explore service res = ", response.data);

          const chart_data = response.data.data.result.chart_data;
          console.log("records = ", chart_data);
          console.log("Labels = ", response.data.data.result.chart_data.labels);

          let temp_chart_data = {
            ...response.data.data.result,
            chart_data: {
              ...chart_data,
              target: "global",
              chart: "bar",
              element: "emotions",
            },
          };
          console.log(
            "I am from AnalyzeContent Explore, temp_chart_data in onSubmit =",
            temp_chart_data
          );
          
          setAnalyzedExplore(temp_chart_data);
          // setAnalyzedExploreFilters({
          //   record_type: recordRadioOptions.filter(opt => opt.value === newValues.record_type).pop().key,
          //   target: targetRadioOptions
          //     .filter((option) => option.value === values.target)
          //     .pop().key,
          //   end_date: newEndDate,
          //   start_date: newStartDate,
          //   element: newElement.charAt(0).toUpperCase() + newElement.slice(1),
          //   sub_cats: new_sub_cats,
          //   age: [minAge, maxAge],
          // });
          
          setLoading(false);
          //Get Profile Stats
          // getUserProfileData();
          
        } else if (response.data.status === "failed") {
          console.log("ERROR:", response.data.error);

          setLoading(false);
          setAnalyzedExplore({});
          // setAnalyzedExploreFilters({
          //   record_type: "Dream",
          //   target: "Global",
          //   end_date: today,
          //   start_date: "2021-02-28",
          //   element: "Emotions",
          //   sub_cats: [],
          //   age: [],
          // });
          
        }
      })
      .catch((err) => {
        console.log(err);
      });
    
  }

  function getAnalyzePattern(newValues) {
    console.log("---getAnalyzePattern newValues = ", newValues);
    recordService
      .analyzePattern(newValues)
      .then((response) => {
        if (response.data.status === "success") {
          // console.log("---Analyze pattern service res = ", response.data);

          const chart_data = response.data.data.result.chart_data;
          const patterns = response.data.data.result.patterns;
          
          let temp_chart_data = {
            ...response.data.data.result,
            chart_data: {
              ...chart_data,
              target: "global",
              chart: "bar",
              element: "emotions",
            },
          };
          // console.log(
          //   "I am from AnalyzeContent Pattern, temp_chart_data in onSubmit =",
          //   temp_chart_data
          // );
          setAnalyzedPatterns(temp_chart_data);
          
          setLoading(false);
          //Get Profile Stats
          getUserProfileData();
        } else if (response.data.status === "failed") {
          console.log("ERROR:", response.data.error);

          setLoading(false);
          setAnalyzedPatterns({});
          // setAnalyzedPatternsFilters({
          //   record: "dream",
          //   target: "global",
          //   end_date: "",
          //   start_date: "",
          //   element: "emotions",
          // });
          // setRecordCount(0);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const handleSelectedTab = (key) => {
    console.log("Key in handleSelectedTab =", key);
    if (key == "analyze_search") {
      console.log("Entering into analyze_search");
      const newValues = {
        record_type: "all",
        target: "global",
        // end_date: "2022-05-31",
        end_date: today,
        start_date: "2021-02-28",
        keyword: "",
      };
      getAnalyzeSearch(newValues);
    }
    if (key == "analyze_pattern") {
      console.log("Entering into analyze_pattern");
      const newValues = {
        record_type: "dream",
        target: "global",
        element: "emotions",
        // end_date: "2022-05-31",
        end_date: today,
        start_date: "2021-02-28",
      };
      getAnalyzePattern(newValues);
    }
    if (key == "analyze_explore") {
      console.log("Entering into analyze_explore");
      const newValues = {
        record_type: "all",
        target: "global",
        end_date: today,
        start_date: "2021-02-28",
        element: "emotions",
        sub_cats: ["happiness", "confusion", "anxiety", "sadness", "frustration", "helplessness", "love", "fear", "surprise", "shame", "desire", "guilt", "no emotion", "anger", "jealousy"],
        age: [13, 100], 
        country: "all",
        education: "all",
        gender: "all" 
      };
      getAnalyzeExplore(newValues);
    }

    setActiveKey(key);
  };

  return (
    <>
      <DashboardSectionTitle
        iconImg="offer-icon3.png"
        title="Analyze"
        pageTitle={true}
        className="mb2"
        withInfo={true}
        infoData="Analyze, compare, and discover trends in recorded experiences from your own experience bank, your friends (anonymized) and the globe."
      />

      <CustomCard className={`main_card border-0 mb2`}>
        <div className="analyze_tab">
          <Tab.Container
            id="analyze-tabs-example"
            className="main_tabs"
            activeKey={activeKey}
            onSelect={(key) => handleSelectedTab(key)}
          >
            <div className="tab_links">
              <Nav variant="pills" className="nav-justified">
                <Nav.Item>
                  <Nav.Link className="normal_link" eventKey="analyze_search">
                    <div class="">Search</div>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link className="normal_link" eventKey="analyze_pattern">
                    <div class="">Pattern</div>
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link className="normal_link" eventKey="analyze_explore">
                    <div class="">Explore</div>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
            <Tab.Content>
              <Tab.Pane className="p2" eventKey="analyze_search" unmountOnExit>
                <DashboardAnalyzeSearch
                  analyzedExperiences={analyzedExperiences}
                  setAnalyzedExperiences={setAnalyzedExperiences}
                />
              </Tab.Pane>

              <Tab.Pane className="p2" eventKey="analyze_pattern" unmountOnExit>
                <DashboardAnalyzePattern
                  analyzedPatterns={analyzedPatterns}
                  setAnalyzedPatterns={setAnalyzedPatterns}
                  getUserProfileData={getUserProfileData}
                />
              </Tab.Pane>
              <Tab.Pane className="p2" eventKey="analyze_explore">
                <DashboardAnalyzeExplore
                  analyzedExplore={analyzedExplore}
                  setAnalyzedExplore={setAnalyzedExplore}
                  getUserProfileData={getUserProfileData}
                />
                {/* <Row className="mt2">
									{groupList.map((item, index) => (
										<Col xs={12}>
											<DashboardNetworkGroupCard key={index} group={item} />
										</Col>
									))}
								</Row> */}
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </CustomCard>
    </>
  );
}

export default DashboardAnalyzeContent;
