import React, { useState, useEffect, useContext } from "react";
import {
  Col,
  Row,
  Card,
  Button,
  Spinner,
  Form,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import DashboardSectionTitle from "../DashboardSectionTitle/DashboardSectionTitle";
import CustomCard from "../../../ui/CustomCard/CustomCard";
import SpeedometerChart from "./SpeedometerChart";
import PlaceholderCard from "../../../ui/PlaceholderCard/PlaceholderCard";
import SimpleMessageCard from "../../../ui/SimpleMessageCard/SimpleMessageCard";
import { Field, Formik } from "formik";
import * as yup from "yup";
import DatePicker from "react-datepicker";
import { addDays, subDays } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import { formatDateYMD } from "../../../util/util";
import { BsFillExclamationTriangleFill } from "react-icons/bs";

//service
import recordService from "../../../services/Record";

//Context
import ThemeContext from "../../../ThemeContext";

function UsefulMetricsContent(props) {
  const { theme } = useContext(ThemeContext);
  const [loading, setLoading] = useState(false);
  const [speedometerChartData, setSpeedometerChartData] = useState([]);
  const [isMobile, setIsMobile] = useState(false);
  const today = formatDateYMD(new Date());
  let speedoWidth = 120;
  let speedoHeight = 100;
  let speedoRingWidth = 8;
  const speedometerConfig = {
    textColor: theme === 'dark' ? '#fff' : "#666",
    needleColor: theme === 'dark' ? '#fff' : "#666",
    needleHeightRatio: 0.5,
    width: speedoWidth,
    height: speedoHeight,
    minValue: 0,
    maxValue: 100,
    labelFontSize: "11px",
    valueTextFontSize: "13px",
    valueTextFontWeight: "bold",
    ringWidth: speedoRingWidth,
    startColor: "#ef6e46",
    maxSegmentLabels: 6,
    segments: 6,
    endColor: "#1cef58",
  };

  //   const chart_labels = props.usefulMetricsData.hasOwnProperty('labels') ? props.usefulMetricsData.labels : [];
  //     const me_data = props.usefulMetricsData.values.hasOwnProperty('me') ? props.usefulMetricsData.values.me : [];
  //     const friend_data = props.usefulMetricsData.values.hasOwnProperty('friends') ? props.usefulMetricsData.values.friends : [];
  //     const global_data = props.usefulMetricsData.values.hasOwnProperty('global') ? props.usefulMetricsData.values.global : [];

  useEffect(() => {
    console.log("in useEffect =", props.usefulMetricsData);

    const chart_labels = props.usefulMetricsData.hasOwnProperty("labels")
      ? props.usefulMetricsData.labels
      : [];
    const me_data = props.usefulMetricsData.values?.hasOwnProperty("me")
      ? props.usefulMetricsData.values.me
      : [];
    const friend_data = props.usefulMetricsData.values?.hasOwnProperty(
      "friends"
    )
      ? props.usefulMetricsData.values.friends
      : [];
    const global_data = props.usefulMetricsData.values?.hasOwnProperty("global")
      ? props.usefulMetricsData.values.global
      : [];

    const metrics_data =
      props.usefulMetricsData.length > 0 ? props.usefulMetricsData : [];

    // if (props.usefulMetricsData.hasOwnProperty("values")) {
    //   createSpeedometerChartData(
    //     chart_labels,
    //     me_data,
    //     friend_data,
    //     global_data
    //   );
    // }
    createSpeedometerChartData(metrics_data);
  }, [props.usefulMetricsData]);

  // const createSpeedometerChartData = (
  //   chart_labels,
  //   me_data,
  //   friend_data,
  //   global_data
  // ) => {
  //   let tempDataSets = [];
  //   if (chart_labels.length > 0) {
  //     for (let i = 0; i < 5; i++) {
  //       tempDataSets.push({
  //         label: chart_labels[i],
  //         me: me_data[i],
  //         friends: friend_data[i],
  //         global: global_data[i],
  //       });
  //     }
  //     if (tempDataSets.length > 0) {
  //       setSpeedometerChartData(tempDataSets);
  //     }
  //   }
  // };

  const createSpeedometerChartData = (metrics_data) => {
    console.log("in createSpeedometerChartData in start =", metrics_data);
    let tempDataSets = [];
    if (metrics_data.length > 0) {
      tempDataSets = metrics_data.map((item) => {
        return {
          label: item.title,
          // me: item.stats.me,
          // friends: item.stats.friends,
          // global: item.stats.global,
          me: item.percents?.me?.percent
            ? Math.round(item.percents.me.percent)
            : 0,
          friends: item.percents?.friends?.percent
            ? Math.round(item.percents.friends.percent)
            : 0,
          global: item.percents?.global?.percent
            ? Math.round(item.percents.global.percent)
            : 0,
          // tags: item.available_tags,
          tags: item.tags,
        };
      });

      if (tempDataSets.length > 0) {
        console.log("in createSpeedometerChartData in end =", tempDataSets);
        setSpeedometerChartData(tempDataSets);
      }
    }
  };

  const initialValues = {
    start_date: new Date("2021-02-28"),
    end_date: new Date(today),
  };
  const usefulMetricsFilterSchema = yup.object({
    start_date: yup.date().required("Required").nullable(),
    end_date: yup.date().required("Required").nullable(),
  });

  const onSubmit = async (values, { resetForm }) => {
    setLoading(true);
    console.log("Dashboard Useful metrics Form values: ", values);
    let newStartDate = formatDateYMD(values.start_date);
    let newEndDate = formatDateYMD(values.end_date);

    const newValues = {
      ...values,
      start_date: newStartDate,
      end_date: newEndDate,
    };
    console.log("Dashboard Useful metrics New values: ", newValues);

    recordService
      .getUsefulMetricsData(newValues)
      .then((response) => {
        if (response.data.status === "success") {
          console.log(
            "Response from getUsefulMetricsData in MetricsContent : ",
            response.data
          );
          const stats = response.data.data.result.stats;
          props.setUsefulMetricsData(stats);
          props.setLoading(false);
          setLoading(false);
        }
        if (response.data.status === "failed") {
          console.log("ERROR:", response.data.error);
          props.setUsefulMetricsData([]);
          props.setLoading(false);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log("There is a problem in creating new record...", err);
        props.setLoading(false);
      });

    // handleAlert("Error! Functionality under development.", "error");
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={usefulMetricsFilterSchema}
        onSubmit={onSubmit}
        // enableReinitialize
      >
        {(formik) => {
          return (
            <Form className="form_style" onSubmit={formik.handleSubmit}>
              <Row className="rwo-cols-1 row-cols-sm-3 g-2">
                <Col>
                  <Form.Group className="form-group small_field rounded_corners">
                    <DashboardSectionTitle
                      iconImg=""
                      title="Start date"
                      pageTitle={false}
                      className="mb-1 small_title"
                    />
                    <Field name="start_date" size="sm">
                      {({ form, field }) => {
                        const { setFieldValue } = form;
                        const { value } = field;

                        return (
                          <DatePicker
                            id="start_date"
                            className="form-control"
                            {...field}
                            selected={value}
                            onChange={(val) => setFieldValue("start_date", val)}
                            dateFormat="yyyy-MM-dd"
                            maxDate={addDays(
                              new Date(formik.values.end_date),
                              0
                            )}
                            placeholderText="Start Date"
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            // scrollableMonthYearDropdown
                            autoComplete="off"
                          />
                        );
                      }}
                    </Field>
                    {formik.touched.start_date && formik.errors.start_date ? (
                      <p className="error-state-text">
                        {formik.errors.start_date}
                      </p>
                    ) : null}
                  </Form.Group>
                </Col>

                <Col>
                  <Form.Group className="form-group small_field rounded_corners">
                    <DashboardSectionTitle
                      iconImg=""
                      title="End date"
                      pageTitle={false}
                      className="mb-1 small_title"
                    />
                    <Field name="end_date">
                      {({ form, field }) => {
                        const { setFieldValue } = form;
                        const { value } = field;

                        return (
                          <DatePicker
                            id="end_date"
                            className="form-control"
                            {...field}
                            todayButton="Today"
                            selected={value}
                            onChange={(val) => setFieldValue("end_date", val)}
                            dateFormat="yyyy-MM-dd"
                            minDate={subDays(
                              new Date(formik.values.start_date),
                              0
                            )}
                            placeholderText="End date"
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            // scrollableMonthYearDropdown
                            autoComplete="off"
                          />
                        );
                      }}
                    </Field>
                    {formik.touched.end_date && formik.errors.end_date ? (
                      <p className="error-state-text">
                        {formik.errors.end_date}
                      </p>
                    ) : null}
                  </Form.Group>
                </Col>

                <Col>
                  <DashboardSectionTitle
                    iconImg=""
                    title="&nbsp;"
                    pageTitle={false}
                    className="mb-1 small_title"
                  />
                  <Button
                    key="submit_btn"
                    type="submit"
                    className="btn_theme btn_primary btn_small btn_rounded w-100"
                  >
                    {loading ? (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />{" "}
                        Processing
                      </>
                    ) : (
                      "Submit"
                    )}
                  </Button>
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>

      <Row className="gy-2 gx-0 row-cols-1 border-top pt1 mt-1">
        
        {!props.loading && speedometerChartData.length > 0 ? (
          speedometerChartData.map((item, index) => (
            <Col key={index}>
              <div
                className={`pt0 pb1 ${
                  index < speedometerChartData.length - 1 ? "border-bottom" : ""
                } border-0 rounded-0`}
              >
                <div className="text-center pt1 pb2">
                  <span className="custom_tag tag_golden">{item.label}</span>
                </div>
                <Row className="row-cols-3 gx-0 gy-2">
                  <Col className="d-flex justify-content-center">
                    <SpeedometerChart
                      speedometerConfig={speedometerConfig}
                      value={item.me}
                      currentValueText="Me"
                    />
                  </Col>
                  <Col className="d-flex justify-content-center">
                    <SpeedometerChart
                      speedometerConfig={speedometerConfig}
                      value={item.friends}
                      currentValueText="Friends"
                    />
                  </Col>
                  <Col className="d-flex justify-content-center">
                    <SpeedometerChart
                      speedometerConfig={speedometerConfig}
                      value={item.global}
                      currentValueText="Global"
                    />
                  </Col>
                </Row>
                <div className="text-center fw_sb py-2">
                  {item.label === "Emotions" && (
                    <div>
                      Percent of{" "}
                      <OverlayTrigger
                        trigger={["hover", "focus"]}
                        placement="top"
                        rootClose={true}
                        overlay={
                          <Tooltip
                            id="tooltip-emotions"
                            className="custom_tooltip"
                          >
                            {item.tags.length > 0 ? (
                              <>
                                {item.tags.map((tag, index) => {
                                  let val =
                                    index < item.tags.length - 1
                                      ? tag + ", "
                                      : tag;
                                  return val;
                                })}
                              </>
                            ) : (
                              "Data not found"
                            )}
                            
                          </Tooltip>
                        }
                      >
                        <span className="fw_b text_primary_dark mb-0">
                          positive emotions
                        </span>
                      </OverlayTrigger>{" "}
                      in recorded experiences.
                    </div>
                  )}
                  {item.label === "Positive Casualities" && (
                    <div>
                      Percent of recorded experiences prompted by{" "}
                      <OverlayTrigger
                        trigger={["hover", "focus"]}
                        placement="top"
                        rootClose={true}
                        overlay={
                          <Tooltip
                            id="tooltip-casualities"
                            className="custom_tooltip"
                          >
                            {item.tags.length > 0 ? (
                              <>
                                {item.tags.map((tag, index) => {
                                  let val =
                                    index < item.tags.length - 1
                                      ? tag + ", "
                                      : tag;
                                  return val;
                                })}
                              </>
                            ) : (
                              "Data not found"
                            )}
                          </Tooltip>
                        }
                      >
                        <span className="fw_b text_primary_dark mb-0">
                          positive causes
                        </span>
                      </OverlayTrigger>
                      .
                    </div>
                  )}
                  {item.label === "Sexuality" && (
                    <div>
                      Percent of reported{" "}
                      <OverlayTrigger
                        trigger={["hover", "focus"]}
                        placement="top"
                        rootClose={true}
                        overlay={
                          <Tooltip
                            id="tooltip-casualities"
                            className="custom_tooltip"
                          >
                            {item.tags.length > 0 ? (
                              <>
                                {item.tags.map((tag, index) => {
                                  let val =
                                    index < item.tags.length - 1
                                      ? tag + ", "
                                      : tag;
                                  return val;
                                })}
                              </>
                            ) : (
                              "Data not found"
                            )}
                          </Tooltip>
                        }
                      >
                        <span className="fw_b text_primary_dark mb-0">
                          sexual interactions
                        </span>
                      </OverlayTrigger>{" "}
                      in recorded experiences.
                    </div>
                  )}
                  {item.label === "Familiar Characters" && (
                    <div>
                      Percent of{" "}
                      <OverlayTrigger
                        trigger={["hover", "focus"]}
                        placement="top"
                        rootClose={true}
                        overlay={
                          <Tooltip
                            id="tooltip-casualities"
                            className="custom_tooltip"
                          >
                            {item.tags.length > 0 ? (
                              <>
                                {item.tags.map((tag, index) => {
                                  let val =
                                    index < item.tags.length - 1
                                      ? tag + ", "
                                      : tag;
                                  return val;
                                })}
                              </>
                            ) : (
                              "Data not found"
                            )}
                          </Tooltip>
                        }
                      >
                        <span className="fw_b text_primary_dark mb-0">
                          familiar characters
                        </span>
                      </OverlayTrigger>{" "}
                      in recorded experiences.
                    </div>
                  )}
                  {item.label === "Victim" && (
                    <div>
                      Percent of time you are the{" "}
                      <OverlayTrigger
                        trigger={["hover", "focus"]}
                        placement="top"
                        rootClose={true}
                        overlay={
                          <Tooltip
                            id="tooltip-casualities"
                            className="custom_tooltip"
                          >
                            {item.tags.length > 0 ? (
                              <>
                                {item.tags.map((tag, index) => {
                                  let val =
                                    index < item.tags.length - 1
                                      ? tag + ", "
                                      : tag;
                                  return val;
                                })}
                              </>
                            ) : (
                              "Data not found"
                            )}
                          </Tooltip>
                        }
                      >
                        <span className="fw_b text_primary_dark mb-0">
                          victim
                        </span>
                      </OverlayTrigger>{" "}
                      in recorded experiences.
                    </div>
                  )}
                  {item.label === "Aggressor" && (
                    <div>
                      Percent of time you are the{" "}
                      <OverlayTrigger
                        trigger={["hover", "focus"]}
                        placement="top"
                        rootClose={true}
                        overlay={
                          <Tooltip
                            id="tooltip-casualities"
                            className="custom_tooltip"
                          >
                            {item.tags.length > 0 ? (
                              <>
                                {item.tags.map((tag, index) => {
                                  let val =
                                    index < item.tags.length - 1
                                      ? tag + ", "
                                      : tag;
                                  return val;
                                })}
                              </>
                            ) : (
                              "Data not found"
                            )}
                          </Tooltip>
                        }
                      >
                        <span className="fw_b text_primary_dark mb-0">
                          aggressor
                        </span>
                      </OverlayTrigger>{" "}
                      in recorded experiences.
                    </div>
                  )}
                  {item.label === "Nightmare" && (
                    <div>
                      Percent of time you recorded{" "}
                      <OverlayTrigger
                        trigger={["hover", "focus"]}
                        placement="top"
                        rootClose={true}
                        overlay={
                          <Tooltip
                            id="tooltip-casualities"
                            className="custom_tooltip"
                          >
                            {item.tags.length > 0 ? (
                              <>
                                {item.tags.map((tag, index) => {
                                  let val =
                                    index < item.tags.length - 1
                                      ? tag + ", "
                                      : tag;
                                  return val;
                                })}
                              </>
                            ) : (
                              "Data not found"
                            )}
                          </Tooltip>
                        }
                      >
                        <span className="fw_b text_primary_dark mb-0">
                          nightmare
                        </span>
                        
                      </OverlayTrigger>{" "}
                      in recorded experiences.
                    </div>
                  )}
                </div>
              </div>
            </Col>
          ))
        ) : (
          <>
            {props.loading ? (
              <>
                {[0].map((_, i) => (
                  <PlaceholderCard type="chart" />
                ))}
              </>
            ) : (
              <SimpleMessageCard
                type="warning"
                msg="No more records found..."
                allowIcon={true}
              />
              
            )}
          </>
        )}
      </Row>
    </>
  );
}

export default UsefulMetricsContent;
