import React from "react";
import { Route, Navigate } from "react-router-dom";
import authService from "./Auth";

const HomeGuard = ({ component: Component, ...rest }) => {
  // let token = localStorage.getItem('auth_token');
  let token = authService.getToken();
  // console.log("token in Homeguard =", token);

  return (
    <>{!token ? <Component {...rest} /> : <Navigate to="/dashboard" />}</>
    // <Route
    //   {...rest}
    //   render={(props) =>
    //     !token ? <Component {...props} /> : <Navigate to="/dashboard" />
    //   }
    // />
  );
};

export default HomeGuard;
