import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import HomeGuestHeader from "../components/HomeGuestHeader/HomeGuestHeader";
import HomeInnerPageBanner from "../components/HomeInnerPageBanner/HomeInnerPageBanner";
import HomeSectionHeading from "../components/HomeSectionHeading/HomeSectionHeading";
import HomeNormalBanner from "../components/HomeNormalBanner/HomeNormalBanner";
import HomeFooter from "../components/HomeFooter/HomeFooter";
import PageComponent from "../components/PageComponent/PageComponent";

function BlogDetails() {
  return (
    <>
      <HomeGuestHeader />
      <PageComponent>
        <HomeInnerPageBanner className="about_banner"></HomeInnerPageBanner>
        <div className="inner_page">
          <Container className="pt5 pb5">
            <Row className="justify-content-center">
              <Col md={12} lg={10}>
                <div className="page_text">
                  <HomeSectionHeading
                    htmlTag="h2"
                    type="m"
                    titleText="Gender Differences in Dream Content"
                    className="mb2"
                  />
                  <div className="mb3">
                    <i>Aug 18, 2020</i>
                  </div>

                  <div className="img_container mb3">
                    <img
                      src={`${process.env.PUBLIC_URL}/img/gender-emotions-comparison.png`}
                      className="img-fluid"
                      alt="gender-emotions-comparison"
                    />
                  </div>

                  <p>
                    We recently completed a deep analysis of thousands of dreams
                    and the findings on gender differences in dream experiences
                    are compelling and ought to pique your interest. Here’s the
                    summary:
                  </p>

                  <p>
                    <strong>Gender differences in dream experiences</strong>
                  </p>

                  <p>
                    Our data indicate that dreams share many characteristics
                    across gender, age and educational level. Despite these
                    remarkable similarities, what makes dream experience so
                    fascinating is the ways in which it differs between women
                    and men. Specifically, there are gender differences in dream
                    characters, the type of interactions that occur in dreams,
                    the role of the dreamer, the emotions expressed by the
                    dreamer and, importantly, the actions taken in response to
                    the dream experience upon waking. However, it is important
                    to point out that the gender differences are in degree, not
                    in kind. Also, it is possible that gender differences may
                    result from differences in dream recall frequency between
                    women and men. While some of the gender differences are
                    small, they seem to form a pattern that we believe are
                    salient because of their potential impact on waking life. It
                    is also important to note at the outset that our data
                    comprises mainly dreams of American men and women.
                    Consequently, the results cannot yet be generalized
                    globally.
                  </p>

                  <p>
                    <strong>
                      While there are many differences, here are the primary
                      ones:
                    </strong>
                  </p>

                  <p>
                    <strong>Characters in dreams.</strong> The differences begin
                    with the cast of <strong>characters</strong> American men
                    and women see in their dreams. Women tend to dream more
                    about familial characters - their mothers, sisters,
                    relatives and romantic interests. On the other hand, men
                    dream about celebrities, classmates, fictional characters,
                    unknown characters and colleagues. For non-human characters,
                    women dream more about pets and animals than men.
                    Interestingly, and for reasons yet unknown, women dream more
                    about dead people than men.
                  </p>

                  <p>
                    <strong>Emotions in dreams.</strong> Both genders often go
                    through an emotional rollercoaster of happiness, anxiety,
                    sadness, helplessness, frustration, fear, and the like. We
                    call this the “restless dream syndrome.” But when compared
                    to men, the dream records of women are characterized by a
                    high degree of negative emotional interactions, including
                    helplessness, sadness, frustration, despair, shame, guilt,
                    disgust, anger, fear, jealousy and anxiety. Why? We looked
                    to other findings on gender differences to see why this
                    might be the case.
                  </p>

                  <ul>
                    <li>
                      When we look at the type of social interactions American
                      women engage in in their dreams, we find that women have
                      fewer friendly interactions than men. Further, women are
                      more often the victims than the aggressors in aggressive
                      interactions in their dream reports. Even in dream life,
                      women feel threatened and vulnerable. This may lead to
                      feelings of anxiety, fear, frustration, despair, anger and
                      sadness.
                    </li>
                    <li>
                      In addition, women dream of indecent public exposure (e.g.
                      naked in public) more than men. This vulnerability may
                      lead to feelings of shame, embarrassment, confusion,
                      helplessness and disgust.
                    </li>
                    <li>
                      Women dream of the inability to reach a destination (e.g.,
                      can’t find a classroom, restroom, etc.) more often than
                      men, which may lead to a sense of failure and feelings of
                      helplessness, anxiety and despair.
                    </li>
                    <li>
                      Women dream of physical inhibition (e.g. can't move fast
                      enough) more often than men. This may evoke a sense of
                      limitations and engender feelings of frustration, despair
                      and helplessness.
                    </li>
                    <li>
                      Women have more bizarre dreams than men (dream bizarreness
                      may be defined as impossible or improbable transformations
                      or unusual circumstances). For example, women dream of
                      unlikely visitations (e.g. visit from a dead relative),
                      unnatural animal behavior (e.g. flying pigs), time
                      dilation (e.g. time travel) and other unusual
                      circumstances such as transformations from place to place
                      and people to people. This could trigger a sense of loss,
                      confusion and anxiety.
                    </li>
                  </ul>

                  <p>
                    <strong>
                      What explains the gender difference in negative dream
                      emotions?
                    </strong>
                  </p>

                  <p>
                    We can draw from contemporary theories of dreaming to shed
                    light on dream emotions.
                  </p>

                  <p>
                    From the perspective of the cognitive theory of dreams, the
                    continuity hypothesis suggests that dream emotions reflect
                    people's emotional state during waking. Therefore, we might
                    infer that the lived lives of women in the US involve
                    unresolved conflicts, negative concerns and experiences,
                    threats and stresses which result in negative emotions they
                    carry forward to bed each night, and which subsequently fill
                    their dreams. It is known that women face greater
                    vulnerability and exposure to workplace harassment, social
                    and economic inequality and domestic abuse in society, which
                    then permeates into their dreams. What is worrisome is that
                    research has shown that dream emotions tend to stay with us
                    throughout the day, thereby affecting our waking moods and
                    behavior. This is a vicious spiral for women if they are
                    burdened in both waking and dreaming.
                  </p>

                  <p>
                    From the viewpoint of the mood-regulating theory of dreams,
                    which posits that dreaming is a mechanism for regulating
                    negative emotions accumulated from awake experiences,
                    dreaming serves a useful purpose of preparing women, better
                    than men, to cope with stress and conflicts in everyday
                    life.
                  </p>

                  <p>
                    <strong>Resolutions:</strong> Another important gender
                    difference is what men and women do in response to their
                    dreams. Women resolve to take more waking actions in
                    response to their dreams than men. Some of the actions taken
                    include: resolving personal internal conflict; facing or
                    overcome fears; being a better friend, sibling, spouse; and
                    seizing opportunities.
                  </p>

                  <p>
                    What makes dream emotions salient and potent is that, unlike
                    waking emotions, they are unencumbered by waking events and
                    distractions, they are sourced from memory traces of which
                    the dreamer is unaware. What we are learning is that dreams,
                    far from being a meaningless experience, are especially
                    informative about the emotional state of the dreamer. Since
                    emotions shape behavior, to understand human behavior,
                    broadly defined, we believe that we must understand dream
                    contents.
                  </p>

                  <p>
                    We recast dream and waking life as a mutually dependent
                    duality - both producing and reproducing each other. Dream
                    imitates life and life imitates dream. In some ways, it
                    appears that our dreams are indeed both a product of and
                    potentially a solution to our waking concerns.
                  </p>

                  <p>
                    <strong>On-going research</strong>
                  </p>

                  <p>
                    There are so many things we want to know about dreams. We
                    are running machine learning research using our dream data
                    to investigate whether and to what extent we can infer
                    psychopathology from dream data. The question is: can dream
                    content be a potential indicator of psychological
                    dispositions to act, feel and think in a certain way. For
                    example, if dream emotions are consistent with waking moods,
                    can we infer emotional wellbeing or the onset of
                    psychopathological problems such as post-traumatic stress
                    disorder and anxiety disorders? If someone frequently dreams
                    of aggression and being victimized, abused, and traumatized,
                    is this a sign of latent psychological issues? Should
                    primary care doctors be trained on how to use dream content
                    as part of the patient diagnostic procedure? Should everyone
                    have a dream record as part of their annual physical
                    checkup? We have all this free and abundant data on the
                    individual (and global) unconscious, but we don’t currently
                    use it as a society. What a waste.
                  </p>

                  <p>
                    In addition to the cognitive investigations, we need to
                    learn more about the underlying neurological mechanisms that
                    explain these gender differences. Thus, as brain-machine
                    interfaces (BMI) become more precise, safe, and
                    consumer-friendly, our understanding of dreams (and our
                    opportunity to leverage them for the good of the individual
                    and of society) will only increase.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <HomeNormalBanner />
      </PageComponent>
      <HomeFooter />
    </>
  );
}

export default BlogDetails;
