import React from "react";
import { Card, Row, Col } from "react-bootstrap";
import CustomCard from "../../../ui/CustomCard/CustomCard";
import Ratings from "../../../ui/Ratings/Ratings";
import { FaCrown } from "react-icons/fa";

function AchievementStatusCard(props) {
  // const ach_total_xp = props.achievements.hasOwnProperty("total_xp")
  //   ? props.achievements.total_xp
  //   : 0;
  const ach_total_xp = props.achievements?.total_xp
    ? props.achievements.total_xp
    : 0;
  const rank = props.rank?.rank ? props.rank.rank : 0;
  const star = props.rank?.star
    ? parseInt(props.rank.star.replace(/[^0-9]/g, ""), 10)
    : 0;
  const next_level_xp = props.rank?.next_level_xp
    ? props.rank.next_level_xp
    : 0;
  
  return (
    <>
      <CustomCard
        className={`achievement_status_card main_card shadow1 overflow-hidden border-0 mb2`}
      >
        <Row className="g-0">
          <Col
            xs={3}
            sm={2}
            md={2}
            className="bg_primary d-flex justify-content-center align-items-center"
          >
            <img
              src={`${process.env.PUBLIC_URL}/img/achievement-icon.png`}
              className="img-fluid mx-auto"
              alt=""
            />
          </Col>
          <Col xs={9} sm={10} md={10}>
            <Card.Body className="py-3 ps-3 pe-2">
              {/* <Row> */}
              <Row className="row-cols-2 g-2">
                {/* <Col xs={6} sm={8} className="px-3"> */}
                <Col>
                  <div className="xp_earned font-18 font-light">{`${ach_total_xp} XP`}</div>
                  <div className="xp_earned mt-1">
                    <span
                      className="custom_tag tag_golden tag_large"
                      style={{ lineHeight: 1 }}
                    >
                      <FaCrown className="me-2" />
                      {`${rank}`}
                    </span>
                  </div>
                  
                </Col>
                <Col
                  // xs={6}
                  // sm={4}
                  className="d-flex align-items-center justify-content-center"
                >
                  <div className="text-center">
                    <Ratings
                      className=""
                      totalItems={5}
                      activeItems={star}
                      fillStyle={true}
                    />
                    <span className="font-12">
                      {next_level_xp > 0
                        ? `${next_level_xp} XP for Next Rank`
                        : "Highest Rank"}
                    </span>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Col>
        </Row>
      </CustomCard>
    </>
  );
}

export default AchievementStatusCard;
