import React, { useState } from "react";
import { Card, Button } from "react-bootstrap";
import CustomCard from "../../../ui/CustomCard/CustomCard";
import ConfirmAcceptConsultInviteModal from "../../../ui/ConfirmAcceptConsultInviteModal/ConfirmAcceptConsultInviteModal";
import "./ConsultantInvitationCard.css";

function ConsultantInvitationCard(props) {
  console.log("ConsultantInvitationCard =", props);
  const avatar = props.invitation.user.avatar
    ? props.invitation.user.avatar
    : `${process.env.PUBLIC_URL}/img/user-profile.jpg`;
  const name = props.invitation.user.fullname
    ? props.invitation.user.fullname
    : "Unknown";
  const msg = props.invitation.message ? props.invitation.message : "";
  const is_consult = props.invitation.is_consult
    ? props.invitation.is_consult
    : true;

  //Accept Invite Confirmation Modal State
  const [show, setShow] = useState({
    isVisible: false,
  });

  const handleClose = () => {
    // console.log("Closing the modal");
    setShow({
      isVisible: false,
    });
  };
  const handleShow = (e) => {
    console.log("data-id=", e.target.getAttribute("data-id"));
    // setRemoveMemberData({
    //   user_id: e.target.getAttribute("data-id"),
    //   group_id: props.group.user_group_id,
    // });

    setShow({
      isVisible: true,
    });
    // console.log("Opening the modal");
  };

  const handleAcceptBtnClick = (invitation) => {
    // props.acceptInvitation(invitation);
    // props.handleShow();
    const data = {
      consult_id: invitation.consult_id,
    };
    props.acceptConsultInvite(data);
  };

  const handleRejectBtnClick = (invitation) => {
    const data = {
      consult_id: invitation.consult_id,
      // payment_amount: 0,
    };
    props.rejectConsultInvite(data);
  };

  return (
    <>
      <CustomCard
        className={`consultant_invitation_card main_card shadow1 border-0 mb2`}
      >
        {is_consult && (
          <>
            <div className="consult_icon">
              <img
                src={`${process.env.PUBLIC_URL}/img/icons/offer-icon6.png`}
                className="img-fluid"
                alt="Consult Icon"
              />
            </div>
          </>
        )}

        <Card.Header className="consultant_invitation_card_header">
          <div className="media">
            <div className="media_img_wrap">
              {props.invitation.type !== "group" && (
                <img
                  src={`${avatar}`}
                  className={`rounded-circle media_img`}
                  alt={`${name} profile`}
                />
              )}
            </div>

            <div className={`media_body ms-3`}>
              <Card.Title className="card_title">Request by {name}</Card.Title>
              <Card.Subtitle className="card_subtitle">
                {`You have been invited to join as a therapist`}
              </Card.Subtitle>
            </div>
          </div>
        </Card.Header>
        <Card.Body className="consultant_invitation_card_body">
          {msg !== "" && <p>{msg}</p>}
          <div className="btn_wrap">
            <Button
              type="button"
              className="btn_theme btn_small btn_secondary btn_rounded"
              onClick={() => {
                handleAcceptBtnClick(props.invitation);
              }}
            >
              Accept
            </Button>
            <Button
              type="button"
              className="btn_theme btn_small btn_danger btn_rounded"
              onClick={() => {
                handleRejectBtnClick(props.invitation);
              }}
            >
              Ignore
            </Button>
          </div>
        </Card.Body>
      </CustomCard>
      <ConfirmAcceptConsultInviteModal
        show={show}
        consult_id={props.invitation.consult_id}
        handleClose={handleClose}
      />
    </>
  );
}

export default ConsultantInvitationCard;
