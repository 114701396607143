import React, { useState } from "react";
import { Form, Button, Spinner } from "react-bootstrap";
// import { withRouter } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import authService from "../../services/Auth";
import { Formik } from "formik";
import * as yup from "yup";
// import { toast } from "react-toastify";
import { handleAlert } from "../../util/util";
import FieldError from "../../ui/FieldError/FieldError";
import { BsArrowClockwise } from "react-icons/bs";

function VerifyEmail({ history, registeredEmail }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loadingResend, setLoadingResend] = useState(false);
  // const handleAlert = (msg = "Default message!", type) => {
  //   if (type === "success") {
  //     toast(msg, {
  //       type: toast.TYPE.SUCCESS,
  //     });
  //   }
  //   if (type === "error") {
  //     toast(msg, {
  //       type: toast.TYPE.ERROR,
  //     });
  //   }
  // };
  // let history = useHistory();
  //   const [verificationCode, setVerificationCode] = useState("");

  const handleResendCode = (data) => {
    setLoadingResend(true);
    authService
      .resendVerificationEmail(data)
      .then((response) => {
        if (response.data.status === "success") {
          console.log('Response by resendVerificationEmail =',response.data);
          console.log("Verification code sent on email!");
          handleAlert("Verification code sent on email!", "success");
          setLoadingResend(false);
        
        } else {
          console.log("ERROR:", response.data.error);
          handleAlert("Failed to send verification code!", "error");
          setLoadingResend(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoadingResend(false)
      });
  }

  console.log("REG email: ", registeredEmail);

  const initialValues = {
    confirmation_code: "",
  };
  const verifyEmailSchema = yup.object({
    confirmation_code: yup.string().required("Required"),
  });

  const onSubmit = async (values, { resetForm }) => {
    setLoading(true);
    console.log("Form Values :", values);
    console.log("Registered Email: ", registeredEmail);
    const newValues = {
      email: registeredEmail,
      confirmation_code: values.confirmation_code
    };
    console.log("New values : ", newValues);
    // console.log("New Val REG email: ", newValues.email);
    authService
      .verifyEmail(newValues)
      .then((response) => {
        if (response.data.status === "success") {
          console.log(response.data);
          // console.log(response.data.data.result);
          console.log("Your email has been successfully verified!");
          handleAlert("Your email has been successfully verified!", "success");

          authService.setToken(response.data.data.result);
          setLoading(false);
          // sessionStorage.removeItem("isVerified");
          console.log("This statement executed");
          resetForm();
          // history.push("/dashboard");
          navigate("/dashboard");
          handleAlert("You have successfully logged in!", "success");
          console.log("This statement executed again");
        } else {
          console.log("ERROR:", response.data.error);
          handleAlert(response.data.error, "error");
          resetForm();
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <>
      {/* <div className="font-18 text_secondary text-center fw_sb mb-1">Verify to continue</div> */}
      <p className="mb-3">Enter verification code and submit.</p>
      <Formik
        initialValues={initialValues}
        validationSchema={verifyEmailSchema}
        onSubmit={onSubmit}
      >
        {(formik) => {
          return (
            <Form onSubmit={formik.handleSubmit}>
              <Form.Group className="form-group rounded_corners">
                <Form.Control
                  type="text"
                  placeholder="Verification code"
                  name="confirmation_code"
                  {...formik.getFieldProps("confirmation_code")}
                />
                <Form.Label className="label_float">
                  Verification code
                </Form.Label>
                <FieldError
                  valid={
                    formik.touched.confirmation_code &&
                    formik.errors.confirmation_code
                      ? true
                      : false
                  }
                  text={formik.errors.confirmation_code}
                />
                {/* {formik.touched.confirmation_code &&
                formik.errors.confirmation_code ? (
                  <p className="error-state-text">
                    {formik.errors.confirmation_code}
                  </p>
                ) : null} */}
              </Form.Group>

              <Button
                type="submit"
                disabled={!formik.isValid}
                className="btn_theme btn_theme_gradient btn_rounded btn_block mb1"
              >
                {loading ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />{" "}
                    Loading
                  </>
                ) : (
                  "Submit"
                )}
              </Button>
            </Form>
          );
        }}
      </Formik>
      <div className="text-center pt-2">
        <p className="mb-0">Didn't receive a code?</p>
        <Button type="button" className="btn_theme btn_small btn_link btn_secondary pt-1 pb-1"
          disabled={loadingResend}
          onClick={() => {
            handleResendCode({email: registeredEmail})
          }}
        ><BsArrowClockwise className="font-16" /> Resend code</Button>
      </div>
    </>
  );
}

export default VerifyEmail;
