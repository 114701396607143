import React, { useState, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  Form,
  Button,
  Card,
  Row,
  Col,
  Spinner,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import DashboardSectionTitle from "../DashboardSectionTitle/DashboardSectionTitle";
import RecordDreamFormSlider from "./RecordDreamFormSlider";
import CustomCard from "../../../ui/CustomCard/CustomCard";
import FieldError from "../../../ui/FieldError/FieldError";
import { Field, Formik } from "formik";
import * as yup from "yup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { handleAlert, formatDateYMD } from "../../../util/util";
import {
  emotionsList,
  charactersList,
  interactionsList,
  locationsList,
  causesList,
  resolutionsList,
} from "../../../util/emoji";
import { BsFillExclamationCircleFill } from "react-icons/bs";

//Services
import recordService from "../../../services/Record";
import { callOpenAIAPI } from "../../../services/ChatService"; //OpenAI API Service

//Context
import AppContext from "../../../AppContext";

//CSS for loading animation
import "./RecordDreams.css";

function RecordDreams(props) {
  const navigate = useNavigate();
  const today = formatDateYMD(new Date());
  const { getData, getUserProfileData, getAllAchievements } =
    useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [loadingDel, setLoadingDel] = useState(false);
  const [step, setStep] = useState(false);
  const [activeSlide, setActiveSlide] = useState(0);
  const [recordFormValues, setRecordFormValues] = useState({});
  const [recordToDelete, setRecordToDelete] = useState(null);

  const [initialValues, setInitialValues] = useState({
    // date_of_occurrence: null,
    record_date: new Date(today),
    dream_type: "",
    record_type: "",
    title: "",
    emotions: [],
    characters: [],
    interactions: [],
    locations: [],
    causes: [],
    resolutions: [],
    // description: "",
    is_private: false,
    is_consult_needed: false,
    is_delete_button: false,
  });

  //For other emotion
  const otherEmotionRef = useRef("");
  //For other character
  const otherCharacterRef = useRef("");
  //For other interactions
  const otherInteractionRef = useRef("");
  //For other locations
  const otherLocationRef = useRef("");
  //For other causes
  const otherCauseRef = useRef("");
  //For other resolutions
  const otherResolutionRef = useRef("");

  // For description field
  const descriptionRef = useRef("");
  const [descriptionError, setDescriptionError] = useState("");

  //For Decoded Description field
  const [isDecoded, setIsDecoded] = useState(false);
  //Array of objects: Array of Dictionary words
  const [decodedDescription, setDecodedDescription] = useState([]);
  //Generated decoded string html (Tooltip html structure) elements + normal text words array
  const [decodedStr, setDecodedStr] = useState(null);

  //Open AI implementation
  const [openAIResponse, setOpenAIResponse] = useState("");
  const [openAICalled, setOpenAICalled] = useState(false); // Additional state to track if the OpenAI API has been called
  const [isLoadingOpenAIResponse, setIsLoadingOpenAIResponse] = useState(false); // Additional state for loading animation

  //Open AI API communication function
  const handleAIInterpretClick = async () => {
    const description = descriptionRef.current.value;
    if (!description.trim()) {
      setDescriptionError("Description cannot be empty"); // Set error if textarea is empty
      return;
    }
    setIsLoadingOpenAIResponse(true); // Loading animation starts
    setOpenAICalled(true);
    // setIsDecoded(false);
    try {
      const description = descriptionRef.current.value; // Get value from textarea
      const response = await callOpenAIAPI({ message: description }); // Replace with your actual API call
      setOpenAIResponse(response.data.message); // Set the OpenAI response
    } catch (error) {
      console.error("Error calling OpenAI API:", error);
      setDescriptionError("Error: Please check back later."); // Set error if textarea is empty
      setOpenAICalled(false); // Reset flag if there's an error
    } finally {
      setIsLoadingOpenAIResponse(false); // Stop loading regardless of success or error
    }
  };

  //Set focus to description textbox
  function handleDescFocus() {
    console.log("Indside handleDescFocus");
    // console.log("descriptionRef =", descriptionRef);
    // To get offsetTop value of direct parent i.e. 'custom_card'
    let descriptionTextTop =
      descriptionRef.current.offsetParent.offsetParent.offsetTop - 100;
    console.log(
      "descriptionRef =",
      descriptionRef,
      "\nTop =",
      descriptionTextTop
    );
    descriptionRef.current.focus();
    //To scroll window to get textarea visible to user...
    window.scroll({
      top: descriptionTextTop,
      behavior: "smooth",
    });
  }

  //Validating description field should not be empty...
  function validateDescription(e) {
    if (e.target.value) {
      setDescriptionError("");
    } else {
      setDescriptionError("Description cannot be empty");
    }
  }
  //Checking descriptionRef should not be empty...
  function checkDescription() {
    const refVal = descriptionRef.current.value;
    if (refVal !== "") {
      console.log("InputRef val = ", refVal);
      setDescriptionError("");
      return true;
    } else {
      setDescriptionError("Description cannot be empty");
      return false;
    }
  }

  //Get decoded string
  function getDecodedString(dictionary) {
    let tempStr = [];
    let tempDesc = descriptionRef.current.value;
    const descriptionWords = tempDesc.split(" ");
    // console.log("tempDesc =", tempDesc, "\nDictionary=", dictionary);
    // console.log("Desc Words =", descriptionWords, "\ntempDesc =", tempDesc);
    const dictionaryWords = dictionary.map((item) => {
      return item.word.toLowerCase();
    });
    // console.log("This is word array =", dictionaryWords, " created.");
    tempStr = descriptionWords.map((word) => {
      let matchedWord = dictionary.find((dic) =>
        dic.tags
          .map((tag) => tag.toLowerCase())
          .concat([dic.word.toLowerCase()])
          .includes(word.toLowerCase().replace(/[^a-zA-Z0-9]/g, ""))
      );
      if (matchedWord) {
        return (
          <OverlayTrigger
            key={matchedWord.id}
            trigger={["focus", "click"]}
            placement="top"
            rootClose={true}
            overlay={
              <Tooltip
                id={`tooltip-${matchedWord.id}`}
                className="custom_tooltip"
              >
                <div className="tooltip_title text_primary">
                  {matchedWord.word}
                </div>
                <div className="mb-2">
                  <span className="text_primary">Short: </span>
                  {matchedWord.short_description}
                </div>
                <div>
                  <span className="text_primary">Long: </span>
                  {matchedWord.description}
                </div>
              </Tooltip>
            }
          >
            <span className="tooltip_badge">{word}</span>
          </OverlayTrigger>
        );
      } else {
        return word;
      }
    });

    console.log("decoded: ", tempStr);
    setDecodedStr(tempStr);
  }

  //Decode function
  function handleDecode() {
    window.console.log("Called function handleDecode()...");
    //Checking descriptionRef should not be empty...
    if (checkDescription()) {
      // console.log("Description is decoded");
      const descVal = { description: descriptionRef.current.value };
      // console.log("descVal =", descVal);
      //Calling Decode function
      recordService
        .getDecodedDictionary(descVal)
        .then((response) => {
          if (response.data.status === "success") {
            console.log("Response from Record Form : ", response.data);
            const dictionary = response.data.data.result.dictionary;
            // setExperiences(records);
            setIsDecoded(true);
            getDecodedString(dictionary);

            setDecodedDescription(dictionary);
          }
          if (response.data.status === "failed") {
            console.log("ERROR:", response.data);
            setIsDecoded(false);
            setDecodedDescription([]);
          }
        })
        .catch((err) => {
          console.log("There is a problem in decoding description...", err);
        });
      setIsDecoded(true); // Ensure decoded description is displayed
      // setOpenAIResponse(""); // Clear the OpenAI response
      // setOpenAICalled(false);
    } else {
      console.log("Description is empty");
    }
  }

  //For getting record list and updating it into context variable
  // function getData() {
  //   recordService
  //     .getAllRecords()
  //     .then((response) => {
  //       if (response.data.status === "success") {
  //         // console.log("Response from APP : ", response.data);
  //         const records = response.data.data.result.records;
  //         setExperiences(records);
  //       }
  //       if (response.data.status === "failed") {
  //         console.log("ERROR:", response.data.error);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log("There is a problem in creating new record...", err);
  //     });
  // }

  //Get Profile Stats
  // function getUserProfileData() {
  //   recordService
  //     .getUserProfileData()
  //     .then((response) => {
  //       if (response.data.status === "success") {
  //         // console.log("Response from APP : ", response.data);
  //         const stats = response.data.data.result.details;
  //         setProfileStats(stats);
  //       }
  //       if (response.data.status === "failed") {
  //         console.log("ERROR:", response.data.error);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log("There is a problem in fetching Profile Stats...", err);
  //     });
  // }

  const recordTypeOptions = [
    { key: "Select dream type", value: "" },
    { key: "Normal", value: "normal" },
    { key: "Lucid", value: "lucid" },
    // { key: "Day", value: "day" },
    { key: "Nightmare", value: "nightmare" },
  ];

  const recordDreamsSchema = yup.object({
    // date_of_occurrence: yup.date().required("Required").nullable(),
    record_date: yup.date().required("Required").nullable(),
    // record_type: yup.string().required("Required"),
    dream_type: yup.string().required("Required"),
    title: yup.string().max(100, "Title is too long!").required("Required"),
  });

  const onSubmit = async (values, { resetForm }) => {
    console.log("Form Values in onSubmit :", values);
    console.log("I am executing though reset button clicked");

    if (!values.is_delete_button) {
      console.log("--------------------------------");
      console.log(
        "You did not prsss delete button and ",
        "----------------------- Values =",
        values
      );
      console.log("Inside If : is_delete_button =", values.is_delete_button);
      console.log("RecordToDelete in if = ", recordToDelete);
      console.log("--------------------------------");

      let final_value = Object.assign({}, initialValues);
      final_value["record_date"] = values["record_date"];
      final_value["dream_type"] = values["dream_type"];
      final_value["title"] = values["title"];
      final_value["is_consult_needed"] = values["is_consult_needed"];
      final_value["is_private"] = values["is_private"];

      if (values["record_id"]) {
        console.log("on submit Record ID = ", values["record_id"]);
        final_value["record_id"] = values["record_id"];
      }

      console.log("Final Values", final_value);
      values = final_value;

      setLoading(true);
      let newDate = formatDateYMD(values.record_date);
      // console.log("New Date =", newDate);

      const newValues = {
        ...values,
        type: "dream",
        record_date: newDate,
        is_complete: false,
      };
      console.log("New Values =", newValues);

      //Update existing records step 2
      if (recordFormValues.hasOwnProperty("record_id")) {
        //Update an existing record having id = record_id
        console.log("Own the property");
        // console.log("Updated Form values: ", newValues);
        // console.log("Updated Form values (formik) : ", values);
        // console.log("In If recordFormVal =", recordFormVal);
        // console.log("In If (update) recordFormVal =", recordFormValues);
        if (!checkDescription()) {
          // alert("Please enter Description");
          handleAlert("Error! Record: Dream updation failed", "error");
          setLoading(false);
          return false;
        }

        console.log("New Values in update dream =", newValues);
        const otherEmotionValue = otherEmotionRef?.current.value;
        console.log("otherEmotionValue =", otherEmotionValue);
        const newEmotions =
          otherEmotionValue !== ""
            ? newValues.emotions.concat(otherEmotionValue)
            : newValues.emotions;
        console.log("newEmotions =", newEmotions);

        const otherCharacterValue = otherCharacterRef?.current.value;
        console.log("otherCharacterValue =", otherCharacterValue);
        const newCharacters =
          otherCharacterValue !== ""
            ? newValues.characters.concat(otherCharacterValue)
            : newValues.characters;
        console.log("newCharacters =", newCharacters);

        const otherInteractionValue = otherInteractionRef?.current.value;
        const newInteractions =
          otherInteractionValue !== ""
            ? newValues.interactions.concat(otherInteractionValue)
            : newValues.interactions;
        console.log("newInteractions =", newInteractions);

        const otherLocationValue = otherLocationRef?.current.value;
        const newLocations =
          otherLocationValue !== ""
            ? newValues.locations.concat(otherLocationValue)
            : newValues.locations;
        console.log("newLocations =", newLocations);

        const otherCauseValue = otherCauseRef?.current.value;
        const newCauses =
          otherCauseValue !== ""
            ? newValues.causes.concat(otherCauseValue)
            : newValues.causes;
        console.log("newCauses =", newCauses);

        const otherResolutionValue = otherResolutionRef?.current.value;
        const newResolutions =
          otherResolutionValue !== ""
            ? newValues.resolutions.concat(otherResolutionValue)
            : newValues.resolutions;
        console.log("newResolutions =", newResolutions);

        const newFormValues = {
          ...newValues,
          emotions: newEmotions,
          characters: newCharacters,
          interactions: newInteractions,
          locations: newLocations,
          causes: newCauses,
          resolutions: newResolutions,
          description: descriptionRef.current.value,
          description_decoded: decodedStr,
          dictionary_words: decodedDescription,
          description_ai_decoded: openAIResponse,
          is_complete: true,
        };
        // console.log("newFormValues in update = ", newFormValues);
        // console.log("openAIResponse in update = ", openAIResponse);

        await setRecordFormValues((prev) => {
          let tVal = Object.assign(prev, newFormValues);
          // console.log("tVal in update =", tVal);
          return tVal;
        });
        // console.log(
        //   "In If (update) after setter method: recordFormVal =",
        //   recordFormValues
        // );

        recordService
          .updateRecord(recordFormValues)
          .then((response) => {
            if (response.data.status === "success") {
              console.log(response.data);
              console.log("New Values =", newFormValues);
              const responseRecord = response.data.data.result.record;
              console.log("recordFormValues: ", recordFormValues);

              handleAlert("Success! Record: Dream updated", "success");
              setLoading(false);

              setRecordFormValues({});
              setInitialValues({
                // date_of_occurrence: null,
                record_date: new Date(today),
                dream_type: "",
                record_type: "",
                title: "",
                emotions: [],
                characters: [],
                interactions: [],
                locations: [],
                causes: [],
                resolutions: [],
                is_private: false,
                is_consult_needed: false,
                is_delete_button: false,
              });

              //Resetting slider current item to 0 for next new recording...
              setActiveSlide(0);
              //resetting back first step of the form
              setStep(false);
              setIsDecoded(false);
              setDecodedDescription([]);
              setOpenAIResponse("");
              setDecodedStr([]);
              resetForm();
              props.handleCollapse("record_dreams");

              // console.log("Record Id...", responseRecord.record_id);
              // console.log("New Record from state (update) =", recordFormValues);
              //fetch new context data
              getData();

              //Get Profile Stats
              getUserProfileData();

              //Get all achievements
              getAllAchievements();

              //Redirect user to consult page if is_consult checkbox is checked...
              setTimeout(() => {
                if (newFormValues.is_consult_needed) {
                  navigate(`/dashboard/consult`);
                  props.getContentType("consult");
                }
              }, 500);
            }
            if (response.data.status === "failed") {
              console.log("ERROR:", response.data.error);
              handleAlert("Error! Record: Dream updation failed", "error");
              setLoading(false);
              // resetForm();
            }
          })
          .catch((err) => {
            console.log("There is a problem in creating new record...", err);
            setLoading(false);
          });
      }
      //Create new record step 1
      if (!recordFormValues.hasOwnProperty("record_id")) {
        //Create new record
        console.log("Does not Own the property");
        recordService
          .createRecord(newValues)
          .then((response) => {
            if (response.data.status === "success") {
              console.log("Created New dream record =", response.data);
              console.log("After create success - newValues =", newValues);
              const responseRecord = response.data.data.result.record;
              // setRecordFormValues({ ...temp, ...responseRecord });
              let resRecord = {
                record_id: responseRecord.record_id,
                record_date: responseRecord.record_date,
                title: responseRecord.title,
                type: responseRecord.type,
                emotions: [],
                characters: [],
                interactions: [],
                locations: [],
                causes: [],
                resolutions: [],
                dream_type: responseRecord.dream_type,
                is_complete: responseRecord.is_complete,
                is_consult_needed: responseRecord.is_consult_needed,
                is_delete_button: false,
                is_private: false,
              };
              setRecordFormValues(resRecord);
              setRecordToDelete(resRecord.record_id);
              // setRecordFormValues((prev) => {
              //   // console.log("In create setter ", { ...prev, ...responseRecord });
              //   // return { ...prev, ...responseRecord };
              //   let tVal = Object.assign(prev, responseRecord);
              //   console.log("after create success - tVal =", tVal);
              //   return tVal;
              // });
              setLoading(false);
              // console.log("New Record from state =", recordFormVal);
              // console.log("New Record from state (create) =", recordFormValues);
              handleAlert("Success! Record: Dream created", "success");
              setStep(true);
              //fetch new context data
              getData();
              //Get all achievements
              getAllAchievements();
              //Get Profile Stats
              getUserProfileData();
            }
            if (response.data.status === "failed") {
              console.log("ERROR:", response.data.error);
              handleAlert("Error! Record: Dream creation failed", "error");
              setStep(false);
              setLoading(false);
            }
          })
          .catch((err) => {
            console.log("There is a problem in creating new record...", err);
            setLoading(false);
          });
      }
    } else {
      console.log("--------------------------------");
      console.log("Inside else : is_delete_button =", values.is_delete_button);
      console.log(
        "You prsssed delete button and recordFormValues =",
        recordFormValues,
        "----------------------- Values =",
        values
      );
      console.log("RecordToDelete in else = ", recordToDelete);
      console.log("--------------------------------");
      setLoadingDel(true);

      recordService
        .deleteRecord(recordToDelete)
        .then((response) => {
          console.log("RESPONSE: ", response);
          if (response.data.status === "success") {
            console.log("Record delete button - Record Delete: ", response);
            handleAlert("Success! Record deleted", "success");
            setLoadingDel(false);
            props.handleCollapse("record_dreams");
            setRecordFormValues({});
            // resetForm();
            setActiveSlide(0);
            setStep(false);
            setIsDecoded(false);
            setDecodedDescription([]);
            setDecodedStr([]);
            setRecordToDelete(null);
            setInitialValues({
              // date_of_occurrence: null,
              record_date: new Date(today),
              dream_type: "",
              record_type: "",
              title: "",
              emotions: [],
              characters: [],
              interactions: [],
              locations: [],
              causes: [],
              resolutions: [],
              // description: "",
              is_private: false,
              is_consult_needed: false,
              is_delete_button: false,
            });
            resetForm();
            //fetch new context data
            getData();
            //Get all achievements
            getAllAchievements();
            //Get Profile Stats
            getUserProfileData();
          }
          if (response.data.status === "failed") {
            console.log("ERROR:", response.data.error);
            setLoadingDel(false);
            handleAlert("Error! Record deletion failed", "error");
          }
        })
        .catch((err) => {
          console.log("There is a problem in deleting record...", err);
          setLoadingDel(false);
          handleAlert("Error! Record deletion failed", "error");
        });
    }
  };

  const owlSliderConfigurations = {
    margin: 15,
    loop: false,
    dots: false,
    nav: true,
    responsiveClass: true,
    startPosition: activeSlide,
    responsive: {
      0: { items: 1 },
    },
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={recordDreamsSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {(formik) => {
          return (
            <Form onSubmit={formik.handleSubmit} className="form_style">
              {!step && (
                <>
                  {/* <div className="font-14 mb2">
                    * No recorded experiences are shared until you choose to
                    share them on the share page.
                  </div> */}
                  <Form.Group className="form-group rounded_corners">
                    <DashboardSectionTitle
                      iconImg=""
                      title="Date of occurrence"
                      pageTitle={false}
                      className="mb1 small_title"
                    />
                    <Field name="record_date">
                      {({ form, field }) => {
                        const { setFieldValue } = form;
                        const { value } = field;

                        return (
                          <DatePicker
                            id="record_date"
                            className="form-control"
                            {...field}
                            todayButton="Today"
                            selected={value}
                            onChange={(val) =>
                              setFieldValue("record_date", val)
                            }
                            dateFormat="yyyy-MM-dd"
                            placeholderText="Date of occurrence"
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            autoComplete="off"
                          />
                        );
                      }}
                    </Field>
                    <FieldError
                      valid={
                        formik.touched.record_date && formik.errors.record_date
                          ? true
                          : false
                      }
                      text={formik.errors.record_date}
                    />
                  </Form.Group>

                  <Form.Group className="form-group rounded_corners">
                    <DashboardSectionTitle
                      iconImg=""
                      title="Dream type"
                      pageTitle={false}
                      className="mb1 small_title"
                    />
                    <Form.Select
                      aria-label="Dream type"
                      className="form-control"
                      name="dream_type"
                      {...formik.getFieldProps("dream_type")}
                    >
                      {recordTypeOptions.map((recordType) => {
                        return (
                          <option
                            key={recordType.value}
                            value={recordType.value}
                          >
                            {recordType.key}
                          </option>
                        );
                      })}
                    </Form.Select>
                    <FieldError
                      valid={
                        formik.touched.dream_type && formik.errors.dream_type
                          ? true
                          : false
                      }
                      text={formik.errors.dream_type}
                    />
                    {/* {formik.touched.record_type && formik.errors.record_type ? (
                      <p className="error-state-text">
                        {formik.errors.record_type}
                      </p>
                    ) : null} */}
                  </Form.Group>

                  <Form.Group className="form-group rounded_corners">
                    <DashboardSectionTitle
                      iconImg=""
                      title="Dream title"
                      pageTitle={false}
                      className="mb1 small_title"
                    />
                    <Form.Control
                      type="text"
                      placeholder="Dream title"
                      name="title"
                      {...formik.getFieldProps("title")}
                      autoComplete="off"
                    />
                    <div className="text-end text_gray8">
                      <small>* Maximum 100 characters long</small>
                    </div>
                    <FieldError
                      valid={
                        formik.touched.title && formik.errors.title
                          ? true
                          : false
                      }
                      text={formik.errors.title}
                    />
                    {/* {formik.touched.title && formik.errors.title ? (
                      <p className="error-state-text">{formik.errors.title}</p>
                    ) : null} */}
                  </Form.Group>
                </>
              )}
              {step && (
                <>
                  <RecordDreamFormSlider
                    options={owlSliderConfigurations}
                    formik={formik}
                    emotionsList={emotionsList}
                    charactersList={charactersList}
                    interactionsList={interactionsList}
                    locationsList={locationsList}
                    causesList={causesList}
                    resolutionsList={resolutionsList}
                    activePage={setActiveSlide}
                    initialValues={initialValues}
                    handleDescFocus={handleDescFocus}
                    otherEmotionRef={otherEmotionRef}
                    otherCharacterRef={otherCharacterRef}
                    otherInteractionRef={otherInteractionRef}
                    otherLocationRef={otherLocationRef}
                    otherCauseRef={otherCauseRef}
                    otherResolutionRef={otherResolutionRef}
                  />

                  <CustomCard className="main_card mb1 bg_primary_light2">
                    <Card.Body>
                      <Row className="align-items-center mb1">
                        <Col xs={12} sm={12} md={8} lg={8}>
                          <div className="font-14 fw_sb">
                            Full description of dream for decoding *
                          </div>
                        </Col>
                        <Col xs={12} sm={12} md={4} lg={4}>
                          <div className="d-flex justify-content-end">
                            {/* New Button */}
                            <Button
                              type="button"
                              className="btn btn_theme btn_ai btn_secondary btn_rounded me-2"
                              onClick={handleAIInterpretClick}
                            >
                              AI Interpreter
                            </Button>
                            {/* Existing Decode Button */}
                            <Button
                              type="button"
                              key="decode_btn"
                              className="btn btn_theme btn_small btn_secondary btn_rounded"
                              onClick={handleDecode}
                            >
                              Decode
                            </Button>
                          </div>
                        </Col>
                      </Row>

                      <Form.Group className="form-group rounded_corners">
                        <Form.Control
                          as="textarea"
                          rows={3}
                          name="description"
                          onChange={(e) => {
                            validateDescription(e);
                          }}
                          ref={descriptionRef}
                          onBlur={(e) => {
                            validateDescription(e);
                          }}
                        />
                        <FieldError
                          valid={descriptionError !== "" ? true : false}
                          text={descriptionError}
                        />
                        {/* {descriptionError !== "" ? (
                          <p className="error-state-text">{descriptionError}</p>
                        ) : null} */}
                      </Form.Group>

                      {/* Conditional Rendering for Decoded Description or OpenAI Response */}
                      {isDecoded && (
                        <>
                          <hr />
                          <Row>
                            <Col xs={12}>
                              <div className="pb1">
                                {decodedStr?.length > 0 && (
                                  <>
                                    <div className="font-14 fw_sb pb1">
                                      Decoded description:
                                    </div>
                                    {decodedStr?.map((item, index) => {
                                      return (
                                        <React.Fragment key={index}>
                                          {item}{" "}
                                        </React.Fragment>
                                      );
                                    })}
                                  </>
                                )}
                              </div>
                              {decodedStr?.length < 0 && (
                                <>
                                  <p className="font-14 fw_sb">
                                    No more decoded desciption to display
                                  </p>
                                </>
                              )}
                            </Col>
                          </Row>
                        </>
                      )}
                      {openAICalled && ( // Only show OpenAI section if API has been called
                        <>
                          {/* OpenAI API Response Display */}
                          <hr />
                          <Row>
                            <Col>
                              <div className="font-14 fw_sb pb1">
                                AI Interpretation:
                              </div>
                              {/* Loading Animation */}
                              {isLoadingOpenAIResponse ? (
                                <div class="center">
                                  <div class="wave"></div>
                                  <div class="wave"></div>
                                  <div class="wave"></div>
                                  <div class="wave"></div>
                                  <div class="wave"></div>
                                  <div class="wave"></div>
                                  <div class="wave"></div>
                                  <div class="wave"></div>
                                  <div class="wave"></div>
                                  <div class="wave"></div>
                                </div>
                              ) : (
                                <>
                                  {/** Display OpenAI response when not loading and openAIResponse has something... */}
                                  {!isLoadingOpenAIResponse &&
                                    openAIResponse !== "" && (
                                      <p>{openAIResponse}</p>
                                    )}
                                </>
                              )}
                            </Col>
                          </Row>
                        </>
                      )}
                    </Card.Body>
                  </CustomCard>

                  <CustomCard className="main_card mb3 bg_warning_light p1">
                    <div className="d-flex">
                      <div className="flex-shrink-0">
                        <BsFillExclamationCircleFill
                          style={{ color: "#f4aa2b", fontSize: "25px" }}
                        />
                      </div>
                      <div className="flex-grow-1 ms-3 fw_sb">
                        Recorded content is publicly searchable. You may want to
                        be sensitive if using real names.
                      </div>
                    </div>
                  </CustomCard>
                </>
              )}

              <div className="d-flex justify-content-center flex-column flex-sm-row">
                <Form.Check
                  inline
                  label="Consult a specialist?"
                  name="is_consult_needed"
                  type="checkbox"
                  id="is_consult_needed"
                  value={formik.values.is_consult_needed}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                {/* <Form.Check
                  inline
                  label="Mark private?"
                  name="is_private"
                  type="checkbox"
                  id="is_private"
                  value={formik.values.is_private}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                /> */}
              </div>
              <div className="btn_wrap text-center pt2">
                <Button
                  type="submit"
                  disabled={!formik.isValid}
                  className="btn_theme btn_primary btn_rounded"
                >
                  {step ? (
                    <>
                      {loading ? (
                        <>
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />{" "}
                          Loading
                        </>
                      ) : (
                        "Save & Exit"
                      )}
                    </>
                  ) : (
                    <>
                      {loading ? (
                        <>
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />{" "}
                          Loading
                        </>
                      ) : (
                        "Add Details"
                      )}
                    </>
                  )}
                </Button>
                {step && (
                  <Button
                    type="button"
                    id="delete-dream-btn"
                    onClick={(e) => {
                      formik.setFieldValue("is_delete_button", true);
                      formik.handleSubmit(e);
                    }}
                    className="btn_theme btn_light btn_rounded"
                  >
                    {loadingDel ? (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />{" "}
                        Deleting
                      </>
                    ) : (
                      "Delete"
                    )}
                  </Button>
                )}
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}

export default RecordDreams;
