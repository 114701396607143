import React, { useState, useEffect } from "react";
import DashboardExperienceCard from "../DashboardExperienceCard/DashboardExperienceCard";
import DashboardSectionTitle from "../DashboardSectionTitle/DashboardSectionTitle";
import PlaceholderCard from "../../../ui/PlaceholderCard/PlaceholderCard";
import SimpleMessageCard from "../../../ui/SimpleMessageCard/SimpleMessageCard";

//Services
import recordService from "../../../services/Record";

function DashboardAssociateContent() {
  const [loading, setLoading] = useState(false);
  const [associateExpbankData, setAssociateExpbankData] = useState([]);
  let mainType = "associate";
  let actions = ["add_association", "view_associations"];

  useEffect(() => {
    setLoading(true);
    async function getData() {
      if (associateExpbankData.length == 0) {
        setLoading(true);
      }

      recordService
        .getAllAssociationRecords()
        .then((response) => {
          if (response.data.status === "success") {
            console.log("Response from getAllAssociationRecords : ", response.data);
            const records = response.data.data.result.records;
            let updatedRecords;
            updatedRecords = records.map((record) => {
              return { ...record, mainType: mainType, actions: actions };
            });
            setAssociateExpbankData(updatedRecords);
            setLoading(false);
          }
          if (response.data.status === "failed") {
            console.log("ERROR:", response.data.error);
          }
        })
        .catch((err) => {
          console.log("There is a problem in creating new record...", err);
          setLoading(false);
        });
    }
    getData();
  }, []);

  return (
    <>
      <DashboardSectionTitle
        iconImg="offer-icon2.png"
        title="Associate"
        pageTitle={true}
        className="mb2"
        withInfo={true}
        infoData="Find connections between your conscious and unconscious experiences."
      />

      {loading ? (
        <>
          {[0, 1].map((_, i) => (
            <PlaceholderCard key={i} />
          ))}
        </>
      ) : (
        <>
          {associateExpbankData.length > 0 ? (
            associateExpbankData.map((exp) => (
              <DashboardExperienceCard
                key={exp.record_id}
                exp={exp}
                setAssociateExpbankData={setAssociateExpbankData}
              />
            ))
          ) : (
            <SimpleMessageCard type="warning" />
          )}
        </>
      )}

    </>
  );
}

export default DashboardAssociateContent;
