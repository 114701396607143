import React from "react";
import { Collapse } from "react-bootstrap";
import "./CustomAccordionContainer.css";

function CustomAccordionContainer(props) {
  function handleCollapseOnExiting() {
    // if (props.cardTop) {
    //   window.scroll({
    //     top: props.cardTop,
    //     behavior: "smooth",
    //   });
    // }
  }
  function handleCollapseOnEntering(e) {
    console.log("Accordion Collapse Container = ", e);
    console.log("Accordion Collapsing container = ", props.ariaControls);

    if (props.ariaControls === "engagement_record") {
      console.log("......... Inside if engagement_record .........");
      props.getDashboardSharedData();
    }

    if (props.ariaControls === "popular_insights") {
      console.log("......... Inside if popular_insights .........");
      props.getDashboardPopularInsightsData();
      props.getDashboardPopularWordcloudData();
      props.getDashboardMorePopularInsightsData();
    }

    if (props.ariaControls === "useful_metrics") {
      console.log("......... Inside if useful_metrics .........");
      props.getUsefulMetricsData();
    }
    if (props.ariaControls === "favorites") {
      console.log("......... Inside if favorites .........");
      props.getDashboardFavoriteData();
    }
    if (props.ariaControls === "resolution") {
      // props.handleAssociationList(props.record_id);
      console.log("......... Inside if resolution .........");
      props.getDashboardResolutionData();
    }
  }

  return (
    <Collapse
      in={props.openCollapse}
      onEntering={handleCollapseOnEntering}
      onExiting={handleCollapseOnExiting}
      className="accordion_container"
      unmountOnExit={props.unmountOnExit || false}
    >
      <div id={props.ariaControls}>{props.children}</div>
    </Collapse>
  );
}

export default CustomAccordionContainer;
