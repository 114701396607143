import React, { useState, useContext } from "react";
import { Nav, Tab } from "react-bootstrap";
import CustomCard from "../../../ui/CustomCard/CustomCard";
import DashboardSectionTitle from "../DashboardSectionTitle/DashboardSectionTitle";
import DashboardFaq from "./DashboardFaq";
import DashboardForum from "./DashboardForum";
import DashboardDictionary from "./DashboardDictionary";
import { handleAlert } from "../../../util/util";

//Context
import AppContext from "../../../AppContext";

//Service
import recordService from "../../../services/Record";

function DashboardDiscoverContent() {
  const { setProfileStats } = useContext(AppContext);
  const [forums, setForums] = useState([]);
  const [dictionaryWords, setDictionaryWords] = useState([]);
  const [loading, setLoading] = useState(false);
  let actions = ["edit_forum", "create_thread", "view_threads"];

  async function getAllForums() {
    // setLoading(true);
    recordService
      .getAllForums()
      .then((response) => {
        if (response.data.status === "success") {
          console.log("Response from Forum : ", response.data);
          const forums = response.data.data.result.forums;

          let updatedRecords;
          updatedRecords = forums.map((record) => {
            return { ...record, actions: actions };
          });

          setForums(updatedRecords);
          // setLoading(false);
        }
        if (response.data.status === "failed") {
          console.log("ERROR:", response.data.error);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log("There is a problem in getting forums...", err);
      });
  }

  async function getAllDictionaryWords(index = "A") {
    setLoading(true);
    recordService
      .getAllDictionaryWords(index)
      .then((response) => {
        if (response.data.status === "success") {
          console.log("Response from Dictionary : ", response.data);
          const words = response.data.data.result.dictionary;
          console.log("Words =", words);
          console.log("Word title =", words[0].word);
          setDictionaryWords(words);
          setLoading(false);
        }
        if (response.data.status === "failed") {
          console.log("ERROR:", response.data.error);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log("There is a problem in getting dictionary words...", err);
        setLoading(false);
      });
  }

  async function getAllRandomDictionaryWords() {
    setLoading(true);
    recordService
      .getAllRandomDictionaryWords()
      .then((response) => {
        if (response.data.status === "success") {
          console.log("Response from Dictionary : ", response.data);
          const words = response.data.data.result.dictionary;
          console.log("Words =", words);
          console.log("Word title =", words[0].word);
          setDictionaryWords(words);
          setLoading(false);
        }
        if (response.data.status === "failed") {
          console.log("ERROR:", response.data.error);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log("There is a problem in getting dictionary words...", err);
        setLoading(false);
      });
  }

  async function searchAllDictionaryWords(keyword = "") {
    setLoading(true);
    recordService
      .searchAllDictionaryWords(keyword)
      .then((response) => {
        if (response.data.status === "success") {
          console.log("Response from Dictionary : ", response.data);
          const words = response.data.data.result.dictionary;
          console.log("Words =", words);
          console.log("Word title =", words[0].word);
          setDictionaryWords(words);
          setLoading(false);
        }
        if (response.data.status === "failed") {
          console.log(
            "Response from dictionary failed searched: ",
            response.data
          );
          console.log("ERROR:", response.data.error);
          setDictionaryWords([]);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log("There is a problem in getting dictionary words...", err);
        setDictionaryWords([]);
        setLoading(false);
      });
  }

  //Get Profile Stats
  function getUserProfileData() {
    recordService
      .getUserProfileData()
      .then((response) => {
        if (response.data.status === "success") {
          // console.log("Response from APP : ", response.data);
          const stats = response.data.data.result.details;
          setProfileStats(stats);
        }
        if (response.data.status === "failed") {
          console.log("ERROR:", response.data.error);
        }
      })
      .catch((err) => {
        console.log("There is a problem in fetching Profile Stats...", err);
      });
  }

  const handleSelectedTab = (key) => {
    console.log("Key in handleSelectedTab =", key);
    if (key == "discover_faq") {
      console.log("Entering into discover faq");
    }
    if (key == "discover_forum") {
      console.log("Entering into discover forum");
      //Fetch all forums from DB
      getAllForums();
    }
    if (key == "discover_dictionary") {
      console.log("Entering into discover dictionary");
      // getAllDictionaryWords();
      getAllRandomDictionaryWords();
    }
  };

  return (
    <>
      <DashboardSectionTitle
        iconImg="offer-icon7.png"
        title="Discover"
        pageTitle={true}
        className="mb2"
        withInfo={true}
        infoData="Discover more about what your unconscious experiences (e.g., dreams) mean, ask questions in the forum, and see frequently asked questions."
      />
      <CustomCard
        className={`main_card discover_content_card border-0 bg_transparent mb2`}
      >
        <Tab.Container
          id="discover-tabs-example"
          className="main_tabs"
          defaultActiveKey="discover_faq"
          onSelect={(key) => handleSelectedTab(key)}
        >
          <div className="tab_links">
            <Nav variant="pills" className="nav-justified">
              <Nav.Item>
                <Nav.Link className="normal_link" eventKey="discover_faq">
                  <div className="">
                    <img
                      src={`${process.env.PUBLIC_URL}/img/faq-icon.png`}
                      className="me-2"
                      alt="faq-icon"
                    />
                    FAQ's
                  </div>
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link className="normal_link" eventKey="discover_forum">
                  <div className="">
                    <img
                      src={`${process.env.PUBLIC_URL}/img/forum-icon.png`}
                      className="me-2"
                      alt="forum-icon"
                    />
                    Forum
                  </div>
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link
                  className="normal_link"
                  eventKey="discover_dictionary"
                >
                  <div className="">
                    <img
                      src={`${process.env.PUBLIC_URL}/img/dictionary-icon.png`}
                      className="me-2"
                      alt="dictionary-icon"
                    />
                    Dictionary
                  </div>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
          <Tab.Content>
            <Tab.Pane className="" eventKey="discover_faq">
              <DashboardFaq />
            </Tab.Pane>

            <Tab.Pane className="" eventKey="discover_forum">
              <DashboardForum
                forums={forums}
                setForums={setForums}
                getAllForums={getAllForums}
              />
            </Tab.Pane>

            <Tab.Pane className="" eventKey="discover_dictionary">
              <DashboardDictionary
                loading={loading}
                dictionaryWords={dictionaryWords}
                getAllDictionaryWords={getAllDictionaryWords}
                getAllRandomDictionaryWords={getAllRandomDictionaryWords}
                searchAllDictionaryWords={searchAllDictionaryWords}
                getUserProfileData={getUserProfileData}
              />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </CustomCard>
    </>
  );
}

export default DashboardDiscoverContent;
