import React from "react";
import { Card, Dropdown, Row, Col } from "react-bootstrap";
import CustomCard from "../../../ui/CustomCard/CustomCard";
import LeaderboardMemberCard from "./LeaderboardMemberCard";
import { BsThreeDotsVertical } from "react-icons/bs";
import PlaceholderCard from "../../../ui/PlaceholderCard/PlaceholderCard";
import SimpleMessageCard from "../../../ui/SimpleMessageCard/SimpleMessageCard";

const LoadingComponent = () => {
  return (
    <>
      {[0, 1, 2].map((_, i) => (
        <PlaceholderCard key={i} type="leaderboard" />
      ))}
    </>
  );
};

function Leaderboard(props) {
  const handleItemClick = (data) => {
    if (data !== "all") {
      props.getFilteredLeaderboard(data);
    } else {
      props.getLeaderboard();
    }
  };

  return (
    <>
      <CustomCard className={`main_card shadow1 border-0 mb2`}>
        <Card.Body>
          <Row className="align-items-center position-relative mb2">
            <Col>
              <div class="section_title">Leaderboard</div>
            </Col>
            <Dropdown
              align="end"
              className="position-absolute top-0 p-0"
              style={{ right: "0.5rem", width: "auto" }}
            >
              <Dropdown.Toggle
                className="btn_theme btn_gray btn_rounded btn_icon down_icon_hidden"
                id="dropdown-basic"
              >
                <BsThreeDotsVertical />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {/* <Dropdown.Item
                  as="button"
                  className="font-14"
                  onClick={() => {
                    handleItemClick("all");
                    //handleSaveGraph(props.analyzedPatterns.chart_data);
                  }}
                >
                  Show All
                </Dropdown.Item>
                <Dropdown.Divider /> */}
                <Dropdown.Item
                  as="button"
                  className="font-14"
                  onClick={() => {
                    handleItemClick("friends");
                  }}
                >
                  Friends
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item
                  as="button"
                  className="font-14"
                  onClick={() => {
                    handleItemClick("global");
                  }}
                >
                  Global
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Row>
          <div className="d-grid gap-3 pt1 pb1">
            {props.leaderboard.length > 0 ? (
              <>
                {!props.loading ? (
                  <>
                    {props.leaderboard.map((item) => {
                      return (
                        <LeaderboardMemberCard item={item} key={item.id} />
                      );
                    })}
                  </>
                ) : (
                  <>
                    <LoadingComponent />
                  </>
                )}
              </>
            ) : props.loading === true ? (
              <>
                <LoadingComponent />
              </>
            ) : (
              <>
                <SimpleMessageCard type="warning" allowIcon={true} />
              </>
            )}
          </div>
        </Card.Body>
      </CustomCard>
    </>
  );
}

export default Leaderboard;
