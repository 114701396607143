import React from "react";
import {
  Card,
  Row,
  Col,
  ListGroup,
  Placeholder,
  Button,
} from "react-bootstrap";
import CustomCard from "../../../ui/CustomCard/CustomCard";
import {
  BsCheckCircleFill,
  BsFillPencilFill,
  BsPencilSquare,
} from "react-icons/bs";
import PlaceholderCard from "../../../ui/PlaceholderCard/PlaceholderCard";

function GeneralSettings(props) {
  const currYear = new Date().getFullYear();

  const userEmail = props.user.email ? props.user.email : "";
  const userFirstname = props.user.firstname ? props.user.firstname : "";
  const userLastname = props.user.lastname ? props.user.lastname : "";
  const dob = props.user.date_of_birth
    ? formattedDate(new Date(props.user.date_of_birth))
    : "";
  const gender = props.user.gender ? props.user.gender : "";
  const education = props.user.education ? props.user.education : "";
  const phoneNo = props.user.phone_no ? props.user.phone_no : "";
  const userAddress = props.user.address ? (
    <>
      {props.user.address.address1 + ","}
      <br />
      {props.user.address.address2 + ","}
      <br />
      {props.user.address.city + " - "}
      {props.user.address.zipcode + "."}
      <br />
      {props.user.address.state + ", "}
      {props.user.country + "."}
    </>
  ) : (
    ""
  );

  const isOfferServices = props.user.is_offer_services
    ? props.user.is_offer_services
    : false;
  const isLicensed = props.user.is_licensed ? props.user.is_licensed : false;
  const licenseNumber = props.user.license_number
    ? props.user.license_number
    : "";
  const licenseYear = props.user.license_year ? props.user.license_year : 0;
  const languageSpoken = props.user.language_spoken
    ? props.user.language_spoken
    : "";
  const specialty = props.user.specialty ? props.user.specialty : [];
  const tenure =
    licenseYear > 0 ? <>{`More than ${currYear - licenseYear} years`}</> : "NA";
  const userExpertise = props.user.expertise ? props.user.expertise : "";
  const costPerSession = props.user.average_cost_per_session
    ? props.user.average_cost_per_session
    : 0;

  const handleOpenModal = () => {
    props.handleShow();
  };
  // console.log("props =", props);

  // Date formatting
  function formattedDate(d) {
    return [d.getFullYear(), d.getMonth() + 1, d.getDate()]
      .map((n) => (n < 10 ? `0${n}` : `${n}`))
      .join("-");
  }

  return (
    <>
      <CustomCard className="main_card general_settings_card border-0 mb2">
        <Card.Body>
          {!props.user.hasOwnProperty("email") && (
            <>
              <PlaceholderCard type="details" />
              {/* <div className="w-100 mb2 row">
                <Placeholder xs={5} bg="secondary" />
                <Placeholder xs={7} bg="secondary" />
              </div>
              <div className="w-100 mb2 row">
                <Placeholder xs={5} bg="secondary" />
                <Placeholder xs={7} bg="secondary" />
              </div>
              <div className="w-100 mb2 row">
                <Placeholder xs={5} bg="secondary" />
                <Placeholder xs={7} bg="secondary" />
              </div>
              <div className="w-100 mb2 row">
                <Placeholder xs={5} bg="secondary" />
                <Placeholder xs={7} bg="secondary" />
              </div>
              <div className="w-100 mb2 row">
                <Placeholder xs={5} bg="secondary" />
                <Placeholder xs={7} bg="secondary" />
              </div> */}
            </>
          )}

          {props.user.hasOwnProperty("email") && (
            <div className="w-100 pt1 pb2">
              <Button
                type="button"
                className="btn_theme btn_gray w-100"
                onClick={handleOpenModal}
              >
                <BsPencilSquare className="font-18 me-2" /> Update General
                Settings
              </Button>
            </div>
          )}

          <ListGroup as="ul" className="settings-list">
            {userEmail && (
              <ListGroup.Item as="li" className="">
                <Row className="w-100">
                  <Col xs={5} sm={5} md={5}>
                    <div className="settings_title">Username/Email:</div>
                  </Col>
                  <Col xs={7} sm={7} md={7}>
                    <div class="">{userEmail}</div>
                  </Col>
                </Row>
              </ListGroup.Item>
            )}
            {userFirstname && (
              <ListGroup.Item as="li" className="">
                <Row className="w-100">
                  <Col xs={5} sm={5} md={5}>
                    <div className="settings_title">First name:</div>
                  </Col>
                  <Col xs={7} sm={7} md={7}>
                    <div class="">{userFirstname}</div>
                  </Col>
                </Row>
              </ListGroup.Item>
            )}
            {userLastname && (
              <ListGroup.Item as="li" className="">
                <Row className="w-100">
                  <Col xs={5} sm={5} md={5}>
                    <div className="settings_title">Last name:</div>
                  </Col>
                  <Col xs={7} sm={7} md={7}>
                    <div class="">{userLastname}</div>
                  </Col>
                </Row>
              </ListGroup.Item>
            )}
            {dob && (
              <ListGroup.Item as="li" className="">
                <Row className="w-100">
                  <Col xs={5} sm={5} md={5}>
                    <div className="settings_title">Date of birth:</div>
                  </Col>
                  <Col xs={7} sm={7} md={7}>
                    <div class="">{dob}</div>
                  </Col>
                </Row>
              </ListGroup.Item>
            )}
            {gender && (
              <ListGroup.Item as="li" className="">
                <Row className="w-100">
                  <Col xs={5} sm={5} md={5}>
                    <div className="settings_title">Gender:</div>
                  </Col>
                  <Col xs={7} sm={7} md={7}>
                    <div class="text-capitalize">{gender}</div>
                  </Col>
                </Row>
              </ListGroup.Item>
            )}
            {userAddress && (
              <ListGroup.Item as="li" className="">
                <Row className="w-100">
                  <Col xs={5} sm={5} md={5}>
                    <div className="settings_title">Address:</div>
                  </Col>
                  <Col xs={7} sm={7} md={7}>
                    <div class="">
                      {userAddress} <br />
                    </div>
                  </Col>
                </Row>
              </ListGroup.Item>
            )}
            {phoneNo && (
              <ListGroup.Item as="li" className="">
                <Row className="w-100">
                  <Col xs={5} sm={5} md={5}>
                    <div className="settings_title">Phone no:</div>
                  </Col>
                  <Col xs={7} sm={7} md={7}>
                    <div class="">{phoneNo}</div>
                  </Col>
                </Row>
              </ListGroup.Item>
            )}

            {isOfferServices && (
              <>
                {isOfferServices && (
                  <ListGroup.Item as="li" className="">
                    <div className={`bg_success_light w-100 d-flex p1 mt1 mb1`}>
                      <BsCheckCircleFill className="font-18 me-2 text_success_dark" />
                      Offer services to uDreamed
                    </div>
                  </ListGroup.Item>
                )}
                {isLicensed && (
                  <ListGroup.Item as="li" className="">
                    <Row className="w-100">
                      <Col xs={5} sm={5} md={5}>
                        <div className="settings_title">License:</div>
                      </Col>
                      <Col xs={7} sm={7} md={7}>
                        <div class="">{isLicensed ? "Yes" : "No"}</div>
                      </Col>
                    </Row>
                  </ListGroup.Item>
                )}
                {licenseNumber && (
                  <ListGroup.Item as="li" className="">
                    <Row className="w-100">
                      <Col xs={5} sm={5} md={5}>
                        <div className="settings_title">License Number:</div>
                      </Col>
                      <Col xs={7} sm={7} md={7}>
                        <div class="">{licenseNumber}</div>
                      </Col>
                    </Row>
                  </ListGroup.Item>
                )}
                {licenseYear && (
                  <ListGroup.Item as="li" className="">
                    <Row className="w-100">
                      <Col xs={5} sm={5} md={5}>
                        <div className="settings_title">License Year:</div>
                      </Col>
                      <Col xs={7} sm={7} md={7}>
                        <div class="">{licenseYear}</div>
                      </Col>
                    </Row>
                  </ListGroup.Item>
                )}
                {education && (
                  <ListGroup.Item as="li" className="">
                    <Row className="w-100">
                      <Col xs={5} sm={5} md={5}>
                        <div className="settings_title">Education:</div>
                      </Col>
                      <Col xs={7} sm={7} md={7}>
                        <div class="">{education}</div>
                      </Col>
                    </Row>
                  </ListGroup.Item>
                )}
                {languageSpoken && (
                  <ListGroup.Item as="li" className="">
                    <Row className="w-100">
                      <Col xs={5} sm={5} md={5}>
                        <div className="settings_title">Language Spoken:</div>
                      </Col>
                      <Col xs={7} sm={7} md={7}>
                        <div class="">{languageSpoken}</div>
                      </Col>
                    </Row>
                  </ListGroup.Item>
                )}
                {specialty.length > 0 && (
                  <ListGroup.Item as="li" className="">
                    <Row className="w-100">
                      <Col xs={5} sm={5} md={5}>
                        <div className="settings_title">Specialty:</div>
                      </Col>
                      <Col xs={7} sm={7} md={7}>
                        <div class="">
                          {specialty.map((item) => item + ", ")}
                        </div>
                      </Col>
                    </Row>
                  </ListGroup.Item>
                )}
                {costPerSession >= 0 && (
                  <ListGroup.Item as="li" className="">
                    <Row className="w-100">
                      <Col xs={5} sm={5} md={5}>
                        <div className="settings_title">
                          Avg. Cost per Session:
                        </div>
                      </Col>
                      <Col xs={7} sm={7} md={7}>
                        <div class="">${costPerSession}</div>
                      </Col>
                    </Row>
                  </ListGroup.Item>
                )}

                {userExpertise && (
                  <ListGroup.Item as="li" className="">
                    <Row className="w-100">
                      <Col xs={5} sm={5} md={5}>
                        <div className="settings_title">
                          Expertise and services:
                        </div>
                      </Col>
                      <Col xs={7} sm={7} md={7}>
                        <div class="">{userExpertise}</div>
                      </Col>
                    </Row>
                  </ListGroup.Item>
                )}
              </>
            )}
          </ListGroup>
        </Card.Body>
      </CustomCard>
    </>
  );
}

export default GeneralSettings;
