import React from "react";
import { Container } from "react-bootstrap";
import styles from "./HomeFeatures.module.css";

import HomeSectionHeading from "../HomeSectionHeading/HomeSectionHeading";
import HomeSectionSubHeading from "../HomeSectionHeading/HomeSectionSubHeading";
import HomeFeaturesSlider from "../HomeFeaturesSlider/HomeFeaturesSlider";
// import HomeFeaturesSlider2 from "../HomeFeaturesSlider/HomeFeaturesSlider2";

function HomeFeatures(props) {
  return (
    <section className={`index_sect ${styles.offers_section}`}>
      <Container>
        <HomeSectionHeading
          htmlTag="h2"
          type="el"
          titleText="What uDreamed offers"
          className="text-center"
        />

        <HomeSectionSubHeading
          htmlTag=""
          type=""
          titleText="Unlocks the power of the unconscious mind"
          className="text_darkgray text-center fw_l"
        />

        <HomeFeaturesSlider featuresSlides={props.featuresSlides} />
      </Container>
    </section>
  );
}

export default HomeFeatures;
