import React from "react";
import { useNavigate } from "react-router-dom";
import { Card } from "react-bootstrap";
import CustomCard from "../../../ui/CustomCard/CustomCard";
import { BsBellFill } from "react-icons/bs";
import "./NotificationCard.css";

function NotificationCard(props) {
  let navigate = useNavigate();
  const notificationTime = props.notification.time_ago
    ? props.notification.time_ago
    : "";
  const notificationText = props.notification.text
    ? props.notification.text
    : "";

  const handleClick = (item) => {
    console.log("Clicked on notification =", item);
    const notificationData = {
      notification_id: item.notification_id,
    };
    const notificationType = item.type;
    props.markNotificationAsRead(notificationData);
    switch (notificationType) {
      case "share":
        navigate(`/dashboard/share`);
        props.getContentType("share");
        break;
      case "consult_approved":
        navigate(`/dashboard/consulting-sessions`);
        props.getContentType("consulting-sessions");
        break;
      case "friend_accepted":
        navigate(`/dashboard/network`);
        props.getContentType("network");
        break;
      case "group_accepted":
        navigate(`/dashboard/network`);
        props.getContentType("network");
        break;
      case "consult_payment_requested":
        navigate(`/dashboard/consulting-sessions`);
        props.getContentType("consulting-sessions");
        break;
    }
  };

  return (
    <>
      <CustomCard
        className={`notification_card main_card shadow1 border-0 mb2 ${
          !props.notification.is_read ? "active" : ""
        }`}
      >
        <span className="indicator"></span>
        <Card.Header
          className="border-0"
          onClick={() => {
            handleClick(props.notification);
          }}
        >
          <div className="media">
            <div className="media_icon_wrap d-flex align-items-center justify-content-center">
              <BsBellFill />
            </div>
            <div className={`media_body ms-3`}>
              <Card.Title className="card_title">
                {notificationText}
              </Card.Title>
              <Card.Subtitle className="card_subtitle">
                {notificationTime}
              </Card.Subtitle>
            </div>
          </div>
        </Card.Header>
      </CustomCard>
    </>
  );
}

export default NotificationCard;
