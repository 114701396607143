import React, { useState, useContext } from "react";
import { Card, Form, Button, Spinner } from "react-bootstrap";
import CustomCard from "../../../ui/CustomCard/CustomCard";
import { Field, Formik } from "formik";
import * as yup from "yup";
import Select from "react-select";
import { handleAlert, handleCustomSelectStyles } from "../../../util/util";

//Context
import AppContext from "../../../AppContext";
import ThemeContext from "../../../ThemeContext";

//Service
import recordService from "../../../services/Record";

function ShareGraph(props) {
  const { setExperiences, friends } = useContext(AppContext);
  const { theme } = useContext(ThemeContext);
  const [loading, setLoading] = useState(false);

  //React select styles
  const customStyles = handleCustomSelectStyles(theme);

  // const customStyles = {
  //   control: (provided, state) => ({
  //     ...provided,
  //     border: theme === "dark" ? "1px solid #666" : "1px solid #ddd",
  //     borderRadius: "30px",
  //     minHeight: "48px",
  //     backgroundColor: theme === "dark" ? "#333" : "#fff",
  //     color: "inherit",
  //     "&:focus": {
  //       borderWidth: "1px",
  //       borderColor: "#39b3ed",
  //       boxShadow: "none",
  //     },
  //     "&:hover": {
  //       borderColor: theme === "dark" ? "#666" : "#ddd",
  //     },
  //   }),
  //   singleValue: (provided) => ({
  //     ...provided,
  //     color: "inherit",
  //   }),
  //   placeholder: (provided) => ({
  //     ...provided,
  //     color: "#ffffff",
  //   }),
  //   menuList: (provided) => ({
  //     ...provided,
  //     backgroundColor: theme === "dark" ? "#444" : "#fff",
  //     padding: 0,
  //   }),
  //   option: (provided, { isFocused, isSelected }) => ({
  //     ...provided,
  //     background: isFocused ? "#999" : isSelected ? "#0991f6" : undefined,
  //   }),
  //   valueContainer: (provided, state) => ({
  //     ...provided,
  //     padding: "2px 12px",
  //     color: "inherit",
  //     zIndex: 1,
  //   }),
  // };

  const friendOptions = friends.map((friend) => {
    return { label: friend.name, value: friend.id };
  });

  const initialValues = {
    friends: null,
  };

  const shareGraphSchema = yup.object({
    friends: yup
      .object()
      .shape({
        label: yup.string().required(),
        value: yup.string().required(),
      })
      .nullable()
      .required("Required"),
  });

  const onSubmit = async (values, { resetForm }) => {
    console.log("Share graph Form Values :", values);
    // setLoading(true);
    // const newFriends = values.friends.map((friend) => Number(friend.value));
    const newFriends = Array.isArray(values.friends)
      ? values.friends[0].value
      : values.friends.value;
    console.log("New Memebrs=", newFriends, " Type = ", typeof newFriends);
    console.log(
      "--- SharedGraph analyzedPatterns chart_data=",
      props.chart_data
    );

    const newValues = {
      shared_user_id: newFriends,
      title: `Shared ${props.chart_data.element}`,
      type: "analyse",
      no_of_records: props.chart_data.total_no_of_records,
      chart_type: props.chart_data.chart,
      target: props.chart_data.target,
      chart_data: {
        labels: props.chart_data.labels,
        values: props.chart_data.values,
        totals: props.chart_data.totals,
        total_no_of_records: props.chart_data.total_no_of_records,
      },
    };

    console.log("New values in Share graph Form =", newValues);

    recordService
      .analyzeShareGraph(newValues)
      .then((response) => {
        if (response.data.status === "success") {
          console.log("Analyze share graph service res = ", response.data);
          handleAlert("You have successfully shared a graph!", "success");
          setLoading(false);
          resetForm({});
          props.handleCollapse("share_graph_collapse");
        } else if (response.data.status === "failed") {
          console.log("ERROR:", response.data.error);
          handleAlert("Failed to share graph!", "error");
          setLoading(false);
          resetForm({});
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <CustomCard className="mt2 mb2 pt2 pb2 px-3 bg_lightgray ">
      <div className="form_style">
        <Formik
          initialValues={initialValues}
          validationSchema={shareGraphSchema}
          onSubmit={onSubmit}
          //   enableReinitialize
        >
          {(formik) => {
            console.log("Formik =", formik);
            return (
              <Form onSubmit={formik.handleSubmit}>
                <Form.Group className="form-group rounded_corners px-1">
                  {/* <Form.Label>Add Recipient(s)</Form.Label> */}
                  <Select
                    styles={customStyles}
                    placeholder="Select Friend"
                    value={formik.values.friends}
                    onChange={(value) => {
                      formik.setFieldValue("friends", value);
                    }}
                    onBlur={() => {
                      formik.setFieldTouched("friends", true);
                    }}
                    isMulti={false}
                    options={friendOptions}
                    name="friends"
                  />
                  {formik.touched.friends && formik.errors.friends ? (
                    <p className="error-state-text">{formik.errors.friends}</p>
                  ) : null}
                </Form.Group>

                <div className="btn_wrap">
                  <Button
                    key="share_graph_submit_btn"
                    type="submit"
                    className="btn btn_theme btn_small btn_primary btn_rounded"
                  >
                    {loading ? (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />{" "}
                        Sharing
                      </>
                    ) : (
                      "Share Graph"
                    )}
                  </Button>

                  <Button
                    key="share_graph_cancel_btn"
                    type="button"
                    className="btn btn_theme btn_small btn_light btn_rounded"
                    onClick={() => {
                      props.handleCollapse("share_graph_collapse");
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </CustomCard>
  );
}

export default ShareGraph;
