import React from "react";
import { useNavigate } from "react-router-dom";
import { Card } from "react-bootstrap";
import CustomCard from "../../../ui/CustomCard/CustomCard";
import { TfiYoutube } from "react-icons/tfi";
import styles from "./DashboardYoutubeLinkCard.module.css";

function DashboardYoutubeLinkCard(props) {
  let navigate = useNavigate();

  function handleClick() {
    // console.log("Called handleClick");
    navigate(`/dashboard/videos`);
    props.getContentType("videos");
  }

  return (
    <CustomCard
      className={`${styles.youtube_link_card} main_card shadow1 border-0 mb2`}
    >
      <div onClick={handleClick}>
        <Card.Body className="d-flex justify-content-center flex-column text-center">
          <div
            className={`${styles.icon_wrap} rounded-circle mb1 d-flex justify-content-center align-items-center mx-auto`}
          >
            {/* <i className="fa fa-youtube" aria-hidden="true"></i> */}
            <TfiYoutube />
          </div>
          <p className="mb0">Watch uDreamed Videos</p>
        </Card.Body>
      </div>
    </CustomCard>
  );
}

export default DashboardYoutubeLinkCard;
