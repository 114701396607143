import React, { useState } from "react";
import { Card, Button, Spinner, Row, Col, Placeholder } from "react-bootstrap";
import CustomCard from "../../../ui/CustomCard/CustomCard";
import DictionaryCard from "../DashboardDiscoverContent/DictionaryCard";
import DashboardSectionTitle from "../DashboardSectionTitle/DashboardSectionTitle";
import { BsFillExclamationTriangleFill } from "react-icons/bs";

//Services
import recordService from "../../../services/Record";
import { callOpenAIAPI } from "../../../services/ChatService"; //OpenAI API Service

function DecodeContent(props) {
  const [loading, setLoading] = useState(false);
  const [decodedWords, setDecodedWords] = useState([]);
  console.log("DecodeContent recordDetails = ", props.recordDetails);
  const description = props.recordDetails.description
    ? props.recordDetails.description
    : "";
  const dictionary_words = props.recordDetails.dictionary_words
    ? props.recordDetails.dictionary_words
    : [];

  const description_decoded = props.recordDetails?.description_decoded
    ? props.recordDetails.description_decoded
    : [];

  //Decode function
  function handleDecode() {
    // console.log("Called function handleDecode()...");
    //Checking descriptionRef should not be empty...
    if (description) {
      setLoading(true);
      // console.log("Description is decoded");
      const descVal = { description: description };
      // console.log("descVal =", descVal);
      //Calling Decode function
      recordService
        .getDecodedDictionary(descVal)
        .then((response) => {
          if (response.data.status === "success") {
            console.log("Response from Record Form : ", response.data);
            const dictionary = response.data.data.result.dictionary;
            setDecodedWords(dictionary);
            setLoading(false);
            // setExperiences(records);
            // setIsDecoded(true);
            // getDecodedString(dictionary);
            // setDecodedDescription(dictionary);
          }
          if (response.data.status === "failed") {
            console.log("ERROR:", response.data);
            setDecodedWords([]);
            setLoading(false);
            // setIsDecoded(false);
            // setDecodedDescription([]);
          }
        })
        .catch((err) => {
          console.log("There is a problem in decoding description...", err);
          setLoading(false);
        });
    } else {
      console.log("Description is empty.");
    }
  }

  const wordsList = [
    {
      id: "word1",
      title: "Abandoned",
      shortDesc:
        "Symbolizes low self-esteem and need for support and security in your life.",
      longDesc:
        "Dreaming of being abandoned symbolizes feelings of low self-esteem and personal undesirable qualities that are hindering your personal and professional advancement more than concerns of desertion. If you have been feeling low or have suffered a setback in our personal or professional life, this dream should motivate you to take action to address any unresolved issues. Dreaming of abandoning loved ones signifies that you are overwhelmed by your personal problems and decisions in your life not to care about others.",
      votes: 3,
      votesPercentage: "33.33%",
      isAgree: false,
    },
    {
      id: "word2",
      title: "Abbey",
      shortDesc:
        "Symbolizes spirituality, inner peace and less anxiety in your life.",
      longDesc:
        "Dreaming of seeing a new abbey symbolizes spirituality, inner peace and less anxiety in your life. Things seem to be going your way and you seem to be satisfied with your life and your future. Dreaming of seeing a ruined abbey signifies feelings of loss, hopelessness and despair. You may have a habit of not to completing tasks or projects you started.",
      votes: 9,
      votesPercentage: "77.78%",
      isAgree: false,
    },
  ];
  // OpenAI Implementation
  const [openAIResponse, setOpenAIResponse] = useState("");
  const [ai_loading, setAiLoading] = useState(false); // This is for AI Interpretation Button
  //Get the already saved ai_description
  const ai_description = props.recordDetails.description_ai_decoded
    ? props.recordDetails.description_ai_decoded
    : "";

  // Function to handle OpenAI API call
  const handleAIInterpret = async () => {
    setAiLoading(true);
    const dream_description = description;
    if (!description.trim()) {
      setOpenAIResponse("Error: Description is empty.");
      return;
    }
    try {
      const response = await callOpenAIAPI({ message: dream_description }); // Replace with your actual API call
      setOpenAIResponse(response.data.message);
      setAiLoading(false);
    } catch (error) {
      console.error("Error calling OpenAI API:", error);
      setOpenAIResponse("Error: Please check back later.");
      setAiLoading(false);
    }
  };

  return (
    <>
      {dictionary_words?.length < 0 && (
        <>
          <div className="w-100 mb-1">
            <Placeholder xs={12} bg="secondary" />
          </div>
          <div className="w-100 mb1">
            <Placeholder xs={8} bg="secondary" />
          </div>
          <hr />
          <div className="w-100 mb-1">
            <Placeholder xs={12} bg="secondary" />
          </div>
          <div className="w-100 mb1">
            <Placeholder xs={8} bg="secondary" />
          </div>
          <hr />
          <div className="w-100 mb-1">
            <Placeholder xs={12} bg="secondary" />
          </div>
          <div className="w-100 mb1">
            <Placeholder xs={8} bg="secondary" />
          </div>
        </>
      )}

      {description && (
        <>
          <DashboardSectionTitle
            iconImg=""
            // title="What do you think the dream meant?"
            title="Full description"
            pageTitle={false}
            className="small_title mb1"
          />
          <div className="bg_lightgray p1 mb2">{description}</div>
          {/* <CustomCard className="shadow1 border-0 text-center">
            <Card.Body>
              <p className="mb-2">
                <b>Try Our AI Interpretation!</b>
              </p>
              <p className="mb-3">
                Click the button below to get an AI interpretation of your
                dream!
              </p> */}
          {/* <Button
                type="button"
                className="btn btn_theme btn_rounded btn_primary"
                onClick={handleAIInterpret}
                disabled={ai_loading}
              >
                {ai_loading ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />{" "}
                    Loading
                  </>
                ) : (
                  "AI Interpretation"
                )}
              </Button> */}

          {/* {ai_loading ? (
                <div class="center">
                  <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
                </div>
              ) : (
                <Button
                  type="button"
                  className="btn btn_theme btn_rounded btn_primary"
                  onClick={handleAIInterpret}
                  disabled={ai_loading}
                >
                  AI Interpretation
                </Button>
              )} */}
          {/* <p></p> */}
          {/* Conditional rendering for OpenAI response */}
          {/* {openAIResponse && (
                <p className="bg_lightgray p1 mb2 text-start">
                  {openAIResponse}
                </p>
              )} */}
          {/* </Card.Body>
          </CustomCard> */}
        </>
      )}
      <p></p>

      {/*****
       ******  AI Interpretation section
       *****/}
      {/**  If AI Description is not already saved **/}
      {ai_description === "" && description !== "" && openAIResponse === "" ? (
        <>
          <CustomCard className="shadow1 border-0 text-center mb2">
            <Card.Body>
              <p className="mb-2">
                <b>Try Our AI Interpretation!</b>
              </p>
              <p className="mb-3">
                Click the button below to get an AI interpretation of your
                dream!
              </p>
              {/* <Button
                type="button"
                className="btn btn_theme btn_rounded btn_primary"
                onClick={handleAIInterpret}
                disabled={ai_loading}
              >
                {ai_loading ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />{" "}
                    Loading
                  </>
                ) : (
                  "AI Interpretation"
                )}
              </Button> */}

              {ai_loading ? (
                <div class="center">
                  <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
                </div>
              ) : (
                <Button
                  type="button"
                  className="btn btn_theme btn_rounded btn_primary"
                  onClick={handleAIInterpret}
                  disabled={ai_loading}
                >
                  AI Interpretation
                </Button>
              )}

              {/* Conditional rendering for OpenAI response */}
              {/* {openAIResponse && (
                <p className="bg_lightgray p1 mb2 text-start">
                  {openAIResponse}
                </p>
              )} */}
            </Card.Body>
          </CustomCard>
        </>
      ) : (
        <>
          {/**  Show result If AI Interpretation is already present... **/}
          {ai_description !== "" && (
            <>
              <DashboardSectionTitle
                iconImg=""
                title="AI Interpretation"
                pageTitle={false}
                className="small_title mb1"
              />
              <p className="bg_lightgray p1 mb2 text-start">{ai_description}</p>
            </>
          )}
        </>
      )}

      {/**  Show Result of newly AI Interpretation description... **/}
      {ai_description === "" && description !== "" && openAIResponse !== "" && (
        <>
          <DashboardSectionTitle
            iconImg=""
            title="AI Interpretation"
            pageTitle={false}
            className="small_title mb1"
          />
          <p className="bg_lightgray p1 mb2 text-start">{openAIResponse}</p>
        </>
      )}

      {/*****
       ******  Decode words section
       *****/}
      {/**  If decode is not already saved **/}
      {dictionary_words?.length === 0 &&
      description !== "" &&
      decodedWords.length === 0 ? (
        <>
          <CustomCard className="shadow1 border-0 text-center">
            <Card.Body>
              <p className="mb-2">
                <b>Decode Not found!</b>
              </p>
              <p className="mb-3">
                You might not have decoded your description while recording an
                experience.
              </p>
              <Button
                type="button"
                className="btn btn_theme btn_rounded btn_primary"
                onClick={handleDecode}
                disabled={loading}
              >
                {loading ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />{" "}
                    Decoding
                  </>
                ) : (
                  "Decode now"
                )}
              </Button>
            </Card.Body>
          </CustomCard>
        </>
      ) : (
        <>
          {/**  Show result If decode is already present... **/}
          {dictionary_words.length > 0 &&
            dictionary_words.map((word) => {
              return (
                <DictionaryCard key={word.id} word={word} isVoted={true} />
              );
            })}
        </>
      )}
      {/**  Show Result of newly created decoded words... **/}
      {dictionary_words?.length === 0 &&
        description !== "" &&
        decodedWords.length > 0 && (
          <>
            {decodedWords.map((word) => {
              return (
                <DictionaryCard key={word.id} word={word} isVoted={true} />
              );
            })}
          </>
        )}
    </>
  );
}

export default DecodeContent;
