import axios from "axios";
import url from "../config/Config";
import authService from "./Auth";

const instance = axios.create({
  baseURL: url.baseUrl,
});

instance.interceptors.request.use(
  (config) => {
    let auth_token = localStorage.getItem("auth_token");
    // let auth_token = authService.getToken();
    // Object.assign(config.headers, { authorization: auth_token });
    config.headers.authorization = auth_token;
    // console.log("Config: ", config);
    return config;
  },
  (error) => {
    window.location.href = "/";
    return Promise.reject(error);
  }
);

export default instance;
