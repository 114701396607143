import React, { useState, useEffect } from "react";
import DashboardExperienceCard from "../DashboardExperienceCard/DashboardExperienceCard";
import DashboardSectionTitle from "../DashboardSectionTitle/DashboardSectionTitle";
import PlaceholderCard from "../../../ui/PlaceholderCard/PlaceholderCard";
import SimpleMessageCard from "../../../ui/SimpleMessageCard/SimpleMessageCard";
//Services
import recordService from "../../../services/Record";

function DashboardShareContent() {
  const [loading, setLoading] = useState(false);
  const [shareExpbankData, setShareExpbankData] = useState([]);
  let mainType = "share";
  let actions = ["share", "add_comment", "view_comments"];

  useEffect(() => {
    setLoading(true);
    async function getData() {
      if (shareExpbankData.length == 0) {
        setLoading(true);
      }

      recordService
        .getAllSharedRecords()
        .then((response) => {
          console.log(response.headers);

          if (response.data.status === "success") {
            console.log("Response from getAllSharedRecords : ", response.data);
            const records = response.data.data.result.records;

            let updatedRecords;
            updatedRecords = records.map((record) => {
              return { ...record, mainType: mainType, actions: actions };
            });
            setShareExpbankData(updatedRecords);
            setLoading(false);
          }
          if (response.data.status === "failed") {
            console.log("ERROR:", response.data.error);
          }
        })
        .catch((err) => {
          console.log("There is a problem in getting shared records...", err);
          setLoading(false);
        });
    }
    getData();
  }, []);

  return (
    <>
      <DashboardSectionTitle
        iconImg="offer-icon10.png"
        title="Share"
        pageTitle={true}
        className="mb2"
        withInfo={true}
        infoData="Share your unconscious experiences with whomever you choose. Others can also share with you and you can comment on each other's experiences."
      />

      {loading ? (
        <>
          {[0, 1].map((_, i) => (
            <PlaceholderCard key={i} />
          ))}
        </>
      ) : (
        <>
          {shareExpbankData.length > 0 ? (
            shareExpbankData.map((exp) => (
              <DashboardExperienceCard key={exp.record_id} exp={exp} />
            ))
          ) : (
            <SimpleMessageCard type="warning" />
          )}
        </>
      )}
    </>
  );
}

export default DashboardShareContent;
