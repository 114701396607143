import React from "react";
import { Card, Button } from "react-bootstrap";
import SocialLinksList from "../../SocialLinksList/SocialLinksList";
import styles from "./WebProfileCard.module.css";

function WebProfileCard({ id, member, handleShow }) {
  return (
    <>
      <Card className={`${styles.web_profile_card} border-0`}>
        <div className={styles["card-img"]}>
          <img
            src={`${process.env.PUBLIC_URL}/img/team/${member.profileImg}`}
            className="card-img-top"
            alt={member.name}
          />
        </div>
        <Card.Body>
          <h6>{member.name}</h6>
          <small>{member.designation}</small>
        </Card.Body>
        <Card.Footer className="border-top-0">
          <Button
            type="button"
            className="btn_theme btn_link btn_small btn_link_gray"
            onClick={() => {
              handleShow(member);
            }}
          >
            View Profile
          </Button>
          <div className="d-flex justify-content-center">
            {member.socialLinks?.length > 0 && (
              <SocialLinksList theme="white" />
            )}
          </div>
        </Card.Footer>
      </Card>
    </>
  );
}

export default WebProfileCard;
