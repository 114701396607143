import React, { useState } from "react";
import { Modal, Form, InputGroup, Button, Spinner } from "react-bootstrap";
import DashboardSectionTitle from "../../components/Dashboard/DashboardSectionTitle/DashboardSectionTitle";
import CustomRating from "../CustomRating/CustomRating";
import { Rating } from "react-simple-star-rating";
import FieldError from "../FieldError/FieldError";
import { Field, Formik } from "formik";
import * as yup from "yup";
import "./ConsultSubmitRatingsModal.css";
import { handleAlert } from "../../util/util";
import { BsEnvelope, BsStar } from "react-icons/bs";

//Service
import recordService from "../../services/Record";

function ConsultSubmitRatingsModal(props) {
  const [loading, setLoading] = useState(false);
  const [mainRating, setMainRating] = useState(0);
  const [ratingValue, setRatingValue] = useState(0);

  let mainRate = 0;

  const handleRating = (rate) => {
    const modRate = Math.round((5 * rate) / 100);
    setRatingValue(modRate);
    console.log("Rate by plugin = ", rate, "\nYour rating = ", modRate);
    // props.onUserRateChange(modRate);
    // if (props.hasOwnProperty("setMainRating")) {
    //   // props.setMainRating(modRate);
    // }

    // other logic
  };

  const onUserRateChange = (newVal) => {
    mainRate = newVal;
    setMainRating(newVal);
    // console.log("mainRate = ", mainRate);
  };

  const initialValues = {
    consult_id: "",
    rating: 0,
    rating_comment: "",
  };

  const consultSubmitRatingsSchema = yup.object({
    rating_comment: yup.string().required("Required"),
  });

  const onSubmit = async (values, { resetForm }) => {
    console.log("Consul Form Values :", values);
    // setLoading(true);

    const newValues = {
      ...values,
      consult_id: props.consult_id,
      rating: mainRating,
    };
    console.log("New values in Compose =", newValues);

    recordService
      .consultSubmitRatings(newValues)
      .then((response) => {
        if (response.data.status === "success") {
          console.log(
            "consultSubmitRatings() in ConsultSubmitRatingsModal ",
            response.data
          );
          handleAlert("Success! You have submitted your ratings.", "success");

          setLoading(false);
          resetForm({});

          //Close Message Modal if exist
          if (props.handleClose) {
            props.handleClose();
          }
          //get all consulting sessions to get updated trasactions
          props.getAllConsultingSessions();
        } else if (response.data.status === "failed") {
          // console.log("ERROR:", response.data.error);
          handleAlert("Error! Failed to submit ratings.", "error");
          setLoading(false);
          resetForm({});
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Modal
        centered
        size="md"
        className="theme-modal submit-ratings-modal"
        show={props.show.isVisible}
        onHide={props.handleClose}
      >
        <Modal.Header className="pb-3" closeButton>
          <Modal.Title className="d-flex align-items-center">
            <div className="heading-icon-wrap flex-shrink-0">
              <span className="heading-icon d-flex justify-content-center align-items-center rounded-circle font-24">
                <BsStar />
              </span>
            </div>
            <div className="heading flex-grow-1 ms-3">Feedback</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-helper-text mb-2">
            Give your ratings and share your comments. Your feedback is
            valuable!
          </div>
          <div className="form_style pt1 pb1">
            <DashboardSectionTitle
              iconImg=""
              title="Rate us"
              pageTitle={false}
              className="mb1 small_title"
            />
            <div className="mb2">
              {/* <Rating onClick={handleRating} /> */}
              <CustomRating
                initialValue={0}
                // ratingValue={0}
                // // readonly={true}
                // // setMainRating={setMainRating}
                onUserRateChange={onUserRateChange}
                allowHover={true}
                transition={true}
                showTooltip={true}
                // className=""
                size={26}
              />
            </div>
            <Formik
              initialValues={initialValues}
              validationSchema={consultSubmitRatingsSchema}
              onSubmit={onSubmit}
              // enableReinitialize
            >
              {(formik) => {
                return (
                  <Form onSubmit={formik.handleSubmit}>
                    <DashboardSectionTitle
                      iconImg=""
                      title="Share more with us"
                      pageTitle={false}
                      className="mb1 small_title"
                    />
                    <Form.Group className="form-group rounded_corners">
                      {/* <Form.Label>Message</Form.Label> */}
                      <Form.Control
                        as="textarea"
                        placeholder="Your comment"
                        style={{ height: "140px" }}
                        name="rating_comment"
                        {...formik.getFieldProps("rating_comment")}
                      />
                      <FieldError
                        valid={
                          formik.touched.rating_comment &&
                          formik.errors.rating_comment
                            ? true
                            : false
                        }
                        text={formik.errors.rating_comment}
                      />
                    </Form.Group>

                    <div className="btn_wrap pt2">
                      <Button
                        type="submit"
                        className="btn btn_theme btn_primary btn_rounded"
                      >
                        {loading ? (
                          <>
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />{" "}
                            Processing
                          </>
                        ) : (
                          "Submit"
                        )}
                      </Button>
                      <Button
                        type="button"
                        key="closeModalBtn"
                        className="btn_theme btn_light btn_rounded"
                        onClick={props.handleClose}
                      >
                        Cancel
                      </Button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ConsultSubmitRatingsModal;
