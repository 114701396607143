import React, { useState, useEffect } from "react";
import CustomCard from "../../../ui/CustomCard/CustomCard";
import { Tab, Nav } from "react-bootstrap";
import "./DashboardRecordSection.css";
import RecordDreams from "./RecordDreams";
import RecordEvents from "./RecordEvents";
import RecordThoughts from "./RecordThoughts";
import RecordOutOfBody from "./RecordOutOfBody";
import CustomCollapseButton from "../../../ui/CustomCollapse/CustomCollapseButton";
import CustomCollapseContainer from "../../../ui/CustomCollapse/CustomCollapseContainer";

function DashboardRecordSection(props) {
  useEffect(() => {
    handleCollapse("close_all");
  }, [props.page]);

  //collapsible state
  const [openRecordCollapse, setOpenRecordCollapse] = useState({
    record_dreams_collapse: false,
    record_events_collapse: false,
    record_thoughts_collapse: false,
    record_outofbody_collapse: false,
  });

  let tempState = null;
  const handleCollapse = (element) => {
    switch (element) {
      case "record_dreams":
        tempState = Object.fromEntries(
          Object.keys({ ...openRecordCollapse }).map((key) => [key, false])
        );
        setOpenRecordCollapse({
          ...tempState,
          record_dreams_collapse: !openRecordCollapse.record_dreams_collapse,
        });
        break;
      case "record_events":
        tempState = Object.fromEntries(
          Object.keys({ ...openRecordCollapse }).map((key) => [key, false])
        );
        setOpenRecordCollapse({
          ...tempState,
          record_events_collapse: !openRecordCollapse.record_events_collapse,
        });
        break;
      case "record_thoughts":
        tempState = Object.fromEntries(
          Object.keys({ ...openRecordCollapse }).map((key) => [key, false])
        );
        setOpenRecordCollapse({
          ...tempState,
          record_thoughts_collapse:
            !openRecordCollapse.record_thoughts_collapse,
        });
        break;
      case "record_outofbody":
        tempState = Object.fromEntries(
          Object.keys({ ...openRecordCollapse }).map((key) => [key, false])
        );
        setOpenRecordCollapse({
          ...tempState,
          record_outofbody_collapse:
            !openRecordCollapse.record_outofbody_collapse,
        });
        break;
      case "close_all":
        setOpenRecordCollapse({
          record_dreams_collapse: false,
          record_events_collapse: false,
          record_thoughts_collapse: false,
          record_outofbody_collapse: false,
        });
    }
  };

  return (
    <CustomCard className="main_card record_opt_card shadow1 border-0 mb4">
      <div className="text-center text_gray font-18 fw_sb pt2 pb2">
        Record your unconscious experiences
      </div>
      <Tab.Container
        id="left-tabs-example"
        className="main_tabs"
        defaultActiveKey=""
      >
        <div className="tab_links tab_links_button">
          <Nav variant="pills" className="nav-justified">
            <Nav.Item>
              <CustomCollapseButton
                handleCollapse={handleCollapse}
                btnTitle="Dreams"
                iconImg="dreams-icon.png"
                className="dreams_tablink nav-link"
                ariaControls="record_dreams"
                ariaExpanded={openRecordCollapse.record_dreams_collapse}
              />
            </Nav.Item>
            <Nav.Item>
              <CustomCollapseButton
                handleCollapse={handleCollapse}
                btnTitle="Events"
                iconImg="events-icon.png"
                className="events_tablink nav-link"
                ariaControls="record_events"
                ariaExpanded={openRecordCollapse.record_events_collapse}
              />
            </Nav.Item>
            <Nav.Item>
              <CustomCollapseButton
                handleCollapse={handleCollapse}
                btnTitle="Thoughts"
                iconImg="thoughts-icon.png"
                className="thoughts_tablink nav-link"
                ariaControls="record_thoughts"
                ariaExpanded={openRecordCollapse.record_thoughts_collapse}
              />
            </Nav.Item>
            <Nav.Item>
              <CustomCollapseButton
                handleCollapse={handleCollapse}
                btnTitle="Out of Body"
                iconImg="experience-icon.png"
                className="outofbody_tablink nav-link"
                ariaControls="record_outofbody"
                ariaExpanded={openRecordCollapse.record_outofbody_collapse}
              />
            </Nav.Item>
          </Nav>
        </div>

        <div>
          <CustomCollapseContainer
            ariaControls="record_dreams"
            openCollapse={openRecordCollapse.record_dreams_collapse}
          >
            <div className="p2">
              <div className="font-16 fw_sb mb1">Record dreams</div>
              <RecordDreams
                handleCollapse={handleCollapse}
                getContentType={props.getContentType}
              />
            </div>
          </CustomCollapseContainer>

          <CustomCollapseContainer
            ariaControls="record_events"
            openCollapse={openRecordCollapse.record_events_collapse}
          >
            <div className="p2">
              <div className="font-16 fw_sb mb1">Record events</div>
              <RecordEvents
                handleCollapse={handleCollapse}
                getContentType={props.getContentType}
              />
            </div>
          </CustomCollapseContainer>

          <CustomCollapseContainer
            ariaControls="record_thoughts"
            openCollapse={openRecordCollapse.record_thoughts_collapse}
          >
            <div className="p2">
              <div className="font-16 fw_sb mb1">Record thoughts</div>
              <RecordThoughts
                isAddMode={true}
                handleCollapse={handleCollapse}
              />
            </div>
          </CustomCollapseContainer>

          <CustomCollapseContainer
            ariaControls="record_outofbody"
            openCollapse={openRecordCollapse.record_outofbody_collapse}
          >
            <div className="p2">
              <div className="font-16 fw_sb mb1">
                Record out of body experiences
              </div>
              <RecordOutOfBody
                isAddMode={true}
                handleCollapse={handleCollapse}
              />
            </div>
          </CustomCollapseContainer>
        </div>
      </Tab.Container>
    </CustomCard>
  );
}

export default DashboardRecordSection;
