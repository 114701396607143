import React from "react";
import { ListGroup } from "react-bootstrap";
import { FaFacebookF, FaTwitter, FaLinkedinIn } from "react-icons/fa";
import "./SocialLinksList.css";

function SocialLinksList(props) {
  let themeClass = "";
  switch (props.theme) {
    case "white":
      themeClass = "theme_white";
      break;
    default:
      themeClass = "";
  }
  const classList = `social_links ${themeClass}`;

  return (
    <>
      <ListGroup as="ul" horizontal className={classList}>
        <ListGroup.Item as="li" className="fb">
          <a href="" target="_blank">
            <FaFacebookF />
          </a>
        </ListGroup.Item>
        <ListGroup.Item as="li" className="twit">
          <a href="" target="_blank">
            <FaTwitter />
          </a>
        </ListGroup.Item>
        <ListGroup.Item as="li" className="linkedin">
          <a href="" target="_blank">
            <FaLinkedinIn />
          </a>
        </ListGroup.Item>
      </ListGroup>
    </>
  );
}

export default SocialLinksList;
