import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import "./UpdateGeneralSettingsModal.css";
import UpdateGeneralSettings from "../../components/Dashboard/DashboardSettingsContent/UpdateGeneralSettings";
import { BsGear, BsPencilSquare } from "react-icons/bs";

function UpdateGeneralSettingsModal(props) {
  return (
    <>
      <Modal
        centered
        size="lg"
        className="theme-modal update-settings-modal"
        show={props.show.isVisible}
        onHide={props.handleClose}
      >
        <Modal.Header className="pb-2" closeButton>
          <Modal.Title className="d-flex align-items-center">
            <div className="heading-icon-wrap flex-shrink-0">
              <span className="heading-icon d-flex justify-content-center align-items-center rounded-circle font-24">
                <BsPencilSquare />
              </span>
            </div>
            <div className="heading flex-grow-1 ms-3">Update Settings</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-helper-text mb-3">
            Please update your complete profile
          </div>

          <UpdateGeneralSettings
            isAddMode={false}
            currentUser={props.currentUser}
            handleClose={props.handleClose}
            getUserDetails={props.getUserDetails}
          />
          {/* <ComposeMessage
            friends={props.friends}
            initialValues={props.initialValues}
            action={props.action}
            handleClose={props.handleClose}
          /> */}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default UpdateGeneralSettingsModal;
