import Button from "@restart/ui/esm/Button";
import React, { useState } from "react";
import { Card } from "react-bootstrap";
import CustomCard from "../../../ui/CustomCard/CustomCard";
import DashboardSectionTitle from "../DashboardSectionTitle/DashboardSectionTitle";
import "./SearchedUserCard.css";
import { handleAlert } from "../../../util/util";

//Service
import recordService from "../../../services/Record";

function SearchedUserCard({
  user,
  // removeFriend,
  composeMessage,
  handleAction,
  // handleRemoveFriend,
  handleShow,
  selectedUser,
  setSelectedUser,
}) {
  const [hasInvited, setHasInvited] = useState(user?.has_invited);

  // console.log("User in searched user card =", user);
  // const handleRemoveFriend = (user) => {
  //   // console.log("handleRemoveFriend of SearchedUserCard...");

  //   // console.log("Friend Id in Handle Remove friend user=", user);
  //   const id = user.user_id;
  //   // console.log("Friend Id in Handle Remove friend id=", id);
  //   removeFriend(user.user_id);
  //   // if (id) {
  //   //   removeFriend(id);
  //   // }
  // };

  const handleClick = () => {
    // selectedUser.u_id = user.user_id;
    setSelectedUser({ u_id: user.user_id });
    // console.log("selectedUser: ", selectedUser);
    handleShow();
  };

  //Send friend request on connect button click
  const handleConnect = (id) => {
    console.log("Connect Id =", id);

    if (id) {
      let addFriendData = {
        friend_id: id,
        message: "",
        is_consult: false,
      };
      recordService
        .addFriend(addFriendData)
        .then((response) => {
          if (response.data.status === "success") {
            // console.log("If status-success =", response.data);
            // console.log("msg =", response.data.message);
            handleAlert(response.data.message, "success");
            setHasInvited(true);
          }
          if (response.data.status === "failed") {
            // console.log("If status-failed =", response.data);
            // console.log("msg =", response.data.error);
            handleAlert(response.data.error, "error");
            setHasInvited(user?.has_invited);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  function handleComposeMessage(e) {
    console.log("In handleComposeMessage =", e);
    let actionBody = {
      action: "message_compose",
      data: {
        users: [
          {
            label: e.name,
            value: e.user_id,
          },
        ],
        message: "",
      },
    };

    handleAction(actionBody);
  }

  return (
    <>
      <CustomCard
        className={`network_friend_card main_card shadow1 border-0 text-center`}
      >
        <Card.Body>
          <div
            className={`icon_wrap mx-auto rounded-circle overflow-hidden mb1`}
          >
            <img 
              src={(user.avatar !== "NULL" && user.avatar) || `${process.env.PUBLIC_URL}/img/user-profile.jpg`} 
              alt={`${user.name} profile pic`} />
            {/* <img
            src={`${process.env.PUBLIC_URL}/img/${user.profileImg}`}
            alt="user profile"
          /> */}
          </div>
          {/* <div className="card-title">{user.name}</div> */}

          <DashboardSectionTitle
            iconImg=""
            title={`${user.name}`}
            pageTitle={false}
            className="mb1"
          />

          {!user.is_friend && (
            <Button
              type="button"
              className="btn btn_theme btn_small btn_success btn_rounded"
              onClick={() => {
                handleConnect(user.user_id);
              }}
              disabled={hasInvited}
            >
              {hasInvited ? "Requested" : "Connect"}
            </Button>
          )}
          {user.is_friend && (
            <div className="btn_wrap">
              <Button
                type="button"
                className="btn btn_theme btn_small btn_primary btn_rounded"
                onClick={() => {
                  handleComposeMessage(user);
                }}
              >
                Message
              </Button>
              <Button
                type="button"
                className="btn btn_theme btn_small btn_danger btn_rounded"
                onClick={handleClick}
              >
                Unfriend
              </Button>
            </div>
          )}
        </Card.Body>
      </CustomCard>
    </>
  );
}

export default SearchedUserCard;
