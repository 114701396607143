import React, { useState, useEffect, useContext } from "react";
import {
  Row,
  Col,
  Card,
  Collapse,
  Dropdown,
  DropdownButton,
  ListGroup,
  Form,
  Button,
  Spinner,
  Placeholder,
} from "react-bootstrap";
import CustomCard from "../../../ui/CustomCard/CustomCard";
import DashboardSectionTitle from "../DashboardSectionTitle/DashboardSectionTitle";
import ShareGraph from "./ShareGraph";
import DashboardBarChart from "../DashboardCharts/DashboardBarChart";
import DashboardPieChart from "../DashboardCharts/DashboardPieChart";
import DashboardWordcloudChart from "../DashboardCharts/DashboardWordcloudChart";
import { Field, Formik } from "formik";
import * as yup from "yup";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { addDays, subDays } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import {
  handleAlert,
  formatDateYMD,
  handleCustomSelectStyles,
} from "../../../util/util";
import {
  BsFillExclamationTriangleFill,
  BsThreeDotsVertical,
  BsFillNutFill,
  BsFunnelFill,
  BsGearFill,
} from "react-icons/bs";
import { FaFilter } from "react-icons/fa";
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/scale.css";
import { bgcolors } from "../../../util/colors";

//Service
import recordService from "../../../services/Record";

import ThemeContext from "../../../ThemeContext";

function DashboardAnalyzePattern(props) {
  const today = formatDateYMD(new Date());
  const { theme } = useContext(ThemeContext);
  const [loading, setLoading] = useState(false);
  const [analyzedPatterns, setAnalyzedPatterns] = useState({});
  // const [analyzedPatterns, setAnalyzedPatterns] = useState({});
  const [labels, setLabels] = useState([]);
  const [meDataset, setMeDataset] = useState([]);
  const [friendsDataset, setFriendsDataset] = useState([]);
  const [globalDataset, setGlobalDataset] = useState([]);
  const [datasets, setDataSets] = useState([]);
  const [fullDatasets, setFullDatasets] = useState([]);
  const [smallDatasets, setSmallDataSets] = useState([]);
  const [recordCount, setRecordCount] = useState(0);
  const [datasetLength, setDatasetLength] = useState(0);
  const [selectedChart, setSelectedChart] = useState("bar");
  const [shareGraphCollapse, setShareGraphCollapse] = useState(false);
  const [patternFiltersCollapse, setPatternFiltersCollapse] = useState(false);
  const [analyzedPatternsFilters, setAnalyzedPatternsFilters] = useState({
    record_type: "Dream",
    target: "Global",
    end_date: today,
    start_date: "2021-02-28",
    element: "Emotions",
  });

  function handleClick(e) {
    const element = e.target.getAttribute("aria-controls");
    let isAriaExpanded = e.target.getAttribute("aria-expanded");
    // console.log("Association Card =", e);
    handleCollapse(element);
  }
  function handleCollapse(element) {
    console.log("In Analyze Pattern Comp =", element);
    if (element === "share_graph_collapse") {
      setShareGraphCollapse(!shareGraphCollapse);
    } else if (element === "pattern_filters_collapse") {
      setPatternFiltersCollapse(!patternFiltersCollapse);
    }
  }

  // console.log("Today =", today);

  // const bgcolors = [
  //   "#81d4fa",
  //   "#26c6da",
  //   "#4db6ac",
  //   "#81c784",
  //   "#aed581",
  //   "#dce775",
  //   "#fff176",
  //   "#ffb74d",
  //   "#ff8a65",
  //   "#a1887f",
  //   "#90a4ae",
  //   "#42a5f5",
  //   "#7986cb",
  //   "#b39ddb",
  //   "#ba68c8",
  //   "#f48fb1",
  //   "#e57373",
  //   "#b39d9d",
  // ];

  //React select styles
  const customStyles = handleCustomSelectStyles(theme);

  // const customStyles = {
  //   control: (provided, state) => ({
  //     ...provided,
  //     border: theme === "dark" ? "1px solid #666" : "1px solid #ddd",
  //     borderRadius: "30px",
  //     minHeight: "48px",
  //     backgroundColor: theme === "dark" ? "#333" : "#fff",
  //     color: "inherit",
  //     "&:focus": {
  //       borderWidth: "1px",
  //       borderColor: "#39b3ed",
  //       boxShadow: "none",
  //     },
  //     "&:hover": {
  //       borderColor: theme === "dark" ? "#666" : "#ddd",
  //     },
  //   }),
  //   input: (provided) => ({
  //     ...provided,
  //     color: "inherit",
  //   }),
  //   singleValue: (provided) => ({
  //     ...provided,
  //     color: "inherit",
  //   }),
  //   placeholder: (provided) => ({
  //     ...provided,
  //     color: "#ffffff",
  //   }),
  //   menuList: (provided) => ({
  //     ...provided,
  //     backgroundColor: theme === "dark" ? "#444" : "#fff",
  //     padding: 0,
  //     zIndex: 1,
  //   }),
  //   option: (provided, { isFocused, isSelected }) => ({
  //     ...provided,
  //     background: isFocused ? "#999" : isSelected ? "#0991f6" : undefined,
  //   }),
  //   valueContainer: (provided, state) => ({
  //     ...provided,
  //     padding: "2px 12px",
  //     color: "inherit",
  //     // zIndex: 1,
  //   }),
  // };

  const recordRadioOptions = [
    { key: "All", value: "all" },
    { key: "Dream", value: "dream" },
    { key: "Event", value: "event" },
    { key: "Out of Body", value: "outofbody" },
    { key: "Thought", value: "thought" },
  ];

  const targetRadioOptions = [
    { key: "Me", value: "me" },
    { key: "Friends", value: "friends" },
    { key: "Global", value: "global" },
    { key: "Me to Friends", value: "me_friends" },
    { key: "Me to Global", value: "me_global" },
    { key: "Me to Friends and Global", value: "me_friends_global" },
  ];

  const chartRadioOptions = [
    { key: "Bar", value: "bar" },
    { key: "Pie", value: "pie" },
    { key: "Word Cloud", value: "wordcloud" },
  ];

  const elementOptions = [
    { label: "Locations in experience", value: "locations" },
    { label: "Characters in experience", value: "characters" },
    { label: "Interactions between characters", value: "interactions" },
    { label: "Emotions in experience", value: "emotions" },
    // { label: "Transformations in experience", value: "transformations" },
    // { label: "Unusal circumstances", value: "circumstances" },
    { label: "Causes of experience", value: "causes" },
    { label: "Resolutions", value: "resolutions" },
    // { label: "Occurrence frequency", value: "occurrence_frequency" },
    // { label: "Time frame", value: "time_frame" },
  ];

  const elementOptions2 = [
    { label: "Causes of experience", value: "causes" },
    { label: "Resolutions", value: "resolutions" },
  ];

  useEffect(() => {
    console.log("in useEffect =", props.analyzedPatterns);
    const chart_data = props.analyzedPatterns.chart_data;
    console.log("chart_data in useEffect =", chart_data);
    const target = chart_data?.target;
    console.log("target in useEffect =", target);
    const chart = chart_data?.chart;
    console.log("chart in useEffect =", chart);

    if (props.analyzedPatterns.hasOwnProperty("chart_data")) {
      createChartData(chart_data, "full", chart, target);
    }
  }, [props.analyzedPatterns]);

  const handleSaveGraph = (data) => {
    console.log("--------- save chart function -----------");
    console.log("data=", data);
    console.log("--------------------");
    //Calling actual service method to save chart...
    saveGraph(data);
  };

  async function saveGraph(data) {
    const newData = {
      title: `Saved ${data.element}`,
      type: "analyse",
      no_of_records: data.total_no_of_records,
      chart_type: data.chart,
      target: data.target,
      chart_data: {
        labels: data.labels,
        values: data.values,
        totals: data.totals,
        total_no_of_records: data.total_no_of_records,
      },
    };
    console.log("--- saveGraph data para =", data);
    console.log("----------------");
    console.log("--- saveGraph =", newData);
    console.log("----------------");
    recordService
      .analyzeSaveGraph(newData)
      .then((response) => {
        if (response.data.status === "success") {
          console.log("Analyze service res = ", response.data);
          handleAlert("You have successfully saved a graph!", "success");
          //Get Profile Stats
          props.getUserProfileData();
        } else if (response.data.status === "failed") {
          console.log("ERROR:", response.data.error);
          handleAlert("Failed to save graph!", "error");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function createChartData(
    data,
    dataLength = "full",
    chartOption = "bar",
    targetOption = "me"
  ) {
    // let newLabels = data.labels.map((label) => {
    //   //Capitalize first letter
    //   return label.charAt(0).toUpperCase() + label.slice(1);
    // });
    console.log("chart_data in createChartdata =", data);
    console.log("dataLength in createChartdata =", dataLength);
    console.log("targetOption =", targetOption);
    console.log("chartOption =", chartOption);
    let newLabels = [];

    newLabels = data.labels.map((label) => {
      //Capitalize first letter
      return label.charAt(0).toUpperCase() + label.slice(1);
    });

    setLabels(newLabels);
    setRecordCount(data.total_no_of_records);

    let tempDataSets = [];
    let allChartsData = [];
    let chartTitle = "";
    let totalRecords = 0;
    let tempLegendList = [];
    if (chartOption === "wordcloud") {
      let values = null;
      console.log("---- I am going to execute ----");
      if (data.values.hasOwnProperty("me")) {
        values = data.values.me;
      } else if (data.values.hasOwnProperty("friends")) {
        values = data.values.friends;
      } else if (data.values.hasOwnProperty("global")) {
        values = data.values.global;
      }
      tempDataSets = newLabels.map((label, index) => {
        return {
          text: label,
          value: values[index],
        };
      });
      console.log(
        "In if (chartOption == wordcloud) - tempDataSets =",
        tempDataSets
      );
    } else {
      console.log("I am in else I have more chance to be executed");
      if (data.values.hasOwnProperty("me")) {
        console.log("MeDataSet = ", data.values.me);

        if (
          targetOption !== "me_friends" &&
          targetOption !== "me_global" &&
          targetOption !== "me_friends_global"
        ) {
          tempLegendList = data.values.me.map((item, index) => {
            return {
              color: bgcolors[index],
              value: item,
              label: newLabels[index],
            };
          });
        }
        tempDataSets.push({
          label: "Me",
          data: data.values.me,
          // data: tempData,
          barPercentage: 1,
          // barThickness: 10,
          maxBarThickness: 10,
          // minBarLength: 4,
          // borderColor: "rgb(0, 153, 255)",
          // backgroundColor: "rgba(0, 153, 255, 0.5)",
          backgroundColor:
            targetOption == "me_friends" ||
            targetOption == "me_global" ||
            targetOption == "me_friends_global"
              ? "rgba(0, 153, 255, 0.5)"
              : bgcolors,
          hoverBorderRadius: 4,
          hoverBorderColor: "rgba(0,0,0,0.4)",
          // hoverBackgroundColor: "rgba(0, 153, 255, 0.7)",
        });
      }
      if (data.values.hasOwnProperty("friends")) {
        console.log("friendsDataSet = ", data.values.friends);
        // let tempData = [];
        // if (dataLength == "full") {
        //   tempData = data.values.friends;
        // } else {
        //   for (let i = 0; i < dataLength; i++) {
        //     tempData[i] = data.values.friends[i];
        //   }
        // }
        if (
          targetOption !== "me_friends" &&
          targetOption !== "me_global" &&
          targetOption !== "me_friends_global"
        ) {
          tempLegendList = data.values.friends.map((item, index) => {
            return {
              color: bgcolors[index],
              value: item,
              label: newLabels[index],
            };
          });
        }

        tempDataSets.push({
          label: "Friends",
          data: data.values.friends,
          // data: tempData,
          barPercentage: 1,
          // barThickness: 10,
          maxBarThickness: 10,
          // minBarLength: 4,
          // borderColor: "rgb(255, 204, 0)",
          backgroundColor:
            targetOption == "me_friends" ||
            targetOption == "me_global" ||
            targetOption == "me_friends_global"
              ? "rgba(255, 204, 0, 0.7)"
              : bgcolors,
          // backgroundColor: bgcolors,
          hoverBorderRadius: 4,
          hoverBorderColor: "rgba(0,0,0,0.4)",
          // hoverBackgroundColor: "rgba(255, 204, 0, 0.85)",
        });
      }
      if (data.values.hasOwnProperty("global")) {
        console.log("globalDataSet = ", data.values.global);
        // let tempData = [];
        // if (dataLength == "full") {
        //   tempData = data.values.global;
        // } else {
        //   for (let i = 0; i < dataLength; i++) {
        //     tempData[i] = data.values.global[i];
        //   }
        // }

        if (
          targetOption !== "me_friends" &&
          targetOption !== "me_global" &&
          targetOption !== "me_friends_global"
        ) {
          tempLegendList = data.values.global.map((item, index) => {
            return {
              color: bgcolors[index],
              value: item,
              label: newLabels[index],
            };
          });
        }

        tempDataSets.push({
          label: "Global",
          data: data.values.global,
          // data: tempData,
          barPercentage: 1,
          // barThickness: 10,
          maxBarThickness: 10,
          // minBarLength: 4,
          // borderColor: "rgb(51, 204, 102)",
          backgroundColor:
            targetOption == "me_friends" ||
            targetOption == "me_global" ||
            targetOption == "me_friends_global"
              ? "rgba(51, 204, 102, 0.5)"
              : bgcolors,
          // backgroundColor: bgcolors,
          hoverBorderRadius: 4,
          hoverBorderColor: "rgba(0,0,0,0.4)",
          // hoverBackgroundColor: "rgba(51, 204, 102, 0.7)",
        });
      }
    }

    if (data.element) {
      chartTitle = elementOptions
        .filter((element) => element.value === data.element)
        .pop().label;
    }
    if (data.total_no_of_records) {
      totalRecords = data.total_no_of_records;
    }

    // console.log(
    //   "chartTitle in dasboard Analayze pattern createChartData = ",
    //   chartTitle
    // );

    console.log("In outer tempDatasets = ", tempDataSets);

    if (tempDataSets.length > 0) {
      allChartsData.push({
        chart_type: chartOption,
        labels: newLabels,
        datasets: tempDataSets,
        total_records: totalRecords,
        chart_title: chartTitle,
        legend_list: tempLegendList,
      });
    }

    if (allChartsData.length > 0) {
      setFullDatasets(allChartsData);
      // setTimeout(() => {
      //   setLoading(false);
      // }, 1000);
    }

    if (tempDataSets.length > 0) {
      setDataSets(tempDataSets);
    }
    if (datasets.length > 0) {
      console.log("In datasets.length > 0 = ", datasets);
    }
  }

  const initialValues = {
    record_type: "dream",
    target: "global",
    chart_options: "bar",
    element: elementOptions[3],
    start_date: new Date("2021-02-28"),
    // end_date: new Date("2022-05-31"),
    end_date: new Date(today),
  };
  const analyzePatternSchema = yup.object({
    target: yup.string().required("Required"),
    element: yup
      .string()
      .transform((_, input) => {
        console.log("input = ", input);
        return input != null && input.toString ? input.toString() : "";
      })
      .required("Required"),
    start_date: yup.date().required("Required").nullable(),
    end_date: yup.date().required("Required").nullable(),
  });

  const onSubmit = async (values, { resetForm }) => {
    setLoading(true);
    console.log("Analyze Pattern Form values: ", values);

    let newStartDate = formatDateYMD(values.start_date);
    let newEndDate = formatDateYMD(values.end_date);
    const newElement = values.element ? values.element.value : "";

    const newValues = {
      ...values,
      start_date: newStartDate,
      end_date: newEndDate,
      element: newElement,
    };

    console.log("Analyze Pattern New values: ", newValues);
    //Set selected chart option
    console.log("values.chart_options = ", values.chart_options);
    setSelectedChart(values.chart_options);

    recordService
      .analyzePattern(newValues)
      .then((response) => {
        if (response.data.status === "success") {
          console.log("Analyze service res = ", response.data);

          const chart_data = response.data.data.result.chart_data;
          const patterns = response.data.data.result.patterns;
          console.log("records = ", chart_data);
          console.log("Labels = ", response.data.data.result.chart_data.labels);

          console.log("In onSubmit selectedChart = ", selectedChart);
          console.log(
            "In onSubmit service success - values.chart_options = ",
            values.chart_options
          );
          let temp_chart_data = {
            ...response.data.data.result,
            chart_data: {
              ...chart_data,
              target: values.target,
              chart: values.chart_options,
              element: newElement,
            },
          };
          console.log("temp_chart_data in onSubmit =", temp_chart_data);
          // props.setAnalyzedPatterns(response.data.data.result);
          props.setAnalyzedPatterns(temp_chart_data);
          handleCollapse("pattern_filters_collapse");
          setAnalyzedPatternsFilters({
            record_type:
              values.record_type.charAt(0).toUpperCase() +
              values.record_type.slice(1),
            target: targetRadioOptions
              .filter((option) => option.value === values.target)
              .pop().key,
            end_date: newEndDate,
            start_date: newStartDate,
            element: newElement.charAt(0).toUpperCase() + newElement.slice(1),
          });
          setLoading(false);
          // resetForm({});
        } else if (response.data.status === "failed") {
          console.log("ERROR:", response.data.error);

          setLoading(false);
          props.setAnalyzedPatterns({});
          setAnalyzedPatternsFilters({
            record_type: "dream",
            target: "global",
            end_date: "",
            start_date: "",
            element: "emotions",
          });
          // setRecordCount(0);
          resetForm({});
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Row className="align-items-center position-relative mb1">
        <Col>
          <div class="section_title small_title pe-5">
            See patterns in dream elements and browse popular patterns.
          </div>
          <Button
            type="button"
            className="btn_theme btn_icon btn_circle btn_pink position-absolute top-0"
            onClick={handleClick}
            aria-controls="pattern_filters_collapse"
            aria-expanded={patternFiltersCollapse}
            style={{ right: "0.5rem" }}
          >
            <BsGearFill />
          </Button>
        </Col>
      </Row>

      <Row className="row-cols-1 align-items-center mb0">
        <Col>
          <div>
            <span className="custom_tag tag_primary_light tag_small">
              Type: {analyzedPatternsFilters.record_type}
              {/* Target : {props.popularInsightsFilterData.target} */}
            </span>
            <span className="custom_tag tag_primary_light tag_small">
              Target: {analyzedPatternsFilters.target}
            </span>
            <span className="custom_tag tag_primary_light tag_small">
              Date:
              {analyzedPatternsFilters.start_date === "" ? (
                " All"
              ) : (
                <>{` ${analyzedPatternsFilters.start_date} - ${analyzedPatternsFilters.end_date}`}</>
              )}
            </span>
            <span className="custom_tag tag_primary_light tag_small">
              Element: {analyzedPatternsFilters.element}
            </span>
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <Collapse in={patternFiltersCollapse}>
            <div id="pattern_filters_collapse">
              <CustomCard className="border-0 shadow1 mt1 mb2 pt2 pb2 px-3">
                <Formik
                  initialValues={initialValues}
                  validationSchema={analyzePatternSchema}
                  onSubmit={onSubmit}
                  // enableReinitialize
                >
                  {(formik) => {
                    return (
                      <Form
                        className="form_style"
                        onSubmit={formik.handleSubmit}
                      >
                        <div className="">
                          <DashboardSectionTitle
                            iconImg=""
                            title="Type of record"
                            pageTitle={false}
                            className="mb1 small_title"
                          />
                          <Form.Group className="form-group rounded_corners px-2 inline_radio_fields radio_btn_field">
                            <div className="">
                              {recordRadioOptions.map((option) => {
                                return (
                                  <Form.Check
                                    key={option.value}
                                    inline
                                    label={option.key}
                                    name="record_type"
                                    type="radio"
                                    id={"pattern-" + option.value}
                                    value={option.value}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    checked={
                                      formik.values?.record_type == option.value
                                    }
                                  />
                                );
                              })}
                            </div>
                            {formik.touched.record_type &&
                            formik.errors.record_type ? (
                              <p className="error-state-text">
                                {formik.errors.record_type}
                              </p>
                            ) : null}
                          </Form.Group>
                        </div>
                        <div className="">
                          <DashboardSectionTitle
                            iconImg=""
                            title="Select target *"
                            pageTitle={false}
                            className="mb1 small_title"
                          />
                          <Form.Group className="form-group rounded_corners px-2 inline_radio_fields radio_btn_field">
                            <div className="">
                              {targetRadioOptions.map((option) => {
                                return (
                                  <Form.Check
                                    key={option.value}
                                    inline
                                    label={option.key}
                                    name="target"
                                    type="radio"
                                    id={"pattern-" + option.value}
                                    value={option.value}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    // {...formik.getFieldProps("outcome")}
                                    // value={option.value}
                                    checked={
                                      formik.values?.target == option.value
                                    }
                                  />
                                );
                              })}
                            </div>
                            {formik.touched.target && formik.errors.target ? (
                              <p className="error-state-text">
                                {formik.errors.target}
                              </p>
                            ) : null}
                          </Form.Group>
                        </div>

                        <div className="">
                          <DashboardSectionTitle
                            iconImg=""
                            title="Select chart option"
                            pageTitle={false}
                            className="mb1 small_title"
                          />
                          <Form.Group className="form-group rounded_corners px-2 inline_radio_fields radio_btn_field">
                            <div className="">
                              {chartRadioOptions.map((option) => {
                                return (
                                  <Form.Check
                                    key={option.value}
                                    inline
                                    label={option.key}
                                    name="chart_options"
                                    type="radio"
                                    id={"pattern-chart-" + option.value}
                                    value={option.value}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    // {...formik.getFieldProps("outcome")}
                                    // value={option.value}
                                    disabled={
                                      (formik.values.target == "me_friends" ||
                                        formik.values.target == "me_global" ||
                                        formik.values.target ==
                                          "me_friends_global") &&
                                      (option.value == "pie" ||
                                        option.value == "wordcloud")
                                        ? true
                                        : false
                                    }
                                    checked={
                                      formik.values?.chart_options ==
                                      option.value
                                    }
                                  />
                                );
                              })}
                            </div>
                            {(formik.values.target == "me_friends" ||
                              formik.values.target == "me_global" ||
                              formik.values.target == "me_friends_global") &&
                              (formik.values.chart_options == "pie" ||
                                formik.values.chart_options == "wordcloud") &&
                              formik.setFieldValue("chart_options", "bar")}

                            {formik.touched.chart_options &&
                            formik.errors.chart_option ? (
                              <p className="error-state-text">
                                {formik.errors.chart_options}
                              </p>
                            ) : null}
                          </Form.Group>
                        </div>

                        <Row className="form_style no-gutters">
                          <Col xs={12}>
                            <DashboardSectionTitle
                              iconImg=""
                              title="Select time"
                              pageTitle={false}
                              className="mb1 small_title"
                            />
                          </Col>
                          <Col xs={12} sm={12} md={6}>
                            <Form.Group className="form-group rounded_corners">
                              <DashboardSectionTitle
                                iconImg=""
                                title="Start date"
                                pageTitle={false}
                                className="mb-1 small_title"
                              />
                              <Field name="start_date">
                                {({ form, field }) => {
                                  const { setFieldValue } = form;
                                  const { value } = field;

                                  return (
                                    <DatePicker
                                      id="start_date"
                                      className="form-control"
                                      {...field}
                                      // todayButton="Today"
                                      selected={value}
                                      onChange={(val) =>
                                        setFieldValue("start_date", val)
                                      }
                                      dateFormat="yyyy-MM-dd"
                                      maxDate={addDays(
                                        new Date(formik.values.end_date),
                                        0
                                      )}
                                      placeholderText="Start Date"
                                      showMonthDropdown
                                      showYearDropdown
                                      dropdownMode="select"
                                      // scrollableMonthYearDropdown
                                      autoComplete="off"
                                    />
                                  );
                                }}
                              </Field>
                              {formik.touched.start_date &&
                              formik.errors.start_date ? (
                                <p className="error-state-text">
                                  {formik.errors.start_date}
                                </p>
                              ) : null}
                            </Form.Group>
                          </Col>
                          <Col xs={12} sm={12} md={6}>
                            <Form.Group className="form-group rounded_corners">
                              <DashboardSectionTitle
                                iconImg=""
                                title="End date"
                                pageTitle={false}
                                className="mb-1 small_title"
                              />
                              <Field name="end_date">
                                {({ form, field }) => {
                                  // console.log("form in Date =", form);
                                  // console.log("field in Date =", field);
                                  const { setFieldValue } = form;
                                  const { value } = field;

                                  return (
                                    <DatePicker
                                      id="end_date"
                                      className="form-control"
                                      {...field}
                                      todayButton="Today"
                                      selected={value}
                                      onChange={(val) =>
                                        setFieldValue("end_date", val)
                                      }
                                      dateFormat="yyyy-MM-dd"
                                      minDate={subDays(
                                        new Date(formik.values.start_date),
                                        0
                                      )}
                                      placeholderText="End date"
                                      showMonthDropdown
                                      showYearDropdown
                                      dropdownMode="select"
                                      // scrollableMonthYearDropdown
                                      autoComplete="off"
                                    />
                                  );
                                }}
                              </Field>
                              {formik.touched.end_date &&
                              formik.errors.end_date ? (
                                <p className="error-state-text">
                                  {formik.errors.end_date}
                                </p>
                              ) : null}
                            </Form.Group>
                          </Col>
                        </Row>
                        <DashboardSectionTitle
                          iconImg=""
                          title="Select element"
                          pageTitle={false}
                          className="mb2 small_title"
                        />
                        <Form.Group className="form-group rounded_corners">
                          {/* <Form.Label>Add Recipient(s)</Form.Label> */}
                          {formik.values.record_type == "outofbody" ||
                          formik.values.record_type == "thought" ? (
                            <>
                              <Select
                                styles={customStyles}
                                placeholder="Select element"
                                value={formik.values.element}
                                onChange={(value) => {
                                  formik.setFieldValue("element", value);
                                }}
                                onBlur={() => {
                                  formik.setFieldTouched("element", true);
                                }}
                                isMulti={false}
                                options={elementOptions2}
                                name="element"
                              />
                            </>
                          ) : (
                            <>
                              <Select
                                styles={customStyles}
                                placeholder="Select element"
                                value={formik.values.element}
                                onChange={(value) => {
                                  formik.setFieldValue("element", value);
                                }}
                                onBlur={() => {
                                  formik.setFieldTouched("element", true);
                                }}
                                isMulti={false}
                                options={elementOptions}
                                name="element"
                              />
                            </>
                          )}

                          {formik.touched.element && formik.errors.element ? (
                            <p className="error-state-text">
                              {formik.errors.element}
                            </p>
                          ) : null}
                        </Form.Group>

                        <div className="btn_wrap pb1">
                          <Button
                            key="submit_btn"
                            type="submit"
                            className="btn_theme btn_primary btn_rounded"
                          >
                            {loading ? (
                              <>
                                <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                />{" "}
                                Processing
                              </>
                            ) : (
                              "Submit"
                            )}
                          </Button>

                          <Button
                            key="close_filters_btn"
                            type="button"
                            className="btn_theme btn_light btn_rounded"
                            onClick={() => {
                              handleCollapse("pattern_filters_collapse");
                            }}
                          >
                            Cancel
                          </Button>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </CustomCard>
            </div>
          </Collapse>
        </Col>
      </Row>

      {!loading && props.analyzedPatterns.hasOwnProperty("chart_data") ? (
        <>
          <hr />
          <Row className="align-items-center position-relative mb2">
            <Col>
              <div class="section_title small_title">
                Analyzed Pattern Graphs
              </div>
            </Col>
            {/* <Col>{datasetLength !== "full" && <>Top Five Results</>}</Col> */}
            {/* <Col className="">
              {datasetLength !== "full" && (
                <span className="me-2">Top 5 Results</span>
              )}
              {datasetLength !== "full" && (
                <>
                  <Button
                    key="see_more_btn"
                    type="button"
                    className="btn_theme btn_small btn_primary btn_rounded"
                    onClick={() => {
                      createChartData(
                        props.analyzedPatterns.chart_data,
                        "full",
                        props.analyzedPatterns.chart_data.chart,
                        props.analyzedPatterns.chart_data.target
                      );
                    }}
                  >
                    View More
                  </Button>
                </>
              )}
            </Col> */}
            <Dropdown
              align="end"
              className="position-absolute top-0 p-0"
              style={{ right: "0.5rem", width: "auto" }}
            >
              <Dropdown.Toggle
                className="btn_theme btn_light btn_rounded btn_icon down_icon_hidden"
                id="dropdown-basic"
              >
                <BsThreeDotsVertical />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  as="button"
                  className="font-14"
                  onClick={() => {
                    handleSaveGraph(props.analyzedPatterns.chart_data);
                  }}
                >
                  Save Graph
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item
                  as="button"
                  className="font-14"
                  onClick={handleClick}
                  aria-controls="share_graph_collapse"
                  aria-expanded={shareGraphCollapse}
                >
                  Share Graph
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Row>
          <Row>
            <Col>
              <Collapse in={shareGraphCollapse}>
                <div id="share_graph_collapse">
                  {/* <hr /> */}
                  <ShareGraph
                    handleCollapse={handleCollapse}
                    chart_data={props.analyzedPatterns.chart_data}
                  />
                </div>
              </Collapse>
            </Col>
          </Row>

          {fullDatasets.length > 0 ? (
            <>
              {fullDatasets.map((dataset, index) => (
                <div key={index} className="card shadow1 border-0 p2 mb2">
                  {dataset.chart_type === "pie" ? (
                    <DashboardPieChart
                      labels={dataset.labels}
                      datasets={dataset.datasets}
                      recordCount={dataset.total_records}
                      chart_title={dataset.chart_title}
                      legend_list={dataset.legend_list}
                      // chartData={dataset}
                    />
                  ) : (
                    <>
                      {dataset.chart_type === "wordcloud" ? (
                        <DashboardWordcloudChart
                          // options={options}
                          recordCount={dataset.total_records}
                          datasets={datasets}
                        />
                      ) : (
                        <DashboardBarChart
                          labels={dataset.labels}
                          datasets={dataset.datasets}
                          recordCount={dataset.total_records}
                          chart_title={dataset.chart_title}
                          legend_list={dataset.legend_list}
                          // chartData={dataset}
                        />
                      )}
                    </>
                  )}
                </div>
              ))}
            </>
          ) : (
            <></>
          )}

          {/* {props.analyzedPatterns.chart_data.chart === "bar" && (
            <DashboardAnalyzeBarChart
              labels={labels}
              datasets={datasets}
              recordCount={recordCount}
              dataLength={datasetLength}
            />
          )} */}
          {/* <DashboardAnalyzeBarChart
            labels={labels}
            datasets={datasets}
            recordCount={recordCount}
            dataLength={datasetLength}
          /> */}
          {/* {props.analyzedPatterns.chart_data.chart === "pie" && (
            <DashboardAnalyzePieChart
              labels={labels}
              datasets={datasets}
              dataLength={datasetLength}
            />
          )} */}
          {/* {props.analyzedPatterns.chart_data.chart === "wordcloud" && (
            <DashboardAnalyzeWordcloudChart
              datasets={datasets}
              dataLength={datasetLength}
            />
          )} */}
        </>
      ) : (
        <>
          {loading ? (
            <>
              {[0, 1].map((_, i) => (
                <CustomCard
                  key={i}
                  className={`main_card shadow1 border-0 mb2 text-left overflow-hidden`}
                >
                  <Card.Header>
                    <div className="d-flex align-items-center">
                      <div className="flex-shrink-0">
                        <Placeholder as="div" animation="glow">
                          <Placeholder
                            bg="secondary"
                            as="div"
                            style={{ height: "54px", width: "54px" }}
                            className="rounded-circle"
                          />
                        </Placeholder>
                      </div>
                      <div className="flex-grow-1 ms-3 text-left">
                        <div className="w-100">
                          <Placeholder xs={6} bg="secondary" />
                        </div>
                        <div className="w-100">
                          <Placeholder xs={4} bg="secondary" />
                        </div>
                      </div>
                    </div>
                  </Card.Header>
                  <Card.Body className="p2">
                    <div className="w-100 mb-1">
                      <Placeholder xs={9} bg="secondary" />
                    </div>
                    <div className="w-100 mb1">
                      <Placeholder xs={6} bg="secondary" />
                    </div>
                  </Card.Body>
                </CustomCard>
              ))}
            </>
          ) : (
            <CustomCard className="shadow1 border-0 text-center">
              <Card.Body>
                <div className="mb1">
                  <BsFillExclamationTriangleFill
                    style={{
                      color: "#d3af61",
                      fontSize: "2.2rem",
                    }}
                  />
                </div>
                <b>No more records found...</b>
              </Card.Body>
            </CustomCard>
          )}
        </>
      )}
    </>
  );
}

export default DashboardAnalyzePattern;
