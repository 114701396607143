import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import HomeSectionHeading from "../HomeSectionHeading/HomeSectionHeading";
import "./HomeTeam.css";
import WebProfileCard from "../../ui/CustomCard/WebProfileCard/WebProfileCard";
import WebProfileModal from "../../ui/WebProfileModal/WebProfileModal";

function HomeTeam() {
  // const [show, setShow] = useState(false);

  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);
  const [show, setShow] = useState({
    data: {},
    isVisible: false,
  });

  const handleClose = () => {
    console.log("Closing the modal");
    setShow({
      data: {},
      isVisible: false,
    });
  };
  const handleShow = (data) => {
    setShow({
      data: data,
      isVisible: true,
    });
    console.log("Opening the modal", data);
  };
  const teamMembers = [
    {
      id: "tm1",
      name: "Chris Ufere",
      designation: "Founder",
      profileImg: "Chris-Ufere.png",
      info: "uDreamed was founded by Chris Ufere. He led the company from startup to exit. He is a board member. Getting access to and understanding his own unconscious experiences has always been one of his ambitions. Chris brings over 9 years of entrepreneurial experience. He holds an AB in Economics from Harvard. He won the prestigious Founder Excellence Prize awarded by the Technology and Entrepreneurship Center at Harvard in the 2018 Harvard College Innovation Challenge.",
      socialLinks: [],
    },
    {
      id: "tm2",
      name: "James Gaskin",
      designation: "Head of Product Design and User Experience",
      profileImg: "James-Gaskin.png",
      info: "James is responsible for product design and user experience. He brings over 10 years of product design, gamification and analytics experience. He is a serial inventor of digital products, including analytic tools and games with a large online presence. He holds a BSc and MSc in Information Systems from Brigham Young University and a Ph.D. in Information Systems Management from Case Western Reserve University.",
      socialLinks: [],
    },
    {
      id: "tm3",
      name: "Tom Brown",
      designation: "Head of Engineering and Technology",
      profileImg: "Tom-Brown.png",
      info: "Tom is responsible for engineering and technology, including software and hardware engineering, analytics, and execution. Tom brings over 30 years of technology innovation and software development as an entrepreneur, investor and corporate executive.Tom holds a BSc in Computer Science from the University of Illinois - Urbana Champaign.",
      socialLinks: [],
    },
    {
      id: "tm7",
      name: "Sandeep Pandit",
      designation: "Head of Development",
      profileImg: "Sandeep-Pandit.png",
      info: "Sandeep is a first generation entrepreneur and computer engineer by qualification. With more than 20 years in the information and communication technology industry, he brings extensive experience in design/IT/mobile apps and cloud technology. Sandeep leverages technologies and platforms that enable and transform operational aspects of uDreamed.",
      socialLinks: [],
    },
    {
      id: "tm4",
      name: "Nnaoke Ufere",
      designation: "Head of Marketing & Finance",
      profileImg: "Nnaoke-Ufere.png",
      info: "Nnaoke is responsible for the day-to-day general management of the firm. He brings over 30 years of managerial and operational experience as a serial entrepreneur, investor, engineer, and management and technology consultant. He has started and invested in several successful ventures. He holds a B.Eng. Electrical/Electronic Engineering, (first class, Honors) from the University of Nigeria, MBA from Harvard Business School and Ph.D. in Management (Designing Sustainable Systems) from Case Western Reserve University.",
      socialLinks: [],
    },
    {
      id: "tm5",
      name: "Jennifer Lewis Priestley",
      designation: "Data Scientist",
      profileImg: "Jennifer-Lewis-Priestley.png",
      info: "As advisor, Jennifer brings over 25 years of deep expertise in the area of Analytics and Data Science as an academic, consultant and corporate executive. She received a BS in Economics from Georgia Tech, MBA from The Smeal College of Business at Pennsylvania State University and Ph.D. in Decision Science from Georgia State University.",
      socialLinks: [],
    },
    {
      id: "tm6",
      name: "Thomas Gaskin",
      designation: "Neuropsychologist",
      profileImg: "Thomas-Gaskin.png",
      info: "As neuropsychology advisor, Tom brings over 25 years of experience in the areas of clinical psychology and neuropsychology as a practitioner and advisor in military, consulting, and private venues. He received his BS in Civil Engineering from the Massachusetts Institute of Technology, Ph.D. in Clinical Psychology from Brigham Young University, and post-doctoral fellowship training in Pediatric Neuropsychology from Harvard Medical School.",
      socialLinks: [],
    },
  ];
  return (
    <>
      <Container>
        <HomeSectionHeading
          htmlTag="h2"
          type="m"
          titleText="Our Core Team"
          className="text-center fw_el mb5"
        />

        <Row className="team_card_wrap">
          {teamMembers.map((member, index) => {
            return (
              <Col key={`col${index}`} xs={12} sm={6} md={6} lg={3}>
                <WebProfileCard
                  id={member.id}
                  member={member}
                  handleShow={handleShow}
                />
              </Col>
            );
          })}
        </Row>
        <WebProfileModal
          show={show}
          member={show.data}
          handleClose={handleClose}
        />
      </Container>
    </>
  );
}

export default HomeTeam;
