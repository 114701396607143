import React, { useState, useCallback, useContext, useRef } from "react";
import {
  Form,
  Button,
  Row,
  Col,
  Spinner,
  Card,
  Placeholder,
} from "react-bootstrap";
import CustomCard from "../../../ui/CustomCard/CustomCard";
import DashboardSectionTitle from "../DashboardSectionTitle/DashboardSectionTitle";
import SearchedUserCard from "./SearchedUserCard";
import SearchedGroupCard from "./SearchedGroupCard";
import { debounce } from "lodash";
import { BsFillStarFill, BsX, BsXCircleFill } from "react-icons/bs";
import "./SearchNetworkGroups.css";
import { handleAlert } from "../../../util/util";

//Service
import recordService from "../../../services/Record";
import authService from "../../../services/Auth";
import PlaceholderCard from "../../../ui/PlaceholderCard/PlaceholderCard";
import SimpleMessageCard from "../../../ui/SimpleMessageCard/SimpleMessageCard";

function SearchNetworkGroups(props) {
  const authUser = JSON.parse(authService.getAuthUser());
  let group_actions = ["group_details", "join_group"];
  const [result, setResult] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);

  //leave group Confirmation Modal State
  const [show2, setShow2] = useState({
    isVisible: false,
  });

  const handleClose2 = () => {
    // console.log("Closing the modal");
    setShow2({
      isVisible: false,
    });
  };

  const handleShow2 = (e) => {
    setShow2({
      isVisible: true,
    });
    console.log("Opening the modal inside Serach group");
  };

  const handleLeaveGroup = (group_id) => {
    setLoading(true);
    console.log("data in handleLeaveGroup =", group_id);
    recordService
      .leaveGroup(group_id)
      .then((response) => {
        if (response.data.status === "success") {
          console.log("leave Group response =", response.data);
          handleAlert("You have successfully left a group!", "success");
          // handleCollapse("group_details");
          handleClose2();
          setLoading(false);
          // console.log("in handleLeaveGroup inputValue =", inputValue);
          // handleChange(inputValue);
          //Get all groups
          // props.getAllGroups();
        } else if (response.data.status === "failed") {
          console.log("ERROR:", response.data.error);
          handleAlert("Unable to leave group!", "error");
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const searchInputRef = useRef();

  const handleClearField = () => {
    setInputValue("");
    searchInputRef.current.value = "";
    setResult([]);
  };

  const handleItemClick = (item) => {
    console.log("Item = ", item);
    setInputValue(item.name);
    setResult([]);
  };

  const handleChange = debounce((value) => {
    console.log("This is function from lodash inside groups...");
    console.log("Search text =", value);
    setLoading(true);
    console.log(value.trim());
    if (value.trim().length > 0) {
      recordService
        .searchGroups(value.trim())
        .then((response) => {
          if (response.data.status === "success") {
            console.log('Searched groups = ',response.data);
            const groups = response.data.data.result.groups;
            let updatedRecords;
            updatedRecords = response.data.data.result.groups.map((record) => {
              return { ...record, actions: group_actions };
            });
            console.log("Result =", updatedRecords);
            setInputValue(value);
            setResult(updatedRecords);
            setLoading(false);
          }
          if (response.data.status === "failed") {
            console.log("ERROR:", response.data.error);
            setResult([]);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log("There is a problem in getting all groups...", err);
        });
    } else {
      setResult([]);
      setLoading(false);
    }
  }, 500);

  return (
    <div className="form_style">
      <Form.Group className="form-group rounded_corners search_field autocomplete_field">
        <Form.Control
          type="text"
          name="searchInput"
          onChange={(e) => {
            // setInputValue(e.target.value);
            handleChange(e.target.value);
            // optimizedHandleChange(e.target.value);
          }}
          ref={searchInputRef}
          // value={inputValue}
          placeholder="Search"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/img/search-icon.png)`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center right 20px",
          }}
          autoComplete="off"
        />
        {searchInputRef?.current?.value.length > 0 && (
          <Button
            onClick={handleClearField}
            className={`btn_theme btn_icon btn_auto_width btn_light btn_circle`}
            type="button"
          >
            <BsX />
          </Button>
        )}
      </Form.Group>

      {loading && (
        <>
          <div className="d-grid gap-1 mt2">
            {[0, 1, 2].map((_, i) => (
              <PlaceholderCard key={i} />
            ))}
          </div>
        </>
      )}
      {!loading && !result?.length > 0 && inputValue.length > 0 && searchInputRef?.current?.value.length > 0 && (
        <SimpleMessageCard type="warning" />
      )}
      {!loading && result?.length > 0 && (
        <>
          <div className="d-grid gap-3 mt2">
            {result?.map((item, index) => (
              <SearchedGroupCard
                key={item.title}
                group={item}
                getAllGroups={props.getAllGroups}
                authUser={authUser}
                inputValue={inputValue}
                // show2={show2}
                // handleShow2={handleShow2}
                // handleClose2={handleClose2}
                // handleLeaveGroup={handleLeaveGroup}
                handleChange={handleChange}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export default SearchNetworkGroups;
