import React from "react";
import { Button } from "react-bootstrap";
import DashboardProfileCard from "../DashboardProfileCard/DashboardProfileCard";
import DashboardNav from "../DashboardNav/DashboardNav";
import DashboardYoutubeLinkCard from "../DashboardYoutubeLinkCard/DashboardYoutubeLinkCard";
import DashboardLatestTrend from "../DashboardLatestTrend/DashboardLatestTrend";
import { FaTimes, FaArrowLeft } from "react-icons/fa";

function DashboardLeftPane({
  contentType,
  getContentType,
  notificationCount,
  setIsNavbarOpen,
  isNavbarOpen,
}) {
  return (
    <div className="left_bar pt-3 pt-lg-0">
      <div className="d-flex justify-content-end d-lg-none mb-3 mb-lg-0">
        <Button
          type="button"
          onClick={() => {
            setIsNavbarOpen(false);
          }}
          title="Close Mobile Navigation"
          className="btn_theme btn_circle btn_icon btn_large btn_light"
        >
          <FaArrowLeft />
        </Button>
      </div>

      <DashboardProfileCard getContentType={getContentType} />
      <DashboardNav
        contentType={contentType}
        getContentType={getContentType}
        notificationCount={notificationCount}
      />
      <DashboardYoutubeLinkCard getContentType={getContentType} />
      {/* <DashboardLatestTrend /> */}
    </div>
  );
}

export default DashboardLeftPane;
