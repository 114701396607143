import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import HomeGuestHeader from "../components/HomeGuestHeader/HomeGuestHeader";
import HomeInnerPageBanner from "../components/HomeInnerPageBanner/HomeInnerPageBanner";
import HomeSectionHeading from "../components/HomeSectionHeading/HomeSectionHeading";
import HomeNormalBanner from "../components/HomeNormalBanner/HomeNormalBanner";
import HomeFooter from "../components/HomeFooter/HomeFooter";
import PageComponent from "../components/PageComponent/PageComponent";

function PressDetails() {
  return (
    <>
      <HomeGuestHeader />
      <PageComponent>
        <HomeInnerPageBanner className="about_banner"></HomeInnerPageBanner>
        <div className="inner_page">
          <Container className="pt5 pb5">
            <Row className="justify-content-center">
              <Col md={12} lg={10}>
                <div className="page_text">
                  <HomeSectionHeading
                    htmlTag="h2"
                    type="m"
                    titleText="Lorem ipsum dolor sit amet, consectetur adipiscing elit"
                    className="mb2"
                  />
                  <div className="mb3">
                    Publication | <i>July 13, 2021</i>
                  </div>

                  <div className="img_container mb3">
                    <img
                      src={`${process.env.PUBLIC_URL}/img/press1.jpg`}
                      className="img-fluid"
                      alt="Press"
                    />
                  </div>

                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Eligendi vero saepe officia id explicabo! Magni facere,
                    perferendis ex pariatur, deleniti consequuntur eos
                    voluptatem delectus aut, perspiciatis dolor autem quia
                    cupiditate!
                  </p>

                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Eligendi vero saepe officia id explicabo! Magni facere,
                    perferendis ex pariatur, deleniti consequuntur eos
                    voluptatem
                  </p>

                  <p>
                    <strong>Features</strong>
                  </p>

                  <ul>
                    <li>
                      Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                      Ullam, illo.
                    </li>
                    <li>Lorem ipsum dolor sit amet consectetur.</li>
                    <li>
                      Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                      Facere eius porro deserunt!
                    </li>
                    <li>Lorem ipsum dolor sit.</li>
                  </ul>

                  <p>
                    <strong>Conclusion:</strong>
                  </p>

                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Magnam laudantium iure laboriosam doloremque optio quo eum
                    sequi, illo ducimus id.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <HomeNormalBanner />
      </PageComponent>
      <HomeFooter />
    </>
  );
}

export default PressDetails;
