import React, { useState, useEffect } from "react";
import {
  Card,
  Row,
  Col,
  Form,
  Button,
  Spinner,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import CustomCard from "../../../ui/CustomCard/CustomCard";
import DashboardSectionTitle from "../DashboardSectionTitle/DashboardSectionTitle";
import { Field, Formik } from "formik";
import * as yup from "yup";
import { handleAlert } from "../../../util/util";

//Service
import recordService from "../../../services/Record";
import FieldError from "../../../ui/FieldError/FieldError";

function ChangePasswordContent(props) {
  const [loading, setLoading] = useState(false);

  const initialValues = {
    oldPassword: "",
    password: "",
  };

  const changePasswordSchema = yup.object({
    oldPassword: yup
      .string()
      .min(8, "Old password is wrong")
      .required("Required"),
    password: yup.string().min(8, "Password is too short").required("Required"),
  });

  const onSubmit = async (values, { resetForm }) => {
    setLoading(true);
    console.log("Form Values :", values);

    recordService
      .changePassword(values)
      .then((response) => {
        if (response.data.status === "success") {
          console.log(response.data);
          handleAlert("You have successfully updated password!", "success");

          resetForm({});
          setLoading(false);
          //   props.handleClose();
          //   props.getUserDetails();
        } else if (response.data.status === "failed") {
          console.log("ERROR:", response.data.error);
          handleAlert(
            "Password updation failed! Old password did not match!",
            "error"
          );
          resetForm({});
          setLoading(false);
          //   props.handleClose();
          // props.getUserDetails();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <CustomCard className={`main_card border-0 mb2`}>
        <Card.Body>
          <Formik
            initialValues={initialValues}
            validationSchema={changePasswordSchema}
            onSubmit={onSubmit}
            // enableReinitialize
          >
            {(formik) => {
              // console.log("Formik vals = ", formik.values);
              return (
                <Form className="form_style" onSubmit={formik.handleSubmit}>
                  <DashboardSectionTitle
                    iconImg=""
                    title="Old password"
                    pageTitle={false}
                    className="mb1 small_title"
                  />
                  <Form.Group className="form-group rounded_corners">
                    <Form.Control
                      type="text"
                      placeholder="Old password"
                      name="oldPassword"
                      {...formik.getFieldProps("oldPassword")}
                    />
                    {/* <Form.Label className="label_float">
                      Old password
                    </Form.Label> */}
                    <FieldError
                      valid={
                        formik.touched.oldPassword && formik.errors.oldPassword
                          ? true
                          : false
                      }
                      text={formik.errors.oldPassword}
                    />
                  </Form.Group>
                  <DashboardSectionTitle
                    iconImg=""
                    title="New password"
                    pageTitle={false}
                    className="mb1 small_title"
                  />
                  <Form.Group className="form-group rounded_corners">
                    <Form.Control
                      type="text"
                      placeholder="New password"
                      name="password"
                      {...formik.getFieldProps("password")}
                    />
                    {/* <Form.Label className="label_float">Password</Form.Label> */}
                    <FieldError
                      valid={
                        formik.touched.password && formik.errors.password
                          ? true
                          : false
                      }
                      text={formik.errors.password}
                    />
                  </Form.Group>
                  <div className="btn_wrap pt1">
                    <Button
                      key="updateNewPasswordBtn"
                      type="submit"
                      // disabled={!formik.isValid}
                      className="btn_theme btn_primary btn_rounded"
                    >
                      {loading ? (
                        <>
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />{" "}
                          Processing
                        </>
                      ) : (
                        "Submit"
                        // <>{isAddMode ? "Save Association" : "Update Settings"}</>
                      )}
                    </Button>
                    <Button
                      key="closePasswordCollapseBtn"
                      type="button"
                      className="btn_theme btn_light btn_rounded"
                      onClick={props.handleClose}
                    >
                      Cancel
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Card.Body>
      </CustomCard>
    </>
  );
}

export default ChangePasswordContent;
