import React from "react";
import CommentList from "./CommentList";
import "./DashboardComments.css";

function DashboardComments(props) {
  // console.log("Comments: ",props)
  return (
    <div className="position-relative border-top mt-3 pt-3 pb-3">
      <CommentList
        loading={props.loading}
        comment_type={props.comment_type}
        parent_id={props.parent_id}
        parent_owner_id={props.parent_owner_id}
        comments={props.comments}
        handleCommentList={props.handleCommentList}
        setCommentsCount={props.setCommentsCount}
      />
    </div>
  );
}

export default DashboardComments;
