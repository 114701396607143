import React from "react";
import { Route, Navigate } from "react-router-dom";
import authService from "./Auth";

const AuthGuard = ({ component: Component, auth, ...rest }) => {
  // let token = localStorage.getItem("auth_token");
  // console.log("auth in Authguard =", auth);
  let token = authService.getToken();
  if (token) {
    auth = true;
  }
  // console.log("auth in Authguard after if token =", auth);
  // console.log("token in Authguard =", token);
  // console.log("rest props in Authguard =", { ...rest });
  return (
    <>{auth === true ? <Component {...rest} /> : <Navigate to="/" />}</>
    // <Route
    //   {...rest}
    //   render={(props) =>
    //     auth === true ? (
    //       <Component
    //         {...props}
    //       />
    //     ) : (
    //       <Redirect to="/" />
    //     )
    //   }
    // />
  );
};

export default AuthGuard;
