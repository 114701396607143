import React from "react";
// import RecordDreams from "../DashboardRecordSection/RecordDreams";
import EditRecordDreams from "../DashboardRecordSection/EditRecordDreams";
import EditRecordEvents from "../DashboardRecordSection/EditRecordEvents";
import EditRecordThoughts from "../DashboardRecordSection/EditRecordThoughts";
import RecordOutOfBody from "../DashboardRecordSection/RecordOutOfBody";
import RecordThoughts from "../DashboardRecordSection/RecordThoughts";

function DashboardEditExperience(props) {
  // console.log("Record Details = ", props.recordDetails);
  return (
    <>
      {props.recordDetails?.type === "dream" && (
        <EditRecordDreams
          recordDetails={props.recordDetails}
          handleCollapse={props.handleCollapse}
        />
      )}
      {props.recordDetails?.type === "event" && (
        <EditRecordEvents
          recordDetails={props.recordDetails}
          handleCollapse={props.handleCollapse}
        />
      )}
      {props.recordDetails?.type === "thought" && (
        <RecordThoughts
          isAddMode={false}
          recordDetails={props.recordDetails}
          handleCollapse={props.handleCollapse}
        />
      )}
      {props.recordDetails?.type === "outofbody" && (
        <RecordOutOfBody
          isAddMode={false}
          recordDetails={props.recordDetails}
          handleCollapse={props.handleCollapse}
        />
      )}

      {/* {props.recordDetails.type === "dream" && <EditRecordDreams />}
      {props.recordDetails.type === "event" && <EditRecordEvents />}
      {props.recordDetails.type === "thought" && <EditRecordDreams />}
      {props.recordDetails.type === "outofbody" && <EditRecordDreams />} */}
    </>
  );
}

export default DashboardEditExperience;
