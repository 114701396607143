import React from "react";
import { Button } from "react-bootstrap";
import {
  BsFillStarFill,
  BsFillExclamationCircleFill,
  BsFillPencilFill,
  BsFillTrashFill,
  BsTrash,
} from "react-icons/bs";
import {
  BiCommentAdd,
  BiCommentDetail,
  BiChat,
  BiConversation,
} from "react-icons/bi";

function CustomCollapseButton({
  handleCollapse,
  btnTitle,
  iconImg,
  className,
  ariaControls,
  ariaExpanded,
  handleRecordDetails,
  exp,
  handleAssociationList,
  handleForward,
  message,
  btnType = "normal",
  setCardTop,
}) {
  let iconComponent = "";
  switch (ariaControls) {
    case "edit":
    case "edit_thread":
    case "edit_forum":
    case "edit_publish_search":
    case "edit_publish_address":
    case "edit_publish_dob":
    case "edit_publish_gender":
    case "edit_publish_education":
    case "edit_publish_phone":
      iconComponent = <BsFillPencilFill />;
      break;
    case "view_thread_comments":
      iconComponent = <BiCommentDetail className="font-18 me-1" />;
      break;
  }

  function handleClick(e) {
    console.log(e);
    const element = e.target.getAttribute("aria-controls");
    let isAriaExpanded = e.target.getAttribute("aria-expanded");
    console.log("Element =", element);
    console.log("expanded =", isAriaExpanded);
    handleCollapse(element);

    /* Get the offsetTop of the Current Custom Card and set it to state
       to scroll page back to its normal position when collapsible collapses.. 
    ***/
    // console.log("Top = ", e.target.offsetParent.offsetTop);
    if (setCardTop) {
      setCardTop(e.target.offsetParent.offsetTop);
    }
    if (isAriaExpanded !== "true" && element === "view_details") {
      // console.log(
      //   "Collapse Desc: ",
      //   element,
      //   "\t aria-expanded = ",
      //   isAriaExpanded,
      //   "Type = ",
      //   typeof isAriaExpanded
      // );
      // handleRecordDetails(exp.record_id);
    }
    if (isAriaExpanded !== "true" && element === "view_associations") {
      // handleAssociationList(exp.record_id);
    }

    // if (element === "forward") {
    //   handleForward(message);
    // }
  }
  return (
    <Button
      type="button"
      className={`btn_theme ${className}`}
      onClick={handleClick}
      aria-controls={ariaControls}
      aria-expanded={ariaExpanded}
      title={`${btnTitle} ${
        ariaControls === "view_thread_comments" ? "Comments" : ""
      }`}
    >
      {iconImg ? (
        <img
          src={`${process.env.PUBLIC_URL}/img/${iconImg}`}
          className="img-fluid mx-auto d-block"
          alt={`${btnTitle} Icon`}
        />
      ) : null}
      {btnType === "normal" ? (
        <>{btnTitle}</>
      ) : (
        <>
          {btnType === "icon" ? (
            <>{iconComponent}</>
          ) : (
            <>
              {iconComponent}
              {btnTitle}
            </>
          )}
        </>
      )}
      {/* {btnTitle} */}
    </Button>
  );
}

export default CustomCollapseButton;
