import React from "react";
import { Card, Button } from "react-bootstrap";
import CustomCard from "../../../ui/CustomCard/CustomCard";
import "./MessageCard.css";

function MessageCard(props) {
  const recipientName = props.msg?.recipient_user
    ? props.msg?.recipient_user.firstname +
      " " +
      props.msg?.recipient_user.lastname
    : null;

  const recipientAvatar = props.msg?.recipient_user?.avatar !== "NULL"
    ? props.msg?.recipient_user?.avatar
    : `${process.env.PUBLIC_URL}/img/user-profile.jpg`;

  const senderName = props.msg?.sender_user 
    ? props.msg?.sender_user.firstname + " " + props.msg?.sender_user.lastname
    : null;

  const senderAvatar = props.msg?.sender_user?.avatar !== "NULL"
    ? props.msg?.sender_user?.avatar
    : `${process.env.PUBLIC_URL}/img/user-profile.jpg`;

  const msgDate = props.msg.created_date ? props.msg.created_date : null;

  const handleActionBtnClick = (e) => {
    let action = e.target.getAttribute("data-action");
    switch (action) {
      case "forward":
        props.handleForward(props.msg);
        break;
      case "reply":
        props.handleReply(props.msg);
        break;
      case "archive":
        props.handleArchive(props.msg);
        break;
      case "trash":
        props.handleTrash(props.msg);
        break;
      case "move_to_inbox":
        // props.handleTrash(props.msg);
        break;
      default:
        console.log("No action matched...");
    }
  };

  return (
    <>
      <CustomCard
        className={`message_card main_card shadow1 border-0 mb2 overflow-hidden`}
      >
        <Card.Header className="message_card_header">
          <div className="media">
            {props.type !== "" && (
              <div className="media_img_wrap">
                {/* <img
                  src={`${process.env.PUBLIC_URL}/img/profile1.png`}
                  className={`rounded-circle media_img`}
                  alt="user profile"
                /> */}
                {(props.type === "inbox" ||
                  props.type === "archive" ||
                  props.type === "trash") && (
                  <img
                    src={senderAvatar}
                    className={`rounded-circle media_img`}
                    alt={`${senderName} profile`}
                  />
                )}
                {props.type === "sent" && (
                  <img
                    src={recipientAvatar}
                    className={`rounded-circle media_img`}
                    alt={`${recipientName} profile`}
                  />
                )}
              </div>
            )}
            <div className={`media_body ${props.type !== "" ? "ms-3" : ""}`}>
              <Card.Title className="card_title">
                {(props.type === "inbox" ||
                  props.type === "archive" ||
                  props.type === "trash") && <>From {senderName}</>}
                {props.type === "sent" && <>To {recipientName}</>}
              </Card.Title>
              <Card.Subtitle className="card_subtitle">
                on {msgDate}
              </Card.Subtitle>
            </div>
          </div>
        </Card.Header>
        <Card.Body className="message_card_body">
          <p className="ps-1">{props.msg.message}</p>

          <div className="btn_wrap">
            {props.actions.map((action, index) => {
              switch (action) {
                case "reply":
                  return (
                    <Button
                      key={`${action}_btn${index}`}
                      data-action={action}
                      type="button"
                      className="btn_theme btn_small btn_primary btn_rounded"
                      onClick={handleActionBtnClick}
                    >
                      Reply
                    </Button>
                  );
                case "forward":
                  return (
                    <Button
                      key={`${action}_btn${index}`}
                      data-action={action}
                      type="button"
                      className="btn_theme btn_small btn_secondary btn_rounded"
                      onClick={handleActionBtnClick}
                    >
                      Forward
                    </Button>
                  );
                case "archive":
                  return (
                    <Button
                      key={`${action}_btn${index}`}
                      data-action={action}
                      type="button"
                      className="btn_theme btn_small btn_light btn_rounded"
                      onClick={handleActionBtnClick}
                    >
                      Archive
                    </Button>
                  );
                case "move_to_inbox":
                  return (
                    <Button
                      key={`${action}_btn${index}`}
                      data-action={action}
                      type="button"
                      className="btn_theme btn_small btn_success btn_rounded"
                      onClick={(e) => {}}
                    >
                      Move to Inbox
                    </Button>
                  );
                case "trash":
                  return (
                    <Button
                      key={`${action}_btn${index}`}
                      data-action={action}
                      type="button"
                      className="btn_theme btn_small btn_danger btn_rounded"
                      onClick={handleActionBtnClick}
                    >
                      Trash
                    </Button>
                  );
              }
            })}
          </div>
        </Card.Body>
      </CustomCard>
    </>
  );
}

export default MessageCard;
