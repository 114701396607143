import React, { useState } from "react";
import { Modal, Form, Button, Spinner } from "react-bootstrap";
import DashboardSectionTitle from "../../components/Dashboard/DashboardSectionTitle/DashboardSectionTitle";
import { Formik } from "formik";
import * as yup from "yup";
// import "./ConfirmAcceptConsultInviteModal.css";
// import { handleAlert } from "../../util/util";

//Service
import recordService from "../../services/Record";

function ConfirmAcceptConsultInviteModal(props) {
  const [loading, setLoading] = useState(false);

  const initialValues = {
    payment_amount: "",
  };

  const rejectConsultInviteSchema = yup.object({
    payment_amount: yup.string().required("Required"),
  });

  const onSubmit = async (values, { resetForm }) => {
    console.log("rejectConsultInvite Form Values :", values);
    // setLoading(true);

    const newValues = {
      ...values,
      consult_id: props.consult_id,
    };
    console.log("New values in Compose =", newValues);

    // recordService
    //   .acceptConsultInvite(newValues)
    //   .then((response) => {
    //     if (response.data.status === "success") {
    //       console.log(response.data);
    //       handleAlert(
    //         "Success! You have accepted consult invitation.",
    //         "success"
    //       );

    //       setLoading(false);
    //       resetForm({});

    //       //Close Message Modal if exist
    //       if (props.handleClose) {
    //         props.handleClose();
    //       }
    //     } else if (response.data.status === "failed") {
    //       // console.log("ERROR:", response.data.error);
    //       handleAlert("Error! Failed to accept consult invitation.", "error");
    //       setLoading(false);
    //       resetForm({});
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };

  return (
    <>
      <Modal
        centered
        size="md"
        className="theme-modal accept-consult-invite-modal"
        show={props.show.isVisible}
        onHide={props.handleClose}
      >
        <Modal.Header className="justify-content-center">
          <Modal.Title>
            Are you sure you would like to remove member?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form_style pt1 pb1">
            <Formik
              initialValues={initialValues}
              validationSchema={rejectConsultInviteSchema}
              onSubmit={onSubmit}
              // enableReinitialize
            >
              {(formik) => {
                return (
                  <Form onSubmit={formik.handleSubmit}>
                    <DashboardSectionTitle
                      iconImg=""
                      title="Enter payment amount"
                      pageTitle={false}
                      className="mb1 small_title"
                    />
                    <Form.Group className="form-group rounded_corners">
                      <Form.Control
                        type="text"
                        placeholder="0"
                        name="payment_amount"
                        {...formik.getFieldProps("payment_amount")}
                      />
                      {formik.touched.payment_amount &&
                      formik.errors.payment_amount ? (
                        <p className="error-state-text">
                          {formik.errors.payment_amount}
                        </p>
                      ) : null}
                    </Form.Group>
                    <div className="btn_wrap">
                      <Button
                        type="submit"
                        className="btn btn_small btn_theme btn_primary btn_rounded"
                      >
                        {loading ? (
                          <>
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />{" "}
                            Processing
                          </>
                        ) : (
                          "Submit"
                        )}
                      </Button>
                      <Button
                        type="button"
                        key="rejectInviteBtn"
                        className="btn_theme btn_small btn_light btn_rounded"
                        onClick={props.handleClose}
                      >
                        Cancel
                      </Button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ConfirmAcceptConsultInviteModal;
