import React from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { BsArrowDown } from "react-icons/bs";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import DashboardCheckboxCard from "../DashboardCheckboxCard/DashboardCheckboxCard";

function RecordEventFormSlider(props) {
  function handleSlideChange(event) {
    // console.log("Slide Changed", event);
    if (event.item.index <= event.item.count) {
      props.activePage(event.item.index);
    }
  }

  function handleFocusBtnClick() {
    props.handleDescFocus();
  }

  return (
    <>
      <OwlCarousel
        className="owl-theme bg_lightgray"
        {...props.options}
        onTranslated={handleSlideChange}
      >
        <div className="item p2">
          <div className="item_content">
            <div className="text-center font-14 fw_sb mb2">
              What emotions did you feel during the event? (select as many as
              apply)
            </div>

            <Row className="row row-cols-2 row-cols-md-3 g-2">
              {props.emotionsList.map((item) => {
                return (
                  <Col key={item.id} >
                    <DashboardCheckboxCard
                      key={item.id}
                      labelInitial="re"
                      item={item}
                      name="emotions"
                      handleChange={props.formik.handleChange}
                      isDisabled={false}
                      isChecked={
                        props.initialValues?.emotions.indexOf(item.value) > -1
                      }
                      initialValues={props.initialValues?.emotions}
                    />
                  </Col>
                );
              })}
            </Row>
            <hr />
            <Row className="row-cols-1">
              <Col>
                <div className="font-14 fw_sb mb-2">
                  Specify other (Optional)
                </div>
                <Form.Group className="form-group rounded_corners mb-2">
                  <Form.Control
                    type="text"
                    name="other_emotion"
                    // onChange={(e) => {
                    //   console.log(e.target.value);
                    // }}
                    ref={props.otherEmotionRef}
                    onBlur={(e) => {
                      console.log(e.target.value);
                    }}
                    
                  />
                </Form.Group>
                <div className="font-13 text_gray7 fw_sb ms-3">
                  * Comma seperated list of keywords
                </div>
                <div className="font-13 text_gray7 fw_sb mb-2 ms-3">
                  Example: emotion 1, emotion 2, emotion 3
                </div>
                 
              </Col>
            </Row>
          </div>
        </div>
        <div className="item p2">
          <div className="item_content">
            <div className="text-center font-14 fw_sb mb2">
              Character(s) inside the event
            </div>

            <Row className="row row-cols-2 row-cols-md-3 g-2">
              {props.charactersList.map((item) => {
                return (
                  <Col key={item.id} >
                    <DashboardCheckboxCard
                      key={item.id}
                      labelInitial="re"
                      item={item}
                      name="characters"
                      handleChange={props.formik.handleChange}
                      isDisabled={false}
                      isChecked={
                        props.initialValues?.characters.indexOf(item.value) > -1
                      }
                      initialValues={props.initialValues?.characters}
                    />
                  </Col>
                );
              })}
            </Row>
            <hr />
            <Row className="row-cols-1">
              <Col>
                <div className="font-14 fw_sb mb-2">
                  Specify other (Optional)
                </div>
                <Form.Group className="form-group rounded_corners mb-2">
                  <Form.Control
                    type="text"
                    name="other_character"
                    // onChange={(e) => {
                    //   console.log(e.target.value);
                    // }}
                    ref={props.otherCharacterRef}
                    onBlur={(e) => {
                      console.log(e.target.value);
                    }}
                    
                  />
                </Form.Group>
                <div className="font-13 text_gray7 fw_sb ms-3">
                  * Comma seperated list of keywords
                </div>
                <div className="font-13 text_gray7 fw_sb mb-2 ms-3">
                  Example: character 1, character 2
                </div>
              </Col>
            </Row>
          </div>
        </div>

        <div className="item p2">
          <div className="item_content">
            <div className="text-center font-14 fw_sb mb2">
              Interactions between characters
            </div>

            <Row className="row row-cols-2 row-cols-md-3 g-2">
              {props.interactionsList.map((item) => {
                return (
                  <Col key={item.id} >
                    <DashboardCheckboxCard
                      key={item.id}
                      labelInitial="re"
                      item={item}
                      name="interactions"
                      handleChange={props.formik.handleChange}
                      isDisabled={false}
                      isChecked={
                        props.initialValues?.interactions.indexOf(item.value) >
                        -1
                      }
                      initialValues={props.initialValues?.interactions}
                    />
                  </Col>
                );
              })}
            </Row>
            <hr />
            <Row className="row-cols-1">
              <Col>
                <div className="font-14 fw_sb mb-2">
                  Specify other (Optional)
                </div>
                <Form.Group className="form-group rounded_corners mb-2">
                  <Form.Control
                    type="text"
                    name="other_interaction"
                    // onChange={(e) => {
                    //   console.log(e.target.value);
                    // }}
                    ref={props.otherInteractionRef}
                    onBlur={(e) => {
                      console.log(e.target.value);
                    }}
                    
                  />
                </Form.Group>
                <div className="font-13 text_gray7 fw_sb ms-3">
                  * Comma seperated list of keywords
                </div>
                <div className="font-13 text_gray7 fw_sb mb-2 ms-3">
                  Example: action 1, action 2
                </div>
              </Col>
            </Row>
          </div>
        </div>

        <div className="item p2">
          <div className="item_content">
            <div className="text-center font-14 fw_sb mb2">
              Location(s) inside the event (select as many as apply)
            </div>
            <Row className="row row-cols-2 row-cols-md-3 g-2">
              {props.locationsList.map((item) => {
                return (
                  <Col key={item.id} >
                    <DashboardCheckboxCard
                      key={item.id}
                      labelInitial="re"
                      item={item}
                      name="locations"
                      handleChange={props.formik.handleChange}
                      isDisabled={false}
                      isChecked={
                        props.initialValues?.locations.indexOf(item.value) > -1
                      }
                      initialValues={props.initialValues?.locations}
                    />
                  </Col>
                );
              })}
            </Row>
            <hr />
            <Row className="row-cols-1">
              <Col>
                <div className="font-14 fw_sb mb-2">
                  Specify other (Optional)
                </div>
                <Form.Group className="form-group rounded_corners mb-2">
                  <Form.Control
                    type="text"
                    name="other_location"
                    // onChange={(e) => {
                    //   console.log(e.target.value);
                    // }}
                    ref={props.otherLocationRef}
                    onBlur={(e) => {
                      console.log(e.target.value);
                    }}
                    
                  />
                </Form.Group>
                <div className="font-13 text_gray7 fw_sb ms-3">
                  * Comma seperated list of keywords
                </div>
                <div className="font-13 text_gray7 fw_sb mb-2 ms-3">
                  Example: location 1, location 2
                </div>
              </Col>
            </Row>
          </div>
        </div>

        <div className="item p2">
          <div className="item_content">
            <div className="text-center font-14 fw_sb mb2">
              What do you think caused this event? (select as many as apply)
            </div>
            <Row className="row row-cols-2 row-cols-md-3 g-2">
              {props.causesList.map((item) => {
                return (
                  <Col key={item.id} >
                    <DashboardCheckboxCard
                      key={item.id}
                      labelInitial="re"
                      item={item}
                      name="causes"
                      handleChange={props.formik.handleChange}
                      isDisabled={false}
                      isChecked={
                        props.initialValues?.causes.indexOf(item.value) > -1
                      }
                      initialValues={props.initialValues?.causes}
                    />
                  </Col>
                );
              })}
            </Row>
            <hr />
            <Row className="row-cols-1">
              <Col>
                <div className="font-14 fw_sb mb-2">
                  Specify other (Optional)
                </div>
                <Form.Group className="form-group rounded_corners mb-2">
                  <Form.Control
                    type="text"
                    name="other_cause"
                    // onChange={(e) => {
                    //   console.log(e.target.value);
                    // }}
                    ref={props.otherCauseRef}
                    onBlur={(e) => {
                      console.log(e.target.value);
                    }}
                    
                  />
                </Form.Group>
                <div className="font-13 text_gray7 fw_sb ms-3">
                  * Comma seperated list of keywords
                </div>
                <div className="font-13 text_gray7 fw_sb mb-2 ms-3">
                  Example: cause 1, cause 2, cause 3
                </div>
              </Col>
            </Row>
          </div>
        </div>

        <div className="item p2">
          <div className="item_content">
            <div className="text-center font-14 fw_sb mb2">
              Has the event inspired any resolutions? (select as many as apply)
            </div>
            <Row className="row row-cols-2 row-cols-md-3 g-2">
              {props.resolutionsList.map((item) => {
                return (
                  <Col key={item.id} >
                    <DashboardCheckboxCard
                      key={item.id}
                      labelInitial="re"
                      item={item}
                      name="resolutions"
                      handleChange={props.formik.handleChange}
                      isDisabled={false}
                      isChecked={
                        props.initialValues?.resolutions.indexOf(item.value) >
                        -1
                      }
                      initialValues={props.initialValues?.resolutions}
                    />
                  </Col>
                );
              })}
            </Row>
            <hr />
            <Row className="row-cols-1">
              <Col>
                <div className="font-14 fw_sb mb-2">
                  Specify other (Optional)
                </div>
                <Form.Group className="form-group rounded_corners mb-2">
                  <Form.Control
                    type="text"
                    name="other_resolution"
                    // onChange={(e) => {
                    //   console.log(e.target.value);
                    // }}
                    ref={props.otherResolutionRef}
                    onBlur={(e) => {
                      console.log(e.target.value);
                    }}
                    
                  />
                </Form.Group>
                <div className="font-13 text_gray7 fw_sb ms-3">
                  * Comma seperated list of keywords
                </div>
                <div className="font-13 text_gray7 fw_sb mb-2 ms-3">
                  Example: resolution 1, resolution 2
                </div>
              </Col>
            </Row>
            <span
              type="button"
              className="btn_theme btn_setfocus btn_icon btn_auto_width btn_primary btn_circle"
              onClick={handleFocusBtnClick}
            >
              <BsArrowDown />
            </span>
          </div>
        </div>

        {/* <div className="item p2">
          <div className="item_content">
            <Row className="align-items-center mb1">
              <Col xs={12} sm={12} md={8} lg={8}>
                <div className="font-14 fw_sb">
                  Full description of event for decoding *
                </div>
              </Col>
              <Col xs={12} sm={12} md={4} lg={4}>
                <div className="d-flex justify-content-end">
                  <Button
                    type="button"
                    className="btn btn_theme btn_small btn_secondary btn_rounded"
                  >
                    Decode
                  </Button>
                </div>
              </Col>
            </Row>
            <Form.Group className="form-group rounded_corners">
              <Form.Control
                as="textarea"
                rows={3}
                name="description"
                // {...props.formik.getFieldProps("description")}
                placeholder=""
              />
            </Form.Group>

            
          </div>
        </div> */}
      </OwlCarousel>
    </>
  );
}

export default RecordEventFormSlider;
