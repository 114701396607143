import React, { useState } from "react";
// import { useHistory, Redirect } from "react-router-dom";
import { Card, Button, Badge, Spinner } from "react-bootstrap";
import CustomCard from "../../../ui/CustomCard/CustomCard";
import CustomCollapseButton from "../../../ui/CustomCollapse/CustomCollapseButton";
import CustomCollapseContainer from "../../../ui/CustomCollapse/CustomCollapseContainer";
import ConsultationCommentList from "./ConsultationCommentList";
import ConsultationTransactionList from "./ConsultationTransactionList";
import AskConsultingPaymentModal from "../../../ui/AskConsultingPaymentModal/AskConsultingPaymentModal";
import {
  BsFillCheckCircleFill,
  BsFillStarFill,
  BsFillExclamationCircleFill,
  BsFillExclamationTriangleFill,
  BsClockHistory,
  BsClockFill,
  BsClock,
  BsXCircleFill,
} from "react-icons/bs";
import "./ConsultingSessionCard.css";
import { handleAlert } from "../../../util/util";

//Service
import recordService from "../../../services/Record";
import ConsultSubmitRatingsModal from "../../../ui/ConsultSubmitRatingsModal/ConsultSubmitRatingsModal";
import ConfirmConsultingSessionCompleteModal from "../../../ui/ConfirmConsultingSessionCompleteModal/ConfirmConsultingSessionCompleteModal";
import ConfirmationModal from "../../../ui/ConfirmationModal/ConfirmationModal";
import DashboardComments from "../DashboardComments/DashboardComments";
import PaymentProcessModal from "../../../ui/PaymentProcessModal/PaymentProcessModal";
import SessionStatusTag from "./SessionStatusTag";

function ConsultingSessionCard(props) {
  // const history = useHistory();
  console.log("Con sessions =", props.session);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [sessionCommentsCount, setSessionCommentsCount] = useState(0);
  const [sessionComments, setSessionComments] = useState([]);
  const [sessionTransactions, setSessionTransactions] = useState([]);
  const [redirectionURL, setRedirectionURL] = useState("");

  //Confirm consulting session make payment redirection Modal State
  const [show4, setShow4] = useState({
    isVisible: false,
  });

  //Confirm consulting session mark complete Modal State
  const [show3, setShow3] = useState({
    isVisible: false,
  });

  //Submit ratings Modal State
  const [show2, setShow2] = useState({
    isVisible: false,
  });
  //Ask for Payment Modal State
  const [show, setShow] = useState({
    isVisible: false,
  });

  const handleClose = () => {
    // console.log("Closing the modal");
    setShow({
      isVisible: false,
    });
  };
  const handleShow = (e) => {
    console.log("data-id=", e.target.getAttribute("data-id"));
    // setRemoveMemberData({
    //   user_id: e.target.getAttribute("data-id"),
    //   group_id: props.group.user_group_id,
    // });

    setShow({
      isVisible: true,
    });
    // console.log("Opening the modal");
  };

  const handleClose2 = () => {
    // console.log("Closing the modal");
    setShow2({
      isVisible: false,
    });
  };
  const handleShow2 = (e) => {
    console.log("data-id=", e.target.getAttribute("data-id"));
    // setRemoveMemberData({
    //   user_id: e.target.getAttribute("data-id"),
    //   group_id: props.group.user_group_id,
    // });

    setShow2({
      isVisible: true,
    });
    // console.log("Opening the modal");
  };

  const handleClose3 = () => {
    // console.log("Closing the modal");
    setShow3({
      isVisible: false,
    });
  };
  const handleShow3 = (e) => {
    console.log("data-id=", e.target.getAttribute("data-id"));
    // setRemoveMemberData({
    //   user_id: e.target.getAttribute("data-id"),
    //   group_id: props.group.user_group_id,
    // });

    setShow3({
      isVisible: true,
    });
    // console.log("Opening the modal");
  };

  const handleClose4 = () => {
    // console.log("Closing the modal");
    setShow4({
      isVisible: false,
    });
  };
  const handleShow4 = (e) => {
    console.log("Opening redirection url modal...", e);
    // setRemoveMemberData({
    //   user_id: e.target.getAttribute("data-id"),
    //   group_id: props.group.user_group_id,
    // });

    setShow4({
      isVisible: true,
    });
    // console.log("Opening the modal");
  };

  const [openCollapse, setOpenCollapse] = useState({
    view_session_comments_collapse: false,
    view_session_transactions_collapse: false,
  });

  let tempState = null;
  const handleCollapse = (element) => {
    switch (element) {
      case "view_session_comments":
        tempState = Object.fromEntries(
          Object.keys({ ...openCollapse }).map((key) => [key, false])
        );
        setOpenCollapse({
          ...tempState,
          view_session_comments_collapse:
            !openCollapse.view_session_comments_collapse,
        });
        break;
      case "view_session_transactions":
        tempState = Object.fromEntries(
          Object.keys({ ...openCollapse }).map((key) => [key, false])
        );
        setOpenCollapse({
          ...tempState,
          view_session_transactions_collapse:
            !openCollapse.view_session_transactions_collapse,
        });
        break;
    }
  };

  const isConsultant =
    props.session.consultant_id == props.authUser.user_id ? true : false;

  const isRatingDone = props.session.rating_done ? true : false;

  const rating = props.session.rating ? props.session.rating : 0;

  const avatar =
    props.session?.consultant?.avatar !== "NULL"
      ? props.session?.consultant?.avatar
      : `${process.env.PUBLIC_URL}/img/user-profile.jpg`;
  const consultant_name = props.session.consultant
    ? props.session.consultant.firstname +
      " " +
      props.session.consultant.lastname
    : "Unknown";
  const user_name = props.session.user
    ? props.session.user.firstname + " " + props.session.user.lastname
    : "Unknown";

  const specialty = props.session.consultant
    ? props.session.consultant.specialty
    : [];

  const session_status = props.session.status ? props.session.status : "";
  const payment_status = props.session.payment_status
    ? props.session.payment_status
    : "";
  const payment_amount = props.session.payment_amount
    ? props.session.payment_amount
    : 0;
  // approved
  const session_tag =
    props.session.status === "invited" ? (
      <>
        <span
          className="custom_tag tag_with_icon tag_danger_light"
          style={{ whiteSpace: "nowrap" }}
        >
          <BsFillExclamationCircleFill style={{ marginRight: "0px" }} /> Invited
        </span>
      </>
    ) : (
      <>
        {props.session.status === "approved" ? (
          <span
            className="custom_tag tag_with_icon tag_success"
            style={{ whiteSpace: "nowrap" }}
          >
            <BsFillCheckCircleFill style={{ marginRight: "0px" }} /> Approved
          </span>
        ) : (
          <>
            {props.session.status === "rejected" ? (
              <span
                className="custom_tag tag_with_icon tag_danger_light"
                style={{ whiteSpace: "nowrap" }}
              >
                <BsXCircleFill style={{ marginRight: "0px" }} /> Rejected
              </span>
            ) : (
              <span
                className="custom_tag tag_with_icon tag_success"
                style={{ whiteSpace: "nowrap" }}
              >
                <BsFillCheckCircleFill style={{ marginRight: "0px" }} />{" "}
                Completed
              </span>
            )}
          </>
        )}
      </>
    );

  const pending_payments = props.session.transactions
    ? props.session.transactions.filter(
        (item) => item.payment_status !== "completed"
      )
    : [];
  const first_payment =
    pending_payments.length > 0 && pending_payments.slice(0, 1).shift();

  console.log(
    "pending_payments =",
    pending_payments,
    "\nfirst_payment =",
    first_payment
  );

  const makePayment = (id) => {
    setLoading2(true);
    const paymentData = {
      consult_payment_id: id,
    };

    recordService
      .makePayment(paymentData)
      .then((response) => {
        if (response.data.status === "success") {
          console.log("makePayment() function: ", response.data);
          const resResult = response.data.data.result.redirect_url;

          // setRedirectionURL(response.data.data.result.redirect_url);
          handleAlert("Redirecting to URL", "success");
          // history.replace(resResult);
          window.location.href = resResult;
          // handleShow4();
          // setTimeout(() => {
          //   if (redirectionURL) handleShow4();
          // }, 1000);
          // handleAlert("payment Completed!", "success");
          // props.getAllConsultingSessions();
          setLoading2(false);
        } else if (response.data.status === "failed") {
          console.log("ERROR:", response.data.error);
          handleAlert("Failed to make payment!", "error");
          setRedirectionURL("");
          setLoading2(false);
        }
      })
      .catch((err) => {
        console.log("Failed to make payment...", err);
        setLoading2(false);
        setRedirectionURL("");
      });
  };

  const getAllConsultationTrasactionList = (session) => {
    // if (session.hasOwnProperty("transactions")) {
    setSessionTransactions(session.transactions);
    // }
  };

  const consultMarkComplete = (id) => {
    setLoading(true);
    const data = {
      consult_id: id,
    };
    console.log("consultMarkComplete req data =", data);
    recordService
      .consultMarkComplete(data)
      .then((response) => {
        if (response.data.status === "success") {
          console.log("consultMarkComplete() Consult list: ", response.data);
          handleAlert(
            "Success! You have marked consulting session as completed.",
            "success"
          );

          setLoading(false);
          //Close modal
          handleClose3();

          props.getAllConsultingSessions();
        } else if (response.data.status === "failed") {
          console.log("ERROR:", response.data.error);
          handleAlert(
            "Error! Failed to mark consulting session as completed.",
            "error"
          );
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log("There is a problem in marking session complete...", err);
        setLoading(false);
      });
  };

  const handleConsultationCommentList = (id) => {
    setLoading(true);
    const commentData = {
      consult_id: id,
    };
    recordService
      .getAllConsultationComments(commentData)
      .then((response) => {
        if (response.data.status === "success") {
          console.log(
            "handleConsultationpCommentList() Consult list: ",
            response.data
          );
          const sessionComments = response.data.data.result.comments;

          setSessionComments(sessionComments);
          setLoading(false);
        } else if (response.data.status === "failed") {
          console.log("ERROR:", response.data.error);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log("There is a problem in fetching session comments...", err);
        setLoading(false);
      });
  };

  return (
    <>
      <CustomCard
        className={`consulting_session_card main_card shadow1 border-0 mb2`}
        key={props.session.consult_id}
      >
        <Card.Header className="consulting_session_card_header">
          <div className="media">
            <div className="media_img_wrap">
              {/* <img
                src={`${process.env.PUBLIC_URL}/img/profile1.png`}
                className={`rounded-circle media_img`}
                alt="user profile"
              /> */}
              <img
                src={avatar}
                className={`rounded-circle media_img`}
                alt={`${consultant_name} profile pic`}
              />
            </div>

            <div className={`media_body ms-2 ms-md-3`}>
              <Card.Title className="card_title">{consultant_name}</Card.Title>
              <Card.Subtitle className="card_subtitle">
                {`consulting with ${user_name}`}
                {/* <div>{props.session.created_date}</div> */}
              </Card.Subtitle>
            </div>
            <SessionStatusTag status={props.session.status} />
            {/* {session_tag} */}
          </div>
        </Card.Header>
        <Card.Body className="consulting_session_card_body">
          {/* {`Session ID is ${props.session.consult_id}`} */}
          {session_status === "invited" && (
            <p className="fw_sb mb-1">
              Session invitation request has been sent!
            </p>
          )}
          {session_status === "rejected" && (
            <p className="fw_sb mb-1">Session is rejected! </p>
          )}
          {(session_status === "approved" ||
            session_status === "completed") && (
            <>
              {/* {payment_status == "pending" && (
                <>
                  <span className="custom_tag tag_danger_light">
                    <BsFillExclamationCircleFill className="me-1" /> Payment
                    pending
                  </span>
                </>
              )} */}
              {/* {payment_status == "completed" && (
                <>
                  <span className="custom_tag tag_success">
                    <BsFillCheckCircleFill className="me-1" /> Payment completed
                  </span>
                </>
              )} */}

              <div className="mb-3 d-flex align-items-center">
                <BsClockHistory className="font-18 me-2" />
                <span className="">{props.session.created_date}</span>

                {isRatingDone && session_status === "completed" && (
                  <span
                    className={`custom_tag tag_small ${
                      rating >= 4
                        ? "tag_success"
                        : `${rating >= 2 ? "tag_warning" : "tag_danger"}`
                    }  ms-2 mb-0`}
                    style={{ padding: "4px 13px", fontSize: "14px" }}
                  >
                    <BsFillStarFill
                      className="me-1"
                      style={{ marginTop: "-2px" }}
                    />{" "}
                    <span>{rating}</span>
                  </span>
                )}
              </div>

              {specialty.length > 0 && (
                <div className="mb2">
                  {specialty.map((item) => (
                    <span className="custom_tag tag_purple tag_small">
                      {item}
                    </span>
                  ))}
                </div>
              )}

              {/* <div className="mb2">
                <strong>{`${consultant_name} `}</strong>
                {"is consulting with"}
                <strong>{` ${user_name}`}</strong>
              </div> */}

              {pending_payments.length > 0 && (
                <>
                  {first_payment && (
                    <div className="d-flex justify-content-between align-items-center p1 bg_lightgray mb2">
                      <div class="me-2 fw_sb">
                        Total payment due: ${first_payment.payment_amount}
                      </div>
                      <div>
                        {!isConsultant && (
                          <Button
                            type="button"
                            className="btn_theme btn_small btn_success btn_rounded"
                            onClick={() => {
                              makePayment(first_payment.consult_payment_id);
                            }}
                          >
                            {loading2 ? (
                              <>
                                <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                />{" "}
                                Processing
                              </>
                            ) : (
                              "Make payment"
                            )}
                          </Button>
                        )}
                      </div>
                    </div>
                  )}
                </>
              )}

              <div className="btn_wrap btn_wrap_overflow">
                {props.session.actions?.map((action, index) => {
                  switch (action) {
                    case "view_session_comments":
                      return (
                        <CustomCollapseButton
                          key={index}
                          handleCollapse={handleCollapse}
                          // btnTitle={
                          //   sessionCommentsCount > 1 ? (
                          //     <>{`${sessionCommentsCount} Comments`}</>
                          //   ) : (
                          //     <>{`${sessionCommentsCount} Comment`}</>
                          //   )
                          // }
                          btnTitle="Conversation"
                          className="btn_small btn_light btn_rounded"
                          ariaControls="view_session_comments"
                          ariaExpanded={
                            openCollapse.view_session_comments_collapse
                          }
                          setCardTop={props.setCardTop}
                        />
                      );
                    case "view_session_transactions":
                      return (
                        <CustomCollapseButton
                          key={index}
                          handleCollapse={handleCollapse}
                          btnTitle="Transactions"
                          className="btn_small btn_primary btn_rounded"
                          ariaControls="view_session_transactions"
                          ariaExpanded={
                            openCollapse.view_session_transactions_collapse
                          }
                          setCardTop={props.setCardTop}
                        />
                      );
                  }
                })}
                {isConsultant && session_status !== "completed" && (
                  <Button
                    type="button"
                    onClick={handleShow}
                    className="btn_theme btn_small btn_success btn_rounded"
                  >
                    Ask for payment
                  </Button>
                )}
                {isConsultant &&
                  payment_status === "completed" &&
                  session_status !== "completed" && (
                    <Button
                      type="button"
                      onClick={handleShow3}
                      className="btn_theme btn_small btn_gray btn_rounded"
                    >
                      Mark Complete
                    </Button>
                  )}
                {!isConsultant &&
                  !isRatingDone &&
                  session_status === "completed" && (
                    <Button
                      type="button"
                      className="btn_theme btn_small btn_gray btn_rounded"
                      onClick={handleShow2}
                    >
                      Give Ratings
                    </Button>
                  )}
              </div>
              {props.session.actions?.map((action, index) => {
                switch (action) {
                  case "view_session_comments":
                    return (
                      <CustomCollapseContainer
                        key={index}
                        ariaControls="view_session_comments"
                        openCollapse={
                          openCollapse.view_session_comments_collapse
                        }
                        handleConsultationCommentList={
                          handleConsultationCommentList
                        }
                        consult_id={props.session.consult_id}
                        // className="position-relative border-top mt-3 pt-3 pb-3"
                        // handleIsUpdating={handleIsUpdating}
                        // thread={props.thread}
                        // cardTop={cardTop}
                      >
                        <DashboardComments
                          comment_type="consult"
                          loading={loading}
                          parent_id={props.session.consult_id}
                          // parent_owner_id={props.thread.user_id}
                          comments={sessionComments}
                          handleCommentList={handleConsultationCommentList}
                          setCommentsCount={setSessionCommentsCount}
                        />
                        {/* <div className="d-grid gap-3 comment_list_wrap">
                          <ConsultationCommentList
                            loading={loading}
                            consult_id={props.session.consult_id}
                            setSessionCommentsCount={setSessionCommentsCount}
                            handleConsultationCommentList={
                              handleConsultationCommentList
                            }
                            sessionComments={sessionComments}
                          />
                        </div> */}
                      </CustomCollapseContainer>
                    );
                  case "view_session_transactions":
                    return (
                      <CustomCollapseContainer
                        key={index}
                        ariaControls="view_session_transactions"
                        openCollapse={
                          openCollapse.view_session_transactions_collapse
                        }
                        getAllConsultationTrasactionList={
                          getAllConsultationTrasactionList
                        }
                        session={props.session}
                        // handleIsUpdating={handleIsUpdating}
                        // thread={props.thread}
                        // cardTop={cardTop}
                      >
                        <hr />
                        <ConsultationTransactionList
                          sessionTransactions={sessionTransactions}
                        />
                        {/* <CustomCard className="shadow1 border-0 text-center">
                          <Card.Body>
                            <div className="mb1">
                              <BsFillExclamationTriangleFill
                                style={{
                                  color: "#d3af61",
                                  fontSize: "2.2rem",
                                }}
                              />
                            </div>
                            <b>No more session transactions found...</b>
                          </Card.Body>
                        </CustomCard> */}
                      </CustomCollapseContainer>
                    );
                }
              })}
            </>
          )}
          {/* <div className="mb1">
            <span className="custom_badge">{props.session.service_type}</span>
          </div> */}

          {/* <div className="btn_wrap">
            <Button
              type="button"
              className="btn_theme btn_small btn_secondary btn_rounded"
            >
              Actions
            </Button>
          </div> */}
        </Card.Body>
      </CustomCard>
      <AskConsultingPaymentModal
        show={show}
        handleClose={handleClose}
        consult_id={props.session.consult_id}
        getAllConsultingSessions={props.getAllConsultingSessions}
      />
      <ConsultSubmitRatingsModal
        show={show2}
        handleClose={handleClose2}
        consult_id={props.session.consult_id}
        getAllConsultingSessions={props.getAllConsultingSessions}
      />
      {/* <ConfirmConsultingSessionCompleteModal
        show={show3}
        handleClose={handleClose3}
        consult_id={props.session.consult_id}
        consultMarkComplete={consultMarkComplete}
        getAllConsultingSessions={props.getAllConsultingSessions}
        loading={loading}
      /> */}
      <ConfirmationModal
        show={show3}
        loading={loading}
        handleClose={handleClose3}
        primaryActionData={props.session.consult_id}
        content_type="consulting-session-complete-modal"
        handlePrimaryAction={consultMarkComplete}
      />

      <PaymentProcessModal
        show={show4}
        loading={loading}
        handleClose={handleClose4}
        redirectionURL={redirectionURL}
      />
    </>
  );
}

export default ConsultingSessionCard;
