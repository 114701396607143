import React, { useState, useEffect } from "react";
import "./CircularProgressProvider.css";

function CircularProgressProvider({ valueStart, valueEnd, children }) {
  const [value, setValue] = React.useState(valueStart);
  useEffect(() => {
    setValue(valueEnd);
  }, [valueEnd]);

  // useEffect(() => {
  //   const timer = setInterval(() => {
  //     let randVal = Math.round(Math.random() * 100);
  //     if (randVal > 20 && randVal < 50) {
  //       randVal += 45;
  //     }
  //     setValue(randVal);
  //   }, 10000);

  //   return () => {
  //     clearInterval(timer);
  //   };
  // }, []);

  return children(value);
}

export default CircularProgressProvider;
