import React, { useState } from "react";
import { Col, Row, Card, Form, Button, Spinner } from "react-bootstrap";
import CustomCard from "../../../ui/CustomCard/CustomCard";
import CustomCollapseButton from "../../../ui/CustomCollapse/CustomCollapseButton";
import CustomCollapseContainer from "../../../ui/CustomCollapse/CustomCollapseContainer";
import CreateThread from "./CreateThread";
import ThreadList from "./ThreadList";
import CreateForum from "./CreateForum";
import DashboardSectionTitle from "../DashboardSectionTitle/DashboardSectionTitle";
import {
  BsFillStarFill,
  BsFillPeopleFill,
  BsChatSquareTextFill,
} from "react-icons/bs";
import { FaClone } from "react-icons/fa";
import {
  BiCommentAdd,
  BiCommentDetail,
  BiChat,
  BiConversation,
} from "react-icons/bi";
import "./ForumCard.css";

import { handleAlert } from "../../../util/util";

//Service
import recordService from "../../../services/Record";
import authService from "../../../services/Auth";

function ForumCard({ forum, getAllForums }) {
  const authUser = JSON.parse(authService.getAuthUser());
  const [loading, setLoading] = useState(false);
  const [threads, setThreads] = useState([]);
  const [editForum, setEditForum] = useState(null);
  const [threadCount, setThreadCount] = useState(forum.thread_count);
  const [cardTop, setCardTop] = useState(0);
  const isForumOwner = authUser.user_id == forum.user_id ? true : false;

  let thread_actions = [
    "edit_thread",
    // "add_thread_comment",
    "view_thread_comments",
  ];
  const [openCollapse, setOpenCollapse] = useState({
    edit_forum_collapse: false,
    create_thread_collapse: false,
    view_threads_collapse: false,
  });

  let tempState = null;
  const handleCollapse = (element) => {
    switch (element) {
      case "edit_forum":
        tempState = Object.fromEntries(
          Object.keys({ ...openCollapse }).map((key) => [key, false])
        );
        setOpenCollapse({
          ...tempState,
          edit_forum_collapse: !openCollapse.edit_forum_collapse,
        });
        break;
      case "create_thread":
        tempState = Object.fromEntries(
          Object.keys({ ...openCollapse }).map((key) => [key, false])
        );
        setOpenCollapse({
          ...tempState,
          create_thread_collapse: !openCollapse.create_thread_collapse,
        });
        break;
      case "view_threads":
        tempState = Object.fromEntries(
          Object.keys({ ...openCollapse }).map((key) => [key, false])
        );
        setOpenCollapse({
          ...tempState,
          view_threads_collapse: !openCollapse.view_threads_collapse,
        });
        break;
    }
  };

  function handleIsUpdating(item) {
    console.log("Item =", item);
    if (forum.forum_id == item.forum_id) {
      console.log("Inside if loop ", forum.forum_id == item.forum_id);
      recordService
        .getForum(item.forum_id)
        .then((response) => {
          if (response.data.status === "success") {
            console.log("handleIsUpdating() - getForum : ", response.data);
            const newResponse = response.data.data.result.forum;
            // const newValues = {
            //   ...newResponse,
            //   forum_id: props.forum_id,
            // };
            console.log("New Form Values =", newResponse);
            setEditForum(newResponse);
          }
          if (response.data.status === "failed") {
            console.log("ERROR:", response.data.error);
            // setLoading(false);
          }
        })
        .catch((err) => {
          console.log("There is a problem in fetching thread details...", err);
          // setLoading(false);
        });
    }
  }

  const handleThreadList = (id) => {
    setLoading(true);
    recordService
      .getAllThreads(id)
      .then((response) => {
        if (response.data.status === "success") {
          // console.log("handleAssociationList() Assoc list: ", response.data);
          const forumThreads = response.data.data.result.threads;

          setThreads(
            forumThreads.map((record) => {
              return { ...record, actions: thread_actions };
            })
          );
          // setThreads(forumThreads);
          setLoading(false);
        } else if (response.data.status === "failed") {
          console.log("ERROR:", response.data.error);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log("There is a problem in fetching threads...", err);
        setLoading(false);
      });
  };

  return (
    <CustomCard
      key={forum.forum_id}
      className="main_card border-0 forum_card shadow1 mb2"
    >
      <Card.Header className="bg_transparent">
        <div className="media">
          <div className="media_icon_wrap d-flex align-items-center justify-content-center">
            <BsChatSquareTextFill />
          </div>
          <div className={`media_body ms-3`}>
            <Card.Title className="card_title">
              {forum.title}
              {/* | {forum.forum_id} */}
            </Card.Title>
            <Card.Subtitle className="card_subtitle">
              {`${
                forum.created_date ? `created on ${forum.created_date}` : "NA"
              }`}
            </Card.Subtitle>
          </div>
          {isForumOwner && (
            <CustomCollapseButton
              handleCollapse={handleCollapse}
              btnTitle={`Edit Forum`}
              className="btn_gray btn_icon btn_auto_width btn_circle"
              ariaControls="edit_forum"
              ariaExpanded={openCollapse.edit_forum_collapse}
              btnType="icon"
            />
          )}
        </div>
      </Card.Header>
      <Card.Body>
        <Card.Text>{forum?.description}</Card.Text>
        {/* <DashboardSectionTitle
          iconImg=""
          title={`${forum.thread_count > 1 ? "Thread(s):" : "Thread:"} ${
            forum.thread_count ? forum.thread_count : 0
          }`}
          pageTitle={false}
          className="small_title"
        /> */}
        <div className="btn_wrap">
          {forum.actions?.map((action, index) => {
            switch (action) {
              case "edit_forum":
                return;
              case "create_thread":
                return (
                  <CustomCollapseButton
                    key={index}
                    handleCollapse={handleCollapse}
                    btnTitle="Add Thread"
                    className="btn_small btn_gray btn_rounded"
                    ariaControls="create_thread"
                    ariaExpanded={openCollapse.create_thread_collapse}
                    setCardTop={setCardTop}
                  />
                );
              case "view_threads":
                return (
                  <CustomCollapseButton
                    key={index}
                    handleCollapse={handleCollapse}
                    btnTitle={
                      threadCount === 1
                        ? `${threadCount} Thread`
                        : `${threadCount} Threads`
                    }
                    className="btn_small btn_light btn_rounded"
                    ariaControls="view_threads"
                    ariaExpanded={openCollapse.view_threads_collapse}
                    setCardTop={setCardTop}
                  />
                );
            }
          })}
        </div>
        {forum.actions?.map((action, index) => {
          switch (action) {
            case "edit_forum":
              return (
                <CustomCollapseContainer
                  key={index}
                  ariaControls="edit_forum"
                  openCollapse={openCollapse.edit_forum_collapse}
                  handleIsUpdating={handleIsUpdating}
                  forum={forum}
                  // cardTop={cardTop}
                >
                  <hr />
                  <div className="pt2 pb2">
                    Update forum
                    <CreateForum
                      forum_id={forum.forum_id}
                      isAddMode={false}
                      existingForum={editForum}
                      getAllForums={getAllForums}
                      handleCollapse={handleCollapse}
                    />
                  </div>
                </CustomCollapseContainer>
              );
            case "create_thread":
              return (
                <CustomCollapseContainer
                  key={index}
                  ariaControls="create_thread"
                  openCollapse={openCollapse.create_thread_collapse}
                  cardTop={cardTop}
                >
                  <hr />
                  <div className="pt2 pb2">
                    <CreateThread
                      forum_id={forum.forum_id}
                      isAddMode={true}
                      setThreadCount={setThreadCount}
                      handleCollapse={handleCollapse}
                      handleThreadList={handleThreadList}
                    />
                  </div>
                </CustomCollapseContainer>
              );
            case "view_threads":
              return (
                <CustomCollapseContainer
                  key={index}
                  ariaControls="view_threads"
                  openCollapse={openCollapse.view_threads_collapse}
                  forum_id={forum.forum_id}
                  handleThreadList={handleThreadList}
                  cardTop={cardTop}
                >
                  <hr />
                  <div className="pt1 pb0">
                    <ThreadList
                      handleCollapse={handleCollapse}
                      threads={threads}
                      loading={loading}
                      forum_id={forum.forum_id}
                      handleThreadList={handleThreadList}
                      cardTop={cardTop}
                      setCardTop={setCardTop}
                      authUser={authUser}
                    />
                  </div>
                </CustomCollapseContainer>
              );
          }
        })}
      </Card.Body>
    </CustomCard>
  );
}

export default ForumCard;
