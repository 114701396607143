import React, { useState, useEffect } from "react";
import { Card, Spinner, Row, Col, Placeholder } from "react-bootstrap";
import CustomCard from "../../../ui/CustomCard/CustomCard";
import DashboardCheckboxCard from "../DashboardCheckboxCard/DashboardCheckboxCard";
import DashboardSectionTitle from "../DashboardSectionTitle/DashboardSectionTitle";
import EmojiIconCard from "../../../ui/IconCard/EmojiIconCard";

function MatchedExperienceElements(props) {
  console.log(
    "Inside MatchedExperienceElements -- ",
    props.matchingRecordElements
  );

  const emotions = props.matchingRecordElements.emotions
    ? props.matchingRecordElements.emotions
    : [];
  const characters = props.matchingRecordElements.characters
    ? props.matchingRecordElements.characters
    : [];
  const interactions = props.matchingRecordElements.interactions
    ? props.matchingRecordElements.interactions
    : [];
  const locations = props.matchingRecordElements.locations
    ? props.matchingRecordElements.locations
    : [];
  const causes = props.matchingRecordElements.causes
    ? props.matchingRecordElements.causes
    : [];
  const resolutions = props.matchingRecordElements.resolutions
    ? props.matchingRecordElements.resolutions
    : [];

  return (
    <>
      {!props.matchingRecordElements.hasOwnProperty("record_created_date") && (
        <>
          <div className="w-100 mb-1">
            <Placeholder xs={12} bg="secondary" />
          </div>
          <div className="w-100 mb1">
            <Placeholder xs={8} bg="secondary" />
          </div>
          <hr />
          <div className="w-100 mb-1">
            <Placeholder xs={12} bg="secondary" />
          </div>
          <div className="w-100 mb1">
            <Placeholder xs={8} bg="secondary" />
          </div>
          <hr />
          <div className="w-100 mb-1">
            <Placeholder xs={12} bg="secondary" />
          </div>
          <div className="w-100 mb1">
            <Placeholder xs={8} bg="secondary" />
          </div>
        </>
      )}

      {emotions.length > 0 && (
        <>
          <DashboardSectionTitle
            iconImg=""
            title="Emotions"
            pageTitle={false}
            className="small_title mb1"
          />
          <div className="bg_lightgray p1 mb2">
            <Row>
              {emotions.map((item, index) => {
                return (
                  <>
                    <Col key={index} xs={12} sm={6} md={4} lg={3}>
                      <EmojiIconCard iconGroup="emotions" iconName={item} />
                    </Col>
                  </>
                );
              })}
            </Row>
          </div>
        </>
      )}

      {characters.length > 0 && (
        <>
          <DashboardSectionTitle
            iconImg=""
            title="Characters"
            pageTitle={false}
            className="small_title mb1"
          />
          <div className="bg_lightgray p1 mb2">
            <Row>
              {characters.map((item, index) => {
                return (
                  <>
                    <Col key={index} xs={12} sm={6} md={4} lg={3}>
                      <EmojiIconCard iconGroup="characters" iconName={item} />
                    </Col>
                  </>
                );
              })}
            </Row>
          </div>
        </>
      )}

      {interactions.length > 0 && (
        <>
          <DashboardSectionTitle
            iconImg=""
            title="Interactions between characters"
            pageTitle={false}
            className="small_title mb1"
          />
          <div className="bg_lightgray p1 mb2">
            <Row>
              {interactions.map((item, index) => {
                return (
                  <>
                    <Col key={index} xs={12} sm={6} md={4} lg={3}>
                      <EmojiIconCard iconGroup="interactions" iconName={item} />
                    </Col>
                  </>
                );
              })}
            </Row>
          </div>
        </>
      )}

      {locations.length > 0 && (
        <>
          <DashboardSectionTitle
            iconImg=""
            title="Locations"
            pageTitle={false}
            className="small_title mb1"
          />
          <div className="bg_lightgray p1 mb2">
            <Row>
              {locations.map((item, index) => {
                return (
                  <>
                    <Col key={index} xs={12} sm={6} md={4} lg={3}>
                      <EmojiIconCard iconGroup="locations" iconName={item} />
                    </Col>
                  </>
                );
              })}
            </Row>
          </div>
        </>
      )}

      {causes.length > 0 && (
        <>
          <DashboardSectionTitle
            iconImg=""
            title="Causes"
            pageTitle={false}
            className="small_title mb1"
          />
          <div className="bg_lightgray p1 mb2">
            <Row>
              {causes.map((item, index) => {
                return (
                  <>
                    <Col key={index} xs={12} sm={6} md={4} lg={3}>
                      <EmojiIconCard iconGroup="causes" iconName={item} />
                    </Col>
                  </>
                );
              })}
            </Row>
          </div>
        </>
      )}

      {resolutions.length > 0 && (
        <>
          <DashboardSectionTitle
            iconImg=""
            title="Resolutions"
            pageTitle={false}
            className="small_title mb1"
          />
          <div className="bg_lightgray p1 mb2">
            <Row>
              {resolutions.map((item, index) => {
                return (
                  <>
                    <Col key={index} xs={12} sm={6} md={4} lg={3}>
                      <EmojiIconCard iconGroup="resolutions" iconName={item} />
                    </Col>
                  </>
                );
              })}
            </Row>
          </div>
        </>
      )}
    </>
  );
}

export default MatchedExperienceElements;
