import React, { useState, useEffect } from "react";
import ReactWordcloud from "react-wordcloud";

function DashboardWordcloudChart(props) {
  const options = {
    colors: [
      "#26c6da",
      "#ffb74d",
      "#a1887f",
      "#7986cb",
      "#ff8a65",
      "#ba68c8",
      "#81c784",
      "#7986cb",
      "#b39d9d",
    ],
    enableOptimizations: true,
    enableTooltip: true,
    deterministic: true,
    fontSizes: [20, 44],
    fontStyle: "normal",
    fontWeight: "normal",
    padding: 2,
    rotations: 3,
    rotationAngles: [-20, 45],
    scale: "linear", //['sqrt', 'linear', 'log']
    spiral: "rectangular", // ['archimedean', 'rectangular']
    transitionDuration: 300,
  };

  console.log("Datasets =", props.datasets);

  return (
    <>
      <div>
        <span className="fw_sb mb-1 d-flex">{`# of experiences = ${props.recordCount}`}</span>
      </div>

      <div className="pt1" style={{ height: "30vh" }}>
        {/* <ReactWordcloud options={props.options} words={props.datasets} /> */}
        <ReactWordcloud options={options} words={props.datasets} />
      </div>
    </>
  );
}

export default DashboardWordcloudChart;
