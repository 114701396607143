import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import HomeVideoContainer from "../HomeVideoContainer/HomeVideoContainer";
import "./HomeVideoBanner.css";

function HomeVideoBanner() {
  return (
    <>
      <section
        className={`page_banner video_banner`}
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/img/banner-bg-dark.jpg)`,
        }}
      >
        <Container>
          <Row className="align-items-center">
            <Col xs={12} sm={12} md={6}>
              <h3>Opening up new possibilities for self-development</h3>
              <Link
                to="/signup"
                className="btn btn_theme btn_rounded btn_border btn_white btn_large"
              >
                Start Today!
              </Link>
            </Col>
            <Col xs={12} sm={12} md={6}>
              <HomeVideoContainer className="page_banner">
                <video
                  preload="auto"
                  poster={`${process.env.PUBLIC_URL}/img/ucs-informative-poster.jpg`}
                  playsInline={true}
                  controls={true}
                >
                  <source
                    src={`${process.env.PUBLIC_URL}/media/ucs-informative.mp4`}
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
              </HomeVideoContainer>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default HomeVideoBanner;
