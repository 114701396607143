import React, { useEffect, useContext, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Col,
  Container,
  Row,
  Form,
  Navbar,
  Dropdown,
  Button,
} from "react-bootstrap";
import ConfirmationModal from "../../../ui/ConfirmationModal/ConfirmationModal";
import {
  BsMoonStarsFill,
  BsFillSunFill,
  BsBellFill,
  BsListNested,
  BsList,
} from "react-icons/bs";
import { FaSignOutAlt, FaBars, FaStream, FaTimes } from "react-icons/fa";

import { handleAlert, handleThemeChange } from "../../../util/util";
import "./DashboardHeader.css";
//Service
import authService from "../../../services/Auth";

//Context
import ThemeContext from "../../../ThemeContext";

function DashboardHeader(props) {
  const navigate = useNavigate();
  const { theme, setTheme } = useContext(ThemeContext);
  const [isScrolling, setIsScrolling] = useState(false);
  const [show, setShow] = useState({
    isVisible: false,
  });

  const navbarRef = useRef();

  const handleClose = () => {
    console.log("Closing the modal");
    setShow({
      isVisible: false,
    });
  };
  const handleShow = () => {
    setShow({
      isVisible: true,
    });
    console.log("Opening the modal");
  };

  function handleLogout() {
    const timer = setTimeout(authService.logout(), 1000);
    console.log(props);
    handleAlert("You have successfully logged out!", "success");
    navigate("/");
    setTheme("light");
    document.body.classList.remove("dark_theme");
    clearTimeout(timer);
  }

  function handleClick() {
    console.log("Dark mode button clicked");
    if (theme === "light") {
      handleThemeChange("dark", setTheme);
    } else {
      handleThemeChange("light", setTheme);
    }
  }

  const toggleNavbar = () => {
    props.setIsNavbarOpen(!props.isNavbarOpen);
  };

  //check window vertical scrolling > 5 then add special styles to navbar...
  // window.addEventListener("scroll", function () {
  //   if (Math.round(window.scrollY) >= 5) {
  //     console.log("setIsScrolling to true");
  //     setIsScrolling(true);
  //   } else {
  //     console.log("setIsScrolling to false");
  //     setIsScrolling(false);
  //     // console.log("window scrolling between 300");
  //   }
  // });
  // window.addEventListener("scroll", handlePageScroll);

  function handlePageScroll() {
    const scrollPosition = window.scrollY;
    // console.log("scrollPosition = ", scrollPosition);
    // console.log("navbarRef = ", navbarRef.current.classList);
    if (Math.round(scrollPosition) >= 10) {
      // console.log("setIsScrolling to true");
      navbarRef.current.classList.add("active");
      // setIsScrolling(true);
    } else {
      // console.log("setIsScrolling to false");
      navbarRef.current.classList.remove("active");
      // setIsScrolling(false);
      // console.log("window scrolling between 300");
    }
  }

  useEffect(() => {
    //call the handler to get scroll position when component first loads...
    handlePageScroll();
    //Attach scroll event to window...
    window.addEventListener("scroll", handlePageScroll);

    return () => {
      //Remove scroll event to window...when component unmounts
      window.removeEventListener("scroll", handlePageScroll);
    };
  }, []);

  window.onresize = (e) => {
    if (window.innerWidth > 991) {
      if (props.isNavbarOpen === true) {
        props.setIsNavbarOpen(false);
      }
    }
  };

  return (
    <>
      <header className="dashboard_header">
        <Navbar
          expand="lg"
          fixed="top"
          // className={`theme_navbar fixed ${isScrolling ? "active" : ""}`}
          className={`theme_navbar fixed`}
          ref={navbarRef}
        >
          <Container className="justify-content-center align-items-center px-0">
            <Row className="row-cols-2 row-cols-lg-3 align-items-center w-100">
              <Col className="logo_box order-0">
                <Link className="navbar-brand" to="/">
                  <img
                    src={`${process.env.PUBLIC_URL}/img/logo-blue.png`}
                    className="img-fluid"
                    alt="uDreamed colored logo"
                  />
                </Link>
              </Col>
              <Col className="col-12 col-lg-4 order-3 order-lg-1 mt-2 mt-lg-0">
                {/* <div className="form_style">
                  <Form>
                    <Form.Group className="form-group rounded_corners search_field">
                      <Form.Control
                        type="text"
                        placeholder="Search"
                        style={{
                          backgroundImage: `url(${process.env.PUBLIC_URL}/img/search-icon.png)`,
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center right 20px",
                        }}
                      />
                    </Form.Group>
                  </Form>
                </div> */}
              </Col>
              <Col className="order-1 order-lg-2 justify-content-end dashboard-navigation-wrap">
                <div className="dashboard_navigation d-flex justify-content-end">
                  <Button
                    type="button"
                    onClick={toggleNavbar}
                    title="Mobile Navigation"
                    className="d-flex d-lg-none nav_link btn_theme_trigger justify-content-center align-items-center"
                  >
                    {props.isNavbarOpen ? <FaTimes /> : <FaBars />}
                  </Button>
                  <Button
                    type="button"
                    title="Dark Mode Toggle"
                    onClick={handleClick}
                    className="d-flex nav_link btn_theme_trigger justify-content-center align-items-center"
                  >
                    {theme === "dark" ? <BsFillSunFill /> : <BsMoonStarsFill />}
                  </Button>

                  {/* <Link
                  to="mailto:feedback@udreamed.com"
                  className="d-flex nav_link justify-content-center align-items-center"
                >
                  <i className="fa fa-envelope" aria-hidden="true"></i>
                </Link> */}
                  {/* <Dropdown className="d-flex nav_link justify-content-center align-items-center">
                  <Dropdown.Toggle id="dropdown-basic">
                    <BsBellFill />
                  </Dropdown.Toggle>

                  <Dropdown.Menu align="end">
                    <Dropdown.Item href="#/action-2">
                      <div className="d-flex">
                        <i
                          className="fa fa-thumbs-o-up me-2"
                          aria-hidden="true"
                        ></i>
                        <div className="align-self-center">
                          <div className="font-14">Your dream shared by</div>
                          <div className="font-14 text_primary">Adam Smith</div>
                        </div>
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3">
                      <div className="d-flex">
                        <i
                          className="fa fa-thumbs-o-up me-3"
                          aria-hidden="true"
                        ></i>
                        <div className="align-self-center">
                          <div className="font-14">Your dream shared by</div>
                          <div className="font-14 text_primary">Adam Smith</div>
                        </div>
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3">
                      <div className="d-flex">
                        <i
                          className="fa fa-thumbs-o-up me-3"
                          aria-hidden="true"
                        ></i>
                        <div className="align-self-center">
                          <div className="font-14">Your dream shared by</div>
                          <div className="font-14 text_primary">Adam Smith</div>
                        </div>
                      </div>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown> */}
                  {/* <Link
                  to=""
                  className="d-flex nav_link justify-content-center align-items-center"
                >
                  <i className="fa fa-cog" aria-hidden="true"></i>
                </Link> */}
                  <Button
                    onClick={handleShow}
                    type="button"
                    title="Logout"
                    className="d-flex nav_link justify-content-center align-items-center"
                  >
                    <FaSignOutAlt />
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </Navbar>
      </header>

      <ConfirmationModal
        show={show}
        handleClose={handleClose}
        content_type="logout-modal"
        handlePrimaryAction={handleLogout}
      />
    </>
  );
}

export default DashboardHeader;
