import React from "react";
import { Card } from "react-bootstrap";
import "./CustomCard.css";

function CustomCard(props) {
  return (
    <Card className={`custom_card ${props.className}`}>{props.children}</Card>
  );
}

export default CustomCard;
