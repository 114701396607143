import React, { useState, useEffect, useMemo, useContext } from "react";
import "swiper/css/bundle";
import "swiper/bundle";
import {
  Col,
  Row,
  Card,
  Collapse,
  Button,
  Form,
  Spinner,
} from "react-bootstrap";
import CustomCard from "../../../ui/CustomCard/CustomCard";
import DashboardSectionTitle from "../DashboardSectionTitle/DashboardSectionTitle";
import ReactWordcloud from "react-wordcloud";
import PlaceholderCard from "../../../ui/PlaceholderCard/PlaceholderCard";
import DashboardWordcloudChart from "../DashboardCharts/DashboardWordcloudChart";
import FieldError from "../../../ui/FieldError/FieldError";
import { Field, Formik } from "formik";
import * as yup from "yup";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { addDays, subDays } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import { formatDateYMD, handleCustomSelectStyles } from "../../../util/util";
import {
  BsFillExclamationTriangleFill,
  BsFillPencilFill,
} from "react-icons/bs";

//Context
import ThemeContext from "../../../ThemeContext";

function PopularWordcloud(props) {
  const today = formatDateYMD(new Date());
  const { theme } = useContext(ThemeContext);
  const [datasets, setDatasets] = useState([]);
  const [recordCount, setRecordCount] = useState([]);
  const [popularWordcloudCollapse, setPopularWordcloudCollapse] =
    useState(false);

  //React select styles
  const customStyles = handleCustomSelectStyles(theme);

  // const customStyles = {
  //   control: (provided, state) => ({
  //     ...provided,
  //     border: theme === "dark" ? "1px solid #666" : "1px solid #ddd",
  //     borderRadius: "30px",
  //     minHeight: "48px",
  //     backgroundColor: theme === "dark" ? "#333" : "#fff",
  //     color: "inherit",
  //     "&:focus": {
  //       borderWidth: "1px",
  //       borderColor: "#39b3ed",
  //       boxShadow: "none",
  //     },
  //     "&:hover": {
  //       borderColor: theme === "dark" ? "#666" : "#ddd",
  //     },
  //   }),
  //   singleValue: (provided) => ({
  //     ...provided,
  //     color: "inherit",
  //   }),
  //   placeholder: (provided) => ({
  //     ...provided,
  //     color: "#ffffff",
  //   }),
  //   menuList: (provided) => ({
  //     ...provided,
  //     backgroundColor: theme === "dark" ? "#444" : "#fff",
  //     padding: 0,
  //   }),
  //   option: (provided, { isFocused, isSelected }) => ({
  //     ...provided,
  //     background: isFocused ? "#999" : isSelected ? "#0991f6" : undefined,
  //   }),
  //   valueContainer: (provided, state) => ({
  //     ...provided,
  //     padding: "2px 12px",
  //     color: "inherit",
  //     // zIndex: 1,
  //   }),
  // };

  const elementOptions = [
    { label: "Location in experience", value: "locations" },
    { label: "Characters in experience", value: "characters" },
    { label: "Interactions between characters", value: "interactions" },
    { label: "Emotions in experience", value: "emotions" },
    { label: "Causes of experience", value: "causes" },
    { label: "Resolutions", value: "resolutions" },
  ];

  function handleClick(e) {
    const element = e.target.getAttribute("aria-controls");
    let isAriaExpanded = e.target.getAttribute("aria-expanded");
    // console.log("Association Card =", e);
    handleCollapse(element);
  }
  function handleCollapse(element) {
    // console.log("In Popular wordcloud graph - Filter =", element);
    // if (element === "edit_association")
    setPopularWordcloudCollapse(!popularWordcloudCollapse);
  }

  useEffect(() => {
    // console.log(
    //   "in useEffect of PopularWordcloudData =",
    //   props.dashboardPopularWordcloudData
    // );

    // if (props.dashboardPopularWordcloudData.hasOwnProperty("stats")) {
    if (props.dashboardPopularWordcloudData?.stats?.length > 0) {
      createChartData(props.dashboardPopularWordcloudData);
    }
    // }
  }, [props.dashboardPopularWordcloudData]);

  function createChartData(
    data,
    dataLength = "full",
    chartOption = "wordcloud"
  ) {
    // console.log(
    //   "dashboardPopularWordcloudData - chart_data in createChartdata =",
    //   data
    // );
    // console.log("dataLength in createChartdata =", dataLength);
    // console.log("chartOption =", chartOption);

    let tempDataSets = [];
    if (data.stats.length > 0) {
      console.log(data.stats);
      tempDataSets = data.stats.map((item, index) => {
        return {
          text: item.element.charAt(0).toUpperCase() + item.element.slice(1),
          value: item.count,
        };
      });
    }

    setRecordCount(data.no_of_dreams);
    if (tempDataSets.length > 0) {
      setDatasets(tempDataSets);
    }

    // console.log("---- RecordCount =", recordCount);
    // console.log("---- Datasets =", datasets);
  }

  const initialValues = {
    target: "global",
    element: elementOptions[3].value,
    start_date: new Date("2021-02-28"),
    end_date: new Date(today),
  };
  const popularInsightsFilterSchema = yup.object({
    start_date: yup.date().required("Required").nullable(),
    end_date: yup.date().required("Required").nullable(),
    element: yup
      .string()
      .transform((_, input) => {
        // console.log('input = ',input);
        return input != null && input.toString ? input.toString() : "";
      })
      .required("Required"),
  });

  const onSubmit = async (values, { resetForm }) => {
    // props.setLoading(true);
    // console.log("Dashboard Popular Insights Form values: ", values);
    let newStartDate = formatDateYMD(values.start_date);
    let newEndDate = formatDateYMD(values.end_date);
    const newElement = values.element ? values.element.value : "";

    const newValues = {
      ...values,
      target: "global",
      start_date: newStartDate,
      end_date: newEndDate,
      element: newElement,
    };
    // console.log("Dashboard Popular Insights New values: ", newValues);

    props.getDashboardPopularWordcloudData(newValues);
    // setFilterData(newValues);
    handleCollapse("popular_wordcloud_collapse");
  };

  const wordCloudOptions = useMemo(() => {
    return {
      colors: [
        "#26c6da",
        "#ffb74d",
        "#a1887f",
        "#7986cb",
        "#ff8a65",
        "#ba68c8",
        "#81c784",
        "#7986cb",
        "#b39d9d",
      ],
      enableOptimizations: true,
      enableTooltip: true,
      deterministic: true,
      fontSizes: [20, 44],
      fontStyle: "normal",
      fontWeight: "normal",
      padding: 2,
      rotations: 3,
      rotationAngles: [-20, 45],
      scale: "linear", //['sqrt', 'linear', 'log']
      spiral: "rectangular", // ['archimedean', 'rectangular']
      transitionDuration: 300,
    };
  }, [datasets]);

  const wordChart = useMemo(() => {
    return (
      <>
        {/* <DashboardWordcloudChart options={wordCloudOptions} datasets={datasets} /> */}
        <div className="pt1" style={{ height: "30vh" }}>
          <ReactWordcloud options={wordCloudOptions} words={datasets} />
        </div>
      </>
    );
  }, [datasets]);

  return (
    <>
      <Row className="align-items-center position-relative mb1">
        <Col>
          <div class="section_title">Global Word Clouds</div>
          <Button
            // key="see_more_btn"
            type="button"
            className="btn_theme btn_icon btn_circle btn_light position-absolute top-0"
            onClick={handleClick}
            aria-controls="popular_wordcloud_collapse"
            aria-expanded={popularWordcloudCollapse}
            style={{ right: "0.5rem" }}
          >
            <BsFillPencilFill />
          </Button>
        </Col>
      </Row>

      <Row className="row-cols-1 align-items-center mb1">
        <Col>
          <div>
            {/* <span className="custom_tag tag_primary_light">
              Target : {props.popularWordcloudFilterData.target}
            </span> */}
            <span className="custom_tag tag_primary_light">
              Element : {props.popularWordcloudFilterData.element}
            </span>
            <span className="custom_tag tag_primary_light">
              Date :
              {props.popularWordcloudFilterData.start_date === "" ? (
                " All"
              ) : (
                <>{` ${props.popularWordcloudFilterData.start_date} - ${props.popularWordcloudFilterData.end_date}`}</>
              )}
            </span>
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <Collapse in={popularWordcloudCollapse}>
            <div id="popular_wordcloud_collapse">
              <CustomCard className="border-0 shadow1 mt0 mb2">
                <Card.Body>
                  <Formik
                    initialValues={initialValues}
                    validationSchema={popularInsightsFilterSchema}
                    onSubmit={onSubmit}
                    enableReinitialize
                  >
                    {(formik) => {
                      return (
                        <Form
                          className="form_style"
                          onSubmit={formik.handleSubmit}
                        >
                          <Row className="row-cols-1 row-cols-sm-2 g-2">
                            <Col>
                              <Form.Group className="form-group small_field rounded_corners">
                                <DashboardSectionTitle
                                  iconImg=""
                                  title="Start date"
                                  pageTitle={false}
                                  className="mb-1 small_title"
                                />
                                <Field name="start_date">
                                  {({ form, field }) => {
                                    const { setFieldValue } = form;
                                    const { value } = field;

                                    return (
                                      <DatePicker
                                        id="start_date"
                                        className="form-control"
                                        {...field}
                                        todayButton="Today"
                                        selected={value}
                                        onChange={(val) =>
                                          setFieldValue("start_date", val)
                                        }
                                        dateFormat="yyyy-MM-dd"
                                        maxDate={addDays(
                                          new Date(formik.values.end_date),
                                          0
                                        )}
                                        placeholderText="Start Date"
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        // scrollableMonthYearDropdown
                                        autoComplete="off"
                                      />
                                    );
                                  }}
                                </Field>
                                <FieldError
                                  valid={
                                    formik.touched.start_date &&
                                    formik.errors.start_date
                                      ? true
                                      : false
                                  }
                                  text={formik.errors.start_date}
                                />
                              </Form.Group>
                            </Col>

                            <Col>
                              <Form.Group className="form-group small_field rounded_corners">
                                <DashboardSectionTitle
                                  iconImg=""
                                  title="End date"
                                  pageTitle={false}
                                  className="mb-1 small_title"
                                />
                                <Field name="end_date">
                                  {({ form, field }) => {
                                    const { setFieldValue } = form;
                                    const { value } = field;

                                    return (
                                      <DatePicker
                                        id="end_date"
                                        className="form-control"
                                        {...field}
                                        selected={value}
                                        onChange={(val) =>
                                          setFieldValue("end_date", val)
                                        }
                                        dateFormat="yyyy-MM-dd"
                                        minDate={subDays(
                                          new Date(formik.values.start_date),
                                          0
                                        )}
                                        placeholderText="End date"
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        // scrollableMonthYearDropdown
                                        autoComplete="off"
                                      />
                                    );
                                  }}
                                </Field>
                                <FieldError
                                  valid={
                                    formik.touched.end_date &&
                                    formik.errors.end_date
                                      ? true
                                      : false
                                  }
                                  text={formik.errors.end_date}
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <DashboardSectionTitle
                                iconImg=""
                                title="Select element"
                                pageTitle={false}
                                className="mb-1 small_title"
                              />
                              <Form.Group className="form-group small_field rounded_corners">
                                <Select
                                  styles={customStyles}
                                  placeholder="Select element"
                                  defaultValue={elementOptions[3]}
                                  // value={formik.values.element}
                                  onChange={(value) => {
                                    formik.setFieldValue("element", value);
                                  }}
                                  onBlur={() => {
                                    formik.setFieldTouched("element", true);
                                  }}
                                  isMulti={false}
                                  options={elementOptions}
                                  name="element"
                                />
                                <FieldError
                                  valid={
                                    formik.touched.element &&
                                    formik.errors.element
                                      ? true
                                      : false
                                  }
                                  text={formik.errors.element}
                                />
                              </Form.Group>
                            </Col>
                          </Row>

                          <Row>
                            <Col>
                              <div className="btn_wrap">
                                <Button
                                  key="submit_btn"
                                  type="submit"
                                  className="btn_theme btn_primary btn_small btn_rounded"
                                >
                                  {props.loadingPopularWordcloud ? (
                                    <>
                                      <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                      />{" "}
                                      Processing
                                    </>
                                  ) : (
                                    "Submit"
                                  )}
                                </Button>
                                <Button
                                  key="popular_graph_cancel_btn"
                                  type="button"
                                  className="btn btn_theme btn_small btn_light btn_rounded"
                                  onClick={() => {
                                    handleCollapse("popular_graph_collapse");
                                  }}
                                >
                                  Cancel
                                </Button>
                              </div>
                            </Col>
                          </Row>
                        </Form>
                      );
                    }}
                  </Formik>
                </Card.Body>
              </CustomCard>
            </div>
          </Collapse>
        </Col>
      </Row>

      {props.loadingPopularWordcloud ? (
        <>
          <PlaceholderCard type="chart" />
        </>
      ) : (
        <>
          {props.dashboardPopularWordcloudData?.stats?.length > 0 ? (
            <>
              <div className="fw_sb mb1">{`# of experiences = ${recordCount}`}</div>
              {wordChart}
              {/* <div className="pt1" style={{ height: "30vh" }}>
                <ReactWordcloud options={wordCloudOptions} words={datasets} />
              </div> */}
              {/* <DashboardWordcloud
                options={wordCloudOptions}
                datasets={datasets}
                recordCount={recordCount}
              /> */}
            </>
          ) : (
            <>
              <CustomCard className="shadow1 border-0 text-center">
                <Card.Body>
                  <div className="mb1">
                    <BsFillExclamationTriangleFill
                      style={{
                        color: "#d3af61",
                        fontSize: "2.2rem",
                      }}
                    />
                  </div>
                  <b>No more records found...</b>
                </Card.Body>
              </CustomCard>
            </>
          )}
        </>
      )}
    </>
  );
}

export default PopularWordcloud;
