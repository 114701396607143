import axios from "./Axios";
const home_url = "/";

const authService = {
  login: async (data) => {
    // console.log("In Auth Data =", data);
    return await axios.post("/web/user/login", data);
  },

  loginWithSocialMedia: async (data) => {
    // console.log("In loginWithSocialMedia: Auth Data =", data);
    return await axios.post("/mobile/user/sociallogin", data);
  },

  getToken: () => {
    // const expiry = localStorage.getItem("auth_expiry");
    // console.log('get Expiry value =',expiry);
    // if((new Date()).getTime() > expiry) {
    //   console.log('in if expiry: Invalid')
    //   localStorage.clear();
    //   // return;
    // } else {
    //   console.log('in else expiry: Valid')
      return localStorage.getItem("auth_token");
    // }
  },

  setToken: (data) => {

    // console.log("Set Token: ", data);
    //Set expiry
    //Days = 30: new Date().setDate(new Date().getDate() + 30)
    const expiryInDays = new Date().setDate(new Date().getDate() + 30);
    //Hours 12: new Date().setHours(new Date().getHours() + 12)
    // const expiryInHours = new Date().setHours(new Date().getHours() + 12);
    // const expiryInMins = new Date().setMinutes(new Date().getMinutes() + 1);
    const expiry = expiryInDays;
    // console.log('expiry = ', expiry);
    // console.log('expiry date = ', new Date(expiry));
    localStorage.setItem("auth_token", data.token);
    localStorage.setItem("auth_user", JSON.stringify(data.user));
    localStorage.setItem("auth_expiry", expiry);
  },

  getAuthUser: () => {
    return localStorage.getItem("auth_user");
  },

  logout: () => {
    // console.log("Logging out");
    // localStorage.removeItem("auth_token");
    // localStorage.removeItem("auth_user");
    //Instead of calling removeItem seperately on each key just call below function to clear all local data
    localStorage.clear();
    // document.body.classList.remove("dark_theme");
  },

  register: async (data) => {
    // console.log("In Register Data =", data);
    return await axios.post("/web/user/register", data);
  },

  verifyEmail: async (data) => {
    // console.log("In Verify Email: ", data);
    return await axios.post("web/user/verifyemail", data);
  },

  resendVerificationEmail: async (data) => {
    console.log("In Resend Verification Email: ", data);
    return await axios.post("mobile/user/resendcode", data);
  },

  forgotPassword: async (data) => {
    // console.log("In Forgot Password: ", data);
    return await axios.post("web/user/forgot_password", data);
  },

  setNewPassword: async (data) => {
    // console.log("In Set New Password: ", data);
    return await axios.post("web/user/set_new_password", data);
  },
};

export default authService;
