import React from "react";

function CookieInfo() {
  return (
    <div className="page_text">
      <p>
        uDreamed offers a range of products and features that involve the use of
        cookies and other similar technologies, such as pixels or local storage,
        to reach you based on your activity on our Services. We use these
        technologies for a variety of reasons, such as allowing us to show you
        content and advertising that’s most relevant to you; improving our
        products and services; and helping to keep our Services secure. Cookies
        help us provide you with a better, faster, and secure experience.
      </p>
    </div>
  );
}

export default CookieInfo;
