import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Card, Button } from "react-bootstrap";
import CustomCard from "../../../ui/CustomCard/CustomCard";
import GradientSVG from "../../../ui/GradientSVG/GradientSVG";
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import CircularProgressProvider from "./CircularProgressProvider";
import "./DashboardProfileCard.css";
import VisibilitySensor from "react-visibility-sensor";
import CountUp from "react-countup";

//Context
import AppContext from "../../../AppContext";

//Services
import authService from "../../../services/Auth";

function DashboardProfileCard({ getContentType }) {
  const navigate = useNavigate();
  const { profileStats } = useContext(AppContext);
  const [valueEnd, setValueEnd] = useState(0);
  const totalRank = 5;
  const rank = profileStats.rank ? profileStats.rank : 0;
  const rankPercentage = profileStats.rank
    ? (profileStats.rank * 100) / totalRank
    : 0;
  const totalXP = profileStats.total_xp ? profileStats.total_xp : 0;
  const valP = 90;

  // useEffect(() => {
  //   const timer = setInterval(() => {
  //     let randVal = Math.round(Math.random() * 100);
  //     if (randVal > 20 && randVal < 50) {
  //       randVal += 45;
  //     }
  //     setValueEnd(randVal);
  //   }, 10000);

  //   return () => {
  //     clearInterval(timer);
  //   };
  // }, []);

  const authUser = JSON.parse(authService.getAuthUser());

  function handleClick(e) {
    const currentType = e.target.dataset.type;
    // console.log("Type: ", currentType);
    // console.log(e);
    // history.push(`/dashboard/${currentType}`);
    navigate(`/dashboard/${currentType}`);
    getContentType(currentType);
  }

  return (
    <CustomCard className={`profile_card main_card shadow1 border-0 mb2`}>
      <Card.Body className="d-flex justify-content-center flex-column">
        <GradientSVG
          startColor="#685aed"
          // middleColor="#7900FF"
          endColor="#55f0bb"
          idCSS={`progress-grad`}
          rotation={90}
        />
        <div className="circular-progressbar-wrap rounded-circle overflow-hidden mx-auto">
          <VisibilitySensor>
            {({ isVisible }) => {
              const percentage = isVisible ? rankPercentage : 0;
              return (
                <CircularProgressProvider valueStart={0} valueEnd={percentage}>
                  {(value) => (
                    <CircularProgressbarWithChildren
                      value={value}
                      text={`${value}%`}
                      className="sample-progress"
                      strokeWidth={4}
                      styles={buildStyles({
                        rotation: 0,
                        pathTransitionDuration: 1.5,
                        trailColor: "transparent",
                        backgroundColor: "transparent",
                      })}
                    >
                      <img
                        src={
                          (authUser.avatar !== "NULL" && authUser.avatar) ||
                          `${process.env.PUBLIC_URL}/img/user-profile.jpg`
                        }
                        className="rounded-circle"
                        alt="User profile"
                      />
                      <span className="rank-status-badge d-flex flex-column justify-content-center align-items-center p-1">
                        <span className="font-32 fw_b">{`${Math.floor(
                          (value * totalRank) / 100
                        )}`}</span>
                        <span className="text_gray3">RANK</span>
                      </span>
                    </CircularProgressbarWithChildren>
                  )}
                </CircularProgressProvider>
              );
            }}
          </VisibilitySensor>
        </div>

        {/* <div
          className={`profile_img_container rounded-circle overflow-hidden mx-auto`}
        > */}
        {/* <img
            src={`${process.env.PUBLIC_URL}/img/team/Sandeep-Pandit.png`}
            className=""
            alt="Profile Image"
          /> */}
        {/* <img src={authUser.avatar} className="" alt="User profile" /> */}
        {/* </div> */}
        <div className="text-center mt1 mb-3">
          <div className="fw_b font-16">{`${authUser.firstname} ${authUser.lastname}`}</div>
          {/* <span className="text_gray6 font-13">
            {authUser.email}
          </span> */}
        </div>

        <Row className="profile-stats-wrap row-cols-2 mb-3">
          <Col className="d-flex flex-column align-items-center border-end">
            <div className="icon-wrap d-flex justify-content-center align-items-center rounded-circle">
              <img
                src={`${process.env.PUBLIC_URL}/img/achievement-icon.png`}
                className="img-fluid mx-auto"
                width="24"
                alt=""
              />
            </div>

            {totalXP ? (
              <>
                {/* <VisibilitySensor>
                  {({ isVisible }) => {
                    const xp = isVisible ? totalXP : 0;
                    return ( */}
                <CountUp
                  className="stats-value font-18 fw_sb text-center"
                  delay={1}
                  end={totalXP}
                  useEasing={true}
                  duration={5}
                  suffix=" XP"
                />
                {/*);
                 }}
                 </VisibilitySensor> */}
              </>
            ) : (
              <span className="stats-value font-18 fw_sb text-center">0</span>
            )}
            {/* {`${totalXP} XP`} */}
          </Col>
          <Col className="d-flex flex-column align-items-center">
            <div className="icon-wrap d-flex justify-content-center align-items-center rounded-circle">
              <img
                src={`${process.env.PUBLIC_URL}/img/crown-icon.png`}
                className="img-fluid mx-auto"
                width="28"
                alt=""
              />
            </div>
            {rank ? (
              <>
                {/* <VisibilitySensor>
                  {({ isVisible }) => {
                    const rankVal = isVisible ? rank : 0;
                    return ( */}
                <CountUp
                  className="stats-value font-18 fw_sb text-center"
                  delay={1}
                  end={rank}
                  useEasing={true}
                  duration={5}
                  suffix=""
                />
                {/* );
                   }}
                </VisibilitySensor> */}
              </>
            ) : (
              <span className="stats-value font-18 fw_sb text-center">0</span>
            )}
            {/* <span className="stats-value font-18 fw_sb text-center">
              {rank}
            </span> */}
          </Col>
        </Row>

        <Button
          type="button"
          data-type="experience-bank"
          onClick={(e) => {
            handleClick(e);
          }}
          className="btn_theme btn_secondary"
        >
          Browse Experiences
        </Button>
      </Card.Body>
    </CustomCard>
  );
}

export default DashboardProfileCard;
