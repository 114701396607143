import React from "react";
import MessageCard from "./MessageCard";
import SimpleMessageCard from "../../../ui/SimpleMessageCard/SimpleMessageCard";

function MessagesArchieved(props) {
  const actions = ["reply", "forward", "trash"];

  function handleForward(e) {
    let actionBody = {
      action: "message_compose",
      data: {
        message: e.message,
      },
    };

    props.handleAction(actionBody);
  }

  function handleReply(e) {
    console.log("In handleReply =", e);
    let actionBody = {
      action: "message_compose",
      data: {
        users: [
          {
            label: `${e.sender_user.firstname} ${e.sender_user.lastname}`,
            value: e.sender,
          },
        ],
        message: "",
      },
    };

    props.handleAction(actionBody);
  }

  function handleTrash(e) {
    console.log("In handleTrash function ", e);

    const msg_id = e.message_id;
    if (msg_id) {
      props.markMessageTrashed(msg_id);
    }
  }

  return (
    <div class="mt2 mb2">
      {props.archiveMessages.length > 0 && (
        <>
          {props.archiveMessages.map((msg, index) => (
            <MessageCard
              key={index}
              actions={actions}
              handleForward={handleForward}
              handleReply={handleReply}
              handleTrash={handleTrash}
              msg={msg}
              type="archive"
            />
          ))}
        </>
      )}
      {!props.archiveMessages.length > 0 && (
        <SimpleMessageCard type="warning" allowIcon={true} />
      )}
    </div>
  );
}

export default MessagesArchieved;
