import React, { useState } from "react";
import { Card, Button, Collapse } from "react-bootstrap";
import CustomCard from "../../../ui/CustomCard/CustomCard";
import {
  BsFillStarFill,
  BsFillPinMapFill,
  BsGeoAltFill,
  BsAwardFill,
  BsCheck,
  BsCheck2,
  BsCheckAll,
  BsCheck2All,
  BsCheckCircleFill,
  BsAward,
  BsCaretRight,
  BsCaretRightFill,
  BsChevronDoubleRight,
  BsChevronDown,
  BsChevronUp,
} from "react-icons/bs";
import "./DashboardAnalyzeCard.css";

function DashboardAnalyzeCard({ exp }) {
  const [detailsCollapse, setDetailsCollapse] = useState(false);

  function handleClick(e) {
    const element = e.target.getAttribute("aria-controls");
    let isAriaExpanded = e.target.getAttribute("aria-expanded");
    // console.log("Association Card =", e);
    handleCollapse(element);
  }
  function handleCollapse(element) {
    console.log("In Analyze search Comp =", element);
    setDetailsCollapse(!detailsCollapse);
  }

  // console.log("exp in card =", exp);
  let exp_type_class = "";
  let type_of_recorded_exp = "";
  let icon_url = "dreams-icon.png";
  let switchVal = exp?.record_type ? exp?.record_type : "dream";
  switch (switchVal) {
    case "dream":
      exp_type_class = "dreams";
      type_of_recorded_exp = "Dream";
      icon_url = "dreams-icon.png";
      break;
    case "event":
      exp_type_class = "events";
      type_of_recorded_exp = "Event";
      icon_url = "events-icon.png";
      break;
    case "thought":
      exp_type_class = "thoughts";
      type_of_recorded_exp = "Thought";
      icon_url = "thoughts-icon.png";
      break;
    case "outofbody":
      exp_type_class = "outofbody";
      type_of_recorded_exp = "Out of Body";
      icon_url = "experience-icon.png";
      break;
  }

  return (
    <CustomCard
      className={`analyze_card ${exp_type_class} shadow1 border-0 mb2`}
    >
      <Card.Header className="analyze_card_header">
        <div className="media">
          <div className="media_icon_wrap">
            {/* <img
              src={`${process.env.PUBLIC_URL}/img/profile1.png`}
              className="media_icon"
              alt="user profile"
            /> */}
            <img
              src={`${process.env.PUBLIC_URL}/img/${icon_url}`}
              className={`media_icon`}
              alt={`${type_of_recorded_exp} icon`}
            />
          </div>
          <div className="media_body ms-3">
            <div className="card_title">{`${type_of_recorded_exp} recorded`}</div>
            <div className="card_subtitle">
              {exp?.user} on {exp.record_date}
            </div>
          </div>
        </div>
      </Card.Header>
      <Card.Body className="analyze_card_body">
        {exp.title !== "" && (
          <div className={`exp_title mb-1 position-relative`}>
            <Button
              type="button"
              className="btn_theme btn_white w-100"
              onClick={handleClick}
              aria-controls="analyze_details"
              aria-expanded={detailsCollapse}
              style={{ background: "transparent" }}
            >
              {detailsCollapse ? (
                <>
                  <BsChevronUp className="position-absolute" /> {exp?.title}
                </>
              ) : (
                <>
                  <BsChevronDown className="position-absolute" /> {exp?.title}
                </>
              )}
            </Button>
            {/* | {exp.record_id}{" "} */}
          </div>
        )}

        {exp.description !== "" && (
          <>
            <div
              className={`more-description ${detailsCollapse ? "p-3" : "px-3"}`}
            >
              <Collapse in={detailsCollapse}>
                <div className="">{exp?.description}</div>
              </Collapse>
            </div>
          </>
        )}
      </Card.Body>
    </CustomCard>
  );
}

export default DashboardAnalyzeCard;
