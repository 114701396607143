import React, { useState, useEffect, useContext } from "react";
import { Card } from "react-bootstrap";
import CustomCard from "../../../ui/CustomCard/CustomCard";
import DashboardExperienceCard from "../DashboardExperienceCard/DashboardExperienceCard";
import DashboardSectionTitle from "../DashboardSectionTitle/DashboardSectionTitle";
import PlaceholderCard from "../../../ui/PlaceholderCard/PlaceholderCard";
import SimpleMessageCard from "../../../ui/SimpleMessageCard/SimpleMessageCard";
import { BsFillExclamationTriangleFill } from "react-icons/bs";

//Context
import AppContext from "../../../AppContext";
//Service
import recordService from "../../../services/Record";

function DashboardExperienceBankContent() {
  // console.log("in dashboard expereince bank content props = ", props);
  const [loading, setLoading] = useState(false);
  const [expbankData, setExpbankData] = useState([]);
  const { experiences, setExperiences } = useContext(AppContext);

  let mainType = "exp_bank";
  let actions = [
    "view_details",
    "decode",
    "share",
    "edit",
    "add_association",
    "match",
    "delete",
  ];

  // useEffect(() => {
  //   // Get all recorded experience list from DB
  //   function getData() {
  //     recordService
  //       .getAllRecords()
  //       .then((response) => {
  //         if (response.data.status === "success") {
  //           // console.log("Response from APP : ", response.data);
  //           const records = response.data.data.result.records;
  //           setExperiences(records);

  //           let updatedRecords;
  //           updatedRecords = records.map((record) => {
  //             return { ...record, mainType: mainType, actions: actions };
  //           });
  //           // console.log("Experiencebank data =", experiences);
  //           setExpbankData(updatedRecords);
  //         }
  //         if (response.data.status === "failed") {
  //           console.log("ERROR:", response.data.error);
  //         }
  //       })
  //       .catch((err) => {
  //         console.log("There is a problem in creating new record...", err);
  //       });
  //   }
  //   getData();
  //   console.log("I am executed");
  // }, []);

  useEffect(() => {
    async function getData() {
      if (expbankData.length == 0) {
        setLoading(true);
      }
      // setLoading(true);

      let updatedRecords;
      updatedRecords = await experiences.map((record) => {
        return { ...record, mainType: mainType, actions: actions };
      });
      console.log("Experiencebank data =", experiences);
      setExpbankData(updatedRecords);
      // setLoading(false);
      setTimeout(() => {
        setLoading(false);
      }, 200);
    }

    getData();
  }, [experiences]);

  return (
    <>
      <DashboardSectionTitle
        iconImg="offer-icon11.png"
        title="Experience Bank"
        pageTitle={true}
        className="mb2"
        withInfo={true}
        infoData="Review, edit and decode all your recorded experiences."
      />
      {!loading && expbankData.length > 0 ? (
        expbankData.map((exp) => (
          <DashboardExperienceCard key={exp.record_id} exp={exp} />
        ))
      ) : (
        <>
          {loading ? (
            <>
              {[0, 1].map((_, i) => (
                <PlaceholderCard key={i} />
              ))}
            </>
          ) : (
            <SimpleMessageCard
              type="warning"
              msg="No more records found..."
              allowIcon={true}
            />
            // <CustomCard className="shadow1 border-0 text-center">
            //   <Card.Body>
            //     <div className="mb1">
            //       <BsFillExclamationTriangleFill
            //         style={{
            //           color: "#d3af61",
            //           fontSize: "2.2rem",
            //         }}
            //       />
            //     </div>
            //     <b>No more records found...</b>
            //   </Card.Body>
            // </CustomCard>
          )}
        </>
      )}
    </>
  );
}

export default DashboardExperienceBankContent;
