import React, { useState, useEffect } from "react";
import { Modal, Form, InputGroup, Button, Spinner } from "react-bootstrap";
import DashboardSectionTitle from "../../components/Dashboard/DashboardSectionTitle/DashboardSectionTitle";
import { handleAlert } from "../../util/util";
import { BsCurrencyDollar } from "react-icons/bs";

function ProcessIframe(props) {
  return (
    <div
      dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : "" }}
    ></div>
  );
}

function PaymentProcessModal(props) {
  const [newIframe, setNewIframe] = useState("");
  console.log("props - URL = ", props.redirectionURL);
  useEffect(() => {
    let tempCode = "";
    if (props.redirectionURL !== "") {
      tempCode = `<iframe src='${props.redirectionURL}' width="100%" height="300"></iframe>`;
    } else {
      tempCode = "No data found";
    }
    console.log("tempCode = ", tempCode);
    setNewIframe(tempCode);
  }, [props.redirectionURL]);

  return (
    <>
      <Modal
        centered
        size="lg"
        className="theme-modal consult-payment-modal"
        show={props.show.isVisible}
        onHide={props.handleClose}
      >
        <Modal.Header className="pb-2" closeButton>
          <Modal.Title className="d-flex align-items-center">
            <div className="heading-icon-wrap flex-shrink-0">
              <span className="heading-icon d-flex justify-content-center align-items-center rounded-circle font-24">
                <BsCurrencyDollar />
              </span>
            </div>
            <div className="heading flex-grow-1 ms-3">Payment</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-helper-text mb1">Payment Process</div>
          {props.redirectionURL && <ProcessIframe iframe={newIframe} />}
          {/* {props.redirectionURL && (
            <iframe
              src={props.redirectionURL}
              title="Payment Processing"
              width="100%"
              height="300"
              frameBorder="no"
              allowTransparency="true"
              allowFullScreen="true"
            ></iframe>
          )} */}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default PaymentProcessModal;
