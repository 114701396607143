import React, { useState, useEffect, useContext } from "react";
import { Col, Row, Form, Button, Spinner } from "react-bootstrap";
import DashboardSectionTitle from "../DashboardSectionTitle/DashboardSectionTitle";
import { Field, Formik } from "formik";
import * as yup from "yup";
import Select from "react-select";
import { handleAlert, handleThemeChange, handleCustomSelectStyles } from "../../../util/util";

//Context
import AppContext from "../../../AppContext";
import ThemeContext from "../../../ThemeContext";

//Service
import recordService from "../../../services/Record";

function AddGroupUser(props) {
  const { friends } = useContext(AppContext);
  const { theme } = useContext(ThemeContext);
  const [loading, setLoading] = useState(false);

  //React select styles
  const customStyles = handleCustomSelectStyles(theme);

  // const customStyles = {
  //   control: (provided, state) => ({
  //     ...provided,
  //     border: theme === "dark" ? "1px solid #666" : "1px solid #ddd",
  //     borderRadius: "30px",
  //     minHeight: "48px",
  //     backgroundColor: theme === "dark" ? "#333" : "#fff",
  //     color: "inherit",
  //     "&:focus": {
  //       borderWidth: "1px",
  //       borderColor: "#39b3ed",
  //       boxShadow: "none",
  //     },
  //     "&:hover": {
  //       borderColor: theme === "dark" ? "#666" : "#ddd",
  //     },
  //   }),
  //   singleValue: (provided) => ({
  //     ...provided,
  //     color: "inherit",
  //   }),
  //   placeholder: (provided) => ({
  //     ...provided,
  //     color: "#ffffff",
  //   }),
  //   menuList: (provided) => ({
  //     ...provided,
  //     backgroundColor: theme === "dark" ? "#444" : "#fff",
  //     padding: 0,
  //   }),
  //   option: (provided, { isFocused, isSelected }) => ({
  //     ...provided,
  //     background: isFocused ? "#999" : isSelected ? "#0991f6" : undefined,
  //   }),
  //   valueContainer: (provided, state) => ({
  //     ...provided,
  //     padding: "2px 12px",
  //     color: "inherit",
  //     zIndex: 1,
  //   }),
  // };

  const initialValues = {
    user: "",
  };

  // console.log("props.addMembers in add group member: ", props.addMembers);

  const userOptions = props.addMembers?.map((member) => {
    return { label: member.name, value: member.id };
  });
  console.log("Users in add group member: ", userOptions);

  const addGroupUserSchema = yup.object({
    user: yup
      .string()
      .transform((_, input) => {
        console.log("input = ", input);
        return input != null && input.toString ? input.toString() : "";
      })
      .required("Required"),
    // users: yup
    //   .array()
    //   .min(1, "Atleast 1 member required")
    //   .of(
    //     yup.object().shape({
    //       label: yup.string().required(),
    //       value: yup.string().required(),
    //     })
    //   ),
  });

  const onSubmit = async (values, { resetForm }) => {
    console.log("add Group member Form submitted...");
    console.log("add Group member Form Values :", values);

    const newUser = values.user.value;
    console.log("New Member=", newUser, "\n group_id=", props.group_id);
    const newValues = {
      ...values,
      user_id: newUser,
      group_id: props.group_id,
    };
    console.log("New values in add Group member =", newValues);
    // resetForm({});
    // setLoading(true);

    recordService
      .addGroupUser(newValues)
      .then((response) => {
        if (response.data.status === "success") {
          console.log("add Group member response =", response.data);
          handleAlert("You have successfully added a member!", "success");

          //Get all groups
          props.getAllGroups();
          setLoading(false);
          resetForm({});
          props.handleCollapse("add_group_user");
        } else if (response.data.status === "failed") {
          console.log("ERROR:", response.data.error);
          handleAlert("Add group member failed!", "error");
          setLoading(false);
          resetForm({});
          // props.handleCollapse("add_association");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div className="form_style mt2 mb2">
        <Formik
          initialValues={initialValues}
          validationSchema={addGroupUserSchema}
          onSubmit={onSubmit}
        >
          {(formik) => {
            // console.log(formik);
            return (
              <Form className="form_style" onSubmit={formik.handleSubmit}>
                <Row className="g-0">
                  <Col xs={12}>
                    <DashboardSectionTitle
                      iconImg=""
                      title="Add Members"
                      pageTitle={false}
                      className="mb2 small_title"
                    />
                    <Form.Group className="form-group rounded_corners px-1">
                      <Select
                        styles={customStyles}
                        placeholder="Select member"
                        value={formik.values.user}
                        onChange={(value) => {
                          formik.setFieldValue("user", value);
                        }}
                        onBlur={() => {
                          formik.setFieldTouched("user", true);
                        }}
                        isMulti={false}
                        options={userOptions}
                        name="user"
                      />

                      {formik.touched.user && formik.errors.user ? (
                        <p className="error-state-text">{formik.errors.user}</p>
                      ) : null}
                    </Form.Group>
                  </Col>
                  <Col xs={12}>
                    <div className="btn_wrap">
                      <Button
                        type="submit"
                        className="btn btn_theme btn_primary btn_rounded"
                      >
                        {loading ? (
                          <>
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />{" "}
                            Adding
                          </>
                        ) : (
                          "Submit"
                        )}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </div>
    </>
  );
}

export default AddGroupUser;
