import React, { useState, useEffect } from "react";
import { Card, Placeholder } from "react-bootstrap";
import CustomCard from "../../../ui/CustomCard/CustomCard";
import DashboardSectionTitle from "../DashboardSectionTitle/DashboardSectionTitle";
import ConsultingSessionCard from "./ConsultingSessionCard";
import { BsFillExclamationTriangleFill } from "react-icons/bs";

//Service
import recordService from "../../../services/Record";
import authService from "../../../services/Auth";
import PlaceholderCard from "../../../ui/PlaceholderCard/PlaceholderCard";
import SimpleMessageCard from "../../../ui/SimpleMessageCard/SimpleMessageCard";

function DashboardConsultingSessionsContent() {
  const [loading, setLoading] = useState(false);
  const [consultingSessions, setConsultingSessions] = useState([]);
  const actions = ["view_session_comments", "view_session_transactions"];
  const authUser = JSON.parse(authService.getAuthUser());

  //Fetch all consult invitations from DB
  function getAllConsultingSessions() {
    setLoading(true);
    console.log(
      "inside getAllConsultingSessions() in DashboardConsultingSessionsContent.js"
    );
    recordService
      .getAllConsultingSessions()
      .then((response) => {
        console.log("Response from consult sessions : ", response.data);
        const sessions = response.data.data.result.sessions.map((item) => {
          return { ...item, actions: actions };
        });
        setLoading(false);
        setConsultingSessions(sessions);
      })
      .catch((err) => {
        console.log(
          "There is a problem in getting all consult sessions...",
          err
        );
        setLoading(false);
      });
  }

  useEffect(() => {
    getAllConsultingSessions();
  }, []);

  return (
    <>
      <DashboardSectionTitle
        iconImg="offer-icon16.png"
        title="Consulting Sessions"
        pageTitle={true}
        className="mb2"
      />

      {!loading && consultingSessions?.length > 0 ? (
        consultingSessions.map((session) => (
          <ConsultingSessionCard
            key={session.consult_id}
            session={session}
            authUser={authUser}
            getAllConsultingSessions={getAllConsultingSessions}
          />
        ))
      ) : (
        <>
          {loading ? (
            <>
              {[0, 1].map((_, i) => (
                <PlaceholderCard key={i} />
              ))}
            </>
          ) : (
            <SimpleMessageCard type="warning" />
          )}
        </>
      )}
    </>
  );
}

export default DashboardConsultingSessionsContent;
