import React, { useState } from "react";
import { Card, Button } from "react-bootstrap";
import CustomCard from "../../../ui/CustomCard/CustomCard";
import "./DashboardConsultantCard.css";
import { handleAlert } from "../../../util/util";
import {
  BsFillStarFill,
  BsFillPinMapFill,
  BsGeoAltFill,
  BsAwardFill,
  BsCheck,
  BsCheck2,
  BsCheckAll,
  BsCheck2All,
  BsCheckCircleFill,
  BsAward,
  BsCaretRight,
  BsCaretRightFill,
  BsChevronDoubleRight,
  BsChevronDown,
  BsChevronUp,
} from "react-icons/bs";

//Service
import authService from "../../../services/Auth";
import recordService from "../../../services/Record";
import CustomRating from "../../../ui/CustomRating/CustomRating";

function DashboardConsultantCard({ consultant }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [hasInvited, setHasInvited] = useState(consultant.has_invited);

  const authUser = JSON.parse(authService.getAuthUser());
  const isCurrentUser = authUser?.user_id === consultant.user_id ? true : false;

  console.log("Consultant =", consultant);

  const profileImg = consultant.avatar !== "NULL"
    ? consultant.avatar
    : `${process.env.PUBLIC_URL}/img/user-profile.jpg`;
  const consultantName =
    consultant.firstname || consultant.lastname
      ? `${consultant.firstname} ${consultant.lastname}`
      : "Sample Consultant";
  const education = consultant.education ? consultant.education : "";
  const country = consultant.country ? consultant.country : "";
  const state = consultant.address.state ? consultant.address.state : "";
  const city = consultant.address.city ? consultant.address.city : "";
  const specialty = consultant.specialty;
  const languages = consultant.language_spoken
    ? consultant.language_spoken
    : "";
  const gender = consultant.gender ? consultant.gender : "male";
  const contactNo = consultant.phone_no ? consultant.phone_no : "";
  const license = consultant.license_number ? consultant.license_number : "";
  const licenseYear = consultant.license_year ? consultant.license_year : 0;
  const currYear = new Date().getFullYear();
  const tenure =
    licenseYear > 0 ? <>{`More than ${currYear - licenseYear} years`}</> : "NA";

  const costPerSession = consultant.average_cost_per_session
    ? consultant.average_cost_per_session
    : 0;

  const expertise = consultant.expertise ? consultant.expertise : "";

  // Date formatting
  function formattedDate(d) {
    return [d.getFullYear(), d.getMonth() + 1, d.getDate()]
      .map((n) => (n < 10 ? `0${n}` : `${n}`))
      .join("-");
  }

  //Send friend request on connect button click
  const handleConnect = (id) => {
    console.log("Connect Id =", id);

    if (id) {
      let requestData = {
        consultant_id: id,
      };
      recordService
        .sendConsultInvite(requestData)
        .then((response) => {
          if (response.data.status === "success") {
            // console.log("If status-success =", response.data);
            // console.log("msg =", response.data.message);
            // handleAlert(response.data.message, "success");
            handleAlert("Request sent to therapist", "success");
            setHasInvited(true);
          }
          if (response.data.status === "failed") {
            // console.log("If status-failed =", response.data);
            // console.log("msg =", response.data.error);
            // handleAlert(response.data.error, "error");
            handleAlert("Therapist request already sent", "error");
            setHasInvited(consultant.has_invited);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <CustomCard className={`main_card consultant_card shadow1 border-0 mb2`}>
      <div className="consult_icon">
        <img
          src={`${process.env.PUBLIC_URL}/img/icons/offer-icon6.png`}
          className="img-fluid"
          alt="Consult invite Icon"
        />
      </div>
      <Card.Header className="consultant_card_header">
        <div className="media">
          <div className="media_img_wrap">
            <img
              src={profileImg}
              className="rounded-circle profile_img"
              alt={consultantName}
            />
          </div>
          <div className={`media_body ms-3`}>
            <div
              className={`card_title d-flex align-items-center flex-column flex-sm-row`}
            >
              <span className="consultant_name me-3">{consultantName}</span>
            </div>
            <div className="card_subtitle d-flex align-items-center">
              <BsAwardFill /> <span className="ms-1">{education}</span>
              {/* Lives in {country} |{" "} */}
              {/* <CustomRating
                initialValue={consultant.rating}
                emptyColor="#666666"
                showTooltip={true}
                showTooltipOnHover={true}
                tooltipSize="small"
                readonly={true}
                className="ms-1"
                size={16}
              /> */}
            </div>
          </div>
        </div>
      </Card.Header>

      <Card.Body className="border-0">
        <div className="d-flex align-items-center ps-1 mb-3">
          <span
            className={`custom_tag tag_small ${
              consultant.rating >= 4
                ? "tag_success"
                : `${consultant.rating >= 2 ? "tag_warning" : "tag_danger"}`
            }  me-3 mb-0`}
            style={{ padding: "4px 13px", fontSize: "14px" }}
          >
            <BsFillStarFill className="me-1" style={{ marginTop: "-2px" }} />{" "}
            <span>{consultant.rating}</span>
          </span>
          <span className="">
            <BsFillPinMapFill className="me-1" /> Lives in {country}
          </span>
        </div>
        <div className="specialty-wrap pb-2">
          {specialty.length > 0 && (
            <>
              {specialty.map((item, index) => {
                return <span className="custom_tag tag_small ">{item}</span>;
              })}
            </>
          )}
        </div>

        <div>
          <ul className={`list-group ${isExpanded && "expand"}`}>
            {/* <li className="list-group-item">
              <BsCheck2All />
              <strong className="pe-1">Speciality: </strong>
              {specialty.length > 0 && (
                <>
                  {specialty.map((item, index) => {
                    let retVal = `${item}${
                      index !== specialty.length - 1 ? ", " : ""
                    }`;
                    return retVal;
                  })}
                </>
              )}
            </li> */}
            <li className="list-group-item">
              <BsCheck2All />
              <strong className="pe-1">Language: </strong>
              {languages}
            </li>
            <li className="list-group-item">
              <BsCheck2All />
              <strong className="pe-1">State: </strong>
              {state}
            </li>
            <li className="list-group-item">
              <BsCheck2All />
              <strong className="pe-1">City: </strong>
              {city}
            </li>
            <li className="list-group-item">
              <BsCheck2All />
              <strong className="pe-1">Gender: </strong>
              <span class="text-capitalize">{gender}</span>
            </li>
            <li className="list-group-item">
              <BsCheck2All />
              <strong className="pe-1">Contact No.: </strong>
              {contactNo}
            </li>
            {consultant.is_licensed && (
              <>
                <li className="list-group-item">
                  <BsCheck2All />
                  <strong className="pe-1">
                    Education &amp; Board License:{" "}
                  </strong>
                  {license}
                </li>
              </>
            )}

            <li className="list-group-item">
              <BsCheck2All />
              <strong className="pe-1">Tenure: </strong>
              {tenure}
            </li>

            {expertise && (
              <li className="list-group-item flex-column">
                <BsCheck2All />
                <strong className="pe-1">Expertise & Services: </strong>
                {expertise}
              </li>
            )}
          </ul>
          <Button
            onClick={(e) => setIsExpanded(!isExpanded)}
            type="button"
            className="btn btn_theme btn_small btn_link btn_expand fw_b"
            style={{ fontWeight: 600 }}
          >
            {isExpanded ? (
              <>
                Show Less <BsChevronUp />
              </>
            ) : (
              <>
                Show More <BsChevronDown />
              </>
            )}
          </Button>

          <div className="btn_wrap d-flex align-items-center">
            <span className="cost-wrap fw_b font-16 text_gray3 me-3 d-flex flex-column align-items-center">
              <span style={{ lineHeight: 1 }}>${costPerSession}</span>
              <small className="ms-1 font-12 text_gray6">per session</small>
            </span>
            {/* <Button
              onClick={(e) => setIsExpanded(!isExpanded)}
              type="button"
              className="btn btn_theme btn_small btn_secondary btn_rounded btn_expand"
            >
              {isExpanded ? "Show Less" : "Show More"}
            </Button> */}
            {!isCurrentUser && (
              <Button
                type="button"
                className="btn btn_theme btn_primary btn_rounded"
                onClick={() => {
                  handleConnect(consultant.user_id);
                }}
                // disabled={hasInvited || consultant.is_friend}
                disabled={hasInvited}
              >
                {consultant.is_friend ? (
                  <>{hasInvited ? "Requested" : "Start new session"}</>
                ) : (
                  <>{hasInvited ? "Requested" : "Connect"}</>
                )}
              </Button>
            )}
          </div>
        </div>
      </Card.Body>
    </CustomCard>
  );
}

export default DashboardConsultantCard;
