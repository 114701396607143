import React from "react";
import { Card, Placeholder } from "react-bootstrap";
import CustomCard from "../CustomCard/CustomCard";
import "./PlaceholderCard.css";

function PlaceholderCard(props) {
  let card = null;
  switch (props.type) {
    case "chart":
      card = (
        <CustomCard
          className={`main_card placeholder_card shadow1 border-0 mb2 text-left overflow-hidden`}
        >
          <Card.Header className="pb-0">
            <div className="d-flex align-items-center justify-content-between">
              <div className="flex-grow-1 ms-0 text-start">
                <div className="w-100 mb-1">
                  <Placeholder xs={5} bg="secondary" />
                </div>
                <div className="w-100">
                  <Placeholder.Button
                    aria-hidden="true"
                    style={{
                      borderRadius: "20px",
                      width: "100px",
                      height: "20px",
                    }}
                    className="btn_theme btn_small btn_gray btn_rounded"
                  />
                  {/* <Placeholder xs={2} bg="secondary" /> */}
                </div>
              </div>
            </div>
          </Card.Header>
          <Card.Body className="p2">
            <div className="w-100 mb-3 text-center">
              <Placeholder
                style={{ height: "30px", borderRadius: "5px" }}
                xs={4}
                bg="secondary"
              />
            </div>
            <div className="w-100 mb-2">
              <Placeholder as="div" animation="glow">
                <Placeholder
                  xs={12}
                  style={{ height: "200px" }}
                  bg="secondary"
                  as="div"
                />
              </Placeholder>
            </div>
          </Card.Body>
        </CustomCard>
      );
      break;
    case "video":           
      card = (
        <CustomCard
          className={`main_card placeholder_card shadow1 border-0 mb2 text-left overflow-hidden`}
        >
          <Card.Header className="p-0">
            <div className="w-100 mb-2">
              <Placeholder as="div" animation="glow">
                <Placeholder
                  xs={12}
                  style={{ height: "200px" }}
                  bg="secondary"
                  as="div"
                />
              </Placeholder>
            </div>
            
          </Card.Header>
          <Card.Body className="p2">
            <div className="d-flex align-items-center">
                <div className="flex-shrink-0">
                  <Placeholder as="div" animation="glow">
                    <Placeholder
                      bg="secondary"
                      as="div"
                      style={{ height: "36px", width: "36px" }}
                      className="rounded-circle"
                    />
                  </Placeholder>
                </div>
                <div className="flex-grow-1 ms-3 text-left">
                  <div className="w-100">
                    <Placeholder xs={10} bg="secondary" />
                  </div>
                </div>
              </div>
            
          </Card.Body>
        </CustomCard>
      );              
      break;  
    case "comment":
      card = (
        <CustomCard
          className={`main_card placeholder_card border-1 mb2 text-left overflow-hidden`}
        >
          <Card.Body className="p2">
            <div className="w-100 mb-1">
              <Placeholder xs={9} bg="secondary" />
            </div>
          </Card.Body>
          <Card.Header className="bg_transparent border-0 pt-2">
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0">
                <Placeholder as="div" animation="glow">
                  <Placeholder
                    bg="secondary"
                    as="div"
                    style={{ height: "36px", width: "36px" }}
                    className="rounded-circle"
                  />
                </Placeholder>
              </div>
              <div className="flex-grow-1 ms-3 text-left">
                <div className="w-100">
                  <Placeholder xs={6} bg="secondary" />
                </div>
              </div>
            </div>
          </Card.Header>
        </CustomCard>
      );
      break;
    case "thread":
      card = (
        <CustomCard
          className={`main_card placeholder_card shadow1 border-0 mb2 text-left overflow-hidden`}
        >
          <Card.Body className="p2">
            <div className="w-100 mb-3">
              <Placeholder xs={9} bg="secondary" />
            </div>
            <div className="w-100 mb-1">
              <Placeholder xs={9} bg="secondary" />
            </div>
          </Card.Body>
          <Card.Header className="bg_transparent border-0 pt-2">
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0">
                <Placeholder as="div" animation="glow">
                  <Placeholder
                    bg="secondary"
                    as="div"
                    style={{ height: "45px", width: "45px" }}
                    className="rounded-circle"
                  />
                </Placeholder>
              </div>
              <div className="flex-grow-1 ms-3 text-left">
                <div className="w-100">
                  <Placeholder xs={6} bg="secondary" />
                </div>
              </div>
            </div>
          </Card.Header>
        </CustomCard>
      );
      break;
    case "friends":
      card = (
        <CustomCard
          className={`main_card placeholder_card placeholder_friend_card shadow1 border-0 text-center overflow-hidden`}
        >
          <Card.Body className="d-flex justify-content-center flex-column">
            <div className="text-center">
              <Placeholder as="div" animation="glow">
                <Placeholder
                  bg="secondary"
                  xs={12}
                  as="div"
                  className="placeholder_profile_img rounded-circle"
                />
              </Placeholder>
              <Placeholder as="card-text" animation="glow">
                <Placeholder xs={10} bg="secondary" />
              </Placeholder>
              <Placeholder as="card-text" animation="glow">
                <Placeholder xs={10} bg="secondary" />
              </Placeholder>
            </div>
          </Card.Body>
        </CustomCard>
      );
      break;
    case "consult":
      card = (
        <CustomCard
          className={`main_card placeholder_card shadow1 border-0 mb2 text-left overflow-hidden`}
        >
          <Card.Header>
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0">
                <Placeholder as="div" animation="glow">
                  <Placeholder
                    bg="secondary"
                    as="div"
                    className="placeholder_profile_img rounded-circle"
                  />
                </Placeholder>
              </div>
              <div className="flex-grow-1 ms-3 text-left">
                <div className="w-100">
                  <Placeholder xs={6} bg="secondary" />
                </div>
                <div className="w-100">
                  <Placeholder xs={4} bg="secondary" />
                </div>
              </div>
            </div>
          </Card.Header>
          <Card.Body className="p2">
            <div className="w-100 mb-1">
              <Placeholder xs={9} bg="secondary" />
            </div>
            <div className="w-100 mb1">
              <Placeholder xs={6} bg="secondary" />
            </div>
            <div className="w-100 mb1">
              <Placeholder xs={6} bg="secondary" />
            </div>
            <div className="w-100 mb1">
              <Placeholder xs={6} bg="secondary" />
            </div>
          </Card.Body>
        </CustomCard>
      );
      break;
    case "dictionary":
      card = (
        <CustomCard
          className={`main_card placeholder_card shadow1 border-0 mb2 text-left overflow-hidden`}
        >
          <Card.Header>
            <div className="d-flex align-items-center justify-content-between">
              <div className="flex-grow-1 ms-0 text-start">
                <div className="w-100">
                  <Placeholder xs={7} bg="secondary" />
                </div>
              </div>
              <div className="flex-grow-1 ms-0 text-end">
                <div className="w-100">
                  <Placeholder xs={5} bg="secondary" />
                </div>
              </div>
            </div>
          </Card.Header>
          <Card.Body className="p2">
            <div className="w-100 mb-2">
              <Placeholder xs={6} bg="secondary" />
            </div>
            <div className="w-100 mb1">
              <Placeholder xs={12} bg="secondary" />
            </div>
            <div className="w-100 mb1">
              <Placeholder xs={12} bg="secondary" />
            </div>
            <div className="w-100 mb1">
              <Placeholder xs={6} bg="secondary" />
            </div>
          </Card.Body>
        </CustomCard>
      );
      break;
    case "leaderboard":
      card = (
        <CustomCard
          className={`main_card placeholder_card shadow1 border-0 mb2 text-left overflow-hidden`}
        >
          <Card.Header>
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0">
                <Placeholder as="div" animation="glow">
                  <Placeholder
                    bg="secondary"
                    as="div"
                    className="placeholder_profile_img rounded-circle"
                  />
                </Placeholder>
              </div>
              <div className="flex-grow-1 ms-3 text-left">
                <div className="w-100">
                  <Placeholder xs={6} bg="secondary" />
                </div>
                <div className="w-100">
                  <Placeholder xs={4} bg="secondary" />
                </div>
              </div>
            </div>
          </Card.Header>
        </CustomCard>
      );
      break;
    case "details":
      card = (
        <>
          <div className="w-100 mb-1">
            <Placeholder xs={12} bg="secondary" />
          </div>
          <div className="w-100 mb1">
            <Placeholder xs={8} bg="secondary" />
          </div>
          <hr />
          <div className="w-100 mb-1">
            <Placeholder xs={12} bg="secondary" />
          </div>
          <div className="w-100 mb1">
            <Placeholder xs={8} bg="secondary" />
          </div>
          <hr />
          <div className="w-100 mb-1">
            <Placeholder xs={12} bg="secondary" />
          </div>
          <div className="w-100 mb1">
            <Placeholder xs={8} bg="secondary" />
          </div>
        </>
      );
      break;
    default:
      card = (
        <CustomCard
          className={`main_card placeholder_card shadow1 border-0 mb2 text-left overflow-hidden`}
        >
          <Card.Header>
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0">
                <Placeholder as="div" animation="glow">
                  <Placeholder
                    bg="secondary"
                    as="div"
                    className="placeholder_profile_img rounded-circle"
                  />
                </Placeholder>
              </div>
              <div className="flex-grow-1 ms-3 text-left">
                <div className="w-100">
                  <Placeholder xs={6} bg="secondary" />
                </div>
                <div className="w-100">
                  <Placeholder xs={4} bg="secondary" />
                </div>
              </div>
            </div>
          </Card.Header>
          <Card.Body className="p2">
            <div className="w-100 mb-1">
              <Placeholder xs={9} bg="secondary" />
            </div>
            <div className="w-100 mb1">
              <Placeholder xs={6} bg="secondary" />
            </div>
            <div className="btn_wrap">
              {/* <Placeholder.Button
                      aria-hidden="true"
                      style={{
                        borderRadius: "20px",
                        width: "100px",
                      }}
                      className="btn_theme btn_small btn_gray btn_rounded"
                    /> */}
              {/* <Placeholder.Button
                      aria-hidden="true"
                      style={{
                        borderRadius: "20px",
                        width: "100px",
                      }}
                      className="btn_theme btn_small btn_gray btn_rounded"
                    /> */}
            </div>
          </Card.Body>
        </CustomCard>
      );
  }

  return <>{card}</>;
}

export default PlaceholderCard;
