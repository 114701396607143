import React, { useState, useEffect, useContext } from "react";
import { Dropdown } from "react-bootstrap";
import DashboardSectionTitle from "../DashboardSectionTitle/DashboardSectionTitle";
import DashboardConsultantCard from "./DashboardConsultantCard";
import DashboardSearchConsults from "./DashboardSearchConsults";
import PlaceholderCard from "../../../ui/PlaceholderCard/PlaceholderCard";
import SimpleMessageCard from "../../../ui/SimpleMessageCard/SimpleMessageCard";
import { BsFunnelFill } from "react-icons/bs";

//Context
import AppContext from "../../../AppContext";
//Service
import recordService from "../../../services/Record";

function DashboardConsultContent() {
  const { friends } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [consultants, setConsultants] = useState([]);

  useEffect(() => {
    //Fetching all consultants for the first time when component loads...
    getAllConsultants();
  }, []);

  //Fetch all consultants from DB
  function getAllConsultants() {
    setLoading(true);
    recordService
      .getAllConsultants()
      .then((response) => {
        console.log("COnsultants =", response.data);
        console.log("--------------------------");
        console.log("Friends=", friends);
        const friendIdList = friends.map((item) => {
          console.log("type of friend id =", typeof item.id);
          return item.id;
        });
        console.log("Fri ID=", friendIdList);
        const consultants = response.data.data.result.consultants.map(
          (item) => {
            if (friendIdList.includes(item.user_id)) {
              return { ...item, is_friend: true };
            }
            return { ...item, is_friend: false };
          }
        );
        console.log("Modifoed consult = ", consultants);
        setConsultants(consultants);
        setLoading(false);
      })
      .catch((err) => {
        console.log("There is a problem in getting all invitations...", err);
        setLoading(false);
      });
  }

  function handleSort(sortByTerm) {
    setLoading(true);
    if (sortByTerm === "ratings") {
      console.log(
        "handleSort('ratings') called = ",
        consultants.sort((a, b) => b.rating - a.rating)
      );
      setTimeout(() => {
        setConsultants(consultants.sort((a, b) => b.rating - a.rating));
        setLoading(false);
      }, 1000);
    } else if (sortByTerm === "sessions") {
      console.log(
        "handleSort('sessions') called = ",
        consultants.sort((a, b) => b.rating - a.rating)
      );
      setTimeout(() => {
        setConsultants(consultants.sort((a, b) => b.rating - a.rating));
        setLoading(false);
      }, 1000);
    } else if (sortByTerm === "cost") {
      console.log(
        "handleSort('cost') called = ",
        consultants.sort(
          (a, b) => b.average_cost_per_session - a.average_cost_per_session
        )
      );
      setTimeout(() => {
        setConsultants(
          consultants.sort(
            (a, b) => b.average_cost_per_session - a.average_cost_per_session
          )
        );
        setLoading(false);
      }, 1000);
    }
  }

  return (
    <>
      <div className="position-relative">
        <DashboardSectionTitle
          iconImg="offer-icon6.png"
          title="Consult"
          pageTitle={true}
          className="mb2"
          withInfo={true}
          infoData="Consult with licensed practitioners about your unconscious experiences in order to discover more about yourself or receive therapy."
        />

        <Dropdown
          align="end"
          className="position-absolute top-0 p-0"
          style={{ right: "0.5rem", width: "auto" }}
        >
          <Dropdown.Toggle
            className="btn_theme btn_light btn_rounded btn_icon down_icon_hidden"
            id="dropdown-basic"
          >
            <BsFunnelFill />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item
              as="button"
              className="font-14"
              onClick={() => {
                handleSort("ratings");
              }}
            >
              Sort by ratings
            </Dropdown.Item>
            {/* <Dropdown.Divider />
            <Dropdown.Item
              as="button"
              className="font-14"
              onClick={() => {
                handleSort("sessions");
              }}
            >
              Sort by no. of sessions
            </Dropdown.Item> */}
            <Dropdown.Divider />
            <Dropdown.Item
              as="button"
              className="font-14"
              onClick={() => {
                handleSort("cost");
              }}
            >
              Sort by average cost
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>

      <DashboardSearchConsults
        setConsultants={setConsultants}
        getAllConsultants={getAllConsultants}
      />

      {loading ? (
        <>
          {[0, 1].map((_, i) => (
            <PlaceholderCard key={i} type="consult" />
          ))}
        </>
      ) : (
        <>
          {consultants.length > 0 ? (
            <>
              {consultants.map((consultant, index) => (
                <DashboardConsultantCard key={index} consultant={consultant} />
              ))}
            </>
          ) : (
            <>
              <SimpleMessageCard type="warning" />
            </>
          )}
        </>
      )}
    </>
  );
}

export default DashboardConsultContent;
