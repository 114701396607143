import React from "react";
import DashboardSectionTitle from "../DashboardSectionTitle/DashboardSectionTitle";
import Association from "./Association";
import PlaceholderCard from "../../../ui/PlaceholderCard/PlaceholderCard";
import SimpleMessageCard from "../../../ui/SimpleMessageCard/SimpleMessageCard";

function AssociationList(props) {
  return (
    <>
      <DashboardSectionTitle
        iconImg=""
        title="Associations"
        pageTitle={false}
        className="mb2"
      />

      {!props.loading && props.association?.length > 0 ? (
        props.association?.map((item, index) => (
          <Association
            item={item}
            key={index}
            record_id={props.record_id}
            handleAssociationList={props.handleAssociationList}
          />
        ))
      ) : (
        <>
          {props.loading ? (
            <>
              {[0, 1].map((_, i) => (
                <PlaceholderCard key={i} />
              ))}
            </>
          ) : (
            <SimpleMessageCard
              type="warning"
              msg="No more associations found..."
              allowIcon={false}
              className="mb5"
            />
          )}
        </>
      )}
    </>
  );
}

export default AssociationList;
