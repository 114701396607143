import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Card,
  Form,
  Button,
  Spinner,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import CustomCard from "../../../ui/CustomCard/CustomCard";
import DashboardSectionTitle from "../DashboardSectionTitle/DashboardSectionTitle";
import RecordEventFormSlider from "./RecordEventFormSlider";
import FieldError from "../../../ui/FieldError/FieldError";
import { Field, Formik } from "formik";
import * as yup from "yup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { handleAlert, formatDateYMD } from "../../../util/util";
import {
  emotionsList,
  charactersList,
  interactionsList,
  locationsList,
  causesList,
  resolutionsList,
} from "../../../util/emoji";

//Services
import recordService from "../../../services/Record";
//Context
import AppContext from "../../../AppContext";

function EditRecordEvents(props) {
  const navigate = useNavigate();
  let { id } = useParams();
  const { setExperiences, getData } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [showSlider, setShowSlider] = useState(false);
  const [step, setStep] = useState(false);
  const [activeSlide, setActiveSlide] = useState(0);
  const [recordEventFormValues, setRecordEventFormValues] = useState({});

  const [initialValues, setInitialValues] = useState({
    record_date: null,
    // record_type: "",
    title: "",
    emotions: [],
    characters: [],
    interactions: [],
    locations: [],
    causes: [],
    resolutions: [],
    // description: "",
    is_private: false,
    is_consult_needed: false,
  });

  //For other emotion
  const otherEmotionRef = useRef("");
  //For other character
  const otherCharacterRef = useRef("");
  //For other interactions
  const otherInteractionRef = useRef("");
  //For other locations
  const otherLocationRef = useRef("");
  //For other causes
  const otherCauseRef = useRef("");
  //For other resolutions
  const otherResolutionRef = useRef("");

  // For description field
  const descriptionRef = useRef("");
  const [descriptionError, setDescriptionError] = useState("");

  //For Decoded Description field
  const [isDecoded, setIsDecoded] = useState(false);
  const [decodedDiscription, setDecodedDiscription] = useState([]);
  const [decodedStr, setDecodedStr] = useState(null);

  //Set focus to description textbox
  function handleDescFocus() {
    console.log("Indside handleDescFocus");
    descriptionRef.current.focus();
  }

  //Validating description field should not be empty...
  function validateDescription(e) {
    console.log(descriptionRef.current.value);

    if (e.target.value) {
      setDescriptionError("");
      descriptionRef.current.value = e.target.value;
    } else {
      setDescriptionError("Description cannot be empty");
    }
  }
  //Checking descriptionRef should not be empty...
  function checkDescription() {
    const refVal = descriptionRef?.current.value;
    if (refVal !== "") {
      console.log("InputRef val = ", refVal);
      setDescriptionError("");
      return true;
    } else {
      setDescriptionError("Description cannot be empty");
      return false;
    }
  }

  //Get decoded string
  function getDecodedString(dictionary) {
    let tempStr = [];
    let tempDesc = descriptionRef.current.value;
    const descriptionWords = tempDesc.split(" ");
    console.log("tempDesc =", tempDesc, "\nDictionary=", dictionary);
    console.log("Desc Words =", descriptionWords, "\ntempDesc =", tempDesc);
    const dictionaryWords = dictionary.map((item) => {
      return item.word.toLowerCase();
    });
    console.log("This is word array =", dictionaryWords, " created.");
    tempStr = descriptionWords.map((word) => {
      let matchedWord = dictionary.find((dic) =>
        dic.tags
          .map((tag) => tag.toLowerCase())
          .concat([dic.word.toLowerCase()])
          .includes(word.toLowerCase().replace(/[^a-zA-Z0-9]/g, ""))
      );
      if (matchedWord) {
        return (
          <OverlayTrigger
            key={matchedWord.id}
            trigger={["focus", "click"]}
            placement="top"
            rootClose={true}
            overlay={
              <Tooltip
                id={`tooltip-${matchedWord.id}`}
                className="custom_tooltip"
              >
                <div className="tooltip_title text_primary">
                  {matchedWord.word}
                </div>
                <div className="mb-2">
                  <span className="text_primary">Short: </span>
                  {matchedWord.short_description}
                </div>
                <div>
                  <span className="text_primary">Long: </span>
                  {matchedWord.description}
                </div>
              </Tooltip>
            }
          >
            <span className="tooltip_badge">{word}</span>
          </OverlayTrigger>
        );
      } else {
        return word;
      }
    });

    console.log("decoded: ", tempStr);
    setDecodedStr(tempStr);
  }

  //Decode function
  function handleDecode() {
    console.log("Called function handleDecode()...");
    //Checking descriptionRef should not be empty...
    if (checkDescription()) {
      console.log("Description is decoded");
      const descVal = { description: descriptionRef.current.value };
      console.log("descVal =", descVal);
      //Calling Decode function
      recordService
        .getDecodedDictionary(descVal)
        .then((response) => {
          if (response.data.status === "success") {
            console.log("Response from Record Form : ", response.data);
            const dictionary = response.data.data.result.dictionary;
            // setExperiences(records);
            setIsDecoded(true);
            getDecodedString(dictionary);

            setDecodedDiscription(dictionary);
          }
          if (response.data.status === "failed") {
            console.log("ERROR:", response.data);
            setIsDecoded(false);
            setDecodedDiscription([]);
          }
        })
        .catch((err) => {
          console.log("There is a problem in decoding description...", err);
        });
    } else {
      console.log("Description is empty");
    }
  }

  //For getting record list and updating it into context variable
  // function getData() {
  //   recordService
  //     .getAllRecords()
  //     .then((response) => {
  //       if (response.data.status === "success") {
  //         // console.log("Response from APP : ", response.data);
  //         const records = response.data.data.result.records;
  //         setExperiences(records);
  //       }
  //       if (response.data.status === "failed") {
  //         console.log("ERROR:", response.data.error);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log("There is a problem in creating new record...", err);
  //     });
  // }

  function handleEditDetails() {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setStep(true);
    }, 1000);
  }

  let initialValue = {
    record_date: new Date(
      formatDateYMD(new Date(props.recordDetails.created_date))
    ),
    title: props.recordDetails.title ? props.recordDetails.title : "",
    emotions: props.recordDetails.emotions ? props.recordDetails.emotions : [],
    characters: props.recordDetails.characters
      ? props.recordDetails.characters
      : [],
    interactions: props.recordDetails.interactions
      ? props.recordDetails.interactions
      : [],
    locations: props.recordDetails.locations
      ? props.recordDetails.locations
      : [],
    causes: props.recordDetails.causes ? props.recordDetails.causes : [],
    resolutions: props.recordDetails.resolutions
      ? props.recordDetails.resolutions
      : [],
    description: props.recordDetails.description
      ? props.recordDetails.description
      : "",
    is_private: props.recordDetails.is_private
      ? props.recordDetails.is_private
      : false,
    is_consult_needed: props.recordDetails.is_consult_needed
      ? props.recordDetails.is_consult_needed
      : false,
  };

  //useEffect for setting initialValues when component loads for the first time
  useEffect(() => {
    console.log("InitialValue = ", initialValue);
    setInitialValues(initialValue);
    console.log("Initial Values = ", initialValues);
  }, []);

  //useEffect for setting value to the description field when step === true
  useEffect(() => {
    if (step === true) {
      if (!checkDescription()) {
        descriptionRef.current.value = initialValues.description;
        setDescriptionError("");
      }
      console.log("initialValues.emotions =", initialValues.emotions);
      console.log("initialValues.interactions =", initialValues.interactions);
      otherEmotionRef.current.value = initialValues.emotions
        .filter((item) => !emotionsList.map((e) => e.value).includes(item))
        .toString();
      otherCharacterRef.current.value = initialValues.characters
        .filter((item) => !charactersList.map((e) => e.value).includes(item))
        .toString();
      otherInteractionRef.current.value = initialValues.interactions
        .filter((item) => !interactionsList.map((e) => e.value).includes(item))
        .toString();
      otherLocationRef.current.value = initialValues.locations
        .filter((item) => !locationsList.map((e) => e.value).includes(item))
        .toString();
      otherCauseRef.current.value = initialValues.causes
        .filter((item) => !causesList.map((e) => e.value).includes(item))
        .toString();
      otherResolutionRef.current.value = initialValues.resolutions
        .filter((item) => !resolutionsList.map((e) => e.value).includes(item))
        .toString();
    }
  }, [step]);

  const recordEventsSchema = yup.object({
    record_date: yup.date().required("Required").nullable(),
    title: yup.string().max(100, "Title is too long!").required("Required"),
  });

  //filters the othe field value with checkbox values and return a combined array...
  function filterOtherValues(savedOptions, optionList, otherValue) {
    let newSavedOptions = [];
    if (savedOptions.length > 0) {
      if (otherValue !== "") {
        newSavedOptions = savedOptions
          .filter((item) => optionList.map((opt) => opt.value).includes(item))
          .concat(otherValue);
      } else {
        newSavedOptions = savedOptions.filter((item) =>
          optionList.map((opt) => opt.value).includes(item)
        );
      }
    } else {
      if (otherValue !== "") newSavedOptions.push(otherValue);
    }
    return newSavedOptions;
  }

  const onSubmit = async (values, { resetForm }) => {
    console.log("Form Values :", values);
    setLoading(true);

    let final_value = Object.assign({}, initialValues);
    final_value["record_date"] = values["record_date"];
    final_value["title"] = values["title"];

    if (props.recordDetails.record_id) {
      final_value["record_id"] = props.recordDetails.record_id;
    }

    console.log("Final Values", final_value);
    values = final_value;

    let newDate = formatDateYMD(values.record_date);
    console.log("New Date =", newDate);

    console.log("Other emotion ref value =", otherEmotionRef);

    //Check descriptionRef is exist or not and if not esist
    if (!checkDescription()) {
      // descriptionRef.current.value = props.recordDetails.description;
      handleAlert("Error! Record updation failed", "error");
      setLoading(false);
      return false;
    }

    console.log("Values in update dream =", values);
    const otherEmotionValue = otherEmotionRef?.current.value;
    const newEmotions = filterOtherValues(
      values.emotions,
      emotionsList,
      otherEmotionValue
    );
    //const newEmotions = values.emotions.filter(item => emotionsList.map(e => e.value).includes(item)).concat(otherEmotionValue);
    console.log("newEmotions =", newEmotions);

    const otherCharacterValue = otherCharacterRef?.current.value;
    const newCharacters = filterOtherValues(
      values.characters,
      charactersList,
      otherCharacterValue
    );
    console.log("newCharacters =", newCharacters);

    const otherInteractionValue = otherInteractionRef?.current.value;
    const newInteractions = filterOtherValues(
      values.interactions,
      interactionsList,
      otherInteractionValue
    );
    console.log("newInteractions =", newInteractions);

    const otherLocationValue = otherLocationRef?.current.value;
    const newLocations = filterOtherValues(
      values.locations,
      locationsList,
      otherLocationValue
    );
    console.log("newLocations =", newLocations);

    const otherCauseValue = otherCauseRef?.current.value;
    const newCauses = filterOtherValues(
      values.causes,
      causesList,
      otherCauseValue
    );
    console.log("newCauses =", newCauses);

    const otherResolutionValue = otherResolutionRef?.current.value;
    const newResolutions = filterOtherValues(
      values.resolutions,
      resolutionsList,
      otherResolutionValue
    );
    console.log("newResolutions =", newResolutions);

    const newValues = {
      ...values,
      type: "event",
      record_date: newDate,
      is_complete: true,
      emotions: newEmotions,
      characters: newCharacters,
      interactions: newInteractions,
      locations: newLocations,
      causes: newCauses,
      resolutions: newResolutions,
      description: descriptionRef.current.value,
      description_decoded: decodedStr,
      dictionary_words: decodedDiscription,
    };
    console.log("New Values =", newValues);

    if (newValues.hasOwnProperty("record_id")) {
      //Update an existing record having id = record_id
      console.log("Own the property");
      console.log("Updated Form values: ", newValues);
      console.log("Updated Form values (formik) : ", values);

      // setRecordEventFormValues((prev) => {
      //   console.log("In update setter ", { ...prev, ...newValues });

      //   let tVal = Object.assign(prev, newValues);
      //   console.log("tVal in update =", tVal);
      //   return tVal;
      // });
      // console.log(
      //   "In If (update) after setter method: recordFormVal =",
      //   recordEventFormValues
      // );

      recordService
        .updateRecord(newValues)
        .then((response) => {
          if (response.data.status === "success") {
            console.log(response.data);
            console.log("New Values =", newValues);
            const responseRecord = response.data.data.result.record;
            console.log("Saved response Record: ", responseRecord);
            // const updatedResponseRecord = {
            //   ...responseRecord,
            //   tags: newValues.tags,
            // };
            // console.log("Updated response record : ", updatedResponseRecord);

            handleAlert("Success! Record: Event updated", "success");
            setLoading(false);
            setRecordEventFormValues({});
            // setRecordEventFormValues((prev) => {
            //   console.log("In create setter ", {
            //     ...prev,
            //     ...updatedResponseRecord,
            //   });
            //   let tVal = Object.assign(prev, updatedResponseRecord);
            //   console.log("tVal =", tVal);
            //   return tVal;
            // });
            //Resetting slider current item to 0 for next new recording...
            setActiveSlide(0);
            //resetting back first step of the form
            setStep(false);
            setIsDecoded(false);
            setDecodedDiscription([]);
            setDecodedStr([]);
            resetForm();
            props.handleCollapse("edit");

            // console.log("Record Id...", responseRecord.record_id);
            // console.log(
            //   "New Record from state (update) =",
            //   recordEventFormValues
            // );
            //fetch new context data
            getData();
          }
          if (response.data.status === "failed") {
            console.log("ERROR:", response.data.error);
            handleAlert("Error! Record updation failed", "error");
            setLoading(false);
            // resetForm();
          }
        })
        .catch((err) => {
          console.log("There is a problem in creating new record...", err);
        });
    }
  };

  const owlSliderConfigurations = {
    margin: 15,
    loop: false,
    dots: false,
    nav: true,
    responsiveClass: true,
    startPosition: activeSlide,
    responsive: {
      0: { items: 1 },
    },
  };

  return (
    <>
      {/* {!showSlider && ( */}
      <>
        <Formik
          initialValues={initialValues}
          validationSchema={recordEventsSchema}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {(formik) => {
            return (
              <Form onSubmit={formik.handleSubmit} className="form_style">
                {!step && (
                  <>
                    {/* <div className="font-14 mb2">
                      * No recorded experiences are shared until you choose to
                      share them on the share page.
                    </div> */}
                    <Form.Group className="form-group rounded_corners">
                      <DashboardSectionTitle
                        iconImg=""
                        title="Date of occurrence"
                        pageTitle={false}
                        className="mb1 small_title"
                      />
                      <Field name="record_date">
                        {({ form, field }) => {
                          const { setFieldValue } = form;
                          const { value } = field;
                          console.log("Field value =", value);
                          return (
                            <DatePicker
                              id="record_date"
                              className="form-control"
                              {...field}
                              selected={value}
                              onChange={(val) =>
                                setFieldValue("record_date", val)
                              }
                              // disabled
                              dateFormat="yyyy-MM-dd"
                              placeholderText="Date of occurrence"
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              // scrollableMonthYearDropdown
                              autoComplete="off"
                              disabled
                            />
                          );
                        }}
                      </Field>
                      <FieldError
                        valid={
                          formik.touched.record_date &&
                          formik.errors.record_date
                            ? true
                            : false
                        }
                        text={formik.errors.record_date}
                      />
                    </Form.Group>

                    <Form.Group className="form-group rounded_corners">
                      <DashboardSectionTitle
                        iconImg=""
                        title="Event title"
                        pageTitle={false}
                        className="mb1 small_title"
                      />
                      <Form.Control
                        type="text"
                        placeholder="Event title"
                        name="title"
                        {...formik.getFieldProps("title")}
                        autoComplete="off"
                      />
                      <div className="text-end text_gray8">
                        <small>* Maximum 100 characters long</small>
                      </div>
                      <FieldError
                        valid={
                          formik.touched.title && formik.errors.title
                            ? true
                            : false
                        }
                        text={formik.errors.title}
                      />
                    </Form.Group>
                  </>
                )}
                {step && (
                  <>
                    <RecordEventFormSlider
                      options={owlSliderConfigurations}
                      formik={formik}
                      emotionsList={emotionsList}
                      charactersList={charactersList}
                      interactionsList={interactionsList}
                      locationsList={locationsList}
                      causesList={causesList}
                      resolutionsList={resolutionsList}
                      activePage={setActiveSlide}
                      initialValues={initialValues}
                      handleDescFocus={handleDescFocus}
                      otherEmotionRef={otherEmotionRef}
                      otherCharacterRef={otherCharacterRef}
                      otherInteractionRef={otherInteractionRef}
                      otherLocationRef={otherLocationRef}
                      otherCauseRef={otherCauseRef}
                      otherResolutionRef={otherResolutionRef}
                      cardTop={props.cardTop}
                    />

                    <CustomCard className="main_card mb3 bg_primary_light2">
                      <Card.Body>
                        <Row className="align-items-center mb1">
                          <Col xs={12} sm={12} md={8} lg={8}>
                            <div className="font-14 fw_sb">
                              Full description of event
                            </div>
                          </Col>
                          <Col xs={12} sm={12} md={4} lg={4}>
                            {/* <div className="d-flex justify-content-end">
                              <Button
                                type="button"
                                key="decode_btn"
                                className="btn btn_theme btn_small btn_secondary btn_rounded"
                                onClick={handleDecode}
                              >
                                Decode
                              </Button>
                            </div> */}
                          </Col>
                        </Row>
                        <Form.Group className="form-group rounded_corners">
                          <Form.Control
                            as="textarea"
                            rows={3}
                            name="description"
                            onChange={(e) => {
                              validateDescription(e);
                            }}
                            ref={descriptionRef}
                            onBlur={(e) => {
                              validateDescription(e);
                            }}
                          />
                          <FieldError
                            valid={descriptionError !== "" ? true : false}
                            text={descriptionError}
                          />
                          {/* {descriptionError !== "" ? (
                            <p className="error-state-text">
                              {descriptionError}
                            </p>
                          ) : null} */}
                        </Form.Group>
                        {isDecoded && (
                          <>
                            <hr />
                            <Row>
                              <Col xs={12}>
                                <div className="pb1">
                                  {decodedStr?.length > 0 && (
                                    <>
                                      <div className="font-14 fw_sb pb1">
                                        Decoded description:
                                      </div>
                                      {decodedStr?.map((item, index) => {
                                        return <>{item} </>;
                                      })}

                                      {/* {decodedDiscription?.map(
                                        (item, index) => {
                                          return (
                                            <div key={index}>
                                              <strong>{item.word}:</strong>
                                              {item.description}
                                            </div>
                                          );
                                        }
                                      )} */}
                                    </>
                                  )}
                                </div>
                                {decodedStr?.length < 0 && (
                                  <>
                                    <p className="font-14 fw_sb">
                                      No more decoded desciption to display
                                    </p>
                                  </>
                                )}
                              </Col>
                            </Row>
                          </>
                        )}
                      </Card.Body>
                    </CustomCard>
                  </>
                )}

                <div className="d-flex justify-content-center flex-column flex-sm-row">
                  <Form.Check
                    inline
                    label="Consult a specialist?"
                    name="is_consult_needed"
                    type="checkbox"
                    id="is_consult_needed"
                    value={formik.values.is_consult_needed}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    checked={
                      formik.values?.is_consult_needed === "true" ? true : false
                    }
                  />
                  {/* <Form.Check
                    inline
                    label="Mark private?"
                    name="is_private"
                    type="checkbox"
                    id="is_private"
                    value={formik.values.is_private}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    checked={
                      formik.values?.is_private === "true" ? true : false
                    }
                  /> */}
                </div>
                <div className="btn_wrap text-center pt2">
                  {step ? (
                    <Button
                      key="submit"
                      type="submit"
                      className="btn_theme btn_primary btn_rounded"
                    >
                      {loading ? (
                        <>
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />{" "}
                          Loading
                        </>
                      ) : (
                        "Save & Exit"
                      )}
                    </Button>
                  ) : (
                    <Button
                      key="notsubmit"
                      type="button"
                      className="btn_theme btn_primary btn_rounded"
                      onClick={handleEditDetails}
                    >
                      {loading ? (
                        <>
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />{" "}
                          Loading
                        </>
                      ) : (
                        "Edit Details"
                      )}
                    </Button>
                  )}
                  {/* {step && (
                    <Button
                      type="button"
                      className="btn_theme btn_primary btn_border btn_rounded"
                    >
                      Discard
                    </Button>
                  )} */}
                </div>
              </Form>
            );
          }}
        </Formik>
      </>
    </>
  );
}

export default EditRecordEvents;
