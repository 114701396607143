import React, { useState } from "react";
import { Card, Button, Collapse } from "react-bootstrap";
import CustomCard from "../../../ui/CustomCard/CustomCard";
import { BsFillPencilFill } from "react-icons/bs";
import AddAssociation from "./AddAssociation";
//Service
import recordService from "../../../services/Record";

function Association(props) {
  const [editCollapse, setEditCollapse] = useState(false);
  const [newRecord, setNewRecord] = useState(null);
  const [cardTop, setCardTop] = useState(0);

  function handleClick(e) {
    const element = e.target.getAttribute("aria-controls");
    let isAriaExpanded = e.target.getAttribute("aria-expanded");
    // console.log("Association Card =", e);
    handleCollapse(element);
    /* Get the offsetTop of the Current Custom Card and set it to state
       to scroll page back to its normal position when collapsible collapses.. 
    ***/
    // console.log("Top = ", e.target.offsetParent.offsetTop);
    setCardTop(e.target.offsetParent.offsetTop);
  }

  function handleCollapseOnExiting() {
    window.scroll({
      top: cardTop,
      behavior: "smooth",
    });
  }

  function handleCollapse(element) {
    // console.log("In Association Comp =", element);
    // if (element === "edit_association")
    setEditCollapse(!editCollapse);
  }

  function handleIsUpdating(item) {
    console.log("Item =", item);
    if (props.item.record_associate_id == item.record_associate_id) {
      // console.log(
      //   "Inside if loop ",
      //   props.item.record_associate_id == item.record_associate_id
      // );
      recordService
        .getAssociation(item.record_associate_id)
        .then((response) => {
          if (response.data.status === "success") {
            // console.log("useEffect of  AddAssociation : ", response.data);
            const newFormValues = response.data.data.result.association;
            let new_is_acted_upon =
              newFormValues.is_acted_upon === true ? "true" : "false";
            let new_is_things_happen =
              newFormValues.is_things_happen === true ? "true" : "false";

            const newValues = {
              ...newFormValues,
              record_id: props.record_id,
              is_acted_upon: new_is_acted_upon,
              is_things_happen: new_is_things_happen,
            };
            // console.log("New Form Values =", newValues);
            // setFormValues(newValues);
            setNewRecord(newFormValues);
          }
          if (response.data.status === "failed") {
            console.log("ERROR:", response.data.error);
            // setLoading(false);
          }
        })
        .catch((err) => {
          console.log(
            "There is a problem in fetching association details...",
            err
          );
          // setLoading(false);
        });
    }
  }

  // Date formatting
  function formattedDate(d) {
    return [d.getMonth() + 1, d.getDate(), d.getFullYear()]
      .map((n) => (n < 10 ? `0${n}` : `${n}`))
      .join("/");
  }
  return (
    <>
      <CustomCard className="border-0 shadow1 association_card overflow-hidden mb2">
        <Card.Header className="border-0">
          <div className="d-flex">
            <div class="card-title flex-grow-1 me-2">
              {/* timestamp ( like Few minutes ago, 1 day ago etc ) */}
              {/* {props.item.action_taken} */}
              {/* Created on {formattedDate(new Date(props.item.created_date))} */}
              {props.item.created_date}
            </div>
            <Button
              className={`btn_theme btn_icon btn_auto_width btn_gray btn_circle`}
              type="button"
              onClick={handleClick}
              aria-controls="edit_association"
              aria-expanded={editCollapse}
            >
              <BsFillPencilFill />
            </Button>
          </div>
        </Card.Header>
        <Card.Body className="comment_text pt0">
          <p>
            {/* {props.item.record_associate_id} |  */}
            {props.item.action_taken}
          </p>
          {/*  */}
          <Collapse
            in={editCollapse}
            onEntered={() => {
              handleIsUpdating(props.item);
            }}
            onExiting={handleCollapseOnExiting}
          >
            <div id="edit_association">
              <hr />
              <AddAssociation
                record_id={props.record_id}
                record_associate_id={props.item.record_associate_id}
                isAddMode={false}
                associationRecord={newRecord}
                handleAssociationList={props.handleAssociationList}
                setEditCollapse={setEditCollapse}
                editCollapse={editCollapse}
                handleCollapse={handleCollapse}
              />
            </div>
          </Collapse>
        </Card.Body>
      </CustomCard>
    </>
  );
}

export default Association;
