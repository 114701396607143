import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./HomeNormalBanner.css";

function HomeNormalBanner() {
  return (
    <div
      className={`pt5 pb5 join_us_banner2`}
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/img/blue-gradient-bg.jpg)`,
      }}
    >
      <Container>
        <Row className="d-flex align-items-center">
          <Col sm={12} md={5} lg={4}>
            <div className="img_container">
              <img
                src={`${process.env.PUBLIC_URL}/img/udreamed-icon.png`}
                className="img-fluid"
                alt="uDreamed Icon"
              />
            </div>
          </Col>
          <Col sm={12} md={7} lg={8}>
            <div className="banner_section_text">
              <h2>opening up new possibilities for self-development</h2>
              <Link
                to="/signup"
                className="btn_theme btn_rounded btn_border btn_white btn_large"
              >
                Start Today!
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default HomeNormalBanner;
