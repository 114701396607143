import React from "react";

function TermsInfo() {
  return (
    <div className="page_text">
      <p>
        Please read these Terms of Use and Terms of Service (“Terms”) carefully.
        It governs your access to and use of the services offered by uDreamed,
        Inc. (“uDreamed”), including our website, mobile application, email
        notifications, ads, practitioner consultation and commerce services (the
        “Services”), and any information, text, graphics, photos or other
        materials recorded, associated, analyzed, matched, shared, uploaded,
        downloaded or appearing on the Services (collectively referred to as
        “Content”). By accessing or using the Services in any manner, you (the
        "user" or "you") agree to be legally bound by these Terms. This Terms of
        Use applies to all users of the Services, including without limitation
        users who are advertisers, therapists, customers, merchants, developers,
        contributors of content, information and other materials or services on
        the Site.
      </p>

      <p>
        You agree that you will not provide any false personal information on
        uDreamed, or create an account for anyone other than yourself without
        permission. You will not create more than one personal account. If
        uDreamed disables your account, you will not create another one without
        permission from uDreamed. You will not upload viruses or other malicious
        code. You will not solicit login information or access an account
        belonging to someone else.
      </p>

      <p>
        You agree that safeguarding the password that you use to access the
        Services and for any activities or actions under your password is your
        responsibility. We encourage you to use “strong” passwords (passwords
        that use a combination of upper and lower case letters, numbers and
        symbols) with your account. uDreamed cannot and will not be liable for
        any loss or damage arising from your failure to comply with the above.
      </p>

      <p>
        We may suspend or terminate your accounts or cease providing you with
        all or part of the Services at any time for any reason, including, but
        not limited to, if we reasonably believe: (i) you have violated these
        Terms, (ii) you create risk or possible legal exposure for us; or (iii)
        our provision of the Services to you is no longer commercially viable.
        We will make reasonable efforts to notify you by the email address
        associated with your account or the next time you attempt to access your
        account.
      </p>

      <p>
        When you use the Services, you are fully responsible for any Content you
        record, associate, analyze, match and share with others, including
        friends, groups and practitioners, and for any consequences thereof. The
        Content you record, associate, analyze, match and share with others,
        including friends, groups and practitioners will be able to be viewed by
        other users of the Services you chose to share or match such content.
        You should only provide Content that you are comfortable sharing or
        matching with others under these Terms.
      </p>

      <p>
        The Services that uDreamed provides may change from time to time without
        prior notice to you. uDreamed may stop (permanently or temporarily)
        providing the Services (or any features within the Services) to you or
        to users generally and may not be able to provide you with prior notice.
        In addition, uDreamed retains the right to create limits on use of
        Services and storage at its sole discretion at any time without prior
        notice to you.
      </p>

      <p>
        The Services may include advertisements, which may be targeted to the
        Content or information on the Services, practitioner consultations and
        queries made through the Services, or other information. The types and
        extent of advertising by uDreamed on the Services are subject to change.
        In consideration for uDreamed granting you access to and use of the
        Services, you agree that uDreamed and its third party providers and
        partners may place such advertising on the Services or in connection
        with the display of Content or information from the Services whether
        submitted by you or others.
      </p>

      <p>
        Information that you provide to uDreamed is subject to our Privacy
        Policy, which governs our collection and use of your information. You
        understand that through your use of the Services you consent to the
        collection and use (as set forth in the Privacy Policy) of this
        information, including the transfer of this information to other
        countries for storage, processing and use by uDreamed.
      </p>

      <p>
        All Content is the sole responsibility of the user who originated and
        recorded such Content. We may not monitor or control the Content
        recorded, posted or shared via the Services and, we cannot take
        responsibility for such Content. Any use or reliance on any Content or
        materials recorded, posted or shared via the Services or obtained by you
        through the Services is at your own risk. We cannot prevent bots from
        scraping our site.
      </p>

      <p>
        uDreamed does not endorse, support, represent or guarantee the
        completeness, truthfulness, accuracy, or reliability of any Content or
        comment posted via the Services or endorses any opinions expressed via
        the Services. You understand that by using the Services, you may be
        exposed to Content that might be offensive, harmful, inaccurate or
        otherwise inappropriate, or in some cases, postings that have been
        mislabeled or are otherwise deceptive. Under no circumstances will
        uDreamed be liable in any way for any Content, including, but not
        limited to, any errors or omissions in any Content, or any loss or
        damage of any kind incurred as a result of the use of any Content
        recorded, posted, shared, matched, emailed, transmitted or otherwise
        made available via the Services or transmitted elsewhere.
      </p>

      <p>
        As a user, you retain your rights to any Content you record, associate,
        analyze, match and share on or through the Services. By recording,
        associating, analyzing, matching and sharing Content on or through the
        Services, you grant us a worldwide, non-exclusive, royalty-free license
        (with the right to sublicense) to use, copy, reproduce, process, adapt,
        modify, publish, transmit, display and distribute such Content in any
        and all media or distribution methods (now known or later developed).
      </p>

      <p>
        You agree that this license includes the right for uDreamed to provide,
        promote, and improve the Services and to make Content submitted to or
        through the Services available to other firms, organizations or
        individuals who partner with uDreamed for the aggregation, syndication,
        broadcast, distribution or publication of such Content on other media
        and services, subject to our terms and conditions for such Content use.
        Such additional and any uses by uDreamed, or other companies,
        organizations or individuals who partner with uDreamed, may be made with
        no compensation paid to you with respect to the Content that you record,
        associate, share, match, email, submit, post, transmit or otherwise make
        available through the Services. uDreamed may modify or adapt your
        Content as are necessary to conform and adapt that Content to any
        requirements or limitations of any networks, devices, services or media.
      </p>

      <p>
        You are responsible for your use of the Services, for any Content you
        provide, and for any consequences thereof, including the use of your
        Content by other users and our third party partners. You understand that
        your Content may be aggregated, syndicated, broadcast, distributed, or
        published by our partners and if you do not have the right to submit
        Content for such use, it may subject you to liability. uDreamed will not
        be responsible or liable for any use of your Content by uDreamed in
        accordance with these Terms. You represent and warrant that you have all
        the rights, power and authority necessary to grant the rights granted
        herein to any Content that you submit.
      </p>

      <p>
        All right, title, and interest in and to the Services (excluding Content
        provided by users) are and will remain the exclusive property of
        uDreamed. The Services are protected by copyright, trademark, and other
        laws of both the United States and foreign countries. Nothing in the
        Terms gives you a right to use the uDreamed name or any of the uDreamed
        trademarks, logos, domain names, and other distinctive brand features.
      </p>

      <p>
        We reserve the right at all times (but will not have an obligation) to
        remove or refuse to distribute any Content on the Services, to suspend
        or terminate users, and to reclaim usernames without liability to you.
        We also reserve the right to access, read, preserve, and disclose any
        information as we reasonably believe is necessary to (i) satisfy any
        applicable law, regulation, legal process or governmental request, (ii)
        enforce the Terms, including investigation of potential violations
        hereof, (iii) detect, prevent, or otherwise address fraud, security or
        technical issues, (iv) respond to user support requests, or (v) protect
        the rights, property or safety of uDreamed, its users and the public.
      </p>

      <p>
        You may end your legal agreement with uDreamed at any time for any
        reason by deactivating your accounts and discontinuing your use of the
        Services. You do not need to specifically inform uDreamed when you stop
        using the Services. If you stop using the Services without deactivating
        your accounts, your accounts may be deactivated due to prolonged
        inactivity under our Inactive Account Policy.
      </p>

      <p>
        We do not undertake responsibility for screening or monitoring materials
        provided or posted or displayed by our users, practitioners, bloggers,
        researchers, developers, advertisers, and merchants. Opinions, advice or
        content expressed by users or service providers on our sites and apps
        are their own and are not ours or endorsed by us.
      </p>

      <p>
        The Services offered by uDreamed on its sites and apps are not intended
        to be a substitute for professional advice. Under no circumstances will
        we be liable for any loss or damage caused by your reliance on
        information obtained through the Services, including but not limited to
        shared content, procedures, opinions, tests, dictionary content, blog
        postings, practitioner consultations and product purchases. It is your
        responsibility to evaluate the accuracy, completeness or usefulness of
        any information, opinion, advice or other content available offered in
        the Services. Please seek the advice of professionals, as appropriate,
        regarding the evaluation of any specific information, opinion, advice or
        other content. Never disregard professional advice, including medical
        advice, diagnosis, treatment, psychotherapy, counseling, or mental
        health services, or delay in seeking it, because of something you have
        read on uDreamed sites or apps.
      </p>

      <p>
        uDreamed does not endorse, and nothing in the Services we offer shall be
        deemed to be an endorsement, representation or warranty of, any third
        party (including practitioners, merchants, advertisers, users,
        developers, researchers and bloggers), whether in relation to such third
        party's products, services, content, procedures, opinions, tests,
        advice, apps, websites, experience or background or otherwise. uDreamed
        does not make any representations or warranties with regard to any
        materials posted by practitioners, merchants, advertisers, users,
        developers, researchers or bloggers. The information in our Consult and
        Shop services are provided exclusively by participating practitioners
        and merchants.
      </p>

      <p>
        uDreamed does not examine, determine, or warrant the competence of any
        practitioner or provider listed in the Consult directory. uDreamed does
        not warrant that the listings in the Consult directory are currently or
        properly licensed. Under no circumstances should the Consult directory
        be used to verify the credentials of practitioners.
      </p>

      <p>
        uDreamed may offer Users access to free or fee-based online training.
        Training may include live or archived audio and/or video presentation
        available on and accessible from uDreamed. All Users who participate in
        any online training offered by uDreamed do so on a completely voluntary
        basis. uDreamed’s provision of online training is based solely on
        uDreamed’s discretion.
      </p>

      <p>
        A new licensed practitioner who registers to provide consultation
        services on uDreamed pays a listing fee of $15 (USD) per month or $180
        per year, after six months of free listing. The practitioner
        acknowledges that the fee is subject to change, and that uDreamed
        reserves the right to revise rates and terms under this contract at any
        time. Practitioners may cancel their listing on uDreamed at any time
        without refund. Absolutely no refunds or pro-rated amounts will be given
        to practitioners who cancel their listing for any reason whatsoever.
        Requests to cancel listing is solely by email and subject to
        verification. If practitioner does not cancel during the free trial
        period, the practitioner will be billed at the end of the free trial
        period and at the beginning of each subsequent period.
      </p>

      <p>
        The practitioner authorizes uDreamed to automatically charge the fee to
        his or her credit card either one time per month or one time per 365
        days. The practitioner acknowledges that uDreamed does not save or
        maintain any credit card information on the uDreamed server and instead
        uses PayPal to store credit card information and process credit card
        transactions via a secure and encrypted SSL page to ensure the highest
        safety for all transactions. If a practitioner's account is left unpaid
        we will keep the practitioner's account active for up to 30 days from
        the time the fee payment transaction first failed. Members will continue
        to be responsible for paying their past due amount and any future past
        due amount that accrues, up to 30 days past due. If a practitioner's
        account is left unpaid for more than 30 days, we reserve the right to
        hire a third-party collection agency to collect the past due balance.
        When a practitioner's account goes to collections, we reserve the right
        to release all of the practitioner’s information to the collection
        agency.
      </p>

      <p>
        uDreamed provides a service to Users which permits the User to send a
        specific Practitioner emails through the uDreamed system. In such an
        event the email may be screened to try to identify possible spam or
        other content violating the uDreamed Terms of Use. Emails which violate
        the Terms of Use may not be forwarded to the Practitioner. Emails which
        pass the screening will be forwarded to the practitioner at the email
        address provided by the practitioner. Contact and all other information
        provided in such an email is as entered by the User and uDreamed cannot
        verify its truth, accuracy or completeness.{" "}
      </p>

      <p>
        Upon receiving a call, email message or other contact or query from a
        User, the decision to provide therapy or other services to that User,
        and the type and content of such services, is a matter for the
        professional judgment of the practitioner. Nothing in these Terms or any
        other documentation published by or on behalf of uDreamed on our
        websites or apps shall be construed or interpreted to permit the
        practitioner to use any of the Services provided by uDreamed for
        purposes of or as part of therapy or health care services for any User.
      </p>

      <p>
        Practitioner agrees to assume all liability for listing profiles, all
        other listings published, and consulting services provided to Users, and
        agrees to assume any and all responsibility for claims occurring
        therefrom against uDreamed.
      </p>

      <p>
        Practitioner agrees that all personal information shared by User before,
        during and after consultation are confidential and may not be
        transferred and shared without express written permission from the User.
        Practitioners shall not enter or store any personally identifiable
        information about any other User on uDreamed.
      </p>

      <p>
        When you purchase listing service, practitioner consultation,
        advertising or product on or through uDreamed, you agree to pay all
        amounts specified in the order, along with any applicable taxes. If you
        make a payment on uDreamed by credit or debit card, we may obtain a
        pre-approval from the issuer of the card for an amount, which may be as
        high as the full price of your purchase. We will bill your card at the
        time you make payment transaction, or shortly thereafter. If you cancel
        a transaction before completion, this pre-approval may result in those
        funds not otherwise being immediately available to you. When you make a
        purchase via the Ecommerce feature or Consult feature, you are
        purchasing a Product from a Merchant or Practitioner, and not uDreamed.
        When you use the uDreamed Payments service to pay for things on
        uDreamed, you agree that we may communicate with you electronically any
        important information regarding your purchases or your account. By
        choosing the PayPal billing payment system, you agree that we and PayPal
        may exchange information about you in order to facilitate completion or
        reversal of payments, resolution of disputes, provision of customer
        support, or other billing-related activity. To prevent financial loss to
        you or to us, we may contact your funding instrument issuer, law
        enforcement, or affected third parties (including other users) and share
        details of any payments you are associated with, if we believe doing so
        may prevent financial loss or a violation of law. We may update these
        Payments Terms at any time without notice as we deem necessary to the
        full extent permitted by law.{" "}
      </p>

      <p className="text-uppercase">
        TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, UDREAMED SHALL NOT BE
        LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE
        DAMAGES, OR ANY LOSS OF PROFITS OR REVENUES, WHETHER INCURRED DIRECTLY
        OR INDIRECTLY, OR ANY LOSS OF DATA, USE, GOOD-WILL, OR OTHER INTANGIBLE
        LOSSES, RESULTING FROM (i) YOUR ACCESS TO OR USE OF OR INABILITY TO
        ACCESS OR USE THE SERVICES; (ii) ANY CONDUCT OR CONTENT OF ANY THIRD
        PARTY ON THE SERVICES, INCLUDING WITHOUT LIMITATION, ANY DEFAMATORY,
        OFFENSIVE OR ILLEGAL CONDUCT OF OTHER USERS OR THIRD PARTIES; (iii) ANY
        CONTENT OBTAINED FROM THE SERVICES; OR (iv) UNAUTHORIZED ACCESS, USE OR
        ALTERATION OF YOUR TRANSMISSIONS OR CONTENT.
      </p>

      <p className="text-uppercase">
        IN NO EVENT SHALL THE AGGREGATE LIABILITY OF UDREAMED EXCEED THE GREATER
        OF ONE HUNDRED U.S. DOLLARS (U.S. $100.00) OR THE AMOUNT YOU PAID
        UDREAMED, IF ANY, IN THE PAST SIX MONTHS FOR THE SERVICES GIVING RISE TO
        THE CLAIM.
      </p>

      <p className="text-uppercase">
        THE LIMITATIONS OF THIS SUBSECTION SHALL APPLY TO ANY THEORY OF
        LIABILITY, WHETHER BASED ON WARRANTY, CONTRACT, STATUTE, TORT (INCLUDING
        NEGLIGENCE) OR OTHERWISE, AND WHETHER OR NOT UDREAMED HAVE BEEN INFORMED
        OF THE POSSIBILITY OF ANY SUCH DAMAGE, AND EVEN IF A REMEDY SET FORTH
        HEREIN IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE.
      </p>

      <p>
        We reserve the right to change this Terms of Use and Term of Service.
      </p>
    </div>
  );
}

export default TermsInfo;
