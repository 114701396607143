import React, { useState, useEffect } from "react";
import Swiper from "react-id-swiper";
import "swiper/css/bundle";
import "swiper/bundle";
import { Card, Placeholder } from "react-bootstrap";
import CustomCard from "../../../ui/CustomCard/CustomCard";
import { BsFillExclamationTriangleFill } from "react-icons/bs";

import DashboardExperienceCard from "../DashboardExperienceCard/DashboardExperienceCard";
import DashboardPieChart from "../DashboardCharts/DashboardPieChart";
import DashboardBarChart from "../DashboardCharts/DashboardBarChart";
import { bgcolors } from "../../../util/colors";

function EngagementRecordsContent(props) {
  const [labels, setLabels] = useState([]);
  const [datasets, setDatasets] = useState([]);
  const [fullDatasets, setFullDatasets] = useState([]);
  const [recordCount, setRecordCount] = useState([]);
  const [datasetLength, setDatasetLength] = useState(0);

  // const bgcolors = [
  //   "#81d4fa",
  //   "#26c6da",
  //   "#4db6ac",
  //   "#81c784",
  //   "#aed581",
  //   "#dce775",
  //   "#fff176",
  //   "#ffb74d",
  //   "#ff8a65",
  //   "#a1887f",
  //   "#90a4ae",
  //   "#42a5f5",
  //   "#7986cb",
  //   "#b39ddb",
  //   "#ba68c8",
  //   "#f48fb1",
  //   "#e57373",
  //   "#b39d9d",
  // ];

  useEffect(() => {
    console.log(
      "in useEffect of EngagementRecordsContent =",
      props.dashboardSharedGraphs
    );

    if (props.dashboardSharedGraphs?.length > 0) {
      createChartData(props.dashboardSharedGraphs);
    }
  }, [props.dashboardSharedGraphs]);

  function createChartData(data, dataLength = "full", chartOption = "bar") {
    console.log("chart_data in createChartdata =", data);
    console.log("dataLength in createChartdata =", dataLength);
    console.log("chartOption =", chartOption);

    let targetOption = "";
    let dataLabels = [];
    let dataRecordCount = [];
    let allDatasets = [];
    let allChartsData = [];

    if (data.length > 0) {
      data.map((item, index) => {
        console.log(`ITEM ${index} = `, item);

        let newLabels = [];
        let tempDatasets = [];
        let tempData = {};
        let targetOption = item.target ? item.target : "";
        let tempLegendList = [];

        //setting up labels and Total count for displaying graphs
        newLabels = item.chart_data.labels.map((label) => {
          //Capitalize first letter
          return label.charAt(0).toUpperCase() + label.slice(1);
        });

        dataLabels.push(newLabels);
        dataRecordCount.push(item.chart_data.total_no_of_records);

        //setting up actual datasets for displaying graphs
        if (item.chart_type === "wordcloud") {
          let values = null;
          console.log("---- I am going to execute ----");
          if (item.chart_data.values.hasOwnProperty("me")) {
            // console.log("I might going to be executed");
            values = item.chart_data.values.me;
          } else if (item.chart_data.values.hasOwnProperty("friends")) {
            values = item.chart_data.values.friends;
          } else if (item.chart_data.values.hasOwnProperty("global")) {
            values = item.chart_data.values.global;
          }
          tempDatasets = newLabels.map((label, index) => {
            return {
              text: label,
              value: values[index],
            };
          });
          // console.log("temp_words =", temp_words);
          console.log(
            "In if (chartOption == wordcloud) - tempDatasets =",
            tempDatasets
          );
        } else {
          console.log("I am in else I have more chance to be executed");
          if (item.chart_data.values.hasOwnProperty("me")) {
            console.log("MeDataSet = ", item.chart_data.values.me);

            if (
              targetOption !== "me_friends" &&
              targetOption !== "me_global" &&
              targetOption !== "me_friends_global"
            ) {
              tempLegendList = item.chart_data.values.me.map((item, index) => {
                return {
                  color: bgcolors[index],
                  value: item,
                  label: newLabels[index],
                };
              });
            }
            tempDatasets.push({
              label: "Me",
              // data: data.values.me,
              data: item.chart_data.values.me,
              barPercentage: 1,
              // barThickness: 10,
              maxBarThickness: 10,
              // minBarLength: 4,
              // borderColor: "rgb(0, 153, 255)",
              // backgroundColor: "rgba(0, 153, 255, 0.5)",
              backgroundColor:
                targetOption == "me_friends" ||
                targetOption == "me_global" ||
                targetOption == "me_friends_global"
                  ? "rgba(0, 153, 255, 0.5)"
                  : bgcolors,
              // backgroundColor: bgcolors,
              hoverBorderRadius: 4,
              hoverBorderColor: "rgba(0,0,0,0.4)",
              // hoverBackgroundColor: "rgba(0, 153, 255, 0.7)",
            });
          }
          if (item.chart_data.values.hasOwnProperty("friends")) {
            console.log("friendsDataSet = ", item.chart_data.values.friends);

            if (
              targetOption !== "me_friends" &&
              targetOption !== "me_global" &&
              targetOption !== "me_friends_global"
            ) {
              tempLegendList = item.chart_data.values.friends.map(
                (item, index) => {
                  return {
                    color: bgcolors[index],
                    value: item,
                    label: newLabels[index],
                  };
                }
              );
            }
            tempDatasets.push({
              label: "Friends",
              // data: data.values.friends,
              data: item.chart_data.values.friends,
              barPercentage: 1,
              // barThickness: 10,
              maxBarThickness: 10,
              // minBarLength: 4,
              // borderColor: "rgb(255, 204, 0)",
              backgroundColor:
                targetOption == "me_friends" ||
                targetOption == "me_global" ||
                targetOption == "me_friends_global"
                  ? "rgba(255, 204, 0, 0.7)"
                  : bgcolors,
              // backgroundColor: bgcolors,
              hoverBorderRadius: 4,
              hoverBorderColor: "rgba(0,0,0,0.4)",
              // hoverBackgroundColor: "rgba(255, 204, 0, 0.85)",
            });
          }
          if (item.chart_data.values.hasOwnProperty("global")) {
            console.log("globalDataSet = ", item.chart_data.values.global);

            if (
              targetOption !== "me_friends" &&
              targetOption !== "me_global" &&
              targetOption !== "me_friends_global"
            ) {
              tempLegendList = item.chart_data.values.global.map(
                (item, index) => {
                  return {
                    color: bgcolors[index],
                    value: item,
                    label: newLabels[index],
                  };
                }
              );
            }

            tempDatasets.push({
              label: "Global",
              // data: data.values.global,
              data: item.chart_data.values.global,
              barPercentage: 1,
              // barThickness: 10,
              maxBarThickness: 10,
              // minBarLength: 4,
              // borderColor: "rgb(51, 204, 102)",
              backgroundColor:
                targetOption == "me_friends" ||
                targetOption == "me_global" ||
                targetOption == "me_friends_global"
                  ? "rgba(51, 204, 102, 0.5)"
                  : bgcolors,
              // backgroundColor: bgcolors,
              hoverBorderRadius: 4,
              hoverBorderColor: "rgba(0,0,0,0.4)",
              // hoverBackgroundColor: "rgba(51, 204, 102, 0.7)",
            });
          }
        }
        console.log("------ tempDatasets = ", tempDatasets);

        allDatasets.push(tempDatasets);

        console.log("------ allDatasets = ", allDatasets);

        allChartsData.push({
          chart_type: item.chart_type,
          labels: newLabels,
          datasets: tempDatasets,
          total_records: item.chart_data.total_no_of_records,
          chart_title: item.title,
          legend_list: tempLegendList,
        });
      });

      setLabels(dataLabels);
      setRecordCount(dataRecordCount);
      if (allDatasets.length > 0) {
        setDatasets(allDatasets);
      }
      if (allChartsData.length > 0) {
        setFullDatasets(allChartsData);
      }
    }

    console.log("---- Labels =", labels);
    console.log("---- RecordCount =", recordCount);
    console.log("---- Datasets =", datasets);
    console.log("---- ALL Datasets =", fullDatasets);
  }

  return (
    <>
      <div className="">
        <div class="section_title mb2">Shared Records</div>
        <div className="position-relative pb-3">
          <Swiper
            {...props.swiperParams}
            containerClass="swiper-container theme_swiper"
          >
            {props.shareExpbankData.length > 0 &&
              props.shareExpbankData.map((exp) => (
                <div key={exp.record_id}>
                  <DashboardExperienceCard exp={exp} />
                </div>
              ))}
          </Swiper>
        </div>
        <hr />
        <div class="section_title mb2">Shared Graphs</div>

        {props.loadingShared ? (
          <>
            <CustomCard
              className={`main_card shadow1 border-0 mb2 text-left overflow-hidden`}
            >
              <Card.Body className="p2">
                <div className="w-100 mb-2">
                  <Placeholder xs={6} bg="secondary" />
                </div>
                <div className="w-100 mb1">
                  <Placeholder as="div" animation="glow">
                    <Placeholder
                      xs={12}
                      style={{ height: "200px" }}
                      bg="secondary"
                      as="div"
                    />
                  </Placeholder>
                </div>
              </Card.Body>
            </CustomCard>
          </>
        ) : (
          <>
            {props.dashboardSharedGraphs.length > 0 ? (
              <>
                <div className="position-relative pb-3">
                  <Swiper
                    {...props.swiperParams}
                    containerClass="swiper-container theme_swiper"
                  >
                    {fullDatasets.length > 0 &&
                      fullDatasets.map((dataset, index) => (
                        <div key={index} className="border-0 pb2 pt-0 px-0 mb2">
                          {dataset.chart_type === "pie" ? (
                            <DashboardPieChart
                              labels={dataset.labels}
                              datasets={dataset.datasets}
                              recordCount={dataset.total_records}
                              chart_title={dataset.chart_title}
                              legend_list={dataset.legend_list}
                            />
                          ) : (
                            <DashboardBarChart
                              labels={dataset.labels}
                              datasets={dataset.datasets}
                              recordCount={dataset.total_records}
                              chart_title={dataset.chart_title}
                              legend_list={dataset.legend_list}
                            />
                          )}
                        </div>
                      ))}
                  </Swiper>
                </div>
              </>
            ) : (
              <>
                <CustomCard className="shadow1 border-0 text-center">
                  <Card.Body>
                    <div className="mb1">
                      <BsFillExclamationTriangleFill
                        style={{
                          color: "#d3af61",
                          fontSize: "2.2rem",
                        }}
                      />
                    </div>
                    <b>No more records found...</b>
                  </Card.Body>
                </CustomCard>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
}

export default EngagementRecordsContent;
