import React from "react";
import { Card } from "react-bootstrap";
import Register from "../Register/Register";
import "./HomeSignupBanner.css";

function HomeSignupBanner() {
  return (
    <>
      <section className={`split_banner signup_page`}>
        <div
          className={`split_item split_item_70 bg_grad_primary_to_secondary pt8 pb8`}
        >
          <div className={`split_item_content`}>
            <div>
              <h1>
                uDreamed enables you to use your dreams to{" "}
                <span className={`line1`}>improve your life.</span>
              </h1>
              <div className={`dream_types_wrap`}>
                <Card className={`card_dream_type border-0`}>
                  <div className={`icon_wrap rounded-circle`}>
                    <img
                      src={`${process.env.PUBLIC_URL}/img/events-icon.png`}
                      alt="Events Icon"
                    />
                  </div>
                  <p className="text-uppercase">Events</p>
                </Card>

                <Card className={`card_dream_type border-0`}>
                  <div className={`icon_wrap rounded-circle`}>
                    <img
                      src={`${process.env.PUBLIC_URL}/img/dreams-icon.png`}
                      alt="Dreams Icon"
                    />
                  </div>
                  <p className="text-uppercase">Dreams</p>
                </Card>
                <Card className={`card_dream_type border-0`}>
                  <div className={`icon_wrap rounded-circle`}>
                    <img
                      src={`${process.env.PUBLIC_URL}/img/thoughts-icon.png`}
                      alt="Thoughts Icon"
                    />
                  </div>
                  <p className="text-uppercase">Thoughts</p>
                </Card>
                <Card className={`card_dream_type border-0`}>
                  <div className={`icon_wrap rounded-circle`}>
                    <img
                      src={`${process.env.PUBLIC_URL}/img/experience-icon.png`}
                      alt="Experience Icon"
                    />
                  </div>
                  <p className="text-uppercase">Experience</p>
                </Card>
              </div>
            </div>
          </div>
        </div>
        <div className={`split_item split_item_30`}>
          <div className="card form_style form_card">
            {/* <div>
              The href attribute requires a valid value to be accessible.
              Provide a valid, navigable address as the href value.{" "}
            </div> */}
            <Register />
          </div>
        </div>
      </section>
    </>
  );
}

export default HomeSignupBanner;
