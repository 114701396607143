import React from "react";
import { Card, Button } from "react-bootstrap";
import CustomCard from "../../../ui/CustomCard/CustomCard";
import { BsFillExclamationTriangleFill, BsFillTrashFill } from "react-icons/bs";

function CommentCard(props) {
  const deleteBtn =
    (props.comment_type === "group" &&
      props.authUser.user_id === props.parent_owner_id) ||
    (props.comment_type === "thread" &&
      props.authUser.user_id === props.parent_owner_id) ||
    (props.comment_type === "consult" &&
      props.authUser.user_id === props.parent_owner_id) ||
    // (props.comment_type !== "group" &&
    //   props.authUser.user_id === props.comment.user_id) ||
    (props.comment_type === "record" &&
      props.authUser.user_id === props.parent_owner_id) ? (
      <Button
        onClick={() => {
          props.handleShow(props.comment);
        }}
        className={`btn_theme btn_icon btn_auto_width btn_danger btn_circle btn_favorite me-1 align-self-start position-absolute`}
        style={{ right: "5px", top: "8px" }}
        type="button"
      >
        <BsFillTrashFill />
      </Button>
    ) : null;

  const avatar =
    props.comment?.user?.avatar !== "NULL"
      ? props.comment?.user?.avatar
      : `${process.env.PUBLIC_URL}/img/user-profile.jpg`;

  return (
    <>
      <CustomCard className={`comment_card border-1 mb-0 overflow-hidden`}>
        {deleteBtn}

        <Card.Body className="pb-2">
          <p className="mb0 font-15 ps-1">{props.comment?.comment}</p>
          {/* <p className="mb0 font-15 ps-1">{props.comment?.comment_id}</p> */}
        </Card.Body>
        <Card.Header className="bg_transparent border-0 pt-2">
          <div className="media">
            <div className="media_icon_wrap d-flex justify-content-center align-items-center align-self-start">
              <img
                // src={props.comment?.user.avatar}
                src={avatar}
                className="rounded-circle media_img"
                alt={`${props.comment?.user.fullname} profile`}
              />
            </div>
            <div className="media_body ms-2 d-flex justify-content-between align-items-center">
              <div class="card-title mb-0 font-13">
                {props.comment?.user.fullname}
              </div>
              <div class="card-subtitle font-13">
                {props.comment?.time_lapsed}
              </div>
            </div>
          </div>
        </Card.Header>
      </CustomCard>
    </>
  );
}

export default CommentCard;
