import React from "react";
import { Card } from "react-bootstrap";
import CustomCard from "../CustomCard/CustomCard";
import "./EmojiIconCard.css";

function EmojiIconCard(props) {
  const iconGroup = props.iconGroup;
  const iconImgName = props.iconName.toLowerCase().split(" ").join("-");
  // console.log("Icon Name =", props.iconName);
  // console.log("Icon Imag Name =", iconImgName);
  const iconImgUrl = `${process.env.PUBLIC_URL}/img/emoji/${iconGroup}/${iconImgName}.png`;
  const iconName = props.iconName;

  return (
    <>
      <CustomCard className="icon_card border-0 mb1">
        <Card.Body>
          <img
            src={iconImgUrl}
            className="img-fluid mx-auto"
            alt={`${iconName} Icon`}
          />
          <div className="icon_title text-capitalize">{iconName}</div>
        </Card.Body>
      </CustomCard>
    </>
  );
}

export default EmojiIconCard;
