import React from "react";
import { Link } from "react-router-dom";
import { Card } from "react-bootstrap";
import styles from "./WebUtilityCard.module.css";

function WebUtilityCard(props) {
  const item = props.itemData;

  return (
    <>
      {(() => {
        switch (item.type) {
          case "press":
            return (
              <Card
                className={`${styles.web_utility_card} ${styles.press_card} border-0`}
              >
                <div className={styles.card_img}>
                  <Card.Img
                    variant="top"
                    src={`${process.env.PUBLIC_URL}/img/${item.mainImage}`}
                  ></Card.Img>
                </div>
                <div className="card-body">
                  <div className={styles.quote_wrap}>
                    <img
                      src={`${process.env.PUBLIC_URL}/img/${item.typeIcon}`}
                      alt={`${item.type} icon`}
                    />
                  </div>
                  <h5>
                    <Link to="/press-details">{item.title}</Link>
                  </h5>
                </div>
                <Card.Footer>
                  <div className="user_title">
                    <strong>{item.publication}</strong>
                  </div>
                  <div className="user_info">{item.date}</div>
                </Card.Footer>
              </Card>
            );
          case "blog":
            return (
              <Card
                className={`${styles.web_utility_card} ${styles.blog_card} border-0`}
              >
                <div className={styles.card_img}>
                  <Card.Img
                    variant="top"
                    src={`${process.env.PUBLIC_URL}/img/${item.mainImage}`}
                  ></Card.Img>
                </div>
                <Card.Body>
                  <div className={styles.quote_wrap}>
                    <span className={styles.date}>Aug 18</span>
                    <span className={styles.year}>2020</span>
                  </div>
                  <h5>
                    <Link to="/blog-details">{item.title}</Link>
                  </h5>
                  <Card.Text>{item.shortDesc}</Card.Text>
                </Card.Body>
                <Card.Footer className="pt2">
                  <Link
                    to="/blog-details"
                    className="btn_theme btn_link btn_primary fw_n"
                  >
                    Read More
                  </Link>
                </Card.Footer>
              </Card>
            );
          case "testimonial":
            return (
              <Card
                className={`${styles.web_utility_card} ${styles.testimonial_card} border-0`}
              >
                <div className={styles.card_img}>
                  <Card.Img
                    variant="top"
                    src={`${process.env.PUBLIC_URL}/img/${item.mainImage}`}
                  ></Card.Img>
                </div>
                <div className="card-body">
                  <div className={styles.quote_wrap}>
                    <img
                      src={`${process.env.PUBLIC_URL}/img/${item.typeIcon}`}
                      alt={`${item.type} icon`}
                    />
                  </div>
                  <p>{item.desc}</p>
                </div>
                <Card.Footer>
                  <div className="user_title">
                    <strong>{item.user}</strong>
                  </div>
                  <div className="user_info">{item.userInfo}</div>
                </Card.Footer>
              </Card>
            );
        }
      })()}
    </>
  );
}

export default WebUtilityCard;
