import React from "react";
import { Card, Nav, Tab, Row, Col, Form } from "react-bootstrap";
import PlaceholderCard from "../../../ui/PlaceholderCard/PlaceholderCard";
import SimpleMessageCard from "../../../ui/SimpleMessageCard/SimpleMessageCard";
import SubscriptionPlanCard from "./SubscriptionPlanCard";

function SubscriptionPlansList(props) {
  return (
    <>
      {!props.loading && props.subscriptionPlans?.length > 0 ? (
        <Row className="row-cols-1 row-cols-sm-2 g-3 mt-1">
          {props.subscriptionPlans.map((plan) => (
            <Col key={plan.plan_id}>
              <SubscriptionPlanCard
                plan={plan}
                // authUser={authUser}
                // getAllConsultingSessions={getAllConsultingSessions}
              />
            </Col>
          ))}
        </Row>
      ) : (
        <>
          {props.loading ? (
            <>
              {[0, 1].map((_, i) => (
                <PlaceholderCard key={i} />
              ))}
            </>
          ) : (
            <SimpleMessageCard type="warning" />
          )}
        </>
      )}
    </>
  );
}

export default SubscriptionPlansList;
