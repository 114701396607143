import { toast } from "react-toastify";

const handleAlert = (msg = "Default message!", type) => {
  // console.log("Inside util.js : msg : ", msg);

  if (type === "success") {
    toast(msg, {
      type: toast.TYPE.SUCCESS,
    });
  }
  if (type === "error") {
    toast(msg, {
      type: toast.TYPE.ERROR,
    });
  }
};

const handleThemeChange = (theme, setTheme) => {
  if (theme === "dark") {
    document.body.classList.add("dark_theme");
  } else {
    document.body.classList.remove("dark_theme");
  }
  setTheme(theme);
  localStorage.setItem("theme", theme);
};

// Date formatting: yyyy-mm-dd
const formatDateYMD = (d) => {
  return [d.getFullYear(), d.getMonth() + 1, d.getDate()]
    .map((n) => (n < 10 ? `0${n}` : `${n}`))
    .join("-");
};

//Calculate age > 13
function calculateAge(birthday) {
  let ageDifMs = Date.now() - birthday;
  let ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
}

//React select styles
function handleCustomSelectStyles(theme) {
  return {
    control: (provided, state) => ({
      ...provided,
      border: theme === "dark" ? "1px solid #666" : "1px solid #ddd",
      borderRadius: "30px",
      minHeight: "48px",
      backgroundColor: theme === "dark" ? "#333" : "#fff",
      color: "inherit",
      // color: theme === "dark" ? "#fff" : '#333',
      "&:focus": {
        borderWidth: "1px",
        borderColor: "#39b3ed",
        boxShadow: "none",
      },
      "&:hover": {
        borderColor: theme === "dark" ? "#666" : "#ddd",
      },
    }),
    input: (provided) => ({
      ...provided,
      color: "inherit",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "inherit",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "inherit",
    }),
    menuList: (provided) => ({
      ...provided,
      backgroundColor: theme === "dark" ? "#444" : "#fff",
      // kill the white space on first and last option
      padding: 0,
      zIndex: 1,
    }),
    option: (provided, { isFocused, isSelected }) => ({
      ...provided,
      background: isFocused ? "#999" : isSelected ? "#0991f6" : undefined,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      padding: "2px 12px",
      color: "inherit",
      // zIndex: 1
    }),
  };
}

export {
  handleAlert,
  handleThemeChange,
  formatDateYMD,
  calculateAge,
  handleCustomSelectStyles,
};
