import React, { useState, useEffect, useContext } from "react";
import {
  Card,
  Row,
  Col,
  Form,
  InputGroup,
  Button,
  Spinner,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { useHistory, useNavigate} from "react-router-dom";
import CustomCard from "../../ui/CustomCard/CustomCard";
import { Field, Formik } from "formik";
import * as yup from "yup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
// import {COUNTRIES } from "../../util/countries"
import { COUNTRIES } from "../../util/countries";
import { handleAlert, formatDateYMD, calculateAge } from "../../util/util";

//Service
import recordService from "../../services/Record";
import authService from "../../services/Auth";
import FieldError from "../../ui/FieldError/FieldError";
import ThemeContext from "../../ThemeContext";

function SocialUserRegister(props) {
  // let history = useHistory();
  const navigate = useNavigate();
  const { theme } = useContext(ThemeContext);
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState({});
  const isAddMode = props.isAddMode;

  //React select styles
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: theme === "dark" ? "1px solid #666" : "1px solid #ddd",
      borderRadius: "30px",
      minHeight: "48px",
      backgroundColor: theme === "dark" ? "#333" : "#fff",
      color: "inherit",
      // color: theme === "dark" ? "#fff" : '#333',
      "&:focus": {
        borderWidth: "1px",
        borderColor: "#39b3ed",
        boxShadow: "none",
      },
      "&:hover": {
        borderColor: theme === "dark" ? "#666" : "#ddd",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "inherit",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#555",
    }),
    menuList: (provided) => ({
      ...provided,
      backgroundColor: theme === "dark" ? "#444" : "#fff",
      // kill the white space on first and last option
      padding: 0,
    }),
    option: (provided, { isFocused, isSelected }) => ({
      ...provided,
      background: isFocused ? "#999" : isSelected ? "#0991f6" : undefined,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      padding: "2px 12px",
      color: "inherit",
      // color: theme === "dark" ? "#fff" : '#333',
    }),
  };

  const countryOptions = COUNTRIES.map((country) => {
    return { label: country.name, value: country.name };
  });
  const genderOptions = [
    { key: "Male", value: "male" },
    { key: "Female", value: "female" },
    { key: "Would rather not disclose", value: "other" },
  ];

  const initialValues = {
    email: "",
    firstname: "",
    lastname: "",
    date_of_birth: null,
    country: "",
    gender: "Male",
    phone_no: "",
  };

  const socialUserRegisterSchema = yup.object({
    email: yup.string().email("Invalid email format").required("Required"),
    firstname: yup.string().required("Required"),
    lastname: yup.string().required("Required"),
    date_of_birth: yup
      .date()
      .required("Required")
      .nullable()
      .test(
        "date_of_birth",
        "Should be greater than 13 years",
        function (value) {
          return calculateAge(new Date(value)) > 13;
        }
      ),
    gender: yup.string().required("Required"),
    country: yup
      .string()
      .transform((_, input) => {
        // console.log('input = ',input);
        return input != null && input.toString ? input.toString() : "";
      })
      .required("Required"),
    phone_no: yup.string().required("Required"),
  });

  useEffect(() => {
    if (!isAddMode && props.currentUser) {
      let setting_values = {
        email: props.currentUser.user.email ? props.currentUser.user.email : "",
        firstname: props.currentUser.user.firstname
          ? props.currentUser.user.firstname
          : "",
        lastname: props.currentUser.user.lastname
          ? props.currentUser.user.lastname
          : "",
        phone_no: props.currentUser.user.phone_no
          ? props.currentUser.user.phone_no
          : "",
      };

      setFormValues(setting_values);
    }
  }, [props.currentUser]);

  const onSubmit = async (values, { resetForm }) => {
    setLoading(true);
    console.log("Form Values :", values);

    const new_country = Array.isArray(values.country)
      ? values.country[0].value
      : values.country.value;
    const new_date_of_birth = formatDateYMD(values.date_of_birth);

    const newValues = {
      ...values,
      country: new_country,
      date_of_birth: new_date_of_birth,
    };
    console.log("New values in Update Settings =", newValues);

    // if (!isAddMode) {
    console.log("Updating user details trying to register with Social media");
    recordService
      .updateUserDetails(newValues)
      .then((response) => {
        if (response.data.status === "success") {
          console.log(response.data);
          handleAlert("You have successfully updated user details!", "success");
          // setFormValues({});
          resetForm({});
          setLoading(false);
          console.log("currentUser = ", props.currentUser);
          let result = {
            ...response.data.data.result,
            token: props.currentUser.token,
          };
          console.log("Social media login/register created result =", result);
          authService.setToken(result);
          props.handleClose();
          // history.push(`/dashboard`);
          navigate("/dashboard");
          // props.getUserDetails();
        } else if (response.data.status === "failed") {
          console.log("ERROR:", response.data.error);
          handleAlert("User details updation failed!", "error");
          // setFormValues({});
          resetForm({});
          setLoading(false);
          props.handleClose();
          // props.getUserDetails();
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // }
  };

  return (
    <>
      <CustomCard className={`main_card shadow1 border-0 mt2 mb2`}>
        <Card.Body>
          <Formik
            initialValues={isAddMode ? initialValues : formValues}
            validationSchema={socialUserRegisterSchema}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {(formik) => {
              // console.log("Formik vals = ", formik.values);
              return (
                <Form className="form_style" onSubmit={formik.handleSubmit}>
                  <Form.Group className="form-group rounded_corners">
                    <Form.Control
                      type="text"
                      placeholder="Email"
                      name="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      autoComplete="off"
                    />
                    <Form.Label className="label_float">Email</Form.Label>
                    <FieldError
                      valid={
                        formik.touched.email && formik.errors.email
                          ? true
                          : false
                      }
                      text={formik.errors.email}
                    />
                  </Form.Group>
                  <Form.Group className="form-group rounded_corners">
                    <Form.Control
                      type="text"
                      placeholder="First name"
                      name="firstname"
                      {...formik.getFieldProps("firstname")}
                      autoComplete="off"
                    />
                    <Form.Label className="label_float">First name</Form.Label>
                    <FieldError
                      valid={
                        formik.touched.firstname && formik.errors.firstname
                          ? true
                          : false
                      }
                      text={formik.errors.firstname}
                    />
                  </Form.Group>
                  <Form.Group className="form-group rounded_corners">
                    <Form.Control
                      type="text"
                      placeholder="Last name"
                      name="lastname"
                      {...formik.getFieldProps("lastname")}
                      autoComplete="off"
                    />
                    <Form.Label className="label_float">Last name</Form.Label>
                    <FieldError
                      valid={
                        formik.touched.lastname && formik.errors.lastname
                          ? true
                          : false
                      }
                      text={formik.errors.lastname}
                    />
                  </Form.Group>

                  <OverlayTrigger
                    trigger={["focus", "click"]}
                    placement="top"
                    rootClose={true}
                    overlay={
                      <Tooltip id="tooltip-dob" className="custom_tooltip">
                        Knowing user's precise birthdays helps us provide all
                        users with accurate analytics regarding particular
                        demographic groups.
                      </Tooltip>
                    }
                  >
                    <div className="text_primary_dark text-end">
                      <small>
                        <i>Why do we collect?</i>
                      </small>
                    </div>
                  </OverlayTrigger>

                  <Form.Group className="form-group rounded_corners">
                    {/* <div className="text_mediumgray mb1">Date of birth</div> */}
                    <Field name="date_of_birth" placeholder="Date of birth">
                      {({ form, field }) => {
                        const { setFieldValue } = form;
                        const { value } = field;
                        // console.log('field =',field)
                        return (
                          <DatePicker
                            id="date_of_birth"
                            className="form-control"
                            {...field}
                            selected={value}
                            onChange={(val) =>
                              setFieldValue("date_of_birth", val)
                            }
                            dateFormat="yyyy-MM-dd"
                            placeholderText="Date of birth"
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            autoComplete="off"
                            inputMode="none"
                            onFocus={(e) => {
                              // console.log(e)
                              e.target.blur();
                            }}
                          />
                        );
                      }}
                    </Field>
                    <div className="text-end text_gray9">
                      <small>* Age greater than 13 years</small>
                    </div>
                    <FieldError
                      valid={
                        formik.touched.date_of_birth &&
                        formik.errors.date_of_birth
                          ? true
                          : false
                      }
                      text={formik.errors.date_of_birth}
                    />
                  </Form.Group>
                  <Form.Group className="form-group rounded_corners px-2 inline_radio_fields">
                    <div className="text_gray3 fw_sb mb1 position-relative">
                      Gender
                      <OverlayTrigger
                        trigger={["focus", "click"]}
                        placement="top"
                        rootClose={true}
                        overlay={
                          <Tooltip
                            id="tooltip-gender"
                            className="custom_tooltip"
                          >
                            Knowing user's precise gender helps us provide all
                            users with accurate analytics regarding particular
                            demographic groups.
                          </Tooltip>
                        }
                      >
                        <span className="text_primary_dark fw_n text-end position-absolute end-0">
                          <small>
                            <i>Why do we collect?</i>
                          </small>
                        </span>
                      </OverlayTrigger>
                    </div>
                    <div className="mb-3">
                      {genderOptions.map((option) => {
                        return (
                          <Form.Check
                            key={option.value}
                            inline
                            label={option.key}
                            name="gender"
                            type="radio"
                            id={option.value}
                            {...formik.getFieldProps("gender")}
                            value={option.value}
                            checked={formik.values?.gender == option.value}
                          />
                        );
                      })}
                    </div>
                    <FieldError
                      valid={
                        formik.touched.gender && formik.errors.gender
                          ? true
                          : false
                      }
                      text={formik.errors.gender}
                    />
                  </Form.Group>
                  <Form.Group className="form-group rounded_corners">
                    <Select
                      styles={customStyles}
                      placeholder="Select Country"
                      value={formik.values.country}
                      onChange={(value) => {
                        // console.log('Country: ', value)
                        formik.setFieldValue("country", value);
                      }}
                      onBlur={() => {
                        formik.setFieldTouched("country", true);
                      }}
                      isMulti={false}
                      options={countryOptions}
                      name="country"
                    />
                    <FieldError
                      valid={
                        formik.touched.country && formik.errors.country
                          ? true
                          : false
                      }
                      text={formik.errors.country}
                    />
                  </Form.Group>
                  <Form.Group className="form-group rounded_corners">
                    <Form.Control
                      type="text"
                      placeholder="Mobile"
                      name="phone_no"
                      {...formik.getFieldProps("phone_no")}
                      autoComplete="off"
                    />
                    <FieldError
                      valid={
                        formik.touched.phone_no && formik.errors.phone_no
                          ? true
                          : false
                      }
                      text={formik.errors.phone_no}
                    />
                  </Form.Group>

                  <Button
                    type="submit"
                    disabled={!formik.isValid}
                    className="btn_theme btn_theme_gradient btn_rounded btn_block mb1"
                  >
                    {loading ? (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />{" "}
                        Loading
                      </>
                    ) : !formik.isValid ? (
                      "Please fill out all info"
                    ) : (
                      "Signup"
                    )}
                  </Button>
                </Form>
              );
            }}
          </Formik>
        </Card.Body>
      </CustomCard>
    </>
  );
}

export default SocialUserRegister;
