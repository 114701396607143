import React from "react";
import "./HomeSectionHeading.css";

function HomeSectionHeading({ htmlTag, type, titleText, className }) {
  let typeClass;
  switch (type) {
    case "el":
      typeClass = "heading_extra_large";
      break;
    case "l":
      typeClass = "heading_large";
      break;
    case "m":
      typeClass = "heading_medium";
      break;
    case "sm":
      typeClass = "heading_small";
      break;
    case "esm":
      typeClass = "heading_extra_small";
      break;
    default:
      typeClass = "";
  }

  const classList =
    "section_heading " + (className ? `${className}` : "") + " " + typeClass;
  return (
    <>
      {(() => {
        switch (htmlTag) {
          case "h1":
            return <h1 className={classList}>{titleText}</h1>;
          case "h2":
            return <h2 className={classList}>{titleText}</h2>;
          case "h3":
            return <h3 className={classList}>{titleText}</h3>;
          case "h4":
            return <h4 className={classList}>{titleText}</h4>;
          case "h5":
            return <h5 className={classList}>{titleText}</h5>;
          case "h6":
            return <h6 className={classList}>{titleText}</h6>;
          default:
            return <div className={classList}>{titleText}</div>;
        }
      })()}
    </>
  );
}

export default HomeSectionHeading;
