import React from "react";
import {
  Card,
  Spinner,
  Placeholder,
  Button,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import CustomCard from "../../../ui/CustomCard/CustomCard";
import "./GroupDetails.css";
import PlaceholderCard from "../../../ui/PlaceholderCard/PlaceholderCard";

//Services
import authService from "../../../services/Auth";

function GroupDetails(props) {
  console.log('Group details - props = ',props?.groupDetails)
  const authUser = JSON.parse(authService.getAuthUser());
  const title = props.groupDetails.group?.title
    ? props.groupDetails.group.title
    : "";
  const description = props.groupDetails.group?.description
    ? props.groupDetails.group.description
    : "";
  const privacy = props.groupDetails.group?.privacy
    ? props.groupDetails.group.privacy
    : "";
  const total_members = props.groupDetails.group?.user_count
    ? props.groupDetails.group.user_count
    : 0;
  const members = props.groupDetails.users ? props.groupDetails.users : [];

  const group_owner_id = props.groupDetails.group?.owner_id
    ? props.groupDetails.group.owner_id
    : "NA";

  return (
    <>
      {!props.groupDetails.group?.hasOwnProperty("user_group_id") && (
        <>
          <PlaceholderCard type="details" />
        </>
      )}

      {title && (
        <div className="media mb1 p1 bg_lightgray">
          <div className="media_body fw_sb font-15">{title}</div>
          <OverlayTrigger
            trigger={["hover", "focus"]}
            placement="top"
            rootClose={true}
            overlay={
              <Tooltip id="tooltip-dob" className="custom_tooltip">
                {privacy === "public" && "Anyone can join"}
                {privacy === "invite" && "A host must send you an invitation"}
                {privacy === "approval" && (
                  <>
                    A new user can submit a request to join and a host can
                    approve or reject their request
                  </>
                )}
              </Tooltip>
            }
          >
            <span className="custom_tag tag_purple mb-0">
              {privacy === "public" && "Public"}
              {privacy === "invite" && "Invite only"}
              {privacy === "approval" && "Request only"}
            </span>
          </OverlayTrigger>
          {/* <span className=" tag_warning me-1">Public</span> */}
        </div>
      )}
      <div className="bg_lightgray p1 mb1">
        {description && (
          <>
            <div className="">
              <div className="me-1 mb-1 fw_b">Group Info:</div>
              <div>{description}</div>
            </div>
          </>
        )}

        {/* {total_members && (
        <>
          <DashboardSectionTitle
            iconImg=""
            title="Members"
            pageTitle={false}
            className="small_title mb1"
          />
          <div className="bg_lightgray p1 mb2">{total_members}</div>
        </>
      )} */}
      </div>
      {members.length > 0 && (
        <>
          <div className="p1 fw_b bg_primary_light">
            Total Members:
            <span className="ms-2 custom_tag tag_small tag_primary">
              {total_members}
            </span>
          </div>
          <div className="p1 member_list_wrap">
            {members.map((item, index) => {
              return (
                <>
                  <CustomCard key={index} className="border-0 member_list_card">
                    <Card.Header className="bg_transparent">
                      <div className="media">
                        <div className="media_img_wrap d-flex align-items-center justify-content-center">
                          <img
                            src={(item.avatar !== "NULL" && item.avatar)  ||  `${process.env.PUBLIC_URL}/img/user-profile.jpg`}
                            className="rounded-circle media_img"
                            alt={`${item.firstname} profile pic`}
                          />
                        </div>
                        <div className={`media_body ms-3`}>
                          <Card.Title className="card_title">
                            {`${item.firstname} ${item.lastname}`}{" "}
                            {group_owner_id === item.user_id && (
                              <span className="ms-2 custom_tag tag_success tag_small">
                                admin
                              </span>
                            )}
                          </Card.Title>
                          <Card.Subtitle className="card_subtitle">
                            {`${
                              item.country !== "NULL" && item.country  ? `Lives in ${item.country}` : ""
                            }`}
                          </Card.Subtitle>
                        </div>
                        {authUser.user_id === group_owner_id &&
                          group_owner_id !== item.user_id && (
                            <Button
                              data-id={item.user_id}
                              onClick={props.handleShow}
                              className={`btn_theme btn_rounded btn_auto_width btn_small btn_danger me-1`}
                              type="button"
                            >
                              Remove
                            </Button>
                            // <Button
                            //   data-id={item.user_id}
                            //   onClick={props.handleShow}
                            //   className={`btn_theme btn_icon btn_auto_width btn_danger btn_circle btn_favorite me-1`}
                            //   type="button"
                            // >
                            //   <BsFillTrashFill />
                            // </Button>
                          )}{" "}
                        {authUser.user_id === item.user_id &&
                          authUser.user_id !== group_owner_id && (
                            <Button
                              data-id={item.user_id}
                              onClick={props.handleShow2}
                              // onClick={}
                              className={`btn_theme btn_rounded btn_auto_width btn_small btn_danger me-1`}
                              type="button"
                            >
                              Leave
                            </Button>
                          )}
                      </div>
                    </Card.Header>
                  </CustomCard>
                </>
              );
            })}
          </div>
        </>
      )}
    </>
  );
}

export default GroupDetails;
