import React, { useState, useEffect, useContext } from "react";
import DashboardSectionTitle from "../DashboardSectionTitle/DashboardSectionTitle";
import { Form, Button, Spinner, Row, Col } from "react-bootstrap";
import CustomCard from "../../../ui/CustomCard/CustomCard";
import { Field, Formik } from "formik";
import * as yup from "yup";
import { handleAlert } from "../../../util/util";
import { FaTelegramPlane } from "react-icons/fa";
import { BiPaperPlane } from "react-icons/bi";
import { MdSend } from "react-icons/md";

//Context
import AppContext from "../../../AppContext";

//Service
import recordService from "../../../services/Record";
import FieldError from "../../../ui/FieldError/FieldError";
import { truncate } from "lodash";

function AddComment(props) {
  const [loading, setLoading] = useState(false);

  // function addThreadComment(data, resetForm) {
  //   // setLoading(false);
  //   recordService
  //     .addThreadComment(data)
  //     .then((response) => {
  //       if (response.data.status === "success") {
  //         console.log(response.data);
  //         props.setCommentsCount((prev) => prev + 1);
  //         props.handleCommentList(props.parent_id);
  //         setLoading(false);
  //         resetForm({});
  //       } else if (response.data.status === "failed") {
  //         console.log("ERROR:", response.data.error);
  //         handleAlert("Adding a comment failed!", "error");
  //         setLoading(false);
  //         resetForm({});
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }

  const initialValues = {
    comment: "",
  };

  const addCommentSchema = yup.object({
    comment: yup.string().required("Required"),
  });

  const onSubmit = async (values, { resetForm }) => {
    setLoading(true);
    console.log("Form Values :", values);

    let newValues = null;

    switch (props.comment_type) {
      case "thread":
        newValues = {
          ...values,
          thread_id: props.parent_id,
        };
        console.log("Calling add thread comment...");
        recordService
          .addThreadComment(newValues)
          .then((response) => {
            if (response.data.status === "success") {
              console.log('Inside AddComment case "thread" : ', response.data);
              props.setCommentsCount((prev) => prev + 1);
              props.handleCommentList(props.parent_id);
              setLoading(false);
              resetForm({});
            } else if (response.data.status === "failed") {
              console.log("ERROR:", response.data.error);
              handleAlert("Adding a comment failed!", "error");
              setLoading(false);
              resetForm({});
            }
          })
          .catch((err) => {
            console.log(err);
          });
        break;
      case "group":
        newValues = {
          ...values,
          group_id: props.parent_id,
        };
        console.log("Calling add group comment...");
        recordService
          .addGroupComment(newValues)
          .then((response) => {
            if (response.data.status === "success") {
              console.log(response.data);
              props.setCommentsCount((prev) => prev + 1);
              props.handleCommentList(props.parent_id);
              setLoading(false);
              resetForm({});
            } else if (response.data.status === "failed") {
              console.log("ERROR:", response.data.error);
              handleAlert("Adding a comment failed!", "error");
              setLoading(false);
              resetForm({});
            }
          })
          .catch((err) => {
            console.log(err);
          });
        break;
      case "consult":
        newValues = {
          ...values,
          consult_id: props.parent_id,
        };
        console.log("Calling add consult session comment...");
        recordService
          .addConsultationComment(newValues)
          .then((response) => {
            if (response.data.status === "success") {
              console.log(response.data);
              // props.setCommentsCount((prev) => prev + 1);
              props.handleCommentList(props.parent_id);
              setLoading(false);
              resetForm({});
            } else if (response.data.status === "failed") {
              console.log("ERROR:", response.data.error);
              handleAlert("Adding a comment failed!", "error");
              setLoading(false);
              resetForm({});
            }
          })
          .catch((err) => {
            console.log(err);
          });
        break;
      case "record":
        newValues = {
          ...values,
          entity_id: props.parent_id,
          module: "record",
        };
        console.log("Calling add share record comment...");
        recordService
          .addComment(newValues)
          .then((response) => {
            if (response.data.status === "success") {
              console.log(response.data);
              props.setCommentsCount((prev) => prev + 1);
              props.handleCommentList(props.parent_id);
              setLoading(false);
              resetForm({});
            } else if (response.data.status === "failed") {
              console.log("ERROR:", response.data.error);
              handleAlert("Adding a comment failed!", "error");
              setLoading(false);
              resetForm({});
            }
          })
          .catch((err) => {
            console.log(err);
          });
        break;
    }

    // const newValues = {
    //   ...values,
    //   thread_id: props.forum_thread_id,
    // };

    // console.log("New Form Values :", newValues);

    // recordService
    //   .addThreadComment(newValues)
    //   .then((response) => {
    //     if (response.data.status === "success") {
    //       console.log(response.data);
    //       // handleAlert("You have successfully added a comment!", "success");
    //       // console.log("associations_count =", props.associationsCount);
    //       // console.log("After associations_count =", props.associationsCount);
    //       props.setThreadCommentsCount((prev) => prev + 1);
    //       props.handleThreadCommentList(props.forum_thread_id);
    //       setLoading(false);
    //       // setFormValues({});
    //       resetForm({});
    //       // props.handleCollapse("add_thread_comment");
    //       //fetch new context data
    //       // getData();
    //     } else if (response.data.status === "failed") {
    //       console.log("ERROR:", response.data.error);
    //       handleAlert("Adding a comment failed!", "error");
    //       setLoading(false);
    //       // setFormValues({});
    //       resetForm({});
    //       // props.handleCollapse("add_thread_comment");
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };
  return (
    <div>
      <CustomCard className="border-0 p-3 add_comment_card">
        <Formik
          initialValues={initialValues}
          validationSchema={addCommentSchema}
          onSubmit={onSubmit}
        >
          {(formik) => {
            return (
              <Form className="form_style" onSubmit={formik.handleSubmit}>
                {/* <DashboardSectionTitle
                  iconImg=""
                  title="Add your comment"
                  pageTitle={false}
                  className="mb2 small_title"
                /> */}
                <div className="d-flex align-items-center">
                  <div className="flex-fill pe-2">
                    <Form.Group className="form-group rounded_corners mb-0">
                      <Form.Control
                        as="textarea"
                        rows={2}
                        style={{ height: "auto", resize: "auto" }}
                        placeholder="Add your comment"
                        name="comment"
                        {...formik.getFieldProps("comment")}
                        autoComplete="off"
                      />
                      <FieldError
                        valid={
                          formik.touched.comment === true &&
                          formik.errors.comment
                            ? true
                            : false
                        }
                        text={formik.errors.comment}
                      />
                    </Form.Group>
                  </div>
                  <div class="btn_wrap">
                    <Button
                      type="submit"
                      className="btn btn_theme btn_small btn_primary btn_rounded btn_comment_submit"
                    >
                      {loading ? (
                        <>
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        </>
                      ) : (
                        <MdSend />
                      )}
                    </Button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </CustomCard>
    </div>
  );
}

export default AddComment;
