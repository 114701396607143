import React from "react";
import "./HomeVideoContainer.css";

function HomeVideoContainer(props) {
  const classList = `video_container ${props.className}`;

  return <div className={classList}>{props.children}</div>;
}

export default HomeVideoContainer;
