import React from "react";
import { Card, Row, Col, Form } from "react-bootstrap";
import CustomCard from "../../../ui/CustomCard/CustomCard";
import "./LeaderboardMemberCard.css";
import Ratings from "../../../ui/Ratings/Ratings";

function LeaderboardMemberCard(props) {
  // const member_img = props.item.user?.avatar ? props.item.user.avatar : "";
  const member_img =
    props.item.user?.avatar !== "NULL"
      ? props.item.user.avatar
      : `${process.env.PUBLIC_URL}/img/user-profile.jpg`;
  const member_name = props.item.user?.fullname
    ? props.item.user.fullname
    : "Default User";
  const member_city =
    props.item.user?.country !== "NULL" ? props.item.user.country : "";
  const member_xp = props.item.total_xp ? props.item.total_xp : 0;
  const rank = props.item.rank ? props.item.rank : 0;
  const star = props.item.star
    ? parseInt(props.item.star.replace(/[^0-9]/g, ""), 10)
    : 0;

  return (
    <>
      <CustomCard
        className={`main_card leaderboard_member_card shadow1 border-0`}
      >
        <div className={`media`}>
          <div className={`media_img_wrap`}>
            {member_img === "" && (
              <img
                src={`${process.env.PUBLIC_URL}/img/user.jpg`}
                className={`media_img`}
                alt={member_name}
              />
            )}
            {/* <img
              src={`${process.env.PUBLIC_URL}/img/${item.profileImg}`}
              className={styles.media_img}
              alt={item.name}
            /> */}
            {member_img !== "" && (
              <img
                src={`${member_img}`}
                className={`media_img`}
                alt={member_name}
              />
            )}
          </div>
          <div className={`media_body ms-3 align-self-center`}>
            <Row className="g-0">
              <Col xs={12} sm={8}>
                <Card.Title className={`card_title`}>{member_name}</Card.Title>
                <Card.Subtitle className={`card_subtitle`}>
                  {member_city}
                </Card.Subtitle>
              </Col>
              <Col
                xs={12}
                sm={4}
                className="d-flex align-items-center justify-content-start justify-content-sm-center"
              >
                <div className="text-center">
                  <Ratings
                    className=""
                    totalItems={5}
                    activeItems={star}
                    fillStyle={true}
                  />
                  <span className="font-15 fw_sb d-inline-flex mt-1">
                    {member_xp} XP
                  </span>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </CustomCard>
    </>
  );
}

export default LeaderboardMemberCard;
