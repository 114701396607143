import React from "react";
import { Card, Button } from "react-bootstrap";
import CustomCard from "../../../ui/CustomCard/CustomCard";
import DashboardSectionTitle from "../DashboardSectionTitle/DashboardSectionTitle";
import "./DashboardNetworkFriendCard.css";

function DashboardNetworkFriendCard(props) {
  // const handleRemoveFriend = (friend) => {
  //   // console.log("handleRemoveFriend of DashboardNetworkFriendCard...");
  //   // console.log("Friend Id in Handle Remove friend friend=", friend);
  //   const id = friend.id;
  //   // console.log("Friend Id in Handle Remove friend id=", id);
  //   props.removeFriend(friend.id);
  //   // if (id) {
  //   //   props.removeFriend(friend);
  //   // }
  // };

  const handleClick = () => {
    props.setSelectedUser({ u_id: props.user.id });
    props.handleShow();
  };

  function handleComposeMessage(e) {
    console.log("In handleComposeMessage =", e);
    let actionBody = {
      action: "message_compose",
      data: {
        users: [
          {
            label: e.name,
            value: e.id,
          },
        ],
        message: "",
      },
    };

    props.handleAction(actionBody);
  }

  return (
    <CustomCard
      className={`network_friend_card main_card shadow1 border-0 text-center`}
    >
      <Card.Body>
        <div className={`icon_wrap mx-auto rounded-circle overflow-hidden mb1`}>
          <img
            src={(props.user?.profile_img !== "NULL" && props.user.profile_img) || `${process.env.PUBLIC_URL}/img/user-profile.jpg`}
            alt={`${props.user.name} profile pic`}
          />
          {/* <img
            src={`${process.env.PUBLIC_URL}/img/${user.profileImg}`}
            alt="user profile"
          /> */}
        </div>
        {/* <div className="card-title">{user.name}</div> */}
        <DashboardSectionTitle
          iconImg=""
          title={props.user.name}
          pageTitle={false}
          className="mb1"
        />

        <div className="btn_wrap">
          <Button
            type="button"
            className="btn_theme btn_small btn_primary btn_rounded"
            onClick={() => {
              // props.composeMessage();
              // props.handleShow();
              handleComposeMessage(props.user);
            }}
          >
            Message
          </Button>
          <Button
            type="button"
            className="btn_theme btn_small btn_danger btn_rounded"
            onClick={handleClick}
          >
            Unfriend
          </Button>
        </div>
      </Card.Body>
    </CustomCard>
  );
}

export default DashboardNetworkFriendCard;
