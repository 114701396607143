import React from "react";
import { Accordion } from "react-bootstrap";
import "./CustomAccordionItem.css";

function CustomAccordionItem(props) {
  return (
    <Accordion.Item className={props.className} eventKey={props.eventKey}>
      {props.children}
    </Accordion.Item>
  );
}

export default CustomAccordionItem;
