import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

function PageComponent(props) {
  const location = useLocation();

  useEffect(() => {
    // console.log("From PageComponent scrooling...", location);
    window.scrollTo(0, 0);
  }, [location]);

  return <>{props.children}</>;
}

export default PageComponent;
