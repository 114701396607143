import React, { useState, useEffect, useContext } from "react";
import { Card, Nav, Tab, Row, Col, Button } from "react-bootstrap";
import CustomCard from "../../../ui/CustomCard/CustomCard";
import DashboardSectionTitle from "../DashboardSectionTitle/DashboardSectionTitle";
import Achievements from "./Achievements";
import AchievementStatusCard from "./AchievementStatusCard";
import Leaderboard from "./Leaderboard";
import RankAndXP from "./RankAndXP";

//Context
import AppContext from "../../../AppContext";

//Service
import recordService from "../../../services/Record";

function DashboardAchievementsContent() {
  const { achievements, setAchievements, getAllAchievements } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [activeKey, setActiveKey] = useState("achievements_tab");
  const [contentType, setContentType] = useState("achievements");
  // const [achievements, setAchievements] = useState({});
  // const [rank, setRank] = useState({});
  const [leaderboard, setLeaderboard] = useState([]);

  const handleSelectedTab = (key) => {
    console.log("Key in handleSelectedTab =", key);
    if (key == "achievements_tab") {
      console.log("Entering into achievements_tab");
      getAllAchievements();
      // getAnalyzeSearch(newValues);
    }
    if (key == "rank_and_xp_tab") {
      console.log("Entering into rank_and_xp_tab");
    }
    if (key == "leaderboard_tab") {
      console.log("Entering into leaderboard_tab");
      //fetch sent messages from db
      getLeaderboard();
    }

    setActiveKey(key);
  };

  // useEffect(() => {
  //   console.log("getAllAchievements useeffect");
  //   getAllAchievements();
  // }, []);

  // async function getAllAchievements() {
  //   setLoading(true);
  //   recordService
  //     .getAllAchievements()
  //     .then((response) => {
  //       if (response.data.status === "success") {
  //         console.log("getAllAchievements service res = ", response.data);
  //         const achievements = response.data.data.result.acheivement;
  //         const rankData = response.data.data.result.rank;
  //         console.log("achievements records = ", achievements);
  //         console.log("rankData = ", rankData);
  //         setAchievements(achievements);
  //         setRank(rankData);
  //         setLoading(false);
  //       } else if (response.data.status === "failed") {
  //         console.log("ERROR:", response.data.error);
  //         setLoading(false);
  //         setAchievements({});
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }

  //Deafult 10 global leaderboard
  async function getLeaderboard() {
    setLoading(true);
    recordService
      .getLeaderboard()
      .then((response) => {
        if (response.data.status === "success") {
          // console.log("getLeaderboard service res = ", response.data);
          const leaderboard = response.data.data.result.acheivement;
          // console.log("records = ", leaderboard);
          setLeaderboard(leaderboard);
          setLoading(false);
        } else if (response.data.status === "failed") {
          console.log("ERROR:", response.data.error);
          setLoading(false);
          setLeaderboard({});
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //Get filtered leaderboard
  async function getFilteredLeaderboard(data) {
    setLoading(true);
    recordService
      .getFilteredLeaderboard(data)
      .then((response) => {
        if (response.data.status === "success") {
          // console.log("getFilteredLeaderboard service res = ", response.data);
          const leaderboard = response.data.data.result.acheivement;
          // console.log("records = ", leaderboard);
          setLeaderboard(leaderboard);
          setLoading(false);
        } else if (response.data.status === "failed") {
          console.log("ERROR:", response.data.error);
          setLoading(false);
          setLeaderboard({});
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <>
      <DashboardSectionTitle
        iconImg="offer-icon9.png"
        title="Achievements"
        pageTitle={true}
        className="mb2"
        withInfo={true}
        infoData="Rank up and earn rewards as you learn more about yourself by unlocking your unconscious mind."
      />
      <AchievementStatusCard
        achievements={achievements.achievements}
        rank={achievements.rank}
      />

      <div className="achievement_tab">
        <Tab.Container
          id="achievement-tabs-example"
          className="main_tabs"
          activeKey={activeKey}
          onSelect={(key) => handleSelectedTab(key)}
        >
          <div className="tab_links">
            <Nav variant="pills" className="nav-justified">
              <Nav.Item>
                <Nav.Link className="normal_link" eventKey="achievements_tab">
                  <div class="">Achievements</div>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className="normal_link" eventKey="leaderboard_tab">
                  <div class="">Leaderboard</div>
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link className="normal_link" eventKey="rank_and_xp_tab">
                  <div class="">Rank and XP</div>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>

          <Tab.Content>
            <Tab.Pane
              className="pt2 pb2"
              eventKey="achievements_tab"
              unmountOnExit
            >
              <Achievements achievements={achievements.achievements} />
            </Tab.Pane>

            <Tab.Pane
              className="pt2 pb2"
              eventKey="leaderboard_tab"
              unmountOnExit
            >
              <Leaderboard
                loading={loading}
                leaderboard={leaderboard}
                setLeaderboard={setLeaderboard}
                getLeaderboard={getLeaderboard}
                getFilteredLeaderboard={getFilteredLeaderboard}
              />
            </Tab.Pane>
            <Tab.Pane className="pt2 pb2" eventKey="rank_and_xp_tab">
              <RankAndXP />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </>
  );
}

export default DashboardAchievementsContent;
