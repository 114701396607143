import React, { useState, useEffect, useContext } from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import CustomCard from "../../../ui/CustomCard/CustomCard";
import DashboardSectionTitle from "../DashboardSectionTitle/DashboardSectionTitle";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Bar, Line } from "react-chartjs-2";
//Context
import ThemeContext from "../../../ThemeContext";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

function DashboardLineChart(props) {
  const { theme } = useContext(ThemeContext);
  ChartJS.defaults.color = theme === 'dark' ? '#fff' : '#444';
  const [data, setData] = useState({
    labels: props.labels,
    datasets: props.datasets,
  });
  const [showAll, setShowAll] = useState(false);

  const options = {
    maintainAspectRatio: false,

    layout: {
      autoPadding: true,
    },
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: false,
        text: props.chart_title ? props.chart_title : "Line Chart",
        padding: {
          top: 10,
          bottom: 20,
        },
        color: "#333333",
        font: {
          size: 14,
          weight: "normal",
        },
      },
    },
  };

  // console.log("All datatset values =", cdata.values);

  // const data = {
  //   labels: cdata.labels,
  //   datasets: cdata.values,
  // };

  // const data = {
  //   labels: props.labels,
  //   datasets: props.datasets,
  // };

  console.log("Dashboard Line labels =", props.labels);
  console.log("Dashboard Line Datasets =", props.datasets);

  // useEffect(() => {
  //   //first
  //   if (showAll) {
  //     setData({
  //       labels: props.labels,
  //       datasets: props.datasets,
  //     });
  //   } else {
  //     setData({
  //       labels: props.labels
  //         .filter((label, index) => index < 5)
  //         .map((item) => item),
  //       datasets: props.datasets.map((dataset) => {
  //         return {
  //           ...dataset,
  //           data: dataset.data
  //             .filter((label, index) => index < 5)
  //             .map((item) => item),
  //         };
  //       }),
  //     });
  //   }
  //   console.log("BAR DATA = ", data);
  // }, [showAll]);

  return (
    <>
      
      {/* <Row className="row-cols-2 align-items-center mb-2">
        <Col>
          <span className="fw_sb mb-1 d-flex">{`# of experiences = ${props.recordCount}`}</span>
          <div>
            {showAll ? (
              <span className="custom_tag tag_small tag_golden">
                Result : All
              </span>
            ) : (
              <span className="custom_tag tag_small tag_golden">
                Result : Top 5
              </span>
            )}
          </div>
        </Col>
        <Col className="d-flex justify-content-end">
          <Button
            type="button"
            className="btn_theme btn_small btn_light btn_rounded"
            onClick={() => {
              setShowAll(!showAll);
            }}
          >
            {showAll ? "View Top 5" : "View All"}
          </Button>
        </Col>
      </Row> */}
      <div className="d-flex align-items-center justify-content-center font-14 fw_sb mb1 chart-title">
        <span className="py-1 px-3">
          {props.chart_title ? props.chart_title : "Line Chart"}
        </span>
      </div>
      <div className="pt1" style={{ height: "70vh" }}>
        <Line options={options} data={data} />
      </div>
    </>
  );
}

export default DashboardLineChart;
