import React, { useState, useEffect } from "react";
import { Col, Row, Form, Button, Spinner } from "react-bootstrap";
import DashboardSectionTitle from "../DashboardSectionTitle/DashboardSectionTitle";
import FieldError from "../../../ui/FieldError/FieldError";
import { Formik } from "formik";
import * as yup from "yup";
import { handleAlert } from "../../../util/util";

//Service
import recordService from "../../../services/Record";

function CreateForum(props) {
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState({});
  const isAddMode = props.isAddMode;

  const initialValues = {
    title: "",
    description: "",
    // sort_order: 10
  };

  useEffect(() => {
    if (!isAddMode && props.existingForum) {
      setFormValues(props.existingForum);
    }
  }, [props.existingForum]);

  const createForumSchema = yup.object({
    title: yup.string().max(100, "Title is too long!").required("Required"),
    description: yup.string().required("Required"),
  });

  const onSubmit = async (values, { resetForm }) => {
    console.log("Create Forum Form Values :", values);
    setLoading(true);

    if (isAddMode) {
      console.log("Creating New Forum");
      recordService
        .createForum(values)
        .then((response) => {
          if (response.data.status === "success") {
            console.log(response.data);
            handleAlert("Success! Forum created.", "success");
            setLoading(false);
            resetForm({});
            //get all forums
            props.getAllForums();
          } else if (response.data.status === "failed") {
            console.log("ERROR:", response.data.error);
            handleAlert("Error! Failed to create forum.", "error");
            setLoading(false);
            resetForm({});
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (!isAddMode) {
      console.log("Updating existing Forum");
      const updatedValues = {
        ...values,
        forum_id: props.forum_id,
      };
      recordService
        .updateForum(updatedValues)
        .then((response) => {
          if (response.data.status === "success") {
            console.log(response.data);
            handleAlert("Success! Forum updated.", "success");
            setLoading(false);
            resetForm({});
            props.handleCollapse("edit_forum");
            //get all forums
            props.getAllForums();
          } else if (response.data.status === "failed") {
            console.log("ERROR:", response.data.error);
            handleAlert("Error! Failed to update forum.", "error");
            setLoading(false);
            resetForm({});
            props.handleCollapse("edit_forum");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <>
      <div className="form_style mt1 mb1">
        <Formik
          initialValues={isAddMode ? initialValues : formValues}
          validationSchema={createForumSchema}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {(formik) => {
            return (
              <Form onSubmit={formik.handleSubmit}>
                <Row className="g-0">
                  <Col xs={12}>
                    <DashboardSectionTitle
                      iconImg=""
                      title="Forum Title"
                      pageTitle={false}
                      className="mb1 small_title"
                    />
                    <Form.Group className="form-group rounded_corners px-1">
                      <Form.Control
                        type="text"
                        placeholder="Forum Title"
                        name="title"
                        {...formik.getFieldProps("title")}
                      />
                      <div class="text-end text_gray8">
                        <small>* Maximum 100 characters long!</small>
                      </div>
                      <FieldError
                        valid={
                          formik.touched.title && formik.errors.title
                            ? true
                            : false
                        }
                        text={formik.errors.title}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={12}>
                    <DashboardSectionTitle
                      iconImg=""
                      title="Description"
                      pageTitle={false}
                      className="mb1 small_title"
                    />
                    <Form.Group className="form-group rounded_corners">
                      <Form.Control
                        as="textarea"
                        placeholder="Description"
                        style={{ height: "140px" }}
                        name="description"
                        {...formik.getFieldProps("description")}
                      />
                      <FieldError
                        valid={
                          formik.touched.description &&
                          formik.errors.description
                            ? true
                            : false
                        }
                        text={formik.errors.description}
                      />
                      {/* {formik.touched.description &&
                      formik.errors.description ? (
                        <p className="error-state-text">
                          {formik.errors.description}
                        </p>
                      ) : null} */}
                    </Form.Group>
                  </Col>
                  <Col xs={12}>
                    <div className="btn_wrap">
                      <Button
                        type="submit"
                        className="btn btn_theme btn_primary btn_rounded"
                      >
                        {loading ? (
                          <>
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />{" "}
                            Processing
                          </>
                        ) : (
                          <>{isAddMode ? "Create Forum" : "Update Forum"}</>
                        )}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </div>
    </>
  );
}

export default CreateForum;
