import React from "react";
import {
  BsFillCheckCircleFill,
  BsFillExclamationCircleFill,
  BsXCircleFill,
} from "react-icons/bs";

function SessionStatusTag(props) {
  let classlist = "";
  let tag_icon = "";
  let tag_title = "title";

  switch (props.status) {
    case "invited":
      classlist = "tag_warning";
      tag_icon = <BsFillExclamationCircleFill className="me-0" />;
      tag_title = "Invited";
      break;
    case "approved":
      classlist = "tag_success";
      tag_icon = <BsFillCheckCircleFill className="me-0" />;
      tag_title = "Approved";
      break;
    case "rejected":
      classlist = "tag_danger";
      tag_icon = <BsXCircleFill className="me-0" />;
      tag_title = "Rejected";
      break;
    case "completed":
      classlist = "tag_secondary";
      tag_icon = <BsFillCheckCircleFill className="me-0" />;
      tag_title = "Completed";
      break;
    default:
      classlist = "tag_light";
      tag_icon = <BsFillExclamationCircleFill className="me-0" />;
      tag_title = "NA";
  }

  return (
    <span
      className={`custom_tag tag_with_icon ${classlist}`}
      style={{ whiteSpace: "nowrap" }}
    >
      {tag_icon} {tag_title}
    </span>
  );
}

export default SessionStatusTag;
