import axios from "./Axios";

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
// const timezone = "America/New_York" //"Asia/Hong_Kong";
// console.log("-------------------------");
// console.log("timezone = ", timezone);
// console.log("-------------------------");

const recordService = {
  /*** Record Experiences ****/
  // Create new record
  createRecord: async (data) => {
    console.log("In Create Record service Data =", data);
    return await axios.post("/mobile/record/create", data);
  },

  // Update record
  updateRecord: async (data) => {
    console.log("In Update Record service Data =", data);
    return await axios.post("/mobile/record/update", data);
  },

  // Delete single record
  deleteRecord: async (id) => {
    console.log("Record Param ID in delete service = ", id);
    return await axios.get(`/mobile/record/delete?id=${id}`);
  },

  // get single record (details)
  getRecord: async (id) => {
    // console.log("Record Param ID = ", id);
    // console.log("getRecord() URL: ", `/mobile/record/details?id=${id}`);
    return await axios.get(`/mobile/record/details?id=${id}`);
  },

  // get all records (list)
  getAllRecords: async () => {
    console.log("Timezone in getAllSharedRecords = ", timezone);
    // return await axios.get("/mobile/record/list");
    return await axios.get(`/mobile/record/list?timezone=${timezone}`);
  },

  /*** Match ****/
  // get all matching records (list)
  getAllMatchingRecords: async (id) => {
    // console.log("all matching records: Record Param ID = ", id);
    return await axios.get(
      `/mobile/record/findmatch?id=${id}&timezone=${timezone}`
    );
  },

  /*** Association ****/
  //Create new association
  createAssociation: async (data) => {
    console.log("In Create Association service Data =", data);
    return await axios.post("/mobile/association/create", data);
  },

  //Update association
  updateAssociation: async (data) => {
    console.log("In Create Association service Data =", data);
    return await axios.post("/mobile/association/update", data);
  },

  // get single association (details)
  getAssociation: async (id) => {
    console.log("Association Param ID = ", id);
    console.log(
      "getAssociation() URL: ",
      `/mobile/association/details?id=${id}`
    );
    return await axios.get(`/mobile/association/details?id=${id}`);
  },

  //get all associations of a specific record
  getAllAssociations: async (id) => {
    return await axios.get(`/mobile/association/records?record_id=${id}`);
  },

  //get all records (list) having one or more associations
  getAllAssociationRecords: async () => {
    console.log("Timezone in getAllSharedRecords = ", timezone);
    return await axios.get(`/mobile/association/list?timezone=${timezone}`);
  },

  /**** Analyze *****/
  //get all analyzed searched records
  analyzeSearch: async (data) => {
    console.log("In Analyze search record service Data =", data);
    return await axios.post("/mobile/analyze/search", data);
  },

  //Analyze patterns
  analyzePattern: async (data) => {
    console.log("In Analyze Pattern service Data =", data);
    return await axios.post("/mobile/analyze/pattern", data);
  },

  //Analyze explore
  analyzeExplore: async (data) => {
    console.log("In Analyze explore service Data =", data);
    return await axios.post("/mobile/analyze/explore", data);
  },

  //Analyze Save Graph
  analyzeSaveGraph: async (data) => {
    console.log("In Analyze Save Graph service Data =", data);
    return await axios.post("/mobile/graph/save", data);
  },

  //Analyze Share Graph
  analyzeShareGraph: async (data) => {
    console.log("In Analyze Share Graph service Data =", data);
    return await axios.post("/mobile/graph/share", data);
  },

  /**** Share ****/
  //Share a record
  shareRecord: async (data) => {
    console.log("In Share record service Data =", data);
    return await axios.post("/mobile/record/share", data);
  },

  //get all records (list) which are shared to/by users
  getAllSharedRecords: async () => {
    // console.log("Timezone in getAllSharedRecords = ",timezone)
    // return await axios.get(`/mobile/record/shares?timezone=${timezone}`);
    return await axios.get("/mobile/record/shares");
  },

  //Add new comment
  addComment: async (data) => {
    console.log("In Add Comment service Data =", data);
    return await axios.post("/mobile/comment/submit", data);
  },

  //get all comments of a specific record
  getAllComments: async (data) => {
    console.log("In All Comments service Data =", data);
    return await axios.post(`/mobile/comment/all`, data);
  },

  /**** Notifications ****/
  //Get all Notifications
  getAllNotifications: async () => {
    console.log("In All Get all Notifications Service call");
    return await axios.get("/mobile/notification/list");
  },

  //Mark notification as read
  markNotificationAsRead: async (data) => {
    console.log("Mark notification as read service call =", data);
    return await axios.post("/mobile/notification/markread", data);
  },

  /**** Network & Invitations ****/
  //Get all friends
  getAllFriends: async () => {
    return await axios.get("/mobile/friends/list");
  },

  //Get all invitations
  getAllInvitations: async () => {
    return await axios.get("/mobile/friends/invitations");
  },

  //Get all group invitations
  getAllGroupInvitations: async () => {
    return await axios.get("/mobile/groups/invitations");
  },

  //Get all group requests
  getAllGroupRequests: async () => {
    return await axios.get("/mobile/groups/requests");
  },

  //Get all consult invitations
  getAllConsultInvitations: async () => {
    return await axios.get("/mobile/consult/invitations");
  },

  // Add friend
  addFriend: async (data) => {
    console.log("Friend id in add service call =", data);
    return await axios.post("/mobile/friends/add", data);
  },

  //Remove friend
  removeFriend: async (id) => {
    console.log("Friend id in remove service call =", id);
    return await axios.post("/mobile/friends/remove", {
      friend_id: id,
    });
  },

  //Accept friend request
  acceptFriend: async (id) => {
    console.log("Friend id in accept service call =", id);
    return await axios.post("/mobile/friends/accept", {
      friend_id: id,
    });
  },

  //Reject friend request
  rejectFriend: async (id) => {
    console.log("Friend id in rejectFriend service call =", id);
    return await axios.post("/mobile/friends/reject", {
      friend_id: id,
    });
  },

  //Accept Consulr request
  acceptConsultInvite: async (data) => {
    console.log("Consult data in acceptConsultInvite service call =", data);
    return await axios.post("/mobile/consult/accept", data);
  },

  //Reject consult request
  rejectConsultInvite: async (data) => {
    console.log("Consult in rejectConsultInvite service call =", data);
    return await axios.post("/mobile/consult/reject", data);
  },

  //Search User by term
  searchUsers: async (term) => {
    console.log("Search term =", term);
    return await axios.get(`/mobile/user/search?q=${term}`);
  },

  //Create group
  createGroup: async (data) => {
    console.log("In Create Group service Data =", data);
    return await axios.post("/mobile/groups/create", data);
  },

  //Join a Group
  joinGroup: async (group_id) => {
    console.log("Group id in Join service call =", group_id);
    return await axios.post("/mobile/groups/join", {
      group_id: group_id,
    });
  },

  //Leave a Group
  leaveGroup: async (group_id) => {
    console.log("Group id in Leave service call =", group_id);
    return await axios.post("/mobile/groups/leave", {
      group_id: group_id,
    });
  },

  //Accept group request
  acceptGroupRequest: async (data) => {
    console.log("Group request data in accept service call =", data);
    return await axios.post("/mobile/groups/accept", data);
  },

  //Reject group request
  rejectGroupRequest: async (data) => {
    console.log("Group request data in reject service call =", data);
    return await axios.post("/mobile/groups/reject", data);
  },

  //Add User in a Group
  addGroupUser: async (data) => {
    console.log("Group id in addUser service call =", data);
    return await axios.post("/mobile/groups/adduser", data);
  },

  //Add User in a Group
  removeGroupUser: async (data) => {
    console.log("Group id in removeUser service call =", data);
    return await axios.post("/mobile/groups/remove", data);
  },

  //get all groups
  getAllGroups: async () => {
    return await axios.get("/mobile/groups/list");
  },

  getGroup: async (id) => {
    return await axios.get(`/mobile/groups/details?id=${id}`);
  },

  //Search group by term
  searchGroups: async (term) => {
    console.log("Search term =", term);
    return await axios.get(`/mobile/groups/search?q=${term}`);
  },

  /** Consultant */
  // Get all consultants
  getAllConsultants: async () => {
    console.log("In getAllConsultants Consultants service...");
    return await axios.get("/mobile/consultants/list");
  },

  // Send Consult Invite
  sendConsultInvite: async (data) => {
    console.log("Consult id in sendConsultInvite service call =", data);
    return await axios.post("/mobile/consult/invite", data);
  },

  //Search consultants
  searchConsultants: async (data) => {
    console.log("In Search consultants service Data =", data);
    return await axios.post("/mobile/consultants/search", data);
  },

  /*** Consulting sessions */
  //Get all inbox messages
  getAllConsultingSessions: async () => {
    return await axios.get("/mobile/consult/list");
  },

  //Add new comment to consultation
  addConsultationComment: async (data) => {
    console.log("In Add Comment consult service Data =", data);
    return await axios.post("/mobile/consult/comment_submit", data);
  },

  //Remove new comment to consultation
  removeConsultationComment: async (data) => {
    console.log("In Remove Comment consult service Data =", data);
    return await axios.post("/mobile/consult/comment_remove", data);
  },

  //get all comments of a specific consultation
  getAllConsultationComments: async (data) => {
    console.log("In All Comments Consultation service Data =", data);
    return await axios.post(`/mobile/consult/comment_all`, data);
  },

  //make payment to consultation using Paypal
  makePayment: async (data) => {
    console.log("In Make Payment using Paypal - consult service Data =", data);
    return await axios.post("/mobile/consult/paypalpayment", data);
  },

  //make payment to consultation
  makePaymentTest: async (data) => {
    console.log("In Make Payment Test consult service Data =", data);
    return await axios.post("/mobile/consult/makepayment", data);
  },

  //Ask for payment to consultation
  askConsultingPayment: async (data) => {
    console.log("In Ask for Payment consult service Data =", data);
    return await axios.post("/mobile/consult/askpayment", data);
  },

  //Give ratings to consultant
  consultSubmitRatings: async (data) => {
    console.log("In Consult Submit Ratings consult service Data =", data);
    return await axios.post("/mobile/consult/submit_rating", data);
  },

  //Mark consult session as complete
  consultMarkComplete: async (data) => {
    console.log("In Consult Mark Complete consult service Data =", data);
    return await axios.post("/mobile/consult/markcompleted", data);
  },

  /*** Messages ****/
  //Get all inbox messages
  getAllInboxMessages: async () => {
    return await axios.get("/mobile/messages/inbox");
  },

  //Get all sent messages
  getAllSentMessages: async () => {
    return await axios.get("/mobile/messages/sent");
  },

  //Get all archived messages
  getAllArchivedMessages: async () => {
    return await axios.get("/mobile/messages/archived");
  },

  //Get all trashed messages
  getAllTrashedMessages: async () => {
    return await axios.get("/mobile/messages/trashed");
  },

  //Add new comment to Group
  addGroupComment: async (data) => {
    console.log("In Add Comment group service Data =", data);
    return await axios.post("/mobile/groups/comment_submit", data);
  },

  //Remove Group comment
  removeGroupComment: async (data) => {
    console.log("In Remove Comment group service Data =", data);
    return await axios.post("/mobile/groups/comment_remove", data);
  },

  //get all comments of a specific Group
  getAllGroupComments: async (data) => {
    console.log("In All Comments group service Data =", data);
    return await axios.post(`/mobile/groups/comment_all`, data);
  },

  //Compose message
  composeMessage: async (data) => {
    console.log("Data from compose message service =", data);
    return await axios.post("/mobile/messages/compose", {
      users: data.users, //array
      message: data.message,
    });
  },

  //mark message as archived
  markMessageArchived: async (message_id) => {
    console.log("Message id in mark archived service =", message_id);
    return await axios.post("/mobile/messages/mark_archived", {
      message_id: message_id,
    });
  },

  //mark message as trashed
  markMessageTrashed: async (message_id) => {
    console.log("Message id in mark thrashed service =", message_id);
    return await axios.post("/mobile/messages/mark_thrashed", {
      message_id: message_id,
    });
  },

  /*** Forum ****/
  //Create Forum
  createForum: async (data) => {
    console.log("In Create Forum service Data =", data);
    return await axios.post("/mobile/forum/create", data);
  },

  //Update Forum
  updateForum: async (data) => {
    console.log("In Update Forum service Data =", data);
    return await axios.post("/mobile/forum/update", data);
  },

  //Get all forums
  getAllForums: async () => {
    console.log("In getAllForums Forum service...");
    return await axios.get("/mobile/forum/list");
  },

  // get single forum (details)
  getForum: async (forum_id) => {
    console.log("Forum ID =", forum_id);
    console.log("getForum() URL: ", `/mobile/forum/details?id=${forum_id}`);
    return await axios.get(`/web/forum/details?id=${forum_id}`);
  },

  //get all threads of a specific forum
  getAllThreads: async (id) => {
    return await axios.get(`/mobile/thread/list?id=${id}`);
  },

  // get single thread (details)
  getThread: async (forum_thread_id, forum_id) => {
    console.log("Thread ID = ", forum_thread_id, "\t Forum ID =", forum_id);
    console.log(
      "getThread() URL: ",
      `/mobile/thread/details?id=${forum_thread_id}&forum_id=${forum_id}`
    );
    return await axios.get(
      `/web/thread/details?id=${forum_thread_id}&forum_id=${forum_id}`
    );
  },

  //Create Thread
  createThread: async (data) => {
    console.log("In Create Thread service Data =", data);
    return await axios.post("/mobile/thread/create", data);
  },

  //Update Thread
  updateThread: async (data) => {
    console.log("In Create Thread service Data =", data);
    return await axios.post("/mobile/thread/update", data);
  },

  //Add new comment to Thread
  addThreadComment: async (data) => {
    console.log("In Add Comment Thread service Data =", data);
    return await axios.post("/mobile/thread/comment_submit", data);
  },

  //Remove thread comment
  removeThreadComment: async (data) => {
    console.log("In Remove Comment thread service Data =", data);
    return await axios.post("/mobile/thread/comment_remove", data);
  },

  //get all comments of a specific Thread
  getAllThreadComments: async (data) => {
    console.log("In All Comments Thread service Data =", data);
    return await axios.post(`/mobile/thread/comment_all`, data);
  },

  /*** Dictionary ***/
  //List all words
  getAllDictionaryWords: async (index) => {
    console.log("In getAllDictionaryWords Dictionary service...", index);
    return await axios.get(`/mobile/dictionary/search?index=${index}`);
  },

  //List all random words
  getAllRandomDictionaryWords: async () => {
    console.log("In getAllRandomDictionaryWords Dictionary service...");
    return await axios.get("/mobile/dictionary/list");
  },

  //Search all words
  searchAllDictionaryWords: async (keyword) => {
    console.log("In getAllDictionaryWords Dictionary service...", keyword);
    return await axios.get(`/mobile/dictionary/search?keyword=${keyword}`);
  },

  //Dictionary Decode
  getDecodedDictionary: async (data) => {
    console.log("In getDecodedDictionary Dictionary service...", data);
    return await axios.post("/mobile/dictionary/decode", data);
  },

  //Dictionary vote
  addDictionaryVote: async (data) => {
    console.log("In addDictionaryVote Dictionary service...", data);
    return await axios.post("/mobile/dictionary/vote", data);
  },

  /** Achievements */
  //List all Achievements
  getAllAchievements: async () => {
    console.log("In getAllAchievements service...");
    return await axios.get("/mobile/achievements/list");
  },

  //List all Leaderboard default 10 records
  getLeaderboard: async () => {
    console.log("In getLeaderboard service...");
    return await axios.get("/mobile/achievements/leaderboard");
  },

  getFilteredLeaderboard: async (data) => {
    console.log("In getLeaderboard service...");
    return await axios.get(`/mobile/achievements/leaderboard?target=${data}`);
  },

  /** User Settings */
  // Get Current User details
  getUserDetails: async () => {
    console.log("In getUserDetails Current User service...");
    return await axios.get("/mobile/user/details");
  },

  //Update user details
  updateUserDetails: async (data) => {
    console.log("In Update User Details service Data =", data);
    return await axios.post("/mobile/user/update", data);
  },

  //Update Publish settings
  updatePublishSettings: async (data) => {
    console.log("In Update Publish service Data =", data);
    return await axios.post("/mobile/user/publish_settings", data);
  },

  //Change new password
  changePassword: async (data) => {
    console.log("In change password service Data =", data);
    return await axios.post("/mobile/user/change_password", data);
  },

  // Get All Subscriptions Plans
  getSubscriptionPlans: async () => {
    console.log("In getSubscriptionPlans Current User service...");
    return await axios.get("/mobile/subscriptions/plans");
  },

  // Get All User Subscriptions
  getUserSubscriptions: async () => {
    console.log("In getUserSubscriptions Current User service...");
    return await axios.get("/mobile/subscriptions/list");
  },

  //get a subscribe
  subscribe: async (data) => {
    console.log("In subscribe service Data =", data);
    return await axios.post("/mobile/subscriptions/subscribe", data);
  },

  /** Dashboard **/

  //Get User Profile Data
  getUserProfileData: async () => {
    console.log("In getUserProfileData service...");
    return await axios.get("/mobile/achievements/profile_card");
  },

  //Dashboard shared (Engagement) data
  getDashboardSharedData: async () => {
    console.log("In getDashboardSharedData service...");
    return await axios.get("/mobile/graph/dashboard");
  },

  //Useful metrics data
  getUsefulMetricsData: async (data) => {
    console.log("In getUsefulMetricsData service...", data);
    return await axios.post("/mobile/user/useful_metric", data);
  },

  //Dashboard Favorite data
  getDashboardFavoriteData: async () => {
    console.log("In getDashboardFavoriteData service...");
    return await axios.get("/mobile/graph/favorites");
  },

  //Dashboard Popular Insights data
  getDashboardPopularInsightsData: async (data) => {
    console.log("In getDashboardPopularInsightsData service...", data);
    return await axios.post("/mobile/graph/populargraphs", data);
  },

  //Dashboard More Popular Insights data
  getDashboardMorePopularInsightsData: async () => {
    console.log("In getDashboardMorePopularInsightsData service...");
    return await axios.get("/mobile/graph/morepopulargraphs");
  },

  //Dashboard Popular Wordcloud data
  getDashboardPopularWordcloudData: async (data) => {
    console.log("In getDashboardPopularWordcloudData service...", data);
    return await axios.post("/mobile/graph/globalwordcloud", data);
  },

  //Dashboard Resolution data
  getDashboardResolutionData: async (data) => {
    console.log("In getDashboardFavoriteData service...", data);
    return await axios.post("/mobile/graph/resolutions", data);
  },

  //General
  getEmoIcons: (emotion) => {
    let imgPath = "";
    switch (emotion) {
      case "anothercharacter":
        imgPath = "characters/another-character.png";
        break;

      default:
        break;
    }

    return imgPath;
  },
};

export default recordService;
