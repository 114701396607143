import React, { useState, useEffect, useContext } from "react";
import DashboardSectionTitle from "../DashboardSectionTitle/DashboardSectionTitle";
import { Form, Button, Spinner } from "react-bootstrap";
import CustomCard from "../../../ui/CustomCard/CustomCard";
import FieldError from "../../../ui/FieldError/FieldError";
import { Formik } from "formik";
import * as yup from "yup";
import { handleAlert } from "../../../util/util";

//Context
import AppContext from "../../../AppContext";

//Service
import recordService from "../../../services/Record";

function AddAssociation(props) {
  const { setExperiences, setProfileStats, getData, getUserProfileData } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState({});
  const isAddMode = props.isAddMode;
  const isUpdating = props.isUpdating;

  const isActedUponRadioOptions = [
    { key: "Yes", value: true },
    { key: "No", value: false },
  ];

  const outcomeRadioOptions = [
    { key: "Positive", value: "positive" },
    { key: "Negative", value: "negative" },
    { key: "No Change", value: "nochange" },
  ];

  const isThingsHappenRadioOptions = [
    { key: "Yes", value: true },
    { key: "No", value: false },
  ];

  const initialValues = {
    is_acted_upon: "true",
    action_taken: "",
    outcome: "positive",
    is_things_happen: "true",
    connection: "",
  };

  // const savedValues = {
  //   is_acted_upon: saved_is_acted_upon,
  //   action_taken: saved_action_taken,
  //   outcome: saved_outcome,
  //   is_things_happen: saved_is_things_happen,
  //   connection: saved_connection,
  // };

  const associationSchema = yup.object({
    is_acted_upon: yup.boolean().required("Required"),
    action_taken: yup.string().when("is_acted_upon", {
      is: (is_things_happen) => is_things_happen === true ? true : false,
      then: yup.string().required("Required"),
      otherwise: yup.string()
    }),
    outcome: yup.string(),
    // action_taken: yup.string().when("is_acted_upon", {
    //   is: true,
    //   then: yup.string(),
    // }),
    // outcome: yup.string().when("is_acted_upon", {
    //   is: true,
    //   then: yup.string(),
    // }),

    is_things_happen: yup.boolean().required("Required"),
    // connection: yup.string().required("Required"),
    connection: yup.string().when("is_things_happen", {
      is: (is_things_happen) => is_things_happen === true ? true : false,
      then: yup.string().required("Required"),
      otherwise: yup.string()
    })
  });

  // useEffect(() => {
  //   if (!isAddMode && props.associationRecord) {
  //     setFormValues(props.associationRecord);
  //   }
  // }, [props.associationRecord]);

  useEffect(() => {
    if (!isAddMode && props.associationRecord) {
      props.associationRecord["is_acted_upon"] =
        props.associationRecord["is_acted_upon"].toString();
      props.associationRecord["is_things_happen"] =
        props.associationRecord["is_things_happen"].toString();
      setFormValues(props.associationRecord);
    }
  }, [props.associationRecord]);

  const onSubmit = async (values, { resetForm }) => {
    setLoading(true);
    // console.log("Form Values :", values);
    let new_is_acted_upon = values.is_acted_upon === "true" ? true : false;
    let new_is_things_happen =
      values.is_things_happen === "true" ? true : false;

    const newValues = {
      ...values,
      record_id: props.record_id,
      is_acted_upon: new_is_acted_upon,
      is_things_happen: new_is_things_happen,
    };

    // console.log("New Form Values :", newValues);

    if (isAddMode) {
      // console.log("Creating new association");
      recordService
        .createAssociation(newValues)
        .then((response) => {
          if (response.data.status === "success") {
            // console.log(response.data);
            handleAlert(
              "You have successfully created an association!",
              "success"
            );
            // console.log("associations_count =", props.associationsCount);
            // console.log("After associations_count =", props.associationsCount);
            props.setAssociationsCount((prev) => prev + 1);

            setLoading(false);
            // setFormValues({});
            resetForm({});
            props.handleCollapse("add_association");
            //fetch new context data
            getData();
            //Get Profile Stats
            getUserProfileData();
          } else if (response.data.status === "failed") {
            console.log("ERROR:", response.data.error);
            handleAlert("Association creation failed!", "error");
            setLoading(false);
            // setFormValues({});
            resetForm({});
            props.handleCollapse("add_association");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (!isAddMode) {
      console.log("Updating existing Association");
      recordService
        .updateAssociation(newValues)
        .then((response) => {
          if (response.data.status === "success") {
            console.log(response.data);
            handleAlert(
              "You have successfully updated an association!",
              "success"
            );
            // setFormValues({});
            resetForm({});
            setLoading(false);
            props.handleCollapse("edit_association");
            props.handleAssociationList(newValues.record_id);
            // props.setEditCollapse(!props.editCollapse);
          } else if (response.data.status === "failed") {
            console.log("ERROR:", response.data.error);
            handleAlert("Association updation failed!", "error");
            // setFormValues({});
            resetForm({});
            setLoading(false);
            props.handleCollapse("edit_association");
            // props.setEditCollapse(!props.editCollapse);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <CustomCard className="border-0">
      <Formik
        initialValues={isAddMode ? initialValues : formValues}
        validationSchema={associationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {(formik) => {
          return (
            <Form className="form_style" onSubmit={formik.handleSubmit}>
              <DashboardSectionTitle
                iconImg=""
                title="I have acted upon feelings or insights derived from this dream."
                pageTitle={false}
                className="mb2 small_title"
              />
              <Form.Group className="form-group rounded_corners px-2 inline_radio_fields">
                <div className="mb-3">
                  {isActedUponRadioOptions.map((option) => {
                    return (
                      <Form.Check
                        key={option.value}
                        inline
                        label={option.key}
                        name="is_acted_upon"
                        type="radio"
                        id={`isActed-${option.key}`}
                        value={option.value}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        checked={
                          formik.values?.is_acted_upon ===
                          option.value.toString()
                        }
                      />
                    );
                  })}
                </div>
                <FieldError
                      valid={
                        formik.touched.is_acted_upon && formik.errors.is_acted_upon
                          ? true
                          : false
                      }
                      text={formik.errors.is_acted_upon}
                    />
                
              </Form.Group>
              {formik.values.is_acted_upon === "true" ? (
                <>
                  <DashboardSectionTitle
                    iconImg=""
                    title="What did you do?"
                    pageTitle={false}
                    className="mb2 small_title"
                  />
                  <Form.Group className="form-group rounded_corners">
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder=""
                      name="action_taken"
                      {...formik.getFieldProps("action_taken")}
                      autoComplete="off"
                    />
                    <FieldError
                      valid={
                        formik.touched.action_taken && formik.errors.action_taken
                          ? true
                          : false
                      }
                      text={formik.errors.action_taken}
                    />
                    
                  </Form.Group>
                  <DashboardSectionTitle
                    iconImg=""
                    title="What was the outcome for you?"
                    pageTitle={false}
                    className="mb2 small_title"
                  />
                  <Form.Group className="form-group rounded_corners px-2 inline_radio_fields">
                    <div className="mb-3">
                      {outcomeRadioOptions.map((option) => {
                        return (
                          <Form.Check
                            key={option.value}
                            inline
                            label={option.key}
                            name="outcome"
                            type="radio"
                            id={option.value}
                            value={option.value}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            // {...formik.getFieldProps("outcome")}
                            // value={option.value}
                            checked={formik.values?.outcome === option.value}
                          />
                        );
                      })}
                    </div>
                    <FieldError
                      valid={
                        formik.touched.outcome && formik.errors.outcome
                          ? true
                          : false
                      }
                      text={formik.errors.outcome}
                    />
                    {/* {formik.touched.outcome && formik.errors.outcome ? (
                      <p className="error-state-text">
                        {formik.errors.outcome}
                      </p>
                    ) : null} */}
                  </Form.Group>
                </>
              ) : null}

              <DashboardSectionTitle
                iconImg=""
                title="Things have happened to me or around me that seem connected with this dream."
                pageTitle={false}
                className="mb2 small_title"
              />
              <Form.Group className="form-group rounded_corners px-2 inline_radio_fields">
                <div className="mb-3">
                  {isThingsHappenRadioOptions.map((option) => {
                    return (
                      <Form.Check
                        key={option.value}
                        inline
                        label={option.key}
                        name="is_things_happen"
                        type="radio"
                        id={`isThingsHappen-${option.key}`}
                        value={option.value}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        checked={
                          formik.values?.is_things_happen ==
                          option.value.toString()
                        }
                      />
                    );
                  })}
                </div>
                <FieldError
                      valid={
                        formik.touched.is_things_happen && formik.errors.is_things_happen
                          ? true
                          : false
                      }
                      text={formik.errors.is_things_happen}
                    />
                
              </Form.Group>
              {formik.values.is_things_happen === "true" ? <>
              <DashboardSectionTitle
                iconImg=""
                title="Describe the connection between this experience and your waking life."
                pageTitle={false}
                className="mb2 small_title"
              />
              <Form.Group className="form-group rounded_corners">
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder=""
                  name="connection"
                  {...formik.getFieldProps("connection")}
                  autoComplete="off"
                />
                <FieldError
                      valid={
                        formik.touched.connection && formik.errors.connection
                          ? true
                          : false
                      }
                      text={formik.errors.connection}
                    />
                
              </Form.Group>
              </> : null }

              
              <Button
                type="submit"
                disabled={!formik.isValid || (formik.values.is_acted_upon !== "true" && formik.values.is_things_happen !== "true")}
                className="btn_theme btn_primary btn_rounded mb1"
              >
                {loading ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />{" "}
                    Processing
                  </>
                ) : (
                  <>{isAddMode ? "Create Association" : "Update Association"}</>
                )}
              </Button>
            </Form>
          );
        }}
      </Formik>
    </CustomCard>
  );
}

export default AddAssociation;
