import React, { useState, useEffect } from "react";
import Swiper from "react-id-swiper";
import "swiper/css/bundle";
import "swiper/bundle";
import {
  Col,
  Row,
  Card,
  Collapse,
  Button,
  Placeholder,
  Form,
  Spinner,
} from "react-bootstrap";
import CustomCard from "../../../ui/CustomCard/CustomCard";
import {
  BsFillExclamationTriangleFill,
  BsThreeDotsVertical,
  BsFillPencilFill,
} from "react-icons/bs";
import DashboardPieChart from "../DashboardCharts/DashboardPieChart";
import DashboardSectionTitle from "../DashboardSectionTitle/DashboardSectionTitle";
import PlaceholderCard from "../../../ui/PlaceholderCard/PlaceholderCard";
import { Field, Formik } from "formik";
import * as yup from "yup";
import DatePicker from "react-datepicker";
import { addDays, subDays } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import { formatDateYMD } from "../../../util/util";
import { bgcolors } from "../../../util/colors";

function PopularGraphs(props) {
  const today = formatDateYMD(new Date());
  const [loading, setLoading] = useState(false);
  const [labels, setLabels] = useState([]);
  const [datasets, setDatasets] = useState([]);
  const [fullDatasets, setFullDatasets] = useState([]);
  const [recordCount, setRecordCount] = useState([]);
  const [datasetLength, setDatasetLength] = useState(0);
  const [selectedChart, setSelectedChart] = useState("bar");
  const [popularGraphCollapse, setPopularGraphCollapse] = useState(false);
  // const [filterData, setFilterData] = useState({
  //   target: "global",
  //   start_date: "",
  //   end_date: "",
  // });
  console.log("I am executed --- PopularGraphs");
  // const bgcolors = [
  //   "#81d4fa",
  //   "#26c6da",
  //   "#4db6ac",
  //   "#81c784",
  //   "#aed581",
  //   "#dce775",
  //   "#fff176",
  //   "#ffb74d",
  //   "#ff8a65",
  //   "#a1887f",
  //   "#90a4ae",
  //   "#42a5f5",
  //   "#7986cb",
  //   "#b39ddb",
  //   "#ba68c8",
  //   "#f48fb1",
  //   "#e57373",
  //   "#b39d9d",
  // ];
  //   const bgcolors = [
  //     "#ebacf9",
  //     "#f2cc85",
  //     "#ffe0b5",
  //     "#a58ff7",
  //     "#f28a92",
  //     "#fcbdd2",
  //     "#cd6fed",
  //     "#ffc6d7",
  //     "#fc79ba",
  //     "#ffe3c1",
  //     "#c1ffaf",
  //     "#f7d3a0",
  //     "#8be7ef",
  //     "#f9837f",
  //     "#94bdf7",
  //     "#7ff4ba",
  //     "#adff87",
  //     "#b2f780",
  //     "#89ffc4",
  //     "#9b8fe8",
  //     "#fff58e",
  //     "#e397f4",
  //     "#7171ed",
  //     "#bfbbf7",
  //     "#b2ffc1",
  //     "#b1e7f9",
  //     "#89ff87",
  //     "#f9debb",
  //     "#7ef7bc",
  //     "#a4defc",
  //     "#c2d9fc",
  //     "#b3f8fc",
  //     "#f280ac",
  //     "#98f9e8",
  //     "#f9cfb8",
  //     "#f9a495",
  //     "#6c9ce2",
  //     "#c37ee5",
  //     "#f29780",
  //     "#9af4a9",
  //     "#a0ffb1",
  //     "#6feddc",
  //     "#efd27a",
  //     "#b162d6",
  //     "#a6fcb2",
  //     "#d5c1ff",
  //     "#cdc7fc",
  //     "#f7afc0",
  //     "#9fd2ea",
  //     "#9effe8",
  //     "#d1ff9e",
  //     "#82f2c7",
  //     "#ed8bcb",
  //     "#b3f7f9",
  //     "#ffd7cc",
  //     "#e7ff7c",
  //     "#ffccce",
  //     "#ffdfcc",
  //     "#f2c98c",
  //     "#9cfce6",
  //     "#9bf7e1",
  //     "#ea8def",
  //     "#e482ed",
  //     "#dec1ff",
  //     "#a1fce1",
  //     "#7ab2e2",
  //     "#ed71dc",
  //     "#9ff791",
  //     "#8a7fe8",
  //     "#dff981",
  //     "#cbffaa",
  //     "#f29c82",
  //     "#deea6e",
  //     "#dff78a",
  //     "#bbeff9",
  //     "#f48dcd",
  //     "#93b1ed",
  //     "#91f7e1",
  //     "#fc92d2",
  //     "#ebffa3",
  //     "#ccffff",
  //     "#8a79e0",
  //     "#93f486",
  //     "#c2cffc",
  //     "#bc96e8",
  //     "#75efd7",
  //     "#f4d684",
  //     "#a8a4f4",
  //     "#d3fc99",
  //     "#d0c6ff",
  //     "#8cf0f2",
  //     "#d1f26f",
  //     "#ea7091",
  //     "#a2daf2",
  //     "#ed95ca",
  //     "#b3dffc",
  //     "#ffe4b7",
  //     "#f4a1be",
  //     "#d3ffaf",
  //     "#f7f986",
  //     "#f9d38b",
  //     "#f2d579",
  //     "#f4b7a1",
  //     "#b9a0ff",
  //     "#f794ba",
  //     "#afffed",
  //     "#f4bffc",
  //     "#f986cd",
  //     "#fafc8d",
  //     "#f9b3e2",
  //     "#edb082",
  //     "#c6a7ef",
  //     "#a4fc94",
  //     "#fcb5da",
  //     "#73f4e3",
  //     "#f9ee72",
  //     "#f7b888",
  //     "#f9e789",
  //     "#a1fcf1",
  //     "#ffddb5",
  //     "#fcd7a6",
  //     "#f49e9c",
  //     "#8cb6ea",
  //     "#f47e7c",
  //     "#7efce9",
  //     "#efa2c5",
  //     "#afffc1",
  //     "#f7f291",
  //     "#e684ed",
  //     "#ea7c69",
  //     "#c7f276",
  //     "#ed7da8",
  //     "#baedff",
  //     "#b3ebf9",
  //     "#f99de7",
  //     "#b48bf9",
  //     "#f28cae",
  //     "#baef7a",
  //     "#caffb2",
  //     "#a9fcb2",
  //     "#ffadd8",
  //     "#b574f7",
  //     "#efc388",
  //     "#fffcba",
  //     "#f9d3a7",
  //     "#95f4e3",
  //     "#b4ef7c",
  //     "#e4bffc",
  //     "#f9e793",
  //     "#fff9bc",
  //     "#b6a0f7",
  //     "#ebc0f9",
  //     "#a0f7a7",
  //     "#b5fafc",
  //     "#a4a8f2",
  //     "#e4b5fc",
  //     "#fcb5be",
  //     "#f9aee2",
  //     "#dfccff",
  //     "#d8b0f2",
  //     "#d2fca6",
  //     "#ffbdaf",
  //     "#ffe5a0",
  //     "#fc94db",
  //     "#8081ed",
  //     "#ef6b7b",
  //     "#fcdc83",
  //     "#baecfc",
  //     "#98b0f2",
  //     "#85f7ea",
  //     "#d7ef6b",
  //     "#ccffad",
  //     "#ffa79e",
  //     "#fcaeca",
  //     "#74f7f5",
  //     "#ff77dd",
  //     "#9ed8ed",
  //     "#c6caff",
  //     "#8aef81",
  //     "#fce07b",
  //     "#d5b9f7",
  //     "#ffccce",
  //     "#80fccd",
  //     "#6f75e8",
  //     "#e9fca9",
  //     "#8fa4ef",
  //     "#a377e5",
  //     "#ffe6c6",
  //     "#ffe177",
  //     "#a2f9df",
  //     "#b76add",
  //     "#f998a2",
  //     "#8defc0",
  //     "#b2ffc1",
  //     "#b9ff96",
  //     "#e8ef83",
  //     "#cff293",
  //     "#a4fcc5",
  //     "#988de8",
  //     "#ffd1b5"
  // ]

  function handleClick(e) {
    const element = e.target.getAttribute("aria-controls");
    let isAriaExpanded = e.target.getAttribute("aria-expanded");
    // console.log("Association Card =", e);
    handleCollapse(element);
  }
  function handleCollapse(element) {
    console.log("In Popular graph - Filter =", element);
    // if (element === "edit_association")
    setPopularGraphCollapse(!popularGraphCollapse);
  }

  useEffect(() => {
    // console.log(
    //   "in useEffect of PopularInsightsContent =",
    //   props.dashboardPopularInsightsData
    // );

    if (props.dashboardPopularInsightsData?.length > 0) {
      createChartData(props.dashboardPopularInsightsData);
    }
  }, [props.dashboardPopularInsightsData]);

  // useEffect(() => {
  //   console.log("I always run...");
  //   setFilterData({
  //     target: "global",
  //     start_date: "",
  //     end_date: "",
  //   });
  // }, []);

  function createChartData(data, dataLength = "full", chartOption = "pie") {
    // let newLabels = data.labels.map((label) => {
    //   //Capitalize first letter
    //   return label.charAt(0).toUpperCase() + label.slice(1);
    // });
    console.log("PopularGraphs - chart_data in createChartdata =", data);
    // console.log("dataLength in createChartdata =", dataLength);
    // console.log("chartOption =", chartOption);

    let targetOption = "";

    let dataLabels = [];
    let dataRecordCount = [];
    let allDatasets = [];
    let allChartsData = [];

    if (data.length > 0) {
      data.map((item, index) => {
        console.log(`ITEM ${index} = `, item);

        let newLabels = [];
        let tempDatasets = [];
        let tempData = {};
        let targetOption = item.target ? item.target : "";
        let tempLegendList = [];

        //setting up labels and Total count for displaying graphs

        if (dataLength === "full") {
          newLabels = item.chart_data.labels.map((label) => {
            //Capitalize first letter
            return label.charAt(0).toUpperCase() + label.slice(1);
          });
          setDatasetLength("full");
        } else {
          if (item.chart_type === "wordcloud") {
            newLabels = item.chart_data.labels.map((label) => {
              //Capitalize first letter
              return label.charAt(0).toUpperCase() + label.slice(1);
            });
            setDatasetLength("full");
          } else {
            for (let i = 0; i < dataLength; i++) {
              if (item.chart_data.labels.length > 0) {
                newLabels.push(
                  item.chart_data.labels[i].charAt(0).toUpperCase() +
                    item.chart_data.labels[i].slice(1)
                );
              }
            }
            setDatasetLength(0);
          }
        }
        dataLabels.push(newLabels);
        dataRecordCount.push(item.chart_data.total_no_of_records);

        //setting up actual datasets for displaying graphs

        if (item.chart_data.values?.length > 0) {
          tempLegendList = item.chart_data.values.map((item, index) => {
            return {
              color: bgcolors[index],
              value: item,
              label: newLabels[index],
            };
          });
        }

        tempDatasets.push({
          // label: "Temp",
          label:
            props.popularInsightsFilterData.target.charAt(0).toUpperCase() +
            props.popularInsightsFilterData.target.slice(1),
          // data: data.values.me,
          data: item.chart_data.values,
          barPercentage: 1,
          // barThickness: 10,
          maxBarThickness: 10,
          // minBarLength: 4,
          // borderColor: "rgb(0, 153, 255)",
          // backgroundColor: "rgba(0, 153, 255, 0.5)",
          backgroundColor: bgcolors,
          hoverBorderRadius: 4,
          hoverBorderColor: "rgba(0,0,0,0.4)",
          // hoverBackgroundColor: "rgba(0, 153, 255, 0.7)",
        });

        // console.log("------ tempDatasets = ", tempDatasets);

        allDatasets.push(tempDatasets);

        // console.log("------ allDatasets = ", allDatasets);

        allChartsData.push({
          chart_type: "pie",
          labels: newLabels,
          datasets: tempDatasets,
          total_records: item.chart_data.total_no_of_records,
          chart_title: "Popular " + item.type,
          legend_list: tempLegendList,
        });
      });

      setLabels(dataLabels);
      setRecordCount(dataRecordCount);
      if (allDatasets.length > 0) {
        setDatasets(allDatasets);
      }
      if (allChartsData.length > 0) {
        setFullDatasets(allChartsData);
      }
    }

    // console.log("---- Labels =", labels);
    // console.log("---- RecordCount =", recordCount);
    // console.log("---- Datasets =", datasets);
    // console.log("---- ALL Datasets =", fullDatasets);
  }

  const targetRadioOptions = [
    { key: "Me", value: "me" },
    { key: "Friends", value: "friends" },
    { key: "Global", value: "global" },
  ];

  const initialValues = {
    target: "global",
    start_date: new Date("2021-02-28"),
    end_date: new Date(today),
  };
  const popularInsightsFilterSchema = yup.object({
    start_date: yup.date().required("Required").nullable(),
    end_date: yup.date().required("Required").nullable(),
  });

  const onSubmit = async (values, { resetForm }) => {
    props.setLoading(true);
    // console.log("Dashboard Popular Insights Form values: ", values);
    let newStartDate = formatDateYMD(values.start_date);
    let newEndDate = formatDateYMD(values.end_date);

    const newValues = {
      ...values,
      start_date: newStartDate,
      end_date: newEndDate,
    };
    // console.log("Dashboard Popular Insights New values: ", newValues);

    props.getDashboardPopularInsightsData(newValues);
    // setFilterData(newValues);
    handleCollapse("popular_graph_collapse");

    // let popularGraphsData = [];
    // recordService
    //   .getDashboardPopularInsightsData(newValues)
    //   .then((response) => {
    //     if (response.data.status === "success") {
    //       console.log(
    //         "Response from getDashboardPopularInsightsData on dashboard : ",
    //         response.data
    //       );
    //       const patterns = response.data.data.result.patterns;
    //       console.log("Pattern - emotions = ", Object.values(patterns));
    //       const tempKeys = Object.keys(patterns);
    //       const graphData = Object.values(patterns).map((pattern, index) => {
    //         return { ...pattern, type: tempKeys[index] };
    //       });

    //       props.setDashboardPopularInsightsData(graphData);
    //       setFilterData(newValues);
    //       handleCollapse("popular_graph_collapse");
    //       props.setLoading(false);
    //     }
    //     if (response.data.status === "failed") {
    //       console.log("ERROR:", response.data.error);
    //       props.setDashboardPopularInsightsData([]);
    //       props.setLoading(false);
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(
    //       "There is a problem in fetching dashboard favorites data...",
    //       err
    //     );
    //     props.setLoading(false);
    //   });

    // if (props.loading) {
    //   console.log("------ inside if loop ------", props.loading);
    //   setFilterData(newValues);
    //   handleCollapse("popular_graph_collapse");
    //   console.log("------ inside if loop ------");
    // }

    // handleAlert("Error! Functionality under development.", "error");
  };

  return (
    <>
      <Row className="align-items-center position-relative mb1">
        <Col>
          <div class="section_title">Popular Graphs</div>
          <Button
            key="see_more_btn"
            type="button"
            className="btn_theme btn_icon btn_circle btn_light position-absolute top-0"
            onClick={handleClick}
            aria-controls="popular_graph_collapse"
            aria-expanded={popularGraphCollapse}
            style={{ right: "0.5rem" }}
          >
            <BsFillPencilFill />
          </Button>
        </Col>
      </Row>

      <Row className="row-cols-1 align-items-center mb2">
        <Col>
          <div>
            <span className="custom_tag tag_primary_light">
              Target : {props.popularInsightsFilterData.target}
            </span>
            <span className="custom_tag tag_primary_light">
              {/* <BsFillCalendar2CheckFill /> : */}
              Date :
              {props.popularInsightsFilterData.start_date === "" ? (
                " All"
              ) : (
                <>{` ${props.popularInsightsFilterData.start_date} - ${props.popularInsightsFilterData.end_date}`}</>
              )}
            </span>
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <Collapse in={popularGraphCollapse}>
            <div id="popular_graph_collapse">
              <CustomCard className="border-0 shadow1 mt0 mb2 pt2 pb2 px-3">
                <Formik
                  initialValues={initialValues}
                  validationSchema={popularInsightsFilterSchema}
                  onSubmit={onSubmit}
                  enableReinitialize
                >
                  {(formik) => {
                    return (
                      <Form
                        className="form_style"
                        onSubmit={formik.handleSubmit}
                      >
                        <Row className="">
                          <Col>
                            <DashboardSectionTitle
                              iconImg=""
                              title="Select target"
                              pageTitle={false}
                              className="mb1 small_title"
                            />
                          </Col>
                          <Col className="col-md-8">
                            <Form.Group className="form-group rounded_corners px-2 inline_radio_fields radio_btn_field">
                              <div className="">
                                {targetRadioOptions.map((option) => {
                                  return (
                                    <Form.Check
                                      key={option.value}
                                      inline
                                      label={option.key}
                                      name="target"
                                      type="radio"
                                      id={"popular-pattern-" + option.value}
                                      value={option.value}
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      checked={
                                        formik.values?.target === option.value
                                      }
                                    />
                                  );
                                })}
                              </div>
                              {formik.touched.target && formik.errors.target ? (
                                <p className="error-state-text">
                                  {formik.errors.target}
                                </p>
                              ) : null}
                            </Form.Group>
                          </Col>
                        </Row>

                        <Row className="row-cols-1 row-cols-sm-2 g-2">
                          <Col>
                            <Form.Group className="form-group small_field rounded_corners">
                              <DashboardSectionTitle
                                iconImg=""
                                title="Start date"
                                pageTitle={false}
                                className="mb-1 small_title"
                              />
                              <Field name="start_date">
                                {({ form, field }) => {
                                  const { setFieldValue } = form;
                                  const { value } = field;

                                  return (
                                    <DatePicker
                                      id="start_date"
                                      className="form-control"
                                      {...field}
                                      todayButton="Today"
                                      selected={value}
                                      onChange={(val) =>
                                        setFieldValue("start_date", val)
                                      }
                                      dateFormat="yyyy-MM-dd"
                                      maxDate={addDays(
                                        new Date(formik.values.end_date),
                                        0
                                      )}
                                      placeholderText="Start Date"
                                      showMonthDropdown
                                      showYearDropdown
                                      dropdownMode="select"
                                      // scrollableMonthYearDropdown
                                      autoComplete="off"
                                    />
                                  );
                                }}
                              </Field>
                              {formik.touched.start_date &&
                              formik.errors.start_date ? (
                                <p className="error-state-text">
                                  {formik.errors.start_date}
                                </p>
                              ) : null}
                            </Form.Group>
                          </Col>

                          <Col>
                            <Form.Group className="form-group small_field rounded_corners">
                              <DashboardSectionTitle
                                iconImg=""
                                title="End date"
                                pageTitle={false}
                                className="mb-1 small_title"
                              />
                              <Field name="end_date">
                                {({ form, field }) => {
                                  const { setFieldValue } = form;
                                  const { value } = field;

                                  return (
                                    <DatePicker
                                      id="end_date"
                                      className="form-control"
                                      {...field}
                                      selected={value}
                                      onChange={(val) =>
                                        setFieldValue("end_date", val)
                                      }
                                      dateFormat="yyyy-MM-dd"
                                      minDate={subDays(
                                        new Date(formik.values.start_date),
                                        0
                                      )}
                                      placeholderText="End date"
                                      showMonthDropdown
                                      showYearDropdown
                                      dropdownMode="select"
                                      // scrollableMonthYearDropdown
                                      autoComplete="off"
                                    />
                                  );
                                }}
                              </Field>
                              {formik.touched.end_date &&
                              formik.errors.end_date ? (
                                <p className="error-state-text">
                                  {formik.errors.end_date}
                                </p>
                              ) : null}
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="btn_wrap">
                              <Button
                                key="submit_btn"
                                type="submit"
                                className="btn_theme btn_primary btn_small btn_rounded"
                              >
                                {props.loading ? (
                                  <>
                                    <Spinner
                                      as="span"
                                      animation="border"
                                      size="sm"
                                      role="status"
                                      aria-hidden="true"
                                    />{" "}
                                    Processing
                                  </>
                                ) : (
                                  "Submit"
                                )}
                              </Button>
                              <Button
                                key="popular_graph_cancel_btn"
                                type="button"
                                className="btn btn_theme btn_small btn_light btn_rounded"
                                onClick={() => {
                                  handleCollapse("popular_graph_collapse");
                                }}
                              >
                                Cancel
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    );
                  }}
                </Formik>
              </CustomCard>
            </div>
          </Collapse>
        </Col>
      </Row>

      {props.loading ? (
        <>
          <PlaceholderCard type="chart" />
        </>
      ) : (
        <>
          {props.dashboardPopularInsightsData.length > 0 ? (
            <>
              <div className="position-relative pt-0 pb-3">
                <Swiper
                  {...props.swiperParams}
                  containerClass="swiper-container theme_swiper"
                >
                  {fullDatasets.length > 0 &&
                    fullDatasets.map((dataset, index) => (
                      <div
                        key={index}
                        className="card custom_card bg-transparent shado border-0 p2 px-0 mb2"
                      >
                        <DashboardPieChart
                          labels={dataset.labels}
                          datasets={dataset.datasets}
                          recordCount={dataset.total_records}
                          chart_title={dataset.chart_title}
                          legend_list={dataset.legend_list}
                          // chartData={dataset}
                        />
                      </div>
                    ))}
                </Swiper>
              </div>
            </>
          ) : (
            <>
              <CustomCard className="shadow1 border-0 text-center">
                <Card.Body>
                  <div className="mb1">
                    <BsFillExclamationTriangleFill
                      style={{
                        color: "#d3af61",
                        fontSize: "2.2rem",
                      }}
                    />
                  </div>
                  <b>No more records found...</b>
                </Card.Body>
              </CustomCard>
            </>
          )}
        </>
      )}
    </>
  );
}

export default PopularGraphs;
