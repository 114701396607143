import React from "react";

function PrivacyInfo() {
  return (
    <div className="page_text">
      <p>
        We receive your information in two ways: the information that's required
        when you sign up for the site, as well as the information you choose to
        record and share.
      </p>

      <p>
        <strong>Information you provide at sign up:</strong>
      </p>
      <p>
        When you sign up for uDreamed, we’ll ask for personal information, like
        your name, email address, home address, gender, birthday and education.
      </p>

      <p>
        <strong>Information you record and share:</strong>
      </p>
      <p>
        Your information includes the information you record and share on the
        site, such as when you record and share unconscious experience contents
        with friends or practitioners. It also includes the information you
        choose to share when you communicate with us, such as when you contact
        us using an email address, or when you take an action, such as when you
        create a group, add a friend, purchase products on the site or contact a
        practitioner for consultation.
      </p>

      <p>
        Your name, profile pictures, gender, networks, username and User ID are
        treated just like information you choose to make public. Your birthday
        allows us to provide better services to all of our users and also show
        you age-appropriate content and advertisements.
      </p>

      <p>
        <strong>Information others share about you:</strong>
      </p>
      <p>
        We receive information about you from your friends and others, such as
        when they upload your contact information, post a comment to you, or add
        you to a group.
      </p>

      <p>
        <strong>Other information we receive about you:</strong>
      </p>
      <p>We also receive other types of information about you:</p>

      <ul>
        <li>
          We receive data about you whenever you use uDreamed, such as when you
          look at another user’s content, send or receive a message, search for
          content, click on, view or otherwise interact with things, use
          uDreamed mobile app, or make purchases through uDreamed.
        </li>
        <li>
          When you search and select a practitioner on uDreamed, we may receive
          additional information related data, such as time, date, and the type
          of practitioner you consulted, the amount you paid the practitioner
          services and your rating of the services you received.
        </li>
        <li>
          We receive data from or about the computer, mobile phone, or other
          devices you use to install uDreamed apps or to access uDreamed,
          including when multiple users log in from the same device. This may
          include network and communication information, such as your IP address
          or mobile phone number, and other information about things like your
          internet service, operating system, location, the type (including
          identifiers) of the device or browser you use, or the pages you visit.
          For example, we may get your GPS or other location information so we
          can tell you if any of your friends or practitioners are nearby, or we
          could request device information to improve how our apps work on your
          device.
        </li>
        <li>
          We receive data whenever you visit our site or application, or website
          that uses uDreamed platform, sometimes through cookies. This may
          include the date and time you visit the site; the web address, or URL,
          you're on; technical information about the IP address, browser and the
          operating system you use; and, if you are logged in to uDreamed, your
          User ID.
        </li>
        {/* <li>
                  When you purchase a product or service on our site, you
                  provide your payment information, including your credit or
                  debit card number, card expiration date, CVV code, and billing
                  address, along with your shipping address, to complete a
                  commerce transaction on uDreamed. You may also provide your
                  credit or debit card number to register for card-linked
                  services, such as practitioner consultation. To facilitate
                  future purchases on uDreamed, we store your payment
                  information, excluding CVV code, and shipping address, which
                  you can remove from your account at any time using your
                  account settings. We consider your payment information and
                  shipping address private and do not make such information
                  public. We collect and store information created by your
                  purchases made on uDreamed.
                </li> */}
        <li>
          Sometimes we get data from our affiliates or our advertising partners,
          users, and other third parties that help us (or them) deliver ads,
          understand online activity, and generally improve the value uDreamed
          provides. For example, an advertiser may tell us information about you
          (like how you responded to an ad on uDreamed or on another site) in
          order to measure the effectiveness of - and improve the quality of –
          ads that relevant to users.
        </li>
      </ul>

      <p>
        <strong>How we use the information we receive:</strong>
      </p>
      <p>
        We use the information we collect to provide, maintain, protect and
        improve the services and features we deliver to you, your friends,
        practitioners you consult, our partners, to develop new services,
        improve current offering, and to protect uDreamed and all our users. We
        also use this information to offer you relevant content and tailored
        services from app developers and advertisers. For example, we may use
        information about you to serve valuable self-development tutorials and
        relevant ads to you, and to measure or understand the effectiveness of
        content and ads you and others see.
      </p>
      <p>
        By granting us permission to use your information you allow us to
        improve your user experience and the overall quality of services we
        provide to you and other users.
      </p>
      <p>
        While you are allowing us to use the information we receive about you,
        you always own all of your information. Your trust is important to us,
        which is why we don't share information we receive about you with others
        unless we have: (i) received your permission; (ii) given you notice,
        such as by telling you about it in this policy; or (iii) removed your
        name and any other personally identifying information from it.
      </p>
      <p>
        When showing you tailored ads, we will not associate a cookie or
        anonymous identifier with sensitive categories, such as those based on
        race, sexual orientation or health conditions you discuss with a
        practitioner you selected on our site.
      </p>
      <p>
        We will share personal information with companies, organizations or
        individuals outside of uDreamed when we have your consent to do so. We
        require opt-in consent for the sharing of any sensitive personal
        information. Accordingly, we will ask for your consent before using
        information for a purpose other than those that are set out in this
        Privacy Policy.
      </p>
      <p>
        We may share aggregated, non-personally identifiable information
        publicly and with our partners – like developers, advertisers, business
        partners or connected sites. For example, we may share information
        publicly to show trends about the general use of our services.
      </p>
      <p>
        uDreamed processes personal information on our servers in many countries
        around the world. We may process your personal information on a server
        located outside the country where you live.
      </p>
      <p>
        Of course, for information others share about you, they control how it
        is shared. Information regarding consultation with a practitioner or a
        merchant on our site is controlled by the practitioner or the merchant,
        not uDreamed.
      </p>
      <p>
        We store data for as long as it is necessary to provide products and
        services to you and others, including those described above. Typically,
        information associated with your account will be kept until your account
        is deleted. For certain categories of data, we may also tell you about
        specific data retention practices.
      </p>
      <p>
        We engage service providers, such as practitioners, directory service
        providers and payment service providers, to perform functions and
        provide services to you. We may share your private personal information
        with such service providers subject to confidentiality obligations
        consistent with this Privacy Policy, and on the condition that the third
        parties use your private personal data only on our behalf and pursuant
        to our instructions.
      </p>
      {/* <p>
        We engage service providers, such as practitioners, directory service
        providers and payment service providers, to perform functions and
        provide services to you. We may share your private personal information
        with such service providers subject to confidentiality obligations
        consistent with this Privacy Policy, and on the condition that the third
        parties use your private personal data only on our behalf and pursuant
        to our instructions. We share your payment information with payment
        services providers to process payments; prevent, detect and investigate
        fraud or other prohibited activities; facilitate dispute resolution such
        as chargebacks or refunds; and for other purposes associated with the
        acceptance of credit or debit cards. We may share your credit or debit
        card number with payment services providers, or third parties authorized
        by them, to monitor card transactions at participating merchants.
      </p> */}
      <p>
        If you buy goods or services on uDreamed, we may provide the seller,
        commerce provider or marketplace with your name, email address, shipping
        address, payment information and transaction data to facilitate payment
        processing, order fulfillment and dispute resolution (including payment
        and shipping disputes) and to help prevent, detect and investigate fraud
        or other prohibited activities. Please refer to these third parties’
        privacy policies for information about their privacy practices.
      </p>
      <p>
        We may preserve or disclose your information if we believe that it is
        reasonably necessary to comply with a law, regulation or legal request;
        to protect the safety of any person; to address fraud, security or
        technical issues; or to protect uDreamed’s rights or property. However,
        nothing in this Privacy Policy is intended to limit any legal defenses
        or objections that you may have to a third party’s, including a
        government’s, request to disclose your information.
      </p>
      <p>
        We may share or disclose your non-private, aggregated or otherwise
        non-personal information, such as your public user profile information,
        the users you shared your unconscious experiences with or who shared
        with you, or the number of users who joined your network, or reports to
        advertisers about unique users who saw or clicked on their ads after we
        have removed any private personal information (such as your name or
        contact information).
      </p>

      <p>
        <strong>Information security:</strong>
      </p>
      <p>
        We work hard to protect uDreamed and our users from unauthorized access
        to or unauthorized alteration, disclosure or destruction of information
        we hold. In particular:
      </p>

      <ul>
        {/* <li>We encrypt many of our services using SSL.</li> */}
        <li>We encrypt all of our services using SSL.</li>
        <li>
          We review our information collection, storage and processing
          practices, including physical security measures, to guard against
          unauthorized access to systems.
        </li>
      </ul>

      <p>
        We restrict access to personal information to uDreamed employees,
        contractors and agents who need to know that information in order to
        process it for us, and who are subject to strict contractual
        confidentiality obligations and may be disciplined or terminated if they
        fail to meet these obligations.
      </p>
    </div>
  );
}

export default PrivacyInfo;
