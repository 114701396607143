import React, { useState, useEffect } from "react";
import { Card, Placeholder } from "react-bootstrap";
import CustomCard from "../../../ui/CustomCard/CustomCard";
import DashboardSectionTitle from "../DashboardSectionTitle/DashboardSectionTitle";
import { BsFillExclamationTriangleFill } from "react-icons/bs";
import DashboardExperienceCard from "../DashboardExperienceCard/DashboardExperienceCard";

function MatchedExperienceList(props) {
  // console.log("inside MatchedExperienceList = ", props.matchedRecords);
  return (
    <>
      <DashboardSectionTitle
        iconImg=""
        title="Matching experiences"
        pageTitle={false}
        className="mb2"
      />
      {!props.loading && props.matchedRecords?.length > 0 ? (
        props.matchedRecords?.map((exp, index) => (
          <DashboardExperienceCard key={index} exp={exp} />
        ))
      ) : (
        <>
          {props.loading ? (
            <>
              {[0, 1].map((_, i) => (
                <CustomCard
                  key={i}
                  className={`main_card shadow1 border-0 mb2 text-left overflow-hidden`}
                >
                  <Card.Header>
                    <div className="d-flex align-items-center">
                      <div className="flex-shrink-0">
                        <Placeholder as="div" animation="glow">
                          <Placeholder
                            bg="secondary"
                            as="div"
                            style={{ height: "54px", width: "54px" }}
                            className="rounded-circle"
                          />
                        </Placeholder>
                      </div>
                      <div className="flex-grow-1 ms-3 text-left">
                        <div className="w-100">
                          <Placeholder xs={6} bg="secondary" />
                        </div>
                        <div className="w-100">
                          <Placeholder xs={4} bg="secondary" />
                        </div>
                      </div>
                    </div>
                  </Card.Header>
                  <Card.Body className="p2">
                    <div className="w-100 mb-1">
                      <Placeholder xs={9} bg="secondary" />
                    </div>
                    <div className="w-100 mb1">
                      <Placeholder xs={6} bg="secondary" />
                    </div>
                  </Card.Body>
                </CustomCard>
              ))}
            </>
          ) : (
            <CustomCard className="shadow1 border-0 text-center">
              <Card.Body>
                <div className="mb1">
                  <BsFillExclamationTriangleFill
                    style={{
                      color: "#d3af61",
                      fontSize: "2.2rem",
                    }}
                  />
                </div>
                <b>No matching records found...</b>
              </Card.Body>
            </CustomCard>
          )}
        </>
      )}
    </>
  );
}

export default MatchedExperienceList;
