import React, { Component } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./HomeFeaturesSlider.css";

class HomeFeaturesSlider extends Component {
  state = {
    nav1: null,
    nav2: null,
    slides: [],
    txtSlides: [],
  };

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
      slides: this.props.featuresSlides,
    });
  }

  render() {
    const { slides } = this.state;

    const slickFeatureNavSettings = {
      slidesToShow: 8,
      slidesToScroll: 1,
      dots: false,
      arrows: false,
      centerMode: false,
      focusOnSelect: true,
      infinite: false,
      swipeToSlide: true,

      asNavFor: this.state.nav2,
      ref: (slider) => (this.slider1 = slider),

      responsive: [
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 7,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 420,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
      ],
    };

    const slickFeatureMainSettings = {
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      fade: false,
      adaptiveHeight: true,
      asNavFor: this.state.nav1,
      ref: (slider) => (this.slider2 = slider),
    };

    return (
      <div className={`offers_slider tab_slider pt2`}>
        <Slider className="tab_options" {...slickFeatureNavSettings}>
          {slides.map((slideNavItem, i) => (
            <div className="tab_options_list" key={slideNavItem.id}>
              <div className="tab_option_img">
                <img
                  src={`${process.env.PUBLIC_URL}/img/${slideNavItem.iconImg}`}
                  className="img-fluid mx-auto"
                  alt={slideNavItem.title}
                />
              </div>
              <span>{slideNavItem.title}</span>
            </div>
          ))}
        </Slider>

        <div className="tab_content_slider_wrap">
          <Slider className="tab_content_slider" {...slickFeatureMainSettings}>
            {slides.map((slideItem, i) => (
              <div className="tab_content_item" key={slideItem.id}>
                <div className="row">
                  <div className="col-12 col-sm-12 col-md">
                    <img
                      src={`${process.env.PUBLIC_URL}img/${slideItem.featureImg}`}
                      className="img-fluid mx-auto d-block"
                      alt=""
                    />
                  </div>
                  <div className="col-12 col-sm-12 col-md d-flex align-items-center">
                    <h3>{slideItem.desc}</h3>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
          <div className="text-center">
            <Link
              to="/signup"
              className="btn btn_theme btn_rounded btn_large btn_theme_gradient"
            >
              Record Your Experiences
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default HomeFeaturesSlider;
