import React, { useState, useEffect } from "react";
import { Col, Row, Card, Button, Spinner, Form } from "react-bootstrap";
import DashboardSectionTitle from "../DashboardSectionTitle/DashboardSectionTitle";
import CustomCard from "../../../ui/CustomCard/CustomCard";
import DashboardPieChart from "../DashboardCharts/DashboardPieChart";
import PlaceholderCard from "../../../ui/PlaceholderCard/PlaceholderCard";
import { Field, Formik } from "formik";
import * as yup from "yup";
import DatePicker from "react-datepicker";
import { addDays, subDays } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import { formatDateYMD } from "../../../util/util";
import { BsFillExclamationTriangleFill } from "react-icons/bs";
//Service
import recordService from "../../../services/Record";

function ResolutionContent(props) {
  const [loading, setLoading] = useState(false);
  const [labels, setLabels] = useState([]);
  const [datasets, setDatasets] = useState([]);
  const [datasetLength, setDatasetLength] = useState(0);
  const [fullDatasets, setFullDatasets] = useState([]);
  const [recordCount, setRecordCount] = useState([]);
  const [legendList, setLegendList] = useState([]);
  const today = formatDateYMD(new Date());

  useEffect(() => {
    console.log(
      "in useEffect dashboardResolutionData =",
      props.dashboardResolutionData
    );
    // const chart_data = props.analyzedPatterns.chart_data;
    // console.log("chart_data in useEffect =", chart_data);
    // const target = chart_data?.target;
    // console.log("target in useEffect =", target);
    // const chart = chart_data?.chart;
    // console.log("chart in useEffect =", chart);

    if (props.dashboardResolutionData.hasOwnProperty("values")) {
      createChartData(props.dashboardResolutionData);
    }
  }, [props.dashboardResolutionData]);

  function createChartData(data, dataLength = "full", chartOption = "pie") {
    // let newLabels = data.labels.map((label) => {
    //   //Capitalize first letter
    //   return label.charAt(0).toUpperCase() + label.slice(1);
    // });
    console.log("chart_data in createChartdata =", data);
    console.log("dataLength in createChartdata =", dataLength);
    console.log("chartOption =", chartOption);

    let dataLabels = [];
    let dataRecordCount = [];
    let allDatasets = [];
    let allChartsData = [];
    let newLabels = [];
    let tempLegendList = [];
    let tempDatasets = [];

    if (dataLength === "full") {
      newLabels = data.labels.map((label) => {
        //Capitalize first letter
        return label.charAt(0).toUpperCase() + label.slice(1);
      });
      setDatasetLength("full");
    } else {
      for (let i = 0; i < dataLength; i++) {
        if (data.labels.length > 0) {
          newLabels.push(
            data.labels[i].charAt(0).toUpperCase() + data.labels[i].slice(1)
          );
        }
      }
      setDatasetLength(0);
    }

    setLabels(newLabels);
    setRecordCount(data.total_no_of_records);

    if (data.values?.length > 0) {
      tempLegendList = data.values.map((item, index) => {
        return {
          color: props.bgcolors[index],
          value: item,
          label: newLabels[index],
        };
      });
    }

    tempDatasets.push({
      // label: newLabels,//"Resolutions Data",
      // data: data.values.me,
      data: data.values,
      barPercentage: 1,
      // barThickness: 10,
      maxBarThickness: 10,
      // minBarLength: 4,
      // borderColor: "rgb(0, 153, 255)",
      // backgroundColor: "rgba(0, 153, 255, 0.5)",
      backgroundColor: props.bgcolors,
      hoverBorderRadius: 4,
      hoverBorderColor: "rgba(0,0,0,0.4)",
      // hoverBackgroundColor: "rgba(0, 153, 255, 0.7)",
    });

    console.log("In Resolution content outer tempDatasets = ", tempDatasets);

    if (tempDatasets.length > 0) {
      console.log("inside tempDatasets.length > 0");
      setDatasets(tempDatasets);
    }
    if (tempLegendList.length > 0) {
      console.log("inside tempLegendList.length > 0");
      setLegendList(tempLegendList);
    }

    if (datasets.length > 0) {
      console.log("In Resolution content datasets.length > 0 = ", datasets);
    }
  }

  const initialValues = {
    start_date: new Date("2021-02-28"),
    end_date: new Date(today),
  };
  const resolutionFilterSchema = yup.object({
    start_date: yup.date().required("Required").nullable(),
    end_date: yup.date().required("Required").nullable(),
  });

  const onSubmit = async (values, { resetForm }) => {
    props.setLoading(true);
    console.log("Dashboard Resolution Form values: ", values);
    let newStartDate = formatDateYMD(values.start_date);
    let newEndDate = formatDateYMD(values.end_date);

    const newValues = {
      ...values,
      start_date: newStartDate,
      end_date: newEndDate,
    };
    console.log("Dashboard Resolution New values: ", newValues);

    recordService
      .getDashboardResolutionData(newValues)
      .then((response) => {
        if (response.data.status === "success") {
          console.log(
            "Response from getDashboardResolutionData in ResolutionContent : ",
            response.data
          );
          const chart_data = response.data.data.result.chart_data;
          // setExperiences(records);
          props.setDashboardResolutionData(chart_data);
          props.setLoading(false);
        }
        if (response.data.status === "failed") {
          console.log("ERROR:", response.data.error);
          props.setDashboardResolutionData({});
          props.setLoading(false);
        }
      })
      .catch((err) => {
        console.log("There is a problem in creating new record...", err);
        props.setLoading(false);
      });

    // handleAlert("Error! Functionality under development.", "error");
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={resolutionFilterSchema}
        onSubmit={onSubmit}
        // enableReinitialize
      >
        {(formik) => {
          return (
            <Form className="form_style" onSubmit={formik.handleSubmit}>
              <Row className="rwo-cols-1 row-cols-sm-3 g-2">
                <Col>
                  <Form.Group className="form-group small_field rounded_corners">
                    <DashboardSectionTitle
                      iconImg=""
                      title="Start date"
                      pageTitle={false}
                      className="mb-1 small_title"
                    />
                    <Field name="start_date" size="sm">
                      {({ form, field }) => {
                        const { setFieldValue } = form;
                        const { value } = field;

                        return (
                          <DatePicker
                            id="start_date"
                            className="form-control"
                            {...field}
                            selected={value}
                            onChange={(val) => setFieldValue("start_date", val)}
                            dateFormat="yyyy-MM-dd"
                            maxDate={addDays(
                              new Date(formik.values.end_date),
                              0
                            )}
                            placeholderText="Start Date"
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            // scrollableMonthYearDropdown
                            autoComplete="off"
                          />
                        );
                      }}
                    </Field>
                    {formik.touched.start_date && formik.errors.start_date ? (
                      <p className="error-state-text">
                        {formik.errors.start_date}
                      </p>
                    ) : null}
                  </Form.Group>
                </Col>

                <Col>
                  <Form.Group className="form-group small_field rounded_corners">
                    <DashboardSectionTitle
                      iconImg=""
                      title="End date"
                      pageTitle={false}
                      className="mb-1 small_title"
                    />
                    <Field name="end_date">
                      {({ form, field }) => {
                        const { setFieldValue } = form;
                        const { value } = field;

                        return (
                          <DatePicker
                            id="end_date"
                            className="form-control"
                            {...field}
                            todayButton="Today"
                            selected={value}
                            onChange={(val) => setFieldValue("end_date", val)}
                            dateFormat="yyyy-MM-dd"
                            minDate={subDays(
                              new Date(formik.values.start_date),
                              0
                            )}
                            placeholderText="End date"
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            // scrollableMonthYearDropdown
                            autoComplete="off"
                          />
                        );
                      }}
                    </Field>
                    {formik.touched.end_date && formik.errors.end_date ? (
                      <p className="error-state-text">
                        {formik.errors.end_date}
                      </p>
                    ) : null}
                  </Form.Group>
                </Col>

                <Col>
                  <DashboardSectionTitle
                    iconImg=""
                    title="&nbsp;"
                    pageTitle={false}
                    className="mb-1 small_title"
                  />
                  <Button
                    key="submit_btn"
                    type="submit"
                    className="btn_theme btn_primary btn_small btn_rounded w-100"
                  >
                    {loading ? (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />{" "}
                        Processing
                      </>
                    ) : (
                      "Submit"
                    )}
                  </Button>
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
      <Row className="g-2 row-cols-1 border-top pt1 mt-1">
        <Col>
          <CustomCard className="bg-transparent p2 px-0 pt-0 border-0">
            {props.loading ? (
              <>
                <PlaceholderCard type="chart" />
              </>
            ) : (
              <>
                {!props.loading &&
                props.dashboardResolutionData.hasOwnProperty("labels") ? (
                  <>
                    <DashboardPieChart
                      labels={labels}
                      datasets={datasets}
                      recordCount={recordCount}
                      chart_title="Resolutions"
                      legend_list={legendList}
                    />
                  </>
                ) : (
                  <>
                    <CustomCard className="shadow1 border-0 text-center">
                      <Card.Body>
                        <div className="mb1">
                          <BsFillExclamationTriangleFill
                            style={{
                              color: "#d3af61",
                              fontSize: "2.2rem",
                            }}
                          />
                        </div>
                        <b>No more records found...</b>
                      </Card.Body>
                    </CustomCard>
                  </>
                )}
              </>
            )}

            {/* <DashboardSharedPieChart
              labels={props.resolutionChartData.labels}
              datasets={props.resolutionChartData.values}
              totalDatasets={props.resolutionChartData.total_no_of_records}
            /> */}
          </CustomCard>
        </Col>
      </Row>
    </>
  );
}

export default ResolutionContent;
