import React, { useState, useEffect, useContext } from "react";
import { useParams, useLocation } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import DashboardLeftPane from "../DashboardLeftPane/DashboardLeftPane";
import DashboardMiddlePane from "../DashboardMiddlePane/DashboardMiddlePane";
import DashboardRightPane from "../DashboardRightPane/DashboardRightPane";
import DashboardRecordSection from "../DashboardRecordSection/DashboardRecordSection";
import DashboardShareContent from "../DashboardShareContent/DashboardShareContent";
import DashboardAssociateContent from "../DashboardAssociateContent/DashboardAssociateContent";
import DashboardAnalyzeContent from "../DashboardAnalyzeContent/DashboardAnalyzeContent";
import DashboardNetworkContent from "../DashboardNetworkContent/DashboardNetworkContent";
import DashboardConsultContent from "../DashboardConsultContent/DashboardConsultContent";
import DashboardDiscoverContent from "../DashboardDiscoverContent/DashboardDiscoverContent";
import DashboardShopContent from "../DashboardShopContent/DashboardShopContent";
import DashboardAchievementsContent from "../DashboardAchievementsContent/DashboardAchievementsContent";
import DashboardBodyContent from "../DashboardBodyContent/DashboardBodyContent";
import DashboardExperienceBankContent from "../DashboardExperienceBankContent/DashboardExperienceBankContent";
import DashboardMessageContent from "../DashboardMessageContent/DashboardMessageContent";
import DashboardInvitationContent from "../DashboardInvitationContent/DashboardInvitationContent";
import DashboardConsultingSessionsContent from "../DashboardConsultingSessionsContent/DashboardConsultingSessionsContent";
import DashboardNotificationsContent from "../DashboardNotificationContent/DashboardNotificationContent";
import DashboardSettingsContent from "../DashboardSettingsContent/DashboardSettingsContent";
import DashboardVideoContent from "../DashboardVideoContent/DashboardVideoContent";

//Context
import AppContext from "../../../AppContext";

function DashboardBody(props) {
  const location = useLocation();
  const initialType = props.id || "dashboard";
  // console.log("initialType =", initialType);
  // console.log("props.page =", props.page);
  // console.log("DashboardBody - location =", location);
  const [contentType, setContentType] = useState(initialType);
  const { notifications } = useContext(AppContext);
  const notificationCount = notifications.length;

  function getContentType(type) {
    console.log("from DashboardBody ", type);
    setContentType(type);
    props.setIsNavbarOpen(false);
    window.scrollTo(0, 0);
  }

  useEffect(() => {
    console.log("In DashboardBody page =", props.page);
    // getContentType(props.page || "dashboard");
    getContentType(props.page);
    console.log("From DashboardBody scrooling...", location);
    // window.scrollTo(0, 0);
  }, [location]);

  return (
    // <PageComponent>
    <section className="body_section pb5">
      <Container>
        <Row>
          <Col
            xs={12}
            lg={4}
            xl={3}
            className={`left_bar_wrap ${props.isNavbarOpen ? "active" : ""}`}
          >
            <DashboardLeftPane
              contentType={contentType}
              getContentType={getContentType}
              notificationCount={notificationCount}
              setIsNavbarOpen={props.setIsNavbarOpen}
              isNavbarOpen={props.isNavbarOpen}
            />
          </Col>
          <Col xs={12} lg={8} xl={6}>
            <DashboardMiddlePane>
              <DashboardRecordSection
                page={props.page}
                getContentType={getContentType}
              />

              {(() => {
                switch (contentType) {
                  case "experience-bank":
                    return <DashboardExperienceBankContent />;

                  case "share":
                    return <DashboardShareContent />;

                  case "associate":
                    return <DashboardAssociateContent />;

                  case "analyze":
                    return <DashboardAnalyzeContent />;

                  case "network":
                    return <DashboardNetworkContent />;

                  // case "match":
                  //   return <DashboardMatchContent />;

                  case "consult":
                    return <DashboardConsultContent />;

                  case "discover":
                    return <DashboardDiscoverContent />;

                  case "shop":
                    return <DashboardShopContent />;

                  case "achievements":
                    return <DashboardAchievementsContent />;

                  case "dashboard":
                    return (
                      <DashboardBodyContent getContentType={getContentType} />
                    );

                  case "messages":
                    return <DashboardMessageContent />;

                  case "invitations":
                    return <DashboardInvitationContent />;

                  case "consulting-sessions":
                    return <DashboardConsultingSessionsContent />;

                  case "notifications":
                    return (
                      <DashboardNotificationsContent
                        getContentType={getContentType}
                      />
                    );

                  case "settings":
                    return <DashboardSettingsContent />;

                  case "videos":
                    return <DashboardVideoContent />;

                  default:
                    getContentType("dashboard");
                    console.log("This is going to execute everytime");
                    return (
                      <DashboardBodyContent getContentType={getContentType} />
                    );
                }
              })()}
            </DashboardMiddlePane>
          </Col>
          <Col xs={12} xl={3} className="d-none d-xl-block">
            <DashboardRightPane />
          </Col>
        </Row>
      </Container>
    </section>
    // </PageComponent>
  );
}

export default DashboardBody;
