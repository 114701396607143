import React from "react";
import { ListGroup } from "react-bootstrap";
import "./Ratings.css";
import { BsFillStarFill } from "react-icons/bs";

function Ratings(props) {
  const items = [];
  for (let i = 0; i < props.totalItems; i++) {
    items.push(i);
  }
  console.log(items);
  const classList = `${props.fillStyle ? `fill` : ""} ${props.className}`;
  return (
    <div className={`ratings ${classList}`}>
      <ListGroup className="ratings-list" horizontal>
        {items.map((item, index) => {
          return (
            <ListGroup.Item
              key={`Rating-list-${index}`}
              className={`ratings-list-item d-flex  align-items-center
								${index < props.activeItems ? `active` : ""}`}
            >
              <BsFillStarFill />
            </ListGroup.Item>
          );
        })}
      </ListGroup>
    </div>
  );
}

export default Ratings;
