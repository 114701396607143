import React, { useState, useEffect, useContext } from "react";
import "./DashboardBodyContent.css";
import "swiper/css/bundle";
import "swiper/bundle";
import { useNavigate } from "react-router-dom";
import DashboardSectionTitle from "../DashboardSectionTitle/DashboardSectionTitle";
import CustomAccordionButton from "../../../ui/CustomCollapse/CustomAccordionButton";
import CustomAccordionContainer from "../../../ui/CustomCollapse/CustomAccordionContainer";
import EngagementRecordsContent from "./EngagementRecordsContent";
import UsefulMetricsContent from "./UsefulMetricsContent";
import FavoriteRecordsContent from "./FavoriteRecordsContent";
import PopularInsightsContent from "./PopularInsightsContent";
import ResolutionContent from "./ResolutionContent";
import DashboardAchievementsCard from "./DashboardAchievementsCard";
import { formatDateYMD } from "../../../util/util";
import { bgcolors } from "../../../util/colors";

//Service
import recordService from "../../../services/Record";
//Context
import AppContext from "../../../AppContext";

function DashboardBodyContent(props) {
  const { achievements, getAllAchievements } = useContext(AppContext);
  const navigate = useNavigate();
  // const bgcolors = [
  //   "#81d4fa",
  //   "#26c6da",
  //   "#4db6ac",
  //   "#81c784",
  //   "#aed581",
  //   "#dce775",
  //   "#fff176",
  //   "#ffb74d",
  //   "#ff8a65",
  //   "#a1887f",
  //   "#90a4ae",
  //   "#42a5f5",
  //   "#7986cb",
  //   "#b39ddb",
  //   "#ba68c8",
  //   "#f48fb1",
  //   "#e57373",
  //   "#b39d9d",
  // ];
  const dashboard_elements = [
    "engagement_record",
    "useful_metrics",
    "popular_insights",
    "favorites",
    "resolution",
  ];

  const swiperParams = {
    // navigation: {
    //   nextEl: ".swiper-button-next",
    //   prevEl: ".swiper-button-prev",
    //   clickable: true,
    // },

    shouldSwiperUpdate: true,
    rebuildOnUpdate: true,
    // autoHeight: true,
    effect: "coverflow",
    coverflowEffect: {
      rotate: 50,
      stretch: 0,
      depth: 100,
      modifier: 1,
      slideShadows: true,
    },

    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    spaceBetween: 10,
    grabCursor: true,
  };

  const [loading, setLoading] = useState(false);
  const [loadingAchievements, setLoadingAchievements] = useState(false);
  const [loadingShared, setLoadingShared] = useState(false);
  const [loadingPopularWordcloud, setLoadingPopularWordcloud] = useState(false);
  const [loadingMorePopularGraphs, setLoadingMorePopularGraphs] =
    useState(false);
  const [loadingMetrics, setLoadingMetrics] = useState(false);
  const [loadingFavorites, setLoadingFavorites] = useState(false);
  const [shareExpbankData, setShareExpbankData] = useState([]);
  const [dashboardSharedGraphs, setDashboardSharedGraphs] = useState([]);
  const [usefulMetricsData, setUsefulMetricsData] = useState([]);
  const [dashboardFavoriteRecords, setDashboardFavoriteRecords] = useState([]);
  const [dashboardFavoriteGraphs, setDashboardFavoriteGraphs] = useState([]);
  const [dashboardResolutionData, setDashboardResolutionData] = useState({});
  const [dashboardPopularInsightsData, setDashboardPopularInsightsData] =
    useState({});
  const [popularInsightsFilterData, setPopularInsightsFilterData] = useState({
    target: "global",
    start_date: "",
    end_date: "",
  });
  const [dashboardPopularWordcloudData, setDashboardPopularWordcloudData] =
    useState({});
  const [popularWordcloudFilterData, setPopularWordcloudFilterData] = useState({
    target: "global",
    element: "emotions",
    end_date: "",
    start_date: "",
  });
  const [
    dashboardMorePopularInsightsData,
    setDashboardMorePopularInsightsData,
  ] = useState({});
  const [dashboardAchievementData, setDashboardAchievementData] = useState({});
  const [rank, setRank] = useState({});

  const today = formatDateYMD(new Date());

  let mainType = "share";
  let actions = ["share", "add_comment", "view_comments"];
  console.log("In DashboardBodyContent Props received = ", props);
  // let history = useHistory();

  function handleClick(e) {
    const currentType = e.target.dataset.type;
    // console.log("Type: ", currentType);
    // console.log(e);
    // history.push(`/dashboard/${currentType}`);
    navigate(`/dashboard/${currentType}`);
    props.getContentType(currentType);
  }

  useEffect(() => {
    setLoading(true);
    getAllAchievements();
    getDashboardSharedData();
    async function getData() {
      if (shareExpbankData.length == 0) {
        setLoading(true);
      }

      recordService
        .getAllSharedRecords()
        .then((response) => {
          if (response.data.status === "success") {
            // console.log("Response from APP : ", response.data);
            const records = response.data.data.result.records;

            let updatedRecords;
            updatedRecords = records.slice(0, 5).map((record, index) => {
              return {
                ...record,
                mainType: "share",
                actions: ["view_details"],
                visibleOnDashbord: true,
              };
            });
            setShareExpbankData(updatedRecords);
            setLoading(false);
          }
          if (response.data.status === "failed") {
            console.log("ERROR:", response.data.error);
          }
        })
        .catch((err) => {
          console.log("There is a problem in getting shared records...", err);
          setLoading(false);
        });
    }
    getData();
  }, []);

  // async function getAllAchievements() {
  //   setLoadingAchievements(true);
  //   recordService
  //     .getAllAchievements()
  //     .then((response) => {
  //       if (response.data.status === "success") {
  //         console.log("getAllAchievements service res = ", response.data);
  //         const achievements = response.data.data.result.acheivement;
  //         const rankData = response.data.data.result.rank;

  //         setDashboardAchievementData(achievements);
  //         setRank(rankData);
  //         setLoadingAchievements(false);
  //       } else if (response.data.status === "failed") {
  //         console.log("ERROR:", response.data.error);
  //         setLoadingAchievements(false);
  //         setDashboardAchievementData({});
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }

  async function getDashboardSharedData() {
    setLoadingShared(true);
    recordService
      .getDashboardSharedData()
      .then((response) => {
        if (response.data.status === "success") {
          // console.log(
          //   "Response from getDashboardSharedData on dashboard : ",
          //   response.data
          // );
          const graphs = response.data.data.result.graphs;

          setDashboardSharedGraphs(graphs);
          setLoadingShared(false);
        }
        if (response.data.status === "failed") {
          console.log("ERROR:", response.data.error);
          setDashboardSharedGraphs([]);
          setLoadingShared(false);
        }
      })
      .catch((err) => {
        console.log(
          "There is a problem in fetching dashboard favorites data...",
          err
        );
        setLoadingShared(false);
      });
  }

  async function getDashboardPopularInsightsData(
    data = {
      target: "global",
      end_date: "2022-04-29",
      start_date: "2021-02-29",
    }
  ) {
    setLoading(true);
    // const data = {
    //   target: "friends",
    //   end_date: "2022-04-29",
    //   start_date: "2021-02-29",
    // };
    // let popularGraphsData = [];
    recordService
      .getDashboardPopularInsightsData(data)
      .then((response) => {
        if (response.data.status === "success") {
          console.log(
            "Response from getDashboardPopularInsightsData on dashboard : ",
            response.data
          );
          const patterns = response.data.data.result.patterns;
          // console.log("Pattern - emotions = ", Object.values(patterns));
          const tempKeys = Object.keys(patterns);
          const graphData = Object.values(patterns).map((pattern, index) => {
            return { ...pattern, type: tempKeys[index] };
          });
          // console.log(
          //   "getDashboardPopularInsightsData - graphData =",
          //   graphData
          // );

          setDashboardPopularInsightsData(graphData);
          setPopularInsightsFilterData(data);
          setLoading(false);
        }
        if (response.data.status === "failed") {
          console.log("ERROR:", response.data.error);
          setDashboardPopularInsightsData([]);
          setPopularInsightsFilterData({
            target: "global",
            start_date: "",
            end_date: "",
          });
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(
          "There is a problem in fetching dashboard favorites data...",
          err
        );
        setLoading(false);
      });
  }

  function getDashboardPopularWordcloudData(
    data = {
      target: "global",
      element: "emotions",
      end_date: "2022-04-29",
      start_date: "2021-02-29",
    }
  ) {
    setLoadingPopularWordcloud(true);
    // const data = {
    //   target: "friends",
    //   end_date: "2022-04-29",
    //   start_date: "2021-02-29",
    // };
    // let popularGraphsData = [];
    recordService
      .getDashboardPopularWordcloudData(data)
      .then((response) => {
        if (response.data.status === "success") {
          // console.log(
          //   "Response from getDashboardPopularWordcloudData on dashboard : ",
          //   response.data
          // );
          const patterns = response.data.data.result.patterns;
          // console.log("Pattern - emotions = ", Object.values(patterns));

          setDashboardPopularWordcloudData(patterns);
          setPopularWordcloudFilterData(data);
          setLoadingPopularWordcloud(false);
        }
        if (response.data.status === "failed") {
          console.log("ERROR:", response.data.error);
          setDashboardPopularWordcloudData({});
          setPopularWordcloudFilterData({
            target: "global",
            element: "emotions",
            start_date: "",
            end_date: "",
          });
          setLoadingPopularWordcloud(false);
        }
      })
      .catch((err) => {
        console.log(
          "There is a problem in fetching dashboard favorites data...",
          err
        );
        setLoadingPopularWordcloud(false);
      });
  }

  function getDashboardMorePopularInsightsData() {
    setLoadingMorePopularGraphs(true);
    // const data = {
    //   target: "friends",
    //   end_date: "2022-04-29",
    //   start_date: "2021-02-29",
    // };
    // let popularGraphsData = [];
    recordService
      .getDashboardMorePopularInsightsData()
      .then((response) => {
        if (response.data.status === "success") {
          console.log(
            "Response from getDashboardMorePopularGraphsData on dashboard : ",
            response.data
          );
          const result = response.data.data.result;
          // console.log("Pattern - emotions = ", Object.values(patterns));

          setDashboardMorePopularInsightsData(result);
          // setPopularWordcloudFilterData(data);
          setLoadingMorePopularGraphs(false);
        }
        if (response.data.status === "failed") {
          console.log("ERROR:", response.data.error);
          setDashboardMorePopularInsightsData({});
          // setPopularWordcloudFilterData({
          //   target: "global",
          //   element: "emotions",
          //   start_date: "",
          //   end_date: "",
          // });
          setLoadingMorePopularGraphs(false);
        }
      })
      .catch((err) => {
        console.log(
          "There is a problem in fetching dashboard favorites data...",
          err
        );
        setLoadingMorePopularGraphs(false);
      });
  }

  function getUsefulMetricsData() {
    const data = {
      end_date: "2022-08-29",
      start_date: "2021-02-29",
    };
    setLoadingMetrics(true);
    recordService
      .getUsefulMetricsData(data)
      .then((response) => {
        if (response.data.status === "success") {
          // console.log("Response from APP : ", response.data);
          const stats = response.data.data.result.stats;
          // setExperiences(records);
          setUsefulMetricsData(stats);
          setLoadingMetrics(false);
        }
        if (response.data.status === "failed") {
          console.log("ERROR:", response.data.error);
          setUsefulMetricsData([]);
          setLoadingMetrics(false);
        }
      })
      .catch((err) => {
        console.log(
          "There is a problem in fetching dashboard useful metrics data...",
          err
        );
        setLoadingMetrics(false);
      });
  }

  function getDashboardFavoriteData() {
    setLoadingFavorites(true);
    recordService
      .getDashboardFavoriteData()
      .then((response) => {
        if (response.data.status === "success") {
          // console.log("Response from APP : ", response.data);
          const records = response.data.data.result.favorites;
          const graphs = response.data.data.result.graphs;
          const chart_data = response.data.data.result.chart_data;

          let updatedRecords;
          updatedRecords = records.map((record) => {
            return {
              ...record,
              mainType: "exp_bank",
              actions: ["view_details"],
              visibleOnDashbord: true,
            };
          });

          console.log("updatedRecords =", updatedRecords);

          setDashboardFavoriteRecords(updatedRecords);
          setDashboardFavoriteGraphs(graphs);
          setLoadingFavorites(false);
        }
        if (response.data.status === "failed") {
          console.log("ERROR:", response.data.error);
          setDashboardFavoriteRecords([]);
          setDashboardFavoriteGraphs([]);
          setLoadingFavorites(false);
        }
      })
      .catch((err) => {
        console.log(
          "There is a problem in fetching dashboard favorite data...",
          err
        );
        setLoadingFavorites(false);
      });
  }

  function getDashboardResolutionData() {
    const data = {
      end_date: today,
      start_date: "2021-02-28",
    };
    setLoading(true);
    recordService
      .getDashboardResolutionData(data)
      .then((response) => {
        if (response.data.status === "success") {
          // console.log("Response from APP : ", response.data);
          const chart_data = response.data.data.result.chart_data;
          // setExperiences(records);
          setDashboardResolutionData(chart_data);
          setLoading(false);
        }
        if (response.data.status === "failed") {
          console.log("ERROR:", response.data.error);
          setDashboardResolutionData({});
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(
          "There is a problem in fetching dashboard resolutions data...",
          err
        );
        setLoading(false);
      });
  }

  /*Collapsible actions: add_association, view_associations, 
    share, add_comment, view_comments, match, view_match, connect, edit, delete, decode, view_details */
  //collapsible state
  const [openCollapse, setOpenCollapse] = useState({
    engagement_record_collapse: false,
    useful_metrics_collapse: false,
    popular_insights_collapse: false,
    favorites_collapse: false,
    resolution_collapse: false,
  });
  let tempCollapseState = null;
  const handleCollapse = (element) => {
    switch (element) {
      case "engagement_record":
        tempCollapseState = Object.fromEntries(
          Object.keys({ ...openCollapse }).map((key) => [key, false])
        );
        setOpenCollapse({
          ...tempCollapseState,
          engagement_record_collapse: !openCollapse.engagement_record_collapse,
        });
        break;
      case "useful_metrics":
        tempCollapseState = Object.fromEntries(
          Object.keys({ ...openCollapse }).map((key) => [key, false])
        );
        setOpenCollapse({
          ...tempCollapseState,
          useful_metrics_collapse: !openCollapse.useful_metrics_collapse,
        });
        break;
      case "popular_insights":
        tempCollapseState = Object.fromEntries(
          Object.keys({ ...openCollapse }).map((key) => [key, false])
        );
        setOpenCollapse({
          ...tempCollapseState,
          popular_insights_collapse: !openCollapse.popular_insights_collapse,
        });
        break;
      case "favorites":
        tempCollapseState = Object.fromEntries(
          Object.keys({ ...openCollapse }).map((key) => [key, false])
        );
        setOpenCollapse({
          ...tempCollapseState,
          favorites_collapse: !openCollapse.favorites_collapse,
        });
        break;
      case "resolution":
        tempCollapseState = Object.fromEntries(
          Object.keys({ ...openCollapse }).map((key) => [key, false])
        );
        setOpenCollapse({
          ...tempCollapseState,
          resolution_collapse: !openCollapse.resolution_collapse,
        });
        break;
    }
  };

  return (
    <>
      <DashboardSectionTitle
        iconImg="offer-icon13.png"
        title="My Dashboard"
        pageTitle={true}
        className="mb3"
      />

      <DashboardAchievementsCard
        loadingAchievements={loadingAchievements}
        achievements={achievements}
        handleClick={handleClick}
      />

      <div class="d-grid gap-3">
        {dashboard_elements?.map((dashboard_element, index) => {
          switch (dashboard_element) {
            case "engagement_record":
              return (
                <>
                  <div className="custom_accordion_wrap shadow1">
                    <CustomAccordionButton
                      key={`DE-${index}`}
                      handleCollapse={handleCollapse}
                      btnTitle="Engagement Record"
                      iconImg="engagement-records.png"
                      className="w-100"
                      ariaControls="engagement_record"
                      ariaExpanded={openCollapse.engagement_record_collapse}
                      // setCardTop={setCardTop}
                    />
                    <CustomAccordionContainer
                      key={`DEC-${index}`}
                      ariaControls="engagement_record"
                      openCollapse={openCollapse.engagement_record_collapse}
                      getDashboardSharedData={getDashboardSharedData}
                      // cardTop={cardTop}
                    >
                      <EngagementRecordsContent
                        shareExpbankData={shareExpbankData}
                        swiperParams={swiperParams}
                        dashboardSharedGraphs={dashboardSharedGraphs}
                        loadingShared={loadingShared}
                      />
                    </CustomAccordionContainer>
                  </div>
                </>
              );
            case "useful_metrics":
              return (
                <>
                  <div className="custom_accordion_wrap shadow1">
                    <CustomAccordionButton
                      key={`DE-${index}`}
                      handleCollapse={handleCollapse}
                      btnTitle="Useful Metrics"
                      iconImg="useful-metrics.png"
                      className="w-100"
                      ariaControls="useful_metrics"
                      ariaExpanded={openCollapse.useful_metrics_collapse}
                      // setCardTop={setCardTop}
                    />
                    <CustomAccordionContainer
                      key={`DEC-${index}`}
                      ariaControls="useful_metrics"
                      openCollapse={openCollapse.useful_metrics_collapse}
                      getUsefulMetricsData={getUsefulMetricsData}
                      // cardTop={cardTop}
                    >
                      <div className="">
                        <UsefulMetricsContent
                          loading={loadingMetrics}
                          setLoading={setLoadingMetrics}
                          usefulMetricsData={usefulMetricsData}
                          setUsefulMetricsData={setUsefulMetricsData}
                        />
                      </div>
                    </CustomAccordionContainer>
                  </div>
                </>
              );
            case "popular_insights":
              return (
                <>
                  <div className="custom_accordion_wrap shadow1">
                    <CustomAccordionButton
                      key={`DE-${index}`}
                      handleCollapse={handleCollapse}
                      btnTitle="Popular Insights"
                      iconImg="popular-insights.png"
                      className="w-100"
                      ariaControls="popular_insights"
                      ariaExpanded={openCollapse.popular_insights_collapse}
                      // setCardTop={setCardTop}
                    />
                    <CustomAccordionContainer
                      key={`DEC-${index}`}
                      ariaControls="popular_insights"
                      openCollapse={openCollapse.popular_insights_collapse}
                      getDashboardPopularInsightsData={
                        getDashboardPopularInsightsData
                      }
                      getDashboardPopularWordcloudData={
                        getDashboardPopularWordcloudData
                      }
                      getDashboardMorePopularInsightsData={
                        getDashboardMorePopularInsightsData
                      }
                      // cardTop={cardTop}
                    >
                      <div className="">
                        <PopularInsightsContent
                          swiperParams={swiperParams}
                          dashboardPopularInsightsData={
                            dashboardPopularInsightsData
                          }
                          getDashboardPopularInsightsData={
                            getDashboardPopularInsightsData
                          }
                          loading={loading}
                          setLoading={setLoading}
                          popularInsightsFilterData={popularInsightsFilterData}
                          dashboardPopularWordcloudData={
                            dashboardPopularWordcloudData
                          }
                          loadingPopularWordcloud={loadingPopularWordcloud}
                          getDashboardPopularWordcloudData={
                            getDashboardPopularWordcloudData
                          }
                          popularWordcloudFilterData={
                            popularWordcloudFilterData
                          }
                          dashboardMorePopularInsightsData={
                            dashboardMorePopularInsightsData
                          }
                        />
                      </div>
                    </CustomAccordionContainer>
                  </div>
                </>
              );
            case "favorites":
              return (
                <>
                  <div className="custom_accordion_wrap shadow1">
                    <CustomAccordionButton
                      key={`DE-${index}`}
                      handleCollapse={handleCollapse}
                      btnTitle="Favorites"
                      iconImg="favorites.png"
                      className="w-100"
                      ariaControls="favorites"
                      ariaExpanded={openCollapse.favorites_collapse}
                      // setCardTop={setCardTop}
                    />
                    <CustomAccordionContainer
                      key={`DEC-${index}`}
                      ariaControls="favorites"
                      openCollapse={openCollapse.favorites_collapse}
                      getDashboardFavoriteData={getDashboardFavoriteData}
                      // cardTop={cardTop}
                    >
                      <div className="">
                        <FavoriteRecordsContent
                          shareExpbankData={shareExpbankData}
                          swiperParams={swiperParams}
                          dashboardFavoriteRecords={dashboardFavoriteRecords}
                          dashboardFavoriteGraphs={dashboardFavoriteGraphs}
                          loadingFavorites={loadingFavorites}
                        />
                      </div>
                    </CustomAccordionContainer>
                  </div>
                </>
              );
            case "resolution":
              return (
                <>
                  <div className="custom_accordion_wrap shadow1">
                    <CustomAccordionButton
                      key={`DE-${index}`}
                      handleCollapse={handleCollapse}
                      btnTitle="Resolution"
                      iconImg="resolutions.png"
                      className="w-100"
                      ariaControls="resolution"
                      ariaExpanded={openCollapse.resolution_collapse}
                      // setCardTop={setCardTop}
                    />
                    <CustomAccordionContainer
                      key={`DEC-${index}`}
                      ariaControls="resolution"
                      openCollapse={openCollapse.resolution_collapse}
                      getDashboardResolutionData={getDashboardResolutionData}
                      // cardTop={cardTop}
                    >
                      <div className="">
                        <ResolutionContent
                          dashboardResolutionData={dashboardResolutionData}
                          setDashboardResolutionData={
                            setDashboardResolutionData
                          }
                          bgcolors={bgcolors}
                          loading={loading}
                          setLoading={setLoading}
                        />
                      </div>
                    </CustomAccordionContainer>
                  </div>
                </>
              );
          }
        })}
      </div>
    </>
  );
}

export default DashboardBodyContent;
