import React from "react";
import "./FieldError.css";

function FieldError(props) {
  // console.log("Error props =", props);
  return (
    <>
      <p className={`field-error ${props.valid && "active"}`}>
        <span>{props.valid && props.text ? props.text : null}</span>
      </p>
      {/* {props.valid ? (
        <p className={`field-error active`}>
          <span>{props.text ? props.text : null}</span>
        </p>
      ) : (
        <p className={`field-error`}>
          <span>{props.text ? props.text : null}</span>
        </p>
      )} */}
    </>
  );
}

export default FieldError;
