import React from "react";
import { ListGroup } from "react-bootstrap";
import "./ChartLegendList.css";

function ChartLegendList(props) {
  return (
    <>
      {props.legendList.length > 0 ? (
        <ListGroup className="chart-legend-list flex-wrap" horizontal>
          {props.legendList.map((item, index) => (
            <ListGroup.Item
              key={`l-${index}`}
              className="chart-legend-list-item d-flex  align-items-center"
            >
              <span
                className="d-inline-block chart-color-dot"
                style={{ background: item.color }}
              ></span>
              <span className="chart-legend-label">
                {`${item.label}: (${item.value})`}
              </span>
            </ListGroup.Item>
          ))}
        </ListGroup>
      ) : null}
    </>
  );
}

export default ChartLegendList;
