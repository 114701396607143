import React, { useState, useEffect, useContext } from "react";
import { Nav, Tab } from "react-bootstrap";
import CustomCard from "../../../ui/CustomCard/CustomCard";
import DashboardSectionTitle from "../DashboardSectionTitle/DashboardSectionTitle";
import ComposeMessage from "./ComposeMessage";
import MessagesInbox from "./MessagesInbox";
import MessagesSent from "./MessagesSent";
import MessagesArchieved from "./MessagesArchieved";
import MessagesTrash from "./MessagesTrash";
import { handleAlert } from "../../../util/util";

//Context
import AppContext from "../../../AppContext";

//Service
import recordService from "../../../services/Record";

function DashboardMessageContent() {
  const { friends } = useContext(AppContext);
  const [activeKey, setActiveKey] = useState("message_inbox");
  const [editValues, setEditValues] = useState({});
  const [inboxMessages, setInboxMessages] = useState([]);
  const [sentMessages, setSentMessages] = useState([]);
  const [archiveMessages, setArchiveMessages] = useState([]);
  const [trashMessages, setTrashMessages] = useState([]);

  useEffect(() => {
    getAllInboxMessages();
  }, []);

  function handleAction(actionBody) {
    if (actionBody["action"]) {
      setActiveKey(actionBody["action"]);
      setEditValues(actionBody["data"]);
    } else {
      setActiveKey("");
      setEditValues({});
    }
  }

  // async function getAllFriends() {
  //   recordService
  //     .getAllFriends()
  //     .then((response) => {
  //       console.log(response.data);
  //       setFriends(response.data.data.result.friends);

  //       // setTimeout(() => {
  //       console.log("All friends from message content =", friends);
  //       // }, 3000);
  //     })
  //     .catch((err) => {
  //       console.log("There is a problem in getting all friends...", err);
  //     });
  // }

  async function getAllInboxMessages() {
    recordService
      .getAllInboxMessages()
      .then((response) => {
        if (response.data.status === "success") {
          // console.log("Response from Inbox : ", response.data);
          const messages = response.data.data.result.messages;
          setInboxMessages(messages);
        }
        if (response.data.status === "failed") {
          console.log("ERROR:", response.data.error);
        }
      })
      .catch((err) => {
        console.log("There is a problem in getting inbox messages...", err);
      });
  }

  async function getAllSentMessages() {
    recordService
      .getAllSentMessages()
      .then((response) => {
        if (response.data.status === "success") {
          // console.log("Response from APP : ", response.data);
          const messages = response.data.data.result.messages;
          setSentMessages(messages);
        }
        if (response.data.status === "failed") {
          console.log("ERROR:", response.data.error);
        }
      })
      .catch((err) => {
        console.log("There is a problem in getting sent messages...", err);
      });
  }
  async function getAllArchivedMessages() {
    recordService
      .getAllArchivedMessages()
      .then((response) => {
        if (response.data.status === "success") {
          // console.log("Response from APP : ", response.data);
          const messages = response.data.data.result.messages;
          setArchiveMessages(messages);
        }
        if (response.data.status === "failed") {
          console.log("ERROR:", response.data.error);
        }
      })
      .catch((err) => {
        console.log("There is a problem in getting archived messages...", err);
      });
  }
  async function getAllTrashedMessages() {
    recordService
      .getAllTrashedMessages()
      .then((response) => {
        if (response.data.status === "success") {
          // console.log("Response from APP : ", response.data);
          const messages = response.data.data.result.messages;
          setTrashMessages(messages);
        }
        if (response.data.status === "failed") {
          console.log("ERROR:", response.data.error);
        }
      })
      .catch((err) => {
        console.log("There is a problem in getting trashed messages...", err);
      });
  }

  //Mark message as Archive
  async function markMessageArchived(msg_id) {
    recordService
      .markMessageArchived(msg_id)
      .then((response) => {
        if (response.data.status === "success") {
          // console.log(response.data);
          handleAlert(response.data.message, "success");

          getAllInboxMessages();
          getAllTrashedMessages();
        } else if (response.data.status === "failed") {
          // console.log("ERROR:", response.data.error);
          handleAlert(response.data.error, "error");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //Mark message as Trash
  async function markMessageTrashed(msg_id) {
    recordService
      .markMessageTrashed(msg_id)
      .then((response) => {
        if (response.data.status === "success") {
          // console.log(response.data);
          handleAlert(response.data.message, "success");

          getAllInboxMessages();
          getAllArchivedMessages();
        } else if (response.data.status === "failed") {
          // console.log("ERROR:", response.data.error);
          handleAlert(response.data.error, "error");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const handleSelectedTab = (key) => {
    console.log("Key in handleSelectedTab =", key);
    setEditValues({});
    if (key == "message_compose") {
      // console.log("Entering into message compose");
    }
    if (key == "message_inbox") {
      // console.log("Entering into message inbox");
    }
    if (key == "message_sent") {
      // console.log("Entering into message sent");
      //fetch sent messages from db
      getAllSentMessages();
    }
    if (key == "message_archive") {
      //fetch archived messages from db
      getAllArchivedMessages();
    }
    if (key == "message_trash") {
      //fetch trashed messages from db
      getAllTrashedMessages();
    }
    setActiveKey(key);
  };

  return (
    <>
      <DashboardSectionTitle
        iconImg="offer-icon14.png"
        title="Messages"
        pageTitle={true}
        className="mb2"
      />
      <CustomCard
        className={`main_card messages_content_card border-0 bg_transparent mb2`}
      >
        <Tab.Container
          id="messages_tabs"
          className="main_tabs"
          activeKey={activeKey}
          onSelect={(key) => handleSelectedTab(key)}
        >
          <div className="tab_links">
            <Nav variant="pills" className="nav-justified">
              <Nav.Item>
                <Nav.Link className="normal_link" eventKey="message_compose">
                  <div className="">Compose</div>
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link className="normal_link" eventKey="message_inbox">
                  <div className="">Inbox</div>
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link className="normal_link" eventKey="message_sent">
                  <div className="">Sent</div>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className="normal_link" eventKey="message_archive">
                  <div className="">Archived</div>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className="normal_link" eventKey="message_trash">
                  <div className="">Trash</div>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
          <Tab.Content>
            <Tab.Pane className="" eventKey="message_compose" unmountOnExit>
              <ComposeMessage
                initialValues={editValues}
                action={activeKey}
                handleAction={handleAction}
                friends={friends}
              />
            </Tab.Pane>
            <Tab.Pane className="" eventKey="message_inbox">
              <MessagesInbox
                handleAction={handleAction}
                inboxMessages={inboxMessages}
                markMessageArchived={markMessageArchived}
                markMessageTrashed={markMessageTrashed}
              />
            </Tab.Pane>
            <Tab.Pane className="" eventKey="message_sent">
              <MessagesSent
                handleAction={handleAction}
                sentMessages={sentMessages}
              />
            </Tab.Pane>
            <Tab.Pane className="" eventKey="message_archive">
              <MessagesArchieved
                handleAction={handleAction}
                archiveMessages={archiveMessages}
                markMessageTrashed={markMessageTrashed}
              />
            </Tab.Pane>
            <Tab.Pane className="" eventKey="message_trash">
              <MessagesTrash
                handleAction={handleAction}
                trashMessages={trashMessages}
                markMessageArchived={markMessageArchived}
              />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </CustomCard>
    </>
  );
}

export default DashboardMessageContent;
