import React, { useState } from "react";
import { Form, Button, Spinner } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import { handleAlert } from "../../util/util";
import FieldError from "../../ui/FieldError/FieldError";
import { BsArrowClockwise } from "react-icons/bs";
//Service
import authService from "../../services/Auth";

function SetNewPassword({
  registeredEmail,
  setIsPasswordChanged,
  handleClose,
}) {
  const [loading, setLoading] = useState(false);
  const [loadingResend, setLoadingResend] = useState(false);

  const initialValues = {
    confirmation_code: "",
    password: "",
  };
  const setNewPasswordSchema = yup.object({
    confirmation_code: yup.string().required("Required"),
    password: yup.string().min(8, "Password is too short").required("Required"),
  });

  const handleResendCode = (data) => {
    // alert("registered email in forgot password resend code: ", registeredEmail);
    setLoadingResend(true);
    authService
      .resendVerificationEmail(data)
      .then((response) => {
        if (response.data.status === "success") {
          console.log("Response by resendVerificationEmail =", response.data);
          console.log("Verification code sent on email!");
          handleAlert("Verification code sent on email!", "success");
          setLoadingResend(false);
        } else {
          console.log("ERROR:", response.data.error);
          handleAlert("Failed to send verification code!", "error");
          setLoadingResend(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoadingResend(false);
      });
  };

  const onSubmit = async (values, { resetForm }) => {
    setLoading(true);
    console.log("Form Values :", values);
    console.log("Registered Email: ", registeredEmail);

    const newValues = {
      email: registeredEmail,
      confirmation_code: values.confirmation_code,
      password: values.password,
    };
    console.log("New values : ", newValues);
    console.log("New Val REG email: ", newValues.email);
    authService
      .setNewPassword(newValues)
      .then((response) => {
        if (response.data.status === "success") {
          console.log("New Pass Response: ", response.data);
          console.log(
            "New Pass Response Actual result: ",
            response.data.data.result
          );
          console.log("Your password has been successfully changed!");
          handleAlert(
            "Your password has been successfully changed!",
            "success"
          );
          resetForm();
          setLoading(false);
          setIsPasswordChanged("failed");
          handleClose();
          //   authService.setToken(response.data.data.result);
        } else {
          console.log("ERROR:", response.data.error);
          handleAlert(
            "There is a problem while setting your new password",
            "error"
          );
          console.log("Resetting a form...");
          resetForm();
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        resetForm();
      });
  };

  return (
    <>
      <p>
        <strong>
          Enter verification code and a new password.
          {/* Enter verification code received on your email and new password!! */}
        </strong>
      </p>
      {/* <div className="text-end">
        <Button
          type="button"
          className="btn_theme btn_small btn_link btn_secondary pt-1 pb-1 mb-1"
          disabled={loadingResend}
          onClick={() => {
            handleResendCode({ email: registeredEmail });
          }}
        >
          <BsArrowClockwise className="font-16" /> Resend code
        </Button>
      </div> */}
      <Formik
        initialValues={initialValues}
        validationSchema={setNewPasswordSchema}
        onSubmit={onSubmit}
      >
        {(formik) => {
          return (
            <Form onSubmit={formik.handleSubmit}>
              <Form.Group className="form-group rounded_corners">
                <Form.Control
                  type="text"
                  placeholder="Verification code"
                  name="confirmation_code"
                  {...formik.getFieldProps("confirmation_code")}
                />
                <Form.Label className="label_float">
                  Verification code
                </Form.Label>
                <FieldError
                  valid={
                    formik.touched.confirmation_code &&
                    formik.errors.confirmation_code
                      ? true
                      : false
                  }
                  text={formik.errors.confirmation_code}
                />
              </Form.Group>

              <Form.Group className="form-group rounded_corners">
                <Form.Control
                  type="password"
                  placeholder="Password"
                  name="password"
                  {...formik.getFieldProps("password")}
                />
                <Form.Label className="label_float">Password</Form.Label>
                <FieldError
                  valid={
                    formik.touched.password && formik.errors.password
                      ? true
                      : false
                  }
                  text={formik.errors.password}
                />
              </Form.Group>

              <Button
                type="submit"
                disabled={!formik.isValid}
                className="btn_theme btn_theme_gradient btn_rounded btn_block mb1"
              >
                {loading ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />{" "}
                    Loading
                  </>
                ) : (
                  "Submit"
                )}
              </Button>
            </Form>
          );
        }}
      </Formik>
      <div className="text-center pt-2">
        <p className="mb-0">Didn't receive a code?</p>
        <Button
          type="button"
          className="btn_theme btn_small btn_link btn_secondary pt-1 pb-1"
          disabled={loadingResend}
          onClick={() => {
            handleResendCode({ email: registeredEmail });
          }}
        >
          <BsArrowClockwise className="font-16" /> Resend code
        </Button>
      </div>
    </>
  );
}

export default SetNewPassword;
