import React from "react";
import { Card } from "react-bootstrap";
import CustomCard from "../CustomCard/CustomCard";
import "./SimpleMessageCard.css";
import {
  BsExclamationCircleFill,
  BsCheckCircleFill,
  BsFillInfoCircleFill,
  BsXDiamondFill,
  BsXCircleFill,
} from "react-icons/bs";

function SimpleMessageCard(props) {
  let iconObj = null;
  switch (props.type) {
    case "warning":
      iconObj = <BsExclamationCircleFill style={{ color: "#d3af61" }} />;
      break;
    case "success":
      iconObj = <BsCheckCircleFill style={{ color: "#70c37a" }} />;
      break;
    case "info":
      iconObj = <BsFillInfoCircleFill style={{ color: "#38c0ef" }} />;
      break;
    case "danger":
      iconObj = <BsXCircleFill style={{ color: "#ff755e" }} />;
      break;
    default:
      iconObj = <BsXDiamondFill style={{ color: "#999999" }} />;
  }
  return (
    <>
      <CustomCard className="main_card simple_msg_card shadow1 border-0 text-center">
        <Card.Body className="pb-3 pt-3">
          {props.allowIcon && <div className={`icon_wrap mb-2`}>{iconObj}</div>}

          <>{props.msg ? props.msg : "Found nothing!"}</>
        </Card.Body>
      </CustomCard>
    </>
  );
}

export default SimpleMessageCard;
