import React, { useState } from "react";
import { Card } from "react-bootstrap";
import CustomCard from "../../../ui/CustomCard/CustomCard";
import "./DashboardCheckboxCard.css";

function DashboardCheckboxCard({
  labelInitial,
  item,
  name,
  isDisabled,
  handleChange,
  formik,
  isChecked,
  initialValues,
}) {
  const [newValues, setNewValues] = useState(initialValues);

  const iconImgUrl = `${process.env.PUBLIC_URL}/img/emoji/${item.imgUrl}`;
  const iconTitle = item.name;
  const iconId =
    (labelInitial ? `${labelInitial}-${name}-` : "") +
    item.name.toLowerCase().split(" ").join("-");

  const disabledInput = {};
  if (isDisabled) {
    disabledInput.disabled = true;
  } else {
    disabledInput.disabled = false;
  }
  // console.log("checked =", isChecked);

  // function handleChanger(e) {
  //   console.log("In Check compo =", e.target.value);
  //   if (newValues?.includes(e.target.value)) {
  //     console.log("Inside if =", e.target.value);
  //     // newValues.splice(1, newValues.indexOf(e.target.value));
  //     console.log("Target value index =", newValues?.indexOf(e.target.value));
  //     // let newValuesTemp = newValues?.splice(
  //     //   1,
  //     //   newValues?.indexOf(e.target.value)
  //     // );

  //     newValues?.splice(1, newValues?.indexOf(e.target.value));
  //     let newValuesTemp = newValues;

  //     console.log("New values in if =", newValuesTemp);
  //     setNewValues(newValuesTemp);
  //   } else {
  //     console.log("Inside else =", e.target.value);
  //     // let newValuesTemp = newValues?.unshift(e.target.value);
  //     newValues?.unshift(e.target.value);
  //     let newValuesTemp = newValues;

  //     console.log("New values in else =", newValuesTemp);
  //     setNewValues(newValuesTemp);
  //   }
  //   console.log("Initial = ", newValues);
  //   initialValues = newValues;
  //   // setTimeout(() => {
  //   handleChange(e);
  //   // }, 1000);
  // }

  function handleChanger(e) {
    if (initialValues?.includes(e.target.value)) {
      console.log(
        "Target value index =",
        initialValues?.indexOf(e.target.value)
      );
      // if (initialValues.length == 1) {
      //   initialValues.length = 0
      // } else {
      initialValues?.splice(initialValues?.indexOf(e.target.value), 1);
      // }
      console.log("New values in if =", initialValues);
    } else {
      console.log("Inside else =", e.target.value);
      initialValues?.push(e.target.value);
      console.log("New values in else =", initialValues);
    }
    // formik.setFieldValue(name, initialValues)
    console.log(formik);
    handleChange(e);
  }

  return (
    <CustomCard className="checkbox_card border-0">
      <input
        type="checkbox"
        id={iconId}
        name={name}
        value={item.value}
        onChange={handleChanger}
        {...disabledInput}
        checked={isChecked || false}
      />
      <label for={iconId}>
        <Card.Body>
          <img
            src={iconImgUrl}
            className="img-fluid mx-auto"
            alt={`${iconTitle} Icon`}
          />
          <div className="checkbox_title">{iconTitle}</div>
        </Card.Body>
      </label>
    </CustomCard>
  );
}

export default DashboardCheckboxCard;
