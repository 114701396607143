import React, { useState } from "react";
import { Container } from "react-bootstrap";
import HomeGuestHeader from "../components/HomeGuestHeader/HomeGuestHeader";
import HomeInnerPageBanner from "../components/HomeInnerPageBanner/HomeInnerPageBanner";
import HomeSectionHeading from "../components/HomeSectionHeading/HomeSectionHeading";
import HomeFeaturesSlider from "../components/HomeFeaturesSlider/HomeFeaturesSlider";
import HomeTeam from "../components/HomeTeam/HomeTeam";
import HomeNormalBanner from "../components/HomeNormalBanner/HomeNormalBanner";
import HomeFooter from "../components/HomeFooter/HomeFooter";
import PageComponent from "../components/PageComponent/PageComponent";

//data
import { features } from "../util/publicData";

function About() {
  const [featuresSlides] = useState(features);
  return (
    <>
      <HomeGuestHeader />
      <PageComponent>
        <HomeInnerPageBanner className="about_banner"></HomeInnerPageBanner>
        <div className="inner_page">
          <Container className="pt5 pb5">
            <HomeSectionHeading
              htmlTag="h2"
              type="m"
              titleText="The Vision of uDreamed"
              className="mb2"
            />
            <p className="font-24 fw_l mb3">
              To enable people around the world to tap the power of the
              unconscious mind to realize their full potential and improve their
              quality of life. The company's core service offerings include a
              mobile application and a website that allow users to record,
              associate, analyze, match and share their unconscious experiences
              (such as dreams) and consult professionals to gain unique
              insights.
            </p>
            <p>
              Dreams are only one type of unconscious experience, but have you
              ever wondered "what does my dream mean?" or wanted dream
              interpretation or to learn about various symbolic dream meanings?
              Our service provides critical analytics, such as dream analysis,
              so that you can better leverage the power of your subconscious
              mind.
            </p>
            <p>
              All of us possess powerful unconscious traits, emotions, memories,
              desires, motivations, inspirations, defenses and bias we are
              unaware of. These "hidden" processes operate outside of our
              awareness and control much of who we are, influencing our daily
              decisions, mood, behavior, preferences, perspectives, and
              interpretation of events. Accessing and harnessing the contents of
              the unconscious mind presents tremendous opportunity for
              individuals, society and organizations.
            </p>
            <p>
              Our free service unlocks the power of the unconscious mind,
              opening up new possibilities for self-development, creativity,
              innovative problem-solving, and discovering new ways of living and
              adapting to a changing world.
            </p>
          </Container>
          <div className="features_wrap pt8 pb5">
            <HomeSectionHeading
              htmlTag="h2"
              type="m"
              titleText="udreamed features unlocks the power of the unconscious mind"
              className="text-center fw_el mb1 pb9"
            />
            <div className="bg_grad_primary_to_secondary pb9">
              <Container>
                <HomeFeaturesSlider featuresSlides={featuresSlides} />
              </Container>
            </div>
          </div>
          <div className="our_team_section pt8 pb8">
            <HomeTeam />
          </div>
        </div>
        <HomeNormalBanner />
      </PageComponent>
      <HomeFooter />
    </>
  );
}

export default About;
