import React, { useState } from "react";
import {
  Col,
  Row,
  Card,
  Form,
  Button,
  Spinner,
  Collapse,
  Placeholder,
} from "react-bootstrap";
import CustomCard from "../../../ui/CustomCard/CustomCard";
import AddComment from "./AddComment";
import CommentCard from "./CommentCard";
import { BsFillExclamationTriangleFill } from "react-icons/bs";
import { handleAlert } from "../../../util/util";
import ConfirmationModal from "../../../ui/ConfirmationModal/ConfirmationModal";

//Service
import authService from "../../../services/Auth";
import recordService from "../../../services/Record";
import SimpleMessageCard from "../../../ui/SimpleMessageCard/SimpleMessageCard";
import PlaceholderCard from "../../../ui/PlaceholderCard/PlaceholderCard";

function CommentList(props) {
  const authUser = JSON.parse(authService.getAuthUser());
  const [loading, setLoading] = useState(false);
  const [selectedComment, setSelectedComment] = useState();
  const [show, setShow] = useState({
    isVisible: false,
  });

  const handleClose = () => {
    // console.log("Closing the modal");
    setShow({
      isVisible: false,
    });
  };
  const handleShow = (e) => {
    console.log("comment modal =", e);
    // setSelectedComment(e.comment_id);
    switch (props.comment_type) {
      case "thread":
        setSelectedComment({
          thread_id: props.parent_id,
          comment_id: e.comment_id,
        });
        break;
      case "group":
        setSelectedComment({
          group_id: props.parent_id,
          comment_id: e.comment_id,
        });
        break;
      case "consult":
        setSelectedComment({
          // group_id: props.parent_id,
          comment_id: e.comment_id,
        });
        break;
    }

    setShow({
      isVisible: true,
    });
    // console.log("Opening the modal");
  };

  const handleCommentDelete = (data) => {
    setLoading(true);

    switch (props.comment_type) {
      case "thread":
        console.log("Thread comment delete data = ", data);
        recordService
          .removeThreadComment(data)
          .then((response) => {
            // console.log("RESPONSE: ", response);
            if (response.data.status === "success") {
              console.log(
                "handleThreadCommentDelete() comment Delete: ",
                response
              );
              props.setCommentsCount((prev) => prev - 1);
              props.handleCommentList(props.parent_id);
              handleAlert("Success! comment deleted", "success");
              setLoading(false);
              handleClose();
            }
            if (response.data.status === "failed") {
              console.log("ERROR:", response.data.error);
              setLoading(false);
              handleAlert("Error! comment deletion failed", "error");
            }
          })
          .catch((err) => {
            console.log("There is a problem in deleting comment...", err);
            setLoading(false);
            handleAlert("Error! comment deletion failed", "error");
          });
        break;
      case "group":
        console.log("Group comment delete data = ", data);
        recordService
          .removeGroupComment(data)
          .then((response) => {
            // console.log("RESPONSE: ", response);
            if (response.data.status === "success") {
              console.log(
                "handleGroupCommentDelete() comment Delete: ",
                response
              );
              props.setCommentsCount((prev) => prev - 1);
              props.handleCommentList(props.parent_id);
              handleAlert("Success! comment deleted", "success");
              setLoading(false);
              handleClose();
            }
            if (response.data.status === "failed") {
              console.log("ERROR:", response.data.error);
              setLoading(false);
              handleAlert("Error! comment deletion failed", "error");
            }
          })
          .catch((err) => {
            console.log("There is a problem in deleting comment...", err);
            setLoading(false);
            handleAlert("Error! comment deletion failed", "error");
          });
        break;
      case "consult":
        console.log("Consult session comment delete data = ", data);
        recordService
          .removeConsultationComment(data)
          .then((response) => {
            // console.log("RESPONSE: ", response);
            if (response.data.status === "success") {
              console.log(
                "handleSessionCommentDelete() comment Delete: ",
                response
              );
              // props.setCommentsCount((prev) => prev - 1);
              props.handleCommentList(props.parent_id);
              handleAlert("Success! comment deleted", "success");
              setLoading(false);
              handleClose();
            }
            if (response.data.status === "failed") {
              console.log("ERROR:", response.data.error);
              setLoading(false);
              handleAlert("Error! comment deletion failed", "error");
            }
          })
          .catch((err) => {
            console.log("There is a problem in deleting comment...", err);
            setLoading(false);
            handleAlert("Error! comment deletion failed", "error");
          });
        break;
    }
  };

  return (
    <div className="d-grid gap-3 comment_list_wrap">
      <AddComment
        comment_type={props.comment_type}
        parent_id={props.parent_id}
        comments={props.comments}
        handleCommentList={props.handleCommentList}
        setCommentsCount={props.setCommentsCount}
      />

      {!props.loading && props.comments.length > 0 ? (
        props.comments.map((comment) => (
          <CommentCard
            key={comment.comment_id}
            comment_type={props.comment_type}
            comment={comment}
            authUser={authUser}
            parent_owner_id={props.parent_owner_id}
            handleShow={handleShow}
          />
        ))
      ) : (
        <>
          {props.loading ? (
            <>
              {[0, 1].map((_, i) => (
                <PlaceholderCard type="comment" />
              ))}
            </>
          ) : (
            <>
              {props.comment_type === "consult" ? (
                <SimpleMessageCard
                  type="warning"
                  msg="Start a conversation..."
                  allowIcon={false}
                />
              ) : (
                <SimpleMessageCard
                  type="warning"
                  msg="Start commenting..."
                  allowIcon={false}
                />
              )}
            </>
          )}
        </>
      )}
      <ConfirmationModal
        show={show}
        loading={loading}
        handleClose={handleClose}
        primaryActionData={selectedComment}
        content_type="comment-delete-modal"
        handlePrimaryAction={handleCommentDelete}
      />
    </div>
  );
}

export default CommentList;
