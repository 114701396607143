import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Card,
  Collapse,
  Dropdown,
  Button,
  Placeholder,
  Form,
  Spinner,
} from "react-bootstrap";
import CustomCard from "../../../ui/CustomCard/CustomCard";
import {
  BsFillExclamationTriangleFill,
  BsThreeDotsVertical,
  BsFillPencilFill,
} from "react-icons/bs";
import DashboardPieChart from "../DashboardCharts/DashboardPieChart";
import DashboardBarChart from "../DashboardCharts/DashboardBarChart";
import DashboardLineChart from "../DashboardCharts/DashboardLineChart";
import PlaceholderCard from "../../../ui/PlaceholderCard/PlaceholderCard";

function MorePopularGraphs(props) {
  const [labels, setLabels] = useState([]);
  const [datasets, setDatasets] = useState([]);
  const [fullDatasets, setFullDatasets] = useState([]);
  const [recordCount, setRecordCount] = useState([]);
  const [datasetLength, setDatasetLength] = useState(0);
  const [morePopularGraphCollapse, setMorePopularGraphCollapse] =
    useState(false);
  const [loading, setLoading] = useState(false);

  function handleClick(e) {
    const element = e.target.getAttribute("aria-controls");
    let isAriaExpanded = e.target.getAttribute("aria-expanded");
    // console.log("Association Card =", e);
    handleCollapse(element);
  }
  function handleCollapse(element) {
    console.log("In Popular graph - Filter =", element);
    // if (element === "edit_association")
    setMorePopularGraphCollapse(!morePopularGraphCollapse);
  }

  useEffect(() => {
    console.log(
      "in useEffect of MorePopularInsightsData =",
      props.dashboardMorePopularInsightsData
    );

    if (props.dashboardMorePopularInsightsData) {
      createChartData(props.dashboardMorePopularInsightsData);
    }
  }, [props.dashboardMorePopularInsightsData]);

  //Sort array of obj in ascending order based on year and month...
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  function ascArraySorter(a, b) {
    if (a.year !== b.year) {
      return a.year - b.year;
    } else {
      return months.indexOf(a.month) - months.indexOf(b.month);
    }
  }

  function createChartData(data, dataType = "counts", chartOption = "bar") {
    // let newLabels = data.labels.map((label) => {
    //   //Capitalize first letter
    //   return label.charAt(0).toUpperCase() + label.slice(1);
    // });
    console.log("MorePopularGraphs - chart_data in createChartdata =", data);
    // console.log("dataLength in createChartdata =", dataLength);
    // console.log("chartOption =", chartOption);

    setLoading(true);

    let targetOption = "";
    let totalRecords = 0;
    let chartTitle = "";
    let dataLabels = [];
    let dataRecordCount = [];
    let allDatasets = [];
    let allChartsData = [];

    let newLabels = [];
    let meData = [];
    let friendsData = [];
    let globalData = [];
    let tempDatasets = [];
    let tempLegendList = [];

    if (dataType === "counts") {
      if (data.counts?.length > 0) {
        data.counts?.sort(ascArraySorter);
        console.log("Asc data = ", data);
        newLabels = data.counts.map((item) => item.month + " " + item.year);
        meData = data.counts.map((item) => item.me);
        friendsData = data.counts.map((item) => item.friends);
        globalData = data.counts.map((item) => item.global);

        // console.log("New Labels = ", newLabels);
        // console.log("meData = ", meData);
        // console.log("friendsData = ", friendsData);
        // console.log("globalData = ", globalData);

        tempDatasets.push({
          label: "Me",
          data: meData,
          barPercentage: 1,
          maxBarThickness: 10,
          fill: true,
          backgroundColor: "rgba(0, 153, 255, 0.2)",
          borderColor: "rgba(0, 153, 255, 0.5)",
          hoverBorderRadius: 4,
          hoverBorderColor: "rgba(0,0,0,0.4)",
        });
        tempDatasets.push({
          label: "Friends",
          data: friendsData,
          barPercentage: 1,
          maxBarThickness: 10,
          fill: true,
          backgroundColor: "rgba(255, 204, 0, 0.7)",
          borderColor: "rgba(255, 204, 0, 1)",
          hoverBorderRadius: 4,
          hoverBorderColor: "rgba(0,0,0,0.4)",
        });
        tempDatasets.push({
          label: "Global",
          data: globalData,
          barPercentage: 1,
          maxBarThickness: 10,
          fill: true,
          backgroundColor: "rgba(51, 204, 102, 0.3)",
          borderColor: "rgba(51, 204, 102, 0.6)",
          hoverBorderRadius: 4,
          hoverBorderColor: "rgba(0,0,0,0.4)",
        });

        chartTitle = "Number of experiences recorded by month";
      }
    } else {
      let chartValues = [];
      if (dataType === "emotions") {
        if (data.responses.emotions) {
          newLabels = data.responses.emotions.chart_data.labels.map((label) => {
            //Capitalize first letter
            return label.charAt(0).toUpperCase() + label.slice(1);
          });

          chartValues = data.responses.emotions.chart_data.values;
          totalRecords = data.responses.emotions.chart_data.total_no_of_records;
          chartTitle = "Five most popular emotions";
          tempLegendList = data.responses.emotions.chart_data.values.map(
            (item, index) => {
              return {
                color: props.bgcolors[index],
                value: item,
                label: newLabels[index],
              };
            }
          );
        }
      }
      if (dataType === "characters") {
        if (data.responses.characters) {
          newLabels = data.responses.characters.chart_data.labels.map(
            (label) => {
              //Capitalize first letter
              return label.charAt(0).toUpperCase() + label.slice(1);
            }
          );
          chartValues = data.responses.characters.chart_data.values;
          totalRecords =
            data.responses.characters.chart_data.total_no_of_records;
          chartTitle = "Five most popular characters";

          tempLegendList = data.responses.characters.chart_data.values.map(
            (item, index) => {
              return {
                color: props.bgcolors[index],
                value: item,
                label: newLabels[index],
              };
            }
          );
        }
      }
      if (dataType === "interactions") {
        if (data.responses.interactions) {
          newLabels = data.responses.interactions.chart_data.labels.map(
            (label) => {
              //Capitalize first letter
              return label.charAt(0).toUpperCase() + label.slice(1);
            }
          );
          chartValues = data.responses.interactions.chart_data.values;
          totalRecords =
            data.responses.interactions.chart_data.total_no_of_records;
          chartTitle = "Five most popular interactions";

          tempLegendList = data.responses.interactions.chart_data.values.map(
            (item, index) => {
              return {
                color: props.bgcolors[index],
                value: item,
                label: newLabels[index],
              };
            }
          );
        }
      }
      if (dataType === "locations") {
        if (data.responses.locations) {
          newLabels = data.responses.locations.chart_data.labels.map(
            (label) => {
              //Capitalize first letter
              return label.charAt(0).toUpperCase() + label.slice(1);
            }
          );
          chartValues = data.responses.locations.chart_data.values;
          totalRecords =
            data.responses.locations.chart_data.total_no_of_records;
          chartTitle = "Five most popular locations";

          tempLegendList = data.responses.locations.chart_data.values.map(
            (item, index) => {
              return {
                color: props.bgcolors[index],
                value: item,
                label: newLabels[index],
              };
            }
          );
        }
      }
      if (dataType === "causes") {
        if (data.responses.causes) {
          newLabels = data.responses.causes.chart_data.labels.map((label) => {
            //Capitalize first letter
            return label.charAt(0).toUpperCase() + label.slice(1);
          });
          chartValues = data.responses.causes.chart_data.values;
          totalRecords = data.responses.causes.chart_data.total_no_of_records;
          chartTitle = "Five most popular causes";

          tempLegendList = data.responses.causes.chart_data.values.map(
            (item, index) => {
              return {
                color: props.bgcolors[index],
                value: item,
                label: newLabels[index],
              };
            }
          );
        }
      }
      if (dataType === "resolutions") {
        if (data.responses.resolutions) {
          newLabels = data.responses.resolutions.chart_data.labels.map(
            (label) => {
              //Capitalize first letter
              return label.charAt(0).toUpperCase() + label.slice(1);
            }
          );
          chartValues = data.responses.resolutions.chart_data.values;
          totalRecords =
            data.responses.resolutions.chart_data.total_no_of_records;
          chartTitle = "Five most popular resolutions";

          tempLegendList = data.responses.resolutions.chart_data.values.map(
            (item, index) => {
              return {
                color: props.bgcolors[index],
                value: item,
                label: newLabels[index],
              };
            }
          );
        }
      }

      tempDatasets.push({
        label: "", //"Temp",
        // label: props.popularInsightsFilterData.target.charAt(0).toUpperCase() +
        //   props.popularInsightsFilterData.target.slice(1),
        data: chartValues,
        barPercentage: 1,
        maxBarThickness: 10,
        backgroundColor: props.bgcolors,
        hoverBorderRadius: 4,
        hoverBorderColor: "rgba(0,0,0,0.4)",
      });
    }

    if (tempDatasets.length > 0) {
      allChartsData.push({
        chart_type: chartOption,
        labels: newLabels,
        datasets: tempDatasets,
        total_records: totalRecords,
        chart_title: chartTitle,
        legend_list: tempLegendList,
      });
    }

    if (allChartsData.length > 0) {
      setFullDatasets(allChartsData);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }

    // console.log("---- Labels =", labels);
    // console.log("---- RecordCount =", recordCount);
    // console.log("---- Datasets =", datasets);
    // console.log("---- ALL Datasets More Popular Graphs =", fullDatasets);
  }

  return (
    <>
      <Row className="align-items-center position-relative mb2">
        <Col>
          <div class="section_title">More Popular Graphs</div>
          {/* <Button
            key="see_more_btn"
            type="button"
            className="btn_theme btn_icon btn_circle btn_light position-absolute top-0"
            onClick={handleClick}
            aria-controls="more_popular_graph_collapse"
            aria-expanded={morePopularGraphCollapse}
            style={{ right: "0.5rem" }}
          >
            <BsThreeDotsVertical />
          </Button> */}
        </Col>
        <Dropdown
          align="end"
          className="position-absolute top-0 p-0"
          style={{ right: "0.5rem", width: "auto" }}
        >
          <Dropdown.Toggle
            className="btn_theme btn_light btn_rounded btn_icon down_icon_hidden"
            id="dropdown-basic"
          >
            <BsFillPencilFill />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item
              as="button"
              className="font-14"
              onClick={() => {
                //handleSaveGraph(props.analyzedPatterns.chart_data);
                createChartData(
                  props.dashboardMorePopularInsightsData,
                  "counts",
                  "line"
                );
              }}
            >
              Number of experiences recorded by month
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item
              as="button"
              className="font-14"
              onClick={() => {
                //handleSaveGraph(props.analyzedPatterns.chart_data);
                createChartData(
                  props.dashboardMorePopularInsightsData,
                  "characters",
                  "pie"
                );
              }}
            >
              Five most popular characters
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item
              as="button"
              className="font-14"
              onClick={() => {
                //handleSaveGraph(props.analyzedPatterns.chart_data);
                createChartData(
                  props.dashboardMorePopularInsightsData,
                  "emotions",
                  "pie"
                );
              }}
            >
              Five most popular emotions
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item
              as="button"
              className="font-14"
              onClick={() => {
                //handleSaveGraph(props.analyzedPatterns.chart_data);
                createChartData(
                  props.dashboardMorePopularInsightsData,
                  "interactions",
                  "pie"
                );
              }}
            >
              Five most popular interactions
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item
              as="button"
              className="font-14"
              onClick={() => {
                //handleSaveGraph(props.analyzedPatterns.chart_data);
                createChartData(
                  props.dashboardMorePopularInsightsData,
                  "locations",
                  "pie"
                );
              }}
            >
              Five most popular locations
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item
              as="button"
              className="font-14"
              onClick={() => {
                //handleSaveGraph(props.analyzedPatterns.chart_data);
                createChartData(
                  props.dashboardMorePopularInsightsData,
                  "causes",
                  "pie"
                );
              }}
            >
              Five most popular causes
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item
              as="button"
              className="font-14"
              onClick={() => {
                //handleSaveGraph(props.analyzedPatterns.chart_data);
                createChartData(
                  props.dashboardMorePopularInsightsData,
                  "resolutions",
                  "pie"
                );
              }}
            >
              Five most popular resolutions
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Row>

      <Row>
        <Col>
          <Collapse in={morePopularGraphCollapse}>
            <div id="more_popular_graph_collapse">
              <CustomCard className="border-0 shadow1 mt0 mb2 pt2 pb2 px-3"></CustomCard>
            </div>
          </Collapse>
        </Col>
      </Row>

      {loading ? (
        <>
          <PlaceholderCard type="chart" />
        </>
      ) : (
        <>
          {fullDatasets.length > 0 ? (
            <>
              {fullDatasets.map((dataset, index) => (
                <div
                  key={index}
                  className="card custom_card bg-transparent shado border-0 p2 px-0 mb2"
                >
                  {dataset.chart_type === "pie" ? (
                    <DashboardPieChart
                      labels={dataset.labels}
                      datasets={dataset.datasets}
                      recordCount={dataset.total_records}
                      chart_title={dataset.chart_title}
                      legend_list={dataset.legend_list}
                      // chartData={dataset}
                    />
                  ) : (
                    <DashboardLineChart
                      labels={dataset.labels}
                      datasets={dataset.datasets}
                      recordCount={dataset.total_records}
                      chart_title={dataset.chart_title}
                    />
                    // <DashboardBarChart
                    //   labels={dataset.labels}
                    //   datasets={dataset.datasets}
                    //   recordCount={dataset.total_records}
                    //   chart_title={dataset.chart_title}
                    //   // chartData={dataset}
                    // />
                  )}
                </div>
              ))}
            </>
          ) : (
            <>
              <CustomCard className="shadow1 border-0 text-center">
                <Card.Body>
                  <div className="mb1">
                    <BsFillExclamationTriangleFill
                      style={{
                        color: "#d3af61",
                        fontSize: "2.2rem",
                      }}
                    />
                  </div>
                  <b>No more records found...</b>
                </Card.Body>
              </CustomCard>
            </>
          )}
        </>
      )}
    </>
  );
}

export default MorePopularGraphs;
