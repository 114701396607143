export const YTVIDEOS = [
    { id: 1, title: "Quick Start Guide to uDreamed", video_id: "zj5ASwpMkQk" },
    {
      id: 2,
      title: "How to remember your dreams routinely.",
      video_id: "0ZFR6n4Eigg",
    },
    {
      id: 3,
      title: "What does it mean when I dream about falling?",
      video_id: "pgSOkxxcHWU",
    },
    {
      id: 4,
      title: "What does it mean when I dream about being naked in public?",
      video_id: "Y2NbdBgbM-U",
    },
    {
      id: 5,
      title: "What does it mean when I dream about breaking up?",
      video_id: "Px30D8SUn4o",
    },
    {
      id: 6,
      title: "What does it mean when I dream about being chased?",
      video_id: "sjH0w3iRwx4",
    },
    {
      id: 7,
      title: "What does it mean when I dream about flying?",
      video_id: "ihSvOUuz4Ww",
    },
    {
      id: 8,
      title: "What does it mean when I dream about my spouse cheating?",
      video_id: "gb2fDmYyXSk",
    },
    {
      id: 9,
      title: "What does it mean when I dream about being unprepared?",
      video_id: "zSz3tuZAY0s",
    },
    {
      id: 10,
      title: "What does it mean when I dream about the toilet and urinating?",
      video_id: "ztZmharxwDs",
    },
    {
      id: 11,
      title: "What does it mean when I dream about the Apocalypse?",
      video_id: "12U2PX1F-rI",
    },
    {
      id: 12,
      title: "What does it mean when I dream about war and battle?",
      video_id: "7R2eIkQBURw",
    },
    {
      id: 13,
      title: "What does it mean when I dream about an airplane?",
      video_id: "hpB9YjB2AVc",
    },
    {
      id: 14,
      title: "What does it mean when I dream about feces?",
      video_id: "AJhL-p003vk",
    },
    {
      id: 15,
      title: "What does it mean when I dream about an accident?",
      video_id: "j41HcfyDIDo",
    },
    {
      id: 16,
      title:
        "What does it mean when I dream about not being able to find my classroom?",
      video_id: "_ScLr4ldVts",
    },
    {
      id: 17,
      title: "What does it mean when I dream about being dead?",
      video_id: "r8LNLCqf0KY",
    },
    {
      id: 18,
      title: "What does it mean when I dream about attacking someone?",
      video_id: "Q4zU1g2Dqkw",
    },
    {
      id: 19,
      title: "What does it mean when I dream about being drunk?",
      video_id: "5t6vH_CAkf4",
    },
    {
      id: 20,
      title: "What does it mean when I dream about being buried?",
      video_id: "01JzMukVdLA",
    },
    {
      id: 21,
      title: "What does it mean when I dream about being robbed?",
      video_id: "1VbGb6VIHPw",
    },
    {
      id: 22,
      title: "What does it mean when I dream about being trapped?",
      video_id: "v4gmMQybVFo",
    },
    {
      id: 23,
      title: "What does it mean when I dream about having a baby?",
      video_id: "JtvvXVFUZNY",
    },
    {
      id: 24,
      title: "What does it mean when I dream about school?",
      video_id: "paKRaWoDmF0",
    },
    {
      id: 25,
      title: "What does it mean when I dream about being ashamed?",
      video_id: "i9qlXcthMKw",
    },
    {
      id: 26,
      title: "What does it mean when I dream about paying bills?",
      video_id: "4rRG6Me1vcQ",
    },
    {
      id: 27,
      title: "What does it mean when I dream about crying?",
      video_id: "4MJBZYyNrAE",
    },
    {
      id: 28,
      title: "What does it mean when I dream about running?",
      video_id: "DteLped4IPg",
    },
    {
      id: 29,
      title: "What does it mean when I dream about money?",
      video_id: "seKkfbPfG9E",
    },
    {
      id: 30,
      title: "What does it mean when I dream about a brother or sister?",
      video_id: "FfygbAOzSd8",
    },
    {
      id: 31,
      title: "What does it mean when I dream about being chased?",
      video_id: "HkxaUb9ZO24",
    },
    {
      id: 32,
      title: "What does it mean when I dream about my boss?",
      video_id: "TPZ3ih6cpoc",
    },
    {
      id: 33,
      title: "What does it mean when I dream about clothes?",
      video_id: "nT-eekg3O9U",
    },
    {
      id: 34,
      title: "What does it mean when I dream about love?",
      video_id: "BHbiwOnXsHw",
    },
    {
      id: 35,
      title: "What does it mean when I dream about maggots?",
      video_id: "drFegRzW2o4",
    },
    {
      id: 36,
      title: "What does it mean when I dream about my pet?",
      video_id: "PAedWgrqQhM",
    },
    {
      id: 37,
      title: "What does it mean when I dream about bankruptcy?",
      video_id: "KJlQ2-1u32A",
    },
  ];