import React from 'react'
import {
    Col,
    Row,
    Card,
    Button,
    Placeholder,
    Spinner,
  } from "react-bootstrap";
  import CustomCard from "../../../ui/CustomCard/CustomCard";
  import { AiOutlineAppstoreAdd } from "react-icons/ai";
  import { FaRegObjectGroup, FaRegObjectUngroup, FaCrown } from "react-icons/fa";
  import {
    GiAchievement,
    GiAlliedStar,
    GiBlackBridge,
    GiImperialCrown,
    GiJewelCrown,
    GiLaurelCrown,
    GiRank3,
    GiCrown,
    GiCrenelCrown,
    GiShare,
    GiHearts,
    GiHeartWings,
    GiBrain,
    GiRevolt,
  } from "react-icons/gi";
  import CountUp from "react-countup";    

function DashboardAchievementsCard({loadingAchievements, achievements, handleClick}) {
  return (
    <>
        <CustomCard
        className={`main_card shadow1 border-0 mt1 mb2 overflow-hidden`}
      >
        <Card.Body className="p-0">
          <Row className="g-0" xs={1} sm={2}>
            <Col
              className="bg_primary p2 d-flex flex-row flex-sm-column align-items-center justify-content-around justify-content-sm-center"
              sm={4}
            >
              <div className="text-center">
                <img
                  src={`${process.env.PUBLIC_URL}/img/achievement-icon.png`}
                  width="45"
                  className="img-fluid mx-auto mb1"
                  alt=""
                />

                <div className="font-22 fw_sb text_white">
                  {loadingAchievements ? (
                    <>
                      <Spinner animation="border" variant="light" size="sm" />
                    </>
                  ) : (
                    <>
                      {achievements?.achievements?.total_xp ? (
                        <>
                          <CountUp
                            delay={1}
                            end={achievements?.achievements?.total_xp}
                            useEasing={true}
                            duration={5}
                            suffix=" XP"
                          />
                        </>
                      ) : (
                        0
                      )}
                    </>
                  )}
                </div>
              </div>
              <hr className="d-none d-sm-block w-100 bg_secondary_dark" />
              <div className="text-center">
                <img
                  src={`${process.env.PUBLIC_URL}/img/crown-icon.png`}
                  width="50"
                  className="img-fluid mx-auto mb1"
                  alt=""
                />
                <div className="font-22 fw_sb text_white">
                  {achievements?.rank?.rank ? (
                    <>
                      <CountUp
                        delay={1}
                        end={achievements?.rank?.rank}
                        useEasing={true}
                        duration={5}
                      />
                    </>
                  ) : (
                    0
                  )}
                </div>
                <div className="font-14 fw_sb text_white text-uppercase">
                  Global Ranking
                </div>
              </div>
            </Col>
            <Col sm={8} className="p2">
              <Row className="gx-0 gy-0" xs={2}>
                <Col className="border-end border-bottom">
                  <Card className="flex-column align-items-center justify-content-center p1 pt-0 border-0 counter_card">
                    <GiBrain className="text_lightgray font-40 mb-1" />
                    <div className="text_gray9 text-uppercase font-13 text-center">
                      Experiences
                    </div>
                    <div className="font-22 fw_sb text_gray6">
                      {loadingAchievements ? (
                        <>
                          <Spinner
                            animation="border"
                            variant="secondary"
                            size="sm"
                          />
                        </>
                      ) : (
                        <>
                          {achievements?.achievements?.record_num ? (
                            <>
                              <CountUp
                                delay={1}
                                start={0}
                                end={achievements?.achievements?.record_num}
                                useEasing={true}
                                duration={5}
                              />
                            </>
                          ) : (
                            0
                          )}
                        </>
                      )}
                    </div>
                  </Card>
                </Col>
                <Col className="border-bottom">
                  <Card className="flex-column align-items-center justify-content-center p1 pt-0 border-0 counter_card">
                    <GiShare className="text_lightgray font-40 mb-1" />
                    <div className="text_gray9 text-uppercase font-13 text-center">
                      Shared Experiences
                    </div>
                    <div className="font-22 fw_sb text_gray6">
                      {loadingAchievements ? (
                        <>
                          <Spinner
                            animation="border"
                            variant="secondary"
                            size="sm"
                          />
                        </>
                      ) : (
                        <>
                          {achievements?.achievements?.share_num ? (
                            <>
                              <CountUp
                                delay={1}
                                end={achievements?.achievements?.share_num}
                                useEasing={true}
                                duration={5}
                              />
                            </>
                          ) : (
                            0
                          )}
                        </>
                      )}
                    </div>
                  </Card>
                </Col>
                <Col className="border-end">
                  <Card className="flex-column align-items-center justify-content-center p1 pb-0 border-0 counter_card">
                    <GiHeartWings className="text_lightgray font-40 mb-1" />
                    <div className="text_gray9 text-uppercase font-13 text-center">
                      Favorited Items
                    </div>
                    <div className="font-22 fw_sb text_gray6">
                      {loadingAchievements ? (
                        <>
                          <Spinner
                            animation="border"
                            variant="secondary"
                            size="sm"
                          />
                        </>
                      ) : (
                        <>
                          {achievements?.achievements?.favorite_num ? (
                            <>
                              <CountUp
                                delay={1}
                                end={achievements?.achievements?.favorite_num}
                                useEasing={true}
                                duration={5}
                              />
                            </>
                          ) : (
                            0
                          )}
                        </>
                      )}
                    </div>
                  </Card>
                </Col>
                <Col>
                  <Card className="flex-column align-items-center justify-content-center p1 pb-0 border-0 counter_card">
                    <GiRevolt className="text_lightgray font-40 mb-1" />
                    <div className="text_gray9 text-uppercase font-13 text-center">
                      Resolutions
                    </div>
                    <div className="font-22 fw_sb text_gray6">
                      {loadingAchievements ? (
                        <>
                          <Spinner
                            animation="border"
                            variant="secondary"
                            size="sm"
                          />
                        </>
                      ) : (
                        <>
                          {achievements?.achievements?.resolution_num ? (
                            <>
                              <CountUp
                                delay={1}
                                end={achievements?.achievements?.resolution_num}
                                useEasing={true}
                                duration={5}
                              />
                            </>
                          ) : (
                            0
                          )}
                        </>
                      )}
                    </div>
                  </Card>
                </Col>
              </Row>
              <div className="text-center pt2">
                <Button
                  type="button"
                  data-type="achievements"
                  onClick={(e) => {
                    handleClick(e);
                  }}
                  className="btn_theme btn_small btn_light btn_rounded"
                >
                  View Achievements
                </Button>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </CustomCard>
    </>
  )
}

export default DashboardAchievementsCard