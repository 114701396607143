import React from "react";
import HomeGuestHeader from "../components/HomeGuestHeader/HomeGuestHeader";
import HomeSignupBanner from "../components/HomeSignupBanner/HomeSignupBanner";
import PageComponent from "../components/PageComponent/PageComponent";

function Signup() {
  return (
    <>
      <HomeGuestHeader />
      <PageComponent>
        <HomeSignupBanner />
      </PageComponent>
    </>
  );
}

export default Signup;
