import React, { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card } from "react-bootstrap";
import CustomCard from "../../../ui/CustomCard/CustomCard";
import "./DashboardNav.css";

//Context
import AppContext from "../../../AppContext";

function DashboardNav({ contentType, getContentType, notificationCount }) {
  let navigate = useNavigate();
  let { id } = useParams();

  const {
    invitations,
    groupInvitations,
    groupRequests,
    consultInvitations,
    notifications,
  } = useContext(AppContext);
  const notificationCount1 = notifications ? notifications.length : 0;
  
  const inviteCount = invitations ? invitations.length : 0;
  const groupInviteCount = groupInvitations ? groupInvitations.length : 0;
  const groupReqCount = groupRequests ? groupRequests.length : 0;
  const consultInviteCount = consultInvitations ? consultInvitations.length : 0;

  const invitationCount = inviteCount + groupInviteCount + groupReqCount + consultInviteCount;
  
  // console.log("------- Inside nav-----------");
  // console.log(
  //   "invitations =",
  //   invitations.length,
  //   "\tGroup-invitations =",
  //   groupInvitations.length,
  //   "\tGroup-request =",
  //   groupRequests.length,
  //   "\tConsult-invitations =",
  //   consultInvitations.length
  // );

  const modules = [
    { id: 1, type: "dashboard", title: "Dashboard", icon: "offer-icon13.png" },
    { id: 2, type: "share", title: "Share", icon: "offer-icon10.png" },
    { id: 3, type: "associate", title: "Associate", icon: "offer-icon2.png" },
    { id: 4, type: "analyze", title: "Analyze", icon: "offer-icon3.png" },
    { id: 5, type: "network", title: "Network", icon: "offer-icon4.png" },
    // { id: 6, type: "match", title: "Match", icon: "offer-icon5.png" },
    { id: 7, type: "consult", title: "Consult", icon: "offer-icon6.png" },
    { id: 8, type: "discover", title: "Discover", icon: "offer-icon7.png" },
    { id: 9, type: "shop", title: "Shop", icon: "offer-icon8.png" },
    {
      id: 10,
      type: "achievements",
      title: "Achievements",
      icon: "offer-icon9.png",
    },
  ];

  const otherModules = [
    { id: "o1", type: "messages", title: "Messages", icon: "offer-icon14.png" },
    {
      id: "o2",
      type: "invitations",
      title: "Invitations",
      icon: "offer-icon15.png",
    },
    {
      id: "o3",
      type: "consulting-sessions",
      title: "Consulting Sessions",
      icon: "offer-icon16.png",
    },
    {
      id: "o4",
      type: "notifications",
      title: "Notifications",
      icon: "offer-icon17.png",
    },
    {
      id: "o5",
      type: "settings",
      title: "Settings",
      icon: "offer-icon12.png",
    },
  ];

  function handleClick(item) {
    if (item.type === "dashboard") {
      navigate(`/${item.type}`);
    } else {
      navigate(`/dashboard/${item.type}`);
    }
    getContentType(item.type);
  }
  let activeClass = contentType === id ? id : "dashboard";

  return (
    <CustomCard className={`dashboard_nav_card main_card shadow1 border-0 mb2`}>
      <Card.Body>
        <ul>
          {modules.map((item) => {
            return (
              <li
                key={item.id}
                id={item.id}
                data-type={item.type}
                className={`d-flex align-items-center ${
                  activeClass === item.type ? "active" : ""
                }`}
                onClick={() => {
                  handleClick(item);
                }}
              >
                <div className="icon_container">
                  <img
                    src={`${process.env.PUBLIC_URL}/img/icons/${item.icon}`}
                    className="img-fluid"
                    alt={`${item.title} Icon`}
                  />
                </div>
                <span>{item.title}</span>
              </li>
            );
          })}
        </ul>
        <hr />
        <ul>
          {otherModules.map((item) => {
            return (
              <li
                key={item.id}
                id={item.id}
                data-type={item.type}
                className={`d-flex align-items-center ${
                  activeClass === item.type ? "active" : ""
                }`}
                onClick={() => {
                  handleClick(item);
                }}
              >
                <div className="icon_container">
                  <img
                    src={`${process.env.PUBLIC_URL}/img/icons/${item.icon}`}
                    className="img-fluid"
                    alt={`${item.title} Icon`}
                  />
                </div>
                <span>{item.title}</span>
                {item.type === "notifications" && notificationCount1 > 0 && (
                  <span className="custom_tag tag_small tag_danger_light">
                    {notificationCount1 >= 100 ? "99+" : notificationCount1}
                  </span>
                )}
                {item.type === "invitations" && invitationCount > 0 && (
                  <span className="custom_tag tag_small tag_danger_light">
                    {invitationCount >= 100 ? "99+" : invitationCount}
                  </span>
                )}
              </li>
            );
          })}
        </ul>
      </Card.Body>
    </CustomCard>
  );
}

export default DashboardNav;
