import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import HomeGuestHeader from "../components/HomeGuestHeader/HomeGuestHeader";
import PageComponent from "../components/PageComponent/PageComponent";
import HomeInnerPageBanner from "../components/HomeInnerPageBanner/HomeInnerPageBanner";
import HomeSectionHeading from "../components/HomeSectionHeading/HomeSectionHeading";
import WebUtilityCard from "../ui/CustomCard/WebUtilityCard";
import HomeNormalBanner from "../components/HomeNormalBanner/HomeNormalBanner";
import HomeFooter from "../components/HomeFooter/HomeFooter";

function Blogs() {
  const blogData = [
    {
      id: "blog1",
      type: "blog",
      mainImage: "gender-emotions-comparison.png",
      title: "Gender Differences in Dream Content",
      shortDesc:
        "We recently completed a deep analysis of thousands of dreams and the findings on gender differences in dream experiences are compelling and ought to pique your interest.",
      date: "Aug 18, 2020",
      dayMonth: "Aug 18",
      year: "2020",
    },
    {
      id: "blog2",
      type: "blog",
      mainImage: "ucs-informative-poster.jpg",
      title: "Dream Comparison of Before and During COVID",
      shortDesc:
        "We wondered whether and to what extent the coronavirus affected what people dream about. This is important because our dream emotions affect our waking mood and our mood impacts our behavior.",
      date: "Aug 18, 2020",
      dayMonth: "Aug 18",
      year: "2020",
    },
  ];
  return (
    <>
      <HomeGuestHeader />
      <PageComponent>
        <HomeInnerPageBanner className="about_banner"></HomeInnerPageBanner>
        <div className="inner_page">
          <Container className="pt5 pb5">
            <HomeSectionHeading
              htmlTag="h2"
              type="m"
              titleText="Blogs"
              className="mb2"
            />

            <Row className="pt3 row row-cols-1 row-cols-md-2 row-cols-xl-3">
              {blogData.map((item) => {
                return (
                  <Col key={item.id} className="mb3">
                    <WebUtilityCard itemData={item} />
                  </Col>
                );
              })}
            </Row>
          </Container>
        </div>
        <HomeNormalBanner />
      </PageComponent>
      <HomeFooter />
    </>
  );
}

export default Blogs;
