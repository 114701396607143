import React, { useState, useEffect, useContext } from "react";
import { Card, Button, Spinner } from "react-bootstrap";
import {
  BsFillStarFill,
  BsFillExclamationCircleFill,
  BsFillTrashFill,
} from "react-icons/bs";
import CustomCard from "../../../ui/CustomCard/CustomCard";
import CustomCollapseButton from "../../../ui/CustomCollapse/CustomCollapseButton";
import CustomCollapseContainer from "../../../ui/CustomCollapse/CustomCollapseContainer";
import AddAssociation from "../DashboardAssociateContent/AddAssociation";
import AssociationList from "../DashboardAssociateContent/AssociationList";
import Share from "../DashboardShareContent/Share";
import MatchedExperienceList from "../DashboardMatchContent/MatchedExperienceList";
import MatchedExperienceElements from "../DashboardMatchContent/MatchedExperienceElements";
import MatchingElements from "../DashboardMatchContent/MatchingElements";
import MatchingConnect from "../DashboardMatchContent/MatchingConnect";
import "./DashboardExperienceCard.css";
import DashboardEditExperience from "../DashboardExperienceBankContent/DashboardEditExperience";
import DecodeContent from "../DashboardExperienceBankContent/DecodeContent";
import DashboardExperienceDetails from "../DashboardExperienceBankContent/DashboardExperienceDetails";
import CustomProgressBar from "../../../ui/CustomProgressBar/CustomProgressBar";
import ConfirmationModal from "../../../ui/ConfirmationModal/ConfirmationModal";
import CircularProgressProvider from "../DashboardProfileCard/CircularProgressProvider";
import SimpleMessageCard from "../../../ui/SimpleMessageCard/SimpleMessageCard";
import DashboardComments from "../DashboardComments/DashboardComments";
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import VisibilitySensor from "react-visibility-sensor";
import "react-circular-progressbar/dist/styles.css";
import { handleAlert } from "../../../util/util";

//Context
import AppContext from "../../../AppContext";
//Service
import recordService from "../../../services/Record";

function DashboardExperienceCard({ exp, authUser, setAssociateExpbankData }) {
  const { experiences, setExperiences } = useContext(AppContext);
  const [recordDetails, setRecordDetails] = useState({});
  const [association, setAssociation] = useState([]);
  const [matchedRecords, setMatchedRecords] = useState([]);
  const [matchingRecordElements, setMatchingRecordElements] = useState([]);
  const [isFavorite, setIsFavorite] = useState(exp.is_favorite);
  const [associationsCount, setAssociationsCount] = useState(
    exp.associations_count
  );
  const [comments, setComments] = useState([]);
  const [commentsCount, setCommentsCount] = useState(exp.comments_count);
  //Delete Confirmation Modal State
  const [show, setShow] = useState({
    isVisible: false,
  });

  const [loading, setLoading] = useState(false);
  const [loadingFavorite, setLoadingFavorite] = useState(false);
  const [cardTop, setCardTop] = useState(0);
  // const [isAssociationUpdating, setIsAssociationUpdating] = useState(false);
  // console.log("Exp = ", exp);
  // Get all recorded experience list from DB
  function getData() {
    recordService
      .getAllRecords()
      .then((response) => {
        if (response.data.status === "success") {
          // console.log("Response from APP : ", response.data);
          const records = response.data.data.result.records;
          setExperiences(records);
        }
        if (response.data.status === "failed") {
          console.log("ERROR:", response.data.error);
        }
      })
      .catch((err) => {
        console.log("There is a problem in creating new record...", err);
      });
  }

  function getLocalExperiences(data) {
    const localExperiences = experiences.map((item) => {
      if (item.record_id === data.record_id) {
        return {
          ...item,
          is_favorite: !isFavorite,
        };
      }
      return item;
    });
    setExperiences(localExperiences);
  }

  // const handleFavoriteAction = () => {
  //   setIsFavorite(!isFavorite);
  // };

  //Send friend request on connect button click
  const handleMatchConnect = (id) => {
    console.log("Connect Id =", id);

    if (id) {
      recordService
        .addFriend(id)
        .then((response) => {
          if (response.data.status === "success") {
            // console.log("If status-success =", response.data);
            // console.log("msg =", response.data.message);
            handleAlert(response.data.message, "success");
          }
          if (response.data.status === "failed") {
            // console.log("If status-failed =", response.data);
            // console.log("msg =", response.data.error);
            handleAlert(response.data.error, "error");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const getMatchedElements = (id) => {
    let tempMatchedElements = { ...exp };
    if (exp.matching_elements.length > 0) {
      exp.matching_elements.forEach((item) => {
        switch (item.label) {
          case "emotions":
            return Object.assign(tempMatchedElements, {
              emotions: item.elements,
            });
          case "characters":
            return Object.assign(tempMatchedElements, {
              characters: item.elements,
            });
          case "interactions":
            return Object.assign(tempMatchedElements, {
              interactions: item.elements,
            });
          case "locations":
            return Object.assign(tempMatchedElements, {
              locations: item.elements,
            });
          case "causes":
            return Object.assign(tempMatchedElements, {
              causes: item.elements,
            });
          case "resolutions":
            return Object.assign(tempMatchedElements, {
              resolutions: item.elements,
            });
        }
      });
      // console.log("Obj in if = ", tempMatchedElements);
      setMatchingRecordElements(tempMatchedElements);
    } else {
      // console.log("Obj in else = ", tempMatchedElements);
      setMatchingRecordElements(tempMatchedElements);
    }
  };

  const handleMatchedExperienceList = (id) => {
    setLoading(true);
    recordService
      .getAllMatchingRecords(id)
      .then((response) => {
        if (response.data.status === "success") {
          // console.log(
          //   "handleMatchedExperienceList() Record list: ",
          //   response.data
          // );
          const matchingRecords = response.data.data.result.matches;
          let updatedRecords;
          let mainType = "match";
          let actions = ["view_details", "view_match", "connect"];
          updatedRecords = matchingRecords.map((record) => {
            return {
              ...record,
              mainType: mainType,
              actions: actions,
              // isMatched: isMatched,
            };
          });
          setMatchedRecords(updatedRecords);
          setLoading(false);
        } else if (response.data.status === "failed") {
          console.log("ERROR:", response.data.error);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log("There is a problem in fetching matching records...", err);
        setLoading(false);
      });
  };

  const handleCommentList = (id) => {
    setLoading(true);
    const commentData = {
      entity_id: id,
      module: "record",
    };
    recordService
      .getAllComments(commentData)
      .then((response) => {
        if (response.data.status === "success") {
          // console.log("handleCommentList() Comment list: ", response.data);
          const comments = response.data.data.result.comments;
          setComments(comments);
          setLoading(false);
        } else if (response.data.status === "failed") {
          console.log("ERROR:", response.data.error);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log("There is a problem in fetching comments...", err);
        setLoading(false);
        // setLoading(false);
      });
  };

  const handleAssociationList = (id) => {
    setLoading(true);
    recordService
      .getAllAssociations(id)
      .then((response) => {
        if (response.data.status === "success") {
          // console.log("handleAssociationList() Assoc list: ", response.data);
          const assocRecords = response.data.data.result.associations;
          setAssociation(assocRecords);
          setLoading(false);
        } else if (response.data.status === "failed") {
          console.log("ERROR:", response.data.error);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log("There is a problem in fetching record details...", err);
        setLoading(false);
      });
  };

  const handleRecordDetails = (id) => {
    setLoading(true);
    recordService
      .getRecord(id)
      .then((response) => {
        // console.log("RESPONSE: ", response);
        if (response.data.status === "success") {
          // console.log("handleRecordDetails() Record Details: ", response);
          let tempRecord = response.data.data.result.records;
          setRecordDetails((prev) => {
            return { ...prev, ...tempRecord };
          });
          setLoading(false);
        }
        if (response.data.status === "failed") {
          console.log("ERROR:", response.data.error);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log("There is a problem in fetching record deatils...", err);
        setLoading(false);
      });
  };

  const handleClose = () => {
    // console.log("Closing the modal");
    setShow({
      isVisible: false,
    });
  };
  const handleShow = () => {
    setShow({
      isVisible: true,
    });
    // console.log("Opening the modal");
  };

  const handleRecordDelete = (id) => {
    setLoading(true);
    recordService
      .deleteRecord(id)
      .then((response) => {
        // console.log("RESPONSE: ", response);
        if (response.data.status === "success") {
          console.log("handleRecordDelete() Record Delete: ", response);
          setLoading(false);
          handleAlert("Success! Record deleted", "success");
          //fetch new context data
          // getData();

          //Updating context locally
          const localExperiences = experiences.filter(
            (item) => item.record_id !== id
          );
          setExperiences(localExperiences);
          handleClose();
        }
        if (response.data.status === "failed") {
          console.log("ERROR:", response.data.error);
          setLoading(false);
          handleAlert("Error! Record deletion failed", "error");
        }
      })
      .catch((err) => {
        console.log("There is a problem in deleting record...", err);
        setLoading(false);
        handleAlert("Error! Record deletion failed", "error");
      });
  };

  const handleIsFavorite = (data) => {
    setLoadingFavorite(true);
    // setIsFavorite(!isFavorite);
    console.log("entered and updated loading favorite");
    // setLoading(true);
    // console.log("Data =", data);
    const newRecord = {
      ...data,
      is_favorite: !data.is_favorite,
    };

    // setTimeout(() => {
    //   setIsFavorite(!isFavorite);
    //   setLoadingFavorite(false);
    //   console.log("called setter method isfevorite and loading favorite");

    //   const localExperiences = experiences.map((item) => {
    //     if (item.record_id === data.record_id) {
    //       return {
    //         ...item,
    //         is_favorite: !isFavorite,
    //       };
    //     }
    //     return item;
    //   });
    //   setExperiences(localExperiences);
    //   console.log("called setter method experiences");
    // }, 500);

    // console.log("newRecord =", newRecord);
    // setTimeout(() => {

    // }, 1000);
    //Update context locally
    // const localExperiences = experiences.map((item) => {
    //   if (item.record_id === data.record_id) {
    //     return {
    //       ...item,
    //       is_favorite: !isFavorite,
    //     };
    //   }
    //   return item;
    // });
    // setExperiences(localExperiences);
    // console.log("setExperiences called");
    // setLoadingFavorite(true);
    // getLocalExperiences(data);

    setIsFavorite(!isFavorite);
    setLoadingFavorite(false);

    if (data.hasOwnProperty("record_id")) {
      recordService
        .updateRecord(newRecord)
        .then((response) => {
          if (response.data.status === "success") {
            console.log("handleIsFavorite SUCCESS =", response.data);
            // console.log("newRecord =", newRecord);
            // setIsFavorite(!isFavorite);
            // setLoadingFavorite(false);
            // handleAlert("Success! Record: Event updated", "success");

            //fetch new context data
            // getData();

            //Update context locally
            const localExperiences = experiences.map((item) => {
              if (item.record_id === data.record_id) {
                return {
                  ...item,
                  is_favorite: !isFavorite,
                };
              }
              return item;
            });
            setExperiences(localExperiences);
            // getLocalExperiences(data);
          }
          if (response.data.status === "failed") {
            console.log("ERROR:", response.data.error);
            setLoadingFavorite(false);
            setIsFavorite(!isFavorite);
            //fetch new context data
            getData();
            // handleAlert("Error! Record updation failed", "error");
          }
        })
        .catch((err) => {
          console.log("There is a problem in creating new record...", err);
          setLoadingFavorite(false);
          setIsFavorite(!isFavorite);
        });

      console.log("called record update method to make experience favorite");
    }
  };

  /*Collapsible actions: add_association, view_associations, 
    share, add_comment, view_comments, match, view_match, connect, edit, delete, decode, view_details */
  //collapsible state
  const [openCollapse, setOpenCollapse] = useState({
    add_association_collapse: false,
    view_associations_collapse: false,
    share_collapse: false,
    add_comment_collapse: false,
    view_comments_collapse: false,
    match_collapse: false,
    view_match_collapse: false,
    connect_collapse: false,
    edit_collapse: false,
    delete_collapse: false,
    decode_collapse: false,
    view_details_collapse: false,
  });

  let tempState = null;
  const handleCollapse = (element) => {
    switch (element) {
      case "add_association":
        tempState = Object.fromEntries(
          Object.keys({ ...openCollapse }).map((key) => [key, false])
        );
        setOpenCollapse({
          ...tempState,
          add_association_collapse: !openCollapse.add_association_collapse,
        });
        break;
      case "view_associations":
        tempState = Object.fromEntries(
          Object.keys({ ...openCollapse }).map((key) => [key, false])
        );
        setOpenCollapse({
          ...tempState,
          view_associations_collapse: !openCollapse.view_associations_collapse,
        });
        break;
      case "share":
        tempState = Object.fromEntries(
          Object.keys({ ...openCollapse }).map((key) => [key, false])
        );
        setOpenCollapse({
          ...tempState,
          share_collapse: !openCollapse.share_collapse,
        });
        break;
      case "add_comment":
        tempState = Object.fromEntries(
          Object.keys({ ...openCollapse }).map((key) => [key, false])
        );
        setOpenCollapse({
          ...tempState,
          add_comment_collapse: !openCollapse.add_comment_collapse,
        });
        break;
      case "view_comments":
        tempState = Object.fromEntries(
          Object.keys({ ...openCollapse }).map((key) => [key, false])
        );
        setOpenCollapse({
          ...tempState,
          view_comments_collapse: !openCollapse.view_comments_collapse,
        });
        break;
      case "match":
        tempState = Object.fromEntries(
          Object.keys({ ...openCollapse }).map((key) => [key, false])
        );
        setOpenCollapse({
          ...tempState,
          match_collapse: !openCollapse.match_collapse,
        });
        break;
      case "view_match":
        tempState = Object.fromEntries(
          Object.keys({ ...openCollapse }).map((key) => [key, false])
        );
        setOpenCollapse({
          ...tempState,
          view_match_collapse: !openCollapse.view_match_collapse,
        });
        break;
      case "connect":
        tempState = Object.fromEntries(
          Object.keys({ ...openCollapse }).map((key) => [key, false])
        );
        setOpenCollapse({
          ...tempState,
          connect_collapse: !openCollapse.connect_collapse,
        });
        break;
      case "edit":
        tempState = Object.fromEntries(
          Object.keys({ ...openCollapse }).map((key) => [key, false])
        );
        setOpenCollapse({
          ...tempState,
          edit_collapse: !openCollapse.edit_collapse,
        });
        break;
      case "delete":
        tempState = Object.fromEntries(
          Object.keys({ ...openCollapse }).map((key) => [key, false])
        );
        setOpenCollapse({
          ...tempState,
          delete_collapse: !openCollapse.delete_collapse,
        });
        break;
      case "decode":
        tempState = Object.fromEntries(
          Object.keys({ ...openCollapse }).map((key) => [key, false])
        );
        setOpenCollapse({
          ...tempState,
          decode_collapse: !openCollapse.decode_collapse,
        });
        break;
      case "view_details":
        tempState = Object.fromEntries(
          Object.keys({ ...openCollapse }).map((key) => [key, false])
        );
        setOpenCollapse({
          ...tempState,
          view_details_collapse: !openCollapse.view_details_collapse,
        });
        break;
    }
  };

  let exp_type_class = "";
  let type_of_recorded_exp = "";
  let icon_url = "dreams-icon.png";
  switch (exp.record_type) {
    case "dream":
      exp_type_class = "dreams";
      type_of_recorded_exp = "Dream";
      icon_url = "dreams-icon.png";
      break;
    case "event":
      exp_type_class = "events";
      type_of_recorded_exp = "Event";
      icon_url = "events-icon.png";
      break;
    case "thought":
      exp_type_class = "thoughts";
      type_of_recorded_exp = "Thought";
      icon_url = "thoughts-icon.png";
      break;
    case "outofbody":
      exp_type_class = "outofbody";
      type_of_recorded_exp = "Out of Body";
      icon_url = "experience-icon.png";
      break;
  }

  //record shared with users list
  // let recordSharedWithUsers = [];
  // exp.shares?.forEach((item) => {
  //   // recordSharedWithUsers = item.users?.map((user) => {
  //   //   return user.fullname;
  //   // });
  //   item.users?.forEach((user) => {
  //     recordSharedWithUsers.push(user.fullname);
  //   });
  // });
  // console.log("record shared with users =", recordSharedWithUsers);

  return (
    <>
      <Card
        className={`experience_card ${exp_type_class} main_card exp_card border-0 shadow1 mb2`}
      >
        <Card.Header className="experience_card_header">
          <div className="media">
            {(exp.mainType === "exp_bank" ||
              exp.mainType === "associate" ||
              exp.mainType === "match") && (
              <div className="media_icon_wrap">
                <img
                  src={`${process.env.PUBLIC_URL}/img/${icon_url}`}
                  className={`media_icon`}
                  alt={`${type_of_recorded_exp} icon`}
                />
              </div>
            )}
            {exp.mainType === "share" && (
              <div className="media_img_wrap">
                {/* <img
                  src={`${process.env.PUBLIC_URL}/img/profile1.png`}
                  className={`rounded-circle media_img`}
                  alt="user profile"
                /> */}
                <img
                  src={
                    (exp.user?.profile_img !== "NULL" &&
                      exp.user.profile_img) ||
                    `${process.env.PUBLIC_URL}/img/user-profile.jpg`
                  }
                  className={`rounded-circle media_img`}
                  alt={`${exp.user?.name} profile`}
                />
              </div>
            )}

            <div className={`media_body ms-2 ms-md-3`}>
              <Card.Title className="card_title">
                {exp.mainType === "share" ? (
                  <>{`${type_of_recorded_exp} shared`}</>
                ) : (
                  <>{`${type_of_recorded_exp} recorded`}</>
                )}
              </Card.Title>
              <Card.Subtitle className="card_subtitle">
                {/* By {authUser.firstname} */}
                {/* By {exp.user?.name} */}
                {exp.user && <>{`By ${exp.user.name}`}</>}
                {exp.match_profile && (
                  <>{`By ${exp.match_profile.firstname} ${exp.match_profile.lastname}`}</>
                )}
                {exp.mainType === "share" ? (
                  <>
                    {" "}
                    with{" "}
                    {exp.share_users?.map((item, index) => {
                      return (
                        <>
                          {item.fullname}
                          {index < exp.share_users?.length - 1 && ", "}
                        </>
                      );
                    })}
                  </>
                ) : (
                  // <> on {`${exp.record_date} ${exp.record_time}`}</>
                  <>
                    {" "}
                    <br className="d-block d-sm-block d-md-none" /> on{" "}
                    {`${exp.record_created_date} ${exp.record_time}`}
                  </>
                )}
              </Card.Subtitle>
              {exp.mainType === "match" && (
                <>
                  {/* <div className="progressbar_wrap">
                    <CustomProgressBar
                      className="rounded_progress small_progress"
                      progress={exp?.percent_match}
                    />
                  </div> */}
                  <div className="progressbar_wrap2">
                    <VisibilitySensor>
                      {({ isVisible }) => {
                        const percentage = isVisible ? exp?.percent_match : 0;
                        return (
                          <CircularProgressProvider
                            valueStart={0}
                            valueEnd={isVisible ? exp?.percent_match : 0}
                          >
                            {(value) => (
                              <CircularProgressbar
                                value={value}
                                text={`${value}%`}
                                className="sample-progress"
                                strokeWidth={6}
                                styles={buildStyles({
                                  rotation: 0,
                                  textSize: "24px",
                                  textColor: "#4b9754",
                                  pathTransitionDuration: 1.5,
                                  pathColor: `#70c37a`,
                                  trailColor: "#d9d9d9",
                                  backgroundColor: "transparent",
                                })}
                              />
                            )}
                          </CircularProgressProvider>

                          // <CircularProgressbar
                          //   value={percentage}
                          //   text={`${percentage}%`}
                          //   strokeWidth={6}
                          //   styles={buildStyles({
                          //     rotation: 0,
                          //     // textSize: "24px",
                          //     // textColor: "#4b9754",
                          //     pathTransitionDuration: 1.5,
                          //     // pathColor: `#70c37a`,
                          //     // trailColor: "#d9d9d9",
                          //     backgroundColor: "transparent",
                          //   })}
                          // />
                        );
                      }}
                    </VisibilitySensor>
                    {/* <CircularProgressProvider
                      valueStart={0}
                      valueEnd={exp?.percent_match}
                    >
                      {(value) => (
                        <CircularProgressbar
                          value={value}
                          text={`${value}%`}
                          className="sample-progress"
                          strokeWidth={6}
                          styles={buildStyles({
                            rotation: 0,
                            textSize: "24px",
                            textColor: "#4b9754",
                            pathTransitionDuration: 1.5,
                            pathColor: `#70c37a`,
                            trailColor: "#d9d9d9",
                            backgroundColor: "transparent",
                          })}
                        />
                      )}
                    </CircularProgressProvider> */}
                  </div>
                </>
              )}
            </div>
            {exp.mainType === "exp_bank" && (
              <>
                {exp.actions.includes("delete") && (
                  <Button
                    onClick={handleShow}
                    className={`btn_theme btn_icon btn_auto_width btn_danger btn_circle btn_favorite me-1`}
                    type="button"
                    title="Delete"
                  >
                    <BsFillTrashFill />
                  </Button>
                )}
                {exp.actions.includes("edit") && (
                  <CustomCollapseButton
                    handleCollapse={handleCollapse}
                    btnTitle={`Edit`}
                    className="btn_gray btn_icon btn_auto_width btn_circle me-1"
                    ariaControls="edit"
                    ariaExpanded={openCollapse.edit_collapse}
                    btnType="icon"
                  />
                )}

                <Button
                  onClick={() => {
                    handleIsFavorite(exp);
                  }}
                  // {exp.visibleOnDashbord &&
                  //   style={{'pointer-events': 'none'}}
                  // }

                  // className={`${
                  //   exp.is_favorite ? "active" : ""
                  // } btn_theme btn_icon btn_auto_width btn_light btn_circle btn_favorite`}
                  className={`${isFavorite ? "active" : ""} ${
                    exp.visibleOnDashbord ? "pe-none" : ""
                  } btn_theme btn_icon btn_auto_width btn_light btn_circle btn_favorite`}
                  type="button"
                  title="Make favorite"
                >
                  {loadingFavorite ? (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    </>
                  ) : (
                    <BsFillStarFill />
                  )}
                </Button>
              </>
            )}
          </div>
        </Card.Header>
        <Card.Body className="experience_card_body">
          <div className={`exp_title mb2`}>
            {exp.title}
            {/* | {exp.record_id}{" "} */}
          </div>

          <div className="btn_wrap btn_wrap_overflow">
            {exp.actions?.map((action, index) => {
              switch (action) {
                case "add_association":
                  return (
                    <CustomCollapseButton
                      key={index}
                      handleCollapse={handleCollapse}
                      btnTitle="Associate"
                      className="btn_small btn_warning btn_rounded"
                      ariaControls="add_association"
                      ariaExpanded={openCollapse.add_association_collapse}
                      setCardTop={setCardTop}
                    />
                  );

                case "view_associations":
                  return (
                    <CustomCollapseButton
                      key={index}
                      handleCollapse={handleCollapse}
                      btnTitle={
                        associationsCount === 1
                          ? `${associationsCount} Association`
                          : `${associationsCount} Associations`
                      }
                      className="btn_small btn_light btn_rounded"
                      ariaControls="view_associations"
                      ariaExpanded={openCollapse.view_associations_collapse}
                      handleAssociationList={handleAssociationList}
                      exp={exp}
                      setCardTop={setCardTop}
                    />
                  );

                case "share":
                  return (
                    <CustomCollapseButton
                      key={index}
                      handleCollapse={handleCollapse}
                      btnTitle={`Share`}
                      className="btn_small btn_pink btn_rounded"
                      ariaControls="share"
                      ariaExpanded={openCollapse.share_collapse}
                      setCardTop={setCardTop}
                    />
                  );

                case "add_comment":
                  return;
                // return (
                //   <CustomCollapseButton
                //     key={index}
                //     handleCollapse={handleCollapse}
                //     btnTitle={`Add Comment`}
                //     className="btn_small btn_secondary btn_rounded"
                //     ariaControls="add_comment"
                //     ariaExpanded={openCollapse.add_comment_collapse}
                //     setCardTop={setCardTop}
                //   />
                // );
                case "view_comments":
                  return (
                    <CustomCollapseButton
                      key={index}
                      handleCollapse={handleCollapse}
                      btnTitle={
                        commentsCount === 1
                          ? `${commentsCount} Comment`
                          : `${commentsCount} Comments`
                      }
                      // btnTitle={`${commentsCount} Comments`}
                      className="btn_small btn_light btn_rounded"
                      ariaControls="view_comments"
                      ariaExpanded={openCollapse.view_comments_collapse}
                      setCardTop={setCardTop}
                    />
                  );
                case "match":
                  return (
                    <CustomCollapseButton
                      key={index}
                      handleCollapse={handleCollapse}
                      btnTitle={`Match`}
                      className="btn_small btn_success btn_rounded"
                      ariaControls="match"
                      ariaExpanded={openCollapse.match_collapse}
                      setCardTop={setCardTop}
                    />
                  );
                case "view_match":
                  return (
                    <CustomCollapseButton
                      key={index}
                      handleCollapse={handleCollapse}
                      btnTitle={`Matching Elements`}
                      className="btn_small btn_secondary btn_rounded"
                      ariaControls="view_match"
                      ariaExpanded={openCollapse.view_match_collapse}
                      setCardTop={setCardTop}
                    />
                  );
                case "connect":
                  return (
                    // <Button
                    //   type="button"
                    //   className="btn_theme btn_small btn_success btn_rounded"
                    //   onClick={() => {
                    //     handleMatchConnect(exp.user_id);
                    //   }}
                    //   disabled={exp.is_connected}
                    // >
                    //   {exp.is_connected ? "Connected" : "Connect"}
                    // </Button>
                    <CustomCollapseButton
                      key={index}
                      handleCollapse={handleCollapse}
                      btnTitle={exp.is_connected ? "Connected" : "Connect"}
                      className="btn_small btn_success btn_rounded"
                      ariaControls="connect"
                      ariaExpanded={openCollapse.connect_collapse}
                    />
                  );

                case "edit":
                  return;
                // return (
                //   <CustomCollapseButton
                //     key={index}
                //     handleCollapse={handleCollapse}
                //     btnTitle={`Edit`}
                //     className="btn_small btn_light btn_rounded"
                //     ariaControls="edit"
                //     ariaExpanded={openCollapse.edit_collapse}
                //   />
                // );
                case "delete":
                  return;
                // return (
                //   <CustomCollapseButton
                //     key={index}
                //     handleCollapse={handleCollapse}
                //     btnTitle={`Delete`}
                //     className="btn_small btn_danger btn_rounded"
                //     ariaControls="delete"
                //     ariaExpanded={openCollapse.delete_collapse}
                //   />
                // );

                case "decode":
                  if (exp.record_type === "dream") {
                    return (
                      <CustomCollapseButton
                        key={index}
                        handleCollapse={handleCollapse}
                        btnTitle={`AI Decode`}
                        className="btn_small btn_secondary btn_rounded"
                        ariaControls="decode"
                        ariaExpanded={openCollapse.decode_collapse}
                        setCardTop={setCardTop}
                      />
                    );
                  } else {
                    return;
                  }
                case "view_details":
                  return (
                    <CustomCollapseButton
                      key={index}
                      handleCollapse={handleCollapse}
                      btnTitle={`Details`}
                      className="btn_small btn_primary btn_rounded"
                      ariaControls="view_details"
                      ariaExpanded={openCollapse.view_details_collapse}
                      handleRecordDetails={handleRecordDetails}
                      exp={exp}
                      setCardTop={setCardTop}
                    />
                  );
              }
            })}
          </div>
          {exp.actions?.map((action, index) => {
            switch (action) {
              case "add_association":
                return (
                  <CustomCollapseContainer
                    key={`add-assoc-${index}`}
                    ariaControls="add_association"
                    openCollapse={openCollapse.add_association_collapse}
                    cardTop={cardTop}
                  >
                    <hr />
                    <div className="pt2 pb2">
                      <AddAssociation
                        record_id={exp.record_id}
                        exp={exp}
                        setAssociationsCount={setAssociationsCount}
                        setAssociateExpbankData={setAssociateExpbankData}
                        isAddMode={true}
                        handleCollapse={handleCollapse}
                      />
                    </div>
                  </CustomCollapseContainer>
                );

              case "view_associations":
                return (
                  <CustomCollapseContainer
                    key={`view-assoc-${index}`}
                    ariaControls="view_associations"
                    openCollapse={openCollapse.view_associations_collapse}
                    handleAssociationList={handleAssociationList}
                    record_id={exp.record_id}
                    cardTop={cardTop}
                  >
                    <hr />
                    <div className="pt2 pb2">
                      <AssociationList
                        record_id={exp.record_id}
                        association={association}
                        handleAssociationList={handleAssociationList}
                        handleCollapse={handleCollapse}
                        loading={loading}
                      />
                    </div>
                  </CustomCollapseContainer>
                );

              case "share":
                return (
                  <CustomCollapseContainer
                    key={`share-${index}`}
                    ariaControls="share"
                    openCollapse={openCollapse.share_collapse}
                    cardTop={cardTop}
                  >
                    <hr />
                    <div className="pt2 pb2">
                      <Share
                        record_id={exp.record_id}
                        handleCollapse={handleCollapse}
                      />
                    </div>
                  </CustomCollapseContainer>
                );

              case "add_comment":
                return (
                  <CustomCollapseContainer
                    key={`add-comment-${index}`}
                    ariaControls="add_comment"
                    openCollapse={openCollapse.add_comment_collapse}
                  >
                    <hr />
                    {/* <div className="pt2 pb2">
                      <AddComment
                        record_id={exp.record_id}
                        exp={exp}
                        setCommentsCount={setCommentsCount}
                        handleCollapse={handleCollapse}
                      />
                    </div> */}
                  </CustomCollapseContainer>
                );
              case "view_comments":
                return (
                  <CustomCollapseContainer
                    key={`view-comments-${index}`}
                    ariaControls="view_comments"
                    openCollapse={openCollapse.view_comments_collapse}
                    handleCommentList={handleCommentList}
                    record_id={exp.record_id}
                    cardTop={cardTop}
                  >
                    <DashboardComments
                      comment_type="record"
                      loading={loading}
                      parent_id={exp.record_id}
                      parent_owner_id={null}
                      comments={comments}
                      handleCommentList={handleCommentList}
                      setCommentsCount={setCommentsCount}
                    />
                    {/* <hr /> */}
                    {/* <div className="pt2 pb2">
                      <CommentList
                        record_id={exp.record_id}
                        comments={comments}
                        handleCommentList={handleCommentList}
                        handleCollapse={handleCollapse}
                        loading={loading}
                      />
                    </div> */}
                  </CustomCollapseContainer>
                );
              case "match":
                return (
                  <CustomCollapseContainer
                    key={`match-${index}`}
                    ariaControls="match"
                    openCollapse={openCollapse.match_collapse}
                    handleMatchedExperienceList={handleMatchedExperienceList}
                    record_id={exp.record_id}
                    cardTop={cardTop}
                  >
                    <hr />
                    <div className="pt2 pb2">
                      <MatchedExperienceList
                        record_id={exp.record_id}
                        matchedRecords={matchedRecords}
                        loading={loading}
                      />
                      {/* <CustomCard className="border-0 shadow1">
                        <Card.Body className="text-center">
                          <BsFillExclamationCircleFill
                            style={{ color: "#fd6464", fontSize: "30px" }}
                            className="mb1"
                          />
                          <div className="font-16 fw_sb mb2">
                            No more matching records found.
                          </div>
                        </Card.Body>
                      </CustomCard> */}
                    </div>
                  </CustomCollapseContainer>
                );
              case "view_match":
                return (
                  <CustomCollapseContainer
                    key={`view-match-${index}`}
                    ariaControls="view_match"
                    openCollapse={openCollapse.view_match_collapse}
                    record_id={exp.record_id}
                    getMatchedElements={getMatchedElements}
                    matchedRecords={matchedRecords}
                    cardTop={cardTop}
                  >
                    <hr />
                    <div className="pt2 pb2">
                      <MatchedExperienceElements
                        matchingRecordElements={matchingRecordElements}
                      />
                      {/* <MatchingElements /> */}
                    </div>
                  </CustomCollapseContainer>
                );
              case "connect":
                // return;
                return (
                  <CustomCollapseContainer
                    key={`connect-${index}`}
                    ariaControls="connect"
                    openCollapse={openCollapse.connect_collapse}
                  >
                    <hr />
                    <div className="pt2 pb2">
                      {exp.is_connected ? (
                        <>
                          <SimpleMessageCard
                            type="success"
                            msg={`${exp.match_profile?.firstname} 
                                      ${exp.match_profile?.lastname} is already your friend.`}
                          />
                          {/* <CustomCard className="shadow1 border-0 text-center">
                            <Card.Body>
                              <div className="mb1">
                                <BsCheckCircleFill
                                  style={{
                                    color: "#70c37a",
                                    fontSize: "2rem",
                                  }}
                                />
                              </div>
                              {exp.match_profile && (
                                <>
                                  <p>
                                    <b>
                                      {`${exp.match_profile.firstname} 
                                      ${exp.match_profile.lastname}`}
                                    </b>{" "}
                                    is already your friend.
                                  </p>
                                </>
                              )}
                            </Card.Body>
                          </CustomCard> */}
                        </>
                      ) : (
                        <>
                          <MatchingConnect
                            exp={exp}
                            handleCollapse={handleCollapse}
                          />
                        </>
                      )}
                    </div>
                  </CustomCollapseContainer>
                );

              case "edit":
                return (
                  <CustomCollapseContainer
                    key={`edit-${index}`}
                    ariaControls="edit"
                    openCollapse={openCollapse.edit_collapse}
                    handleRecordDetails={handleRecordDetails}
                    record_id={exp.record_id}
                    cardTop={cardTop}
                  >
                    <hr />
                    <div className="pt2 pb2">
                      <DashboardEditExperience
                        id={exp.record_id}
                        recordDetails={recordDetails}
                        handleCollapse={handleCollapse}
                      />
                    </div>
                  </CustomCollapseContainer>
                );
              case "delete":
                return (
                  <CustomCollapseContainer
                    key={`delete-${index}`}
                    ariaControls="delete"
                    openCollapse={openCollapse.delete_collapse}
                  >
                    <hr />
                    <div className="pt2 pb2">
                      <CustomCard className="border-0 shadow1">
                        <Card.Body className="text-center">
                          <BsFillExclamationCircleFill
                            style={{ color: "#fd6464", fontSize: "30px" }}
                            className="mb1"
                          />
                          <div className="font-16 fw_sb mb2">
                            Are you sure you want to delete it?
                          </div>
                          <div className="btn_wrap">
                            <Button
                              type="button"
                              onClick={() => {
                                handleRecordDelete(exp.record_id);
                              }}
                              className="btn_theme btn_small btn_rounded btn_danger"
                            >
                              {loading ? (
                                <>
                                  <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                  />{" "}
                                  Deleting
                                </>
                              ) : (
                                "Yes"
                              )}
                            </Button>
                            <Button
                              type="button"
                              onClick={() => {
                                handleCollapse("delete");
                              }}
                              className="btn_theme btn_small btn_rounded btn_light"
                            >
                              No
                            </Button>
                          </div>
                        </Card.Body>
                      </CustomCard>
                    </div>
                  </CustomCollapseContainer>
                );

              case "decode":
                return (
                  <CustomCollapseContainer
                    key={`decode-${index}`}
                    ariaControls="decode"
                    openCollapse={openCollapse.decode_collapse}
                    handleRecordDetails={handleRecordDetails}
                    record_id={exp.record_id}
                    cardTop={cardTop}
                  >
                    <hr />
                    <div className="pt2 pb2">
                      <DecodeContent
                        id={exp.record_id}
                        recordDetails={recordDetails}
                      />
                    </div>
                  </CustomCollapseContainer>
                );
              case "view_details":
                return (
                  <CustomCollapseContainer
                    key={`view-details-${index}`}
                    ariaControls="view_details"
                    openCollapse={openCollapse.view_details_collapse}
                    handleRecordDetails={handleRecordDetails}
                    record_id={exp.record_id}
                    cardTop={cardTop}
                  >
                    <hr />
                    <div className="pt2 pb2">
                      <DashboardExperienceDetails
                        id={exp.record_id}
                        recordDetails={recordDetails}
                      />
                    </div>
                  </CustomCollapseContainer>
                );
            }
          })}
        </Card.Body>
      </Card>

      <ConfirmationModal
        show={show}
        loading={loading}
        handleClose={handleClose}
        primaryActionData={exp.record_id}
        content_type="record-delete-modal"
        handlePrimaryAction={handleRecordDelete}
      />
    </>
  );
}

export default DashboardExperienceCard;
