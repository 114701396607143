import React, { useState } from "react";
import { Modal, Button, Form, Spinner } from "react-bootstrap";
import SetNewPassword from "../../components/Login/SetNewPassword";
import FieldError from "../FieldError/FieldError";
import styles from "./ForgotPasswordModal.module.css";
import { Formik } from "formik";
import * as yup from "yup";
import { handleAlert } from "../../util/util";
//Service
import authService from "../../services/Auth";

function ForgotPasswordModal(props) {
  const [loading, setLoading] = useState(false);
  const [isPasswordChanged, setIsPasswordChanged] = useState("failed"); //failed
  const [registeredEmail, setRegisteredEmail] = useState("");

  const initialValues = {
    email: "",
  };

  const forgotPasswordSchema = yup.object({
    email: yup.string().email("Invalid email format").required("Required"),
  });

  const onSubmit = async (values, { resetForm }) => {
    setLoading(true);
    console.log("Form Values :", values);

    authService
      .forgotPassword(values)
      .then((response) => {
        if (response.data.status === "success") {
          console.log(response.data);
          setRegisteredEmail(values.email);
          setIsPasswordChanged("success");
          console.log(
            "Message: We have sent a confirmation code on your email"
          );
          handleAlert(
            "We have sent a confirmation code on your email",
            "success"
          );
          resetForm();
          setLoading(false);
        } else {
          console.log("ERROR:", response.data.error);
          handleAlert("There is a problem while sending a code", "error");
          setIsPasswordChanged("failed");
          resetForm();
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Modal
        centered
        size="md"
        className={styles.theme_modal}
        show={props.show.isVisible}
        onHide={props.handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Forgot password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form_style">
            {isPasswordChanged === "failed" && (
              <Formik
                initialValues={initialValues}
                validationSchema={forgotPasswordSchema}
                onSubmit={onSubmit}
              >
                {(formik) => {
                  return (
                    <Form onSubmit={formik.handleSubmit}>
                      <Form.Group className="form-group rounded_corners">
                        <Form.Control
                          type="text"
                          placeholder="Email"
                          name="email"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          autoComplete="off"
                        />
                        <Form.Label className="label_float">Email</Form.Label>
                        <FieldError
                          valid={
                            formik.touched.email && formik.errors.email
                              ? true
                              : false
                          }
                          text={formik.errors.email}
                        />
                      </Form.Group>
                      <Button
                        type="submit"
                        disabled={!formik.isValid}
                        className="btn_theme btn_theme_gradient btn_rounded mt1"
                      >
                        {loading ? (
                          <>
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />{" "}
                            Loading
                          </>
                        ) : (
                          "Submit"
                        )}
                      </Button>
                    </Form>
                  );
                }}
              </Formik>
            )}
            {isPasswordChanged === "success" && (
              <SetNewPassword
                registeredEmail={registeredEmail}
                setIsPasswordChanged={setIsPasswordChanged}
                handleClose={props.handleClose}
              />
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ForgotPasswordModal;
