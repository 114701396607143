import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./HomeAbout.css";

import HomeSectionHeading from "../HomeSectionHeading/HomeSectionHeading";
import HomeSectionSubHeading from "../HomeSectionHeading/HomeSectionSubHeading";

function HomeAbout() {
  return (
    <>
      <div className="index_sect about_section">
        <Container>
          <Row>
            <Col xs={12} sm={12} md={8}>
              <HomeSectionHeading
                htmlTag="h2"
                type="el"
                titleText="What does your dream mean?"
                className=""
              />

              <HomeSectionSubHeading
                htmlTag=""
                type=""
                titleText="Record your unconscious experiences in your personal
                experience bank."
                className="text_primary fw_l"
              />
              {/* <HomeSectionHeading className="section_heading">
                <h2>What does your dream mean?</h2>
                <p className="text_primary">
                  Record your unconscious experiences in your personal
                  experience bank.
                </p>
              </HomeSectionHeading> */}
            </Col>
          </Row>
          <img
            src={`${process.env.PUBLIC_URL}/img/about-ud.png`}
            className="d-block bg_img"
            alt=""
          />
        </Container>
      </div>
    </>
    //         <section className="index_sect about_section">
    //     <div className="container">
    //         <div className="row">
    //             <div className="col-12 col-sm-12 col-md-8">
    //                 <div className="section_heading">
    //                     <h2>What does your dream mean?</h2>
    //                     <p className="text_primary">Record your unconscious experiences in your personal experience bank.</p>
    //                 </div>
    //             </div>
    //         </div>
    //         <img src="./img/about-ud.png" className="d-block bg_img" alt="">
    //     </div>
    // </section>
  );
}

export default HomeAbout;
