import React, { useState } from "react";
import { Rating } from "react-simple-star-rating";
import "./CustomRating.css";

function CustomRating(props) {
  const [rating, setRating] = useState(0); // initial rating value
  const [ratingValue, setRatingValue] = useState(0);

  console.log("I am running", ratingValue);

  const tooltipClasses = `custom-rating-tooltip ${
    props.tooltipSize
      ? props.tooltipSize === "small"
        ? "tooltip-small"
        : "tooltip-large"
      : ""
  } ${props.showTooltipOnHover ? "show-hover" : ""}`;

  const options = {
    size: props.size ? props.size : 20,
    fillColorArray: ["#f17a45", "#f19745", "#f1a545", "#eeb600", "#ffc709"],
    emptyColor: props.emptyColor ? props.emptyColor : "#bbbbbb",
    readonly: props.readonly ? props.readonly : false,
    transition: props.transition ? props.transition : false,
    showTooltip: props.showTooltip ? props.showTooltip : false,
    allowHover: props.allowHover ? props.allowHover : false,
    initialValue: props.initialValue ? props.initialValue : 0,
    iconsCount: props.iconsCount ? props.iconsCount : 5,
    className: `custom-rating ${props.className !== "" ? props.className : ""}`,
    tooltipClassName: tooltipClasses,
    tooltipDefaultText: "No Ratings",
    tooltipArray: ["Bad", "Good", "Better", "Best", "Awesome"],
  };

  // Catch Rating value
  const handleRating = (rate) => {
    const modRate = Math.round((options.iconsCount * rate) / 100);
    setRatingValue(modRate);
    // console.log("Rate by plugin = ", rate, "\nYour rating = ", modRate);
    if (props.onUserRateChange) {
      props.onUserRateChange(modRate);
    }
  };

  return (
    <>
      <Rating onClick={handleRating} {...options} />
    </>
  );
}

export default CustomRating;
