import React, {useState, useRef} from "react";
import { Row, Col, Card, Form, Button } from "react-bootstrap";
import DashboardSectionTitle from "../DashboardSectionTitle/DashboardSectionTitle";
import "./DashboardVideoContent.css";
import VideoModal from "../../../ui/VideoModal/VideoModal";
import { YTVIDEOS } from "../../../util/ytvideos";
import { debounce } from "lodash";
import { BsX, BsPlayCircleFill } from "react-icons/bs";
import PlaceholderCard from "../../../ui/PlaceholderCard/PlaceholderCard";
import CustomCard from "../../../ui/CustomCard/CustomCard";

function DashboardVideoContent() {
  const searchInputRef = useRef();
  const [loading, setLoading] = useState(false);
  const [videos, setVideos] = useState(YTVIDEOS);
  const [show, setShow] = useState({
    data: {},
    isVisible: false,
  });

  const handleClose = () => {
    setShow({
      data: {},
      isVisible: false,
    });
  };
  const handleShow = (data) => {
    setShow({
      data: data,
      isVisible: true,
    });
  };

  const handleClearField = () => {
    setLoading(true);
    searchInputRef.current.value = "";
    setTimeout(() => {
      setVideos(YTVIDEOS);
      setLoading(false);
    }, 500)
    
  };

  const handleChange = debounce((value) => {
    setLoading(true);
    if (value.trim().length > 0) {
      console.log(value.trim());
      const filteredVideos = YTVIDEOS.filter(video => 
        video.title.toString().toLowerCase().indexOf(value.trim().toLowerCase()) > -1
      );
      setTimeout(() => {
        setVideos(filteredVideos);
        setLoading(false);
      }, 500)
    } else {
      setTimeout(() => {
        setVideos(YTVIDEOS);
        setLoading(false);
      }, 500)
    }
  }, 500);

  //You can use following url to get youtube video thumbnail just replace the youtube video id...
  // https://img.youtube.com/vi/8k8i9IY4LCo/mqdefault.jpg

  const handleClick = (item) => {
    console.log("handleClick: video data =",item)
    let data = {
      title: item.title, 
      video_id: item.video_id
    }
    handleShow(data);
  }

  return (
    <>
      <DashboardSectionTitle
        iconImg="offer-icon13.png"
        title="Videos"
        pageTitle={true}
        className="mb2"
      />
      <CustomCard className={`main_card shadow1 border-0 mt2 mb2`}>
        <Card.Body>
          <div className="form_style pt-1 pb-1">
            
            <Form.Group className="form-group rounded_corners search_field autocomplete_field mb-0">
              <Form.Control
                type="text"
                name="searchInput"
                onChange={(e) => {
                  handleChange(e.target.value);
                }}
                ref={searchInputRef}
                placeholder="Search"
                style={{
                  backgroundImage: `url(${process.env.PUBLIC_URL}/img/search-icon.png)`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center right 20px",
                }}
                autoComplete="off"
              />
              {searchInputRef?.current?.value.length > 0 && (
                <Button
                  onClick={handleClearField}
                  className={`btn_theme btn_icon btn_auto_width btn_light btn_circle`}
                  type="button"
                >
                  <BsX />
                </Button>
              )}
            </Form.Group>
            
          </div>
        </Card.Body>
      </CustomCard>
      {loading && (
        <>
          <Row className="row-cols-1 row-cols-sm-2 g-4">
            {[0, 1].map((_, i) => (
              <Col key={`video-plceholder${i}`} >
                <PlaceholderCard key={`video-plceholder${i}`} type="video" />
              </Col>
            ))}
          </Row>
        </>
      )}
      {!loading &&
        !videos?.length > 0 &&
        searchInputRef?.current?.value.length > 0 && (
          <CustomCard className={`main_card border-0 text-center mt2 mb2`}>
            <Card.Body>
              <b>No matching videos</b>
            </Card.Body>
          </CustomCard>
        )}
      {!loading && videos?.length > 0 && (
        <>
          <Row className="row-cols-1 row-cols-sm-2 g-4">
          {videos?.map((video) => (
            <Col key={video?.video_id} >
            <Card 
              item={video}
              className={`main_card custom_card h-100 video_card border-0 overflow-hidden shadow1`}
              onClick={() => {
                handleShow(video)
              }}
            >
              <div className="card_img">
                <Card.Img
                  variant="top"
                  src={`https://img.youtube.com/vi/${video?.video_id}/mqdefault.jpg`}
                ></Card.Img>
              </div>
              <Card.Body className="d-flex align-items-center">
                <div className="heading-icon-wrap flex-shrink-0">
                  <span className="heading-icon d-flex justify-content-center align-items-center font-28">
                    <BsPlayCircleFill />
                  </span>
                </div>
                <div className="heading flex-grow-1 ms-3 ms-sm-3">{video?.title}</div>
              </Card.Body>
            </Card>
          </Col>
        ))}
          </Row>
        </>
      )}  
      
      <VideoModal
        show={show}
        video={show.data}
        handleClose={handleClose}
        
      />
    </>
  );
}

export default DashboardVideoContent;
