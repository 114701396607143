import { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { handleThemeChange } from "./util/util";

//Context
import AppContext from "./AppContext";
import ThemeContext from "./ThemeContext";

//Route Guards
import AuthGuard from "./services/AuthGuard";
import HomeGuard from "./services/HomeGuard";

//Components
import HomeGuest from "./pages/HomeGuest";
import Dashboard from "./pages/Dashboard";
import Signup from "./pages/Signup";
import About from "./pages/About";
import Press from "./pages/Press";
import PressDetails from "./pages/PressDetails";
import Blogs from "./pages/Blogs";
import BlogDetails from "./pages/BlogDetails";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import Cookies from "./pages/Cookies";
import Success from "./pages/Success";
import Failure from "./pages/Failure";

//Services
import recordService from "./services/Record";
import authService from "./services/Auth";

function App() {
  //This below line will stop console logs from printing throughout the app...
  console.log = function () {};

  // const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  // console.log("-------------------------")
  // console.log("timezone = ",timezone)
  // console.log("-------------------------")

  const [isLogin, setisLogin] = useState(false);
  const [theme, setTheme] = useState("light");
  const [experiences, setExperiences] = useState([]);
  const [profileStats, setProfileStats] = useState({});
  const [achievements, setAchievements] = useState({});
  const [friends, setFriends] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [invitations, setInvitations] = useState([]);
  const [groupInvitations, setGroupInvitations] = useState([]);
  const [groupRequests, setGroupRequests] = useState([]);
  const [consultInvitations, setConsultInvitations] = useState([]);

  console.log("-----EXECUTED----");
  console.log("Theme = ", theme);
  console.log("------------------");

  //Get Profile Stats
  function getUserProfileData() {
    recordService
      .getUserProfileData()
      .then((response) => {
        if (response.data.status === "success") {
          // console.log("Response from APP : ", response.data);
          const stats = response.data.data.result.details;
          setProfileStats(stats);
        }
        if (response.data.status === "failed") {
          console.log("ERROR:", response.data.error);
        }
      })
      .catch((err) => {
        console.log("There is a problem in fetching Profile Stats...", err);
      });
  }

  // Get all recorded experience list from DB
  function getData() {
    recordService
      .getAllRecords()
      .then((response) => {
        if (response.data.status === "success") {
          console.log("Response from APP Exps : ", response.data);
          const records = response.data.data.result.records;
          setExperiences(records);
        }
        if (response.data.status === "failed") {
          console.log("ERROR:", response.data.error);
        }
      })
      .catch((err) => {
        console.log("There is a problem in fetching records...", err);
      });
  }

  //Get all achievements
  function getAllAchievements() {
    // setLoading(true);
    recordService
      .getAllAchievements()
      .then((response) => {
        if (response.data.status === "success") {
          // console.log("getAllAchievements service res = ", response.data);
          const achievements = response.data.data.result.acheivement;
          const rankData = response.data.data.result.rank;
          // console.log("achievements records = ", achievements);
          // console.log("rankData = ", rankData);
          setAchievements({ achievements: achievements, rank: rankData });
          // setRank(rankData);
          // setLoading(false);
        } else if (response.data.status === "failed") {
          console.log("ERROR:", response.data.error);
          // setLoading(false);
          setAchievements({});
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // Get all friends related to current user from DB
  function getAllFriends() {
    recordService
      .getAllFriends()
      .then((response) => {
        // console.log("All Friends =", response.data);
        setFriends(response.data.data.result.friends);
      })
      .catch((err) => {
        console.log("There is a problem in getting all friends...", err);
      });
  }

  // Get all notification list from DB
  function getAllNotifications() {
    // console.log("inside getAllNotifications() in app.js");
    recordService
      .getAllNotifications()
      .then((response) => {
        if (response.data.status === "success") {
          // console.log("Response from APP notifications : ", response.data);
          setNotifications(
            response.data.data.result.notifications.filter(
              (notification) => !notification.is_read
            )
          );
        }
        if (response.data.status === "failed") {
          console.log("ERROR:", response.data.error);
        }
      })
      .catch((err) => {
        console.log("There is a problem in creating new record...", err);
      });
  }

  //Fetch all invitations from DB
  function getAllInvitations() {
    // console.log("inside getAllInvitations() in app.js");
    recordService
      .getAllInvitations()
      .then((response) => {
        // console.log("Response from APP invitations : ", response.data);
        setInvitations(response.data.data.result.friend_invites);
      })
      .catch((err) => {
        console.log("There is a problem in getting all invitations...", err);
      });
  }

  //Fetch all group invitations from DB
  function getAllGroupInvitations() {
    // console.log("inside getAllGroupInvitations() in app.js");
    recordService
      .getAllGroupInvitations()
      .then((response) => {
        // console.log("Response from APP group invitations : ", response.data);
        setGroupInvitations(response.data.data.result.group_invites);
      })
      .catch((err) => {
        console.log(
          "There is a problem in getting all group invitations...",
          err
        );
      });
  }

  //Fetch all group invitations from DB
  function getAllGroupRequests() {
    // console.log("inside getAllGroupRequests() in app.js");
    recordService
      .getAllGroupRequests()
      .then((response) => {
        // console.log("Response from APP group Requests : ", response.data);
        setGroupRequests(response.data.data.result.group_requests);
      })
      .catch((err) => {
        console.log("There is a problem in getting all group Requests...", err);
      });
  }

  //Fetch all consult invitations from DB
  function getAllConsultInvitations() {
    // console.log("inside getAllConsultInvitations() in app.js");
    recordService
      .getAllConsultInvitations()
      .then((response) => {
        // console.log("Response from APP consult invitations : ", response.data);
        setConsultInvitations(response.data.data.result.consult_invites);
      })
      .catch((err) => {
        console.log(
          "There is a problem in getting all consult invitations...",
          err
        );
      });
  }

  useEffect(() => {
    const current_theme = localStorage.getItem("theme");
    handleThemeChange(current_theme ? current_theme : theme, setTheme);
    // let token = localStorage.getItem("auth_token");
    let token = authService.getToken();
    if (token) {
      setisLogin(true);
      console.log("This will execute");
    }
  }, []);

  useEffect(() => {
    console.log("-----EXECUTED----");
    //get data if user is logged in (or token is set)
    // let token = localStorage.getItem("auth_token");
    let token = authService.getToken();
    // console.log("Theme = ",theme)
    // console.log("token = ", token);
    if (token) {
      // console.log('This will execute everytime');

      getData();
      getAllFriends();
      getAllNotifications();
      getAllInvitations();
      getAllGroupInvitations();
      getAllGroupRequests();
      getAllConsultInvitations();
      getUserProfileData();
      getAllAchievements();
    }
  }, []);

  return (
    <div className="app-wrapper">
      <ToastContainer
        position="top-center"
        hideProgressBar={true}
        transition={Slide}
        theme="dark"
        className="custom_toast"
      />

      <AppContext.Provider
        value={{
          experiences,
          setExperiences,
          friends,
          setFriends,
          notifications,
          setNotifications,
          invitations,
          setInvitations,
          groupInvitations,
          setGroupInvitations,
          groupRequests,
          setGroupRequests,
          consultInvitations,
          setConsultInvitations,
          profileStats,
          setProfileStats,
          achievements,
          setAchievements,
          getUserProfileData,
          getData,
          getAllFriends,
          getAllNotifications,
          getAllInvitations,
          getAllGroupInvitations,
          getAllGroupRequests,
          getAllConsultInvitations,
          getAllAchievements,
        }}
      >
        <ThemeContext.Provider value={{ theme, setTheme }}>
          <BrowserRouter>
            <Routes>
              {/* <HomeGuard path="/" exact component={HomeGuest} /> */}
              <Route path="/" element={<HomeGuard component={HomeGuest} />} />
              <Route
                path="dashboard"
                element={<AuthGuard component={Dashboard} auth={isLogin} />}
              />
              <Route
                path="dashboard/subscription-success"
                element={<AuthGuard component={Success} auth={isLogin} />}
              />
              <Route
                path="dashboard/subscription-failure"
                element={<AuthGuard component={Failure} auth={isLogin} />}
              />
              <Route
                path="dashboard/consult-success"
                element={<AuthGuard component={Success} auth={isLogin} />}
              />
              <Route
                path="dashboard/consult-failure"
                element={<AuthGuard component={Failure} auth={isLogin} />}
              />
              <Route
                path="dashboard/:id"
                element={<AuthGuard component={Dashboard} auth={isLogin} />}
              />
              <Route path="signup" element={<Signup />} />
              <Route path="about" element={<About />} />
              <Route path="press-details" element={<PressDetails />} />
              <Route path="press" element={<Press />} />
              <Route path="blog-details" element={<BlogDetails />} />
              <Route path="blogs" element={<Blogs />} />
              <Route path="privacy-policy" element={<Privacy />} />
              <Route path="terms" element={<Terms />} />
              <Route path="cookies" element={<Cookies />} />
            </Routes>
          </BrowserRouter>
        </ThemeContext.Provider>
      </AppContext.Provider>
    </div>
  );
}

export default App;
