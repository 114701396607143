import React from "react";
import { Card, Row, Col, ListGroup } from "react-bootstrap";
import CustomCard from "../../../ui/CustomCard/CustomCard";
import "./RankAndXP.css";
// import styles from "./RankAndXP.module.css";
import DashboardSectionTitle from "../DashboardSectionTitle/DashboardSectionTitle";
import Ratings from "../../../ui/Ratings/Ratings";

function RankAndXP() {
  const earnXPList = [
    { id: "list1", desc: "Recorded experience", earnXP: "2x" },
    { id: "list2", desc: "Shared experience", earnXP: "3x" },
    { id: "list3", desc: "Added friend", earnXP: "5x" },
    { id: "list4", desc: "Joined group", earnXP: "10x" },
    { id: "list5", desc: "Created analysis", earnXP: "10x" },
    // { id: "list6", desc: "Contributed to symbol", earnXP: "3x" },
    { id: "list6", desc: "Voted on dictionary meaning", earnXP: "3x" },
    { id: "list7", desc: "Added association", earnXP: "5x" },
    { id: "list8", desc: "Explored matches", earnXP: "5x" },
    { id: "list9", desc: "Favorited items", earnXP: "10x" },
    { id: "list10", desc: "Made resolutions", earnXP: "5x" },
    { id: "list11", desc: "Consulted", earnXP: "50x" },
    { id: "list12", desc: "Posted on a forum", earnXP: "15x" },
  ];

  const rankList = [
    { id: "rank1", xp: 0, ratings: 0 },
    { id: "rank2", xp: 10, ratings: 1 },
    { id: "rank3", xp: 100, ratings: 2 },
    { id: "rank4", xp: 500, ratings: 3 },
    { id: "rank5", xp: 1500, ratings: 4 },
    { id: "rank6", xp: 5000, ratings: 5 },
  ];

  return (
    <>
      {/* <CustomCard className={`main_card shadow1 border-0 mb2 overflow-hidden`}>
        <Card.Body> */}
      <DashboardSectionTitle
        iconImg=""
        title="Rank and XP"
        pageTitle={false}
        className="mb-2"
      />

      <div className="d-grid gap-2">
        <CustomCard className={`xp_info_card mb2 overflow-hidden`}>
          <Card.Header className="border-0 bg_primary">
            <ListGroup as="ul">
              <ListGroup.Item as="li" className="text_white fw_sb font-15">
                <Row className="w-100">
                  <Col xs={7} sm={7} md={9}>
                    <div className="">Description</div>
                  </Col>
                  <Col xs={5} sm={5} md={3}>
                    <div class="">XP</div>
                  </Col>
                </Row>
              </ListGroup.Item>
            </ListGroup>
          </Card.Header>
          <Card.Body className="bg_primary_light2">
            <ListGroup as="ul">
              {earnXPList.map((list) => {
                return (
                  <>
                    <ListGroup.Item
                      as="li"
                      className="font-14"
                      key={`earn-xp-${list.id}`}
                    >
                      <Row className="w-100">
                        <Col xs={7} sm={7} md={9}>
                          <div className="">{list.desc}</div>
                        </Col>
                        <Col xs={5} sm={5} md={3}>
                          <div class="">{list.earnXP}</div>
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  </>
                );
              })}
            </ListGroup>
          </Card.Body>
        </CustomCard>

        <CustomCard className={`xp_rank_card mb2 overflow-hidden`}>
          <Card.Header className="border-0">
            <ListGroup as="ul">
              <ListGroup.Item as="li" className="fw_sb font-15">
                <Row className="w-100">
                  <Col xs={5} sm={5} md={5}>
                    <div className="text-center">XP</div>
                  </Col>
                  <Col xs={7} sm={7} md={7}>
                    <div class="">Rank</div>
                  </Col>
                </Row>
              </ListGroup.Item>
            </ListGroup>
          </Card.Header>
          <Card.Body className="">
            <ListGroup as="ul">
              {rankList.map((list) => {
                return (
                  <>
                    <ListGroup.Item
                      as="li"
                      className="font-14"
                      key={`rank-info-${list.id}`}
                    >
                      <Row className="w-100">
                        <Col xs={5} sm={5} md={5}>
                          <div className="text-center">{list.xp}</div>
                        </Col>
                        <Col xs={7} sm={7} md={7}>
                          <div class="">
                            <Ratings
                              className=""
                              totalItems={5}
                              activeItems={list.ratings}
                              fillStyle={true}
                            />
                          </div>
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  </>
                );
              })}
            </ListGroup>
          </Card.Body>
        </CustomCard>
      </div>
      {/* </Card.Body>
      </CustomCard> */}
    </>
  );
}

export default RankAndXP;
