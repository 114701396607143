const features = [
  {
    id: "fs1",
    title: "Record",
    iconImg: "icons/offer-icon1.png",
    featureImg: "connection.jpg",
    desc: "Record detailed content and full descriptions of your unconscious experiences in your personal experience bank.",
  },

  {
    id: "fs2",
    title: "Share",
    iconImg: "icons/offer-icon10.png",
    featureImg: "connection.jpg",
    desc: "Share your unconscious experiences with whomever you choose. Others can also share with you and you can comment on each other's experiences.",
  },

  {
    id: "fs3",
    title: "Associate",
    iconImg: "icons/offer-icon2.png",
    featureImg: "connection.jpg",
    desc: "Find connections between your conscious and unconscious experiences.",
  },

  {
    id: "fs4",
    title: "Analyze",
    iconImg: "icons/offer-icon3.png",
    featureImg: "connection.jpg",
    desc: "Analyze, compare, and discover trends in recorded experiences from your own experience bank, your friends (anonymized) and the globe.",
  },

  {
    id: "fs5",
    title: "Network",
    iconImg: "icons/offer-icon4.png",
    featureImg: "connection.jpg",
    desc: "Connect with friends and create groups around similar interests and experiences.",
  },

  {
    id: "fs6",
    title: "Match",
    iconImg: "icons/offer-icon5.png",
    featureImg: "connection.jpg",
    desc: "Discover who is having similar unconscious experiences and how many similar experiences are occurring all over the globe.",
  },

  {
    id: "fs7",
    title: "Consult",
    iconImg: "icons/offer-icon6.png",
    featureImg: "connection.jpg",
    desc: "Consult with licensed practitioners about your unconscious experiences in order to discover more about yourself or receive therapy.",
  },

  {
    id: "fs8",
    title: "Discover",
    iconImg: "icons/offer-icon7.png",
    featureImg: "connection.jpg",
    desc: "Discover more about what your unconscious experiences (e.g., dreams) mean, ask questions in the forum, and see frequently asked questions.",
  },

  {
    id: "fs9",
    title: "Shop",
    iconImg: "icons/offer-icon8.png",
    featureImg: "connection.jpg",
    desc: "Find products to help you navigate, facilitate and augment your unconscious experiences.",
  },

  {
    id: "fs10",
    title: "Achievements",
    iconImg: "icons/offer-icon9.png",
    featureImg: "connection.jpg",
    desc: "Rank up and earn rewards as you learn more about yourself by unlocking your unconscious mind.",
  },
];

export { features };
