import React, { useState } from "react";
import { Form, Button, Spinner } from "react-bootstrap";
import DashboardSectionTitle from "../DashboardSectionTitle/DashboardSectionTitle";
import { Field, Formik } from "formik";
import * as yup from "yup";
import { handleAlert } from "../../../util/util";

//Service
import recordService from "../../../services/Record";

function MatchingConnect(props) {
  const [loading, setLoading] = useState(false);

  const initialValues = {
    message: "",
  };
  const addMatchingConnectSchema = yup.object({
    message: yup.string().required("Required"),
  });

  const onSubmit = async (values, { resetForm }) => {
    setLoading(true);
    console.log("Form Values :", values);

    const addFriendData = {
      ...values,
      friend_id: props.exp.user_id,
    };

    console.log("addFriendData =", addFriendData);

    recordService
      .addFriend(addFriendData)
      .then((response) => {
        if (response.data.status === "success") {
          // console.log("If status-success =", response.data);
          // console.log("msg =", response.data.message);
          handleAlert(response.data.message, "success");
          setLoading(false);
          resetForm({});
          props.handleCollapse("connect");
        }
        if (response.data.status === "failed") {
          // console.log("If status-failed =", response.data);
          // console.log("msg =", response.data.error);
          handleAlert(response.data.error, "error");
          setLoading(false);
          resetForm({});
          props.handleCollapse("connect");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <DashboardSectionTitle
        iconImg=""
        title="Send request with a message"
        pageTitle={false}
        className="small_title mb2"
      />
      <Formik
        initialValues={initialValues}
        validationSchema={addMatchingConnectSchema}
        onSubmit={onSubmit}
      >
        {(formik) => {
          return (
            <Form className="form_style" onSubmit={formik.handleSubmit}>
              {/* <DashboardSectionTitle
                iconImg=""
                title="Add your message"
                pageTitle={false}
                className="mb2 small_title"
              /> */}
              <Form.Group className="form-group rounded_corners">
                <Form.Control
                  as="textarea"
                  rows={6}
                  style={{ height: "140px" }}
                  placeholder="Message"
                  name="message"
                  {...formik.getFieldProps("message")}
                  autoComplete="off"
                />
                {formik.touched.message && formik.errors.message ? (
                  <p className="error-state-text">{formik.errors.message}</p>
                ) : null}
              </Form.Group>
              <div class="mt1 mb1 btn_wrap">
                <Button
                  type="submit"
                  className="btn btn_theme btn_small btn_primary btn_rounded"
                >
                  {loading ? (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />{" "}
                      Sending Request
                    </>
                  ) : (
                    "Send Request"
                  )}
                </Button>
                <Button
                  type="button"
                  onClick={() => {
                    props.handleCollapse("connect");
                  }}
                  className="btn btn_theme btn_small btn_light btn_rounded"
                >
                  Cancel
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}

export default MatchingConnect;
