import React, { useState, useEffect } from "react";
import { callOpenAIAPI } from "../../services/ChatService";
import { Link } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Login from "../Login/Login";
import ForgotPasswordModal from "../../ui/ForgotPasswordModal/ForgotPasswordModal";
import SocialUserRegistrationModal from "../../ui/SocialUserRegistrationModal/SocialUserRegistrationModal";
import "./HomeGuestBanner.css";

function HomeGuestBanner() {
  // OpenAI API
  const [userInput, setUserInput] = useState("");
  const [openAIResponse, setOpenAIResponse] = useState("");
  const [showTooltip, setShowTooltip] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Check if the user has already interacted (based on localStorage)
  const [hasInteracted, setHasInteracted] = useState(
    localStorage.getItem("hasInteracted") === "true"
  );

  useEffect(() => {
    // console.info(
    //   "localStorage.getItem('hasInteracted') === 'true' = ",
    //   localStorage.getItem("hasInteracted") === "true"
    // );
    // Update the disabled state based on localStorage
    setHasInteracted(localStorage.getItem("hasInteracted") === "true");
  }, []);

  const isDisabled = hasInteracted || showTooltip || isLoading;
  // console.log("isDisabled = ", isDisabled);

  // Word count checker
  const countWords = (str) => {
    return str.trim().split(/\s+/).length;
  };

  // OpenAI API Function
  const handleInterpretClick = async () => {
    try {
      setIsLoading(true); // Start loading
      const response = await callOpenAIAPI({ message: userInput });
      const limitedResponse =
        response.data.message.split(" ").slice(0, 50).join(" ") + "..."; // Limit to 50 words
      setOpenAIResponse(limitedResponse);
      setShowTooltip(true); // Show tooltip when the response is received
      setIsLoading(false); // End loading
      localStorage.setItem("hasInteracted", "true"); // Set interaction flag in localStorage
      setHasInteracted(true); // Update local state
    } catch (error) {
      console.error("Error calling OpenAI API:", error);
      setOpenAIResponse(
        "Error: Could not get an interpretation due to technical issues. Please check back later!"
      );
      setShowTooltip(true); // Show tooltip even if there's an error
      setIsLoading(false); // End loading even if there's an error
    }
  };

  const [socialUserData, setSocialUserData] = useState({});
  // forgot password modal state
  const [show, setShow] = useState({ isVisible: false });
  //social user reg. modal state
  const [show2, setShow2] = useState({ isVisible: false });

  const handleClose = () => setShow({ isVisible: false });
  const handleShow = () => setShow({ isVisible: true });
  const handleClose2 = () => setShow2({ isVisible: false });
  const handleShow2 = () => setShow2({ isVisible: true });

  return (
    <>
      <section className={`main_banner split_banner`}>
        <div
          className={`split_item split_item_70`}
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/img/main-banner-bg.jpg)`,
          }}
        >
          <div className={`split_item_content`}>
            <h1>
              Use your dreams to <span className={`line1`}>improve your</span>
              <span className={`line2`}>life</span>
              <span className={`line3`}>
                {/* Search Bar UI */}
                <div className="interpret-search-bar">
                  <input
                    type="text"
                    value={userInput}
                    onChange={(e) => setUserInput(e.target.value)}
                    placeholder={
                      isDisabled
                        ? "Sign up to use our service to interpret more dreams!"
                        : "Type or paste your dream for a free AI interpretation... Or sign in."
                    }
                    readOnly={isDisabled} // Make read-only if there's a tooltip or isLoading
                    className={isDisabled ? "disabled" : ""} // Add 'disabled' class if it should be disabled
                  />
                  <OverlayTrigger
                    trigger={["hover", "focus"]}
                    placement="top"
                    rootClose={true}
                    overlay={
                      <Tooltip
                        id="tooltip-interpret"
                        className={`custom_tooltip ${
                          countWords(userInput) < 3 ? "" : "d-none"
                        }`}
                      >
                        Please enter dream details
                      </Tooltip>
                    }
                  >
                    <button
                      onClick={!isDisabled ? handleInterpretClick : null}
                      className={`interpret-button ${
                        isDisabled || countWords(userInput) < 3
                          ? "disabled"
                          : ""
                      }`}
                      disabled={isDisabled || countWords(userInput) < 3}
                    >
                      Interpret
                    </button>
                  </OverlayTrigger>
                </div>
                {/* Conditional Rendering for Loading Animation and Tooltip showing the response */}
                <div className="response-container">
                  {isLoading ? (
                    // Show loading animation when isLoading is true
                    <div class="center">
                      <div class="wave"></div>
                      <div class="wave"></div>
                      <div class="wave"></div>
                      <div class="wave"></div>
                      <div class="wave"></div>
                      <div class="wave"></div>
                      <div class="wave"></div>
                      <div class="wave"></div>
                      <div class="wave"></div>
                      <div class="wave"></div>
                    </div>
                  ) : (
                    // Show tooltip when isLoading is false
                    <div
                      className={`interpret-response-tooltip ${
                        showTooltip ? "active" : ""
                      }`}
                    >
                      {openAIResponse}
                      <p></p>
                      {/* Only show 'Sign Up' prompt if response is not an error */}
                      {!openAIResponse.startsWith("Error:") && (
                        <p>
                          <b>
                            <Link to="/signup">Sign Up</Link>
                          </b>{" "}
                          today (or log in) to see the full interpretation!
                        </p>
                      )}
                    </div>
                  )}
                </div>
              </span>
            </h1>
          </div>
        </div>
        <div className={`split_item split_item_30`}>
          <div className="card form_style form_card">
            <div className="text-center mb2 letter_spacing">
              <small>Welcome to uDreamed</small>
            </div>
            <Login
              handleShow={handleShow}
              handleShow2={handleShow2}
              setSocialUserData={setSocialUserData}
            />
          </div>
          <div className={`btn_explore_wrap`}>
            <a className="btn_theme btn_explore">
              <i className="fa fa-angle-down" aria-hidden="true"></i>
            </a>
          </div>
        </div>
      </section>
      <ForgotPasswordModal show={show} handleClose={handleClose} />
      <SocialUserRegistrationModal
        show={show2}
        handleClose={handleClose2}
        currentUser={socialUserData}
      />
    </>
  );
}

export default HomeGuestBanner;
