import React, { useState } from "react";
import { Card, Row, Col, Button } from "react-bootstrap";
import { BsCheck, BsX } from "react-icons/bs";
import CustomCard from "../../../ui/CustomCard/CustomCard";
import "./DictionaryCard.css";

//Service
import recordService from "../../../services/Record";

function DictionaryCard(props) {
  const [loading, setLoading] = useState(false);
  const [dictionaryVote, setDictionaryVote] = useState(null);

  const agreePercent = props.word.agree_percent ? props.word.agree_percent : 0;
  const upVotes = props.word.up_vote ? props.word.up_vote.length : 0;
  const downVotes = props.word.down_vote ? props.word.down_vote.length : 0;
  const totalVotes = upVotes + downVotes;
  const isVoted = props.isVoted;

  const handleDictionaryVote = (word, voteType) => {
    const voteData = {
      dictionary_id: word.dictionary_id,
      vote_type: voteType,
    };
    console.log("Called handleDictionaryVote & voteData =", voteData);
    //call dictionary vote async function which will call actual service
    addDictionaryVote(voteData);
  };

  async function addDictionaryVote(data) {
    setLoading(true);
    recordService
      .addDictionaryVote(data)
      .then((response) => {
        if (response.data.status === "success") {
          console.log("Response from Dictionary : ", response.data);
          const dictionary = response.data.data.result.dictionary;
          console.log("Words =", dictionary);
          // console.log("Word title =", dictionary.word);
          setDictionaryVote(dictionary);
          setLoading(false);
          props.getUserProfileData();
          props.getAllRandomDictionaryWords();
        }
        if (response.data.status === "failed") {
          console.log("ERROR:", response.data.error);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log("There is a problem in getting dictionary words...", err);
        setLoading(false);
      });
  }

  return (
    <>
      <CustomCard className={`main_card border-0 shadow1 dictionary_card mb2`}>
        {/* <span className="position-absolute bg_letter">{props.word.word}</span> */}
        <Card.Header>
          <Row>
            <Col xs={12} sm={12} md={6}>
              <Card.Title className="mb2 font-18 text_secondary_dark">
                {props.word.word}
              </Card.Title>
            </Col>
            <Col xs={12} sm={12} md={6}>
              <div className={`votes_wrap font-13 text_secondary`}>
                <div>{`${agreePercent}% agree (${totalVotes} votes)`}</div>
                {!isVoted && (
                  <div className="agree_cont">
                    Do you agree?{" "}
                    <Button
                      className="btn_theme btn_icon btn_auto_width btn_small btn_success btn_circle"
                      onClick={() => {
                        handleDictionaryVote(props.word, "agree");
                      }}
                    >
                      <BsCheck />
                    </Button>{" "}
                    <Button
                      className="btn_theme btn_icon btn_auto_width btn_small btn_danger btn_circle"
                      onClick={() => {
                        handleDictionaryVote(props.word, "disagree");
                      }}
                    >
                      <BsX />
                    </Button>
                  </div>
                )}
              </div>
              <div className=""></div>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <div>
            {props.word.short_description && (
              <Card.Text>
                <strong>Short: </strong>
                {props.word.short_description}
              </Card.Text>
            )}
            {props.word.description && (
              <Card.Text>
                <strong>Long: </strong>
                {props.word.description}
              </Card.Text>
            )}
          </div>
        </Card.Body>
      </CustomCard>
    </>
  );
}

export default DictionaryCard;
