import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Container } from "react-bootstrap";
import PageComponent from "../components/PageComponent/PageComponent";
import { BsXLg } from "react-icons/bs";

function Failure(props) {
  let { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  // console.log("Failure PAGE Props = ", props);

  const goTo = (link) => {
    navigate(link);
  };

  useEffect(() => {
    let link = "";
    
    if (
      location.pathname.includes("consult-success") ||
      location.pathname.includes("consult-failure")
    ) {
      console.log("Redirecting to consulting-sessions...");
      link = "/dashboard/consulting-sessions";
    } else if (
      location.pathname.includes("subscription-success") ||
      location.pathname.includes("subscription-failure")
    ) {
      console.log("Redirecting to settings...");
      link = "/dashboard/settings";
    }
    setTimeout(() => {
      goTo(link);
      console.log("Redirecting to ", link);
    }, 6000);
  }, []);

  return (
    <>
      <PageComponent>
        <Container
          className="failure-page d-flex justify-content-center align-items-center"
          style={{ minHeight: "100vh" }}
        >
          <div
            className="card failure-card overflow-hidden border-0 shadow1"
            style={{ maxWidth: "100%", width: "40%", borderRadius: "15px" }}
          >
            <div className="card-body text-center px-3 py-5">
              <div className="heading-icon-wrap d-flex justify-content-center mt-3 mb-4">
                <span className="heading-icon d-flex justify-content-center align-items-center rounded-circle font-24">
                  <BsXLg />
                </span>
              </div>
              <div className="font-18 fw_sb mb-3">Payment Failed!</div>

              <div className="helper-text">
                There is some problem while processing payment.
              </div>
            </div>
          </div>
        </Container>
      </PageComponent>
    </>
  );
}

export default Failure;
