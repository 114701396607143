import React, { useState } from "react";
import { Card, Row, Col } from "react-bootstrap";
import CustomCard from "../../../ui/CustomCard/CustomCard";
import "./Achievements.css";

function Achievements(props) {
  console.log("In Achievements comp =", props.achievements);

  const ach_record_num = props.achievements?.record_num
    ? props.achievements.record_num
    : 0;
  const ach_record_xp = props.achievements?.record_xp
    ? props.achievements.record_xp
    : 0;
  const ach_share_num = props.achievements?.share_num
    ? props.achievements.share_num
    : 0;
  const ach_share_xp = props.achievements?.share_xp
    ? props.achievements.share_xp
    : 0;
  const ach_friend_num = props.achievements?.friend_num
    ? props.achievements.friend_num
    : 0;
  const ach_friend_xp = props.achievements?.friend_xp
    ? props.achievements.friend_xp
    : 0;
  const ach_group_num = props.achievements?.group_num
    ? props.achievements.group_num
    : 0;
  const ach_group_xp = props.achievements?.group_xp
    ? props.achievements.group_xp
    : 0;
  const ach_analysis_num = props.achievements?.analysis_num
    ? props.achievements.analysis_num
    : 0;
  const ach_analysis_xp = props.achievements?.analysis_xp
    ? props.achievements.analysis_xp
    : 0;
  const ach_symbol_num = props.achievements?.symbol_num
    ? props.achievements.symbol_num
    : 0;
  const ach_symbol_xp = props.achievements?.symbol_xp
    ? props.achievements.symbol_xp
    : 0;
  const ach_association_num = props.achievements?.association_num
    ? props.achievements.association_num
    : 0;
  const ach_association_xp = props.achievements?.association_xp
    ? props.achievements.association_xp
    : 0;
  const ach_explore_num = props.achievements?.explore_num
    ? props.achievements.explore_num
    : 0;
  const ach_explore_xp = props.achievements?.explore_xp
    ? props.achievements.explore_xp
    : 0;
  const ach_favorite_num = props.achievements?.favorite_num
    ? props.achievements.favorite_num
    : 0;
  const ach_favorite_xp = props.achievements?.favorite_xp
    ? props.achievements.favorite_xp
    : 0;
  const ach_resolution_num = props.achievements?.resolution_num
    ? props.achievements.resolution_num
    : 0;
  const ach_resolution_xp = props.achievements?.resolution_xp
    ? props.achievements.resolution_xp
    : 0;
  const ach_consulted_num = props.achievements?.consulted_num
    ? props.achievements.consulted_num
    : 0;
  const ach_consulted_xp = props.achievements?.consulted_xp
    ? props.achievements.consulted_xp
    : 0;
  const ach_forum_num = props.achievements?.forum_num
    ? props.achievements.forum_num
    : 0;
  const ach_forum_xp = props.achievements?.forum_xp
    ? props.achievements.forum_xp
    : 0;

  return (
    <>
      <div className="xp_card_wrap">
        <Row className="row row-cols-3 g-3">
          <Col>
            <CustomCard className={`main_card xp_card shadow1 border-0 h-100`}>
              {/* <img
                src={`${process.env.PUBLIC_URL}/img/earned-badge.png`}
                className="ribbon_type top_left"
                alt="Earned Ribbon"
              /> */}
              <Card.Body
                style={{ minHeight: "140px" }}
                className="text-center d-flex flex-column justify-content-center align-items-center"
              >
                <div className="icon_wrap mb1">
                  <img
                    src={`${process.env.PUBLIC_URL}/img/xp-icons/friends.png`}
                    width="35"
                    className=""
                    alt=""
                  />
                </div>
                <div className="font-22">{`${ach_record_xp} XP`}</div>
                <div className="font-14">{`Recorded ${ach_record_num} ${
                  ach_record_num > 1 ? "Experiences" : "Experience"
                }`}</div>
              </Card.Body>
            </CustomCard>
          </Col>

          <Col>
            <CustomCard className={`main_card xp_card shadow1 border-0 h-100`}>
              {/* <img
                src={`${process.env.PUBLIC_URL}/img/earned-badge.png`}
                className="ribbon_type top_left"
                alt="Earned Ribbon"
              /> */}
              <Card.Body
                style={{ minHeight: "140px" }}
                className="text-center d-flex flex-column justify-content-center align-items-center"
              >
                <div className="icon_wrap mb1">
                  <img
                    src={`${process.env.PUBLIC_URL}/img/xp-icons/friends.png`}
                    width="35"
                    className=""
                    alt=""
                  />
                </div>
                <div className="font-22">{`${ach_share_xp} XP`}</div>
                <div className="font-14">{`Shared ${ach_share_num} ${
                  ach_share_num > 1 ? "Experiences" : "Experience"
                }`}</div>
              </Card.Body>
            </CustomCard>
          </Col>

          <Col>
            <CustomCard className={`main_card xp_card shadow1 border-0 h-100`}>
              {/* <img
                src={`${process.env.PUBLIC_URL}/img/earned-badge.png`}
                className="ribbon_type top_left"
                alt="Earned Ribbon"
              /> */}
              <Card.Body
                style={{ minHeight: "140px" }}
                className="text-center d-flex flex-column justify-content-center align-items-center"
              >
                <div className="icon_wrap mb1">
                  <img
                    src={`${process.env.PUBLIC_URL}/img/xp-icons/friends.png`}
                    width="35"
                    className=""
                    alt=""
                  />
                </div>
                <div className="font-22">{`${ach_friend_xp} XP`}</div>
                <div className="font-14">{`${ach_friend_num} ${
                  ach_friend_num === 1 ? "Friend" : "Friends"
                  // ach_friend_num > 1 ? "Friends" : "Friend"
                } added`}</div>
              </Card.Body>
            </CustomCard>
          </Col>

          <Col>
            <CustomCard className={`main_card xp_card shadow1 border-0 h-100`}>
              {/* <img
                src={`${process.env.PUBLIC_URL}/img/earned-badge.png`}
                className="ribbon_type top_left"
                alt="Earned Ribbon"
              /> */}
              <Card.Body
                style={{ minHeight: "140px" }}
                className="text-center d-flex flex-column justify-content-center align-items-center"
              >
                <div className="icon_wrap mb1">
                  <img
                    src={`${process.env.PUBLIC_URL}/img/xp-icons/groups.png`}
                    width="35"
                    className=""
                    alt=""
                  />
                </div>
                <div className="font-22">{`${ach_group_xp} XP`}</div>
                <div className="font-14">{`${ach_group_num} ${
                  ach_group_num === 1 ? "Group" : "Groups"
                  // ach_group_num > 1 ? "Groups" : "Group"
                } joined`}</div>
              </Card.Body>
            </CustomCard>
          </Col>

          <Col>
            <CustomCard className={`main_card xp_card shadow1 border-0 h-100`}>
              {/* <img
                src={`${process.env.PUBLIC_URL}/img/earned-badge.png`}
                className="ribbon_type top_left"
                alt="Earned Ribbon"
              /> */}
              <Card.Body
                style={{ minHeight: "140px" }}
                className="text-center d-flex flex-column justify-content-center align-items-center"
              >
                <div className="icon_wrap mb1">
                  <img
                    src={`${process.env.PUBLIC_URL}/img/xp-icons/analysis.png`}
                    width="35"
                    className=""
                    alt=""
                  />
                </div>
                <div className="font-22">{`${ach_analysis_xp} XP`}</div>
                <div className="font-14">{`${ach_analysis_num} Analysis`}</div>
              </Card.Body>
            </CustomCard>
          </Col>

          <Col>
            <CustomCard className={`main_card xp_card shadow1 border-0 h-100`}>
              {/* <img
                src={`${process.env.PUBLIC_URL}/img/earned-badge.png`}
                className="ribbon_type top_left"
                alt="Earned Ribbon"
              /> */}
              <Card.Body
                style={{ minHeight: "140px" }}
                className="text-center d-flex flex-column justify-content-center align-items-center"
              >
                <div className="icon_wrap mb1">
                  <img
                    src={`${process.env.PUBLIC_URL}/img/xp-icons/symbols.png`}
                    width="35"
                    className=""
                    alt=""
                  />
                </div>
                <div className="font-22">{`${ach_symbol_xp} XP`}</div>
                <div className="font-14">{`${ach_symbol_num} ${
                  ach_symbol_num === 1
                    ? `Time voted on dictionary meaning`
                    : "Times voted on dictionary meaning"
                  // ach_symbol_num > 1
                  //   ? "times voted on dictionary meaning"
                  //   : `${
                  //       ach_symbol_num == 0
                  //         ? `times voted on dictionary meaning`
                  //         : `time voted on dictionary meaning`
                  //     }`
                }`}</div>
              </Card.Body>
            </CustomCard>
          </Col>

          <Col>
            <CustomCard className={`main_card xp_card shadow1 border-0 h-100`}>
              {/* <img
                src={`${process.env.PUBLIC_URL}/img/earned-badge.png`}
                className="ribbon_type top_left"
                alt="Earned Ribbon"
              /> */}
              <Card.Body
                style={{ minHeight: "140px" }}
                className="text-center d-flex flex-column justify-content-center align-items-center"
              >
                <div className="icon_wrap mb1">
                  <img
                    src={`${process.env.PUBLIC_URL}/img/xp-icons/associations.png`}
                    width="35"
                    className=""
                    alt=""
                  />
                </div>
                <div className="font-22">{`${ach_association_xp} XP`}</div>
                <div className="font-14">{`${ach_association_num} ${
                  ach_association_num === 1 ? "Association" : "Associations"
                }`}</div>
              </Card.Body>
            </CustomCard>
          </Col>

          <Col>
            <CustomCard className={`main_card xp_card shadow1 border-0 h-100`}>
              {/* <img
                src={`${process.env.PUBLIC_URL}/img/earned-badge.png`}
                className="ribbon_type top_left"
                alt="Earned Ribbon"
              /> */}
              <Card.Body
                style={{ minHeight: "140px" }}
                className="text-center d-flex flex-column justify-content-center align-items-center"
              >
                <div className="icon_wrap mb1">
                  <img
                    src={`${process.env.PUBLIC_URL}/img/xp-icons/match.png`}
                    width="35"
                    className=""
                    alt=""
                  />
                </div>
                <div className="font-22">{`${ach_explore_xp} XP`}</div>
                <div className="font-14">{`${ach_explore_num} ${
                  ach_explore_num === 1 ? "Match" : "Matches"
                }`}</div>
              </Card.Body>
            </CustomCard>
          </Col>

          <Col>
            <CustomCard className={`main_card xp_card shadow1 border-0 h-100`}>
              {/* <img
                src={`${process.env.PUBLIC_URL}/img/earned-badge.png`}
                className="ribbon_type top_left"
                alt="Earned Ribbon"
              /> */}
              <Card.Body
                style={{ minHeight: "140px" }}
                className="text-center d-flex flex-column justify-content-center align-items-center"
              >
                <div className="icon_wrap mb1">
                  <img
                    src={`${process.env.PUBLIC_URL}/img/xp-icons/favourite.png`}
                    width="35"
                    className=""
                    alt=""
                  />
                </div>
                <div className="font-22">{`${ach_favorite_xp} XP`}</div>
                <div className="font-14">{`${ach_favorite_num} Favorited ${
                  ach_favorite_num === 1 ? "item" : "items"
                }`}</div>
              </Card.Body>
            </CustomCard>
          </Col>

          <Col>
            <CustomCard className={`main_card xp_card shadow1 border-0 h-100`}>
              {/* <img
                src={`${process.env.PUBLIC_URL}/img/earned-badge.png`}
                className="ribbon_type top_left"
                alt="Earned Ribbon"
              /> */}
              <Card.Body
                style={{ minHeight: "140px" }}
                className="text-center d-flex flex-column justify-content-center align-items-center"
              >
                <div className="icon_wrap mb1">
                  <img
                    src={`${process.env.PUBLIC_URL}/img/xp-icons/resolutions.png`}
                    width="35"
                    className=""
                    alt=""
                  />
                </div>
                <div className="font-22">{`${ach_resolution_xp} XP`}</div>
                <div className="font-14">{`${ach_resolution_num} ${
                  ach_resolution_num === 1 ? "Resolution" : "Resolutions"
                }`}</div>
              </Card.Body>
            </CustomCard>
          </Col>

          <Col>
            <CustomCard className={`main_card xp_card shadow1 border-0 h-100`}>
              {/* <img
                src={`${process.env.PUBLIC_URL}/img/earned-badge.png`}
                className="ribbon_type top_left"
                alt="Earned Ribbon"
              /> */}
              <Card.Body
                style={{ minHeight: "140px" }}
                className="text-center d-flex flex-column justify-content-center align-items-center"
              >
                <div className="icon_wrap mb1">
                  <img
                    src={`${process.env.PUBLIC_URL}/img/xp-icons/consult.png`}
                    width="35"
                    className=""
                    alt="consult"
                  />
                </div>
                <div className="font-22">{`${ach_consulted_xp} XP`}</div>
                <div className="font-14">
                  {`${ach_consulted_num} 
                  ${
                    ach_consulted_num === 1
                      ? "Consulting session"
                      : "Consulting sessions"
                  }`}
                </div>
              </Card.Body>
            </CustomCard>
          </Col>

          <Col>
            <CustomCard className={`main_card xp_card shadow1 border-0 h-100`}>
              {/* <img
                src={`${process.env.PUBLIC_URL}/img/earned-badge.png`}
                className="ribbon_type top_left"
                alt="Earned Ribbon"
              /> */}
              <Card.Body
                style={{ minHeight: "140px" }}
                className="text-center d-flex flex-column justify-content-center align-items-center"
              >
                <div className="icon_wrap mb1">
                  <img
                    src={`${process.env.PUBLIC_URL}/img/xp-icons/forum-post.png`}
                    width="35"
                    className=""
                    alt="forum-post"
                  />
                </div>
                <div className="font-22">{`${ach_forum_xp} XP`}</div>
                <div className="font-14">{`${ach_forum_num} ${
                  ach_forum_num === 1 ? "Post on forum" : "Posts on forum"
                }`}</div>
              </Card.Body>
            </CustomCard>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Achievements;
