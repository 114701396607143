import React from "react";
import { Modal, Button, Spinner } from "react-bootstrap";
import YouTube from "react-youtube";
import "./VideoModal.css";
import { BsExclamationCircle } from "react-icons/bs";
import { MdClose } from "react-icons/md";

function VideoModal(props) {
    
    const video_id = props.video?.video_id ? props.video.video_id : null;
    const video_title = props.video?.title ? props.video.title : 'No title'; 
    const opts = {
        height: "360",
        width: "640",
        playerVars: {
          // https://developers.google.com/youtube/player_parameters
          autoplay: 0,
        },
      };

  return (
    <>
      <Modal
        centered
        size="lg"
        className="theme-modal video-modal"
        show={props.show.isVisible}
        onHide={props.handleClose}
      >
        
        <Modal.Body className="position-relative p-0">
          <Button type="button" style={{zIndex: 1}} onClick={props.handleClose} className="btn_theme btn_circle btn_icon btn_primary position-absolute top-0 end-0"><MdClose /></Button>   
          <YouTube
            videoId={video_id}
            className="video_wrapper ratio ratio-16x9"
            opts={opts}
            onReady={(event) => {
              console.log('We are ready =',props.video)
              event.target.pauseVideo();
            }}
          />
        
        </Modal.Body>
      </Modal>
    </>
  );
}

export default VideoModal;
