import React, { useState, useEffect } from "react";
import { Card, Placeholder, Table } from "react-bootstrap";
import CustomCard from "../../../ui/CustomCard/CustomCard";
import DashboardSectionTitle from "../DashboardSectionTitle/DashboardSectionTitle";
import { BsFillExclamationTriangleFill } from "react-icons/bs";
// import "./CommentCard.css";

function ConsultationTransactionList(props) {
  // const [loading, setLoading] = useState(true);
  // const [sessionTransactions, setSessionTransactions] = useState(
  //   props.session.transactions
  // );
  console.log("session trasaction =", props.sessionTransactions);

  // useEffect(() => {
  //   // if (props.session.transactions?.length > 0) {
  //   setSessionTransactions(props.session.transactions);
  //   // }
  // }, [props.session]);

  return (
    <>
      {/* <div class="pt1 pb1">
				<hr />
			</div> */}
      {/* <DashboardSectionTitle
        iconImg=""
        title="All comments"
        pageTitle={false}
        className="mb1"
      /> */}

      {props.sessionTransactions.length > 0 ? (
        <>
          <Table bordered hover size="sm" className="text-center">
            <thead className="bg_lightgray">
              <tr>
                <th>#</th>
                <th>Date</th>
                <th>Amount ($)</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {props.sessionTransactions.map((item, index) => (
                // <p>{item.payment_amount}</p>
                <tr key={item._id}>
                  <td>{index + 1}</td>
                  <td>{item.created_date}</td>
                  <td>{item.payment_amount}</td>
                  <td>{item.payment_status}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      ) : (
        <CustomCard className="shadow1 border-0 text-center">
          <Card.Body>
            <div className="mb1">
              <BsFillExclamationTriangleFill
                style={{
                  color: "#d3af61",
                  fontSize: "2.2rem",
                }}
              />
            </div>
            <b>No more session transactions found...</b>
          </Card.Body>
        </CustomCard>
      )}

      {/* {!props.loading && props.sessionComments?.length > 0 ? (
        props.sessionComments?.map((item, index) => (
          <CustomCard key={item.comment_id} className="main_card comment_card">
            <Card.Header className="bg_transparent border-0">
              <div className="media">
                <div className="media_icon_wrap align-self-start">
                  <img
                    src={item.user?.avatar}
                    className="rounded-circle media_img"
                    alt={`${item.user?.fullname} profile`}
                  />
                </div>
                <div className="media_body ms-3">
                  <div class="card-title">{item.user?.fullname} commented</div>
                  <div class="card-subtitle">{item.time_lapsed}</div>
                  <p className="mb0">{item.comment}</p>
                </div>
              </div>
            </Card.Header>
            
          </CustomCard>
        ))
      ) : (
        <>
          {props.loading ? (
            <>
              {[0, 1].map((_, i) => (
                <CustomCard
                  key={i}
                  className={`main_card shadow1 border-0 mb2 text-left overflow-hidden`}
                >
                  <Card.Header>
                    <div className="d-flex align-items-center">
                      <div className="flex-shrink-0">
                        <Placeholder as="div" animation="glow">
                          <Placeholder
                            bg="secondary"
                            as="div"
                            style={{ height: "54px", width: "54px" }}
                            className="rounded-circle"
                          />
                        </Placeholder>
                      </div>
                      <div className="flex-grow-1 ms-3 text-left">
                        <div className="w-100">
                          <Placeholder xs={6} bg="secondary" />
                        </div>
                        <div className="w-100">
                          <Placeholder xs={4} bg="secondary" />
                        </div>
                      </div>
                    </div>
                  </Card.Header>
                  <Card.Body className="p2">
                    <div className="w-100 mb-1">
                      <Placeholder xs={9} bg="secondary" />
                    </div>
                    <div className="w-100 mb1">
                      <Placeholder xs={6} bg="secondary" />
                    </div>
                  </Card.Body>
                </CustomCard>
              ))}
            </>
          ) : (
            <CustomCard className="shadow1 border-0 text-center">
              <Card.Body>
                <div className="mb1">
                  <BsFillExclamationTriangleFill
                    style={{
                      color: "#d3af61",
                      fontSize: "2.2rem",
                    }}
                  />
                </div>
                <b>No comments found...</b>
              </Card.Body>
            </CustomCard>
          )}
        </>
      )} */}
    </>
  );
}

export default ConsultationTransactionList;
