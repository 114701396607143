import React, { useState, useEffect, useContext } from "react";
import { Card, Form, Button, Spinner } from "react-bootstrap";
import CustomCard from "../../../ui/CustomCard/CustomCard";
import DashboardSectionTitle from "../DashboardSectionTitle/DashboardSectionTitle";
import { Field, Formik } from "formik";
import * as yup from "yup";
import Select from "react-select";
import { handleAlert, handleCustomSelectStyles } from "../../../util/util";

//Service
import recordService from "../../../services/Record";
import FieldError from "../../../ui/FieldError/FieldError";
import ThemeContext from "../../../ThemeContext";

function ComposeMessage(props) {
  const { theme } = useContext(ThemeContext);
  const [allUsers, setAllUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  console.log("initialValues =", props);

  //React select styles
  const customStyles = handleCustomSelectStyles(theme);

  // const customStyles = {
  //   control: (provided, state) => ({
  //     ...provided,
  //     border: theme === "dark" ? "1px solid #666" : "1px solid #ddd",
  //     borderRadius: "30px",
  //     minHeight: "48px",
  //     backgroundColor: theme === "dark" ? "#333" : "#fff",
  //     color: "inherit",
  //     "&:focus": {
  //       borderWidth: "1px",
  //       borderColor: "#39b3ed",
  //       boxShadow: "none",
  //     },
  //     "&:hover": {
  //       borderColor: theme === "dark" ? "#666" : "#ddd",
  //     },
  //   }),
  //   singleValue: (provided) => ({
  //     ...provided,
  //     color: "inherit",
  //   }),
  //   placeholder: (provided) => ({
  //     ...provided,
  //     color: "#ffffff",
  //   }),
  //   menuList: (provided) => ({
  //     ...provided,
  //     backgroundColor: theme === "dark" ? "#444" : "#fff",
  //     padding: 0,
  //   }),
  //   option: (provided, { isFocused, isSelected }) => ({
  //     ...provided,
  //     background: isFocused ? "#999" : isSelected ? "#0991f6" : undefined,
  //   }),
  //   valueContainer: (provided, state) => ({
  //     ...provided,
  //     padding: "2px 12px",
  //     color: "inherit",
  //     zIndex: 1,
  //   }),
  // };

  const userOptions = props.friends.map((friend) => {
    return { label: friend.name, value: friend.id };
  });
  console.log("Users : ", userOptions);

  // useEffect(() => {
  //   console.log("Friends from compose message useeffect =", props.friends);
  //   setAllUsers(
  //     props.friends.map((friend) => [{ label: friend.name, value: friend.id }])
  //   );
  //   setTimeout(() => {
  //     // console.log("New updated friends =", newList);
  //     console.log("users in compose =", allUsers);
  //   }, 2000);
  // }, []);

  // const userOptions = [
  //   { label: "Select recipient", value: "" },
  //   { label: "Recipient 1", value: "recipient1" },
  //   { label: "Recipient 2", value: "recipient2" },
  //   { label: "Recipient 3", value: "recipient3" },
  //   { label: "Recipient 4", value: "recipient4" },
  // ];

  const initialValues = {
    users: [],
    message: "",
  };

  useEffect(() => {
    if (props.action == "message_compose") {
      if (props.initialValues["message"]) {
        initialValues["message"] = props.initialValues["message"];
      }
      if (props.initialValues["users"] && props.initialValues["users"].length) {
        initialValues["users"] = props.initialValues["users"];
      }
    }
  }, []);

  const createMessageSchema = yup.object({
    users: yup
      .array()
      .min(1, "Atleast 1 user required")
      .of(
        yup.object().shape({
          label: yup.string().required(),
          value: yup.string().required(),
        })
      ),
    message: yup.string().required("Required"),
  });

  const onSubmit = async (values, { resetForm }) => {
    console.log("Compose Message Form Values :", values);
    setLoading(true);
    const newUsers = values.users.map((user) => Number(user.value));
    // console.log("New Memebrs=", newUsers, " Type = ", typeof newUsers);

    const newValues = {
      ...values,
      users: newUsers,
    };
    // console.log("New values in Compose =", newValues);

    recordService
      .composeMessage(newValues)
      .then((response) => {
        if (response.data.status === "success") {
          // console.log(response.data);
          handleAlert("Success! Your message sent.", "success");

          setLoading(false);
          resetForm({});

          //Close Message Modal if exist
          if (props.handleClose) {
            props.handleClose();
          }
        } else if (response.data.status === "failed") {
          // console.log("ERROR:", response.data.error);
          handleAlert("Error! Failed to send message.", "error");
          setLoading(false);
          resetForm({});
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <CustomCard className={`main_card shadow1 border-0 mt2 mb2`}>
        <Card.Body>
          {/* <DashboardSectionTitle
            iconImg=""
            title="Compose Message"
            pageTitle={false}
            className="mb2"
          /> */}
          <div className="form_style pt1 pb1">
            <Formik
              initialValues={initialValues}
              validationSchema={createMessageSchema}
              onSubmit={onSubmit}
              enableReinitialize
            >
              {(formik) => {
                return (
                  <Form onSubmit={formik.handleSubmit}>
                    <DashboardSectionTitle
                      iconImg=""
                      title="Add Recipient(s)"
                      pageTitle={false}
                      className="mb1 small_title"
                    />
                    <Form.Group className="form-group rounded_corners">
                      {/* <Form.Label>Add Recipient(s)</Form.Label> */}
                      <Select
                        styles={customStyles}
                        placeholder="Select Recipient"
                        value={formik.values.users}
                        onChange={(value) => {
                          formik.setFieldValue("users", value);
                        }}
                        onBlur={() => {
                          formik.setFieldTouched("users", true);
                        }}
                        isMulti={true}
                        options={userOptions}
                        name="users"
                      />
                      <FieldError
                        valid={
                          formik.touched.users && formik.errors.users
                            ? true
                            : false
                        }
                        text={formik.errors.users}
                      />
                      {/* {formik.touched.users && formik.errors.users ? (
                        <p className="error-state-text">
                          {formik.errors.users}
                        </p>
                      ) : null} */}
                    </Form.Group>

                    <DashboardSectionTitle
                      iconImg=""
                      title="Message"
                      pageTitle={false}
                      className="mb1 small_title"
                    />
                    <Form.Group className="form-group rounded_corners">
                      {/* <Form.Label>Message</Form.Label> */}
                      <Form.Control
                        as="textarea"
                        placeholder="Message"
                        style={{ height: "140px" }}
                        name="message"
                        {...formik.getFieldProps("message")}
                      />
                      <FieldError
                        valid={
                          formik.touched.message && formik.errors.message
                            ? true
                            : false
                        }
                        text={formik.errors.message}
                      />
                      {/* {formik.touched.message && formik.errors.message ? (
                        <p className="error-state-text">
                          {formik.errors.message}
                        </p>
                      ) : null} */}
                    </Form.Group>
                    <div className="btn_wrap pt1">
                      <Button
                        type="submit"
                        className="btn btn_theme btn_primary btn_rounded"
                      >
                        {loading ? (
                          <>
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />{" "}
                            Sending
                          </>
                        ) : (
                          "Send Message"
                        )}
                      </Button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </Card.Body>
      </CustomCard>
    </>
  );
}

export default ComposeMessage;
