import Button from "@restart/ui/esm/Button";
import React, { useState, useContext } from "react";
import { Card, Spinner } from "react-bootstrap";
import CustomCard from "../../../ui/CustomCard/CustomCard";
import CustomCollapseButton from "../../../ui/CustomCollapse/CustomCollapseButton";
import CustomCollapseContainer from "../../../ui/CustomCollapse/CustomCollapseContainer";
import GroupDetails from "./GroupDetails";
import ConfirmRemoveGroupMemberModal from "../../../ui/ConfirmRemoveGroupMemberModal/ConfirmRemoveGroupMemberModal";
import { BsFillStarFill, BsFillPeopleFill } from "react-icons/bs";
import "./SearchedGroupCard.css";
import { handleAlert } from "../../../util/util";

//Context
import AppContext from "../../../AppContext";

//Service
import recordService from "../../../services/Record";
import ConfirmLeaveGroupModal from "../../../ui/ConfirmLeaveGroupModal/ConfirmLeaveGroupModal";
import ConfirmationModal from "../../../ui/ConfirmationModal/ConfirmationModal";

function SearchedGroupCard(props) {
  const { setProfileStats } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [cardTop, setCardTop] = useState(0);
  const [groupDetails, setGroupDetails] = useState({});
  const [removeMemberData, setRemoveMemberData] = useState({});

  //Delete Member Confirmation Modal State
  const [show, setShow] = useState({
    isVisible: false,
  });

  //leave group Confirmation Modal State
  const [show2, setShow2] = useState({
    isVisible: false,
  });

  const handleClose = () => {
    // console.log("Closing the modal");
    setShow({
      isVisible: false,
    });
  };
  const handleShow = (e) => {
    console.log("data-id=", e.target.getAttribute("data-id"));
    setRemoveMemberData({
      user_id: e.target.getAttribute("data-id"),
      group_id: props.group.user_group_id,
    });

    setShow({
      isVisible: true,
    });
    // console.log("Opening the modal");
  };

  const handleClose2 = () => {
    // console.log("Closing the modal");
    setShow2({
      isVisible: false,
    });
  };

  const handleShow2 = (e) => {
    setShow2({
      isVisible: true,
    });
    console.log("Opening the modal inside Serach group");
  };

  //Get Profile Stats
  function getUserProfileData() {
    recordService
      .getUserProfileData()
      .then((response) => {
        if (response.data.status === "success") {
          // console.log("Response from APP : ", response.data);
          const stats = response.data.data.result.details;
          setProfileStats(stats);
        }
        if (response.data.status === "failed") {
          console.log("ERROR:", response.data.error);
        }
      })
      .catch((err) => {
        console.log("There is a problem in fetching Profile Stats...", err);
      });
  }

  const handleRemoveGroupUser = (data) => {
    // const data = {
    //   group_id: group_id,
    //   user_id: user_id,
    // };
    setLoading(true);
    console.log("data in handleRemoveGroupUser =", data);
    recordService
      .removeGroupUser(data)
      .then((response) => {
        if (response.data.status === "success") {
          console.log("remove Group member response =", response.data);
          handleAlert("You have successfully deleted a member!", "success");
          handleCollapse("group_details");
          handleClose();
          setLoading(false);
          props.handleChange(props.inputValue);
          //Get all groups
          props.getAllGroups();
        } else if (response.data.status === "failed") {
          console.log("ERROR:", response.data.error);
          handleAlert("Delete group member failed!", "error");
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleLeaveGroup = (group) => {
    setLoading(true);
    console.log("SearchedGroupCard - data in handleLeaveGroup =", group);
    recordService
      .leaveGroup(group.user_group_id)
      .then((response) => {
        if (response.data.status === "success") {
          console.log("leave Group response =", response.data);
          handleAlert(`You have left ${group.title}`, "success");
          // handleCollapse("group_details");
          handleClose2();
          setLoading(false);
          console.log("in handleLeaveGroup inputValue =", props.inputValue);
          props.handleChange(props.inputValue);
          //Get all groups
          props.getAllGroups();
        } else if (response.data.status === "failed") {
          console.log("ERROR:", response.data.error);
          handleAlert(`Unable to leave ${group.title}`, "error");
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleJoinGroup = (group) => {
    setLoading(true);
    console.log("data in handleJoinGroup =", group);
    const group_id = group.user_group_id;
    const group_name = group.title;

    recordService
      .joinGroup(group_id)
      .then((response) => {
        if (response.data.status === "success") {
          console.log("join Group response =", response.data);
          if (group.privacy === "approval") {
            // handleAlert(
            //   "Your group join request in sent to group owner successfully! You will get an access once owner accepts your request",
            //   "success"
            // );
            handleAlert("Group request sent!", "success");
          } else {
            // handleAlert("You have successfully join a group!", "success");
            handleAlert(
              `You have joined the ${group_name} ${
                group_name.toLowerCase().includes("group") ? "" : "group"
              }`,
              "success"
            );
          }
          // handleCollapse("group_details");
          // handleClose2();
          setLoading(false);
          console.log("in handleJoinGroup inputValue =", props.inputValue);
          props.handleChange(props.inputValue);
          //Get all groups
          props.getAllGroups();
          //Get Profile Stats
          getUserProfileData();
        } else if (response.data.status === "failed") {
          console.log("ERROR:", response.data.error);
          handleAlert(`Unable to Join group!`, "error");
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  console.log("Serach input value in SearchedGroupCard = ", props.inputValue);

  const [openCollapse, setOpenCollapse] = useState({
    view_group_details_collapse: false,
    join_group_collapse: false,
  });

  let tempState = null;
  const handleCollapse = (element) => {
    switch (element) {
      case "group_details":
        tempState = Object.fromEntries(
          Object.keys({ ...openCollapse }).map((key) => [key, false])
        );
        setOpenCollapse({
          ...tempState,
          view_group_details_collapse:
            !openCollapse.view_group_details_collapse,
        });
        break;
      case "join_group":
        tempState = Object.fromEntries(
          Object.keys({ ...openCollapse }).map((key) => [key, false])
        );
        setOpenCollapse({
          ...tempState,
          join_group_collapse: !openCollapse.join_group_collapse,
        });
        break;
    }
  };

  const handleGroupDetails = (id) => {
    setLoading(true);
    recordService
      .getGroup(id)
      .then((response) => {
        if (response.data.status === "success") {
          console.log("handleGroupDetails() Group card: ", response.data);
          const group_details = response.data.data.result;
          setGroupDetails(group_details);
          setLoading(false);
        } else if (response.data.status === "failed") {
          console.log("ERROR:", response.data.error);
          setLoading(false);
          setGroupDetails({});
        }
      })
      .catch((err) => {
        console.log("There is a problem in fetching group details...", err);
        setLoading(false);
        setGroupDetails({});
      });
  };

  return (
    <>
      <CustomCard className={`searched_group_card main_card shadow1 border-0`}>
        <Card.Header className="border-0">
          <div className="media">
            <div className="media_icon_wrap d-flex align-items-center justify-content-center">
              <BsFillPeopleFill />
            </div>
            <div className={`media_body ms-3`}>
              <Card.Title className="card_title">
                {props.group.title}
                {/* | {props.group.user_group_id} */}
              </Card.Title>
              <Card.Subtitle className="card_subtitle">
                Members {props.group.user_count}
              </Card.Subtitle>
            </div>
          </div>
        </Card.Header>
        <Card.Body>
          <p>{props.group.description}</p>
          <div className="btn_wrap">
            {props.group.actions?.map((action, index) => {
              switch (action) {
                case "group_details":
                  return (
                    <CustomCollapseButton
                      key={index}
                      handleCollapse={handleCollapse}
                      btnTitle="Details"
                      className="btn_small btn_light btn_rounded"
                      ariaControls="group_details"
                      ariaExpanded={openCollapse.view_group_details_collapse}
                      setCardTop={setCardTop}
                    />
                  );
                case "join_group":
                  if (
                    props.group.owner_id !== props.authUser.user_id &&
                    !props.group.user_ids.includes(props.authUser.user_id) &&
                    // !props.group.requests.includes(props.authUser.user_id) &&
                    props.group.privacy !== "invite"
                  ) {
                    return (
                      <Button
                        type="button"
                        key={index}
                        className="btn btn_theme btn_small btn_gray btn_rounded"
                        onClick={() => {
                          handleJoinGroup(props.group);
                        }}
                        disabled={props.group.requests.includes(
                          props.authUser.user_id
                        )}
                      >
                        {loading ? (
                          <>
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />{" "}
                            Processing
                          </>
                        ) : (
                          <>
                            {props.group.requests.includes(
                              props.authUser.user_id
                            )
                              ? "Requested"
                              : "Join"}
                          </>
                        )}
                      </Button>
                      // <CustomCollapseButton
                      //   key={index}
                      //   handleCollapse={handleCollapse}
                      //   btnTitle="Join"
                      //   className="btn_small btn_gray btn_rounded"
                      //   ariaControls="join_group"
                      //   ariaExpanded={openCollapse.join_group_collapse}
                      //   setCardTop={setCardTop}
                      // />
                    );
                  } else {
                    return;
                  }
              }
            })}
          </div>
          {props.group.actions?.map((action, index) => {
            switch (action) {
              case "join_group":
                return;
              // return (
              //   <CustomCollapseContainer
              //     key={index}
              //     ariaControls="join_group"
              //     openCollapse={openCollapse.join_group_collapse}
              //     cardTop={cardTop}
              //   >
              //     <hr />
              //     <div className="pt2 pb2">Join group content</div>
              //   </CustomCollapseContainer>
              // );
              case "group_details":
                return (
                  <CustomCollapseContainer
                    key={index}
                    ariaControls="group_details"
                    openCollapse={openCollapse.view_group_details_collapse}
                    group_id={props.group.user_group_id}
                    handleGroupDetails={handleGroupDetails}
                    cardTop={cardTop}
                  >
                    <hr />
                    <GroupDetails
                      groupDetails={groupDetails}
                      inputValue={props.inputValue}
                      handleShow={handleShow}
                      handleShow2={handleShow2}
                      handleLeaveGroup={handleLeaveGroup}
                    />
                  </CustomCollapseContainer>
                );
            }
          })}
        </Card.Body>
      </CustomCard>

      {/***** 
       ** Remove group member confirmation modal **
       ******/}
      <ConfirmationModal
        show={show}
        loading={loading}
        handleClose={handleClose}
        primaryActionData={removeMemberData}
        content_type="remove-member-modal"
        handlePrimaryAction={handleRemoveGroupUser}
      />      

      {/* <ConfirmRemoveGroupMemberModal
        show={show}
        group_id={props.group.user_group_id}
        user_id={""}
        removeMemberData={removeMemberData}
        loading={loading}
        handleClose={handleClose}
        handleRemoveGroupUser={handleRemoveGroupUser}
      /> */}

      {/***** 
       ** Leave group confirmation modal **
       ******/}
      <ConfirmationModal
        show={show2}
        loading={loading}
        handleClose={handleClose2}
        primaryActionData={props.group}
        content_type="leave-group-modal"
        handlePrimaryAction={handleLeaveGroup}
      />

      {/* <ConfirmLeaveGroupModal
        show={show2}
        group={props.group}
        loading={loading}
        handleClose={handleClose2}
        handleLeaveGroup={handleLeaveGroup}
      /> */}
    </>
  );
}

export default SearchedGroupCard;
