import React, { useState, useContext, useCallback } from "react";
import { Form, Button, Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import authService from "../../services/Auth";
import { handleAlert, handleThemeChange } from "../../util/util";
import { BsApple } from "react-icons/bs";
import {FaFacebookF} from "react-icons/fa";
import { LoginSocialFacebook, LoginSocialGoogle, LoginSocialApple } from "reactjs-social-login";

//Context
import AppContext from "../../AppContext";
import ThemeContext from "../../ThemeContext";

//Service
import recordService from "../../services/Record";
import FieldError from "../../ui/FieldError/FieldError";

// const REDIRECT_URI =
//   'https://plenty-planets-beam-42-118-51-2.loca.lt/account/login';
// const REDIRECT_URI = 'http://localhost:3000/';

const formschema = yup.object().shape({
  email: yup.string().required("Please enter a valid email"),
  password: yup.string().required("Please enter a valid password"),
});

function Login(props) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const {
    getData,
    getAllFriends,
    getAllNotifications,
    getAllInvitations,
    getAllGroupInvitations,
    getAllGroupRequests,
    getAllConsultInvitations,
    getUserProfileData
  } = useContext(AppContext);
  const { theme, setTheme, } = useContext(ThemeContext);

  const onLoginStart = useCallback(() => {
    // alert("login start");
    console.log("login start");
    // alert("login start");
  }, []);

  const onLogoutSuccess = useCallback(() => {
    // setProfile(null);
    // setProvider("");
    console.log("logout success");
    // alert("logout success");
  }, []);

  const onLogout = useCallback(() => {}, []);

  //After getting response from FB use this function to get accesss in uD app
  async function loginWithSocialMedia(loginData) {
    console.log("Inside loginWithSocialMedia(): loginData =", loginData);

    authService
      .loginWithSocialMedia(loginData)
      .then((response) => {
        console.log("Social media LOGIN Response: loginWithSocialMedia() ", response);
        // console.log("Response status: ", response.data.status);
        if (response.data.status === "success") {
          console.log("Social media Login Response in success =", response.data);

          //If Social media user/email is Registered with application...
          if (!response.data.data.result.show_register) {
            console.log("Social media user/email is Registered with application...");
            console.log(
              "show_register = ",
              response.data.data.result.show_register
            );
            // handleAlert("You have successfully logged in!", "success");
            // props.handleShow2();
            setLoading(false);
            //Setting token and user data in Local storage
            authService.setToken(response.data.data.result);
            //Get all recorded experiences
            getData();
            //Get all friends
            getAllFriends();
            //Get all notifications
            getAllNotifications();
            //Get all invitations
            getAllInvitations();
            //Get all Group invitations
            getAllGroupInvitations();
            //Get all Group Requests
            getAllGroupRequests();
            //Get All Consult Invitations
            getAllConsultInvitations();
            //Get Profile Stats
            getUserProfileData();

            //Redirect to Dashboard
            // props.history.push("/dashboard");
            navigate("/dashboard");
          }

          //If Social media user/email is NOT Registered with application...
          if (response.data.data.result.show_register) {
            console.log("Social media user/email is NOT Registered with application...");
            console.log(
              "show_register = ",
              response.data.data.result.show_register
            );
            //Setting a local state to get the values in register form
            props.setSocialUserData(response.data.data.result);
            //Setting token and user data in Local storage
            authService.setToken(response.data.data.result);
            //Calling the modal having register form
            props.handleShow2();
            console.log("I am going to execute");
          }

          // resetForm();
        }
        if (response.data.status === "failed") {
          console.log("In Failed ERROR : ", response.data.error);
          handleAlert(response.data.error, "error");
          setLoading(false);
          // resetForm();
        }
      })
      .catch((err) => {
        console.log("Executed: ", err);
      });
  }

  async function onSubmit(values, { resetForm }) {
    setLoading(true);
    console.log("Login button clicked");
    console.log("Form values=", values);
    console.log("---- Theme = ",theme);
    authService
      .login(values)
      .then((response) => {
        console.log("LOGIN Response: ", response);
        // console.log("Response status: ", response.data.status);
        if (response.data.status === "success") {
          console.log("Response in success =", response.data);
          handleAlert("You have successfully logged in!", "success");
          setLoading(false);
          authService.setToken(response.data.data.result);
          // Get all recorded experiences
          getData();
          // Get all friends
          getAllFriends();
          // Get all notifications
          getAllNotifications();
          // Get all invitations
          getAllInvitations();
          // Get all Group invitations
          getAllGroupInvitations();
          // Get all Group Requests
          getAllGroupRequests();
          // Get All Consult Invitations
          getAllConsultInvitations();
          //Get Profile Stats
          getUserProfileData();

          //Redirect to Dashboard
          navigate("/dashboard");
          resetForm();
        }
        if (response.data.status === "failed") {
          console.log("In Failed ERROR : ", response.data.error);
          handleAlert(response.data.error, "error");
          setLoading(false);
          resetForm();
        }
      })
      .catch((err) => {
        console.log("Executed: ", err);
      });
  }
  return (
    <>
      <Formik
        initialValues={{ email: "", password: "" }}
        validationSchema={formschema}
        onSubmit={onSubmit}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Group className="form-group rounded_corners">
              <Form.Control
                name="email"
                value={values.email}
                onBlur={handleBlur}
                onChange={handleChange}
                isValid={touched.email && !errors.email}
                required
                type="text"
                placeholder="Email"
              />
              <Form.Label className="label_float">email</Form.Label>
              <FieldError
                valid={touched.email === true && errors.email ? true : false}
                text={errors.email}
              />
             
            </Form.Group>
            <Form.Group className="form-group rounded_corners">
              <Form.Control
                name="password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                isValid={touched.password && !errors.password}
                required
                type="password"
                placeholder="Password"
              />
              <Form.Label className="label_float">Password</Form.Label>
              <FieldError
                valid={
                  touched.password === true && errors.password ? true : false
                }
                text={errors.password}
              />
              
            </Form.Group>
            <Button
              type="submit"
              className="btn_theme btn_theme_gradient btn_rounded btn_block"
            >
              {loading ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />{" "}
                  Loading
                </>
              ) : (
                "Login"
              )}
            </Button>
          </Form>
        )}
      </Formik>

      <div className="text-center pt1">
        <Button
          type="button"
          className="btn_theme btn_link btn_small btn_link_gray"
          onClick={() => {
            props.handleShow();
          }}
        >
          Forgot password?
        </Button>
      </div>
      <div className="text-center btn_wrap">
        <div className="mb-2 text_gray6">Or sign in with:</div>

        <div className="d-flex justify-content-center align-items-center">
        <LoginSocialFacebook
            className="me-2"
            isOnlyGetToken={false}
            isOnlyGetCode={true}
            appId={process.env.REACT_APP_FB_APP_ID || ""}
            fieldsProfile={
              "id,first_name,last_name,middle_name,name,name_format,picture,short_name,email"
            }
            scope={"email, public_profile"}
            onLoginStart={onLoginStart}
            // redirect_uri={REDIRECT_URI}
            onResolve={({ provider, data }) => {
              // setProvider(provider);
              console.log("Provider = ", provider);
              // setProfile(data);
              console.log("data = ", data);

              if (data.accessToken) {
                console.log(JSON.stringify(data));
                console.log("userId =", data.userID);
                console.log("token =", data.accessToken);

                let loginData = {
                  platform: provider || "facebook",
                  platform_response: {
                    id: data.userID,
                    token: data.accessToken,
                  },
                };
                loginWithSocialMedia(loginData);
              } else {
                console.log("result =", data);
                console.log("There is some problem...try after sometimes!!");
              }
            }}
            onReject={(err) => {
              console.log(err);
            }}
          >
            <Button
              type="button"
              className="btn_theme btn_fb btn_icon btn_large btn_circle"
            >
              <FaFacebookF />
            </Button>
          </LoginSocialFacebook>
          
          <LoginSocialApple
            // client_id={process.env.REACT_APP_APPLE_ID || ''}
            client_id={'com.udreamed.web'}
            scope={'name email'}
            redirect_uri={'https://ud2.udreamed.com/gateway/mobile/user/applehook'}
            onLoginStart={onLoginStart}
            onResolve={({ provider, data }) => {
              console.log('Login success in LoginSocialApple...')
              // setProvider(provider);
              console.log("Provider Apple = ", provider);
              // setProfile(data);
              console.log("Data Apple = ", data);  
              console.log('JSON Data Apple =',JSON.stringify(data.authorization));
              // console.log('Data using atob =',window.atob(data.authorization.is_token));
              // console.log('Data using btoa =',window.btoa(data.authorization.is_token));  

              if(data.authorization) {
                
                let claims = data.authorization.id_token.split(".")
                let user_info = JSON.parse(atob(claims[1]))

                let loginData = {
                  platform: provider || "apple",
                  platform_response: {
                    accessToken: data.authorization.id_token,
                    email: user_info.email,
                    userId: user_info.sub,
                    displayName: '',
                    familyName: '',
                    givenName: ''
                  },
                };
                loginWithSocialMedia(loginData);

              } else {
                console.log("result =", data);
                console.log("There is some problem...try after sometimes!!");
              }


            }}
            onReject={err => {
              console.log(err);
            }}
          >
            <Button
              type="button"
              className="btn_theme btn_apple btn_icon btn_large btn_circle"
            >
              <BsApple />
            </Button>  
          </LoginSocialApple>
        </div>
        
      </div>
      {/* <div className="text-center d-flex justify-content-center align-items-center pt2 pb2">
        <span className="text_primary me-3">Or sign in with : </span>
        <div>
          <a href="#">
            <img
              src={`${process.env.PUBLIC_URL}/img/fb-icon.png`}
              alt="FB Icon"
            />
          </a>{" "}
          <a href="#">
            <img
              src={`${process.env.PUBLIC_URL}/img/ios-icon.png`}
              alt="IOS Icon"
            />
          </a>
        </div>
      </div> */}
      <div className="text-center pt1">
        <Link
          to="/signup"
          className="btn btn_theme btn_border btn_primary btn_rounded register_btn"
        >
          <img
            src={`${process.env.PUBLIC_URL}/img/logo-icon.png`}
            alt="uDreamed Icon"
          />{" "}
          New user? Join now
        </Link>
      </div>
    </>
  );
}

export default Login;
