import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import HomeGuestHeader from "../components/HomeGuestHeader/HomeGuestHeader";
import HomeInnerPageBanner from "../components/HomeInnerPageBanner/HomeInnerPageBanner";
import HomeSectionHeading from "../components/HomeSectionHeading/HomeSectionHeading";
import WebUtilityCard from "../ui/CustomCard/WebUtilityCard";
import HomeNormalBanner from "../components/HomeNormalBanner/HomeNormalBanner";
import HomeFooter from "../components/HomeFooter/HomeFooter";
import PageComponent from "../components/PageComponent/PageComponent";

function Press() {
  const pressData = [
    {
      id: "p1",
      type: "press",
      typeIcon: "news-icon.png",
      mainImage: "press2.jpg",
      title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      desc: "",
      publication: "publication",
      date: "July 13, 2021",
    },
    {
      id: "p2",
      type: "press",
      typeIcon: "news-icon.png",
      mainImage: "press1.jpg",
      title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      desc: "",
      publication: "publication",
      date: "July 13, 2021",
    },
    {
      id: "p3",
      type: "press",
      typeIcon: "news-icon.png",
      mainImage: "press2.jpg",
      title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      desc: "",
      publication: "publication",
      date: "July 13, 2021",
    },
    {
      id: "p4",
      type: "press",
      typeIcon: "news-icon.png",
      mainImage: "press1.jpg",
      title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      desc: "",
      publication: "publication",
      date: "July 13, 2021",
    },
  ];
  return (
    <>
      <HomeGuestHeader />
      <PageComponent>
        <HomeInnerPageBanner className="about_banner"></HomeInnerPageBanner>
        <div className="inner_page">
          <Container className="pt5 pb5">
            <HomeSectionHeading
              htmlTag="h2"
              type="m"
              titleText="Press Articles"
              className="mb2"
            />

            <Row className="pt3 row row-cols-1 row-cols-md-2 row-cols-xl-3">
              {pressData.map((item) => {
                return (
                  <Col key={item.id} className="mb3 px-3">
                    <WebUtilityCard itemData={item} />
                  </Col>
                );
              })}
            </Row>
          </Container>
        </div>
        <HomeNormalBanner />
      </PageComponent>
      <HomeFooter />
    </>
  );
}

export default Press;
