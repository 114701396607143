import React, { useState, useEffect } from "react";
import Swiper from "react-id-swiper";
import "swiper/css/bundle";
import "swiper/bundle";
import { Col, Row, Card, Button, Placeholder, Spinner } from "react-bootstrap";
import CustomCard from "../../../ui/CustomCard/CustomCard";
import { BsFillExclamationTriangleFill } from "react-icons/bs";
import DashboardExperienceCard from "../DashboardExperienceCard/DashboardExperienceCard";
import DashboardPieChart from "../DashboardCharts/DashboardPieChart";
import DashboardBarChart from "../DashboardCharts/DashboardBarChart";
import PlaceholderCard from "../../../ui/PlaceholderCard/PlaceholderCard";
import { bgcolors } from "../../../util/colors";

function FavoriteRecordsContent(props) {
  const [labels, setLabels] = useState([]);
  const [datasets, setDatasets] = useState([]);
  const [fullDatasets, setFullDatasets] = useState([]);
  const [recordCount, setRecordCount] = useState([]);
  const [datasetLength, setDatasetLength] = useState(0);
  const [selectedChart, setSelectedChart] = useState("bar");

  // const bgcolors = [
  //   "#81d4fa",
  //   "#26c6da",
  //   "#4db6ac",
  //   "#81c784",
  //   "#aed581",
  //   "#dce775",
  //   "#fff176",
  //   "#ffb74d",
  //   "#ff8a65",
  //   "#a1887f",
  //   "#90a4ae",
  //   "#42a5f5",
  //   "#7986cb",
  //   "#b39ddb",
  //   "#ba68c8",
  //   "#f48fb1",
  //   "#e57373",
  //   "#b39d9d",
  // ];

  useEffect(() => {
    console.log(
      "in useEffect of FavoriteRecordsContent =",
      props.dashboardFavoriteGraphs
    );
    // const chart_data = props.analyzedPatterns.chart_data;
    // console.log("chart_data in useEffect =", chart_data);
    // const target = chart_data?.target;
    // console.log("target in useEffect =", target);
    // const chart = chart_data?.chart;
    // console.log("chart in useEffect =", chart);
    if (props.dashboardFavoriteGraphs?.length > 0) {
      createChartData(props.dashboardFavoriteGraphs);
    }
    // if (props.analyzedPatterns.hasOwnProperty("chart_data")) {
    //   createChartData(chart_data, 5, chart);
    // }
  }, [props.dashboardFavoriteGraphs]);

  function createChartData(data, dataLength = "full", chartOption = "bar") {
    // let newLabels = data.labels.map((label) => {
    //   //Capitalize first letter
    //   return label.charAt(0).toUpperCase() + label.slice(1);
    // });
    console.log("chart_data in createChartdata =", data);
    console.log("dataLength in createChartdata =", dataLength);
    console.log("chartOption =", chartOption);

    let targetOption = "";

    let dataLabels = [];
    let dataRecordCount = [];
    let allDatasets = [];
    let allChartsData = [];

    if (data.length > 0) {
      data.map((item, index) => {
        console.log(`ITEM ${index} = `, item);

        let newLabels = [];
        let tempDatasets = [];
        let tempData = {};
        let targetOption = item.target ? item.target : "";
        let tempLegendList = [];

        //setting up labels and Total count for displaying graphs

        if (dataLength === "full") {
          newLabels = item.chart_data.labels.map((label) => {
            //Capitalize first letter
            return label.charAt(0).toUpperCase() + label.slice(1);
          });
          setDatasetLength("full");
        } else {
          if (item.chart_type === "wordcloud") {
            newLabels = item.chart_data.labels.map((label) => {
              //Capitalize first letter
              return label.charAt(0).toUpperCase() + label.slice(1);
            });
            setDatasetLength("full");
          } else {
            for (let i = 0; i < dataLength; i++) {
              if (item.chart_data.labels.length > 0) {
                newLabels.push(
                  item.chart_data.labels[i].charAt(0).toUpperCase() +
                    item.chart_data.labels[i].slice(1)
                );
              }
            }
            setDatasetLength(0);
          }
        }
        dataLabels.push(newLabels);
        dataRecordCount.push(item.chart_data.total_no_of_records);

        //setting up actual datasets for displaying graphs
        if (item.chart_type === "wordcloud") {
          let values = null;
          console.log("---- I am going to execute ----");
          if (item.chart_data.values.hasOwnProperty("me")) {
            // console.log("I might going to be executed");
            values = item.chart_data.values.me;
          } else if (item.chart_data.values.hasOwnProperty("friends")) {
            values = item.chart_data.values.friends;
          } else if (item.chart_data.values.hasOwnProperty("global")) {
            values = item.chart_data.values.global;
          }
          tempDatasets = newLabels.map((label, index) => {
            return {
              text: label,
              value: values[index],
            };
          });
          // console.log("temp_words =", temp_words);
          console.log(
            "In if (chartOption == wordcloud) - tempDatasets =",
            tempDatasets
          );
        } else {
          console.log("I am in else I have more chance to be executed");
          if (item.chart_data.values.hasOwnProperty("me")) {
            console.log("MeDataSet = ", item.chart_data.values.me);
            let tempData = [];
            if (dataLength == "full") {
              tempData = item.chart_data.values.me;
              console.log('tempData in if (dataLength == "full") = ', tempData);
            } else {
              for (let i = 0; i < dataLength; i++) {
                tempData[i] = item.chart_data.values.me[i];
              }
              console.log(
                'tempData in else (dataLength !== "full") = ',
                tempData
              );
            }

            if (item.chart_data.values.me?.length > 0) {
              if (
                targetOption !== "me_friends" &&
                targetOption !== "me_global" &&
                targetOption !== "me_friends_global"
              ) {
                tempLegendList = item.chart_data.values.me.map(
                  (item, index) => {
                    return {
                      color: bgcolors[index],
                      value: item,
                      label: newLabels[index],
                    };
                  }
                );
              }
            }

            tempDatasets.push({
              label: "Me",
              // data: data.values.me,
              data: tempData,
              barPercentage: 1,
              // barThickness: 10,
              maxBarThickness: 10,
              // minBarLength: 4,
              // borderColor: "rgb(0, 153, 255)",
              // backgroundColor: "rgba(0, 153, 255, 0.5)",
              backgroundColor:
                targetOption == "me_friends" ||
                targetOption == "me_global" ||
                targetOption == "me_friends_global"
                  ? "rgba(0, 153, 255, 0.5)"
                  : bgcolors,
              // backgroundColor: bgcolors,
              hoverBorderRadius: 4,
              hoverBorderColor: "rgba(0,0,0,0.4)",
              // hoverBackgroundColor: "rgba(0, 153, 255, 0.7)",
            });
          }
          if (item.chart_data.values.hasOwnProperty("friends")) {
            console.log("friendsDataSet = ", item.chart_data.values.friends);
            let tempData = [];
            if (dataLength == "full") {
              tempData = item.chart_data.values.friends;
            } else {
              for (let i = 0; i < dataLength; i++) {
                tempData[i] = item.chart_data.values.friends[i];
              }
            }

            if (item.chart_data.values.friends?.length > 0) {
              if (
                targetOption !== "me_friends" &&
                targetOption !== "me_global" &&
                targetOption !== "me_friends_global"
              ) {
                tempLegendList = item.chart_data.values.friends.map(
                  (item, index) => {
                    return {
                      color: bgcolors[index],
                      value: item,
                      label: newLabels[index],
                    };
                  }
                );
              }
            }

            tempDatasets.push({
              label: "Friends",
              // data: data.values.friends,
              data: tempData,
              barPercentage: 1,
              // barThickness: 10,
              maxBarThickness: 10,
              // minBarLength: 4,
              // borderColor: "rgb(255, 204, 0)",
              backgroundColor:
                targetOption == "me_friends" ||
                targetOption == "me_global" ||
                targetOption == "me_friends_global"
                  ? "rgba(255, 204, 0, 0.7)"
                  : bgcolors,
              // backgroundColor: bgcolors,
              hoverBorderRadius: 4,
              hoverBorderColor: "rgba(0,0,0,0.4)",
              // hoverBackgroundColor: "rgba(255, 204, 0, 0.85)",
            });
          }
          if (item.chart_data.values.hasOwnProperty("global")) {
            console.log("globalDataSet = ", item.chart_data.values.global);
            let tempData = [];
            if (dataLength == "full") {
              tempData = item.chart_data.values.global;
            } else {
              for (let i = 0; i < dataLength; i++) {
                tempData[i] = item.chart_data.values.global[i];
              }
            }

            if (item.chart_data.values.global?.length > 0) {
              if (
                targetOption !== "me_friends" &&
                targetOption !== "me_global" &&
                targetOption !== "me_friends_global"
              ) {
                tempLegendList = item.chart_data.values.global.map(
                  (item, index) => {
                    return {
                      color: bgcolors[index],
                      value: item,
                      label: newLabels[index],
                    };
                  }
                );
              }
            }

            tempDatasets.push({
              label: "Global",
              // data: data.values.global,
              data: tempData,
              barPercentage: 1,
              // barThickness: 10,
              maxBarThickness: 10,
              // minBarLength: 4,
              // borderColor: "rgb(51, 204, 102)",
              backgroundColor:
                targetOption == "me_friends" ||
                targetOption == "me_global" ||
                targetOption == "me_friends_global"
                  ? "rgba(51, 204, 102, 0.5)"
                  : bgcolors,
              // backgroundColor: bgcolors,
              hoverBorderRadius: 4,
              hoverBorderColor: "rgba(0,0,0,0.4)",
              // hoverBackgroundColor: "rgba(51, 204, 102, 0.7)",
            });
          }
        }

        console.log("------ tempLegendList = ", tempLegendList);

        console.log("------ tempDatasets = ", tempDatasets);

        allDatasets.push(tempDatasets);

        console.log("------ allDatasets = ", allDatasets);

        allChartsData.push({
          chart_type: item.chart_type,
          labels: newLabels,
          datasets: tempDatasets,
          total_records: item.chart_data.total_no_of_records,
          chart_title: item.title,
          legend_list: tempLegendList,
        });
      });

      setLabels(dataLabels);
      setRecordCount(dataRecordCount);
      if (allDatasets.length > 0) {
        setDatasets(allDatasets);
      }
      if (allChartsData.length > 0) {
        setFullDatasets(allChartsData);
      }
    }

    console.log("---- Labels =", labels);
    console.log("---- RecordCount =", recordCount);
    console.log("---- Datasets =", datasets);
    console.log("---- ALL Datasets =", fullDatasets);
  }

  return (
    <>
      <div className="">
        <div class="section_title mb2">Favorite Records</div>
        {props.loadingFavorites ? (
          <>
            <PlaceholderCard />
          </>
        ) : (
          <>
            {props.dashboardFavoriteRecords.length > 0 ? (
              <>
                <div className="position-relative pb-3">
                  <Swiper
                    {...props.swiperParams}
                    containerClass="swiper-container theme_swiper"
                  >
                    {props.dashboardFavoriteRecords.length > 0 &&
                      props.dashboardFavoriteRecords.map((exp) => (
                        <div key={exp.record_id}>
                          <DashboardExperienceCard exp={exp} />
                        </div>
                      ))}
                  </Swiper>
                </div>
              </>
            ) : (
              <>
                <CustomCard className="shadow1 border-0 text-center">
                  <Card.Body>
                    <div className="mb1">
                      <BsFillExclamationTriangleFill
                        style={{
                          color: "#d3af61",
                          fontSize: "2.2rem",
                        }}
                      />
                    </div>
                    <b>No more records found...</b>
                  </Card.Body>
                </CustomCard>
              </>
            )}
          </>
        )}

        <hr />
        <div class="section_title mb2">Saved Graphs</div>

        {props.loadingFavorites ? (
          <>
            <PlaceholderCard type="chart" />
          </>
        ) : (
          <>
            {props.dashboardFavoriteGraphs.length > 0 ? (
              <>
                <div className="position-relative pb-3">
                  <Swiper
                    {...props.swiperParams}
                    containerClass="swiper-container theme_swiper"
                  >
                    {fullDatasets.length > 0 &&
                      fullDatasets.map((dataset, index) => (
                        <div
                          key={index}
                          className="card custom_card bg-transparent shadow1 border-0 p2 mb2"
                        >
                          {dataset.chart_type === "pie" ? (
                            <DashboardPieChart
                              labels={dataset.labels}
                              datasets={dataset.datasets}
                              recordCount={dataset.total_records}
                              chart_title={dataset.chart_title}
                              legend_list={dataset.legend_list}
                              // chartData={dataset}
                            />
                          ) : (
                            <DashboardBarChart
                              labels={dataset.labels}
                              datasets={dataset.datasets}
                              recordCount={dataset.total_records}
                              chart_title={dataset.chart_title}
                              legend_list={dataset.legend_list}
                              // chartData={dataset}
                            />
                          )}
                        </div>
                      ))}
                  </Swiper>
                </div>
              </>
            ) : (
              <>
                <CustomCard className="shadow1 border-0 text-center">
                  <Card.Body>
                    <div className="mb1">
                      <BsFillExclamationTriangleFill
                        style={{
                          color: "#d3af61",
                          fontSize: "2.2rem",
                        }}
                      />
                    </div>
                    <b>No more records found...</b>
                  </Card.Body>
                </CustomCard>
              </>
            )}
          </>
        )}

        {/* <div className="position-relative pb-3">
          <Swiper
            {...props.swiperParams}
            containerClass="swiper-container theme_swiper"
          >
            {datasets.length > 0 &&
              datasets.map((dataset, index) => (
                <div key={index} className="card shadow1 border-0 p2 mb2">
                  <DashboardPieChart
                    labels={labels[index]}
                    datasets={dataset}
                    // chartData={chartData}
                    recordCount={recordCount[index]}
                  />
                </div>
              ))}
          </Swiper>
        </div> */}

        {/* <div className="position-relative pb-3">
          <Swiper
            {...props.swiperParams}
            containerClass="swiper-container theme_swiper"
          >
            {props.sharedChartData.length > 0 &&
              props.sharedChartData.map((chartData, index) => (
                <div key={index} className="card shadow1 border-0 p2 mb2">
                  {chartData.chart == "pie" ? (
                    <DashboardSharedPieChart
                      labels={chartData.labels}
                      datasets={chartData.values}
                      chartData={chartData}
                    />
                  ) : (
                    <DashboardSharedBarChart
                      labels={chartData.labels}
                      datasets={chartData.values}
                      chartData={chartData}
                    />
                  )}
                </div>
              ))}
          </Swiper>
        </div> */}
      </div>
    </>
  );
}

export default FavoriteRecordsContent;
