import React, { useState, useEffect, useContext } from "react";
import { Card, Nav, Tab, Row, Col, Form } from "react-bootstrap";
import DashboardSectionTitle from "../DashboardSectionTitle/DashboardSectionTitle";
import SearchNetworkFriends from "./SearchNetworkFriends";
import CreateGroup from "./CreateGroup";
import SearchNetworkGroups from "./SearchNetworkGroups";
import DashboardNetworkGroupsContent from "./DashboardNetworkGroupsContent";
import DashboardNetworkFriendCard from "./DashboardNetworkFriendCard";
import ComposeMessageModal from "../../../ui/ComposeMessageModal/ComposeMessageModal";
import "./DashboardNetworkContent.css";
import { handleAlert } from "../../../util/util";

//Context
import AppContext from "../../../AppContext";

//Service
import recordService from "../../../services/Record";
import ConfirmRemoveFriendModal from "../../../ui/ConfirmRemoveFriendModal/ConfirmRemoveFriendModal";
import ConfirmationModal from "../../../ui/ConfirmationModal/ConfirmationModal";

function DashboardNetworkContent() {
  const [groups, setGroups] = useState([]);
  const { friends, setFriends } = useContext(AppContext);
  const [show, setShow] = useState({
    isVisible: false,
  });
  const [show2, setShow2] = useState({
    isVisible: false,
  });
  const [activeKey, setActiveKey] = useState("network_friends");
  const [action, setAction] = useState("");
  const [editValues, setEditValues] = useState({});
  const [selectedUser, setSelectedUser] = useState({ u_id: null });
  const [loading, setLoading] = useState(false);
  let group_actions = ["group_details", "add_group_user", "group_messages"];

  console.log("friends in network =", friends);

  function handleAction(actionBody) {
    console.log("handleAction callled...", actionBody);
    if (actionBody["action"]) {
      setAction(actionBody["action"]);
      setEditValues(actionBody["data"]);
      console.log("In if handleAction callled...", actionBody);
    } else {
      setAction("");
      setEditValues({});
    }
    handleShow();
  }

  const handleClose = () => {
    console.log("Closing the modal");
    setShow({
      isVisible: false,
    });
  };
  const handleShow = () => {
    setShow({
      isVisible: true,
    });
    console.log("Opening the modal");
  };

  const handleClose2 = () => {
    console.log("Closing the modal");
    setShow2({
      isVisible: false,
    });
  };
  const handleShow2 = () => {
    setShow2({
      isVisible: true,
    });
    console.log("Opening the modal");
  };

  //go to compose message
  function composeMessage() {
    handleAlert(
      "Under construction...kindly use Messages tab to send a message.",
      "error"
    );
  }

  // Get all friends related to current user from DB
  function getAllFriends() {
    recordService
      .getAllFriends()
      .then((response) => {
        // console.log("All Friends =", response.data);
        setFriends(response.data.data.result.friends);
      })
      .catch((err) => {
        console.log("There is a problem in getting all friends...", err);
      });
  }

  const handleRemoveFriend = (data) => {
    const id = data.u_id && data.u_id;
    console.log("New handleRemoveFriend func id =", id);
    setLoading(true);
    recordService
      .removeFriend(id)
      .then((response) => {
        if (response.data.status === "success") {
          // console.log("If status-success =", response.data);
          // console.log("msg =", response.data.message);
          setLoading(false);
          handleAlert(response.data.message, "success");
          //Call function to update friends in context
          getAllFriends();
          handleClose2();
        }
        if (response.data.status === "failed") {
          // console.log("If status-failed =", response.data);
          // console.log("msg =", response.data.error);
          setLoading(false);
          handleAlert(response.data.error, "error");
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  //Handle remove friend button click...
  // const handleRemoveFriend = (id) => {
  //   // console.log("handleRemoveFriend of SearchedUserCard...");

  //   // console.log("Friend Id in Handle Remove friend user=", user);
  //   // const id = user.user_id;
  //   // console.log("Friend Id in Handle Remove friend id=", id);
  //   removeFriend(id);
  //   // if (id) {
  //   //   removeFriend(id);
  //   // }
  // };

  //Remove friend
  // function removeFriend(id) {
  //   // console.log("id =", id);
  //   setLoading(true);
  //   recordService
  //     .removeFriend(id)
  //     .then((response) => {
  //       if (response.data.status === "success") {
  //         // console.log("If status-success =", response.data);
  //         // console.log("msg =", response.data.message);
  //         setLoading(false);
  //         handleAlert(response.data.message, "success");
  //         //Call function to update friends in context
  //         getAllFriends();
  //         handleClose2();
  //       }
  //       if (response.data.status === "failed") {
  //         // console.log("If status-failed =", response.data);
  //         // console.log("msg =", response.data.error);
  //         setLoading(false);
  //         handleAlert(response.data.error, "error");
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       setLoading(false);
  //     });
  // }

  function getAllGroups() {
    recordService
      .getAllGroups()
      .then((response) => {
        console.log("group list: ", response.data);
        let updatedRecords;
        updatedRecords = response.data.data.result.groups.map((record) => {
          return { ...record, actions: group_actions };
        });
        setGroups(updatedRecords);
      })
      .catch((err) => {
        console.log("There is a problem in getting all groups...", err);
      });
  }

  const handleSelectedTab = (key) => {
    console.log("Key in handleSelectedTab =", key);
    setEditValues({});
    if (key == "network_friends") {
      console.log("Entering into network_friends");
      //fetch archived messages from db
    }
    if (key == "network_groups") {
      console.log("Entering into network_groups");
      //fetch all greoups from db
      getAllGroups();
    }
    setActiveKey(key);
  };

  return (
    <>
      <DashboardSectionTitle
        iconImg="offer-icon4.png"
        title="Network"
        pageTitle={true}
        className="mb2"
        withInfo={true}
        infoData="Connect with friends and create groups around similar interests and experiences."
      />

      {/* <CustomCard className={`main_card border-0 mb2`}> */}
      <div className="network_tab">
        <Tab.Container
          id="network-tabs-example"
          className="main_tabs"
          activeKey={activeKey}
          onSelect={(key) => handleSelectedTab(key)}
        >
          <div className="tab_links">
            <Nav variant="pills" className="nav-justified">
              <Nav.Item>
                <Nav.Link className="normal_link" eventKey="network_friends">
                  <div className="">
                    <img
                      src={`${process.env.PUBLIC_URL}/img/friends-icon.png`}
                      className="me-2"
                      alt="friends-icon"
                    />
                    Friends
                  </div>
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link className="normal_link" eventKey="network_groups">
                  <div className="">
                    <img
                      src={`${process.env.PUBLIC_URL}/img/groups-icon.png`}
                      className="me-2"
                      alt="Groups-icon"
                    />
                    Groups
                  </div>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
          <Tab.Content>
            <Tab.Pane className="p2" eventKey="network_friends">
              <Row className="form_style mt0 mb0 no-gutters">
                <Col xs={12}>
                  <SearchNetworkFriends
                    friends={friends}
                    setSelectedUser={setSelectedUser}
                    // removeFriend={removeFriend}
                    composeMessage={composeMessage}
                    handleAction={handleAction}
                    handleShow={handleShow2}
                    // handleRemoveFriend={handleRemoveFriend}
                  />
                </Col>
              </Row>
              <DashboardSectionTitle
                iconImg=""
                title="All Friends"
                pageTitle={false}
                className=""
              />

              <Row className="row-cols-1 row-cols-sm-2 g-3 mt1">
                {friends?.map((item, index) => (
                  <Col key={index}>
                    <DashboardNetworkFriendCard
                      user={item}
                      setSelectedUser={setSelectedUser}
                      // removeFriend={removeFriend}
                      handleClose={handleClose2}
                      handleShow={handleShow2}
                      handleAction={handleAction}
                      // handleRemoveFriend={handleRemoveFriend}
                    />
                  </Col>
                ))}
              </Row>
            </Tab.Pane>

            <Tab.Pane className="p2" eventKey="network_groups">
              <Row className="mt2 mb3 no-gutters">
                <Col xs={12}>
                  <CreateGroup getAllGroups={getAllGroups} />
                </Col>
              </Row>

              <Row className="form_style mt2 mb1 no-gutters">
                <Col xs={12}>
                  <SearchNetworkGroups getAllGroups={getAllGroups} />
                </Col>
              </Row>
              <DashboardSectionTitle
                iconImg=""
                title="My Groups"
                pageTitle={false}
                className="mb1"
              />
              <DashboardNetworkGroupsContent
                groups={groups}
                getAllGroups={getAllGroups}
              />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
      <ComposeMessageModal
        friends={friends}
        show={show}
        handleClose={handleClose}
        initialValues={editValues}
        action={action}
      />
      {/* <ConfirmRemoveFriendModal
        show={show2}
        id={selectedUser.u_id}
        loading={loading}
        handleClose={handleClose2}
        removeFriend={removeFriend}
      /> */}
      <ConfirmationModal
        show={show2}
        loading={loading}
        handleClose={handleClose2}
        primaryActionData={selectedUser}
        content_type="remove-friend-modal"
        handlePrimaryAction={handleRemoveFriend}
      />
      {/* </CustomCard> */}
    </>
  );
}

export default DashboardNetworkContent;
