import React, { useState, useContext } from "react";
import DashboardSectionTitle from "../DashboardSectionTitle/DashboardSectionTitle";
import InvitationCard from "./InvitationCard";
import GroupInvitationCard from "./GroupInvitationCard";
import GroupRequestInvitationCard from "./GroupRequestInvitationCard";
import ConsultantInvitationCard from "./ConsultantInvitationCard";
import SimpleMessageCard from "../../../ui/SimpleMessageCard/SimpleMessageCard";
import { handleAlert } from "../../../util/util";

//Context
import AppContext from "../../../AppContext";
//Service
import authService from "../../../services/Auth";
import recordService from "../../../services/Record";

function DashboardInvitationContent() {
  const [loading, setLoading] = useState(false);
  const {
    invitations,
    groupInvitations,
    groupRequests,
    consultInvitations,
    getUserProfileData,
    getAllFriends,
    getAllInvitations,
    getAllConsultInvitations,
    getAllGroupInvitations,
    getAllGroupRequests
  } = useContext(AppContext);
  const authUser = JSON.parse(authService.getAuthUser());
  // console.log("Invitations =", invitations);
  // console.log("groupInvitations =", groupInvitations);
  // console.log("groupRequests =", groupRequests);
  // console.log("consultInvitations =", consultInvitations);

  //Accept Group invitation/request
  function acceptGroupInvitation(invitation, type = "invite") {
    console.log("Accept Button clicked data =", invitation);
    const data = {};
    if (type == "request") {
      console.log("Invite type is=", type);
      data = {
        group_id: invitation.user_group_id,
        user_id: invitation.user_id,
      };
    } else {
      console.log("Invite type is=", type);
      data = {
        group_id: invitation.user_group_id,
        user_id: authUser.user_id,
      };
    }

    if (data.group_id) {
      acceptFriend(data);
    }
  }

  function getGroupInvitationData(
    invitation,
    action = "accept",
    type = "invite"
  ) {
    console.log("getGroupInvitationData = type =", type, "\t action =", action);
    console.log("Accept Button clicked data =", invitation);
    let data = {};
    if (type == "request") {
      console.log("Invite type is=", type);
      data = {
        group_id: invitation.user_group_id,
        user_id: invitation.user_id,
      };
    } else {
      console.log("Invite type is=", type);
      data = {
        group_id: invitation.user_group_id,
        user_id: authUser.user_id,
      };
    }

    if (data.group_id) {
      if (action == "accept") {
        console.log("Calling acceptGroupRequest()...", data);
        acceptGroupRequest(data);
      } else if (action == "reject") {
        console.log("Calling rejectGroupRequest()...", data);
        rejectGroupRequest(data);
      }
    }
  }

  // Accept Group request/invitation
  function acceptGroupRequest(data) {
    recordService
      .acceptGroupRequest(data)
      .then((response) => {
        console.log(response.data);
        if (response.data.status === "success") {
          handleAlert(response.data.message, "success");
          //Fetch all group invitations
          getAllGroupInvitations();
          //Fetch all group request
          getAllGroupRequests();
          //Update friends in context
          // getAllFriends();
        }
        if (response.data.status === "failed") {
          console.log("ERROR:", response.data.error);
          handleAlert(response.data.error, "error");
        }
      })
      .catch((err) => {
        console.log("There is a problem in accept request...", err);
        handleAlert("Request acceptance failed!", "error");
      });
  }

  // Reject Group request/invitation
  function rejectGroupRequest(data) {
    recordService
      .rejectGroupRequest(data)
      .then((response) => {
        console.log(response.data);
        if (response.data.status === "success") {
          handleAlert(response.data.message, "success");
          //Fetch all group invitations
          getAllGroupInvitations();
          //Fetch all group request
          getAllGroupRequests();
          //Update friends in context
          // getAllFriends();
        }
        if (response.data.status === "failed") {
          console.log("ERROR:", response.data.error);
          handleAlert(response.data.error, "error");
        }
      })
      .catch((err) => {
        console.log("There is a problem in accept request...", err);
        handleAlert("Request acceptance failed!", "error");
      });
  }

  // Accept consult invitation
  function acceptConsultInvite(data) {
    recordService
      .acceptConsultInvite(data)
      .then((response) => {
        console.log(response.data);
        if (response.data.status === "success") {
          // handleAlert(response.data.message, "success");
          handleAlert("Consultation approved!", "success");
          //Fetch all consult invitations
          getAllConsultInvitations();

          //Update friends in context
          // getAllFriends();
        }
        if (response.data.status === "failed") {
          console.log("ERROR:", response.data.error);
          handleAlert(response.data.error, "error");
        }
      })
      .catch((err) => {
        console.log("There is a problem in accept request...", err);
        handleAlert("Request acceptance failed!", "error");
      });
  }

  // Reject consult invitation
  function rejectConsultInvite(data) {
    recordService
      .rejectConsultInvite(data)
      .then((response) => {
        console.log(response.data);
        if (response.data.status === "success") {
          handleAlert(response.data.message, "success");
          //Fetch all consult invitations
          getAllConsultInvitations();

          //Update friends in context
          // getAllFriends();
        }
        if (response.data.status === "failed") {
          console.log("ERROR:", response.data.error);
          handleAlert(response.data.error, "error");
        }
      })
      .catch((err) => {
        console.log("There is a problem in accept request...", err);
        handleAlert("Request acceptance failed!", "error");
      });
  }

  //Accept invitation
  function acceptInvitation(invitation) {
    console.log("Accept Button clicked ID =", invitation.user_id);
    if (invitation.user_id) {
      acceptFriend(invitation.user_id);
    }
  }
  // Accept friend request/invitation
  function acceptFriend(id) {
    recordService
      .acceptFriend(id)
      .then((response) => {
        console.log(response.data);
        if (response.data.status === "success") {
          handleAlert(response.data.message, "success");
          //Fetch all invitations
          getAllInvitations();

          //Update friends in context
          getAllFriends();
          //Get Profile Stats
          getUserProfileData();
        }
        if (response.data.status === "failed") {
          console.log("ERROR:", response.data.error);
          handleAlert(response.data.error, "error");
        }
      })
      .catch((err) => {
        console.log("There is a problem in accept request...", err);
        handleAlert("Request acceptance failed!", "error");
      });
  }

  //Accept invitation
  function rejectInvitation(invitation) {
    console.log("Reject Button clicked ID =", invitation.user_id);
    if (invitation.user_id) {
      rejectFriend(invitation.user_id);
    }
  }
  // Accept friend request/invitation
  function rejectFriend(id) {
    recordService
      .rejectFriend(id)
      .then((response) => {
        console.log(response.data);
        if (response.data.status === "success") {
          handleAlert(response.data.message, "success");
          //Fetch all invitations
          getAllInvitations();

          //Update friends in context
          getAllFriends();
        }
        if (response.data.status === "failed") {
          console.log("ERROR:", response.data.error);
          handleAlert(response.data.error, "error");
        }
      })
      .catch((err) => {
        console.log("There is a problem in accept request...", err);
        handleAlert("Request rejection failed!", "error");
      });
  }

  return (
    <>
      <DashboardSectionTitle
        iconImg="offer-icon15.png"
        title="Invitations"
        pageTitle={true}
        className="mb2"
      />
      {consultInvitations.length > 0 && (
        <>
          {consultInvitations.map((consultInvitation, index) => (
            <ConsultantInvitationCard
              key={index}
              invitation={consultInvitation}
              acceptConsultInvite={acceptConsultInvite}
              rejectConsultInvite={rejectConsultInvite}
            />
          ))}
        </>
      )}
      {groupInvitations.length > 0 && (
        <>
          {groupInvitations.map((groupInvitation, index) => (
            <GroupInvitationCard
              key={index}
              invitation={groupInvitation}
              getGroupInvitationData={getGroupInvitationData}
            />
          ))}
        </>
      )}
      {groupRequests.length > 0 && (
        <>
          {groupRequests.map((groupRequest, index) => (
            <GroupRequestInvitationCard
              key={index}
              invitation={groupRequest}
              getGroupInvitationData={getGroupInvitationData}
            />
          ))}
        </>
      )}
      {invitations.length > 0 && (
        <>
          {invitations.map((invitation, index) => (
            <InvitationCard
              key={index}
              invitation={invitation}
              acceptInvitation={acceptInvitation}
              rejectInvitation={rejectInvitation}
            />
          ))}
        </>
      )}

      {!invitations.length > 0 &&
        !groupRequests.length > 0 &&
        !groupInvitations.length > 0 &&
        !consultInvitations.length > 0 && (
          <>
            <SimpleMessageCard 
              type="warning" 
              msg="No invitations"
              allowIcon={false}
              className="mb5"
            />
          </>
      )}
    </>
  );
}

export default DashboardInvitationContent;
