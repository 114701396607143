import React from "react";
import { Card, Button } from "react-bootstrap";
import CustomCard from "../../../ui/CustomCard/CustomCard";
import "./GroupRequestInvitationCard.css";
import { BsFillPeopleFill } from "react-icons/bs";

function GroupRequestInvitationCard(props) {
  const fname = props.invitation?.firstname
    ? props.invitation.firstname
    : "Unknown";
  const lname = props.invitation?.lastname ? props.invitation.lastname : "";
  const group_name = props.invitation?.title
    ? props.invitation.title
    : "Unknown";

  const handleAcceptBtnClick = (invitation) => {
    props.getGroupInvitationData(invitation, "accept", "request");
  };
  const handleRejectBtnClick = (invitation) => {
    props.getGroupInvitationData(invitation, "reject", "request");
  };

  return (
    <>
      <CustomCard
        className={`group_request_invitation_card main_card shadow1 border-0 mb2`}
      >
        <Card.Header className="group_request_invitation_card_header">
          <div className="media">
            <div className="media_icon_wrap d-flex align-items-center justify-content-center">
              <BsFillPeopleFill />
            </div>

            <div className={`media_body ms-3`}>
              <Card.Title className="card_title">{`Request by ${fname} ${lname}`}</Card.Title>
              <Card.Subtitle className="card_subtitle">
                {`You have been requested to add ${fname} ${lname} in your group (${group_name})`}
              </Card.Subtitle>
            </div>
          </div>
        </Card.Header>
        <Card.Body className="group_request_invitation_card_body">
          <div className="btn_wrap">
            <Button
              type="button"
              className="btn_theme btn_small btn_secondary btn_rounded"
              onClick={() => {
                handleAcceptBtnClick(props.invitation);
              }}
            >
              Accept
            </Button>
            <Button
              type="button"
              className="btn_theme btn_small btn_danger btn_rounded"
              onClick={() => {
                handleRejectBtnClick(props.invitation);
              }}
            >
              Ignore
            </Button>
          </div>
        </Card.Body>
      </CustomCard>
    </>
  );
}

export default GroupRequestInvitationCard;
