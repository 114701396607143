import React, { useState, useEffect } from "react";
import { Nav, Tab, Placeholder, Card } from "react-bootstrap";
import CustomCard from "../../../ui/CustomCard/CustomCard";
import DashboardSectionTitle from "../DashboardSectionTitle/DashboardSectionTitle";
import "./DashboardSettingsContent.css";
import GeneralSettings from "./GeneralSettings";

//Service
import recordService from "../../../services/Record";
import UpdateGeneralSettingsModal from "../../../ui/UpdateGeneralSettingsModal/UpdateGeneralSettingsModal";
import PublishSettings from "./PublishSettings";
import ChangePasswordContent from "./ChangePasswordContent";
import SubscriptionContent from "./SubscriptionContent";

function DashboardSettingsContent() {
  const [currentUser, setCurrentUser] = useState({});
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const [userSubscriptions, setUserSubscriptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState({
    isVisible: false,
  });
  console.log("Inside DashboardSettingsContent component");
  useEffect(() => {
    console.log(
      "Fetching logged in user details for the first time when component loads..."
    );
    //Fetching logged in user details for the first time when component loads...
    getUserDetails();
  }, []);

  const handleClose = () => {
    console.log("Closing the modal");
    setShow({
      isVisible: false,
    });
  };
  const handleShow = () => {
    setShow({
      isVisible: true,
    });
    console.log("Opening the modal");
  };

  function getUserDetails() {
    setLoading(true);
    recordService
      .getUserDetails()
      .then((response) => {
        if (response.data.status === "success") {
          console.log("Response from General settings : ", response.data);
          console.log("Response currUser : ", response.data.data.result.user);
          setCurrentUser(response.data.data.result.user);
          setLoading(false);
          console.log("currentUser = ", currentUser);
        }
        if (response.data.status === "failed") {
          console.log("ERROR:", response.data.error);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log("There is a problem in getting forums...", err);
      });
  }

  function getSubscriptionPlans() {
    setLoading(true);
    recordService
      .getSubscriptionPlans()
      .then((response) => {
        if (response.data.status === "success") {
          console.log("Response from Subscription settings : ", response.data);
          console.log(
            "Response Subscription plans : ",
            response.data.data.result.plans
          );
          setSubscriptionPlans(response.data.data.result.plans);
          setLoading(false);
        }
        if (response.data.status === "failed") {
          console.log("ERROR:", response.data.error);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log("There is a problem in Subscription plans...", err);
      });
  }

  function getUserSubscriptions() {
    setLoading(true);
    recordService
      .getUserSubscriptions()
      .then((response) => {
        if (response.data.status === "success") {
          console.log("Response from Subscription settings : ", response.data);
          console.log(
            "Response User Subscriptions list : ",
            response.data.data.result.subscriptions
          );
          setUserSubscriptions(response.data.data.result.subscriptions);
          setLoading(false);
        }
        if (response.data.status === "failed") {
          console.log("ERROR:", response.data.error);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(
          "There is a problem in fetching User subscriptions...",
          err
        );
      });
  }

  const handleSelectedTab = (key) => {
    console.log("Key in handleSelectedTab =", key);
    if (key === "general_settings") {
      console.log("Entering into General Settings");
      getUserDetails();
    }
    if (key === "public_settings") {
      console.log("Entering into Publish Settings");
      getUserDetails();
    }
    if (key === "subscription_settings") {
      console.log("Entering into subscription Settings");
      // getUserDetails();
      getSubscriptionPlans();
      getUserSubscriptions();
    }
  };

  return (
    <>
      <DashboardSectionTitle
        iconImg="offer-icon12.png"
        title="Settings"
        pageTitle={true}
        className="mb2"
      />
      <CustomCard className={`main_card border-0 bg_transparent mb2`}>
        <Tab.Container
          id="discover-tabs-example"
          className="main_tabs"
          defaultActiveKey="general_settings"
          onSelect={(key) => handleSelectedTab(key)}
        >
          <div className="tab_links">
            <Nav variant="pills" className="nav-justified">
              <Nav.Item>
                <Nav.Link className="normal_link" eventKey="general_settings">
                  <div className="">General</div>
                </Nav.Link>
              </Nav.Item>

              {/* <Nav.Item>
                <Nav.Link className="normal_link" eventKey="public_settings">
                  <div className="">Publish</div>
                </Nav.Link>
              </Nav.Item> */}

              <Nav.Item>
                <Nav.Link className="normal_link" eventKey="change_pwd">
                  <div className="">Change Password</div>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="normal_link"
                  eventKey="subscription_settings"
                >
                  <div className="">Subscription</div>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
          <Tab.Content>
            <Tab.Pane className="pt2 pb2" eventKey="general_settings">
              <GeneralSettings user={currentUser} handleShow={handleShow} />
            </Tab.Pane>

            {/* <Tab.Pane className="pt2 pb2" eventKey="public_settings">
              
              <PublishSettings
                user={currentUser}
                getUserDetails={getUserDetails}
              />
              
            </Tab.Pane> */}

            <Tab.Pane className="pt2 pb2" eventKey="change_pwd" unmountOnExit>
              <ChangePasswordContent />
              {/* <GeneralSettings user={currentUser} handleShow={handleShow} /> */}
            </Tab.Pane>

            <Tab.Pane className="pt2 pb2" eventKey="subscription_settings">
              <SubscriptionContent
                loading={loading}
                subscriptionPlans={subscriptionPlans}
                userSubscriptions={userSubscriptions}
              />
              {/* <GeneralSettings user={currentUser} handleShow={handleShow} /> */}
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </CustomCard>
      <UpdateGeneralSettingsModal
        currentUser={currentUser}
        show={show}
        handleClose={handleClose}
        getUserDetails={getUserDetails}
      />
    </>
  );
}

export default DashboardSettingsContent;
