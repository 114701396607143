import React, { useState } from "react";
import { Modal, Form, InputGroup, Button, Spinner } from "react-bootstrap";
import DashboardSectionTitle from "../../components/Dashboard/DashboardSectionTitle/DashboardSectionTitle";
import { Field, Formik } from "formik";
import * as yup from "yup";
import "./AskConsultingPaymentModal.css";
import { handleAlert } from "../../util/util";
import { BsCurrencyDollar } from "react-icons/bs";

//Service
import recordService from "../../services/Record";
import FieldError from "../FieldError/FieldError";

function AskConsultingPaymentModal(props) {
  const [loading, setLoading] = useState(false);

  const initialValues = {
    amount: "",
  };

  const askConsultingPaymentSchema = yup.object({
    amount: yup.number().positive("Invalid amount").required("Required"),
  });

  const onSubmit = async (values, { resetForm }) => {
    console.log("askConsultingPayment Form Values :", values);
    setLoading(true);

    const newValues = {
      ...values,
      consult_id: props.consult_id,
    };
    console.log("New values in Compose =", newValues);

    recordService
      .askConsultingPayment(newValues)
      .then((response) => {
        if (response.data.status === "success") {
          console.log(
            "askConsultingPayment() in AskConsultingPaymentModal ",
            response.data
          );
          handleAlert(
            "Success! You have requested payment for consulting.",
            "success"
          );

          setLoading(false);
          resetForm({});

          //Close Message Modal if exist
          if (props.handleClose) {
            props.handleClose();
          }
          //get all consulting sessions to get updated trasactions
          props.getAllConsultingSessions();
        } else if (response.data.status === "failed") {
          // console.log("ERROR:", response.data.error);
          handleAlert("Error! Failed to request consulting payment.", "error");
          setLoading(false);
          resetForm({});
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Modal
        centered
        size="md"
        className="theme-modal consult-payment-modal"
        show={props.show.isVisible}
        onHide={props.handleClose}
      >
        <Modal.Header className="pb-2" closeButton>
          <Modal.Title className="d-flex align-items-center">
            <div className="heading-icon-wrap flex-shrink-0">
              <span className="heading-icon d-flex justify-content-center align-items-center rounded-circle font-24">
                <BsCurrencyDollar />
              </span>
            </div>
            <div className="heading flex-grow-1 ms-3">Payment</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-helper-text mb1">
            Ask your client for session payment
          </div>

          <div className="form_style pt1 pb1">
            <Formik
              initialValues={initialValues}
              validationSchema={askConsultingPaymentSchema}
              onSubmit={onSubmit}
              // enableReinitialize
            >
              {(formik) => {
                return (
                  <Form onSubmit={formik.handleSubmit}>
                    <DashboardSectionTitle
                      iconImg=""
                      title="Enter amount"
                      pageTitle={false}
                      className="mb1 small_title"
                    />
                    <InputGroup className="form-group rounded_corners mb0">
                      <InputGroup.Text>$</InputGroup.Text>
                      <Form.Control
                        aria-label="amount"
                        type="text"
                        placeholder="0"
                        name="amount"
                        {...formik.getFieldProps("amount")}
                        onChange={(e) => {
                          e.preventDefault();
                          const { value } = e.target;
                          const regex =
                            /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;
                          if (!value || regex.test(value.toString())) {
                            formik.setFieldValue("amount", value);
                          }
                        }}
                      />
                    </InputGroup>
                    <FieldError
                      valid={
                        formik.touched.amount && formik.errors.amount
                          ? true
                          : false
                      }
                      text={formik.errors.amount}
                    />
                    {/* {formik.touched.amount && formik.errors.amount ? (
                      <p className="error-state-text">{formik.errors.amount}</p>
                    ) : null} */}
                    {/* <Form.Group className="form-group rounded_corners">
                      <Form.Control
                        type="text"
                        placeholder="0"
                        name="amount"
                        {...formik.getFieldProps("amount")}
                      />
                      {formik.touched.amount && formik.errors.amount ? (
                        <p className="error-state-text">
                          {formik.errors.amount}
                        </p>
                      ) : null}
                    </Form.Group> */}
                    <div className="btn_wrap pt2">
                      <Button
                        type="submit"
                        className="btn btn_theme btn_primary btn_rounded"
                      >
                        {loading ? (
                          <>
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />{" "}
                            Processing
                          </>
                        ) : (
                          "Submit"
                        )}
                      </Button>
                      <Button
                        type="button"
                        key="closeModalBtn"
                        className="btn_theme btn_light btn_rounded"
                        onClick={props.handleClose}
                      >
                        Cancel
                      </Button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AskConsultingPaymentModal;
