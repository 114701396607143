import React, { useState, useContext } from "react";
import { Card, Button } from "react-bootstrap";
import CustomCard from "../../../ui/CustomCard/CustomCard";
import CustomCollapseButton from "../../../ui/CustomCollapse/CustomCollapseButton";
import CustomCollapseContainer from "../../../ui/CustomCollapse/CustomCollapseContainer";
import AddGroupUser from "./AddGroupUser";
import GroupDetails from "./GroupDetails";
import "./DashboardNetworkGroupCard.css";
import { BsFillStarFill, BsFillPeopleFill } from "react-icons/bs";
import ConfirmRemoveGroupMemberModal from "../../../ui/ConfirmRemoveGroupMemberModal/ConfirmRemoveGroupMemberModal";
import { handleAlert } from "../../../util/util";

//Context
import AppContext from "../../../AppContext";

//Service
import recordService from "../../../services/Record";
import authService from "../../../services/Auth";
import GroupCommentList from "./GroupCommentList";
import ConfirmLeaveGroupModal from "../../../ui/ConfirmLeaveGroupModal/ConfirmLeaveGroupModal";
import DashboardComments from "../DashboardComments/DashboardComments";
import ConfirmationModal from "../../../ui/ConfirmationModal/ConfirmationModal";

function DashboardNetworkGroupCard(props) {
  const { friends } = useContext(AppContext);
  const authUser = JSON.parse(authService.getAuthUser());
  const group_owner_id = props.group?.owner_id ? props.group.owner_id : "NA";
  const [loading, setLoading] = useState(false);
  const [cardTop, setCardTop] = useState(0);
  const [groupDetails, setGroupDetails] = useState({});
  const [addMembers, setAddMembers] = useState([]);
  const [removeMemberData, setRemoveMemberData] = useState({});
  const [leaveGroupData, setLeaveGroupData] = useState({});

  const [groupCommentsCount, setGroupCommentsCount] = useState(
    props.group.comment_count
  );
  const [groupComments, setGroupComments] = useState([]);

  // const isThreadOwner =
  //   props.authUser.user_id == props.thread.user_id ? true : false;

  //Delete Member Confirmation Modal State
  const [show, setShow] = useState({
    isVisible: false,
  });
  //leave group Confirmation Modal State
  const [show2, setShow2] = useState({
    isVisible: false,
  });

  const handleClose2 = () => {
    // console.log("Closing the modal");
    setShow2({
      isVisible: false,
    });
  };

  const handleShow2 = (e) => {
    // console.log("data-id=", e.target.getAttribute("data-id"));
    // setRemoveMemberData({
    //   user_id: e.target.getAttribute("data-id"),
    //   group_id: props.group.user_group_id,
    // });
    setShow2({
      isVisible: true,
    });
    console.log("Opening the modal inside Network group card");
  };

  const handleClose = () => {
    // console.log("Closing the modal");
    setShow({
      isVisible: false,
    });
  };
  const handleShow = (e) => {
    console.log("data-id=", e.target.getAttribute("data-id"));
    setRemoveMemberData({
      user_id: e.target.getAttribute("data-id"),
      group_id: props.group.user_group_id,
    });

    setShow({
      isVisible: true,
    });
    // console.log("Opening the modal");
  };

  const [openCollapse, setOpenCollapse] = useState({
    view_group_details_collapse: false,
    join_group_collapse: false,
    add_group_user_collapse: false,
    view_group_messages_collapse: false,
  });

  let tempState = null;
  const handleCollapse = (element) => {
    switch (element) {
      case "group_details":
        tempState = Object.fromEntries(
          Object.keys({ ...openCollapse }).map((key) => [key, false])
        );
        setOpenCollapse({
          ...tempState,
          view_group_details_collapse:
            !openCollapse.view_group_details_collapse,
        });
        break;
      case "add_group_user":
        tempState = Object.fromEntries(
          Object.keys({ ...openCollapse }).map((key) => [key, false])
        );
        setOpenCollapse({
          ...tempState,
          add_group_user_collapse: !openCollapse.add_group_user_collapse,
        });
        break;
      case "group_messages":
        tempState = Object.fromEntries(
          Object.keys({ ...openCollapse }).map((key) => [key, false])
        );
        setOpenCollapse({
          ...tempState,
          view_group_messages_collapse:
            !openCollapse.view_group_messages_collapse,
        });
        break;
      case "join_group":
        tempState = Object.fromEntries(
          Object.keys({ ...openCollapse }).map((key) => [key, false])
        );
        setOpenCollapse({
          ...tempState,
          join_group_collapse: !openCollapse.join_group_collapse,
        });
        break;
    }
  };

  const handleUserList = () => {
    const group_members = [];
    recordService
      .getGroup(props.group.user_group_id)
      .then((response) => {
        if (response.data.status === "success") {
          console.log("handleGroupDetails() Group card: ", response.data);
          const group_members = response.data.data.result.users;

          // Seperated user_ids into one array from group_members
          const group_member_ids = group_members.map((item) => item.user_id);
          setAddMembers(
            friends.filter((item) => !group_member_ids.includes(item.id))
          );

          console.log("USerList in handleUserList =", addMembers);

          // setGroupDetails(group_details);
          // setLoading(false);
        } else if (response.data.status === "failed") {
          console.log("ERROR:", response.data.error);
          // setLoading(false);
          // setGroupDetails({});
        }
      })
      .catch((err) => {
        console.log("There is a problem in fetching group details...", err);
        // setLoading(false);
        // setGroupDetails({});
      });
  };
  // const handleAddGroupUser = (friends, members) => {
  //   const data = {
  //     group_id: group_id,
  //     user_id: user_id,
  //   };

  //   console.log("data in handleAddGroupUser =", data);

  //   recordService
  //     .addGroupUser(data)
  //     .then((response) => {
  //       if (response.data.status === "success") {
  //         console.log("handleAddGroupUser() Group card: ", response.data);
  //         const response = response.data.data.result;
  //         // setGroupDetails(group_details);
  //         setLoading(false);
  //       } else if (response.data.status === "failed") {
  //         console.log("ERROR:", response.data.error);
  //         setLoading(false);
  //         // setGroupDetails({});
  //       }
  //     })
  //     .catch((err) => {
  //       console.log("There is a problem in adding user in group...", err);
  //       setLoading(false);
  //       setGroupDetails({});
  //     });
  // };

  const handleLeaveGroup = (group) => {
    setLoading(true);
    console.log(
      "DashboardNetworkGroupCard - data in handleLeaveGroup =",
      group
    );
    recordService
      .leaveGroup(group.user_group_id)
      .then((response) => {
        if (response.data.status === "success") {
          console.log("leave Group response =", response.data);
          handleAlert(`You have left ${group.title}`, "success");
          handleCollapse("group_details");
          handleClose2();
          setLoading(false);
          //Get all groups
          props.getAllGroups();
        } else if (response.data.status === "failed") {
          console.log("ERROR:", response.data.error);
          handleAlert(`Unable to leave ${group.title}`, "error");
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleRemoveGroupUser = (data) => {
    // const data = {
    //   group_id: group_id,
    //   user_id: user_id,
    // };
    setLoading(true);
    console.log("data in handleRemoveGroupUser =", data);
    recordService
      .removeGroupUser(data)
      .then((response) => {
        if (response.data.status === "success") {
          console.log("remove Group member response =", response.data);
          handleAlert("You have successfully deleted a member!", "success");
          handleCollapse("group_details");
          handleClose();
          setLoading(false);
          //Get all groups
          props.getAllGroups();
        } else if (response.data.status === "failed") {
          console.log("ERROR:", response.data.error);
          handleAlert("Delete group member failed!", "error");
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleGroupDetails = (id) => {
    setLoading(true);
    recordService
      .getGroup(id)
      .then((response) => {
        if (response.data.status === "success") {
          console.log("handleGroupDetails() Group card: ", response.data);
          const group_details = response.data.data.result;
          setGroupDetails(group_details);
          setLoading(false);
        } else if (response.data.status === "failed") {
          console.log("ERROR:", response.data.error);
          setLoading(false);
          setGroupDetails({});
        }
      })
      .catch((err) => {
        console.log("There is a problem in fetching group details...", err);
        setLoading(false);
        setGroupDetails({});
      });
  };

  const handleGroupCommentList = (id) => {
    setLoading(true);
    const commentData = {
      group_id: id,
    };
    recordService
      .getAllGroupComments(commentData)
      .then((response) => {
        if (response.data.status === "success") {
          console.log(
            "---handleGroupCommentList() comment list: ",
            response.data
          );
          const groupComments = response.data.data.result.comments;

          setGroupComments(groupComments);
          // setThreads(forumThreads);
          setLoading(false);
        } else if (response.data.status === "failed") {
          console.log("ERROR:", response.data.error);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log("There is a problem in fetching group messages...", err);
        setLoading(false);
      });
  };

  return (
    <>
      <CustomCard className={`network_group_card main_card shadow1 border-0`}>
        <Card.Header className="border-0">
          <div className="media">
            <div className="media_icon_wrap d-flex align-items-center justify-content-center">
              <BsFillPeopleFill />
              {/* <img
                  src={`${process.env.PUBLIC_URL}/img/${icon_url}`}
                  className={`media_icon`}
                  alt={`${type_of_recorded_exp} icon`}
                /> */}
            </div>
            <div className={`media_body ms-3`}>
              <Card.Title className="card_title">
                {props.group.title}
                {/* | {props.group.user_group_id} */}
              </Card.Title>
              <Card.Subtitle className="card_subtitle">
                Members {props.group.user_count}
              </Card.Subtitle>
            </div>
          </div>
          {/* <div className="d-flex align-items-center">
          <div className="flex-shrink-0">
            <img
              src={`${process.env.PUBLIC_URL}/img/${
                group.profileImg === "" ? "groups-icon.jpg" : group.profileImg
              }`}
              className="me-2"
              alt="user group"
            />
          </div>
          <div className="flex-grow-1 ms-3">
            <div className="card-title text_primary">{group.gname}</div>
            <div className="card-subtitle" style={{ marginBottom: "2px" }}>
              Members {group.members}
            </div>
            <div className="text-gray9">{group.desc}</div>
          </div>
        </div> */}
        </Card.Header>
        <Card.Body>
          <p>{props.group.description}</p>
          <div className="btn_wrap">
            {props.group.actions?.map((action, index) => {
              switch (action) {
                case "group_details":
                  return (
                    <CustomCollapseButton
                      key={index}
                      handleCollapse={handleCollapse}
                      btnTitle="Details"
                      className="btn_small btn_light btn_rounded"
                      ariaControls="group_details"
                      ariaExpanded={openCollapse.view_group_details_collapse}
                      setCardTop={setCardTop}
                    />
                  );
                case "add_group_user":
                  let add_group_user_btnobj;
                  {
                    authUser.user_id === group_owner_id
                      ? (add_group_user_btnobj = (
                          <CustomCollapseButton
                            key={index}
                            handleCollapse={handleCollapse}
                            btnTitle="Add Member"
                            className="btn_small btn_success btn_rounded"
                            ariaControls="add_group_user"
                            ariaExpanded={openCollapse.add_group_user_collapse}
                            setCardTop={setCardTop}
                          />
                        ))
                      : (add_group_user_btnobj = "");
                  }
                  return add_group_user_btnobj;

                case "group_messages":
                  return (
                    <CustomCollapseButton
                      key={index}
                      handleCollapse={handleCollapse}
                      btnTitle={
                        groupCommentsCount === 1
                          ? `${groupCommentsCount} Comment`
                          : `${groupCommentsCount} Comments`
                      }
                      className="btn_small btn_secondary btn_rounded"
                      ariaControls="group_messages"
                      ariaExpanded={openCollapse.view_group_messages_collapse}
                      setCardTop={setCardTop}
                    />
                  );

                case "join_group":
                  return (
                    <CustomCollapseButton
                      key={index}
                      handleCollapse={handleCollapse}
                      btnTitle="Join"
                      className="btn_small btn_gray btn_rounded"
                      ariaControls="join_group"
                      ariaExpanded={openCollapse.join_group_collapse}
                      setCardTop={setCardTop}
                    />
                  );
              }
            })}
          </div>
          {props.group.actions?.map((action, index) => {
            switch (action) {
              case "group_details":
                return (
                  <CustomCollapseContainer
                    key={index}
                    ariaControls="group_details"
                    openCollapse={openCollapse.view_group_details_collapse}
                    group_id={props.group.user_group_id}
                    handleGroupDetails={handleGroupDetails}
                    cardTop={cardTop}
                  >
                    <hr />
                    <GroupDetails
                      groupDetails={groupDetails}
                      handleShow={handleShow}
                      handleShow2={handleShow2}
                      handleLeaveGroup={handleLeaveGroup}
                    />
                  </CustomCollapseContainer>
                );
              case "add_group_user":
                let add_group_user_containerobj;
                {
                  authUser.user_id === group_owner_id
                    ? (add_group_user_containerobj = (
                        <CustomCollapseContainer
                          key={index}
                          ariaControls="add_group_user"
                          openCollapse={openCollapse.add_group_user_collapse}
                          handleGroupDetails={handleGroupDetails}
                          handleUserList={handleUserList}
                          group_id={props.group.user_group_id}
                          cardTop={cardTop}
                        >
                          <hr />
                          <AddGroupUser
                            group_id={props.group.user_group_id}
                            groupDetails={groupDetails}
                            addMembers={addMembers}
                            handleCollapse={handleCollapse}
                            getAllGroups={props.getAllGroups}
                          />
                        </CustomCollapseContainer>
                      ))
                    : (add_group_user_containerobj = "");
                }
                return add_group_user_containerobj;
              // return (
              //   <CustomCollapseContainer
              //     key={index}
              //     ariaControls="add_group_user"
              //     openCollapse={openCollapse.add_group_user_collapse}
              //     handleGroupDetails={handleGroupDetails}
              //     handleUserList={handleUserList}
              //     group_id={props.group.user_group_id}
              //     cardTop={cardTop}
              //   >
              //     <hr />
              //     <AddGroupUser
              //       group_id={props.group.user_group_id}
              //       groupDetails={groupDetails}
              //       addMembers={addMembers}
              //       handleCollapse={handleCollapse}
              //       getAllGroups={props.getAllGroups}
              //     />
              //   </CustomCollapseContainer>
              // );
              case "group_messages":
                return (
                  <CustomCollapseContainer
                    key={index}
                    ariaControls="group_messages"
                    openCollapse={openCollapse.view_group_messages_collapse}
                    group_id={props.group.user_group_id}
                    handleGroupCommentList={handleGroupCommentList}
                    // className="position-relative border-top mt-3 pt-3 pb-3"
                    cardTop={cardTop}
                  >
                    {/* <hr /> */}
                    <DashboardComments
                      comment_type="group"
                      loading={loading}
                      parent_id={props.group.user_group_id}
                      parent_owner_id={group_owner_id}
                      comments={groupComments}
                      handleCommentList={handleGroupCommentList}
                      setCommentsCount={setGroupCommentsCount}
                    />
                    {/* <div className="d-grid gap-3 comment_list_wrap">
                      <GroupCommentList
                        loading={loading}
                        group_id={props.group.user_group_id}
                        group_owner_id={group_owner_id}
                        setGroupCommentsCount={setGroupCommentsCount}
                        groupComments={groupComments}
                        handleGroupCommentList={handleGroupCommentList}
                        handleShow={handleShow}
                        handleCollapse={handleCollapse}
                      />
                    </div> */}
                  </CustomCollapseContainer>
                );

              case "join_group":
                return (
                  <CustomCollapseContainer
                    key={index}
                    ariaControls="join_group"
                    openCollapse={openCollapse.join_group_collapse}
                    cardTop={cardTop}
                  >
                    <hr />
                    <div className="pt2 pb2">Join group content</div>
                  </CustomCollapseContainer>
                );
            }
          })}
          {/* <div className="btn_wrap">
          <Button
            type="button"
            className="btn_theme btn_small btn_primary btn_rounded"
          >
            Details
          </Button>
          <Button
            type="button"
            className="btn_theme btn_small btn_light btn_rounded"
          >
            Edit
          </Button>
        </div> */}
        </Card.Body>
      </CustomCard>
      

      {/***** 
       ** Remove group member confirmation modal **
       ******/}
      <ConfirmationModal
        show={show}
        loading={loading}
        handleClose={handleClose}
        primaryActionData={removeMemberData}
        content_type="remove-member-modal"
        handlePrimaryAction={handleRemoveGroupUser}
      />  

      {/* <ConfirmRemoveGroupMemberModal
        show={show}
        group_id={props.group.user_group_id}
        user_id={""}
        removeMemberData={removeMemberData}
        loading={loading}
        handleClose={handleClose}
        handleRemoveGroupUser={handleRemoveGroupUser}
      /> */}

      {/***** 
       ** Leave group confirmation modal **
       ******/}
      <ConfirmationModal
        show={show2}
        loading={loading}
        handleClose={handleClose2}
        primaryActionData={props.group}
        content_type="leave-group-modal"
        handlePrimaryAction={handleLeaveGroup}
      />  

      {/* <ConfirmLeaveGroupModal
        show={show2}
        group={props.group}
        loading={loading}
        handleClose={handleClose2}
        handleLeaveGroup={handleLeaveGroup}
      /> */}
    </>
  );
}

export default DashboardNetworkGroupCard;
