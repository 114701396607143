import React, { useState } from "react";
import HomeAbout from "../components/HomeAbout/HomeAbout";
import HomeGuestHeader from "../components/HomeGuestHeader/HomeGuestHeader";

//Components
import HomeGuestBanner from "../components/HomeGuestBanner/HomeGuestBanner";
import HomePress from "../components/HomePress/HomePress";
import HomeTestimonial from "../components/HomeTestimonial/HomeTestimonial";
import HomeImageSlider from "../components/HomeImageSlider/HomeImageSlider";
import HomeVideoBanner from "../components/HomeVideoBanner/HomeVideoBanner";
import HomeFooter from "../components/HomeFooter/HomeFooter";
import HomeFeatures from "../components/HomeFeatures/HomeFeatures";
import PageComponent from "../components/PageComponent/PageComponent";

//data
import { features } from "../util/publicData";

function HomeGuest() {
  const pressData = [
    {
      id: "p1",
      type: "press",
      typeIcon: "news-icon.png",
      mainImage: "press2.jpg",
      title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      desc: "",
      publication: "publication",
      date: "July 13, 2021",
    },
    {
      id: "p2",
      type: "press",
      typeIcon: "news-icon.png",
      mainImage: "press1.jpg",
      title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      desc: "",
      publication: "publication",
      date: "July 13, 2021",
    },
    {
      id: "p3",
      type: "press",
      typeIcon: "news-icon.png",
      mainImage: "press2.jpg",
      title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      desc: "",
      publication: "publication",
      date: "July 13, 2021",
    },
  ];

  const testimonialData = [
    {
      id: "t1",
      type: "testimonial",
      typeIcon: "quote-icon.png",
      mainImage: "user1.jpg",
      desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      user: "John Doe",
      userInfo: "Founder at Company",
    },
    {
      id: "t2",
      type: "testimonial",
      typeIcon: "quote-icon.png",
      mainImage: "user2.jpg",
      desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      user: "Michel J",
      userInfo: "Founder at Company",
    },
    {
      id: "t3",
      type: "testimonial",
      typeIcon: "quote-icon.png",
      mainImage: "user3.jpg",
      desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      user: "Aron Smith",
      userInfo: "Founder at Company",
    },
  ];

  const [titleSlides] = useState([
    {
      id: "titleSlide1",
      title: "Share",
      subTitle: "your experiences",
    },
    {
      id: "titleSlide2",
      title: "Consult",
      subTitle: "professionals",
    },
    {
      id: "titleSlide3",
      title: "Gain",
      subTitle: "unique insights",
    },
  ]);
  const [imageSlides] = useState([
    {
      id: "imgSlide1",
      imgUrl: "sample.jpg",
      title: "Share",
      subTitle: "your experiences",
    },
    {
      id: "imgSlide2",
      imgUrl: "sample.jpg",
      title: "Consult",
      subTitle: "professionals",
    },
    {
      id: "imgSlide3",
      imgUrl: "sample.jpg",
      title: "Gain",
      subTitle: "unique insights",
    },
  ]);

  const [featuresSlides] = useState(features);

  return (
    <>
      <HomeGuestHeader />
      <PageComponent>
        <HomeGuestBanner />
        <HomeAbout />
        <HomeFeatures featuresSlides={featuresSlides} />
        {/* <HomePress data={pressData} /> */}
        <HomeImageSlider titleSlides={titleSlides} imageSlides={imageSlides} />
        <HomeVideoBanner />
        {/* <HomeTestimonial testiData={testimonialData} /> */}
      </PageComponent>
      <HomeFooter />
    </>
  );
}

export default HomeGuest;
