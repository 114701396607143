import React, { useState, useEffect } from "react";
import { Card, Placeholder } from "react-bootstrap";
import CustomCard from "../../../ui/CustomCard/CustomCard";
import DashboardSectionTitle from "../DashboardSectionTitle/DashboardSectionTitle";
import { BsFillExclamationTriangleFill } from "react-icons/bs";
import AddGroupComment from "./AddConsultationComment";
import AddConsultationComment from "./AddConsultationComment";
import "./ConsultationCommentList.css";

function ConsultationCommentList(props) {
  // const [loading, setLoading] = useState(true);
  return (
    <>
      {/* <div class="pt1 pb1">
				<hr />
			</div> */}
      {/* <DashboardSectionTitle
        iconImg=""
        title="All comments"
        pageTitle={false}
        className="mb1"
      /> */}

      <AddConsultationComment
        setSessionCommentsCount={props.setSessionCommentsCount}
        handleConsultationCommentList={props.handleConsultationCommentList}
        consult_id={props.consult_id}
      />

      {!props.loading && props.sessionComments?.length > 0 ? (
        props.sessionComments?.map((item, index) => (
          <CustomCard
            key={item.comment_id}
            className="main_card comment_card border-1 mb-0 overflow-hidden"
          >
            <Card.Body className="pb-1">
              <p className="mb0 font-15 ps-1">{item.comment}</p>
            </Card.Body>
            <Card.Header className="bg_transparent border-0 pt-2">
              <div className="media">
                <div className="media_icon_wrap d-flex justify-content-center align-items-center align-self-start">
                  <img
                    src={item.user?.avatar}
                    className="rounded-circle media_img"
                    alt={`${item.user?.fullname} profile`}
                  />
                </div>

                <div className="media_body ms-2 d-flex justify-content-between align-items-center">
                  <div class="card-title mb-0 font-13">
                    {item.user?.fullname}
                  </div>
                  <div class="card-subtitle font-13">{item.time_lapsed}</div>
                  {/* <p className="mb0">{item.comment}</p> */}
                </div>
              </div>
            </Card.Header>
            {/* <Card.Body className="comment_text pt0"> */}
            {/* <p>{item.comment}</p> */}
            {/* </Card.Body> */}
          </CustomCard>
        ))
      ) : (
        <>
          {props.loading ? (
            <>
              {[0, 1].map((_, i) => (
                <CustomCard
                  key={i}
                  className={`main_card border-1 mb-0 text-left overflow-hidden`}
                >
                  <Card.Body className="p2">
                    <div className="w-100 mb-1">
                      <Placeholder xs={9} bg="secondary" />
                    </div>
                  </Card.Body>
                  <Card.Header className="bg_transparent border-0 pt-2">
                    <div className="d-flex align-items-center">
                      <div className="flex-shrink-0">
                        <Placeholder as="div" animation="glow">
                          <Placeholder
                            bg="secondary"
                            as="div"
                            style={{ height: "36px", width: "36px" }}
                            className="rounded-circle"
                          />
                        </Placeholder>
                      </div>
                      <div className="flex-grow-1 ms-3 text-left">
                        <div className="w-100">
                          <Placeholder xs={6} bg="secondary" />
                        </div>
                      </div>
                    </div>
                  </Card.Header>
                </CustomCard>
              ))}
            </>
          ) : (
            <CustomCard className="shadow1 border-0 text-center">
              <Card.Body>
                <div className="mb1">
                  <BsFillExclamationTriangleFill
                    style={{
                      color: "#d3af61",
                      fontSize: "2.2rem",
                    }}
                  />
                </div>
                <b>No comments found...</b>
              </Card.Body>
            </CustomCard>
          )}
        </>
      )}
    </>
  );
}

export default ConsultationCommentList;
