import React, { useState, useEffect } from "react";
import Swiper from "react-id-swiper";
import "swiper/css/bundle";
import "swiper/bundle";
import PopularGraphs from "./PopularGraphs";
import PopularWordcloud from "./PopularWordcloud";
import MorePopularGraphs from "./MorePopularGraphs";
import { bgcolors } from "../../../util/colors";

function PopularInsightsContent(props) {
  // const bgcolors = [
  //   "#81c784",
  //   "#81d4fa",
  //   "#ff8a65",
  //   "#dce775",
  //   "#ffb74d",
  //   "#26c6da",
  //   "#4db6ac",
  //   "#aed581",
  //   "#fff176",
  //   "#a1887f",
  //   "#90a4ae",
  //   "#42a5f5",
  //   "#7986cb",
  //   "#b39ddb",
  //   "#ba68c8",
  //   "#f48fb1",
  //   "#e57373",
  //   "#b39d9d",
  // ];

  return (
    <>
      <div className="">
        <PopularGraphs
          // bgcolors={bgcolors}
          swiperParams={props.swiperParams}
          dashboardPopularInsightsData={props.dashboardPopularInsightsData}
          setDashboardPopularInsightsData={
            props.setDashboardPopularInsightsData
          }
          getDashboardPopularInsightsData={
            props.getDashboardPopularInsightsData
          }
          loading={props.loading}
          setLoading={props.setLoading}
          popularInsightsFilterData={props.popularInsightsFilterData}
        />

        <hr />

        <MorePopularGraphs
          bgcolors={bgcolors}
          dashboardMorePopularInsightsData={
            props.dashboardMorePopularInsightsData
          }
          popularInsightsFilterData={props.popularInsightsFilterData}
        />

        <hr />

        <PopularWordcloud
          dashboardPopularWordcloudData={props.dashboardPopularWordcloudData}
          loadingPopularWordcloud={props.loadingPopularWordcloud}
          popularWordcloudFilterData={props.popularWordcloudFilterData}
          getDashboardPopularWordcloudData={
            props.getDashboardPopularWordcloudData
          }
        />
      </div>
    </>
  );
}

export default PopularInsightsContent;
