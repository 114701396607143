import React from "react";
import { Row, Col, Card } from "react-bootstrap";
import CustomCard from "../../../ui/CustomCard/CustomCard";
import DashboardNetworkGroupCard from "./DashboardNetworkGroupCard";

function DashboardNetworkGroupsContent(props) {
  console.log("Groups = ", props.groups);
  return (
    <>
      <div className="d-grid gap-3 mt2">
        {props.groups?.map((item, index) => (
          <DashboardNetworkGroupCard
            key={item.title}
            group={item}
            getAllGroups={props.getAllGroups}
          />
        ))}
        {/* {props.groups.length < 0 && (
          <Col xs={12}>
            <CustomCard className={`main_card border-0 text-center mt2 mb2`}>
              <Card.Body>
                <b>
                  You do not have access to any group. Create one or join a
                  group by searching one!
                </b>
              </Card.Body>
            </CustomCard>
          </Col>
        )} */}
      </div>
    </>
  );
}

export default DashboardNetworkGroupsContent;
