import React, { useState, useEffect } from "react";
import ReactSpeedometer from "react-d3-speedometer";

function SpeedometerChart(props) {
  const [val, setVal] = useState(props.value);

  useEffect(() => {
    setVal(props.value);
  }, [props.value]);

  return (
    <>
      <ReactSpeedometer
        {...props.speedometerConfig}
        value={val > 100 ? 100 : val}
        currentValueText={`${props.currentValueText} : ${val}`}
        needleTransitionDuration={4000}
        needleTransition="easeElastic"
        forceRender={true}
      />
    </>
  );
}

export default SpeedometerChart;
