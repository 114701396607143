import React from "react";
import { Card } from "react-bootstrap";
import CustomCard from "../../../ui/CustomCard/CustomCard";
import DashboardSectionTitle from "../DashboardSectionTitle/DashboardSectionTitle";

function DashboardShopContent() {
  return (
    <>
      <DashboardSectionTitle
        iconImg="offer-icon8.png"
        title="Shop"
        pageTitle={true}
        className="mb2"
        withInfo={true}
        infoData="Find products to help you navigate, facilitate and augment your unconscious experiences."
      />
      <CustomCard className="main_card border-0 mb2">
        <Card.Body>
          <DashboardSectionTitle
            iconImg=""
            title="Coming Soon!"
            pageTitle={false}
            className="mb2"
          />
          
        </Card.Body>
      </CustomCard>
    </>
  );
}

export default DashboardShopContent;
