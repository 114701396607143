import React from "react";
import { Card, Placeholder } from "react-bootstrap";
import CustomCard from "../../../ui/CustomCard/CustomCard";
import ThreadCard from "./ThreadCard";
import PlaceholderCard from "../../../ui/PlaceholderCard/PlaceholderCard";
import SimpleMessageCard from "../../../ui/SimpleMessageCard/SimpleMessageCard";
import { BsFillExclamationTriangleFill } from "react-icons/bs";

//Services
import authService from "../../../services/Auth";

function ThreadList(props) {
  console.log("All Threads =", props.threads);
  // const authUser = JSON.parse(authService.getAuthUser());

  return (
    <>
      {/* <Button className="btn_theme btn_small btn_gray btn_rounded">Add Thread</Button> */}
      {!props.loading && props.threads?.length > 0 ? (
        props.threads?.map((thread, index) => (
          <ThreadCard
            thread={thread}
            key={index}
            forum_id={props.forum_id}
            handleThreadList={props.handleThreadList}
            cardTop={props.cardTop}
            setCardTop={props.setCardTop}
            authUser={props.authUser}
          />
        ))
      ) : (
        <>
          {props.loading ? (
            <>
              {[0, 1].map((_, i) => (
                <PlaceholderCard type="thread" />
                // <CustomCard
                //   key={i}
                //   className={`main_card shadow1 border-0 mb2 text-left overflow-hidden`}
                // >
                //   <Card.Header>
                //     <div className="d-flex align-items-center">
                //       <div className="flex-shrink-0">
                //         <Placeholder as="div" animation="glow">
                //           <Placeholder
                //             bg="secondary"
                //             as="div"
                //             style={{ height: "54px", width: "54px" }}
                //             className="rounded-circle"
                //           />
                //         </Placeholder>
                //       </div>
                //       <div className="flex-grow-1 ms-3 text-left">
                //         <div className="w-100">
                //           <Placeholder xs={6} bg="secondary" />
                //         </div>
                //         <div className="w-100">
                //           <Placeholder xs={4} bg="secondary" />
                //         </div>
                //       </div>
                //     </div>
                //   </Card.Header>
                //   <Card.Body className="p2">
                //     {/* <div className="w-100 mb-1">
                //       <Placeholder xs={9} bg="secondary" />
                //     </div> */}
                //     <div className="w-100 mb1">
                //       <Placeholder xs={6} bg="secondary" />
                //     </div>
                //   </Card.Body>
                // </CustomCard>
              ))}
            </>
          ) : (
            <SimpleMessageCard
              type="warning"
              msg="No more threads"
              allowIcon={false}
            />
            // <CustomCard className="shadow1 border-0 text-center">
            //   <Card.Body>
            //     <div className="mb1">
            //       <BsFillExclamationTriangleFill
            //         style={{
            //           color: "#d3af61",
            //           fontSize: "2.2rem",
            //         }}
            //       />
            //     </div>
            //     <b>No more threads found...</b>
            //   </Card.Body>
            // </CustomCard>
          )}
        </>
      )}
    </>
  );
}

export default ThreadList;
