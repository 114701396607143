import React from "react";
import { Button } from "react-bootstrap";
import "./CustomAccordionButton.css";

function CustomAccordionButton(props) {
  function handleClick(e) {
    console.log(e);
    const element = e.target.getAttribute("aria-controls");
    let isAriaExpanded = e.target.getAttribute("aria-expanded");
    console.log("In CustomAccordionButton - Element =", element);
    console.log("CustomAccordionButton - expanded =", isAriaExpanded);
    props.handleCollapse(element);
  }

  return (
    <Button
      type="button"
      className={`btn_accordion ${props.className}`}
      onClick={handleClick}
      aria-controls={props.ariaControls}
      aria-expanded={props.ariaExpanded}
    >
      {props.iconImg ? (
        <img
          src={`${process.env.PUBLIC_URL}/img/${props.iconImg}`}
          className="img-fluid d-block"
          alt={`${props.btnTitle} Icon`}
        />
      ) : null}
      {/* {btnType === "normal" ? <>{btnTitle}</> : <>{iconComponent}</>} */}
      {props.btnTitle}
    </Button>
  );
}

export default CustomAccordionButton;
