import React from "react";
import { useHistory, Redirect } from "react-router-dom";
import { Card, Button, Badge, Spinner } from "react-bootstrap";
import CustomCard from "../../../ui/CustomCard/CustomCard";
import "./SubscriptionPlanCard.css";
import {
  BsFillCheckCircleFill,
  BsFillStarFill,
  BsFillExclamationCircleFill,
  BsFillExclamationTriangleFill,
  BsClockHistory,
  BsClockFill,
  BsClock,
} from "react-icons/bs";
//   import "./ConsultingSessionCard.css";
import { handleAlert } from "../../../util/util";

//Service
import recordService from "../../../services/Record";

function SubscriptionPlanCard(props) {
  const handleClick = (item) => {
    console.log("Clicked on Plan =", item);
    const data = {
      plan_id: item.plan_id,
    };
    subscribe(data);
  };

  const subscribe = (data) => {
    recordService
      .subscribe(data)
      .then((response) => {
        if (response.data.status === "success") {
          console.log("subscribe() function: ", response.data);
          const resResult = response.data.data.result.redirect_url;

          // setRedirectionURL(response.data.data.result.redirect_url);
          handleAlert("Redirecting to URL", "success");
          // history.replace(resResult);
          window.location.href = resResult;
          // handleShow4();
          // setTimeout(() => {
          //   if (redirectionURL) handleShow4();
          // }, 1000);
          // handleAlert("payment Completed!", "success");
          // props.getAllConsultingSessions();
          // setLoading2(false);
        } else if (response.data.status === "failed") {
          console.log("ERROR:", response.data.error);
          handleAlert("Failed to redirect to payment!", "error");
          // setRedirectionURL("");
          // setLoading2(false);
        }
      })
      .catch((err) => {
        console.log("Failed to redirect to payment...", err);
        // setLoading2(false);
        // setRedirectionURL("");
      });
  };

  return (
    <CustomCard
      className={`subscription_plan_card custom_card border-1 overflow-hidden`}
      key={props.plan.plan_id}
    >
      <Card.Body
        className="d-flex flex-column align-items-center"
        onClick={() => {
          handleClick(props.plan);
        }}
      >
        <div className="custom_tag tag_small tag_purple text-uppercase mb-2">
          {props.plan.title}
        </div>
        <div className="font-24 fw_b text_secondary_dark mb-1">{`$${props.plan.amount}`}</div>
        <div className="font-14 text_gray6">
          {props.plan.months > 1
            ? `${props.plan.months} months`
            : `${props.plan.months} month`}
        </div>
      </Card.Body>
    </CustomCard>
  );
}

export default SubscriptionPlanCard;
