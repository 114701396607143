import React, { useState, Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./HomeImageSlider.css";
// import { Carousel } from "react-bootstrap";

class HomeImageSlider extends Component {
  state = {
    nav1: null,
    nav2: null,
    slides: [],
    txtSlides: [],
  };

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
      slides: this.props.imageSlides,
      txtSlides: this.props.titleSlides,
    });
  }

  render() {
    const { slides, txtSlides } = this.state;

    const slickVerticalNavSettings = {
      slidesToShow: 3,
      slidesToScroll: 1,
      dots: false,
      arrows: false,
      centerMode: false,
      focusOnSelect: true,
      infinite: false,
      vertical: true,
      verticalSwiping: true,
      autoplay: false,
      autoplaySpeed: 5000,
      asNavFor: this.state.nav2,
      ref: (slider) => (this.slider1 = slider),
      responsive: [
        {
          breakpoint: 991,
          settings: {
            vertical: false,
            verticalSwiping: false,
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
      ],
    };

    const slickVerticalMainSettings = {
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      adaptiveHeight: true,
      autoplay: false,
      autoplaySpeed: 5000,
      asNavFor: this.state.nav1,
      ref: (slider) => (this.slider2 = slider),
    };
    // className={["row", this.props.className].join(" ")}
    return (
      <section className={`banner new_banner split_banner`}>
        <div
          className={`split_item split_item_30 bg_grad_primary_to_secondary`}
        >
          <div className="split_item_content">
            <Slider
              className="new_banner_slider_indicator_wrap"
              {...slickVerticalNavSettings}
            >
              {txtSlides.map((txtSlide, i) => (
                <div className="new_banner_indicator pt1 pb1" key={txtSlide.id}>
                  <h1>
                    {txtSlide.title}{" "}
                    <span className="line1">{txtSlide.subTitle}</span>
                  </h1>
                </div>
              ))}
            </Slider>
          </div>
        </div>
        <div className={`split_item split_item_70 new_banner_slider_content`}>
          <Slider {...slickVerticalMainSettings}>
            {slides.map((slide, i) => (
              <div className="img_container" key={slide.id}>
                <img
                  src={`${process.env.PUBLIC_URL}/img/${slide.imgUrl}`}
                  className="img-fluid "
                  alt="Sample Image"
                />
              </div>
            ))}
          </Slider>
        </div>
      </section>
    );
  }
}

export default HomeImageSlider;
