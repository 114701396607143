import React, { useState, useEffect, useContext } from "react";
import {
  Row,
  Col,
  Card,
  Collapse,
  Form,
  Button,
  Spinner,
  Placeholder,
  Alert,
} from "react-bootstrap";
import CustomCard from "../../../ui/CustomCard/CustomCard";
import DashboardSectionTitle from "../DashboardSectionTitle/DashboardSectionTitle";
import DashboardCheckboxCard from "../DashboardCheckboxCard/DashboardCheckboxCard";
import DashboardAnalyzeExploreBarChart from "./DashboardAnalyzeExploreBarChart";
import DashboardBarChart from "../DashboardCharts/DashboardBarChart";
import styles from "./DashboardAnalyzeExplore.module.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Field, Formik } from "formik";
import * as yup from "yup";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { addDays, subDays } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import { formatDateYMD, handleCustomSelectStyles } from "../../../util/util";
import {
  emotionsList,
  charactersList,
  interactionsList,
  locationsList,
  causesList,
  resolutionsList,
} from "../../../util/emoji";
import { BsFillExclamationTriangleFill, BsGearFill } from "react-icons/bs";

import { COUNTRIES } from "../../../util/countries";
import { bgcolors } from "../../../util/colors";

//Context
import ThemeContext from "../../../ThemeContext";
//Service
import recordService from "../../../services/Record";

function DashboardAnalyzeExplore(props) {
  const today = formatDateYMD(new Date());
  const { theme } = useContext(ThemeContext);
  const [loading, setLoading] = useState(false);
  const [analyzedExplore, setAnalyzedExplore] = useState({});
  const [labels, setLabels] = useState([]);
  const [meDataset, setMeDataset] = useState([]);
  const [friendsDataset, setFriendsDataset] = useState([]);
  const [globalDataset, setGlobalDataset] = useState([]);
  const [datasets, setDataSets] = useState([]);
  const [fullDatasets, setFullDatasets] = useState([]);
  const [selectedChart, setSelectedChart] = useState("bar");
  const [recordCount, setRecordCount] = useState(0);
  const [exploreFiltersCollapse, setExploreFiltersCollapse] = useState(false);
  const [analyzedExploreFilters, setAnalyzedExploreFilters] = useState({
    record_type: "all",
    target: "global",
    element: "emotions",
    end_date: today,
    start_date: "2021-02-28",
    sub_cats: [
      "happiness",
      "confusion",
      "anxiety",
      "sadness",
      "frustration",
      "helplessness",
      "love",
      "fear",
      "surprise",
      "shame",
      "desire",
      "guilt",
      "no emotion",
      "anger",
      "jealousy",
    ],
    age: [13, 100],
    country: "all",
    education: "all",
    gender: "all",
  });

  // const bgcolors = [
  //   "#81d4fa",
  //   "#26c6da",
  //   "#4db6ac",
  //   "#81c784",
  //   "#aed581",
  //   "#dce775",
  //   "#fff176",
  //   "#ffb74d",
  //   "#ff8a65",
  //   "#a1887f",
  //   "#90a4ae",
  //   "#42a5f5",
  //   "#7986cb",
  //   "#b39ddb",
  //   "#ba68c8",
  //   "#f48fb1",
  //   "#e57373",
  //   "#b39d9d",
  // ];

  //React select styles
  const customStyles = handleCustomSelectStyles(theme);

  // const customStyles = {
  //   control: (provided, state) => ({
  //     ...provided,
  //     border: theme === "dark" ? "1px solid #666" : "1px solid #ddd",
  //     borderRadius: "30px",
  //     minHeight: "48px",
  //     backgroundColor: theme === "dark" ? "#333" : "#fff",
  //     color: "inherit",
  //     "&:focus": {
  //       borderWidth: "1px",
  //       borderColor: "#39b3ed",
  //       boxShadow: "none",
  //     },
  //     "&:hover": {
  //       borderColor: theme === "dark" ? "#666" : "#ddd",
  //     },
  //   }),
  //   input: (provided) => ({
  //     ...provided,
  //     color: "inherit",
  //   }),
  //   singleValue: (provided) => ({
  //     ...provided,
  //     color: "inherit",
  //     // color: theme === "dark" ? "#fff" : "#333"
  //   }),
  //   placeholder: (provided) => ({
  //     ...provided,
  //     color: "#ffffff",
  //   }),
  //   menuList: (provided) => ({
  //     ...provided,
  //     backgroundColor: theme === "dark" ? "#444" : "#fff",
  //     padding: 0,
  //     zIndex: 1,
  //   }),
  //   option: (provided, { isFocused, isSelected }) => ({
  //     ...provided,
  //     background: isFocused ? "#999" : isSelected ? "#0991f6" : undefined,
  //   }),
  //   valueContainer: (provided, state) => ({
  //     ...provided,
  //     padding: "2px 12px",
  //     color: "inherit",
  //     // color: theme === "dark" ? "#fff" : "#333",
  //     // zIndex: 1,
  //   }),
  // };

  function handleClick(e) {
    const element = e.target.getAttribute("aria-controls");
    let isAriaExpanded = e.target.getAttribute("aria-expanded");
    // console.log("Association Card =", e);
    handleCollapse(element);
  }
  function handleCollapse(element) {
    console.log("In Analyze explore Comp =", element);
    if (element === "explore_filters_collapse") {
      setExploreFiltersCollapse(!exploreFiltersCollapse);
    }
  }

  const recordRadioOptions = [
    { key: "All", value: "all" },
    { key: "Dream", value: "dream" },
    { key: "Event", value: "event" },
    { key: "Out of Body", value: "outofbody" },
    { key: "Thought", value: "thought" },
  ];

  const targetRadioOptions = [
    { key: "Me", value: "me" },
    { key: "Friends", value: "friends" },
    { key: "Global", value: "global" },
    { key: "Me to Friends", value: "me_friends" },
    { key: "Me to Global", value: "me_global" },
    { key: "Me to Friends and Global", value: "me_friends_global" },
  ];

  const elementOptions = [
    // { label: "Select Element", value: "" },
    { label: "Location in experience", value: "locations" },
    { label: "Characters in experience", value: "characters" },
    { label: "Interactions between characters", value: "interactions" },
    { label: "Emotions in experience", value: "emotions" },
    // { label: "Transformations in experience", value: "transformations" },
    // { label: "Unusal circumstances", value: "circumstances" },
    { label: "Causes of experience", value: "causes" },
    { label: "Resolutions", value: "resolutions" },
    // { label: "Occurrence frequency", value: "occurrence_frequency" },
    // { label: "Time frame", value: "time_frame" },
  ];

  const ageValues = [];
  for (let i = 13; i <= 100; i++) {
    ageValues.push(i);
  }
  const ageOptions = ageValues.map((age) => {
    return { label: age === 100 ? "100+" : age.toString(), value: age };
  });
  // console.log("ageValues", ageValues);

  const genderOptions = [
    { key: "All", value: "all" },
    { key: "Male", value: "male" },
    { key: "Female", value: "female" },
  ];

  const educationOptions = [
    { label: "All", value: "all" },
    { label: "Less than high school", value: "less than high school" },
    { label: "High school diploma", value: "high school diploma" },
    { label: "Some college", value: "some college" },
    { label: "Undergraduate degree", value: "undergraduate degree" },
    { label: "Undergraduate degree", value: "undergraduate degree" },
    { label: "Graduate degree", value: "graduate degree" },
    { label: "Post Graduate degree", value: "post graduate degree" },
  ];

  const countryOptions1 = COUNTRIES.map((country) => {
    return { label: country.name, value: country.name };
  });

  const countryOptions = [{ label: "All", value: "all" }, ...countryOptions1];

  useEffect(() => {
    console.log("in useEffect =", props.analyzedExplore);
    const chart_data = props.analyzedExplore.chart_data;
    console.log("chart_data in useEffect =", chart_data);
    const target = chart_data?.target;
    console.log("target in useEffect =", target);
    const chart = chart_data?.chart;
    console.log("chart in useEffect =", chart);

    if (props.analyzedExplore.hasOwnProperty("chart_data")) {
      createChartData(chart_data, "full", chart, target);
    }
  }, [props.analyzedExplore]);

  function createChartData(
    data,
    dataLength = "full",
    chartOption = "bar",
    targetOption = "me"
  ) {
    console.log("chart_data in createChartdata =", data);
    console.log("dataLength in createChartdata =", dataLength);
    console.log("targetOption =", targetOption);
    console.log("chartOption =", chartOption);

    let newLabels = [];
    newLabels = data.labels.map((label) => {
      //Capitalize first letter
      return label.charAt(0).toUpperCase() + label.slice(1);
    });

    setLabels(newLabels);
    setRecordCount(data.total_no_of_records);

    let tempDataSets = [];
    let allChartsData = [];
    let chartTitle = "";
    let totalRecords = 0;
    let tempLegendList = [];
    if (chartOption === "wordcloud") {
      let values = null;
      console.log("---- I am going to execute ----");
      if (data.values.hasOwnProperty("me")) {
        values = data.values.me;
      } else if (data.values.hasOwnProperty("friends")) {
        values = data.values.friends;
      } else if (data.values.hasOwnProperty("global")) {
        values = data.values.global;
      }
      tempDataSets = newLabels.map((label, index) => {
        return {
          text: label,
          value: values[index],
        };
      });
      console.log(
        "In if (chartOption == wordcloud) - tempDataSets =",
        tempDataSets
      );
    } else {
      console.log("I am in else I have more chance to be executed");
      if (data.values.hasOwnProperty("me")) {
        console.log("MeDataSet = ", data.values.me);
        if (
          targetOption !== "me_friends" &&
          targetOption !== "me_global" &&
          targetOption !== "me_friends_global"
        ) {
          tempLegendList = data.values.me.map((item, index) => {
            return {
              color: bgcolors[index],
              value: item,
              label: newLabels[index],
            };
          });
        }
        tempDataSets.push({
          label: "Me",
          data: data.values.me,
          // data: tempData,
          barPercentage: 1,
          // barThickness: 10,
          maxBarThickness: 10,
          // minBarLength: 4,
          // borderColor: "rgb(0, 153, 255)",
          // backgroundColor: "rgba(0, 153, 255, 0.5)",
          backgroundColor:
            targetOption == "me_friends" ||
            targetOption == "me_global" ||
            targetOption == "me_friends_global"
              ? "rgba(0, 153, 255, 0.5)"
              : bgcolors,
          hoverBorderRadius: 4,
          hoverBorderColor: "rgba(0,0,0,0.4)",
          // hoverBackgroundColor: "rgba(0, 153, 255, 0.7)",
        });
      }
      if (data.values.hasOwnProperty("friends")) {
        console.log("friendsDataSet = ", data.values.friends);
        if (
          targetOption !== "me_friends" &&
          targetOption !== "me_global" &&
          targetOption !== "me_friends_global"
        ) {
          tempLegendList = data.values.friends.map((item, index) => {
            return {
              color: bgcolors[index],
              value: item,
              label: newLabels[index],
            };
          });
        }

        tempDataSets.push({
          label: "Friends",
          data: data.values.friends,
          // data: tempData,
          barPercentage: 1,
          // barThickness: 10,
          maxBarThickness: 10,
          // minBarLength: 4,
          // borderColor: "rgb(255, 204, 0)",
          backgroundColor:
            targetOption == "me_friends" ||
            targetOption == "me_global" ||
            targetOption == "me_friends_global"
              ? "rgba(255, 204, 0, 0.7)"
              : bgcolors,
          // backgroundColor: bgcolors,
          hoverBorderRadius: 4,
          hoverBorderColor: "rgba(0,0,0,0.4)",
          // hoverBackgroundColor: "rgba(255, 204, 0, 0.85)",
        });
      }
      if (data.values.hasOwnProperty("global")) {
        console.log("globalDataSet = ", data.values.global);
        if (
          targetOption !== "me_friends" &&
          targetOption !== "me_global" &&
          targetOption !== "me_friends_global"
        ) {
          tempLegendList = data.values.global.map((item, index) => {
            return {
              color: bgcolors[index],
              value: item,
              label: newLabels[index],
            };
          });
        }

        tempDataSets.push({
          label: "Global",
          data: data.values.global,
          // data: tempData,
          barPercentage: 1,
          // barThickness: 10,
          maxBarThickness: 10,
          // minBarLength: 4,
          // borderColor: "rgb(51, 204, 102)",
          backgroundColor:
            targetOption == "me_friends" ||
            targetOption == "me_global" ||
            targetOption == "me_friends_global"
              ? "rgba(51, 204, 102, 0.5)"
              : bgcolors,
          // backgroundColor: bgcolors,
          hoverBorderRadius: 4,
          hoverBorderColor: "rgba(0,0,0,0.4)",
          // hoverBackgroundColor: "rgba(51, 204, 102, 0.7)",
        });
      }
    }

    if (data.element) {
      chartTitle = elementOptions
        .filter((element) => element.value === data.element)
        .pop().label;
    }
    if (data.total_no_of_records) {
      totalRecords = data.total_no_of_records;
    }

    console.log("In outer tempDatasets = ", tempDataSets);

    if (tempDataSets.length > 0) {
      allChartsData.push({
        chart_type: chartOption,
        labels: newLabels,
        datasets: tempDataSets,
        total_records: totalRecords,
        chart_title: chartTitle,
        legend_list: tempLegendList,
      });
    }

    if (allChartsData.length > 0) {
      setFullDatasets(allChartsData);
      // setTimeout(() => {
      //   setLoading(false);
      // }, 1000);
    }

    if (tempDataSets.length > 0) {
      setDataSets(tempDataSets);
    }
    if (datasets.length > 0) {
      console.log("In datasets.length > 0 = ", datasets);
    }
  }

  const initialValues = {
    record_type: "all",
    target: "global",
    element: elementOptions[0],
    start_date: new Date("2021-02-28"),
    end_date: new Date(today),
    sub_cats: [],
    locations_sub_cats: [],
    characters_sub_cats: [],
    interactions_sub_cats: [],
    emotions_sub_cats: [],
    causes_sub_cats: [],
    resolutions_sub_cats: [],
    min_age: ageOptions[0],
    max_age: ageOptions[ageOptions.length - 1],
    gender: "all",
    education: "",
    country: "",
  };
  const analyzeExploreSchema = yup.object({
    target: yup.string().required("Required"),
    element: yup
      .string()
      .transform((_, input) => {
        // console.log("input = ", input);
        return input != null && input.toString ? input.toString() : "";
      })
      .required("Required"),
    start_date: yup.date().required("Required").nullable(),
    end_date: yup.date().required("Required").nullable(),
  });

  const onSubmit = async (values, { resetForm }) => {
    setLoading(true);
    console.log("Analyze Explore Form values: ", values);

    let newStartDate = formatDateYMD(values.start_date);
    let newEndDate = formatDateYMD(values.end_date);
    const newElement = values.element ? values.element.value : "";
    const newEducation = values.education ? values.education.value : "all";
    const newCountry = values.country ? values.country.value : "all";
    const minAge = values.min_age ? +values.min_age.value : 13;
    const maxAge = values.max_age ? +values.max_age.value : 100;
    let new_sub_cats = [];
    if (newElement === "locations") {
      new_sub_cats = values.locations_sub_cats;
      console.log("new_sub_cats =", new_sub_cats);
    }
    if (newElement === "characters") {
      new_sub_cats = values.characters_sub_cats;
    }
    if (newElement === "interactions") {
      new_sub_cats = values.interactions_sub_cats;
    }
    if (newElement === "emotions") {
      new_sub_cats = values.emotions_sub_cats;
    }
    if (newElement === "causes") {
      new_sub_cats = values.causes_sub_cats;
    }
    if (newElement === "resolutions") {
      new_sub_cats = values.resolutions_sub_cats;
    }

    const newValues = {
      // ...values,
      record_type: values.record_type,
      target: values.target,
      element: newElement,
      start_date: newStartDate,
      end_date: newEndDate,
      sub_cats: new_sub_cats,
      age: [minAge, maxAge],
      gender: values.gender,
      education: newEducation,
      country: newCountry,
    };

    console.log("Analyze Explore new_sub_cats: ", new_sub_cats);
    console.log("Analyze Explore New values: ", newValues);

    recordService
      .analyzeExplore(newValues)
      .then((response) => {
        if (response.data.status === "success") {
          console.log("Analyze Explore service res = ", response.data);

          const chart_data = response.data.data.result.chart_data;
          console.log("records = ", chart_data);
          console.log("Labels = ", response.data.data.result.chart_data.labels);

          // let newLabels = response.data.data.result.chart_data.labels.map(
          //   (label) => {
          //     //Capitalize first letter
          //     return label.charAt(0).toUpperCase() + label.slice(1);
          //   }
          // );

          console.log("In onSubmit selectedChart = ", selectedChart);
          console.log(
            "In onSubmit service success - values.chart_options = ",
            values.chart_options
          );

          let temp_chart_data = {
            ...response.data.data.result,
            chart_data: {
              ...chart_data,
              target: values.target,
              chart: values.chart_options,
              element: newElement,
            },
          };
          console.log("temp_chart_data in onSubmit =", temp_chart_data);
          props.setAnalyzedExplore(temp_chart_data);

          // setAnalyzedExplore(chart_data);
          setAnalyzedExploreFilters({
            // record_type:
            //   values.record_type.charAt(0).toUpperCase() +
            //   values.record_type.slice(1),
            record_type: recordRadioOptions
              .filter((opt) => opt.value === newValues.record_type)
              .pop().key,
            target: targetRadioOptions
              .filter((option) => option.value === values.target)
              .pop().key,
            end_date: newEndDate,
            start_date: newStartDate,
            element: newElement.charAt(0).toUpperCase() + newElement.slice(1),
            sub_cats: new_sub_cats,
            age: [minAge, maxAge],
            country: newCountry,
            education: newEducation,
            gender: values.gender,
          });
          // setLabels(newLabels);
          // let tempData = [];
          // if (chart_data.values.hasOwnProperty("me")) {
          //   console.log("MeDataSet = ", chart_data.values.me);
          //   tempData.push({
          //     label: "Me",
          //     data: chart_data.values.me,
          //     barPercentage: 1,
          //     // barThickness: 10,
          //     maxBarThickness: 10,
          //     // minBarLength: 4,
          //     borderColor: "rgb(0, 153, 255)",
          //     backgroundColor: "rgba(0, 153, 255, 0.5)",
          //     hoverBorderRadius: 4,
          //     hoverBackgroundColor: "rgba(0, 153, 255, 0.7)",
          //   });

          //   setMeDataset(chart_data.values.me);
          // }
          // if (chart_data.values.hasOwnProperty("friends")) {
          //   console.log("friendsDataSet = ", chart_data.values.friends);
          //   tempData.push({
          //     label: "Friends",
          //     data: chart_data.values.friends,
          //     barPercentage: 1,
          //     // barThickness: 10,
          //     maxBarThickness: 10,
          //     // minBarLength: 4,
          //     borderColor: "rgb(255, 204, 0)",
          //     backgroundColor: "rgba(255, 204, 0, 0.7)",
          //     hoverBorderRadius: 4,
          //     hoverBackgroundColor: "rgba(255, 204, 0, 0.85)",
          //   });
          //   setFriendsDataset(chart_data.values.friends);
          // }
          // if (chart_data.values.hasOwnProperty("global")) {
          //   console.log("globalDataSet = ", chart_data.values.global);
          //   tempData.push({
          //     label: "Global",
          //     data: chart_data.values.global,
          //     barPercentage: 1,
          //     // barThickness: 10,
          //     maxBarThickness: 10,
          //     // minBarLength: 4,
          //     borderColor: "rgb(51, 204, 102)",
          //     backgroundColor: "rgba(51, 204, 102, 0.5)",
          //     hoverBorderRadius: 4,
          //     hoverBackgroundColor: "rgba(51, 204, 102, 0.7)",
          //   });
          //   setGlobalDataset(chart_data.values.global);
          // }

          // if (tempData.length > 0) {
          //   setDataSets(tempData);
          //   setRecordCount(chart_data.total_no_of_records)
          // }

          handleCollapse("explore_filters_collapse");
          setLoading(false);
          // resetForm({});
        } else if (response.data.status === "failed") {
          console.log("ERROR:", response.data.error);

          setLoading(false);
          setAnalyzedExplore({});
          setAnalyzedExploreFilters({
            record_type: "all",
            target: "global",
            end_date: today,
            start_date: "2021-02-28",
            element: "emotions",
            sub_cats: [
              "happiness",
              "confusion",
              "anxiety",
              "sadness",
              "frustration",
              "helplessness",
              "love",
              "fear",
              "surprise",
              "shame",
              "desire",
              "guilt",
              "no emotion",
              "anger",
              "jealousy",
            ],
            age: [13, 100],
            country: "all",
            education: "all",
            gender: "all",
          });
          // setMeDataset([]);
          // setFriendsDataset([]);
          // setGlobalDataset([]);
          resetForm({});
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Row className="align-items-center position-relative mb2">
        <Col>
          <div class="section_title small_title pe-5">
            Filter experience content to create precise and custom reports.
          </div>
          <Button
            type="button"
            className="btn_theme btn_icon btn_circle btn_pink position-absolute top-0"
            onClick={handleClick}
            aria-controls="explore_filters_collapse"
            aria-expanded={exploreFiltersCollapse}
            style={{ right: "0.5rem" }}
          >
            <BsGearFill />
          </Button>
        </Col>
      </Row>

      <Row className="row-cols-1 align-items-center mb1">
        <Col>
          <div>
            <span className="custom_tag tag_primary_light tag_small">
              Type: {analyzedExploreFilters.record_type}
            </span>
            <span className="custom_tag tag_primary_light tag_small">
              Target: {analyzedExploreFilters.target}
            </span>
            <span className="custom_tag tag_primary_light tag_small">
              Date:
              {analyzedExploreFilters.start_date === "" ? (
                " All"
              ) : (
                <>{` ${analyzedExploreFilters.start_date} - ${analyzedExploreFilters.end_date}`}</>
              )}
            </span>
            <span className="custom_tag tag_primary_light tag_small">
              Element: {analyzedExploreFilters.element}
            </span>
          </div>
          {analyzedExploreFilters.sub_cats.length > 0 && (
            <div>
              <span className="pe-1 fw_sb font-13">
                {analyzedExploreFilters.element} (Sub categories):
              </span>
              {analyzedExploreFilters.sub_cats.map((cat) => (
                <span className="custom_tag tag_primary_light tag_small">
                  {cat}
                </span>
              ))}
            </div>
          )}
        </Col>
      </Row>

      <Row>
        <Col>
          <Collapse in={exploreFiltersCollapse}>
            <div id="explore_filters_collapse">
              <CustomCard className="border-0 shadow1 mt1 mb2 pt2 pb2 px-3">
                <Formik
                  initialValues={initialValues}
                  validationSchema={analyzeExploreSchema}
                  onSubmit={onSubmit}
                  enableReinitialize
                >
                  {(formik) => {
                    // console.log("Formik =", formik);
                    return (
                      <Form
                        className="form_style"
                        onSubmit={formik.handleSubmit}
                      >
                        <div className="">
                          <DashboardSectionTitle
                            iconImg=""
                            title="Type of record"
                            pageTitle={false}
                            className="mb1 small_title"
                          />
                          <Form.Group className="form-group rounded_corners px-2 inline_radio_fields radio_btn_field">
                            <div className="">
                              {recordRadioOptions.map((option) => {
                                return (
                                  <Form.Check
                                    key={option.value}
                                    inline
                                    label={option.key}
                                    name="record_type"
                                    type="radio"
                                    id={"explore-type-" + option.value}
                                    value={option.value}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    checked={
                                      formik.values?.record_type ===
                                      option.value
                                    }
                                  />
                                );
                              })}
                            </div>
                            {formik.touched.record_type &&
                            formik.errors.record_type ? (
                              <p className="error-state-text">
                                {formik.errors.record_type}
                              </p>
                            ) : null}
                          </Form.Group>
                        </div>
                        <div className="">
                          <DashboardSectionTitle
                            iconImg=""
                            title="Select target *"
                            pageTitle={false}
                            className="mb1 small_title"
                          />
                          <Form.Group className="form-group rounded_corners px-2 inline_radio_fields radio_btn_field">
                            <div className="">
                              {targetRadioOptions.map((option) => {
                                return (
                                  <Form.Check
                                    key={option.value}
                                    inline
                                    label={option.key}
                                    name="target"
                                    type="radio"
                                    id={"explore-" + option.value}
                                    value={option.value}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    checked={
                                      formik.values?.target === option.value
                                    }
                                  />
                                );
                              })}
                            </div>
                            {formik.touched.target && formik.errors.target ? (
                              <p className="error-state-text">
                                {formik.errors.target}
                              </p>
                            ) : null}
                          </Form.Group>
                        </div>
                        <Row className="form_style no-gutters">
                          <Col xs={12}>
                            <DashboardSectionTitle
                              iconImg=""
                              title="Select time"
                              pageTitle={false}
                              className="mb1 small_title"
                            />
                          </Col>
                          <Col xs={12} sm={12} md={6}>
                            <Form.Group className="form-group rounded_corners">
                              <DashboardSectionTitle
                                iconImg=""
                                title="Start date"
                                pageTitle={false}
                                className="mb-1 small_title"
                              />
                              <Field name="start_date">
                                {({ form, field }) => {
                                  const { setFieldValue } = form;
                                  const { value } = field;

                                  return (
                                    <DatePicker
                                      id="start_date"
                                      className="form-control"
                                      {...field}
                                      selected={value}
                                      onChange={(val) =>
                                        setFieldValue("start_date", val)
                                      }
                                      dateFormat="yyyy-MM-dd"
                                      maxDate={addDays(
                                        new Date(formik.values.end_date),
                                        0
                                      )}
                                      placeholderText="Start date"
                                      showMonthDropdown
                                      showYearDropdown
                                      dropdownMode="select"
                                      // scrollableMonthYearDropdown
                                      autoComplete="off"
                                    />
                                  );
                                }}
                              </Field>
                              {formik.touched.start_date &&
                              formik.errors.start_date ? (
                                <p className="error-state-text">
                                  {formik.errors.start_date}
                                </p>
                              ) : null}
                            </Form.Group>
                          </Col>
                          <Col xs={12} sm={12} md={6}>
                            <Form.Group className="form-group rounded_corners">
                              <DashboardSectionTitle
                                iconImg=""
                                title="End date"
                                pageTitle={false}
                                className="mb-1 small_title"
                              />
                              <Field name="end_date">
                                {({ form, field }) => {
                                  const { setFieldValue } = form;
                                  const { value } = field;

                                  return (
                                    <DatePicker
                                      id="end_date"
                                      className="form-control"
                                      {...field}
                                      todayButton="Today"
                                      selected={value}
                                      onChange={(val) =>
                                        setFieldValue("end_date", val)
                                      }
                                      dateFormat="yyyy-MM-dd"
                                      minDate={subDays(
                                        new Date(formik.values.start_date),
                                        0
                                      )}
                                      placeholderText="End date"
                                      showMonthDropdown
                                      showYearDropdown
                                      dropdownMode="select"
                                      // scrollableMonthYearDropdown
                                      autoComplete="off"
                                    />
                                  );
                                }}
                              </Field>
                              {formik.touched.end_date &&
                              formik.errors.end_date ? (
                                <p className="error-state-text">
                                  {formik.errors.end_date}
                                </p>
                              ) : null}
                            </Form.Group>
                          </Col>
                        </Row>

                        <DashboardSectionTitle
                          iconImg=""
                          title="Select element"
                          pageTitle={false}
                          className="mb1 small_title"
                        />
                        <Form.Group className="form-group rounded_corners">
                          <Select
                            styles={customStyles}
                            placeholder="Select element"
                            value={formik.values.element}
                            onChange={(value) => {
                              formik.setFieldValue("element", value);
                            }}
                            onBlur={() => {
                              formik.setFieldTouched("element", true);
                            }}
                            isMulti={false}
                            options={elementOptions}
                            name="element"
                          />
                          {formik.touched.element && formik.errors.element ? (
                            <p className="error-state-text">
                              {formik.errors.element}
                            </p>
                          ) : null}
                        </Form.Group>
                        {formik.values.element !== "" && (
                          <DashboardSectionTitle
                            iconImg=""
                            title="Select sub category"
                            pageTitle={false}
                            className="mb1 small_title"
                          />
                        )}
                        {formik.values.element.value == "locations" && (
                          <>
                            {formik.values.characters_sub_cats.length > 0 &&
                              formik.setFieldValue("characters_sub_cats", [])}
                            {formik.values.interactions_sub_cats.length > 0 &&
                              formik.setFieldValue("interactions_sub_cats", [])}
                            {formik.values.emotions_sub_cats.length > 0 &&
                              formik.setFieldValue("emotions_sub_cats", [])}
                            {formik.values.causes_sub_cats.length > 0 &&
                              formik.setFieldValue("causes_sub_cats", [])}
                            {formik.values.resolutions_sub_cats.length > 0 &&
                              formik.setFieldValue("resolutions_sub_cats", [])}
                            {formik.values.locations_sub_cats.length === 0 &&
                              formik.setFieldValue(
                                "locations_sub_cats",
                                locationsList.map((item) => item.value)
                              )}

                            <Form.Group className="form-group rounded_corners px-2 inline_radio_fields radio_btn_field">
                              <div className="">
                                {locationsList.map((option) => {
                                  return (
                                    <Form.Check
                                      key={option.value}
                                      inline
                                      label={option.name}
                                      name="locations_sub_cats"
                                      type="checkbox"
                                      id={
                                        "explore-" +
                                        option.name
                                          .toLowerCase()
                                          .split(" ")
                                          .join("-")
                                      }
                                      value={option.value}
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      checked={
                                        formik.values?.locations_sub_cats.indexOf(
                                          option.value
                                        ) > -1
                                      }
                                    />
                                  );
                                })}
                              </div>
                            </Form.Group>
                          </>
                        )}
                        {formik.values.element.value == "characters" && (
                          <>
                            {formik.values.locations_sub_cats.length > 0 &&
                              formik.setFieldValue("locations_sub_cats", [])}
                            {formik.values.interactions_sub_cats.length > 0 &&
                              formik.setFieldValue("interactions_sub_cats", [])}
                            {formik.values.emotions_sub_cats.length > 0 &&
                              formik.setFieldValue("emotions_sub_cats", [])}
                            {formik.values.causes_sub_cats.length > 0 &&
                              formik.setFieldValue("causes_sub_cats", [])}
                            {formik.values.resolutions_sub_cats.length > 0 &&
                              formik.setFieldValue("resolutions_sub_cats", [])}
                            {formik.values.characters_sub_cats.length === 0 &&
                              formik.setFieldValue(
                                "characters_sub_cats",
                                charactersList.map((item) => item.value)
                              )}

                            <Form.Group className="form-group rounded_corners px-2 inline_radio_fields radio_btn_field">
                              <div className="">
                                {charactersList.map((option) => {
                                  return (
                                    <Form.Check
                                      key={option.value}
                                      inline
                                      label={option.name}
                                      name="characters_sub_cats"
                                      type="checkbox"
                                      id={
                                        "explore-" +
                                        option.name
                                          .toLowerCase()
                                          .split(" ")
                                          .join("-")
                                      }
                                      value={option.value}
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      checked={
                                        formik.values?.characters_sub_cats.indexOf(
                                          option.value
                                        ) > -1
                                      }
                                    />
                                  );
                                })}
                              </div>
                            </Form.Group>
                          </>
                        )}
                        {formik.values.element.value == "interactions" && (
                          <>
                            {formik.values.locations_sub_cats.length > 0 &&
                              formik.setFieldValue("locations_sub_cats", [])}
                            {formik.values.characters_sub_cats.length > 0 &&
                              formik.setFieldValue("characters_sub_cats", [])}
                            {formik.values.emotions_sub_cats.length > 0 &&
                              formik.setFieldValue("emotions_sub_cats", [])}
                            {formik.values.causes_sub_cats.length > 0 &&
                              formik.setFieldValue("causes_sub_cats", [])}
                            {formik.values.resolutions_sub_cats.length > 0 &&
                              formik.setFieldValue("resolutions_sub_cats", [])}
                            {formik.values.interactions_sub_cats.length === 0 &&
                              formik.setFieldValue(
                                "interactions_sub_cats",
                                interactionsList.map((item) => item.value)
                              )}

                            <Form.Group className="form-group rounded_corners px-2 inline_radio_fields radio_btn_field">
                              <div className="">
                                {interactionsList.map((option) => {
                                  return (
                                    <Form.Check
                                      key={option.value}
                                      inline
                                      label={option.name}
                                      name="interactions_sub_cats"
                                      type="checkbox"
                                      id={
                                        "explore-" +
                                        option.name
                                          .toLowerCase()
                                          .split(" ")
                                          .join("-")
                                      }
                                      value={option.value}
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      checked={
                                        formik.values?.interactions_sub_cats.indexOf(
                                          option.value
                                        ) > -1
                                      }
                                    />
                                  );
                                })}
                              </div>
                            </Form.Group>
                          </>
                        )}
                        {formik.values.element.value == "emotions" && (
                          <>
                            {formik.values.locations_sub_cats.length > 0 &&
                              formik.setFieldValue("locations_sub_cats", [])}
                            {formik.values.characters_sub_cats.length > 0 &&
                              formik.setFieldValue("characters_sub_cats", [])}
                            {formik.values.interactions_sub_cats.length > 0 &&
                              formik.setFieldValue("interactions_sub_cats", [])}
                            {formik.values.causes_sub_cats.length > 0 &&
                              formik.setFieldValue("causes_sub_cats", [])}
                            {formik.values.resolutions_sub_cats.length > 0 &&
                              formik.setFieldValue("resolutions_sub_cats", [])}
                            {formik.values.emotions_sub_cats.length === 0 &&
                              formik.setFieldValue(
                                "emotions_sub_cats",
                                emotionsList.map((item) => item.value)
                              )}

                            <Form.Group className="form-group rounded_corners px-2 inline_radio_fields radio_btn_field">
                              <div className="">
                                {emotionsList.map((option) => {
                                  return (
                                    <Form.Check
                                      key={option.value}
                                      inline
                                      label={option.name}
                                      name="emotions_sub_cats"
                                      type="checkbox"
                                      id={
                                        "explore-" +
                                        option.name
                                          .toLowerCase()
                                          .split(" ")
                                          .join("-")
                                      }
                                      value={option.value}
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      checked={
                                        formik.values?.emotions_sub_cats.indexOf(
                                          option.value
                                        ) > -1
                                      }
                                    />
                                  );
                                })}
                              </div>
                            </Form.Group>
                          </>
                        )}

                        {formik.values.element.value == "causes" && (
                          <>
                            {formik.values.locations_sub_cats.length > 0 &&
                              formik.setFieldValue("locations_sub_cats", [])}
                            {formik.values.characters_sub_cats.length > 0 &&
                              formik.setFieldValue("characters_sub_cats", [])}
                            {formik.values.interactions_sub_cats.length > 0 &&
                              formik.setFieldValue("interactions_sub_cats", [])}
                            {formik.values.emotions_sub_cats.length > 0 &&
                              formik.setFieldValue("emotions_sub_cats", [])}
                            {formik.values.resolutions_sub_cats.length > 0 &&
                              formik.setFieldValue("resolutions_sub_cats", [])}
                            {formik.values.causes_sub_cats.length === 0 &&
                              formik.setFieldValue(
                                "causes_sub_cats",
                                causesList.map((item) => item.value)
                              )}

                            <Form.Group className="form-group rounded_corners px-2 inline_radio_fields radio_btn_field">
                              <div className="">
                                {causesList.map((option) => {
                                  return (
                                    <Form.Check
                                      key={option.value}
                                      inline
                                      label={option.name}
                                      name="causes_sub_cats"
                                      type="checkbox"
                                      id={
                                        "explore-" +
                                        option.name
                                          .toLowerCase()
                                          .split(" ")
                                          .join("-")
                                      }
                                      value={option.value}
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      checked={
                                        formik.values?.causes_sub_cats.indexOf(
                                          option.value
                                        ) > -1
                                      }
                                    />
                                  );
                                })}
                              </div>
                            </Form.Group>
                          </>
                        )}

                        {formik.values.element.value == "resolutions" && (
                          <>
                            {formik.values.locations_sub_cats.length > 0 &&
                              formik.setFieldValue("locations_sub_cats", [])}
                            {formik.values.characters_sub_cats.length > 0 &&
                              formik.setFieldValue("characters_sub_cats", [])}
                            {formik.values.interactions_sub_cats.length > 0 &&
                              formik.setFieldValue("interactions_sub_cats", [])}
                            {formik.values.emotions_sub_cats.length > 0 &&
                              formik.setFieldValue("emotions_sub_cats", [])}
                            {formik.values.causes_sub_cats.length > 0 &&
                              formik.setFieldValue("causes_sub_cats", [])}
                            {formik.values.resolutions_sub_cats.length === 0 &&
                              formik.setFieldValue(
                                "resolutions_sub_cats",
                                resolutionsList.map((item) => item.value)
                              )}

                            <Form.Group className="form-group rounded_corners px-2 inline_radio_fields radio_btn_field">
                              <div className="">
                                {resolutionsList.map((option) => {
                                  return (
                                    <Form.Check
                                      key={option.value}
                                      inline
                                      label={option.name}
                                      name="resolutions_sub_cats"
                                      type="checkbox"
                                      id={
                                        "explore-" +
                                        option.name
                                          .toLowerCase()
                                          .split(" ")
                                          .join("-")
                                      }
                                      value={option.value}
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      checked={
                                        formik.values?.resolutions_sub_cats.indexOf(
                                          option.value
                                        ) > -1
                                      }
                                    />
                                  );
                                })}
                              </div>
                            </Form.Group>
                          </>
                        )}

                        {formik.values.target !== "me" && (
                          <>
                            <DashboardSectionTitle
                              iconImg=""
                              title="Demographics"
                              pageTitle={false}
                              className="mb1 small_title"
                            />
                            <DashboardSectionTitle
                              iconImg=""
                              title="Age"
                              pageTitle={false}
                              className="mb1 small_title"
                            />
                            <Row>
                              <Col xs={5}>
                                <Form.Group className="form-group rounded_corners">
                                  <Select
                                    styles={customStyles}
                                    placeholder="Select min age"
                                    value={formik.values.min_age}
                                    onChange={(value) => {
                                      formik.setFieldValue("min_age", value);
                                    }}
                                    onBlur={() => {
                                      formik.setFieldTouched("min_age", true);
                                    }}
                                    isMulti={false}
                                    options={ageOptions}
                                    name="min_age"
                                  />
                                  {formik.touched.min_age &&
                                  formik.errors.min_age ? (
                                    <p className="error-state-text">
                                      {formik.errors.min_age}
                                    </p>
                                  ) : null}
                                </Form.Group>
                              </Col>
                              <Col xs={1}>To</Col>
                              <Col xs={5}>
                                <Form.Group className="form-group rounded_corners">
                                  <Select
                                    styles={customStyles}
                                    placeholder="Select max age"
                                    value={formik.values.max_age}
                                    onChange={(value) => {
                                      formik.setFieldValue("max_age", value);
                                    }}
                                    onBlur={() => {
                                      formik.setFieldTouched("max_age", true);
                                    }}
                                    isMulti={false}
                                    options={ageOptions}
                                    name="max_age"
                                  />
                                  {formik.touched.max_age &&
                                  formik.errors.max_age ? (
                                    <p className="error-state-text">
                                      {formik.errors.max_age}
                                    </p>
                                  ) : null}
                                </Form.Group>
                              </Col>
                            </Row>

                            <div className="">
                              <DashboardSectionTitle
                                iconImg=""
                                title="Gender"
                                pageTitle={false}
                                className="mb1 small_title"
                              />
                              <Form.Group className="form-group rounded_corners px-2 inline_radio_fields radio_btn_field">
                                <div className="">
                                  {genderOptions.map((option) => {
                                    return (
                                      <Form.Check
                                        key={option.value}
                                        inline
                                        label={option.key}
                                        name="gender"
                                        type="radio"
                                        id={"explore-gender-" + option.value}
                                        value={option.value}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        // {...formik.getFieldProps("outcome")}
                                        // value={option.value}
                                        checked={
                                          formik.values?.gender == option.value
                                        }
                                      />
                                    );
                                  })}
                                </div>
                                {formik.touched.gender &&
                                formik.errors.gender ? (
                                  <p className="error-state-text">
                                    {formik.errors.gender}
                                  </p>
                                ) : null}
                              </Form.Group>
                            </div>

                            <DashboardSectionTitle
                              iconImg=""
                              title="Education"
                              pageTitle={false}
                              className="mb1 small_title"
                            />
                            <Row>
                              <Col xs={12}>
                                <Form.Group className="form-group rounded_corners">
                                  <Select
                                    styles={customStyles}
                                    placeholder="Select education"
                                    // value={formik.values.education}
                                    defaultValue={educationOptions[0]}
                                    onChange={(value) => {
                                      formik.setFieldValue("education", value);
                                    }}
                                    onBlur={() => {
                                      formik.setFieldTouched("education", true);
                                    }}
                                    isMulti={false}
                                    options={educationOptions}
                                    name="education"
                                  />
                                  {formik.touched.education &&
                                  formik.errors.education ? (
                                    <p className="error-state-text">
                                      {formik.errors.education}
                                    </p>
                                  ) : null}
                                </Form.Group>
                              </Col>
                            </Row>
                            <DashboardSectionTitle
                              iconImg=""
                              title="Geography"
                              pageTitle={false}
                              className="mb1 small_title"
                            />
                            <Row>
                              <Col xs={12}>
                                <Form.Group className="form-group rounded_corners">
                                  <Select
                                    styles={customStyles}
                                    placeholder="Select country"
                                    // value={formik.values.country}
                                    defaultValue={countryOptions[0]}
                                    onChange={(value) => {
                                      formik.setFieldValue("country", value);
                                    }}
                                    onBlur={() => {
                                      formik.setFieldTouched("country", true);
                                    }}
                                    isMulti={false}
                                    options={countryOptions}
                                    name="country"
                                  />
                                  {formik.touched.country &&
                                  formik.errors.country ? (
                                    <p className="error-state-text">
                                      {formik.errors.country}
                                    </p>
                                  ) : null}
                                </Form.Group>
                              </Col>
                            </Row>
                          </>
                        )}

                        <div className="btn_wrap pb1">
                          <Button
                            key="submit_btn"
                            type="submit"
                            className="btn_theme btn_primary btn_rounded"
                          >
                            {loading ? (
                              <>
                                <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                />{" "}
                                Processing
                              </>
                            ) : (
                              "Submit"
                            )}
                          </Button>

                          <Button
                            key="close_filters_btn"
                            type="button"
                            className="btn_theme btn_light btn_rounded"
                            onClick={() => {
                              handleCollapse("explore_filters_collapse");
                            }}
                          >
                            Cancel
                          </Button>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </CustomCard>
            </div>
          </Collapse>
        </Col>
      </Row>

      {!loading && props.analyzedExplore.hasOwnProperty("chart_data") ? (
        <>
          <hr />
          <Row className="align-items-center position-relative mb2">
            <Col>
              <div class="section_title small_title">
                Analyzed Explore Graphs
              </div>
            </Col>
          </Row>
          {fullDatasets.length > 0 ? (
            <>
              {fullDatasets.map((dataset, index) => (
                <div key={index} className="card shadow1 border-0 p2 mb2">
                  <DashboardBarChart
                    labels={dataset.labels}
                    datasets={dataset.datasets}
                    recordCount={dataset.total_records}
                    chart_title={dataset.chart_title}
                    legend_list={dataset.legend_list}
                    // chartData={dataset}
                  />
                </div>
              ))}
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        <>
          {loading ? (
            <>
              {[0, 1].map((_, i) => (
                <CustomCard
                  key={i}
                  className={`main_card shadow1 border-0 mb2 text-left overflow-hidden`}
                >
                  <Card.Header>
                    <div className="d-flex align-items-center">
                      <div className="flex-shrink-0">
                        <Placeholder as="div" animation="glow">
                          <Placeholder
                            bg="secondary"
                            as="div"
                            style={{ height: "54px", width: "54px" }}
                            className="rounded-circle"
                          />
                        </Placeholder>
                      </div>
                      <div className="flex-grow-1 ms-3 text-left">
                        <div className="w-100">
                          <Placeholder xs={6} bg="secondary" />
                        </div>
                        <div className="w-100">
                          <Placeholder xs={4} bg="secondary" />
                        </div>
                      </div>
                    </div>
                  </Card.Header>
                  <Card.Body className="p2">
                    <div className="w-100 mb-1">
                      <Placeholder xs={9} bg="secondary" />
                    </div>
                    <div className="w-100 mb1">
                      <Placeholder xs={6} bg="secondary" />
                    </div>
                  </Card.Body>
                </CustomCard>
              ))}
            </>
          ) : (
            <CustomCard className="shadow1 border-0 text-center">
              <Card.Body>
                <div className="mb1">
                  <BsFillExclamationTriangleFill
                    style={{
                      color: "#d3af61",
                      fontSize: "2.2rem",
                    }}
                  />
                </div>
                <b>No more records found...</b>
              </Card.Body>
            </CustomCard>
          )}
        </>
      )}

      {/* <Row className="g-0">
        <Col xs={12}>
          {!loading && analyzedExplore.hasOwnProperty("labels") ? (
            <>
              <DashboardBarChart
              labels={labels}
              datasets={datasets}
              recordCount={recordCount}
                          
                        />
            </>
          ) : (
            <>
              {loading ? (
                <>
                  {[0, 1].map((_, i) => (
                    <CustomCard
                      key={i}
                      className={`main_card shadow1 border-0 mb2 text-left overflow-hidden`}
                    >
                      <Card.Header>
                        <div className="d-flex align-items-center">
                          <div className="flex-shrink-0">
                            <Placeholder as="div" animation="glow">
                              <Placeholder
                                bg="secondary"
                                as="div"
                                style={{ height: "54px", width: "54px" }}
                                className="rounded-circle"
                              />
                            </Placeholder>
                          </div>
                          <div className="flex-grow-1 ms-3 text-left">
                            <div className="w-100">
                              <Placeholder xs={6} bg="secondary" />
                            </div>
                            <div className="w-100">
                              <Placeholder xs={4} bg="secondary" />
                            </div>
                          </div>
                        </div>
                      </Card.Header>
                      <Card.Body className="p2">
                        <div className="w-100 mb-1">
                          <Placeholder xs={9} bg="secondary" />
                        </div>
                        <div className="w-100 mb1">
                          <Placeholder xs={6} bg="secondary" />
                        </div>
                      </Card.Body>
                    </CustomCard>
                  ))}
                </>
              ) : (
                <CustomCard className="shadow1 border-0 text-center">
                  <Card.Body>
                    <div className="mb1">
                      <BsFillExclamationTriangleFill
                        style={{
                          color: "#d3af61",
                          fontSize: "2.2rem",
                        }}
                      />
                    </div>
                    <b>No more records found...</b>
                  </Card.Body>
                </CustomCard>
              )}
            </>
          )}
        </Col>
      </Row> */}
    </>
  );
}

export default DashboardAnalyzeExplore;
