import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import "./DashboardFooter.css";

function DashboardFooter() {
  return (
    <footer className="footer_sect">
      <Container>
        <Row>
          <Col xs={10} lg={7}>
            <p>&copy; Copyright 2013-2021 uDreamed. All rights Reserved.</p>
          </Col>
          <Col xs={2} lg={5}>
            <img
              src={`${process.env.PUBLIC_URL}/img/logo-icon.png`}
              width="90"
              className="img-fluid mx-auto footer_logo"
              alt="uDreamed Icon"
            />
          </Col>
        </Row>
      </Container>
      <Button type="button" className="back_to_top" title="Back to Top">
        <i className="fa fa-angle-up"></i>
      </Button>
    </footer>
  );
}

export default DashboardFooter;
