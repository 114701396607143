import React, { useState, useCallback, useContext, useRef } from "react";
import {
  Form,
  Button,
  Row,
  Col,
  Spinner,
  Card,
  Placeholder,
} from "react-bootstrap";
import { debounce } from "lodash";
import { BsFillStarFill, BsX, BsXCircleFill } from "react-icons/bs";
import "./SearchNetworkFriends.css";

import CustomCard from "../../../ui/CustomCard/CustomCard";
import SearchedUserCard from "./SearchedUserCard";
import PlaceholderCard from "../../../ui/PlaceholderCard/PlaceholderCard";

//Context
import AppContext from "../../../AppContext";

//Service
import recordService from "../../../services/Record";

const friendList = [
  { id: 1, name: "John Doe", profileImg: "consultant3.jpg" },
  { id: 2, name: "Chris Powell", profileImg: "profile2.png" },
  { id: 3, name: "Adam Smith", profileImg: "consultant2.jpg" },
  // { id: 4, name: "Jason Berman", profileImg: "consultant1.jpg" },
];
function SearchNetworkFriends(props) {
  let friends = props.friends;
  // const { friends } = useContext(AppContext);
  const [result, setResult] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);

  const searchInputRef = useRef();

  console.log("Friends in SearchNetworkFriends = ", friends);

  const handleClearField = () => {
    // setInputValue("");
    searchInputRef.current.value = "";
    setResult([]);
  };

  const handleChange = debounce((value) => {
    console.log("This is function from lodash...");
    console.log("Search text =", value);
    setLoading(true);
    console.log(value.trim());
    if (value.trim().length > 0) {
      recordService
        .searchUsers(value.trim())
        .then((response) => {
          if (response.data.status === "success") {
            console.log("Response all users =", response.data);
            const users = response.data.data.result.users;

            // console.log("Friends =", friends);

            // const newUsers = users.map((user) => {
            //   let temp = user;
            //   if (friends.length > 0) {
            //     friends.forEach((element) => {
            //       if (element.id === temp.user_id) {
            //         temp.isFriend = true;
            //       }
            //     });
            //   } else {
            //     temp.isFriend = false;
            //   }
            //   return temp;
            // });

            // console.log("newUsers =", newUsers);

            setResult(users);
            setLoading(false);
          }
          if (response.data.status === "failed") {
            console.log("ERROR:", response.data.error);
          }
        })
        .catch((err) => {
          console.log("There is a problem in getting all friends...", err);
        });
    } else {
      setResult([]);
      setLoading(false);
    }
  }, 500);

  // const debounce = (func) => {
  //   let timer;
  //   return function (...args) {
  //     const context = this;
  //     if (timer) clearTimeout(timer);

  //     timer = setTimeout(() => {
  //       timer = null;
  //       func.apply(context, args);
  //     }, 450);
  //   };
  // };

  // const optimizedHandleChange = useCallback(debounce(handleChange));
  // const optimizedHandleChange = debounce(handleChange);

  return (
    <div className="form_style">
      <Form.Group className="form-group rounded_corners search_field autocomplete_field">
        <Form.Control
          type="text"
          name="searchInput"
          onChange={(e) => {
            // setInputValue(e.target.value);
            handleChange(e.target.value);
            // optimizedHandleChange(e.target.value);
          }}
          ref={searchInputRef}
          // value={inputValue}
          placeholder="Search"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/img/search-icon.png)`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center right 20px",
          }}
          autoComplete="off"
        />
        {searchInputRef?.current?.value.length > 0 && (
          <Button
            onClick={handleClearField}
            className={`btn_theme btn_icon btn_auto_width btn_light btn_circle`}
            type="button"
          >
            <BsX />
          </Button>
        )}
        {/* {result?.length > 0 && (
          <Button
            onClick={handleClearField}
            className={`btn_theme btn_icon btn_auto_width btn_light btn_circle`}
            type="button"
          >
            <BsX />
          </Button>
        )} */}
        {loading && (
          <>
            <Row className="row-cols-1 row-cols-sm-2 g-3 mt2">
              {[0, 1, 2].map((_, i) => (
                <Col key={i}>
                  <PlaceholderCard type="friends" />
                </Col>
              ))}
            </Row>
          </>
        )}
        {!loading &&
          !result?.length > 0 &&
          searchInputRef?.current?.value.length > 0 && (
            <CustomCard className={`main_card border-0 text-center mt2 mb2`}>
              <Card.Body>
                <b>No matching records found.</b>
              </Card.Body>
            </CustomCard>
          )}
        {!loading && result?.length > 0 && (
          <>
            <Row className="row-cols-1 row-cols-sm-2 g-3 mt2">
              {result?.map((item, index) => (
                <Col key={index}>
                  <SearchedUserCard
                    user={item}
                    // removeFriend={props.removeFriend}
                    handleAction={props.handleAction}
                    // handleRemoveFriend={props.handleRemoveFriend}
                    setSelectedUser={props.setSelectedUser}
                    handleShow={props.handleShow}
                  />
                </Col>
              ))}
            </Row>
          </>
        )}

        {/* <div className="autocomplete_result">
          {friendList.map((item, index) => (
            <div
              key={index}
              className="autocomplete_result_item"
              onClick={() => handleItemClick(item)}
            >
              <img
                src={`${process.env.PUBLIC_URL}/img/team/Sandeep-Pandit.png`}
                className="rounded-circle"
                alt="Profile Image"
              />
              {item.name}
              <Button
                onClick={handleClearField}
                className={`btn_theme btn_auto_width btn_secondary btn_rounded btn_small`}
                type="button"
              >
                Connect
              </Button>
            </div>
          ))}
        </div> */}

        {/* {result?.length > 0 && (
          <div className="autocomplete_result">
            {result?.map((item, i) => (
              <div
                key={i}
                className="autocomplete_result_item"
                onClick={() => handleItemClick(item)}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/img/team/Sandeep-Pandit.png`}
                  className="rounded-circle"
                  alt="Profile Image"
                />
                <img src={item?.avatar} className="" alt="User profile" />
                {item.user_id} | {item.name}
                {item.id} | {item.name}
                <Button
                  onClick={handleClearField}
                  className={`btn_theme btn_auto_width btn_secondary btn_rounded btn_small`}
                  type="button"
                >
                  Connect
                </Button>
              </div>
            ))}
          </div>
        )} */}
      </Form.Group>
    </div>
  );
}

export default SearchNetworkFriends;
