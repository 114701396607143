import React, { useState, useContext } from "react";
import { Form, Button, Spinner } from "react-bootstrap";
import CustomCard from "../../../ui/CustomCard/CustomCard";
import FieldError from "../../../ui/FieldError/FieldError";
import { Formik } from "formik";
import * as yup from "yup";
import Select from "react-select";
import { handleAlert, handleCustomSelectStyles } from "../../../util/util";

//Context
import AppContext from "../../../AppContext";
import ThemeContext from "../../../ThemeContext";

//Service
import recordService from "../../../services/Record";

function Share(props) {
  const { friends, getData, getUserProfileData } = useContext(AppContext);
  const { theme } = useContext(ThemeContext);
  const [loading, setLoading] = useState(false);

  //React select styles
  const customStyles = handleCustomSelectStyles(theme);
  
  // const customStyles = {
  //   control: (provided, state) => ({
  //     ...provided,
  //     border: theme === "dark" ? "1px solid #666" : "1px solid #ddd",
  //     borderRadius: "30px",
  //     minHeight: "48px",
  //     backgroundColor: theme === "dark" ? "#333" : "#fff",
  //     color: "inherit",
  //     "&:focus": {
  //       borderWidth: "1px",
  //       borderColor: "#39b3ed",
  //       boxShadow: "none",
  //     },
  //     "&:hover": {
  //       borderColor: theme === "dark" ? "#666" : "#ddd",
  //     },
  //   }),
  //   singleValue: (provided) => ({
  //     ...provided,
  //     color: "inherit",
  //   }),
  //   placeholder: (provided) => ({
  //     ...provided,
  //     color: "#ffffff",
  //   }),
  //   menuList: (provided) => ({
  //     ...provided,
  //     backgroundColor: theme === "dark" ? "#444" : "#fff",
  //     padding: 0,
  //   }),
  //   option: (provided, { isFocused, isSelected }) => ({
  //     ...provided,
  //     background: isFocused ? "#999" : isSelected ? "#0991f6" : undefined,
  //   }),
  //   valueContainer: (provided, state) => ({
  //     ...provided,
  //     padding: "2px 12px",
  //     color: "inherit",
  //     zIndex: 1,
  //   }),
  // };


  const friendOptions = friends.map((friend) => {
    return { label: friend.name, value: friend.id };
  });
  //   console.log("Users : ", friendOptions);

  const initialValues = {
    friends: [],
    message: "",
  };

  const shareRecordSchema = yup.object({
    friends: yup
      .array()
      .min(1, "Atleast 1 user required")
      .of(
        yup.object().shape({
          label: yup.string().required(),
          value: yup.string().required(),
        })
      ),
    groups: yup.array().of(
      yup.object().shape({
        label: yup.string().required(),
        value: yup.string().required(),
      })
    ),
  });

  const onSubmit = async (values, { resetForm }) => {
    console.log("Share record Form Values :", values);
    setLoading(true);
    const newFriends = values.friends.map((friend) => Number(friend.value));
    // console.log("New Memebrs=", newUsers, " Type = ", typeof newUsers);

    const newValues = {
      ...values,
      record_id: props.record_id,
      friends: newFriends,
      groups: [],
    };
    // console.log("New values in Compose =", newValues);

    recordService
      .shareRecord(newValues)
      .then((response) => {
        if (response.data.status === "success") {
          console.log(response.data);
          handleAlert("Success! Your record is shared.", "success");

          setLoading(false);
          resetForm({});
          props.handleCollapse("share");
          //fetch new context data
          getData();
          //Get Profile Stats
          getUserProfileData();
        } else if (response.data.status === "failed") {
          // console.log("ERROR:", response.data.error);
          handleAlert("Error! Failed to share record.", "error");
          setLoading(false);
          resetForm({});
          props.handleCollapse("share");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      {/* <CustomCard className="border-0"> */}
      <div>
        <div className="form_style">
          <Formik
            initialValues={initialValues}
            validationSchema={shareRecordSchema}
            onSubmit={onSubmit}
            //   enableReinitialize
          >
            {(formik) => {
              return (
                <Form onSubmit={formik.handleSubmit}>
                  <Form.Group className="form-group rounded_corners px-1">
                    {/* <Form.Label>Add Recipient(s)</Form.Label> */}
                    <Select
                      styles={customStyles}
                      placeholder="Select Friend"
                      value={formik.values.friends}
                      onChange={(value) => {
                        formik.setFieldValue("friends", value);
                      }}
                      onBlur={() => {
                        formik.setFieldTouched("friends", true);
                      }}
                      isMulti={true}
                      options={friendOptions}
                      name="friends"
                    />
                    <FieldError
                      valid={
                        formik.touched.friends && formik.errors.friends
                          ? true
                          : false
                      }
                      text={formik.errors.friends}
                    />
                    
                  </Form.Group>
                  
                  <div className="btn_wrap">
                    <Button
                      type="submit"
                      className="btn btn_theme btn_small btn_primary btn_rounded"
                    >
                      {loading ? (
                        <>
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />{" "}
                          Sharing
                        </>
                      ) : (
                        "Share Record"
                      )}
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>  
      {/* </CustomCard> */}
    </>
  );
}

export default Share;
