import React, { useState, useEffect } from "react";
import {
  Card,
  Row,
  Col,
  Form,
  Button,
  Spinner,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import CustomCard from "../../../ui/CustomCard/CustomCard";
import DashboardSectionTitle from "../DashboardSectionTitle/DashboardSectionTitle";
import { Field, Formik } from "formik";
import * as yup from "yup";
import { handleAlert } from "../../../util/util";

//Service
import recordService from "../../../services/Record";
import FieldError from "../../../ui/FieldError/FieldError";
import SubscriptionPlansList from "./SubscriptionPlansList";
import UserSubscriptionList from "./UserSubscriptionList";

function SubscriptionContent(props) {
  const [loading, setLoading] = useState(false);

  return (
    <>
      <CustomCard className={`main_card border-0 mb2`}>
        <Card.Body>
          <div className="font-16 fw_b">Subscription Plans for Offering Therapy Services:</div>
          <SubscriptionPlansList
            loading={props.loading}
            subscriptionPlans={props.subscriptionPlans}
          />
          <hr className="mt-4 mb-4" />
          <div className="font-16 fw_b">User Subscriptions:</div>
          <UserSubscriptionList userSubscriptions={props.userSubscriptions} />
        </Card.Body>
      </CustomCard>
    </>
  );
}

export default SubscriptionContent;
