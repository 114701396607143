import React from "react";
import MessageCard from "./MessageCard";
import SimpleMessageCard from "../../../ui/SimpleMessageCard/SimpleMessageCard";

function MessagesSent(props) {
  const actions = ["forward"];

  function handleForward(e) {
    let actionBody = {
      action: "message_compose",
      data: {
        message: e.message,
      },
    };
    props.handleAction(actionBody);
  }

  return (
    <div class="mt2 mb2">
      {props.sentMessages.length > 0 && (
        <>
          {props.sentMessages.map((msg, index) => (
            <MessageCard
              key={index}
              actions={actions}
              handleForward={handleForward}
              msg={msg}
              type="sent"
            />
          ))}
        </>
      )}
      {!props.sentMessages.length > 0 && (
        <SimpleMessageCard type="warning" allowIcon={true} />
      )}
    </div>
  );
}

export default MessagesSent;
