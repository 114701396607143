import React from "react";
import { Modal } from "react-bootstrap";
import "./SocialUserRegistrationModal.css";
import SocialUserRegister from "../../components/Login/SocialUserRegister";
import { BsPencilSquare } from "react-icons/bs";

function SocialUserRegistrationModal(props) {
  return (
    <>
      <Modal
        centered
        size="lg"
        className="theme-modal update-settings-modal"
        show={props.show.isVisible}
        onHide={props.handleClose}
      >
        <Modal.Header className="pb-2" closeButton>
          <Modal.Title className="d-flex align-items-center">
            <div className="heading-icon-wrap flex-shrink-0">
              <span className="heading-icon d-flex justify-content-center align-items-center rounded-circle font-24">
                <BsPencilSquare />
              </span>
            </div>
            <div className="heading flex-grow-1 ms-3">Update & Register</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-helper-text mb-3">
            Please update your complete profile
          </div>
          <SocialUserRegister
            handleClose={props.handleClose}
            currentUser={props.currentUser}
            isAddMode={false}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default SocialUserRegistrationModal;
