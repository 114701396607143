import React from "react";
import { Card } from "react-bootstrap";
import CustomCard from "../../../ui/CustomCard/CustomCard";
import "./DashboardAdvertisement.css";

function DashboardAdvertisement() {
  return (
    <CustomCard className={`neuro_ad_card main_card shadow1 border-0 mb2`}>
      <Card.Body className="d-flex justify-content-center align-items-center">
        <div className="img_container">
          <img
            src={`${process.env.PUBLIC_URL}/img/neuro-advertising-logo.png`}
            className="img-fluid mx-auto"
            alt="neuro-advertising-logo"
          />
        </div>
      </Card.Body>
    </CustomCard>
  );
}

export default DashboardAdvertisement;
