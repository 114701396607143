import React, { useEffect, useRef } from "react";
import {
  Row,
  Col,
  Card,
  Form,
  Button,
  Dropdown,
  Placeholder,
} from "react-bootstrap";
import CustomCard from "../../../ui/CustomCard/CustomCard";
import styles from "./DashboardDictionary.module.css";
import DictionaryCard from "./DictionaryCard";
import PlaceholderCard from "../../../ui/PlaceholderCard/PlaceholderCard";
import { debounce } from "lodash";
import { BsX, BsThreeDotsVertical, BsFilter, BsType } from "react-icons/bs";
import { MdOutlineSortByAlpha, MdSortByAlpha } from "react-icons/md";
import { TbSortAscendingLetters } from "react-icons/tb";
import { TiSortAlphabetically } from "react-icons/ti";

//Service
import recordService from "../../../services/Record";

function DashboardDictionary(props) {
  const searchInputRef = useRef();

  const alpha = Array.from(Array(26)).map((e, i) => i + 65);
  const alphabets = alpha.map((code) => String.fromCharCode(code));

  const handleClick = (item) => {
    console.log("Clicked Item =", item);
    props.getAllDictionaryWords(item);
  };

  const handleClearField = () => {
    // setInputValue("");
    searchInputRef.current.value = "";
    // props.getAllDictionaryWords();
    props.getAllRandomDictionaryWords();
    // setResult([]);
  };

  const handleChange = debounce((value) => {
    console.log("This is function from lodash...");
    console.log("Search text =", value);
    // setLoading(true);
    if (value.trim().length > 0) {
      console.log(value.trim());
      props.searchAllDictionaryWords(value.trim());
    } else {
      // props.getAllDictionaryWords();
      props.getAllRandomDictionaryWords();
      // setLoading(false);
    }
  }, 500);

  return (
    <>
      <CustomCard className={`main_card shadow1 border-0 mt2 mb2`}>
        <Card.Body>
          <Row className="align-items-center position-relative mb-1">
            <Col>
              <div class="section_title">Filter by letter </div>
            </Col>
            <Dropdown
              align="end"
              className="position-absolute top-0 p-0"
              style={{ right: "0.5rem", width: "auto" }}
            >
              <Dropdown.Toggle
                className="btn_theme btn_light btn_rounded btn_icon down_icon_hidden"
                id="dropdown-basic"
              >
                <TiSortAlphabetically className="font-20" />
              </Dropdown.Toggle>

              <Dropdown.Menu style={{ width: 240 }}>
                <div className="d-flex flex-wrap px-2">
                  <Row className="row-cols-6 g-2">
                    {alphabets.map((item) => {
                      return (
                        <Col className="text-center" key={item}>
                          <Dropdown.Item
                            as="button"
                            className="font-14 btn btn_theme btn_gray btn_icon"
                            onClick={() => {
                              handleClick(item);
                            }}
                          >
                            {item}
                          </Dropdown.Item>
                        </Col>
                      );
                    })}
                  </Row>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </Row>
          <div className="d-flex justify-content-center text_gray6">
            <strong>OR</strong>
            {/* <ul className={styles.alphabet_filter_list}>
              {alphabets.map((item) => {
                return (
                  <li
                    key={item}
                    className={styles.alphabet_filter_list_item}
                    onClick={() => {
                      handleClick(item);
                    }}
                  >
                    {item}
                  </li>
                );
              })}
            </ul> */}
          </div>
          <div className="form_style pt1 pb1">
            {/* <Form> */}
            <Form.Group className="form-group rounded_corners search_field autocomplete_field mb-0">
              <Form.Control
                type="text"
                name="searchInput"
                onChange={(e) => {
                  handleChange(e.target.value);
                }}
                ref={searchInputRef}
                placeholder="Search"
                style={{
                  backgroundImage: `url(${process.env.PUBLIC_URL}/img/search-icon.png)`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center right 20px",
                }}
                autoComplete="off"
              />
              {searchInputRef?.current?.value.length > 0 && (
                <Button
                  onClick={handleClearField}
                  className={`btn_theme btn_icon btn_auto_width btn_light btn_circle`}
                  type="button"
                >
                  <BsX />
                </Button>
              )}
            </Form.Group>
            {/* </Form> */}
          </div>
        </Card.Body>
      </CustomCard>
      {props.loading && (
        <>
          <div className="d-grid gap-0">
            {[0, 1, 2].map((_, i) => (
              <PlaceholderCard key={i} type="dictionary" />
            ))}
          </div>
        </>
      )}
      {!props.loading &&
        !props.dictionaryWords?.length > 0 &&
        searchInputRef?.current?.value.length > 0 && (
          <CustomCard className={`main_card border-0 text-center mt2 mb2`}>
            <Card.Body>
              <b>No more matching words found.</b>
            </Card.Body>
          </CustomCard>
        )}
      {!props.loading && props.dictionaryWords?.length > 0 && (
        <>
          {props.dictionaryWords?.map((word) => {
            return (
              <DictionaryCard
                key={word.dictionary_id}
                word={word}
                isVoted={word.is_voted}
                getAllRandomDictionaryWords={props.getAllRandomDictionaryWords}
                getUserProfileData={props.getUserProfileData}
              />
            );
          })}
        </>
      )}
      {/* {props.dictionaryWords?.map((word) => {
            return <DictionaryCard key={word.dictionary_id} word={word} />;
          })} */}
      {/* {wordsList.map((word) => {
            return <DictionaryCard key={word.id} word={word} />;
          })} */}
      {/* </Card.Body>
      </CustomCard> */}
    </>
  );
}

export default DashboardDictionary;
