const emotionsList = [
    {
        id: "emo1",
        name: "Happiness",
        value: "happiness",
        imgUrl: "emotions/happiness.png",
    },
    {
      id: "emo2",
      name: "Confusion",
      value: "confusion",
      imgUrl: "emotions/confusion.png",
    },
    {
      id: "emo3",
      name: "Anxiety",
      value: "anxiety",
      imgUrl: "emotions/anxiety.png",
    },
    {
      id: "emo4",
      name: "Sadness",
      value: "sadness",
      imgUrl: "emotions/sadness.png",
    },
    {
      id: "emo5",
      name: "Frustration",
      value: "frustration",
      imgUrl: "emotions/frustration.png",
    },
    {
      id: "emo6",
      name: "Helplessness",
      value: "helplessness",
      imgUrl: "emotions/helplessness.png",
    },
    {
      id: "emo7",
      name: "Love",
      value: "love",
      imgUrl: "emotions/love.png",
    },
    {
      id: "emo8",
      name: "Fear",
      value: "fear",
      imgUrl: "emotions/fear.png",
    },
    {
      id: "emo9",
      name: "Surprise",
      value: "surprise",
      imgUrl: "emotions/surprise.png",
    },
    {
      id: "emo10",
      name: "Shame",
      value: "shame",
      imgUrl: "emotions/shame.png",
    },
    {
      id: "emo11",
      name: "Desire",
      value: "desire",
      imgUrl: "emotions/desire.png",
    },
    {
      id: "emo12",
      name: "Guilt",
      value: "guilt",
      imgUrl: "emotions/guilt.png",
    },
    {
      id: "emo13",
      name: "No emotion",
      value: "no emotion",
      imgUrl: "emotions/no-emotion.png",
    },
    {
      id: "emo14",
      name: "Anger",
      value: "anger",
      imgUrl: "emotions/anger.png",
    },
    {
      id: "emo15",
      name: "Jealousy",
      value: "jealousy",
      imgUrl: "emotions/jealousy.png",
    },
];
const charactersList = [
    {
        id: "ch1",
        name: "Father",
        value: "father",
        imgUrl: "characters/father.png",
    },
    {
        id: "ch2",
        name: "Mother",
        value: "mother",
        imgUrl: "characters/mother.png",
    },
    {
        id: "ch3",
        name: "Sister",
        value: "sister",
        imgUrl: "characters/sister.png",
    },
    {
        id: "ch4",
        name: "Brother",
        value: "brother",
        imgUrl: "characters/brother.png",
    },
    {
        id: "ch5",
        name: "Partner",
        value: "partner",
        imgUrl: "characters/partner.png",
    },
    {
        id: "ch6",
        name: "Romantic interest (not partner)",
        value: "romantic interest (not partner)",
        imgUrl: "characters/romantic-interest-(not-partner).png",
    },
    {
        id: "ch7",
        name: "Crowd",
        value: "crowd",
        imgUrl: "characters/crowd.png",
    },
    {
        id: "ch8",
        name: "Character unknown",
        value: "character unknown",
        imgUrl: "characters/character-unknown.png",
    },
    {
        id: "ch9",
        name: "Daughter",
        value: "daughter",
        imgUrl: "characters/daughter.png",
    },
    {
        id: "ch10",
        name: "Son",
        value: "son",
        imgUrl: "characters/son.png",
    },
    {
        id: "ch11",
        name: "Boss",
        value: "boss",
        imgUrl: "characters/boss.png",
    },
    {
        id: "ch12",
        name: "Colleague",
        value: "colleague",
        imgUrl: "characters/colleague.png",
    },
    {
        id: "ch13",
        name: "Deceased person",
        value: "deceased person",
        imgUrl: "characters/deceased-person.png",
    },
    {
        id: "ch14",
        name: "Classmate",
        value: "classmate",
        imgUrl: "characters/classmate.png",
    },
    {
        id: "ch15",
        name: "Pet or animal",
        value: "pet or animal",
        imgUrl: "characters/pet-or-animal.png",
    },
    {
        id: "ch16",
        name: "Roommate",
        value: "roommate",
        imgUrl: "characters/roommate.png",
    },
    {
        id: "ch17",
        name: "Fictional character",
        value: "fictional character",
        imgUrl: "characters/fictional-character.png",
    },
    {
        id: "ch18",
        name: "Another Character",
        value: "another character",
        imgUrl: "characters/another-character.png",
    },
];
const interactionsList = [
    {
        id: "in1",
        name: "Friendly",
        value: "friendly",
        imgUrl: "interactions/friendly.png",
    },
    {
        id: "in2",
        name: "Aggressive (as aggressor)",
        value: "aggressive (as aggressor)",
        imgUrl: "interactions/aggressive-(as-aggressor).png",
    },
    {
        id: "in3",
        name: "Aggressive (as victim)",
        value: "aggressive (as victim)",
        imgUrl: "interactions/aggressive-(as-victim).png",
    },
    {
        id: "in4",
        name: "Competitive",
        value: "competitive",
        imgUrl: "interactions/competitive.png",
    },
    {
        id: "in5",
        name: "Romantic",
        value: "romantic",
        imgUrl: "interactions/romantic.png",
    },
    {
        id: "in6",
        name: "Sexual",
        value: "sexual",
        imgUrl: "interactions/sexual.png",
    },
    {
        id: "in7",
        name: "Spiritual",
        value: "spiritual",
        imgUrl: "interactions/spiritual.png",
    },
];
const locationsList = [
    {
        id: "loc1",
        name: "At home",
        value: "at home",
        imgUrl: "locations/at-home.png",
    },
    {
        id: "loc2",
        name: "At another's home",
        value: "at another's home",
        imgUrl: "locations/at-another's-home.png",
    },
    {
        id: "loc3",
        name: "Place of worship",
        value: "place of worship",
        imgUrl: "locations/place-of-worship.png",
    },
    {
        id: "loc4",
        name: "At school",
        value: "at school",
        imgUrl: "locations/at-school.png",
    },
    {
        id: "loc5",
        name: "In a vehicle",
        value: "in a vehicle",
        imgUrl: "locations/in-a-vehicle.png",
    },
    {
        id: "loc6",
        name: "At work",
        value: "at work",
        imgUrl: "locations/at-work.png",
    },
    {
        id: "loc7",
        name: "Unfamiliar or unknown place",
        value: "unfamiliar or unknown place",
        imgUrl: "locations/unfamiliar-or-unknown-place.png",
    },
    {
        id: "loc8",
        name: "In prison or police office",
        value: "in prison or police office",
        imgUrl: "locations/in-prison-or-police-office.png",
    },
    {
        id: "loc9",
        name: "At a fictional location",
        value: "at a fictional location",
        imgUrl: "locations/at-a-fictional-location.png",
    },
    {
        id: "loc10",
        name: "At the hospital",
        value: "at the hospital",
        imgUrl: "locations/at-the-hospital.png",
    },
    {
        id: "loc11",
        name: "Outdoors",
        value: "outdoors",
        imgUrl: "locations/outdoors.png",
    },
    {
        id: "loc12",
        name: "Abroad",
        value: "abroad",
        imgUrl: "locations/abroad.png",
    },
    {
        id: "loc13",
        name: "Other",
        value: "other",
        imgUrl: "locations/other.png",
    },
];
const causesList = [
    {
        id: "ca1",
        name: "Interpersonal conflict",
        value: "interpersonal conflict",
        imgUrl: "causes/interpersonal-conflict.png",
    },
    {
        id: "ca2",
        name: "Internal conflict",
        value: "internal conflict",
        imgUrl: "causes/internal-conflict.png",
    },
    {
        id: "ca3",
        name: "Family concerns",
        value: "family concerns",
        imgUrl: "causes/family-concerns.png",
    },
    {
        id: "ca4",
        name: "Relationship concerns",
        value: "relationship concerns",
        imgUrl: "causes/relationship-concerns.png",
    },
    {
        id: "ca5",
        name: "Self image",
        value: "self image",
        imgUrl: "causes/self-image.png",
    },
    {
        id: "ca6",
        name: "Recent stress or pressure",
        value: "recent stress or pressure",
        imgUrl: "causes/recent-stress-or-pressure.png",
    },
    {
        id: "ca7",
        name: "A nagging problem",
        value: "a nagging problem",
        imgUrl: "causes/a-nagging-problem.png",
    },
    {
        id: "ca8",
        name: "Something seen recently",
        value: "something seen recently",
        imgUrl: "causes/something-seen-recently.png",
    },
    {
        id: "ca9",
        name: "Upcoming event",
        value: "upcoming event",
        imgUrl: "causes/upcoming-event.png",
    },
    {
        id: "ca10",
        name: "Job concerns",
        value: "job concerns",
        imgUrl: "causes/job-concerns.png",
    },
    {
        id: "ca11",
        name: "Financial concerns",
        value: "financial concerns",
        imgUrl: "causes/financial-concerns.png",
    },
    {
        id: "ca12",
        name: "Health issues",
        value: "health issues",
        imgUrl: "causes/health-issues.png",
    },
    {
        id: "ca13",
        name: "Positive feelings for someone",
        value: "positive feelings for someone",
        imgUrl: "causes/positive-feelings-for-someone.png",
    },
    {
        id: "ca14",
        name: "Negative feelings for someone",
        value: "negative feelings for someone",
        imgUrl: "causes/negative-feelings-for-someone.png",
    },
    {
        id: "ca15",
        name: "Fear",
        value: "fear",
        imgUrl: "causes/fear.png",
    },
];
const resolutionsList = [
    {
        id: "res1",
        name: "Take no action",
        value: "take no action",
        imgUrl: "resolutions/take-no-action.png",
    },
    {
        id: "res2",
        name: "Exercise",
        value: "exercise",
        imgUrl: "resolutions/exercise.png",
    },
    { id: "res3", name: "Diet", value: "diet", imgUrl: "resolutions/diet.png" },
    {
        id: "res4",
        name: "Quit a bad habit",
        value: "quit a bad habit",
        imgUrl: "resolutions/quit-a-bad-habit.png",
    },
    {
        id: "res5",
        name: "Address internal conflict",
        value: "address internal conflict",
        imgUrl: "resolutions/address-internal-conflict.png",
    },
    {
        id: "res6",
        name: "Address external conflict",
        value: "address external conflict",
        imgUrl: "resolutions/address-external-conflict.png",
    },
    {
        id: "res7",
        name: "Spend time with someone",
        value: "spend time with someone",
        imgUrl: "resolutions/spend-time-with-someone.png",
    },
    {
        id: "res8",
        name: "Socialize more",
        value: "socialize more",
        imgUrl: "resolutions/socialize-more.png",
    },
    {
        id: "res9",
        name: "Get revenge",
        value: "get revenge",
        imgUrl: "resolutions/get-revenge.png",
    },
    {
        id: "res10",
        name: "Be open minded",
        value: "be open minded",
        imgUrl: "resolutions/be-open-minded.png",
    },
    {
        id: "res11",
        name: "Educate yourself",
        value: "educate yourself",
        imgUrl: "resolutions/educate-yourself.png",
    },
    {
        id: "res12",
        name: "Consult a therapist",
        value: "consult a therapist",
        imgUrl: "resolutions/consult-a-therapist.png",
    },
    {
        id: "res13",
        name: "Warn someone",
        value: "warn someone",
        imgUrl: "resolutions/warn-someone.png",
    },
    {
        id: "res14",
        name: "Change plans",
        value: "change plans",
        imgUrl: "resolutions/change-plans.png",
    },
    {
        id: "res15",
        name: "Take more risks",
        value: "take more risks",
        imgUrl: "resolutions/take-more-risks.png",
    },
    {
        id: "res16",
        name: "Face fear(s)",
        value: "face fear(s)",
        imgUrl: "resolutions/face-fear(s).png",
    },
    {
        id: "res17",
        name: "Take other action",
        value: "take other action",
        imgUrl: "resolutions/take-other-action.png",
    },
];

export {emotionsList, charactersList, interactionsList, locationsList, causesList, resolutionsList};