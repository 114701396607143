import React from "react";
import { useHistory, Redirect } from "react-router-dom";
import { Card, Button, Badge, Spinner } from "react-bootstrap";
import CustomCard from "../../../ui/CustomCard/CustomCard";
import "./SubscriptionCard.css";
import {
  BsFillCheckCircleFill,
  BsFillStarFill,
  BsFillExclamationCircleFill,
  BsFillExclamationTriangleFill,
  BsClockHistory,
  BsClockFill,
  BsClock,
} from "react-icons/bs";
//   import "./ConsultingSessionCard.css";
import { handleAlert } from "../../../util/util";

//Service
import recordService from "../../../services/Record";

function SubscriptionCard(props) {
  const handleClick = (item) => {
    console.log("Clicked on Plan =", item);
  };

  return (
    <CustomCard
      className={`subscription_card custom_card border-1 overflow-hidden`}
      key={props.subscription.subscription_id}
    >
      <Card.Body
        className="d-flex flex-column align-items-center"
        onClick={() => {
          handleClick(props.subscription);
        }}
      >
        <div className="custom_tag tag_small fw_sb tag_golden text-uppercase mb-2">
          {props.subscription.subscription_name}
        </div>
        <div className="font-24 fw_b text_secondary_dark mb-1">{`$${props.subscription.payment_amount}`}</div>
        <div className="font-14 text_gray6 mb">
          {props.subscription.subscription_months > 1
            ? `${props.subscription.subscription_months} months`
            : `${props.subscription.subscription_months} month`}
        </div>
      </Card.Body>
      <Card.Footer className="border-0 bg_primary_light">
        <div className="fw_sb text_gray5">
          <BsClockHistory className="font-20 fw_sb me-2" />
          {`${props.subscription.subscription_start_date} to ${props.subscription.subscription_end_date}`}
        </div>
      </Card.Footer>
    </CustomCard>
  );
}

export default SubscriptionCard;
