import React from "react";
import { Accordion } from "react-bootstrap";
import DashboardSectionTitle from "../DashboardSectionTitle/DashboardSectionTitle";
import CustomAccordionGroup from "../../../ui/CustomAccordionGroup/CustomAccordionGroup";
import CustomAccordionItem from "../../../ui/CustomAccordionItem/CustomAccordionItem";
import "./DashboardFaq.css";

function DashboardFaq() {
  return (
    <>
      <CustomAccordionGroup className="custom_accordion faq_accordion mt2">
        <CustomAccordionItem className="mb2 shadow1" eventKey="0">
          <Accordion.Header>
            <img
              src={`${process.env.PUBLIC_URL}/img/qa.png`}
              alt="Question-answer icon"
            />{" "}
            How can I remember my dreams better?
          </Accordion.Header>
          <Accordion.Body>
            <p>
              Remembering your dream is the most important step in learning from
              your unconscious experience. Here are some tips we have garnered
              from research and from decades of practice.
            </p>
            <div className="custom_list text_list">
              <ul className="list-group">
                <li className="list-group-item">
                  Repeat aloud (or in your mind) “I will remember my dreams” at
                  least ten times. Determine to remember your dreams through a
                  self-commitment and goal. During a dream, if you are
                  sufficiently lucid, make a mental note to record your dream
                  upon awaking.
                </li>
                <li className="list-group-item">
                  If you stir during a dream, immediately write down a few cues
                  to remember the dream upon fully waking.
                </li>
                <li className="list-group-item">
                  When recording a dream, record as many details as possible.
                  This exercises your unconscious recollection.
                </li>
                <li className="list-group-item">
                  Record dreams as often as possible, even if you remember only
                  a small part. Tell someone else about your dream as soon as
                  possible after having the dream.
                </li>
                <li className="list-group-item">
                  If you cannot record your dream in full upon waking, at least
                  jot down some notes to cue your memory for later.
                </li>
                <li className="list-group-item">
                  Keep the uDreamed app active (don’t close before turning off
                  your phone) so that you can record immediately.
                </li>
                <li className="list-group-item">
                  Don’t let morning distractions delay your recording. Record
                  before getting out of bed.
                </li>
                <li className="list-group-item">
                  Avoid sleep assisting medicines and over-the-counter sleep
                  aids if possible. Shift your sleep schedule around a little
                  bit.
                </li>
                <li className="list-group-item">
                  Drink a little water before falling asleep, as this will
                  sometimes prompt a stirring after a dream.
                </li>
                <li className="list-group-item">
                  Talk to other people about dreams you’ve had. This will stir
                  your unconscious to reward you with more dreams.
                </li>
                <li className="list-group-item">
                  Reread some of your dreams from previous dream records. This
                  step is likely to activate your dream memory and enable
                  retention of dreams you may have.
                </li>
              </ul>
            </div>
            <p>
              Learning to remember your dreams may seem difficult at first, but
              if you persist, you will succeed, and may find yourself
              remembering more dreams. Here is a video we have made to explain
              some of this:{" "}
              <a href="https://youtu.be/0ZFR6n4Eigg" target="_blank">
                How to remember your dreams better.
              </a>
            </p>
          </Accordion.Body>
        </CustomAccordionItem>

        <CustomAccordionItem className="mb2 shadow1" eventKey="1">
          <Accordion.Header>
            <img
              src={`${process.env.PUBLIC_URL}/img/qa.png`}
              alt="Question-answer icon"
            />{" "}
            How do I change my personal settings?
            <span className="accordion_toggle_icon"></span>
          </Accordion.Header>
          <Accordion.Body>
            <p>
              Click on the cog icon on the top right of the screen. Then click
              on the Publish Settings button on the left side of the screen.
            </p>
          </Accordion.Body>
        </CustomAccordionItem>

        <CustomAccordionItem className="mb2 shadow1" eventKey="2">
          <Accordion.Header>
            <img
              src={`${process.env.PUBLIC_URL}/img/qa.png`}
              alt="Question-answer icon"
            />{" "}
            How do I go back to the Dashboard?
            <span className="accordion_toggle_icon"></span>
          </Accordion.Header>
          <Accordion.Body>
            <p>
              Click on the uDreamed logo at the top of the page or click on
              Dashboard link present in left navigation.
            </p>
          </Accordion.Body>
        </CustomAccordionItem>

        <CustomAccordionItem className="mb2 shadow1" eventKey="3">
          <Accordion.Header>
            <img
              src={`${process.env.PUBLIC_URL}/img/qa.png`}
              alt="Question-answer icon"
            />{" "}
            Where can I find a list of all the dreams I've recorded?
            <span className="accordion_toggle_icon"></span>
          </Accordion.Header>
          <Accordion.Body>
            <p>
              Click on the Browse experiences button below your profile picture
              and your name and email.
            </p>
          </Accordion.Body>
        </CustomAccordionItem>

        <CustomAccordionItem className="mb2 shadow1" eventKey="4">
          <Accordion.Header>
            <img
              src={`${process.env.PUBLIC_URL}/img/qa.png`}
              alt="Question-answer icon"
            />{" "}
            Can others read my dreams and know they are mine?
            <span className="accordion_toggle_icon"></span>
          </Accordion.Header>
          <Accordion.Body>
            <p>
              No, not unless you have shared the dreams with them or if they
              have invited you to be friends through a matched dream.
            </p>
          </Accordion.Body>
        </CustomAccordionItem>

        <CustomAccordionItem className="mb2 shadow1" eventKey="5">
          <Accordion.Header>
            <img
              src={`${process.env.PUBLIC_URL}/img/qa.png`}
              alt="Question-answer icon"
            />{" "}
            How can I learn more about the features of uDreamed?
            <span className="accordion_toggle_icon"></span>
          </Accordion.Header>
          <Accordion.Body>
            <p>
              We've made a quickstart tutorial exactly for this:{" "}
              <a href="https://youtu.be/CXa_GG29xfI" target="_blank">
                https://youtu.be/CXa_GG29xfI
              </a>
            </p>
          </Accordion.Body>
        </CustomAccordionItem>
      </CustomAccordionGroup>
    </>
  );
}

export default DashboardFaq;
