import React, { useState } from "react";
import { Modal, Button, Form, Spinner } from "react-bootstrap";
import ComposeMessage from "../../components/Dashboard/DashboardMessageContent/ComposeMessage";
import "./ComposeMessageModal.css";
import { Formik } from "formik";
import * as yup from "yup";
import { BsEnvelope } from "react-icons/bs";

function ComposeMessageModal(props) {
  console.log("initialValues compose modal=", props.initialValues);

  return (
    <>
      <Modal
        centered
        size="md"
        className="theme-modal compose-message-modal"
        show={props.show.isVisible}
        onHide={props.handleClose}
      >
        <Modal.Header className="pb-2" closeButton>
          <Modal.Title className="d-flex align-items-center">
            <div className="heading-icon-wrap flex-shrink-0">
              <span className="heading-icon d-flex justify-content-center align-items-center rounded-circle font-24">
                <BsEnvelope />
              </span>
            </div>
            <div className="heading flex-grow-1 ms-3">Compose Message</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-helper-text">
            Compose and send a message to your friends
          </div>
          <ComposeMessage
            friends={props.friends}
            initialValues={props.initialValues}
            action={props.action}
            handleClose={props.handleClose}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ComposeMessageModal;
