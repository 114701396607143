import React from "react";
import { Link } from "react-router-dom";
import "./HomeGuestHeader.css";

function HomeGuestHeader() {
  return (
    <header className="guest_header d-flex align-items-center justify-content-between">
      <div className="container">
        <Link className="brand_logo" to="/">
          <img
            src={`${process.env.PUBLIC_URL}/img/logo.png`}
            className="img-fluid"
            alt="uDreamed white logo"
          />
        </Link>
      </div>
    </header>
  );
}

export default HomeGuestHeader;
