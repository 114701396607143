import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import {
  Form,
  Button,
  Spinner,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { Field, Formik } from "formik";
import * as yup from "yup";
import DatePicker from "react-datepicker";

import authService from "../../services/Auth";
import VerifyEmail from "./VerifyEmail";
import {
  handleAlert,
  calculateAge,
  formatDateYMD,
  handleCustomSelectStyles,
} from "../../util/util";
import { COUNTRIES } from "../../util/countries";
import Select from "react-select";

import "react-datepicker/dist/react-datepicker.css";
import FieldError from "../../ui/FieldError/FieldError";
import InformationModal from "../../ui/InformationModal/InformationModal";

//Context
import ThemeContext from "../../ThemeContext";

function Register() {
  const { theme } = useContext(ThemeContext);
  const [loading, setLoading] = useState(false);
  const [isRegister, setIsRegister] = useState("failed");
  const [registeredEmail, setRegisteredEmail] = useState("");
  const [countries, setCountries] = useState([]);
  const [show, setShow] = useState({
    data: {},
    isVisible: false,
  });

  const handleClose = () => {
    console.log("Closing the modal");
    setShow({
      data: {},
      isVisible: false,
    });
  };
  const handleShow = (data) => {
    setShow({
      data: data,
      isVisible: true,
    });
    console.log("Opening the modal", data);
  };
  const handleClick = (e) => {
    console.log("test", e);
    const type = e.target.getAttribute("data-type");
    console.log("type=", type);
    let data = {
      title: "",
      type: "",
    };
    switch (type) {
      case "terms":
        data = {
          title: "Terms",
          type: type,
        };
        break;
      case "policy":
        data = {
          title: "Privacy Policy",
          type: type,
        };
        break;
      default:
        data = {
          title: "Cookies",
          type: type,
        };
    }
    handleShow(data);
  };

  //React select styles
  const customStyles = handleCustomSelectStyles(theme);

  // const customStyles = {
  //   control: (provided, state) => ({
  //     ...provided,
  //     border: "1px solid #ddd",
  //     borderRadius: "30px",
  //     minHeight: "48px",
  //     "&:focus": {
  //       borderWidth: "1px",
  //       borderColor: "#39b3ed",
  //       boxShadow: "none",
  //     },
  //     "&:hover": {
  //       borderColor: "#ddd",
  //     },
  //   }),
  //   valueContainer: (provided, state) => ({
  //     ...provided,
  //     padding: "2px 12px",
  //   }),
  // };

  const radioOptions = [
    { key: "Male", value: "male" },
    { key: "Female", value: "female" },
    { key: "Would rather not disclose", value: "other" },
  ];

  const countryOptions = COUNTRIES.map((country) => {
    return { label: country.name, value: country.name };
  });

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    dob: null,
    gender: "male",
    country: "",
  };

  const registerSchema = yup.object({
    firstName: yup.string().required("Required"),
    lastName: yup.string().required("Required"),
    email: yup.string().email("Invalid email format").required("Required"),
    password: yup.string().min(8, "Password is too short").required("Required"),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), ""], "Passwords must match")
      .required("Required"),
    dob: yup
      .date()
      .required("Required")
      .nullable()
      .test("dob", "Should be greater than 13 years", function (value) {
        return calculateAge(new Date(value)) > 13;
      }),
    gender: yup.string().required("Required"),
    country: yup
      .string()
      .transform((_, input) => {
        // console.log('input = ',input);
        return input != null && input.toString ? input.toString() : "";
      })
      .required("Required"),
  });

  // Date formatting
  // function formattedDate(d) {
  //   return [d.getFullYear(), d.getMonth() + 1, d.getDate()]
  //     .map((n) => (n < 10 ? `0${n}` : `${n}`))
  //     .join("-");
  // }

  const onSubmit = async (values, { resetForm }) => {
    // setLoading(true);
    console.log("Form Values :", values);

    let newDate = formatDateYMD(values.dob);
    console.log("New Date =", newDate);
    const newCountry = values.country.value;
    console.log("Selected country=", newCountry, " Type = ", typeof newCountry);

    const newValues = {
      firstname: values.firstName,
      lastname: values.lastName,
      email: values.email,
      password: values.password,
      confirm_password: values.confirmPassword,
      date_of_birth: newDate,
      gender: values.gender,
      country: newCountry,
    };
    console.log("New Values =", newValues);

    authService
      .register(newValues)
      .then((response) => {
        if (response.data.status === "success") {
          console.log("User created successfully!");
          console.log(response.data);
          setRegisteredEmail(newValues.email);
          setIsRegister("success");
          console.log("Message: ", response.data.data.result.message);
          handleAlert(response.data.data.result.message, "success");
          resetForm({});
          setLoading(false);
        } else {
          console.log("ERROR:", response.data.error);
          handleAlert(response.data.error, "error");
          setIsRegister("failed");
          resetForm({});
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      {isRegister === "failed" && (
        <Formik
          initialValues={initialValues}
          validationSchema={registerSchema}
          onSubmit={onSubmit}
        >
          {(formik) => {
            return (
              <Form onSubmit={formik.handleSubmit}>
                <Form.Group className="form-group rounded_corners">
                  <Form.Control
                    type="text"
                    placeholder="Email"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    autoComplete="off"
                  />
                  <Form.Label className="label_float">Email</Form.Label>
                  <FieldError
                    valid={
                      formik.touched.email && formik.errors.email ? true : false
                    }
                    text={formik.errors.email}
                  />
                  {/* {formik.touched.email && formik.errors.email ? (
                    <p className="error-state-text">{formik.errors.email}</p>
                  ) : null} */}
                </Form.Group>
                <Form.Group className="form-group rounded_corners">
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    name="password"
                    {...formik.getFieldProps("password")}
                  />
                  <Form.Label className="label_float">Password</Form.Label>
                  <FieldError
                    valid={
                      formik.touched.password && formik.errors.password
                        ? true
                        : false
                    }
                    text={formik.errors.password}
                  />
                  {/* {formik.touched.password && formik.errors.password ? (
                    <p className="error-state-text">{formik.errors.password}</p>
                  ) : null} */}
                </Form.Group>
                <Form.Group className="form-group rounded_corners">
                  <Form.Control
                    type="password"
                    placeholder="Confirm password"
                    name="confirmPassword"
                    {...formik.getFieldProps("confirmPassword")}
                  />
                  <Form.Label className="label_float">
                    Confirm password
                  </Form.Label>
                  <FieldError
                    valid={
                      formik.touched.confirmPassword &&
                      formik.errors.confirmPassword
                        ? true
                        : false
                    }
                    text={formik.errors.confirmPassword}
                  />
                  {/* {formik.touched.confirmPassword &&
                  formik.errors.confirmPassword ? (
                    <p className="error-state-text">
                      {formik.errors.confirmPassword}
                    </p>
                  ) : null} */}
                </Form.Group>
                <Form.Group className="form-group rounded_corners">
                  <Form.Control
                    type="text"
                    placeholder="First name"
                    name="firstName"
                    {...formik.getFieldProps("firstName")}
                    autoComplete="off"
                  />
                  <Form.Label className="label_float">First name</Form.Label>
                  <FieldError
                    valid={
                      formik.touched.firstName && formik.errors.firstName
                        ? true
                        : false
                    }
                    text={formik.errors.firstName}
                  />
                  {/* {formik.touched.firstName && formik.errors.firstName ? (
                    <p className="error-state-text">
                      {formik.errors.firstName}
                    </p>
                  ) : null} */}
                </Form.Group>
                <Form.Group className="form-group rounded_corners">
                  <Form.Control
                    type="text"
                    placeholder="Last name"
                    name="lastName"
                    {...formik.getFieldProps("lastName")}
                    autoComplete="off"
                  />
                  <Form.Label className="label_float">Last name</Form.Label>
                  <FieldError
                    valid={
                      formik.touched.lastName && formik.errors.lastName
                        ? true
                        : false
                    }
                    text={formik.errors.lastName}
                  />
                  {/* {formik.touched.lastName && formik.errors.lastName ? (
                    <p className="error-state-text">{formik.errors.lastName}</p>
                  ) : null} */}
                </Form.Group>

                <OverlayTrigger
                  trigger={["hover", "focus"]}
                  placement="top"
                  rootClose={true}
                  overlay={
                    <Tooltip id="tooltip-dob" className="custom_tooltip">
                      Knowing user's precise birthdays helps us provide all
                      users with accurate analytics regarding particular
                      demographic groups.
                    </Tooltip>
                  }
                >
                  <div className="text_primary_dark text-end">
                    <small>
                      <i>Why do we collect?</i>
                    </small>
                  </div>
                </OverlayTrigger>
                <Form.Group className="form-group rounded_corners">
                  {/* <div className="text_mediumgray mb1">Date of birth</div> */}
                  <Field name="dob" placeholder="Date of birth">
                    {({ form, field }) => {
                      const { setFieldValue } = form;
                      const { value } = field;

                      return (
                        <DatePicker
                          id="dob"
                          className="form-control"
                          {...field}
                          selected={value}
                          onChange={(val) => setFieldValue("dob", val)}
                          dateFormat="yyyy-MM-dd"
                          placeholderText="Date of birth"
                          showMonthDropdown
                          showYearDropdown
                          // scrollableYearDropdown
                          dropdownMode="select"
                          autoComplete="off"
                        />
                      );
                    }}
                  </Field>
                  <div className="text-end text_gray9">
                    <small>* Age greater than 13 years</small>
                  </div>
                  <FieldError
                    valid={
                      formik.touched.dob && formik.errors.dob ? true : false
                    }
                    text={formik.errors.dob}
                  />
                  {/* {formik.touched.dob && formik.errors.dob ? (
                    <p className="error-state-text">{formik.errors.dob}</p>
                  ) : null} */}
                </Form.Group>

                <Form.Group className="form-group rounded_corners px-2 inline_radio_fields">
                  <div className="text_gray3 fw_sb mb1 position-relative">
                    Gender
                    <OverlayTrigger
                      trigger={["hover", "focus"]}
                      placement="top"
                      rootClose={true}
                      overlay={
                        <Tooltip id="tooltip-gender" className="custom_tooltip">
                          Knowing user's precise gender helps us provide all
                          users with accurate analytics regarding particular
                          demographic groups.
                        </Tooltip>
                      }
                    >
                      <span className="text_primary_dark fw_n text-end position-absolute end-0">
                        <small>
                          <i>Why do we collect?</i>
                        </small>
                      </span>
                    </OverlayTrigger>
                  </div>
                  <div className="mb-3">
                    {radioOptions.map((option) => {
                      return (
                        <Form.Check
                          key={option.value}
                          inline
                          label={option.key}
                          name="gender"
                          type="radio"
                          id={option.value}
                          {...formik.getFieldProps("gender")}
                          value={option.value}
                          checked={formik.values?.gender == option.value}
                        />
                      );
                    })}
                  </div>
                  <FieldError
                    valid={
                      formik.touched.gender && formik.errors.gender
                        ? true
                        : false
                    }
                    text={formik.errors.gender}
                  />
                  {/* {formik.touched.gender && formik.errors.gender ? (
                    <p className="error-state-text">{formik.errors.gender}</p>
                  ) : null} */}
                </Form.Group>
                <Form.Group className="form-group rounded_corners">
                  <Select
                    styles={customStyles}
                    placeholder="Select Country"
                    value={formik.values.country}
                    onChange={(value) => {
                      formik.setFieldValue("country", value);
                    }}
                    onBlur={() => {
                      formik.setFieldTouched("country", true);
                    }}
                    isMulti={false}
                    options={countryOptions}
                    name="country"
                  />
                  <FieldError
                    valid={
                      formik.touched.country && formik.errors.country
                        ? true
                        : false
                    }
                    text={formik.errors.country}
                  />
                  {/* {formik.touched.country && formik.errors.country ? (
                    <p className="error-state-text">{formik.errors.country}</p>
                  ) : null} */}
                </Form.Group>

                {/* <Form.Group className="form-group rounded_corners">
                  <Form.Select
                    aria-label="Default select example"
                    className="form-control"
                    name="country"
                    {...formik.getFieldProps("country")}
                  >
                    {countryOptions.map((country) => {
                      return (
                        <option key={country.value} value={country.value}>
                          {country.key}
                        </option>
                      );
                    })}
                  </Form.Select>
                  {formik.touched.country && formik.errors.country ? (
                    <p className="error-state-text">{formik.errors.country}</p>
                  ) : null}
                </Form.Group> */}
                <div className="pt1 pb2">
                  <small style={{ fontSize: "13px" }}>
                    By clicking Sign Up Now, you agree to our{" "}
                    <span
                      style={{ cursor: "pointer" }}
                      data-type="terms"
                      onClick={handleClick}
                      className="text_primary fw_sb"
                    >
                      Terms
                    </span>{" "}
                    and that you have read our{" "}
                    <span
                      style={{ cursor: "pointer" }}
                      data-type="policy"
                      onClick={handleClick}
                      className="text_primary fw_sb"
                    >
                      Data Use Policy
                    </span>
                    , including our{" "}
                    <span
                      style={{ cursor: "pointer" }}
                      data-type="cookie"
                      onClick={handleClick}
                      className="text_primary fw_sb"
                    >
                      Cookie Use
                    </span>
                    .
                  </small>
                </div>
                <Button
                  type="submit"
                  disabled={!formik.isValid}
                  className="btn_theme btn_theme_gradient btn_rounded btn_block mb1"
                >
                  {loading ? (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />{" "}
                      Loading
                    </>
                  ) : !formik.isValid ? (
                    "Please fill out all info"
                  ) : (
                    "Signup"
                  )}
                </Button>
                <Link to="/" className="btn_theme btn_rounded btn_block">
                  Cancel
                </Link>
              </Form>
            );
          }}
        </Formik>
      )}
      {isRegister === "success" && (
        <VerifyEmail registeredEmail={registeredEmail} />
      )}
      <InformationModal
        show={show}
        data={show.data}
        handleClose={handleClose}
      />
    </>
  );
}

export default Register;
