import React from "react";
import { Card, Button } from "react-bootstrap";
import CustomCard from "../../../ui/CustomCard/CustomCard";
import "./InvitationCard.css";

function InvitationCard(props) {
  const avatar = props.invitation.avatar !== "NULL" ? props.invitation.avatar : `${process.env.PUBLIC_URL}/img/user-profile.jpg`;
  const name = props.invitation.name ? props.invitation.name : "Unknown";
  const msg = props.invitation.message ? props.invitation.message : "";
  const is_consult = props.invitation.is_consult
    ? props.invitation.is_consult
    : false;
  const invite_keyword = props.invitation.is_consult ? "therapist" : "friend";
  const invite_date = props.invitation.date ? props.invitation.date : "NA";

  const handleAcceptBtnClick = (invitation) => {
    props.acceptInvitation(invitation);
  };

  const handleRejectBtnClick = (invitation) => {
    props.rejectInvitation(invitation);
  };

  return (
    <>
      <CustomCard className={`invitation_card main_card shadow1 border-0 mb2`}>
        {is_consult && (
          <>
            <div className="consult_icon">
              <img
                src={`${process.env.PUBLIC_URL}/img/icons/offer-icon6.png`}
                className="img-fluid"
                alt="Consult Icon"
              />
            </div>
          </>
        )}

        <Card.Header className="invitation_card_header">
          <div className="media">
            <div className="media_img_wrap">
              {props.invitation.type !== "group" && (
                <img
                  src={`${avatar}`}
                  className={`rounded-circle media_img`}
                  alt={`${name} profile`}
                />
              )}

              {/* {props.invitation.type === "group" && (
                <img
                  src={`${process.env.PUBLIC_URL}/img/groups-icon.jpg`}
                  className={`rounded-circle media_img`}
                  alt={`${props.invitation.sender.name} profile`}
                />
              )} */}
            </div>

            <div className={`media_body ms-3`}>
              <Card.Title className="card_title">Request by {name}</Card.Title>
              <Card.Subtitle className="card_subtitle">
                {invite_date && <div>{`on ${invite_date}`}</div>}
                {`You have been invited to join as a ${invite_keyword}`}
              </Card.Subtitle>
            </div>
          </div>
        </Card.Header>
        <Card.Body className="invitation_card_body">
          {msg !== "" && <p>{msg}</p>}
          <div className="btn_wrap">
            <Button
              type="button"
              className="btn_theme btn_small btn_secondary btn_rounded"
              onClick={() => {
                handleAcceptBtnClick(props.invitation);
              }}
            >
              Accept
            </Button>
            <Button
              type="button"
              className="btn_theme btn_small btn_danger btn_rounded"
              onClick={() => {
                handleRejectBtnClick(props.invitation);
              }}
            >
              Ignore
            </Button>
          </div>
        </Card.Body>
      </CustomCard>
    </>
  );
}

export default InvitationCard;
