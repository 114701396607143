import React from "react";
import { Modal, Button, Spinner } from "react-bootstrap";
import "./ConfirmationModal.css";
import { BsExclamationCircle } from "react-icons/bs";

function ConfirmationModal(props) {
  let helper_text = "";
  let primary_action_btn_label = "";

  const handlePrimaryBtnClick = () => {
    switch (props.content_type) {
      case "logout-modal":
        props.handlePrimaryAction();
        break;
      case "remove-friend-modal":
        props.handlePrimaryAction(props.primaryActionData);
        break;
      case "remove-member-modal": 
        props.handlePrimaryAction(props.primaryActionData);
        break;
      case "leave-group-modal":
        props.handlePrimaryAction(props.primaryActionData);
        break;  
      case "record-delete-modal":
        props.handlePrimaryAction(props.primaryActionData);
        break;
      case "consulting-session-complete-modal":
        props.handlePrimaryAction(props.primaryActionData);
        break;
      case "comment-delete-modal":
        props.handlePrimaryAction(props.primaryActionData);
        break;
      
    }
  };

  //Decide content of the modal
  switch (props.content_type) {
    case "logout-modal":
      helper_text =
        "Are you sure you want to logout from app? This action cannot be undone.";
      primary_action_btn_label = "Logout";
      break;
    case "remove-friend-modal":
      helper_text =
        "Are you sure you would like to remove friend? This action cannot be undone.";
      primary_action_btn_label = "Remove";
      break;
    case "remove-member-modal":
      helper_text =
        "Are you sure you would like to remove member? This action cannot be undone.";
      primary_action_btn_label = "Remove";
      break; 
    case "leave-group-modal":
      helper_text =
        `Are you sure you want to leave a ${props.primaryActionData.title}? You simply cannot undo this action.`;
      primary_action_btn_label = "Leave";
      break;  
    case "record-delete-modal":
      helper_text =
        "Are you sure you want to delete a record? You simply cannot undo this action.";
      primary_action_btn_label = "Delete";
      break; 
    case "consulting-session-complete-modal":
      helper_text =
        "Are you sure want to mark consulting session as complete? You simply cannot undo this action.";
      primary_action_btn_label = "Confirm";
      break;
    case "comment-delete-modal":
      helper_text =
        "Are you sure you want to delete a comment? This action cannot be undone.";
      primary_action_btn_label = "Delete";
      break;
  }

  return (
    <>
      <Modal
        centered
        size="md"
        className="theme-modal confirmation-modal"
        show={props.show.isVisible}
        onHide={props.handleClose}
      >
        <Modal.Header className="pb-2" closeButton>
          <Modal.Title className="d-flex align-items-center">
            <div className="heading-icon-wrap flex-shrink-0">
              <span className="heading-icon d-flex justify-content-center align-items-center rounded-circle font-24">
                <BsExclamationCircle />
              </span>
            </div>
            <div className="heading flex-grow-1 ms-3">Confirmation</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-helper-text">{helper_text}</div>
          <div className="btn_wrap pt2 pb1" style={{ marginLeft: "-5px" }}>
            <Button
              type="submit"
              className="btn_theme
               btn_light btn_rounded"
              onClick={props.handleClose}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              className="btn_theme
               btn_warning btn_rounded"
              onClick={handlePrimaryBtnClick}
            >
              {props.loading ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />{" "}
                  Processing
                </>
              ) : (
                <>{primary_action_btn_label}</>
              )}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ConfirmationModal;
