import React, { useState } from "react";
import {
  Col,
  Row,
  Card,
  Form,
  Button,
  Spinner,
  Collapse,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import CustomCard from "../../../ui/CustomCard/CustomCard";
import { BsFillPencilFill, BsChatSquareTextFill, BsBack } from "react-icons/bs";
import CustomCollapseButton from "../../../ui/CustomCollapse/CustomCollapseButton-2";
import CustomCollapseContainer from "../../../ui/CustomCollapse/CustomCollapseContainer";
import DashboardSectionTitle from "../DashboardSectionTitle/DashboardSectionTitle";
import CreateThread from "./CreateThread";
import DashboardComments from "../DashboardComments/DashboardComments";
import { FaClone, FaEye } from "react-icons/fa";
import { BsFillEyeFill } from "react-icons/bs";
import ReactTooltip from "react-tooltip";

import {
  BiCommentAdd,
  BiCommentDetail,
  BiChat,
  BiConversation,
} from "react-icons/bi";
import "./ThreadCard.css";

import { handleAlert } from "../../../util/util";

//Service
import recordService from "../../../services/Record";
import AddThreadComment from "./AddThreadComment";
import ThreadCommentList from "./ThreadCommentList";
import CustomTooltip from "../../../ui/CustomTooltip/CustomTooltip";

function ThreadCard(props) {
  const [loading, setLoading] = useState(false);
  const [editCollapse, setEditCollapse] = useState(false);
  const [editThread, setEditThread] = useState(null);
  const [threadCommentsCount, setThreadCommentsCount] = useState(
    props.thread.comment_count
  );
  const [threadComments, setThreadComments] = useState([]);

  const isThreadOwner =
    props.authUser.user_id == props.thread.user_id ? true : false;

  const threadOwnerName = props.thread.user
    ? `${props.thread.user.firstname} ${props.thread.user.lastname}`
    : "Unknown";

  const avatar = props.thread.user.avatar ? props.thread.user.avatar : "";

  console.log("created_date =", props.thread.created_date);

  // const [cardTop, setCardTop] = useState(0);
  const [openCollapse, setOpenCollapse] = useState({
    edit_thread_collapse: false,
    add_thread_comment_collapse: false,
    view_thread_comments_collapse: false,
  });

  let tempState = null;
  const handleCollapse = (element) => {
    switch (element) {
      case "edit_thread":
        tempState = Object.fromEntries(
          Object.keys({ ...openCollapse }).map((key) => [key, false])
        );
        setOpenCollapse({
          ...tempState,
          edit_thread_collapse: !openCollapse.edit_thread_collapse,
        });
        break;
      case "add_thread_comment":
        tempState = Object.fromEntries(
          Object.keys({ ...openCollapse }).map((key) => [key, false])
        );
        setOpenCollapse({
          ...tempState,
          add_thread_comment_collapse:
            !openCollapse.add_thread_comment_collapse,
        });
        break;
      case "view_thread_comments":
        tempState = Object.fromEntries(
          Object.keys({ ...openCollapse }).map((key) => [key, false])
        );
        setOpenCollapse({
          ...tempState,
          view_thread_comments_collapse:
            !openCollapse.view_thread_comments_collapse,
        });
        break;
    }
  };

  function handleClick(e) {
    const element = e.target.getAttribute("aria-controls");
    let isAriaExpanded = e.target.getAttribute("aria-expanded");
    handleCollapse(element);
    /* Get the offsetTop of the Current Custom Card and set it to state
       to scroll page back to its normal position when collapsible collapses.. 
    ***/
    console.log("Element = ", e);
    console.log("Parent = ", e.target.offsetParent);
    console.log("Top = ", e.target.offsetParent.offsetParent.offsetTop);
    props.setCardTop(e.target.offsetParent.offsetParent.offsetTop);
  }

  function handleCollapseOnExiting() {
    window.scroll({
      top: props.cardTop,
      behavior: "smooth",
    });
  }

  // function handleCollapse(element) {
  //   console.log("In Thread Card Comp =", element);
  //   setEditCollapse(!editCollapse);
  // }

  const handleThreadCommentList = (id) => {
    setLoading(true);
    const commentData = {
      thread_id: id,
    };
    recordService
      .getAllThreadComments(commentData)
      .then((response) => {
        if (response.data.status === "success") {
          console.log(
            "handleThreadCommentList() Thread-comment list: ",
            response.data
          );
          const forumThreadsComments = response.data.data.result.comments;

          setThreadComments(forumThreadsComments);
          // setThreads(forumThreads);
          setLoading(false);
        } else if (response.data.status === "failed") {
          console.log("ERROR:", response.data.error);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log("There is a problem in fetching threads...", err);
        setLoading(false);
      });
  };

  function handleIsUpdating(item) {
    console.log("Item =", item);
    if (props.thread.forum_thread_id == item.forum_thread_id) {
      // console.log(
      //   "Inside if loop ",
      //   props.item.record_associate_id == item.record_associate_id
      // );
      recordService
        .getThread(item.forum_thread_id, props.forum_id)
        .then((response) => {
          if (response.data.status === "success") {
            console.log("handleIsUpdating() - getThread : ", response.data);
            const newResponse = response.data.data.result.thread;
            // const newValues = {
            //   ...newResponse,
            //   forum_id: props.forum_id,
            // };
            console.log("New Form Values =", newResponse);
            setEditThread(newResponse);
          }
          if (response.data.status === "failed") {
            console.log("ERROR:", response.data.error);
            // setLoading(false);
          }
        })
        .catch((err) => {
          console.log("There is a problem in fetching thread details...", err);
          // setLoading(false);
        });
    }
  }

  return (
    <>
      <CustomCard
        key={props.thread.forum_thread_id}
        data-id={`${props.thread.forum_thread_id}`}
        className="border-0 thread_card shadow1 mb2"
      >
        <Card.Body>
          <div className="d-flex justify-content-between mb2">
            <div className="flex-shrink-1 flex-fill">
              <div className="font-15 fw_b">{`${props.thread.title}`}</div>
            </div>
            <div
              className="d-flex flex-fill flex-grow-1 btn_wrap justify-content-end"
              style={{ minWidth: "100px" }}
            >
              {isThreadOwner && (
                <>
                  <CustomCollapseButton
                    title="Edit Thread"
                    handleCollapse={handleCollapse}
                    btnTitle={`Edit Thread`}
                    className="btn_gray btn_icon btn_auto_width btn_circle"
                    ariaControls="edit_thread"
                    ariaExpanded={openCollapse.edit_thread_collapse}
                    btnType="icon"
                  />
                </>
              )}
              <CustomCollapseButton
                handleCollapse={handleCollapse}
                btnTitle={threadCommentsCount}
                className="btn_small btn_primary btn_auto_width btn_rounded align-self-start flex-nowrap"
                ariaControls="view_thread_comments"
                ariaExpanded={openCollapse.view_thread_comments_collapse}
                // setCardTop={props.setCardTop}
                btnType="icon_with_title"
              />
            </div>
          </div>
          <div className="font-14">{props.thread?.description}</div>

          <div className="btn_wrap">
            {props.thread.actions?.map((action, index) => {
              switch (action) {
                case "edit_thread":
                  return;
                case "add_thread_comment":
                  return (
                    <CustomCollapseButton
                      key={index}
                      handleCollapse={handleCollapse}
                      btnTitle="Add Comment"
                      className="btn_small btn_primary btn_rounded"
                      ariaControls="add_thread_comment"
                      ariaExpanded={openCollapse.add_thread_comment_collapse}
                      setCardTop={props.setCardTop}
                    />
                  );
                case "view_thread_comments":
                  return;
                // return (
                //   <CustomCollapseButton
                //     key={index}
                //     handleCollapse={handleCollapse}
                //     btnTitle={
                //       threadCommentsCount > 1 ? (
                //         <>{`${threadCommentsCount} Comments`}</>
                //       ) : (
                //         <>{`${threadCommentsCount} Comment`}</>
                //       )
                //     }
                //     className="btn_small btn_light btn_rounded"
                //     ariaControls="view_thread_comments"
                //     ariaExpanded={openCollapse.view_thread_comments_collapse}
                //     setCardTop={props.setCardTop}
                //   />
                // );
              }
            })}
          </div>
        </Card.Body>
        <Card.Header className="bg_transparent">
          <div className="media">
            <div className="media_icon_wrap d-flex justify-content-center align-items-center">
              {avatar === "" ? (
                <>
                  <BiConversation />
                </>
              ) : (
                <>
                  <img
                    src={avatar}
                    className="rounded-circle media_img"
                    alt={`${threadOwnerName} profile`}
                  />
                </>
              )}
            </div>
            <div className={`media_body ms-2`}>
              <Card.Title className="card_title">
                {`Created by `}
                <span className="text_primary_dark fw_b">
                  {threadOwnerName}
                </span>
                {/* {`${props.thread.title} | ${props.thread.forum_thread_id}`} */}
              </Card.Title>
              <span className="d-flex-inline font-13 text_gray9">
                {`${
                  props.thread.created_date
                    ? `on ${props.thread.created_date}`
                    : "NA"
                }`}
              </span>
            </div>
            <Card.Subtitle
              className="card_subtitle"
              // title={`${props.thread.views === 1} Views`}
              title={`${props.thread.views} ${props.thread.views === 1 ? 'View' : 'Views'}`}
              // data-tip={`${props.thread.views} Views`}
              // data-for="tooltip1"
            >
              <>
                {props.thread.views > 0 ? (
                  <span className="custom_tag">
                    <BsFillEyeFill className="font-16" /> {props.thread.views}
                  </span>
                ) : (
                  <>
                    <BsFillEyeFill className="font-16" /> 0
                  </>
                )}
                {/* <ReactTooltip id="tooltip1" /> */}
              </>
              {/* <CustomTooltip
                id={`thread-view-${props.thread.forum_thread_id}`}
                title={`${props.thread.views} ${
                  props.thread.views > 1 ? "views" : "view"
                }`}
              >
                {props.thread.views > 0 ? (
                  <span className="custom_tag">
                    <BsFillEyeFill className="font-16" /> {props.thread.views}
                  </span>
                ) : (
                  <>
                    <BsFillEyeFill className="font-16" /> 0
                  </>
                )}
              </CustomTooltip> */}
            </Card.Subtitle>
          </div>

          {props.thread.actions?.map((action, index) => {
            switch (action) {
              case "edit_thread":
                return (
                  <CustomCollapseContainer
                    key={index}
                    ariaControls="edit_thread"
                    openCollapse={openCollapse.edit_thread_collapse}
                    handleIsUpdating={handleIsUpdating}
                    thread={props.thread}
                    // cardTop={cardTop}
                  >
                    <hr />
                    <div className="pt2 pb2">
                      <CreateThread
                        forum_id={props.forum_id}
                        forum_thread_id={props.thread.forum_thread_id}
                        isAddMode={false}
                        existingThread={editThread}
                        handleThreadList={props.handleThreadList}
                        // editCollapse={editCollapse}
                        // setEditCollapse={setEditCollapse}
                        handleCollapse={handleCollapse}
                      />
                    </div>
                  </CustomCollapseContainer>
                );
              case "add_thread_comment":
                return (
                  <CustomCollapseContainer
                    key={index}
                    ariaControls="add_thread_comment"
                    openCollapse={openCollapse.add_thread_comment_collapse}

                    // cardTop={cardTop}
                  >
                    <hr />
                    <div className="pt2 pb2">
                      {/* <AddThreadComment
                        forum_thread_id={props.thread.forum_thread_id}
                        setThreadCommentsCount={setThreadCommentsCount}
                        handleThreadCommentList={handleThreadCommentList}
                        handleCollapse={handleCollapse}
                      /> */}
                      {/* <CreateThread
                      forum_id={forum.forum_id}
                      isAddMode={true}
                      setThreadCount={setThreadCount}
                      handleCollapse={handleCollapse}
                      handleThreadList={handleThreadList}
                    /> */}
                    </div>
                  </CustomCollapseContainer>
                );
              case "view_thread_comments":
                return (
                  <CustomCollapseContainer
                    key={index}
                    ariaControls="view_thread_comments"
                    openCollapse={openCollapse.view_thread_comments_collapse}
                    forum_thread_id={props.thread.forum_thread_id}
                    handleThreadCommentList={handleThreadCommentList}
                    // className="position-relative border-top mt-3 pt-3 pb-3"
                    // forum_id={forum.forum_id}
                    // handleThreadList={handleThreadList}
                    // cardTop={cardTop}
                  >
                    <DashboardComments
                      comment_type="thread"
                      loading={loading}
                      parent_id={props.thread.forum_thread_id}
                      parent_owner_id={props.thread.user_id}
                      comments={threadComments}
                      handleCommentList={handleThreadCommentList}
                      setCommentsCount={setThreadCommentsCount}
                    />
                    {/* <div className="d-grid gap-3 comment_list_wrap"> */}
                    {/* <ThreadCommentList
                        loading={loading}
                        forum_thread_id={props.thread.forum_thread_id}
                        setThreadCommentsCount={setThreadCommentsCount}
                        threadComments={threadComments}
                        handleThreadCommentList={handleThreadCommentList}
                        handleCollapse={handleCollapse}
                      /> */}
                    {/* <ThreadList
                      handleCollapse={handleCollapse}
                      threads={threads}
                      loading={loading}
                      forum_id={forum.forum_id}
                      handleThreadList={handleThreadList}
                      cardTop={cardTop}
                      setCardTop={setCardTop}
                    /> */}
                    {/* </div> */}
                  </CustomCollapseContainer>
                );
            }
          })}
        </Card.Header>
      </CustomCard>
    </>
  );
}

export default ThreadCard;
