import React, { useState, useEffect } from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import CustomCard from "../../../ui/CustomCard/CustomCard";
import DashboardSectionTitle from "../DashboardSectionTitle/DashboardSectionTitle";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

// export const options = {
//   indexAxis: "y",
//   elements: {
//     bar: {
//       borderWidth: 2,
//     },
//   },
//   responsive: true,
//   plugins: {
//     legend: {
//       position: "right",
//     },
//     title: {
//       display: true,
//       text: "Analyzed Patterns",
//     },
//   },
// };

function DashboardAnalyzeExploreBarChart(props) {
  const [allDataSets, setAllDataSets] = useState([]);
  const [chartData, setChartData] = useState({});

  const options = {
    responsive: false,
    maintainAspectRatio: false,
    indexAxis: "y",
    scales: {
      y: {
        grid: {
          offset: true,
        },
      },
    },
    elements: {
      bar: {
        borderWidth: 1,
        // inflateAmount: 2,
      },
    },
    layout: {
      autoPadding: true,
    },
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Analyzed Explore",
      },
    },
  };

  //   let allDataSets = [];

  //   if (props.meDataset?.length > 0) {
  //     setAllDataSets((prev) => {
  //       return [
  //         ...prev,
  //         {
  //           label: "Me",
  //           data: props.meDataset,
  //           borderColor: "rgb(0, 153, 255)",
  //           backgroundColor: "rgba(0, 153, 255, 0.4)",
  //         },
  //       ];
  //     });
  //     // allDataSets.push({
  //     //   label: "Me",
  //     //   data: props.meDataset,
  //     //   borderColor: "rgb(0, 153, 255)",
  //     //   backgroundColor: "rgba(0, 153, 255, 0.4)",
  //     // });
  //   }
  //   if (props.friendsDataset?.length > 0) {
  //     setAllDataSets((prev) => {
  //       return [
  //         ...prev,
  //         {
  //           label: "Friends",
  //           data: props.friendsDataset,
  //           borderColor: "rgb(255, 51, 153)",
  //           backgroundColor: "rgba(255, 51, 153, 0.4)",
  //         },
  //       ];
  //     });
  //     // allDataSets.push({
  //     //   label: "Friends",
  //     //   data: props.friendsDataset,
  //     //   borderColor: "rgb(255, 51, 153)",
  //     //   backgroundColor: "rgba(255, 51, 153, 0.4)",
  //     // });
  //   }
  //   if (props.globalDataset?.length > 0) {
  //     setAllDataSets((prev) => {
  //       return [
  //         ...prev,
  //         {
  //           label: "Global",
  //           data: props.globalDataset,
  //           borderColor: "rgb(51, 204, 102)",
  //           backgroundColor: "rgba(51, 204, 102, 0.4)",
  //         },
  //       ];
  //     });
  //     // allDataSets.push({
  //     //   label: "Global",
  //     //   data: props.globalDataset,
  //     //   borderColor: "rgb(51, 204, 102)",
  //     //   backgroundColor: "rgba(51, 204, 102, 0.4)",
  //     // });
  //   }

  //   console.log("All datatset =", allDataSets);

  const data = {
    labels: props.labels,
    datasets: props.datasets,
  };

  return (
    <>
      {/* <hr /> */}
      <div className="pt1" style={{ height: "100vh" }}>
        {/*  <CustomCard
          className={`main_card shadow1 border-0 mb2 text-left overflow-hidden`}
        >
          <Card.Body style={{ height: "100vh" }} className="p-1"> */}
        <Bar options={options} data={data} />
        {/* </Card.Body>
        </CustomCard> */}
      </div>
    </>
  );
}

export default DashboardAnalyzeExploreBarChart;
