import React from "react";
import { Modal, Row, Col } from "react-bootstrap";
import SocialLinksList from "../SocialLinksList/SocialLinksList";
import styles from "./WebProfileModal.module.css";

function WebProfileModal(props) {
  const memberProfileImg = props.member.profileImg;
  const memberName = props.member.name;
  const memberDesignation = props.member.designation;
  const memberInfo = props.member.info;
  // const memberSocialLinks = props.member.socialLinks;

  return (
    <>
      <Modal
        centered
        size="lg"
        className={styles.theme_modal}
        show={props.show.isVisible}
        onHide={props.handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>{memberName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={12} lg={4} className="text-center">
              <img
                src={`${process.env.PUBLIC_URL}/img/team/${memberProfileImg}`}
                className={`${styles.profile_img} img-fluid mx-auto`}
                alt={memberName}
              />
              <div className="d-flex justify-content-center pt2 pb2">
                {props.member?.socialLinks?.length > 0 && (
                  <SocialLinksList theme="" />
                )}
              </div>
            </Col>
            <Col md={12} lg={8}>
              <div className="profile_data pt2">
                <h6 className={styles.member_designation}>
                  {memberDesignation}
                </h6>
                <p className={styles.member_info}>{memberInfo}</p>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default WebProfileModal;
