import React, { useState, useEffect, useContext } from "react";
import { Card, Row, Col, Form, Button, Spinner } from "react-bootstrap";
import CustomCard from "../../../ui/CustomCard/CustomCard";
import DashboardSectionTitle from "../DashboardSectionTitle/DashboardSectionTitle";
import { Field, Formik } from "formik";
import * as yup from "yup";
import Select from "react-select";
import { handleAlert, handleCustomSelectStyles } from "../../../util/util";
import { BsSearch } from "react-icons/bs";

//Service
import recordService from "../../../services/Record";
//Context
import ThemeContext from "../../../ThemeContext";

function DashboardSearchConsults(props) {
  const { theme } = useContext(ThemeContext);
  const [loading, setLoading] = useState(false);

  const languages = [
    "English",
    "Spanish",
    "German",
    "French",
    "Chinese",
    "Japanese",
    "Portuguese",
    "Indian (Hindi etc.)",
    "Arabic",
    "Other",
  ];
  const specialties = [
    "Psychology",
    "Jungian Psychotherapy",
    "Psychotherapy",
    "Sleep Therapy",
    "Modern Psychoanalysis",
    "Neuropsychology",
    "Neurophysiology",
    "Cognitive Behavior Therapy",
    "Psychiatry",
    "Nutrition Therapy",
    "Dreamwork and Dream Analysis",
    "Emotion Focused Therapy",
    "Conflict Resolution Therapy",
    "Eye Movement Desensitization and Reprocessing (EMDR)",
    "Mindfulness-Based Cognitive Therapy (MBCT)",
    "Motivational Therapy",
    "Complementary and Alternative Medicine (CAM)",
    "Spiritualist",
    "Hypnotist",
    "Counselor",
    "Clinical Social Workers",
    "Marriage and Family Therapist",
    "Rehabilitation Counselor",
    "Other",
  ];
  const languageOptions = languages.map((lang) => {
    return { label: lang, value: lang };
  });

  const specialtyOptions = specialties.map((spl) => {
    return { label: spl, value: spl };
  });

  //React select styles
  const customStyles = handleCustomSelectStyles(theme);

  // const customStyles = {
  //   control: (provided, state) => ({
  //     ...provided,
  //     border: theme === "dark" ? "1px solid #666" : "1px solid #ddd",
  //     borderRadius: "30px",
  //     minHeight: "48px",
  //     backgroundColor: theme === "dark" ? "#333" : "#fff",
  //     color: "inherit",
  //     "&:focus": {
  //       borderWidth: "1px",
  //       borderColor: "#39b3ed",
  //       boxShadow: "none",
  //     },
  //     "&:hover": {
  //       borderColor: theme === "dark" ? "#666" : "#ddd",
  //     },
  //   }),
  //   singleValue: (provided) => ({
  //     ...provided,
  //     color: "inherit",
  //   }),
  //   placeholder: (provided) => ({
  //     ...provided,
  //     color: "#ffffff",
  //   }),
  //   menuList: (provided) => ({
  //     ...provided,
  //     backgroundColor: theme === "dark" ? "#444" : "#fff",
  //     padding: 0,
  //   }),
  //   option: (provided, { isFocused, isSelected }) => ({
  //     ...provided,
  //     background: isFocused ? "#999" : isSelected ? "#0991f6" : undefined,
  //   }),
  //   valueContainer: (provided, state) => ({
  //     ...provided,
  //     padding: "2px 12px",
  //     color: "inherit",
  //     zIndex: 1,
  //   }),
  // };

  const initialValues = {
    specialty: "Psychology",
    language_spoken: "English",
  };

  const onSubmit = async (values, { resetForm }) => {
    setLoading(true);
    console.log("Form Values in searchConsultants :", values);

    // const new_language_spoken = Array.isArray(values.language_spoken)
    //   ? values.language_spoken[0].value
    //   : values.language_spoken.value;
    // const new_specialty = Array.isArray(values.specialty)
    //   ? values.specialty[0].value
    //   : values.specialty.value;

    const new_language_spoken =
      typeof values.language_spoken === "string"
        ? values.language_spoken
        : values.language_spoken.value;
    const new_specialty =
      typeof values.specialty === "string"
        ? values.specialty
        : values.specialty.value;

    const newValues = {
      ...values,
      specialty: new_specialty,
      language_spoken: new_language_spoken,
    };
    console.log("New values in searchConsultants =", newValues);

    recordService
      .searchConsultants(newValues)
      .then((response) => {
        if (response.data.status === "success") {
          console.log(response.data);
          // handleAlert(
          //   "You have successfully updated user details!",
          //   "success"
          // );
          props.setConsultants(response.data.data.result.consultants);
          // resetForm({});
          setLoading(false);
        } else if (response.data.status === "failed") {
          console.log("ERROR:", response.data.error);
          props.setConsultants([]);
          // handleAlert("User details updation failed!", "error");
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <CustomCard className={`main_card shadow1 border-0 mt2 mb2`}>
        <Card.Body>
          <Formik
            initialValues={initialValues}
            // validationSchema={generalSettingsSchema}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {(formik) => {
              console.log("Formik =", formik);
              return (
                <Form
                  className="form_style mt-1"
                  onSubmit={formik.handleSubmit}
                >
                  <Row className="no-gutters row-cols-1 row-cols-sm-2">
                    <Col>
                      <Form.Group className="form-group rounded_corners">
                        <Select
                          styles={customStyles}
                          placeholder="Specialty"
                          defaultValue={specialtyOptions[0]}
                          // value={formik.values.specialty}
                          onChange={(value) => {
                            formik.setFieldValue("specialty", value);
                          }}
                          onBlur={() => {
                            formik.setFieldTouched("specialty", true);
                          }}
                          isMulti={false}
                          options={specialtyOptions}
                          name="specialty"
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="form-group rounded_corners">
                        <Select
                          styles={customStyles}
                          placeholder="Language"
                          defaultValue={languageOptions[0]}
                          // value={formik.values.language_spoken}
                          onChange={(value) => {
                            formik.setFieldValue("language_spoken", value);
                          }}
                          onBlur={() => {
                            formik.setFieldTouched("language_spoken", true);
                          }}
                          isMulti={false}
                          options={languageOptions}
                          name="language_spoken"
                        />
                      </Form.Group>
                    </Col>
                    {/* <Col xs={12} sm={12} md={3}>
                      <div className="btn_wrap">
                        <Button
                          key="searchConsultantsBtn"
                          type="submit"
                          // disabled={!formik.isValid}
                          className="btn_theme btn_primary btn_icon btn_circle btn_large"
                        >
                          {loading ? (
                            <>
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                            </>
                          ) : (
                            <BsSearch style={{ fontSize: "18px" }} />
                          )}
                        </Button>
                        <Button
                          key="allConsultantsBtn"
                          type="button"
                          className="btn_theme btn_secondary btn_rounded btn_auto_width"
                          onClick={props.getAllConsultants}
                        >
                          All
                        </Button>
                      </div>
                    </Col> */}
                  </Row>
                  <Row className="row-cols-1">
                    <Col>
                      <div className="btn_wrap">
                        <Button
                          key="searchConsultantsBtn"
                          type="submit"
                          // disabled={!formik.isValid}
                          className="btn_theme btn_primary  btn_rounded btn_auto_width"
                        >
                          {loading ? (
                            <>
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                            </>
                          ) : (
                            <>
                              <BsSearch
                                style={{
                                  fontSize: "14px",
                                  top: "-1px",
                                  left: "-2px",
                                }}
                                className="position-relative"
                              />{" "}
                              Search
                            </>
                          )}
                        </Button>
                        <Button
                          key="allConsultantsBtn"
                          type="button"
                          className="btn_theme btn_light btn_rounded btn_auto_width"
                          onClick={props.getAllConsultants}
                        >
                          Show All
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              );
            }}
          </Formik>
        </Card.Body>
      </CustomCard>
    </>
  );
}

export default DashboardSearchConsults;
