import React, { useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import DashboardHeader from "../components/Dashboard/DashboardHeader/DashboardHeader";
import DashboardBody from "../components/Dashboard/DashboardBody/DashboardBody";
import DashboardFooter from "../components/Dashboard/DashboardFooter/DashboardFooter";
import PageComponent from "../components/PageComponent/PageComponent";

function Dashboard(props) {
  let { id } = useParams();
  // const location = useLocation();
  // console.log("ID = ", id);
  // console.log("Location in Dashboard = ", location);
  // console.log(
  //   "Location in Dashboard without / = ",
  //   location.pathname.replace("/", "")
  // );
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);

  return (
    <>
      <DashboardHeader
        setIsNavbarOpen={setIsNavbarOpen}
        isNavbarOpen={isNavbarOpen}
      />
      <PageComponent>
        <DashboardBody
          page={id}
          setIsNavbarOpen={setIsNavbarOpen}
          isNavbarOpen={isNavbarOpen}
        />
      </PageComponent>
      <DashboardFooter />
    </>
  );
}

export default Dashboard;
