import React from "react";
import { Container } from "react-bootstrap";
import HomeGuestHeader from "../components/HomeGuestHeader/HomeGuestHeader";
import HomeInnerPageBanner from "../components/HomeInnerPageBanner/HomeInnerPageBanner";
import HomeSectionHeading from "../components/HomeSectionHeading/HomeSectionHeading";
import HomeNormalBanner from "../components/HomeNormalBanner/HomeNormalBanner";
import HomeFooter from "../components/HomeFooter/HomeFooter";
import PageComponent from "../components/PageComponent/PageComponent";
import PrivacyInfo from "../components/Terms/PrivacyInfo";

function Privacy() {
  return (
    <>
      <HomeGuestHeader />
      <PageComponent>
        <HomeInnerPageBanner className="about_banner"></HomeInnerPageBanner>
        <div className="inner_page">
          <Container className="pt5 pb5">
            <div className="page_text">
              <HomeSectionHeading
                htmlTag="h2"
                type="m"
                titleText="Privacy Policy"
                className="mb2"
              />
              <PrivacyInfo />
            </div>
          </Container>
        </div>
        <HomeNormalBanner />
      </PageComponent>
      <HomeFooter />
    </>
  );
}

export default Privacy;
