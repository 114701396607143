import React, { useState, useEffect, useContext } from "react";
import { Col, Row, Accordion, Form, Button, Spinner } from "react-bootstrap";
import CustomAccordionGroup from "../../../ui/CustomAccordionGroup/CustomAccordionGroup";
import CustomAccordionItem from "../../../ui/CustomAccordionItem/CustomAccordionItem";
import DashboardSectionTitle from "../DashboardSectionTitle/DashboardSectionTitle";
import CustomAccordionButton from "../../../ui/CustomCollapse/CustomAccordionButton";
import CustomAccordionContainer from "../../../ui/CustomCollapse/CustomAccordionContainer";
import { Field, Formik } from "formik";
import * as yup from "yup";
import Select from "react-select";
import { handleAlert, handleThemeChange, handleCustomSelectStyles } from "../../../util/util";

//Context
import AppContext from "../../../AppContext";
import ThemeContext from "../../../ThemeContext";

//Service
import recordService from "../../../services/Record";

function CreateGroup(props) {
  const { friends } = useContext(AppContext);
  const { theme } = useContext(ThemeContext);
  const [loading, setLoading] = useState(false);
  const [isDuplicateGroup, setIsDuplicateGroup] = useState(false);
  const [createGroupCollapse, setCreateGroupCollapse] = useState(false);

  const handleCollapse = (element) => {
    setCreateGroupCollapse(!createGroupCollapse);
  };

  //React select styles
  const customStyles = handleCustomSelectStyles(theme);

  // const customStyles = {
  //   control: (provided, state) => ({
  //     ...provided,
  //     border: theme === "dark" ? "1px solid #666" : "1px solid #ddd",
  //     borderRadius: "30px",
  //     minHeight: "48px",
  //     backgroundColor: theme === "dark" ? "#333" : "#fff",
  //     color: "inherit",
  //     "&:focus": {
  //       borderWidth: "1px",
  //       borderColor: "#39b3ed",
  //       boxShadow: "none",
  //     },
  //     "&:hover": {
  //       borderColor: theme === "dark" ? "#666" : "#ddd",
  //     },
  //   }),
  //   singleValue: (provided) => ({
  //     ...provided,
  //     color: "inherit",
  //   }),
  //   placeholder: (provided) => ({
  //     ...provided,
  //     color: "#ffffff",
  //   }),
  //   menuList: (provided) => ({
  //     ...provided,
  //     backgroundColor: theme === "dark" ? "#444" : "#fff",
  //     padding: 0,
  //   }),
  //   option: (provided, { isFocused, isSelected }) => ({
  //     ...provided,
  //     background: isFocused ? "#999" : isSelected ? "#0991f6" : undefined,
  //   }),
  //   valueContainer: (provided, state) => ({
  //     ...provided,
  //     padding: "2px 12px",
  //     color: "inherit",
  //     zIndex: 1,
  //   }),
  // };

  const radioOptions = [
    { key: "Public (anyone can join)", value: "public" },
    {
      key: "Invite only (a host must send you an invitation)",
      value: "invite",
    },
    {
      key: "By approval (a new user can submit a request to join and a host can approve or reject their request)",
      value: "approval",
    },
  ];

  const initialValues = {
    title: "",
    description: "",
    user_ids: [],
    privacy: "",
  };

  const userOptions = friends?.map((friend) => {
    return { label: friend.name, value: friend.id };
  });
  console.log("Users : ", userOptions);

  const createGroupSchema = yup.object({
    title: yup.string().required("Required"),
    description: yup.string().required("Required"),
    user_ids: yup
      .array()
      .min(1, "Atleast 1 member required")
      .of(
        yup.object().shape({
          label: yup.string().required(),
          value: yup.string().required(),
        })
      ),
    privacy: yup.string().required(),
  });

  const onSubmit = async (values, { resetForm }) => {
    console.log("Create Group Form Values :", values);

    const newUsers = values.user_ids.map((user) => user.value);
    console.log("New Memebrs=", newUsers);
    const newValues = {
      ...values,
      user_ids: newUsers,
    };
    console.log("New values in Compose =", newValues);
    // resetForm({});
    setLoading(true);

    recordService
      .createGroup(newValues)
      .then((response) => {
        if (response.data.status === "success") {
          console.log("Create group response =", response.data);
          if (response.data.data.result.group.privacy === "invite") {
            handleAlert(
              "You have successfully created a group! Also group invitations are sent to the other members",
              "success"
            );
          } else {
            handleAlert("You have successfully created a group!", "success");
          }
          //Get all groups
          props.getAllGroups();
          setLoading(false);
          setIsDuplicateGroup(false);
          handleCollapse("create_group");
          resetForm({});
          // props.handleCollapse("add_association");
        } else if (response.data.status === "failed") {
          console.log("ERROR:", response.data.error);
          console.log("Response Data in ERROR: ", response.data);
          handleAlert(
            `Group creation failed! ${response.data.message}`,
            "error"
          );
          setLoading(false);
          if (response.data.message === "") {
            resetForm({});
          } else {
            setIsDuplicateGroup(true);
          }
          // props.handleCollapse("add_association");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div className="custom_accordion_wrap shadow1">
        <CustomAccordionButton
          key={`create-group`}
          handleCollapse={handleCollapse}
          btnTitle="Create New Group"
          iconImg="qa.png"
          className="w-100"
          ariaControls="create_group"
          ariaExpanded={createGroupCollapse}
          // setCardTop={setCardTop}
        />
        <CustomAccordionContainer
          key={`create-group-container`}
          ariaControls="create_group"
          openCollapse={createGroupCollapse}
          unmountOnExit={true}
          // cardTop={cardTop}
        >
          <Formik
            initialValues={initialValues}
            validationSchema={createGroupSchema}
            onSubmit={onSubmit}
          >
            {(formik) => {
              // console.log(formik);
              return (
                <Form
                  className="form_style px-4 py-3"
                  onSubmit={formik.handleSubmit}
                >
                  <Row className="g-0">
                    <Col xs={12}>
                      <DashboardSectionTitle
                        iconImg=""
                        title="Group Name"
                        pageTitle={false}
                        className="mb1 small_title"
                      />
                      <Form.Group className="form-group rounded_corners">
                        <Form.Control
                          type="text"
                          placeholder="Group Name"
                          name="title"
                          {...formik.getFieldProps("title")}
                        />
                        {formik.touched.title && formik.errors.title ? (
                          <p className="error-state-text">
                            {formik.errors.title}
                          </p>
                        ) : null}
                        {isDuplicateGroup ? (
                          <p className="error-state-text">
                            Duplicate name not allowed
                          </p>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col xs={12}>
                      <DashboardSectionTitle
                        iconImg=""
                        title="Group Description"
                        pageTitle={false}
                        className="mb1 small_title"
                      />
                      <Form.Group className="form-group rounded_corners">
                        <Form.Control
                          as="textarea"
                          placeholder="Group Description"
                          style={{ height: "140px" }}
                          name="description"
                          {...formik.getFieldProps("description")}
                        />
                        {formik.touched.description &&
                        formik.errors.description ? (
                          <p className="error-state-text">
                            {formik.errors.description}
                          </p>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col xs={12}>
                      <DashboardSectionTitle
                        iconImg=""
                        title="Add Members"
                        pageTitle={false}
                        className="mb1 small_title"
                      />
                      <Form.Group className="form-group rounded_corners px-1">
                        <Select
                          styles={customStyles}
                          placeholder="Select members"
                          value={formik.values.user_ids}
                          onChange={(value) => {
                            formik.setFieldValue("user_ids", value);
                          }}
                          onBlur={() => {
                            formik.setFieldTouched("user_ids", true);
                          }}
                          isMulti={true}
                          options={userOptions}
                          name="user_ids"
                        />
                        {formik.touched.user_ids && formik.errors.user_ids ? (
                          <p className="error-state-text">
                            {formik.errors.user_ids}
                          </p>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col xs={12}>
                      <DashboardSectionTitle
                        iconImg=""
                        title="Privacy"
                        pageTitle={false}
                        className="mb1 small_title"
                      />
                      <Form.Group className="form-group rounded_corners px-1">
                        {radioOptions.map((option) => {
                          return (
                            <Form.Check
                              key={option.value}
                              inline
                              label={option.key}
                              name="privacy"
                              type="radio"
                              id={option.value}
                              value={option.value}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              checked={formik.values?.privacy == option.value}
                            />
                          );
                        })}
                        {formik.touched.privacy && formik.errors.privacy ? (
                          <p className="error-state-text">
                            {formik.errors.privacy}
                          </p>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col xs={12}>
                      <div className="btn_wrap">
                        <Button
                          type="submit"
                          className="btn btn_theme btn_primary btn_rounded"
                        >
                          {loading ? (
                            <>
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />{" "}
                              Creating
                            </>
                          ) : (
                            "Create Group"
                          )}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              );
            }}
          </Formik>
        </CustomAccordionContainer>
      </div>
    </>
  );
}

export default CreateGroup;
