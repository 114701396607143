import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Collapse,
  Form,
  Button,
  Spinner,
  Placeholder,
} from "react-bootstrap";
import CustomCard from "../../../ui/CustomCard/CustomCard";
import DashboardSectionTitle from "../DashboardSectionTitle/DashboardSectionTitle";
import DashboardAnalyzeCard from "./DashboardAnalyzeCard";
import { Field, Formik } from "formik";
import * as yup from "yup";
import DatePicker from "react-datepicker";
import { addDays, subDays } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import { formatDateYMD } from "../../../util/util";
import {
  BsFillExclamationTriangleFill,
  BsThreeDotsVertical,
  BsFillNutFill,
  BsFunnelFill,
  BsGearFill,
} from "react-icons/bs";
import { FaFilter } from "react-icons/fa";

//Service
import recordService from "../../../services/Record";

function DashboardAnalyzeSearch(props) {
  const today = formatDateYMD(new Date());
  const [loading, setLoading] = useState(false);
  const [analyzedExperiences, setAnalyzedExperiences] = useState([]);
  const [analyzeSearchFiltersCollapse, setAnalyzeSearchFiltersCollapse] =
    useState(false);
  const [analyzeSearchFilters, setAnalyzeSearchFilters] = useState({
    record_type: "All",
    target: "Global",
    end_date: today,
    start_date: "2021-02-28",
    keyword: "",
  });

  function handleClick(e) {
    const element = e.target.getAttribute("aria-controls");
    let isAriaExpanded = e.target.getAttribute("aria-expanded");
    // console.log("Association Card =", e);
    handleCollapse(element);
  }
  function handleCollapse(element) {
    console.log("In Analyze Pattern Comp =", element);
    if (element === "analyze_search_filters_collapse") {
      setAnalyzeSearchFiltersCollapse(!analyzeSearchFiltersCollapse);
    }
  }

  // useEffect(() => {
  //   console.log(props.analyzedExperiences);
  // }, [props.analyzedExperiences]);

  const recordRadioOptions = [
    {key: "All", value: "all"},
    { key: "Dream", value: "dream" },
    { key: "Event", value: "event" },
    { key: "Out of Body", value: "outofbody" },
    { key: "Thought", value: "thought" },
  ];

  const targetRadioOptions = [
    { key: "Me", value: "me" },
    { key: "Friends", value: "friends" },
    { key: "Global", value: "global" },
  ];

  const initialValues = {
    record_type: "all",
    target: "global",
    start_date: new Date("2021-02-28"),
    end_date: new Date(today),
    keyword: "",
  };
  const analyzeSearchSchema = yup.object({
    target: yup.string().required("Required"),
    start_date: yup.date().required("Required").nullable(),
    end_date: yup.date().required("Required").nullable(),
    keyword: yup.string(),
  });

  useEffect(() => {
    console.log("analyzedExperiences =", analyzedExperiences);
  }, [props.analyzedExperiences]);

  const onSubmit = async (values, { resetForm }) => {
    setLoading(true);
    console.log("Analyze Search Form values: ", values);

    let newStartDate = formatDateYMD(values.start_date);
    let newEndDate = formatDateYMD(values.end_date);

    const newValues = {
      ...values,
      start_date: newStartDate,
      end_date: newEndDate,
    };

    console.log("Analyze Search New values: ", newValues);

    recordService
      .analyzeSearch(newValues)
      .then((response) => {
        if (response.data.status === "success") {
          console.log("Analyze service res = ", response.data);
          // handleAlert(
          //   "You have successfully created an association!",
          //   "success"
          // );
          const records = response.data.data.result.records;
          console.log("records = ", records);
          // let updatedRes = records.map((record) => {
          //   return { ...record, record_type: "dream" };
          // });
          // console.log("updatedRes = ", updatedRes);
          // props.setAnalyzedExperiences(updatedRes);
          props.setAnalyzedExperiences(records);
          handleCollapse("analyze_search_filters_collapse");
          setAnalyzeSearchFilters({
            record_type:
              newValues.record_type.charAt(0).toUpperCase() +
              newValues.record_type.slice(1),
            target: targetRadioOptions
              .filter((option) => option.value === newValues.target)
              .pop().key,
            end_date: newEndDate,
            start_date: newStartDate,
            keyword: newValues.keyword,
          });
          setLoading(false);
          // resetForm({});
        } else if (response.data.status === "failed") {
          console.log("ERROR:", response.data.error);
          // handleAlert("Association creation failed!", "error");
          setLoading(false);
          props.setAnalyzedExperiences([]);
          setAnalyzeSearchFilters({
            record_type: "All",
            target: "Global",
            end_date: today,
            start_date: "2021-02-28",
            keyword: "",
          });
          resetForm({});
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Date formatting
  function formattedDate(d) {
    return [d.getFullYear(), d.getMonth() + 1, d.getDate()]
      .map((n) => (n < 10 ? `0${n}` : `${n}`))
      .join("-");
  }

  const experienceList = [
    {
      id: "sexp1",
      title: "Shared Experience Title 1",
      type: "dream",
      user: "Chris Powell",
      date: "10/18/2021 | 2 days ago",
      isFavorite: true,
    },
    {
      id: "sexp2",
      title: "Shared Experience Title 2",
      type: "event",
      user: "Chris Powell",
      date: "10/08/2021 | 1 week ago",
      isFavorite: false,
    },
    {
      id: "sexp3",
      title: "Shared Experience Title 3",
      type: "thoughts",
      user: "Chris Powell",
      date: "10/5/2021 | 2 weeks ago",
      isFavorite: false,
    },
    {
      id: "sexp4",
      title: "Shared Experience Title 4",
      type: "out of body",
      user: "Chris Powell",
      date: "10/1/2021 | 2 weeks ago",
      isFavorite: false,
    },
  ];
  return (
    <>
      <Row className="align-items-center position-relative mb1">
        <Col>
          <div class="section_title small_title pe-5">
            Search through all dream content, including all descriptions and
            dream elements.
          </div>
          <div className="text-gray9 font-13">
            * Cannot search friends' dreams until you have at least ten friends
            on the site.
          </div>
          <Button
            type="button"
            className="btn_theme btn_icon btn_circle btn_pink position-absolute top-0"
            onClick={handleClick}
            aria-controls="analyze_search_filters_collapse"
            aria-expanded={analyzeSearchFiltersCollapse}
            style={{ right: "0.5rem" }}
          >
            <BsGearFill />
          </Button>
        </Col>
      </Row>

      <Row className="row-cols-1 align-items-center mb0">
        <Col>
          <div>
            <span className="custom_tag tag_primary_light tag_small">
              Type: {analyzeSearchFilters.record_type}
              {/* Target : {props.popularInsightsFilterData.target} */}
            </span>
            <span className="custom_tag tag_primary_light tag_small">
              Target: {analyzeSearchFilters.target}
            </span>
            <span className="custom_tag tag_primary_light tag_small">
              Date:
              {analyzeSearchFilters.start_date === "" ? (
                " All"
              ) : (
                <>{` ${analyzeSearchFilters.start_date} - ${analyzeSearchFilters.end_date}`}</>
              )}
            </span>
            <span className="custom_tag tag_primary_light tag_small">
              Keyword: {analyzeSearchFilters.keyword}
            </span>
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <Collapse in={analyzeSearchFiltersCollapse}>
            <div id="analyze_search_filters_collapse">
              <CustomCard className="border-0 shadow1 mt1 mb2 pt2 pb2 px-3">
                <Formik
                  initialValues={initialValues}
                  validationSchema={analyzeSearchSchema}
                  onSubmit={onSubmit}
                  // enableReinitialize
                >
                  {(formik) => {
                    return (
                      <Form
                        className="form_style"
                        onSubmit={formik.handleSubmit}
                      >
                        {/* <div className="p1 bg_lightgray"> */}
                        <div className="">
                          <DashboardSectionTitle
                            iconImg=""
                            title="Type of record"
                            pageTitle={false}
                            className="mb1 small_title"
                          />
                          <Form.Group className="form-group rounded_corners px-2 inline_radio_fields radio_btn_field">
                            <div className="">
                              {recordRadioOptions.map((option) => {
                                return (
                                  <Form.Check
                                    key={option.value}
                                    inline
                                    label={option.key}
                                    name="record_type"
                                    type="radio"
                                    id={"search-" + option.value}
                                    value={option.value}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    checked={
                                      formik.values?.record_type == option.value
                                    }
                                  />
                                );
                              })}
                            </div>
                            {formik.touched.record_type &&
                            formik.errors.record_type ? (
                              <p className="error-state-text">
                                {formik.errors.record_type}
                              </p>
                            ) : null}
                          </Form.Group>
                        </div>
                        <div className="">
                          <DashboardSectionTitle
                            iconImg=""
                            title="Select target *"
                            pageTitle={false}
                            className="mb1 small_title"
                          />
                          <Form.Group className="form-group rounded_corners px-2 inline_radio_fields radio_btn_field">
                            <div className="">
                              {targetRadioOptions.map((option) => {
                                return (
                                  <Form.Check
                                    key={option.value}
                                    inline
                                    label={option.key}
                                    name="target"
                                    type="radio"
                                    id={option.value}
                                    value={option.value}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    // {...formik.getFieldProps("outcome")}
                                    // value={option.value}
                                    checked={
                                      formik.values?.target == option.value
                                    }
                                  />
                                );
                              })}
                            </div>
                            {formik.touched.target && formik.errors.target ? (
                              <p className="error-state-text">
                                {formik.errors.target}
                              </p>
                            ) : null}
                          </Form.Group>
                        </div>

                        <Row className="form_style no-gutters">
                          <Col xs={12}>
                            <DashboardSectionTitle
                              iconImg=""
                              title="Select time"
                              pageTitle={false}
                              className="mb1 small_title"
                            />
                          </Col>
                          <Col xs={12} sm={12} md={6}>
                            <Form.Group className="form-group rounded_corners">
                              <DashboardSectionTitle
                                iconImg=""
                                title="Start date"
                                pageTitle={false}
                                className="mb-1 small_title"
                              />
                              <Field name="start_date">
                                {({ form, field }) => {
                                  const { setFieldValue } = form;
                                  const { value } = field;

                                  return (
                                    <DatePicker
                                      id="start_date"
                                      className="form-control"
                                      {...field}
                                      selected={value}
                                      onChange={(val) =>
                                        setFieldValue("start_date", val)
                                      }
                                      dateFormat="yyyy-MM-dd"
                                      maxDate={addDays(
                                        new Date(formik.values.end_date),
                                        0
                                      )}
                                      placeholderText="Start date"
                                      showMonthDropdown
                                      showYearDropdown
                                      dropdownMode="select"
                                      // scrollableMonthYearDropdown
                                      autoComplete="off"
                                    />
                                  );
                                }}
                              </Field>
                              {formik.touched.start_date &&
                              formik.errors.start_date ? (
                                <p className="error-state-text">
                                  {formik.errors.start_date}
                                </p>
                              ) : null}
                            </Form.Group>
                          </Col>
                          <Col xs={12} sm={12} md={6}>
                            <Form.Group className="form-group rounded_corners">
                              <DashboardSectionTitle
                                iconImg=""
                                title="End date"
                                pageTitle={false}
                                className="mb-1 small_title"
                              />
                              <Field name="end_date">
                                {({ form, field }) => {
                                  const { setFieldValue } = form;
                                  const { value } = field;

                                  return (
                                    <DatePicker
                                      id="end_date"
                                      className="form-control"
                                      {...field}
                                      todayButton="Today"
                                      selected={value}
                                      onChange={(val) =>
                                        setFieldValue("end_date", val)
                                      }
                                      dateFormat="yyyy-MM-dd"
                                      minDate={subDays(
                                        new Date(formik.values.start_date),
                                        0
                                      )}
                                      placeholderText="End date"
                                      showMonthDropdown
                                      showYearDropdown
                                      dropdownMode="select"
                                      // scrollableMonthYearDropdown
                                      autoComplete="off"
                                    />
                                  );
                                }}
                              </Field>
                              {formik.touched.end_date &&
                              formik.errors.end_date ? (
                                <p className="error-state-text">
                                  {formik.errors.end_date}
                                </p>
                              ) : null}
                            </Form.Group>
                          </Col>
                        </Row>

                        <Form.Group className="form-group rounded_corners">
                          <DashboardSectionTitle
                            iconImg=""
                            title="Enter keyword (optional)"
                            pageTitle={false}
                            className="mb1 small_title"
                          />
                          <Form.Control
                            type="text"
                            placeholder="Enter keyword"
                            name="keyword"
                            {...formik.getFieldProps("keyword")}
                            autoComplete="off"
                          />

                          {/* {formik.touched.keyword && formik.errors.keyword ? (
                        <p className="error-state-text">
                          {formik.errors.keyword}
                        </p>
                      ) : null} */}
                        </Form.Group>

                        <div className="btn_wrap pb1">
                          <Button
                            key="submit_btn"
                            type="submit"
                            className="btn_theme btn_primary btn_rounded"
                          >
                            {loading ? (
                              <>
                                <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                />{" "}
                                Processing
                              </>
                            ) : (
                              "Submit"
                            )}
                          </Button>

                          <Button
                            key="close_filters_btn"
                            type="button"
                            className="btn_theme btn_light btn_rounded"
                            onClick={() => {
                              handleCollapse("analyze_search_filters_collapse");
                            }}
                          >
                            Cancel
                          </Button>
                        </div>
                        {/* </div> */}
                      </Form>
                    );
                  }}
                </Formik>
              </CustomCard>
            </div>
          </Collapse>
        </Col>
      </Row>

      {/* {analyzedExperiences.length > 0 &&
        analyzedExperiences.map((exp, index) => (
          // <p>record id = {exp.record_id}</p>
          <DashboardAnalyzeCard exp={exp} key={index} />
        ))}

      {analyzedExperiences.length < 0 && <p>No record found</p>} */}

      {!loading && props.analyzedExperiences?.length > 0 ? (
        <>
          <div className="font-14 fw_b p-2 mt1 mb1">
            Total records found:{" "}
            {props.analyzedExperiences.length > 0
              ? props.analyzedExperiences.length
              : 0}
          </div>
          {props.analyzedExperiences?.map((exp, index) => (
            <DashboardAnalyzeCard exp={exp} key={index} />
          ))}
        </>
      ) : (
        <>
          {loading ? (
            <>
              {[0, 1].map((_, i) => (
                <CustomCard
                  key={i}
                  className={`main_card shadow1 border-0 mb2 text-left overflow-hidden`}
                >
                  <Card.Header>
                    <div className="d-flex align-items-center">
                      <div className="flex-shrink-0">
                        <Placeholder as="div" animation="glow">
                          <Placeholder
                            bg="secondary"
                            as="div"
                            style={{ height: "54px", width: "54px" }}
                            className="rounded-circle"
                          />
                        </Placeholder>
                      </div>
                      <div className="flex-grow-1 ms-3 text-left">
                        <div className="w-100">
                          <Placeholder xs={6} bg="secondary" />
                        </div>
                        <div className="w-100">
                          <Placeholder xs={4} bg="secondary" />
                        </div>
                      </div>
                    </div>
                  </Card.Header>
                  <Card.Body className="p2">
                    <div className="w-100 mb-1">
                      <Placeholder xs={9} bg="secondary" />
                    </div>
                    <div className="w-100 mb1">
                      <Placeholder xs={6} bg="secondary" />
                    </div>
                  </Card.Body>
                </CustomCard>
              ))}
            </>
          ) : (
            <CustomCard className="shadow1 border-0 text-center mt1">
              <Card.Body>
                <div className="mb1">
                  <BsFillExclamationTriangleFill
                    style={{
                      color: "#d3af61",
                      fontSize: "2.2rem",
                    }}
                  />
                </div>
                <b>No more records found...</b>
              </Card.Body>
            </CustomCard>
          )}
        </>
      )}
    </>
  );
}

export default DashboardAnalyzeSearch;
