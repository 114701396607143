import React, { useState, useEffect, useContext } from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import {
  Chart as ChartJS,
  CategoryScale,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Pie } from "react-chartjs-2";
import ChartLegendList from "../../../ui/ChartLegendList/ChartLegendList";
//Context
import ThemeContext from "../../../ThemeContext";

ChartJS.register(
  CategoryScale,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

function DashboardPieChart(props) {
  const { theme } = useContext(ThemeContext);
  ChartJS.defaults.color = theme === 'dark' ? '#fff' : '#444';
  const [data, setData] = useState({
    labels: props.labels,
    datasets: props.datasets,
  });
  const [legendList, setLegendList] = useState(props.legend_list);
  const [showAll, setShowAll] = useState(false);

  const options = {
    maintainAspectRatio: false,
    // indexAxis: "y",
    // scales: {
    //   y: {
    //     grid: {
    //       offset: true,
    //     },
    //   },
    // },
    elements: {
      bar: {
        borderWidth: 1,
        // inflateAmount: 2,
      },
    },
    layout: {
      autoPadding: true,
    },
    responsive: true,
    plugins: {
      tooltip: {
        boxPadding: 2
      },
      legend: {
        display: props.legend_list?.length > 0 ? false : true,
        // display: true,
        position: "top",
      },
      title: {
        display: false,
        text: props.chart_title ? props.chart_title : "Pie Chart",
        padding: {
          top: 10,
          bottom: 20,
        },
        color: "#333333",
        font: {
          size: 14,
          weight: "normal",
        },
      },
    },
  };

  //   console.log("All datatset =", allDataSets);

  // const data = {
  //   labels: props.labels,
  //   datasets: props.datasets,
  // };
  // console.log("Dashboard labels =", props.labels);
  console.log("Dashboard PIE Datasets =", props.datasets);

  useEffect(() => {
    //first
    console.log("in useeffect outside showAll =", showAll);
    if (showAll) {
      console.log("in useeffect if showAll =", showAll);
      setData({
        labels: props.labels,
        datasets: props.datasets,
      });
      setLegendList(props.legend_list);
    } else {
      setData({
        labels: props.labels
          .filter((label, index) => index < 5)
          .map((item) => item),
        // datasets: props.datasets.slice(0, 5).map((dataset) => dataset),
        datasets: props.datasets.map((dataset) => {
          return {
            ...dataset,
            data: dataset.data
              .filter((label, index) => index < 5)
              .map((item) => item),
          };
        }),
      });
      setLegendList(
        props.legend_list
          ?.filter((item, index) => index < 5)
          .map((item) => item)
      );
    }
    // setData({
    //   labels: props.labels
    //     .filter((label, index) => index < 5)
    //     .map((item) => item),
    //   datasets: props.datasets.slice(0, 5).map((dataset) => dataset),
    // });
    console.log("PIE DATA = ", data);
    console.log("Legendlist = ",legendList)
  }, [showAll, props.labels]);

  return (
    <>
      <Row className="row-cols-2 align-items-center mb-2">
        <Col>
          <span className="fw_sb mb-1 d-flex">{`# of experiences = ${props.recordCount}`}</span>
          <div>
            {showAll ? (
              <span className="custom_tag tag_small tag_golden">
                Result : All
              </span>
            ) : (
              <span className="custom_tag tag_small tag_golden">
                Result : Top 5
              </span>
            )}
          </div>
        </Col>
        <Col className="d-flex justify-content-end">
          <Button
            type="button"
            className="btn_theme btn_small btn_light btn_rounded"
            onClick={() => {
              setShowAll(!showAll);
            }}
          >
            {showAll ? "View Top 5" : "View All"}
          </Button>
        </Col>
      </Row>

      <div className="d-flex align-items-center justify-content-center font-14 fw_sb mb1 chart-title">
        <span className="py-1 px-3">
          {props.chart_title ? props.chart_title : "Pie Chart"}
        </span>
      </div>

      <div
        className="pt1 mb-3"
        style={{ height: "70vh" }}
        // style={
        //   props.dataLength === "full" ? { height: "100vh" } : { height: "35vh" }
        // }
      >
        <Pie options={options} data={data} />
      </div>
      {legendList?.length > 0 ? (
        <Card className="mb-1 py-3 px-2"
          style={showAll ? {height: 140, overflowY: 'scroll'} : {}}
        >
          <ChartLegendList legendList={legendList} />
        </Card>
      ) : null}
    </>
  );
}

export default DashboardPieChart;
